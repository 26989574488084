import React from 'react';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';

import {
    PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT,
    translatePermissionName
} from '../../../helpers_permissions';
import { Text } from '../../common';
import {
    PermissionListImplementationLevelCell
} from './PermissionListImplementationLevelCell';
import { PermissionsMoreOptionButton } from './PermissionsMoreOptionButton';


export const PermissionListRow = ({
    isImplementationLevelType, permission, onSetActiveHistoryPermission,
    onSetActiveUsersPermission
}) => {

    const permissions = (
        permission.permissions || {}).permissionsAdministration || {};
    const permissionForActionDict = {
        isImplementationLevelType: false,
        permission: {type: permission.type},
    };
    return (
        <DataTableRow>
            <DataTableCell className="permissions__col-permissions-list">
                {isImplementationLevelType
                    ? <PermissionListImplementationLevelCell
                        permission={permission}
                        onSetActiveHistoryPermission={onSetActiveHistoryPermission}
                        onSetActiveUsersPermission={onSetActiveUsersPermission} />
                    : (
                        <div className="permissions__level-row">
                            <PermissionsMoreOptionButton
                                onOpenHistory={() => onSetActiveHistoryPermission(
                                    permissionForActionDict
                                )}
                                onOpenUserList={() => onSetActiveUsersPermission(
                                    permissionForActionDict
                                )} />
                            <Text>{PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[permission.type]}</Text>
                        </div>
                    )
                }
            </DataTableCell>
            <DataTableCell>
                {(permissions.previewListUsersAuthorized || false) &&
                    <Text className="permissions__legend permissions__legend--users">
                        {translatePermissionName('previewListUsersAuthorized')}
                    </Text>
                }
                {(permissions.functionalityManagement || false)  &&
                    <Text className="permissions__legend permissions__legend--projects">
                        {translatePermissionName(
                            isImplementationLevelType
                                ? 'functionalityManagementImplementationLevel'
                                : 'functionalityManagement'
                        )}
                    </Text>
                }
                {(permissions.permissionsManagement || false)  &&
                    <Text className="permissions__legend permissions__legend--permissions">
                        {translatePermissionName('permissionsManagement')}
                    </Text>
                }
            </DataTableCell>
        </DataTableRow>
    )
};
