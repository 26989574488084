import React, { Component } from 'react';

import { foreignCountries } from '../../../foreignCountries';
import { FieldWithLabel, Select, Text } from '../../common';
import { customSelectStyles } from '../../vars/vars';


const EMPTY_VALUE = {
    value: 0,
    label: 'Zacznij wpisywać...',
};
const SELECT_CLASSNAME = 'select-custom';
const SELECT_INVALID_CLASSNAME = 'select-custom select-custom--invalid';


class FormKraj extends Component {

    constructor(props) {
        super(props);
        let kraj = null;
        if (props.krajName) {
            kraj = {
                value: props.krajName,
                label: props.krajName,
            }
        }
        this.state = {kraj};

        this.handleChangeKraj = this.handleChangeKraj.bind(this);
    }

    // handlers

    handleChangeKraj(selectedOption) {
        this.setState(
            {kraj: selectedOption},
            () => this.props.changeKraj(selectedOption)
        );
    }

    // rendering

    render() {
        const { kraj } = this.state;
        const { error, formLocked, invalid, isClearable } = this.props;

        let options = Object.values(foreignCountries).map(
            kraj_ => ({value: kraj_, label: kraj_}));
        let value;
        if (isClearable) {
            value = kraj;
        } else {
            options = [EMPTY_VALUE, ...options];
            value = {
                value: kraj ? kraj.value : EMPTY_VALUE.value,
                label: kraj ? kraj.label : EMPTY_VALUE.label
            };
        }

        return (
            <FieldWithLabel
                label="Kraj"
                labelFor="country_id"
                selectRef={React.createRef()}
                tag="label"
            >
                <Select
                    aria-label={`Kraj ${invalid && error}`}
                    aria-required="true"
                    className={invalid ? SELECT_INVALID_CLASSNAME : SELECT_CLASSNAME}
                    isClearable={isClearable}
                    isDisabled={formLocked}
                    id="country_id"
                    isSearchable={true}
                    noOptionsMessage={() => 'Brak wybranej opcji'}
                    options={options}
                    placeholder={isClearable ? EMPTY_VALUE.label : ''}
                    required={true}
                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                    styles={customSelectStyles}
                    value={value}
                    onChange={selectedOption => this.handleChangeKraj(selectedOption)}
                />
                {invalid && <Text error>{error}</Text>}
            </FieldWithLabel>
        )
    }
}


export { FormKraj };
