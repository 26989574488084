import React from 'react';

import { Text } from '../Text';


export const PaginatedListHeader = ({title, total}) => {
    return (
        <div className="section-header">
            <Text tag="h1" sectionHeader>{title}</Text>
            <Text info className="text--counter">Liczba wyników: {total}</Text>
        </div>
    )
}
