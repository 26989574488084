import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TabBar, Tab } from '@rmwc/tabs';

import { Breadcrumps } from '../../common';
import { Permissions } from './Permissions';
import { Uzytkownicy } from './Uzytkownicy';


const TAB_INDEXES = {
    lista: 0,
    uprawnienia: 1,
};
const REVERTED_TAB_INDEXES = {
    0: 'lista',
    1: 'uprawnienia',
};


export const Users = (props) => {
    const [selectedTab, setSelectedTab] = useState(TAB_INDEXES.lista);
    const location = useLocation();

    useEffect(() => {
        document.title = "Baza Konkurencyjności - użytkownicy";
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const section = params.get('sekcja') || 'lista';
        setSelectedTab(TAB_INDEXES[section] || TAB_INDEXES.lista);
    }, [location.search]);

    function handleChangeTab(ev) {
        props.history.push(
            `${location.pathname}?sekcja=${
                REVERTED_TAB_INDEXES[ev.detail.index] ||
                REVERTED_TAB_INDEXES[0]
            }`
        );
    }

    return (
        <>
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__current">
                    Administracja użytkownikami i uprawnieniami
                </li>
            </Breadcrumps>
            <TabBar
                activeTabIndex={selectedTab}
                onActivate={handleChangeTab}>
                <Tab aria-label="Lista użytkowników, panel zakładek, lista 1 z 2" label="Lista użytkowników" />
                <Tab aria-label="Przegląd uprawnień, panel zakładek, lista 2 z 2" label="Przegląd uprawnień" />
            </TabBar>
            {selectedTab === TAB_INDEXES.lista && (
                <Uzytkownicy {...props} />
            )}
            {selectedTab === TAB_INDEXES.uprawnienia && <Permissions />}
        </>
    )
};
