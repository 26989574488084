import React from 'react';
import {
    DataTableCell,
    DataTableRow,
} from '@rmwc/data-table';

import { Container, TableCustom, Text } from '../../common';


export const UserContactPersonsList = ({ contactPersons }) => {
    let content;
    if (contactPersons.length) {
        content = (
            <TableCustom tableHeaderList={[
                'Imię i nazwisko',
                'Numer telefonu',
                'Adres e-mail',
            ]}>
                {contactPersons.map(cp => (
                    <DataTableRow key={cp.id}>
                        <DataTableCell>{cp.imie} {cp.nazwisko}</DataTableCell>
                        <DataTableCell>{cp.numerTelefonu}</DataTableCell>
                        <DataTableCell className="table__width-needy-cell">
                            {cp.email}
                        </DataTableCell>
                    </DataTableRow>
                ))}
            </TableCustom>
        );
    } else {
        content = <Text error>Brak zdefiniowanych osób do kontaktu.</Text>;
    }
    return (
        <Container>
            <Text tag="h2" mainHeader>Osoby do kontaktu</Text>
            {content}
        </Container>
    )
};
