import React from 'react';

import { FieldWithLabel, GridCustom, TextFieldCustom } from '../../common';


export const UserDataFilters = ({userEmail, userForename, userSurname, onChangeFilter}) => {

    return (
        <>
            <GridCustom fullwidth flexM>
                <FieldWithLabel 
                    label="Adres e-mail"
                    tag="label"
                    labelFor="filters_userEmail_id"
                >
                    <TextFieldCustom
                        aria-label={`Adres e-mail, maksymalna liczba znaków: 100. Wpisano ${userEmail.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="adres e-mail"
                        id="filters_userEmail_id"
                        maxLength={100}
                        value={userEmail}
                        onChange={ev => onChangeFilter('userEmail', ev.target.value)}
                        onClear={ev => onChangeFilter('userEmail', '')} />
                </FieldWithLabel>
            </GridCustom>
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Imię"
                    tag="label"
                    labelFor="filters_userForename_id"
                >
                    <TextFieldCustom
                        aria-label={`Imię, maksymalna liczba znaków: 50. Wpisano ${userForename.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="imię"
                        id="filters_userForename_id"
                        maxLength={50}
                        value={userForename}
                        onChange={ev => onChangeFilter('userForename', ev.target.value)}
                        onClear={ev => onChangeFilter('userForename', '')} />
                </FieldWithLabel>
                <FieldWithLabel
                    label="Nazwisko"
                    tag="label"
                    labelFor="filters_userSurname_id"
                >
                    <TextFieldCustom
                        aria-label={`Nazwisko, maksymalna liczba znaków: 50. Wpisano ${userSurname.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="nazwisko"
                        id="filters_userSurname_id"
                        maxLength={50}
                        value={userSurname}
                        onChange={ev => onChangeFilter('userSurname', ev.target.value)}
                        onClear={ev => onChangeFilter('userSurname', '')} />
                </FieldWithLabel>
            </GridCustom>
        </>
    )
}
