import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';
import { Radio } from '@rmwc/radio';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/radio/dist/mdc.radio.css';

import { fetchApi } from '../../../fetch';
import { dateStringToYearMonth, datetimeToString, dateToString,
         fillWithLeadingZero } from '../../../helpers';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { customSelectStyles } from '../../vars/vars';
import {
    ButtonStyled,
    DateCalendar,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Message,
    Select,
    Text,
} from '../../common';


const EMPTY_VALUE = {
    value: null,
    label: '--- Wybierz ---',
};


class FormPublikowanie extends Component {

    constructor(props) {
        super(props);

        const o = props.formOgloszenie.context.ogloszenie;
        this.planowanyTerminPodpisaniaUmowy = o.planowanyTerminPodpisaniaUmowy;

        this.state ={
            initializingOn: true,
            savingOn: false,
            publishImmediately: props.formOgloszenie.isAlreadyPublished || o.dataOpublikowania === '',
            dataOpublikowania: o.dataOpublikowania,
            terminOfertData: '',
            terminOfertWithTime: false,
            terminOfertGodzina: EMPTY_VALUE,
            error: '',
            formErrors: {},
            dialogPublishClassName: null,
        };

        this.xhrFetch = null;
    }

    // basic functions

    componentDidMount() {
        const formOgloszenie = this.props.formOgloszenie;
        if (formOgloszenie.handleValidate()) {
            formOgloszenie.handleSave(
                null,
                this.handleFetchSaveSuccess,
                this.handleFetchSaveError,
                this.handleFetchSaveIncorrectStatus,
            );
        } else {
            this.setState({
                initializingOn: false,
                error: 'Ogłoszenie zawiera błędy. Po ich poprawieniu spróbuj opublikować ogłoszenie jeszcze raz.',
            });
        }
    }

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }
    }

    // handlers

    handleFetchSaveSuccess = (data) => {
        const formOgloszenie = this.props.formOgloszenie;
        formOgloszenie.handleFetchSaveSuccess(
            data,
            () => {
                formOgloszenie.context.updateOgloszenieBeforePublishing(
                    () => this.setState({
                        initializingOn: false,
                        dataOpublikowania: '',
                    })
                );
            }
        );
    }

    setError = (message) => {
        this.setState({initializingOn: false, error: message});
    }

    handleFetchSaveError = (data) => {
        this.setError(
            'Podczas próby opublikowania ogłoszenia wystąpił nieoczekiwany błąd.');
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.setError(
            `Podczas próby opublikowania ogłoszenia wystąpił nieoczekiwany błąd o kodzie ${status}.`);
    }

    handleCloseDialog = () => {
        this.props.formOgloszenie.setState({publishingOn: false})
    }

    handleChangePublikujOdRazu = (event) => {
        const publikujOdRazu = event.currentTarget.value === 'true';
        let data = {publishImmediately: publikujOdRazu};
        if (publikujOdRazu) {
            data['dataOpublikowania'] = '';
        }
        this.setState(data);
    }

    handleChangeTerminOfertData = (date) => {
        let data = {terminOfertData: !date ? '' : dateToString(date)};
        if (!date) {
            data['terminOfertGodzina'] = '';
            data['terminOfertWithTime'] = false;
        }
        this.setState(
            prevState => {
                data['formErrors'] = Object.assign({}, prevState.formErrors, {terminOfertGodzina: '', terminOfertData: ''});
                return data
            }
        );
    }

    handleChangeTerminOfertWithTime = (ev) => {
        const isChecked = ev.target.checked;
        let data = {terminOfertWithTime: isChecked};
        if (!isChecked) {
            data['terminOfertGodzina'] = EMPTY_VALUE;
        }
        this.setState(data);
    }

    handleCalendarOpen = () => {
        this.setState({ dialogPublishClassName: 'dialog--publish'})
    }

    handleCalendarClose = () => {
        this.setState({ dialogPublishClassName: null })
    }

    handlePublish = () => {
        this.setState(
            {savingOn: true},
            () => {
                const [isValid, errors] = this.validate();
                if (isValid) {
                    this.xhrFetch = fetchApi(
                        `/api/announcements/${this.props.formOgloszenie.context.ogloszenie.ogloszenie.id}/publish`,
                        'POST',
                        {},
                        getSerializedObject(this.getData(), {toServer: true}),
                        this.handleFetchPublishSuccess,
                        this.handleFetchPublishError,
                        this.handleFetchPublishIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, formErrors: errors});
                }
            }
        );
    }

    handleFetchPublishSuccess = (data) => {
        this.xhrFetch = null;
        this.props.formOgloszenie.setPublishingSuccessInfo(
            !this.state.dataOpublikowania
                ? 'Ogłoszenie zostało pomyślnie opublikowane.'
                :'Ogłoszenie zostało zapisane z przyszłą datą publikacji.'
        );
    }

    handleFetchPublishError = (data) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            error: `Podczas próby opublikowania ogłoszenia wystąpił błąd. ${translateErrorMessage(data.message)} Dane ogłoszenia zostały zachowane w kopii roboczej.`,
            savingOn: false,
        });
    }

    handleFetchPublishIncorrectStatus = (status) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            error: `Podczas próby opublikowania ogłoszenia wystąpił nieoczekiwany błąd o kodzie ${status}. Dane ogłoszenia zostały zachowane w kopii roboczej.`,
            savingOn: false,
        });
    }

    // helpers

    validate() {
        const {
            dataOpublikowania,
            publishImmediately,
            terminOfertGodzina,
            terminOfertData,
            terminOfertWithTime,
        } = this.state;

        let isValid = true;
        let errors = {};

        if (!publishImmediately && !dataOpublikowania) {
            isValid = false;
            errors['dataOpublikowania'] = 'To pole jest wymagane.';
        }

        const today = dateToString(new Date());

        if (dataOpublikowania && dataOpublikowania <= today) {
            isValid = false;
            errors['dataOpublikowania'] = 'Data publikacji musi być późniejsza niż dziś.';
        }
        if (!terminOfertData) {
            isValid = false;
            errors['terminOfertData'] = 'To pole jest wymagane.';
        } else {
            if (terminOfertWithTime && terminOfertGodzina.value === null) {
                isValid = false;
                errors['terminOfertGodzina'] = 'To pole jest wymagane.';
            }

            if (this.planowanyTerminPodpisaniaUmowy && dateStringToYearMonth(this.planowanyTerminPodpisaniaUmowy) < dateStringToYearMonth(terminOfertData)) {
                isValid = false;
                errors['terminOfertData'] = 'Planowany termin podpisania umowy nie może być wcześniejszy niż miesiąc i rok wskazane w terminie ofert. Wróć do formularza ogłoszenia i zamień planowany termin podpisania umowy w sekcji Terminy.';
            } else if (dataOpublikowania && terminOfertData <= dataOpublikowania) {
                isValid = false;
                errors['terminOfertData'] = 'Termin składania ofert musi być późniejszy niż data opublikowania.';
            } else if (terminOfertData <= today) {
                isValid = false;
                errors['terminOfertData'] = 'Termin składania ofert musi być późniejszy niż dziś.';
            }
        }

        return [isValid, errors]
    }

    getTomorrow() {
        return new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    }

    getData() {
        const {
            dataOpublikowania,
            terminOfertGodzina,
            terminOfertData,
            terminOfertWithTime
        } = this.state;

        let terminOfert_;
        if (terminOfertWithTime) {
            terminOfert_ = `${datetimeToString(new Date(new Date(terminOfertData).setHours(terminOfertGodzina.value || 0)))}:00`;
        } else {
            terminOfert_ = `${terminOfertData} 23:59:59`;
        }

        return {
            id: this.props.formOgloszenie.context.ogloszenie.id,
            dataOpublikowania: dataOpublikowania,
            terminOfert: terminOfert_,
        }
    }

    // rendering

    render() {
        let content = null;
        if (this.state.initializingOn) {
            content = <Text info tabIndex="1">Trwa sprawdzanie danych do opublikowania ogłoszenia...</Text>;
        } else {
            content = (
                this.state.error.length > 0 ? this.renderError() : this.renderForm()
            );
        }
        return (
            <DialogCustom
                onClose={this.handleCloseDialog}
            >
                {content}
            </DialogCustom>
        )
    }

    renderError() {
        return (
            <>
                <Message
                    error
                    messageIcon="clear"
                    messageTitle={this.state.error} />
                <GridCustom flexEnd>
                    <ButtonStyled onClick={this.handleCloseDialog} primary>OK</ButtonStyled>
                </GridCustom>
            </>
        )
    }

    renderForm() {
        const {
            dataOpublikowania,
            publishImmediately,
            savingOn,
            terminOfertGodzina,
            terminOfertData,
            terminOfertWithTime,
        } = this.state;

        const terminOfertDataError = this.state.formErrors.terminOfertData || '';
        const isTerminOfertDataInvalid = terminOfertDataError.length > 0;
        const terminOfertGodzinaError = this.state.formErrors.terminOfertGodzina || '';
        const isTerminOfertGodzinaInvalid = terminOfertGodzinaError.length > 0;
        let terminSkladaniaOfertAriDescribedby1 = null;
        let terminSkladaniaOfertAriDescribedby2 = null;
        let terminSkladaniaOfertAriDescribedby = null;

        terminSkladaniaOfertAriDescribedby1 = !['', null].includes(terminOfertData) && 'terminOfertData_id';
        terminSkladaniaOfertAriDescribedby2 = isTerminOfertDataInvalid && 'terminOfertData_error';
        terminSkladaniaOfertAriDescribedby = (terminSkladaniaOfertAriDescribedby1 && terminSkladaniaOfertAriDescribedby2) ? `${terminSkladaniaOfertAriDescribedby1} ${terminSkladaniaOfertAriDescribedby2}` : (terminSkladaniaOfertAriDescribedby1 || terminSkladaniaOfertAriDescribedby2)

        const terminOfertInputRef = React.createRef();
        return (
            <div className={this.state.dialogPublishClassName}>
                {this.renderWhenPublishPart()}
                {!publishImmediately && this.renderPublicationDateField()}
                <GridCustom flexS fullwidth className=" date-time-picker">
                    <FieldWithLabel
                        className={savingOn && 'disabled'}
                        inputRef={terminOfertInputRef}
                        label="Termin składania ofert"
                        tag="label">
                        <DateCalendar
                            ariaDescribedby={terminSkladaniaOfertAriDescribedby}
                            disabled={savingOn}
                            fullwidth
                            invalid={isTerminOfertDataInvalid}
                            minDate={!dataOpublikowania ? this.getTomorrow() : Date.parse(dataOpublikowania)}
                            parentRef={terminOfertInputRef}
                            value={Date.parse(terminOfertData)}
                            onChange={this.handleChangeTerminOfertData}
                            onCalendarOpen={this.handleCalendarOpen}
                            onCalendarClose={this.handleCalendarClose}
                        />
                        {isTerminOfertDataInvalid && <Text error id="terminOfertData_error">{terminOfertDataError}</Text>}
                    </FieldWithLabel>
                    <section>
                        <Checkbox
                            disabled={savingOn || !terminOfertData}
                            label="Wskaż konkretną godzinę"
                            checked={terminOfertWithTime}
                            onChange={this.handleChangeTerminOfertWithTime}
                            className="date-time-picker__checkbox"
                        />
                        <Select
                            aria-label={`Godzina ${isTerminOfertGodzinaInvalid ? terminOfertGodzinaError: ''}`}
                            className={isTerminOfertGodzinaInvalid ? 'select-custom select-custom--invalid' : 'select-custom'}
                            isDisabled={savingOn || !terminOfertData || !terminOfertWithTime}
                            options={[EMPTY_VALUE, ].concat([...Array(24).keys()].map(h => {
                                return {value: h, label: `${fillWithLeadingZero(h)}:00`}
                            }))}
                            placeholder="--- Wybierz ---"
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            value={terminOfertGodzina}
                            onChange={(selectedOption) => this.setState({terminOfertGodzina: selectedOption})}
                            styles={customSelectStyles}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                        {isTerminOfertGodzinaInvalid && <Text error>{terminOfertGodzinaError}</Text>}
                    </section>
                </GridCustom>
                {!['', null].includes(terminOfertData) && <Text info id="terminOfertData_id">Konieczne jest zachowanie zgodnego z Wytycznymi, minimalnego okresu między publikacją ogłoszenia a terminem składania ofert. Uwaga! Jeżeli koniec wyznaczonego terminu przypada na sobotę lub dzień ustawowo wolny od pracy, w Bazie należy zaznaczyć dzień następujący po dniu lub dniach wolnych od pracy.</Text>}
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled disabled={savingOn} onClick={this.handleCloseDialog} cancel>Wróć do edycji</ButtonStyled>
                    <ButtonStyled disabled={savingOn} onClick={this.handlePublish} save>Potwierdź publikację</ButtonStyled>
                </GridCustom>
            </div>
        )
    }

    renderWhenPublishPart() {
        const {publishImmediately, savingOn} = this.state;
        const isAlreadyPublished = this.props.formOgloszenie.isAlreadyPublished;

        return (
            <fieldset>
                <Text tag="legend" className="label">Kiedy chcesz opublikować ogłoszenie:</Text>
                <Radio
                    disabled={isAlreadyPublished || savingOn}
                    value={true}
                    name="publishImmediatelyRadioGroup"
                    checked={publishImmediately === true}
                    onChange={this.handleChangePublikujOdRazu}
                    label="Publikuj od razu"
                    id="radio_publish_id"
                />
                <Radio
                    disabled={isAlreadyPublished || savingOn}
                    value={false}
                    name="publishImmediatelyRadioGroup"
                    checked={publishImmediately === false}
                    onChange={this.handleChangePublikujOdRazu}
                    label="Publikuj później"
                />
                {isAlreadyPublished && <Text className="success-text">To ogłoszenie zostało już wcześniej opublikowane, w związku z tym nowa wersja zostanie opublikowana natychmiastowo.</Text>}
            </fieldset>
        )
    }

    renderPublicationDateField() {
        const error = this.state.formErrors.dataOpublikowania || '';
        const isInvalid = error.length > 0;
        const dataOpublikowaniaInputRef = React.createRef();
        return (
            <FieldWithLabel label="Data publikacji" inputRef={dataOpublikowaniaInputRef} className={this.state.savingOn && 'disabled'}>
                <DateCalendar
                    ariaDescribedby={isInvalid ? 'dataOpublikowania_error' : null}
                    disabled={this.state.savingOn}
                    fullwidth
                    invalid={isInvalid}
                    minDate={this.getTomorrow()}
                    parentRef={dataOpublikowaniaInputRef}
                    value={Date.parse(this.state.dataOpublikowania)}
                    onChange={(date) => this.setState({dataOpublikowania: dateToString(date)})} />
                {isInvalid && <Text error id="dataOpublikowania_error">{error}</Text>}
            </FieldWithLabel>
        )
    }
}


export { FormPublikowanie };
