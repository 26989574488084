import jp from 'jsonpath';

// from server
const mapServerFront = {
    account_disabled: 'kontoZablokowane',
    account_disabled_at: 'dataZablokowaniaKonta',
    accountDisabled: 'kontoZablokowane',
    active: 'czyAktywny',
    activities: 'dzialania',
    activity: 'dzialanie',
    added_at: 'dataDodania',
    address: 'adres',
    address_type: 'rodzajAdresu',
    addressType: 'rodzajAdresu',
    advertisement: 'ogloszenie',
    advertisements: 'ogloszenia',
    advertiser_address_details: 'daneAdresoweOgloszeniodawcy',
    advertiser_name: 'nazwaOgloszeniodawcy',
    answer: 'odpowiedz',
    attached_advertisements_amount: 'attachedAdvertisementsAmount',
    attachments: 'zalaczniki',
    category: 'kategoria',
    children: 'dzieci',
    chosen_offer_variant: 'wybranyWariant',
    chosenOfferVariant: 'wybranyWariant',
    code: 'kod',
    comment: 'komentarz',
    commune: 'gmina',
    contact_persons: 'osobyDoKontaktu',
    content: 'tresc',
    country: 'kraj',
    cpv_items: 'kodyCPV',
    created_by: 'ktoUtworzyl',
    created_at: 'dataUtworzenia',
    current_exists: 'aktualnyIstnieje',
    description: 'opis',
    dictionaries: 'slowniki',
    economic_subject: 'danePodmiotu',
    economicSubject: 'danePodmiotu',
    email: 'email',
    end_at: 'koniecRealizacji',
    enrollment: 'nabor',
    enrollment_number: 'numerNaboru',
    estimated_value: 'szacunkowaWartosc',
    evaluation_criteria: 'kryteriaOceny',
    expiration_date: 'dataWaznosci',
    file: 'plik',
    file_to_load: 'plikDoWgrania',
    for_publication_exists: 'doPublikacjiIstnieje',
    forename: 'imie',
    fulfillment_place: 'miejsceRealizacji',
    fulfillment_places: 'miejscaRealizacji',
    full_number: 'calyNumer',
    general_content: 'trescOgolna',
    general_content_items: 'tresciOgolne',
    has_advertisements: 'maOgloszenia',
    has_draft: 'czyMaKopieRobocza',
    has_draft_advertisements: 'maRoboczeOgloszenia',
    has_published_advertisements: 'maOpublikowaneOgloszenia',
    headline: 'naglowek',
    help: 'pomoc',
    helps: 'pomoce',
    id: 'id',
    identification_number: 'numerIdentyfikacyjny',
    identification_number_type: 'typNumeruIdentyfikacyjnego',
    identifier: 'identyfikator',
    institution: 'instytucja',
    institutionNumber: 'numerInstytucji',
    is_admin: 'isAdmin',
    is_author: 'jestAutorem',
    is_created_by_advertiser: 'isCreatedByAdvertiser',
    is_mine: 'moje',
    isAdmin: 'isAdmin',
    is_protocol: 'jestProtokolem',
    isProtocol: 'jestProtokolem',
    is_refused: 'isRefused',
    is_variant: 'czyWariantowe',
    isVariant: 'czyWariantowe',
    last_publicated_version_id: 'ostatniaOpublikowanaWersjaId',
    label: 'label',
    last_version: 'ostatniaWersja',
    locality: 'miejscowosc',
    modified_at: 'dataModyfikacji',
    modified_by: 'ktoModyfikowal',
    name: 'nazwa',
    no_offer_selected: 'zadnaOfertaWybrana',
    noOfferSelected: 'zadnaOfertaWybrana',
    number: 'numer',
    number_description: 'numerDomu',
    order_node: 'zamowienieNode',
    order_nodes: 'zamowieniaNodes',
    order: 'zamowienie',
    orderNode: 'zamowienieNode',
    orderNodes: 'zamowieniaNodes',
    orders: 'zamowienia',
    offers: 'oferty',
    offerset: 'pakietOfert',
    offersets: 'pakietyOfert',
    order_id: 'zamowienieId',
    order_items: 'przedmiotyZamowienia',
    order_temporary_id: 'zamowienieTemporaryId',
    orders_amount: 'liczbaZamowien',
    original_content: 'trescOryginalna',
    page: 'strona',
    parent: 'rozdzialNadrzedny',
    partial_offer_allowed: 'czyDopuszczalnaOfertaCzesciowa',
    partial_payment: 'czyWystepujePlatnoscCzesciowa',
    participation_conditions: 'warunkiUdzialu',
    perspective: 'perspektywa',
    phone_number: 'numerTelefonu',
    planned_sign_date: 'planowanyTerminPodpisaniaUmowy',
    postcode: 'kodPocztowy',
    poviat: 'powiat',
    price: 'cena',
    price_criterion: 'czyKryteriumCenowe',
    priorities: 'osiePriorytetowe',
    priority: 'osPriorytetowa',
    programme: 'programOperacyjny',
    programmes: 'programyOperacyjne',
    project: 'projekt',
    projects: 'projekty',
    projects_enrollments: 'projektyNabory',
    publication_date: 'dataOpublikowania',
    question: 'pytanie',
    questions: 'pytania',
    refusedOfferSets: 'ofertyDoUsuniecia',
    region: 'region',
    regulations: 'regulaminy',
    relatives: 'powiazane',
    relatives_pointing_to_current: 'powiazaneDlaObecnego',
    screen: 'ekran',
    settlement: 'rozstrzygniecie',
    slug: 'slug',
    start_at: 'poczatekRealizacji',
    statement: 'komunikat',
    statements: 'komunikaty',
    status: 'status',
    street: 'ulica',
    subcategories: 'podkategorie',
    subcategory: 'podkategoria',
    submission_deadline: 'terminOfert',
    supplementary_orders: 'zamowieniaUzupelniajace',
    surname: 'nazwisko',
    tax_identification_number: 'nip',
    temporary_id: 'temporaryId',
    timetable_items: 'harmonogramy',
    title: 'tytul',
    terms_of_contract_change: 'warunkiZmianyUmowy',
    teryt_items: 'teryty',
    type: 'typ',
    user: 'uzytkownik',
    userEmail: 'emailUzytkownika',
    userForename: 'imieUzytkownika',
    userSurname: 'nazwiskoUzytkownika',
    users: 'uzytkownicy',
    warranty_period: 'okresGwarancji',
    variants: 'warianty',
    version: 'wersja',
    voivodeship: 'wojewodztwo',
    yellow_stripe: 'czyZoltyPasek',
    osoba: 'osoba',
    account_type: 'accountType',
}

// to server
const mapFrontServer = {
    adres: 'address',
    aktualnyIstnieje: 'currentExists',
    cena: 'price',
    czyAktywny: 'active',
    czyDopuszczalnaOfertaCzesciowa: 'partialOfferAllowed',
    czyKryteriumCenowe: 'priceCriterion',
    czyMaKopieRobocza: 'hasDraft',
    czyWariantowe: 'isVariant',
    czyWystepujePlatnoscCzesciowa: 'partialPayment',
    czyZoltyPasek: 'yellowStripe',
    daneAdresoweOgloszeniodawcy: 'advertiserAddressDetails',
    danePodmiotu: 'economicSubject',
    dataDodania: 'addedAt',
    dataModyfikacji: 'modifiedAt',
    dataOpublikowania: 'publicationDate',
    dataUtworzenia: 'createdAt',
    dataWaznosci: 'expirationDate',
    dataZablokowaniaKonta: 'accountDisabledAt',
    doPublikacjiIstnieje: 'forPublicationExists',
    dzialania: 'activities',
    dzialanie: 'activity',
    dzieci: 'children',
    ekran: 'screen',
    email: 'email',
    emailUzytkownika: 'userEmail',
    gmina: 'commune',
    harmonogramy: 'timetableItems',
    id: 'id',
    identyfikator: 'identifier',
    imie: 'forename',
    imieUzytkownika: 'userForename',
    instytucja: 'institution',
    isAdmin: 'isAdmin',
    jestAutorem: 'isAuthor',
    jestProtokolem: 'isProtocol',
    kategoria: 'category',
    kod: 'code',
    kodyCPV: 'cpvItems',
    kodPocztowy: 'postcode',
    komentarz: 'comment',
    komunikat: 'statement',
    komunikaty: 'statements',
    koniecRealizacji: 'endAt',
    kontoZablokowane: 'accountDisabled',
    kraj: 'country',
    kryteriaOceny: 'evaluationCriteria',
    ktoModyfikowal: 'modifiedBy',
    ktoUtworzyl: 'createdBy',
    label: 'label',
    liczbaZamowien: 'ordersAmount',
    miejscaRealizacji: 'fulfillmentPlaces',
    miejscowosc: 'locality',
    nabor: 'enrollment',
    naglowek: 'headline',
    nazwa: 'name',
    nazwisko: 'surname',
    nazwiskoUzytkownika: 'userSurname',
    nip: 'taxIdentificationNumber',
    numer: 'number',
    numerDomu: 'numberDescription',
    numerIdentyfikacyjny: 'identificationNumber',
    numerNaboru: 'enrollmentNumber',
    odpowiedz: 'answer',
    oferty: 'offers',
    ofertyDoUsuniecia: 'refusedOfferSets',
    ogloszenia: 'advertisements',
    ogloszenie: 'advertisement',
    okresGwarancji: 'warrantyPeriod',
    opis: 'description',
    osiePriorytetowe: 'priorities',
    osPriorytetowa: 'priority',
    osoba: 'osoba',
    osobyDoKontaktu: 'contactPersons',
    pakietOfert: 'offerset',
    perspektywa: 'perspective',
    planowanyTerminPodpisaniaUmowy: 'plannedSignDate',
    plik: 'file',
    plikDoWgrania: 'fileToLoad',
    poczatekRealizacji: 'startAt',
    podkategoria: 'subcategory',
    podkategorie: 'subcategories',
    pomoc: 'help',
    pomoce: 'helps',
    powiat: 'poviat',
    powiazane: 'relatives',
    powiazaneDlaObecnego: 'relativesPointingToCurrent',
    programOperacyjny: 'programme',
    programyOperacyjne: 'programmes',
    projekt: 'project',
    projekty: 'projects',
    przedmiotyZamowienia: 'orderItems',
    pytania: 'questions',
    pytanie: 'question',
    region: 'region',
    regulaminy: 'regulations',
    rodzajAdresu: 'addressType',
    rozdzialNadrzedny: 'parent',
    slug: 'slug',
    slowniki: 'dictionaries',
    status: 'status',
    strona: 'page',
    szacunkowaWartosc: 'estimatedValue',
    numerInstytucji: 'institutionNumber',
    numerTelefonu: 'phoneNumber',
    temporaryId: 'temporaryId',
    terminOfert: 'submissionDeadline',
    teryty: 'terytItems',
    tresc: 'content',
    trescOgolna: 'generalContent',
    tresciOgolne: 'generalContentItems',
    trescOryginalna: 'originalContent',
    typ: 'type',
    typNumeruIdentyfikacyjnego: 'identificationNumberType',
    tytul: 'title',
    ulica: 'street',
    uzytkownicy: 'users',
    warianty: 'variants',
    warunkiUdzialu: 'participationConditions',
    warunkiZmianyUmowy: 'termsOfContractChange',
    wojewodztwo: 'voivodeship',
    wybranyWariant: 'chosenOfferVariant',
    zadnaOfertaWybrana: 'noOfferSelected',
    zamowieniaUzupelniajace: 'supplementaryOrders',
    zamowienia: 'orders',
    zamowieniaNodes: 'orderNodes',
    zamowienie: 'order',
    zamowienieId: 'orderId',
    zamowienieNode: 'orderNode',
    zamowienieTemporaryId: 'orderTemporaryId',
    zalaczniki: 'attachments',
    accountType: 'account_type',
}


const serializeObject = (object, toServer=false, nonRecursiveKeys=[], dateWithTime=false) => {
    // old serializer; don't use it with a new functionality
    const serializationDct = toServer ? mapFrontServer : mapServerFront;
    let serializedObject = {};
    for (let attrName of Object.keys(object)) {
        const value = object[attrName];
        const serializedName = serializationDct[attrName] || attrName;
        if (nonRecursiveKeys && nonRecursiveKeys.includes(toServer ? attrName : serializedName)) {
            serializedObject[serializedName] = value || (value === false ? value : (toServer ? null : ''));
        } else if (Array.isArray(value)) {
            let serializedObjectList = serializeObjectList(value, toServer, nonRecursiveKeys, dateWithTime);
            serializedObject[serializedName] = serializedObjectList;
        } else if ((!toServer && ['created_at', 'end_at', 'modified_at',
                    'planned_sign_date', 'publication_date', 'start_at',
                    'submission_deadline'].includes(attrName))
                    || (toServer && ['dataUtworzenia', 'dataModyfikacji',
                    'dataOpublikowania', 'koniecRealizacji', 'poczatekRealizacji',
                    'planowanyTerminPodpisaniaUmowy', 'terminOfert'].includes(attrName))) {
            // serialize date
            if (['terminOfert', 'submission_deadline'].includes(attrName)) {
                let date_ = serializeDate(value, toServer, true);
                if (attrName === 'submission_deadline' && !!date_) {
                    date_ = date_.replace(' 23:59:59', '');
                }
                serializedObject[serializedName] = date_;
            } else {
                serializedObject[serializedName] = serializeDate(value, toServer, dateWithTime);
            }
        } else if (typeof value === 'object' && value !== null) {
            serializedObject[serializedName] = serializeObject(value, toServer, nonRecursiveKeys, dateWithTime);
        } else {
            serializedObject[serializedName] = value || (value === false ? value : (toServer ? null : ''));
        }
    }
    return serializedObject
};

const getSerializedObject = (object, config=null) => {
    config = config || {};
    const toServer = config['toServer'] || false;
    const mapToCamelCaseName = config['mapToCamelCaseName'] || false;
    const doNotTranslateNames = config['doNotTranslateNames'] || false;
    const serializationDct = toServer ? mapFrontServer : mapServerFront;
    const nonRecursiveKeys = config['nonRecursiveKeys'] || [];
    // all date (and datetime) fields add here:
    const dateAttributes = config['dateAttributes'] || [];
    // only datetime fields add here:
    const dateTimeAttributes = config['dateTimeAttributes'] || [];
    // add here datetime attributes if you want to cut 23:59:59:
    const dateTimeToLastMinuteAttributes = config['dateTimeToLastMinuteAttributes'] || [];
    const removeTemporaryId = config['removeTemporaryId'] || false;

    let serializedObject = {};
    for (let attrName of Object.keys(object)) {
        if (removeTemporaryId && attrName === 'temporaryId') {
            continue
        }
        const value = object[attrName];
        let serializedName;
        if (doNotTranslateNames) {
            serializedName = attrName;
        } else if (mapToCamelCaseName) {
            serializedName = mapToCamelCase(attrName);
        } else {
            serializedName = serializationDct[attrName] || attrName;
        }
        if (nonRecursiveKeys.length && nonRecursiveKeys.includes(toServer ? attrName : serializedName)) {
            serializedObject[serializedName] = value || (value === false ? value : (toServer ? null : ''));
        } else if (Array.isArray(value)) {
            serializedObject[serializedName] = getSerializedList(value, config);
        } else if (dateAttributes.includes(attrName) || dateAttributes.includes(serializedName)) {
            // serialize date
            if (dateTimeAttributes.includes(attrName) || dateTimeAttributes.includes(serializedName)) {
                let date_ = getSerializedDate(value, toServer, true);
                if ((dateTimeToLastMinuteAttributes.includes(attrName)  || dateTimeToLastMinuteAttributes.includes(serializedName)) && !!date_) {
                    date_ = date_.replace(' 23:59:59', '');
                }
                serializedObject[serializedName] = date_;
            } else {
                serializedObject[serializedName] = getSerializedDate(value, toServer, false);
            }
        } else if (typeof value === 'object' && value !== null) {
            serializedObject[serializedName] = getSerializedObject(value, config);
        } else {
            serializedObject[serializedName] = value || (value === false ? value : (toServer ? null : ''));
        }
    }
    return serializedObject
}

const mapToCamelCase = (value) => {
    return value.split('_').map((part, index) => index > 0 ? part.charAt(0).toUpperCase() + part.slice(1) : part).join('')
};

const getSerializedDate = (value, toServer, withTime=false) => {
    if (toServer) {
        if (value === '') { return null }
    } else if (value === null) { return '' }
    return withTime ? value : value.slice(0, 10)
}

const getSerializedList = (objectList, config) => {
    if (!objectList.length) {return []}

    if (typeof objectList[0] !== 'object') {return objectList}

    if ((config || {})['addTemporaryId'] || false) {
        return objectList.map(object => Object.assign(
            getSerializedObject(object, config),
            {temporaryId: object.id}
        ))
    }
    return objectList.map(object => getSerializedObject(object, config))
}


const serializeJsonPathList = (list) => {
    let serializedList = [];
    for (let element of list) {
        if (element === undefined) {
            serializedList.push({});
        } else if (typeof element !== 'object') {
            serializedList.push(element);
        } else {
            serializedList.push(serializeJsonPathObject(element));
        }
    }
    return serializedList
};

const serializeJsonPathObject = (object) => {
    let serializedObject = {};
    for (let attrName of Object.keys(object)) {
        const value = object[attrName];
        if (Array.isArray(value)) {
            serializedObject[attrName] = serializeJsonPathList(value);
        } else {
            serializedObject[attrName] = value;
        }
    }
    return serializedObject
};


const findJsonPathErrors = (errors, path) => {
    for (let e of errors) {
        if (e.path === path) {
            return e.errors
        }
    }
    return []
};

const getSerializedNestedErrorsFromServer = (errors, data, attrName, pathPrefix, nestedFields) => {
    const attrBasePathPrefix = `${pathPrefix}${mapFrontServer[attrName]}`;
    let errors_ = {};
    const baseAttrErrors = findJsonPathErrors(errors, attrBasePathPrefix);
    if (baseAttrErrors.length) {
        errors_['errors'] = baseAttrErrors;
    }

    // iterate objects on list
    for (let i=0; i < data[attrName].length; i++) {
        const obj = data[attrName][i];
        let objectErrors = {}
        for (let subAttrName of Object.keys(obj)) {
            if (nestedFields.includes(subAttrName)) {
                objectErrors[subAttrName] = getSerializedNestedErrorsFromServer(
                    errors, obj, subAttrName, `${attrBasePathPrefix}.${i}.`, nestedFields);
            } else {
                const subAttrPathPrefix = `${attrBasePathPrefix}.${i}.${mapFrontServer[subAttrName]}`;
                const subAttrErrors = findJsonPathErrors(errors, subAttrPathPrefix);
                if (subAttrErrors.length) {
                    objectErrors[subAttrName] = subAttrErrors;
                }
            }
        }
        errors_[obj.temporaryId] = objectErrors;
    }
    return errors_
}

const getSerializedObjectErrorsFormServer = (errors, data, nestedFields=[], pathPrefix='$.') => {
    let serializedErrors = {};
    for (let attrName of Object.keys(data)) {
        if (nestedFields.includes(attrName)) {
            serializedErrors[attrName] = getSerializedNestedErrorsFromServer(
                errors, data, attrName, pathPrefix, nestedFields);
        } else if (typeof data[attrName] === 'object' && data[attrName] !== null) {
            serializedErrors[attrName] = getSerializedObjectErrorsFormServer(
                errors, data[attrName], nestedFields, `${pathPrefix}${mapFrontServer[attrName]}.`);
        } else {
            const attrPathPrefix = `${pathPrefix}${mapFrontServer[attrName]}`;
            const attrErrors = findJsonPathErrors(errors, attrPathPrefix);
            if (attrErrors.length) {
                serializedErrors[attrName] = attrErrors;
            }
        }
    }
    return serializedErrors
};

const getSerializedErrorsFromServer = (errors, config={}) => {
    // set config according to config in getSerializedObject function
    let serializedErrors = {};
    errors.forEach(error => jp.value(serializedErrors, error.path, error.errors));
    return getSerializedObject(serializeJsonPathObject(serializedErrors), config)
};

const serializeDate = (value, toServer, withTime=false) => {
    if (toServer) {
        if (value === '') { return null }
    } else if (value === null) { return '' }
    return withTime ? value : value.slice(0, 10)
}

const serializeObjectList = (objectList, toServer=false, nonRecursiveKeys=[], dateWithTime=false) => {
    if (!objectList.length) {return []}
    if (typeof objectList[0] !== 'object') {return objectList}
    return objectList.map(object => Object.assign(
        serializeObject(object, toServer, nonRecursiveKeys, dateWithTime),
        {temporaryId: object.id}
    ))
}


const ERRORS_SERVER_FRONT = {
    'credentials (mail and password) are required.': 'credentials (mail and password) are required.',
    'DateTime::__construct(): Failed to parse time string (some-bad-format) at position 0 (s): The timezone could not be found in the database': 'DateTime::__construct(): Failed to parse time string (some-bad-format) at position 0 (s): The timezone could not be found in the database',  // new advertisement
    'email is not unique': 'email is not unique',  // new user
    'missing parameters': 'missing parameters',  // new advertisement
    'missing params': 'missing params',  // new user
    'no results': 'no results',     // delete project
    'Number is not unique.': 'Istnieje w bazie projekt o takim numerze.',     // add project
    'Project not found': 'Nie ma takiego projektu.',     // delete project
    'Session ID is required': 'Session ID is required',
    'Session invalid.': 'Session invalid.',
    'User not found.': 'Nie ma takiego użytkownika.',
    'An empty file is not allowed.': 'Nie udało się wgrać pliku, ponieważ jest on pusty.',
    'Internal Server Error': 'Wystąpił błąd serwera.',
    'Unauthorized': 'Wystąpił problem z uwierzytelnieniem użytkownika.',
}


const translateErrorMessage = (message) => {
    return ERRORS_SERVER_FRONT[message] || message
}


const serializeOrderItemCategories = (categoriesList) => {
    let categories = {}, subcategories = {};
    for (let cat of categoriesList) {
        categories[cat.id] = cat.name;
        for (let subcat of cat.subcategories) {
            subcategories[subcat.id] = subcat.name;
        }
    }
    return [categories, subcategories]
}

function serializeUrlParams(params, serializationDict) {
    let serializedDict = {};
    for (let [key, val] of Object.entries(params)) {
        serializedDict[serializationDict[key] || key] = val;
    }
    return serializedDict
}


export {
    getSerializedObjectErrorsFormServer,
    getSerializedErrorsFromServer,
    getSerializedList,
    getSerializedObject,
    mapServerFront,
    serializeObject,
    serializeObjectList,
    serializeOrderItemCategories,
    serializeUrlParams,
    translateErrorMessage,
};


if (typeof module === 'object' && module.exports) {
    module.exports = {
        getSerializedObjectErrorsFormServer,
    };
}
