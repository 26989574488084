// USAGE:
// This component renders "outer" part of the table: table layout, header and body wrapper.
// It doesnt render <tbody></tbody> content.
// To fill in the table, please provide imports:
//  import { TableCustom } from '<path_to>/common';
//  import { DataTableCell, DataTableRow } from '@rmwc/data-table';
// and fill in the <DataTableRow/> as needed.
// Expample:
// <TableCustom tableHeaderList={['Col1 header', 'Col2 header', 'Col3 header']}>
//    <DataTableRow>
//        <DataTableCell>Col1 content</DataTableCell>
//        <DataTableCell>Col2 content</DataTableCell>
//        <DataTableCell>Col3 content</DataTableCell>
//    </DataTableRow >
// </TableCustom>

import React from 'react';
import {
    DataTable,
    DataTableBody,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell,
    DataTableRow,
} from '@rmwc/data-table';
import { Typography } from '@rmwc/typography';

const TableCustom = (props) => {
    const {
        children,
        tableHeaderList,
        ...otherProps
    } = props;

    return (
        <DataTable className="table table--full-width" {...otherProps}>
            <DataTableContent>
                <DataTableHead>
                    <DataTableRow>
                        {tableHeaderList.map(tableHeader => 
                            <DataTableHeadCell key={tableHeader}>
                                <Typography use="subtitle2" className="table__header">
                                    {tableHeader}
                                </Typography>
                            </DataTableHeadCell>
                        )}
                    </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                    {children}
                </DataTableBody>
            </DataTableContent>
        </DataTable>
    );
}

export { TableCustom };
