import React from 'react';

import { Message } from './Message';


const NotFoundPage = (props) => {
    return (
        <Message
            messageIcon="report_problem"
            messageTitle="Błąd 404" >
            Strona nie istnieje
        </Message>
    );
}

export { NotFoundPage };
