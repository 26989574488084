import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import {
    getSerializedErrorsFromServer,
    translateErrorMessage
} from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    CancelSaveButtonsRow,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Text,
    TextFieldCustom
} from '../../common';


const DEFAULT_DATA = {
    code: '',
    name: '',
};


export const ImplementationLevelForm = ({
    addApiUrlName,
    additionalSaveData,
    codeMaxLength = 2,
    editApiUrlName,
    originalData,   // original if exists
    parentFormLabel,
    titleFormLabel,
    onCancel,
    onSave
}) => {
    const isNew = originalData === null;
    const [data, setData] = useState(
        {...(isNew ? DEFAULT_DATA : originalData)});
    const [savingOn, setSavingOn] = useState(false);
    const [errors, setErrors] = useState({});
    const [fetchError, setFetchError] = useState(null);

    function handleChangeAttribute(name, value) {
        setData(prevValue => ({
            ...prevValue,
            [name]: value,
        }));
    }

    const { removePermission } = useContext(UserContext);

    useEffect(() => {
        if (!savingOn) { return }
        setErrors({});
        setFetchError(null);
        let xhrFetch = null;

        function callbackSuccess(responseData) {
            xhrFetch = null;
            onSave();
        }

        function callbackError(responseData) {
            xhrFetch = null;
            setFetchError(translateErrorMessage(responseData.message));
            setSavingOn(false);
        }

        function callbackIncorrectStatus(status) {
            xhrFetch = null;
            setFetchError(
                status === 403
                ? 'Nie masz uprawnień do zapisu elementów słownika poziomów wdrożenia.'
                : `Wystąpił nieoczekiwany błąd o kodzie ${status}.`);
            setSavingOn(false);
            if (status === 403) {
                removePermission('editingDictionaries');
            }
        }

        function callbackShowErrors(errors) {
            xhrFetch = null;
            setErrors(getSerializedErrorsFromServer(
                errors, {doNotTranslateNames: true}));
            setSavingOn(false);
        }

        xhrFetch = fetchApi(
            isNew ? API_URLS[addApiUrlName].path : API_URLS[editApiUrlName].getUrl(data.id),
            isNew ? 'POST' : 'PUT',
            {},
            {
                ...(isNew ? {...additionalSaveData, code: data.code} : {}),
                name: data.name,
            },
            callbackSuccess,
            callbackError,
            callbackIncorrectStatus,
            callbackShowErrors
        );

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [savingOn]); // eslint-disable-line react-hooks/exhaustive-deps

    const isCodeInvalid = (errors.code || []).length > 0;
    const isNameInvalid = (errors.name || []).length > 0;
    return (
        <DialogCustom
            ariaLabelledby="addDialogLabel"
            className="dialog"
            onClose={onCancel}
        >
            <h2 id="addDialogLabel" className="text--main-header">
                {isNew ? 'Dodawanie' : 'Edycja'} {titleFormLabel}
            </h2>

            {isNew
                ? (
                    <>
                        <FieldWithLabel
                            label="Kod"
                            tag="label"
                            labelFor="id_code"
                        >
                            <GridCustom fullwidth noWrap className="code-input-container">
                                {parentFormLabel && <Text accentHeader>{parentFormLabel}</Text>}
                                <TextFieldCustom
                                    aria-describedby={isCodeInvalid ? 'code_error' : null}
                                    aria-label={`Kod, maksymalna liczba znaków: ${codeMaxLength}. Wpisano ${data.code.length} znaków.`}
                                    aria-required={true}
                                    aria-valuemax={codeMaxLength}
                                    characterCount
                                    clearFieldContext="code"
                                    disabled={savingOn}
                                    fullwidth
                                    id="id_code"
                                    invalid={isCodeInvalid}
                                    maxLength={codeMaxLength}
                                    required
                                    value={data.code}
                                    onChange={ev => handleChangeAttribute(
                                        'code', ev.target.value
                                    )}
                                    onClear={ev => handleChangeAttribute('code', '')}
                                />
                            </GridCustom>
                            {isCodeInvalid && (
                                <ul id="code_error">
                                    {errors.code.map((e, i) =>
                                        <Text error key={i} tag="li">{e}</Text>
                                    )}
                                </ul>
                            )}
                        </FieldWithLabel>
                    </>
                )
                : (
                    <FieldWithLabel
                        label="Kod"
                        tag="label"
                        labelFor="id_code"
                    >
                        <GridCustom noWrap centerVertical className="code-input-container code-input-container--edition">
                            {parentFormLabel && <Text accentHeader className="code-input-container__first-part">{parentFormLabel}</Text>}
                            <Text accentHeader>{data.code}</Text>
                        </GridCustom>
                    </FieldWithLabel>
                )
            }
            <FieldWithLabel
                label="Nazwa"
                tag="label"
                labelFor="id_name"
            >
                <TextFieldCustom
                    aria-describedby={isCodeInvalid ? 'name_error' : null}
                    aria-label={`Kod, maksymalna liczba znaków: 1000. Wpisano ${data.name.length} znaków.`}
                    aria-required={true}
                    aria-valuemax={1000}
                    characterCount
                    clearFieldContext="name"
                    disabled={savingOn}
                    fullwidth
                    id="id_name"
                    invalid={isNameInvalid}
                    maxLength={1000}
                    required
                    value={data.name}
                    onChange={ev => handleChangeAttribute(
                        'name', ev.target.value
                    )}
                    onClear={ev => handleChangeAttribute('name', '')}
                />
                {isNameInvalid && (
                    <ul id="name_error">
                        {errors.name.map((e, i) =>
                            <Text error key={i} tag="li">{e}</Text>
                        )}
                    </ul>
                )}
            </FieldWithLabel>

            <CancelSaveButtonsRow
                disabled={savingOn}
                onCancel={onCancel}
                onSave={() => setSavingOn(true)} />
            {!!fetchError && <Text error>{fetchError}</Text>}
        </DialogCustom>
    )
};
