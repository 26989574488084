import React, {useRef, useState} from 'react';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import { MenuSurfaceAnchor, MenuSurface } from '@rmwc/menu';

const MoreOptionsButton = (props) => {
  const [open, setOpen] = useState(false); 
  const menuContentRef = useRef();
  const {
    children,
    className,
    menuAriaLabel,
    ...otherProps
  } = props;

  const handleOpen = () => {
    menuContentRef.current.focus();
  }

  return (
    <MenuSurfaceAnchor {...otherProps}>
      <Button
        aria-label={menuAriaLabel}
        onClick={e => {
          setOpen(!open);
          e.currentTarget.blur();
        }}
        unelevated
        className="more-options__btn" >
        <Icon aria-hidden="true" icon="more_horiz" className="more-options__icon" />
      </Button>
      <MenuSurface
        anchorCorner="bottomLeft"
        className={`more-options__menu ${className || ''}`}
        onClose={() => setOpen(false)}
        onOpen={handleOpen}
        open={open}
      >
        <>
          <div ref={menuContentRef} aria-hidden={true} tabIndex={0} />
          <nav role="dialog">
            {children}
          </nav>
        </>
      </MenuSurface>
    </MenuSurfaceAnchor>
  );
}

export { MoreOptionsButton };
