import React from 'react';
import { TabBar, Tab } from '@rmwc/tabs';
import {
    DataTable,
    DataTableBody,
    DataTableCell,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell,
    DataTableRow,
} from '@rmwc/data-table';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@rmwc/data-table/data-table.css';

import { EKRANY_DICT } from '../../../const';
import { UserContext } from '../../../context/user-context';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { Breadcrumps, ButtonStyled, GridCustom, Text } from '../../common';
import { BaseComponent } from '../common';
import { PomocUsun } from './PomocUsun';
import { TrescPreview } from './TrescPreview';


class Pomoce extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        this.setState({
            initializingOn: false,
            fetchError: '',
            pomoce: getSerializedObject(
                data, {nonRecursiveKeys: ['tresc', ]}).pomoce,
        });
    }

    handleChangeTypPomocy(typOgolna) {
        this.setState(
            {
                initializingOn: true,
                typOgolna,
                pomoce: [],
            },
            () =>  {
                this.props.history.push(`/zarzadzanie-trescia/pomoc${typOgolna === false ? '?typ=ekranowa' : ''}`);
                this.fetchInitialData()
            }
        )
    }

    handleRemovePomoc(id_) {
        this.setState(prevState => {
            return {
                pomocForRemoving: null,
                pomoce: prevState.pomoce.filter(p => p.id !== id_),
            }
        })
    }

    // helpers

    getAdditionalState(props) {
        const params = new URLSearchParams(props.location.search);
        return {
            previewOn: false,
            pomoce: [],
            typOgolna: params.get('typ') !== 'ekranowa',
            pomocForPreview: null,
            pomocForRemoving: null,
        }
    }

    setAdditionalHandlers() {
        this.handleRemovePomoc = this.handleRemovePomoc.bind(this);
    }

    getFetchUrl() {
        return API_URLS.helpAdmin.path
    }

    getFetchData() {
        let query = {};
        if (!this.state.typOgolna) {
            query['type'] = 'screen_assigned';
        }
        return query
    }

    getFetchError(message) {
        return `Nie udało się pobrać pomocy. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.context.removePermission('help');
    }

    // rendering
    renderHeader() {
        const typOgolna = this.state.typOgolna;
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__current">Pomoc</li>
                </Breadcrumps>
                <GridCustom fullwidth centerVertical>
                    <Text tag="h2" mainHeader>Pomoc</Text>
                    <ButtonStyled add onClick={() => this.props.history.push(`/zarzadzanie-trescia/pomoc/nowa`)}>Dodaj pomoc</ButtonStyled>
                </GridCustom>
                <TabBar>
                    <Tab disabled={typOgolna === true} onClick={() => this.handleChangeTypPomocy(true)}>Ogólna</Tab>
                    <Tab disabled={typOgolna === false} onClick={() => this.handleChangeTypPomocy(false)}>Na ekranie aplikacji</Tab>
                </TabBar>
            </>
        )
    }

    renderContent() {
        return (
            <>
                {this.renderList()}
                {this.state.previewOn && this.renderPreview()}
                {this.state.pomocForRemoving !== null && (
                    <PomocUsun
                        pomoc={this.state.pomocForRemoving}
                        typOgolna={this.state.typOgolna}
                        closePomocForRemoving={() => this.setState({pomocForRemoving: null})}
                        removePomoc={this.handleRemovePomoc}
                    />
                )}
            </>
        )
    }

    renderList() {
        if (!this.state.pomoce.length) {
            return <Text error role="alert">Nie dodano jeszcze żadnej pomocy {this.state.typOgolna ? 'ogólnej' : 'na ekranie aplikacji'}.</Text>
        }
        const typOgolna = this.state.typOgolna;

        const sort = (p1, p2) => {
            return p1.id > p2.id ? -1 : 1
        };

        return (
            <DataTable className="table">
                <DataTableContent>
                    <DataTableHead>
                        <DataTableRow>
                            <DataTableHeadCell>Nagłówek pomocy</DataTableHeadCell>
                            {!typOgolna && <DataTableHeadCell>Przypięta do ekranu</DataTableHeadCell>}
                            <DataTableHeadCell></DataTableHeadCell>
                        </DataTableRow>
                    </DataTableHead>
                    <DataTableBody>
                        {this.state.pomoce.sort(sort).map(p => (
                            <DataTableRow key={p.id}>
                                <DataTableCell>
                                    {typOgolna && !p.rozdzialNadrzedny && <Text info>Pierwsza!</Text>}
                                    <Text>{p.naglowek}</Text>
                                </DataTableCell>
                                {!typOgolna && (
                                    <DataTableCell className="table__width-needy-cell">
                                            {!p.ekran ? <Text error>Nieprzypięta do ekranu</Text> : <Text >{EKRANY_DICT[p.ekran].label}</Text>}
                                            <ButtonStyled lite disabled={!p.ekran} onClick={() => this.setState({previewOn: true, pomocForPreview: p})} noMargin>
                                                Podgląd na ekranie
                                            </ButtonStyled>
                                    </DataTableCell>
                                )}
                                <DataTableCell>
                                    <ButtonStyled remove onClick={() => this.setState({pomocForRemoving: p})}>Usuń</ButtonStyled>
                                    <ButtonStyled lite onClick={() => this.props.history.push(`/zarzadzanie-trescia/pomoc/${p.id}-${p.slug}/edytuj`)}>Edytuj</ButtonStyled>
                                    <ButtonStyled primary onClick={() => this.props.history.push(`/zarzadzanie-trescia/pomoc/${p.id}-${p.slug}`)}>Szczegóły</ButtonStyled>
                                </DataTableCell>
                            </DataTableRow>
                        ))}
                    </DataTableBody>
                </DataTableContent>
            </DataTable>
        )
    }

    renderPreview() {
        const pomoc = this.state.pomocForPreview;
        if (!pomoc.ekran) { return null }
        return (
            <TrescPreview
                content={{naglowek: pomoc.naglowek, tresc: pomoc.tresc}}
                screen={EKRANY_DICT[pomoc.ekran].id}
                closePreview={() => this.setState({previewOn: false, pomocForPreview: null})}
            />
        )
    }
}

Pomoce.contextType = UserContext;

export { Pomoce };
