import React from 'react';

import { BasePytaniaForOgloszenieWidthPdf } from './BasePytaniaForOgloszenieWidthPdf';
import { FormOdpowiedz } from './FormOdpowiedz';
import { Pytanie } from './Pytanie';
import { Container, Text } from '../../common';


class PytaniaForOgloszenieAnnouncementOwner extends BasePytaniaForOgloszenieWidthPdf {

    handleUpdate = (id, data) => {
        const { odpowiedz, status, tresc } = data;
        this.setState(
            prevState => ({
                answeredQuestionId: null,
                pytania: prevState.pytania.map(q => {
                    if (q.id === id) {
                        q = Object.assign({}, q, {
                            tresc: tresc,
                            status: status,
                            odpowiedz: {
                                id: odpowiedz.id,
                                tresc: odpowiedz.tresc
                            }
                        });
                    }
                    return q
                }),
            })
        );
    }

    handleShowAnswerForm = (id) => {
        this.setState({answerFormOn: true, answeredQuestionId: id});
    }

    // helpers

    getAdditionalState(props) {
        return {
            pytania: [],
            answerFormOn: false,
            answeredQuestionId: null,
            pdfCreatingOn: false,
        }
    }

    // rendering

    renderContent() {
        const pytania = this.state.pytania;

        if (!pytania.length) {
            return <Text info>Nie zadano żadnego pytania.</Text>
        }

        return (
            <>
                <Container className="questions-container">
                    {this.renderPrintButton()}
                    <Text tag="h3" mainHeader>Zadane pytania</Text>
                    <Text info>Liczba wyników: {pytania.length}</Text>
                    {this.state.answerFormOn && this.renderAnswerForm()}
                    <ul>
                        {pytania.map((q) => {
                            return (
                                <Pytanie
                                    allowAnswer={this.props.ogloszenie.ogloszenie.status.label === 'PUBLISHED'}
                                    key={q.id}
                                    question={q}
                                    annoucementOwner
                                    onClickAnswer={this.handleShowAnswerForm}
                                    reloadAnnouncement={this.props.reloadAnnouncement} />
                            )
                        })}
                    </ul>
                </Container>

            </>
        )
    }

    renderAnswerForm(pytanie) {
        if (this.state.answeredQuestionId === null) { return null };

        const answeredQuestion = this.state.pytania.filter(q => q.id === this.state.answeredQuestionId)[0]
        if ([null, undefined].includes(answeredQuestion)) { return null };
        return (
            <FormOdpowiedz
                pytanie={answeredQuestion}
                onCloseForm={() => this.setState({answerFormOn: false, answeredQuestionId: null})}
                onUpdate={this.handleUpdate} />
        )
    }
}

export { PytaniaForOgloszenieAnnouncementOwner };
