import React, { useState } from 'react';

import { PROJECTS_MANAGEMENTS_CHOICES } from '../../../const';
import { serializePerspectiveForSelect } from '../../../helpers';
import { useOneTimeFetchApi } from '../../../hooks';
import { API_URLS } from '../../../urls/api';
import {
    ButtonStyled,
    FieldWithLabel,
    GridCustom,
    Loader,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { ProjectNumberSelectsWithPerspectives } from '../common';
import { UserDataFilters } from './UserDataFilters';


export const ProjectsFilters = (props) => {
    const [filters, setFilters] = useState(props.filters);
    const {data, loadingOn, fetchError} = useOneTimeFetchApi({
        url: API_URLS.perspectives.path,
        fetchData: {mode: 'projects_enrollments'},
        serializeFetchDataFunction: serializePerspectiveForSelect,
    });

    function handleChangeFilter(name, value) {
        setFilters({...filters, [name]: value});
    }

    function handleApplyFilters() {
        props.onUpdateFilters(filters);
    }

    function handleChangeProjectNumberSelect(data) {
        setFilters({...filters, ...data});
    }

    return loadingOn ? <Loader /> : (fetchError
        ? (
            <>
                <Text error>{fetchError}</Text>
                <ButtonStyled cancel onClick={props.onCloseFilters}>OK</ButtonStyled>
            </>
        )
        : (
            <>
                <section className="filters-dialog__filters-section">
                    <Text>Informacje o użytkowniku</Text>
                    <UserDataFilters
                        userEmail={filters.userEmail}
                        userForename={filters.userForename}
                        userSurname={filters.userSurname}
                        onChangeFilter={handleChangeFilter} />
                    <Text>Informacje o projekcie lub naborze</Text>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel
                            label="Kontekst"
                            tag="label"
                            selectRef={React.createRef()} tabIndex="-1">
                            <Select
                                aria-label="Kontekst"
                                className="select-custom"
                                screenReaderStatus={() => {return ''}}
                                options={PROJECTS_MANAGEMENTS_CHOICES.map(s => ({value: s[0], label: s[1]}))}
                                styles={customSelectStyles}
                                value={{value: filters.type.value || PROJECTS_MANAGEMENTS_CHOICES[0][0], label: filters.type.label || PROJECTS_MANAGEMENTS_CHOICES[0][1]}}
                                onChange={(selectedOption) => handleChangeFilter('type', selectedOption)} />
                        </FieldWithLabel>
                        <FieldWithLabel
                            label="Numer"
                            tag="label"
                            labelFor="filters_fullNumber_id"
                        >
                            <TextFieldCustom
                                aria-label="Numer"
                                className="filters__input"
                                clearFieldContext="numer"
                                id="filters_fullNumber_id"
                                value={filters.fullNumber}
                                onChange={ev => handleChangeFilter('fullNumber', ev.target.value)}
                                onClear={ev => handleChangeFilter('fullNumber', '')} />
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel
                            label="Tytuł"
                            tag="label"
                            labelFor="filters_name_id"
                        >
                            <TextFieldCustom
                                aria-label="Tytuł"
                                className="filters__input"
                                clearFieldContext="tytuł"
                                id="filters_name_id"
                                value={filters.name}
                                onChange={ev => handleChangeFilter('name', ev.target.value)}
                                onClear={ev => handleChangeFilter('name', '')} />
                        </FieldWithLabel>
                    </GridCustom>
                    <ProjectNumberSelectsWithPerspectives
                        activity={filters.activity}
                        activityErrors={[]}
                        disabled={false}
                        institution={filters.institution}
                        institutionErrors={[]}
                        isClearable={true}
                        mode="projects_enrollments"
                        perspective={filters.perspective}
                        perspectiveErrors={[]}
                        perspectives={data.perspectives}
                        perspectivesAsSelect={true}
                        priority={filters.priority}
                        priorityErrors={[]}
                        programme={filters.programme}
                        programmeErrors={[]}
                        region={filters.region}
                        regionErrors={[]}
                        withInstitutionNumber={false}
                        onChangeActivity={handleChangeProjectNumberSelect}
                        onChangeInstitution={handleChangeProjectNumberSelect}
                        onChangePerspective={handleChangeProjectNumberSelect}
                        onChangePriority={handleChangeProjectNumberSelect}
                        onChangeProgramme={handleChangeProjectNumberSelect}
                        onChangeRegion={handleChangeProjectNumberSelect} />
                </section>
                <GridCustom flexEnd>
                    <ButtonStyled cancel onClick={props.onCloseFilters}>Anuluj</ButtonStyled>
                    <ButtonStyled primary onClick={handleApplyFilters}>Zastosuj kryteria</ButtonStyled>
                </GridCustom>
            </>
        )
    )
};
