import React from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { getLastTemporaryId, getSortedOrderItemsFromOrder,
         isNotEmpty } from '../../../helpers';
import {
    ButtonStyled,
    DialogCustom,
    GridCustom,
    Steps,
    Text,
} from '../../common';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { AlreadyPublishedInfo } from './AlreadyPublishedInfo';
import { BaseStepComponent } from './BaseStepComponent';
import { FormPrzedmiotZamowienia } from './FormPrzedmiotZamowienia';
import { PrzedmiotZamowienia } from './PrzedmiotZamowienia';


class StepPrzedmiotZamowienia extends BaseStepComponent {
    stepName = 'przedmiot';

    constructor(props) {
        super(props);
        this.state = {
            formPrzedmiotZamowieniaOn: false,
            addedPrzedmiotZamowieniaId: null,
            editedPrzedmiotZamowieniaId: null,
        };
    }

    // handlers

    handleCloseForm = () => {
        this.setState(
            {
                formPrzedmiotZamowieniaOn: false,
                addedPrzedmiotZamowieniaId: null,
                editedPrzedmiotZamowieniaId: null,
            },
            () => {
                if (window.formOgloszenie) { window.formOgloszenie.computeProgress()}
            }
        );
    }

    handleSave = (data) => {
        const isNew = this.state.editedPrzedmiotZamowieniaId === null;
        this.context.savePrzedmiotZamowienia(
            data,
            isNew,
            () => this.setState(
                {
                    formPrzedmiotZamowieniaOn: false,
                    addedPrzedmiotZamowieniaId: isNew ? data.temporaryId : null,
                    editedPrzedmiotZamowieniaId: null,
                }
            )
        )
    }

    handleEditClick = (temporaryId) => {
        this.setState({
            formPrzedmiotZamowieniaOn: true,
            editedPrzedmiotZamowieniaId: temporaryId,
        });
    }

    handleRemovePrzedmiotZamowienia = (temporaryId) => {
        this.context.removePrzedmiotZamowienia(temporaryId);
    }

    // rendering

    render() {
        const { isAlreadyPublished, isPreview } = this.props;
        const przedmiotZamowieniaError = this.context.formErrors.ogloszenie.przedmiotyZamowienia;
        const o = this.context.ogloszenie;
        const przedmiotyZamowienia = isPreview ? [] : getSortedOrderItemsFromOrder(o.zamowienia);

        const formLocked = this.props.isPreview || this.context.validationOn || this.context.savingOn;
        return (
            <>
                <PomocEkranowa ekran="form_announcement_item" content={this.props.pomocEkranowaContent} />
                {isAlreadyPublished && <AlreadyPublishedInfo />}
                {!isAlreadyPublished && (
                    <GridCustom flexEnd>
                        <ButtonStyled
                            add
                            className="btn btn--main"
                            disabled={formLocked}
                            id="dodaj_przedmiotZamowienia_button_id"
                            onClick={(e) => {
                                this.setState({formPrzedmiotZamowieniaOn: true});
                                e.currentTarget.blur();
                            }} >
                            Dodaj nowy przedmiot zamówienia
                        </ButtonStyled>
                    </GridCustom>
                )}
                <Text tag="h2" sectionHeader>Lista przedmiotów zamówienia</Text>
                <Text info>Liczba wyników: {przedmiotyZamowienia.length}</Text>
                <ul>
                    {przedmiotyZamowienia.map(pz => {
                        return <PrzedmiotZamowienia
                            formLocked={formLocked}
                            isAlreadyPublished={isAlreadyPublished}
                            key={pz.temporaryId}
                            przedmiotZamowienia={pz}
                            onEditClick={this.handleEditClick}
                            onRemoveClick={this.handleRemovePrzedmiotZamowienia} />
                    })}
                </ul>
                {!isNotEmpty(przedmiotyZamowienia) && (przedmiotZamowieniaError || '').length > 0 && <Text error>{przedmiotZamowieniaError}</Text>}
                <Steps
                    disabled={formLocked}
                    elementToFocusRef={this.props.elementToFocusRef}
                    hasNext
                    nextLabel="Terminy"
                    onNextClick={() => this.props.onChangeCurrentMenuLink('terminy')}
                />
                {this.state.addedPrzedmiotZamowieniaId !== null && o.czyDopuszczalnaOfertaCzesciowa && o.zamowienia.length > 1 && (
                    <DialogCustom 
                        className="dialog"
                        dialogTitleError='Przedmiot zamówienia został dodany do pierwszej części zamówienia. Można to zmienić w sekcji "Części i kryteria".'
                        style={{ zIndex: 100 }}
                        onClose={() => this.setState({ addedPrzedmiotZamowieniaId: null })}
                    >
                        <GridCustom centerVertical flexEnd>
                            <ButtonStyled onClick={() => this.setState({ addedPrzedmiotZamowieniaId: null })} primary>OK</ButtonStyled>
                        </GridCustom>
                    </DialogCustom>
                )}
                {this.state.formPrzedmiotZamowieniaOn && this.renderPrzedmiotZamowieniaForm(przedmiotyZamowienia)}
            </>
        )
    }

    renderPrzedmiotZamowieniaForm(przedmiotyZamowienia) {
        let przedmiotZamowieniaData = {};
        const { editedPrzedmiotZamowieniaId } = this.state;
        const pzLength = przedmiotyZamowienia.length;
        const zamowienia = this.context.ogloszenie.zamowienia;

        if (editedPrzedmiotZamowieniaId === null) {
            przedmiotZamowieniaData['temporaryId'] = pzLength ? Math.max(...przedmiotyZamowienia.map(pz => pz.temporaryId)) + 1 : 1;
            przedmiotZamowieniaData['zamowienieTemporaryId'] = zamowienia.length ? Math.min(...zamowienia.map(z => z.temporaryId)) : 1;
        } else {
            for (let pz of przedmiotyZamowienia) {
                if (pz.temporaryId === editedPrzedmiotZamowieniaId) {
                    przedmiotZamowieniaData = pz;
                    break;
                }
            }
        }
        const isAlreadyPublished = this.props.isAlreadyPublished;
        return (
            <DialogCustom 
                className="dialog-long-content"
                dialogTitle={`${editedPrzedmiotZamowieniaId === null ? 'Dodaj' : 'Edytuj'} przedmiot zamówienia`}
                style={{ zIndex: 100 }}
                onClose={this.handleCloseForm}
            >
                <>
                    {isAlreadyPublished && <AlreadyPublishedInfo />}
                    <FormPrzedmiotZamowienia
                        isAlreadyPublished={isAlreadyPublished}
                        miejsceRealizacjiLastTemporaryId={getLastTemporaryId(
                            przedmiotyZamowienia, 'miejsceRealizacji')}
                        przedmiotZamowienia={przedmiotZamowieniaData}
                        onCloseForm={this.handleCloseForm}
                        onSave={this.handleSave}
                    />
                </>
            </DialogCustom>
        )
    }
}


StepPrzedmiotZamowienia.contextType = AdvertisementContext;


export { StepPrzedmiotZamowienia }
