import React from 'react';
import { Icon } from '@rmwc/icon';
import { Text } from'../../common';


export const InfoTextWithIcon = ({message, icon, ...props}) => {
    return (
        <Text role="status" className="success-text">
            <Icon icon={icon || 'error'} aria-hidden={true} />
            {message}
        </Text>
    )
}
