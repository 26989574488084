import React, { useEffect, useState } from 'react';

import { fetchApi } from '../../../fetch';
import {
    getSerializedErrorsFromServer,
    translateErrorMessage
} from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    CancelSaveButtonsRow,
    FieldWithLabel,
    GridCustom,
    Text,
    TextFieldCustom
} from '../../common';


const ADD_PERMISSION_MODE_CONTEXTS = {
    administration: ['contentAdministration', 'usersAdministration'],
    implementationLevel: ['adProjectEnrollmentAdministration'],
}


export const SearchUserForm = ({addPermissionMode, ...props}) => {
    const [searchingOn, setSearchingOn] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [identifier, setIdentifier] = useState('');

    function save() {
        if (!searchingOn) { return }
        let xhrFetch = null;

        function callbackSuccess(data) {
            xhrFetch = null;
            props.onFindUser(data.user || null);
        }

        function callbackFetchError(message) {
            xhrFetch = null;
            setFetchError(`Nie udało się wyszukać użytkownika. ${message}`);
            setSearchingOn(false);
        }

        function callbackError(data) {
            callbackFetchError(translateErrorMessage(data.message));
        }

        function callbackIncorrectStatus(status) {
            let message = '';
            switch (status) {
                case 403:
                    message = `Nie masz uprawnień do nadawania uprawnień w obszarze ${addPermissionMode === 'administration'
                        ? 'Administracji'
                        : 'Poziomów wdrożenia'
                    }.`;
                    break;
                case 404:
                    message = 'Użytkownik o podanych danych nie istnieje.';
                    break;
                default:
                    message = `Wystąpił nieoczekiwany błąd o kodzie ${status}.`;
            }
            callbackFetchError(message);
        }

        function callbackShowErrors(errors) {
            xhrFetch = null;
            setErrors(getSerializedErrorsFromServer(
                errors, {doNotTranslateNames: true}
            ));
            setSearchingOn(false);
        }

        setSearchingOn(true);
        setFetchError(null);
        setErrors({});
        let fetchData = {
            contexts: ADD_PERMISSION_MODE_CONTEXTS[addPermissionMode],
        };
        if (email.length) {
            fetchData['email'] = email;
        }
        if (identifier.length) {
            fetchData['identifier'] = identifier;
        }

        xhrFetch = fetchApi(
            API_URLS.usersFindUser.url, 'GET', {}, fetchData,
            callbackSuccess, callbackError, callbackIncorrectStatus,
            callbackShowErrors
        );
        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }
    useEffect(save, [searchingOn]);

    const isEmailInvalid = (errors.email || []).length > 0;
    const isIdentifierInvalid = (errors.identifier || []).length > 0;

    return (
        <>
            <Text>Informacje o użytkowniku</Text>
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Adres e-mail"
                    labelFor="email_id"
                    tag="label">
                    <TextFieldCustom
                        characterCount
                        disabled={searchingOn}
                        fullwidth
                        id="email_id"
                        invalid={isEmailInvalid}
                        maxLength={200}
                        value={email}
                        onChange={ev => setEmail(ev.target.value)}
                        onClear={ev => setEmail('')} />
                    {isEmailInvalid &&
                        <ul id="email_error">
                            {errors.email.map((e, i) =>
                                <Text error key={i} tag="li">{e}</Text>
                            )}
                        </ul>
                    }
                </FieldWithLabel>
                <FieldWithLabel
                    label="Identyfikator"
                    labelFor="identifier_id"
                    tag="label">
                    <TextFieldCustom
                        characterCount
                        disabled={searchingOn}
                        fullwidth
                        id="identifier_id"
                        invalid={isIdentifierInvalid}
                        maxLength={100}
                        value={identifier}
                        onChange={ev => setIdentifier(ev.target.value)}
                        onClear={ev => setIdentifier('')} />
                    {isIdentifierInvalid &&
                        <ul id="identifier_error">
                            {errors.identifier.map((e, i) =>
                                <Text error key={i} tag="li">{e}</Text>
                            )}
                        </ul>
                    }
                </FieldWithLabel>
            </GridCustom>
            <CancelSaveButtonsRow
                disabled={searchingOn}
                disabledSave={!email.length && ! identifier.length}
                saveText="Wybierz użytkownika"
                onCancel={props.onCancel}
                onSave={() => setSearchingOn(true)} />
            {fetchError !== null && <Text error className="dialog__global-error">{fetchError}</Text>}
            {(errors.allFields || []).length > 0 &&
                <ul id="form_error">
                    {errors.allFields.map((e, i) =>
                        <Text error key={i} tag="li">{e}</Text>
                    )}
                </ul>
            }
        </>
    )
};
