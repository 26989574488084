import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TabBar, Tab } from '@rmwc/tabs';

import { UserContext } from '../../../context/user-context';
import { useOneTimeFetchApi } from '../../../hooks';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { URLS } from '../../../urls/frontend';
import {
    Breadcrumps,
    Container,
    DialogCustom,
    Loader,
    NotPermittedPage,
    Text
} from '../../common';
import { PermissionList } from './PermissionList';
import { PermissionsHistory } from './PermissionsHistory';
import { UserContactPersonsList } from './UserContactPersonsList';
import { UserDataForm } from './UserDataForm';
import { UserEconomicSubjectData } from './UserEconomicSubjectData';
import { UserHeader } from './UserHeader';
import {
    UserPermissionsAdministrationForm
} from './UserPermissionsAdministrationForm';
import {
    UserPermissionsImplementationLevelForm
} from './UserPermissionsImplementationLevelForm';
import { UserPermissionsListRow } from './UserPermissionsListRow';
import {
    UserPermissionsRemovingPermissionDialog
} from './UserPermissionsRemovingPermissionDialog';


const TAB_INDEXES = {
    dane: 0,
    uprawnienia: 1,
};
const REVERTED_TAB_INDEXES = {
    0: 'dane',
    1: 'uprawnienia',
};
const ADMINISTRATION_INITIAL_REQUEST_ID = 1;
const IMPLEMENTATION_LEVEL_INITIAL_REQUEST_ID = 1;
const TABLE_HEADER_LIST = ['Nazwa', 'Uprawnienia', 'Działania'];

function serializeFetchData(data) {
    // it has to be serialized because some child components need like that
    return getSerializedObject(data)
}


export const User = (props) => {
    const [updatedUserData, setUpdatedUserData] = useState(null);
    const { id: id_ } = useParams();
    const [pageNotPermitted, setPageNotPermitted] = useState(false);

    const { removePermission } = useContext(UserContext);

    function callback403() {
        setPageNotPermitted(true);
        removePermission('usersAndPermissionsPreviewListUsers');
    }

    const {data, loadingOn, fetchError} = useOneTimeFetchApi({
        url: API_URLS.usersDetails.getUrl(id_),
        serializeFetchDataFunction: serializeFetchData,
        updatedData: updatedUserData,
        callback403,
    });
    const user = data === null ? {} : data.uzytkownik;

    const [selectedTab, setSelectedTab] = useState(TAB_INDEXES.dane);

    function setInitialTab() {
        const params = new URLSearchParams(props.history.location.search);
        const section = params.get('sekcja') || 'dane';
        setSelectedTab(TAB_INDEXES[section] || TAB_INDEXES.dane);
    }
    useEffect(setInitialTab, []);

    const [administrationFormOn, setAdministrationFormOn] = useState(false);
    const [
        implementationLevelFormOn,
        setImplementationLevelFormOn
    ] = useState(false);
    const [
        administrationPermissionsRequestId,
        setAdministrationPermissionsRequestId
    ] = useState(ADMINISTRATION_INITIAL_REQUEST_ID);
    const [
        implementationLevelPermissionsRequestId,
        setImplementationLevelPermissionsRequestId
    ] = useState(IMPLEMENTATION_LEVEL_INITIAL_REQUEST_ID);

    function handleChangeTab(ev) {
        const index = ev.detail.index
        setSelectedTab(index);
        props.history.push(
            `${window.location.pathname}?sekcja=${REVERTED_TAB_INDEXES[index] || REVERTED_TAB_INDEXES[0]}`);
    }

    function handleUpdateUserData(data) {
        setUpdatedUserData(data);
    }

    const [editedPermission, setEditedPermission] = useState(null);

    function handleOpenAdministrationForm() {
        setImplementationLevelFormOn(false);
        setAdministrationFormOn(true);
    }

    function handleCloseAdministrationForm() {
        setAdministrationFormOn(false);
        if (editedPermission !== null) {
            setEditedPermission(null);
        }
    }

    function handleOpenImplementationLevelForm() {
        setAdministrationFormOn(false);
        setImplementationLevelFormOn(true);
    }

    function handleCloseImplementationLevelForm() {
        setImplementationLevelFormOn(false);
        if (editedPermission !== null) {
            setEditedPermission(null);
        }
    }

    function handleSavePermissionSuccess(data, isAdministrationPermission) {
        if (isAdministrationPermission) {
            setAdministrationFormOn(false);
            setAdministrationPermissionsRequestId(prevId => prevId + 1);
        } else {
            setImplementationLevelFormOn(false);
            setImplementationLevelPermissionsRequestId(prevId => prevId + 1);
        }
        if (editedPermission !== null) {
            setEditedPermission(null);
        }
    }

    function handleSetEditedPermission(isImplementationLevelType, permission) {
        setEditedPermission(permission);
        if (isImplementationLevelType) {
            setImplementationLevelFormOn(true);
        } else {
            setAdministrationFormOn(true);
        }
    }

    const [removedPermission, setRemovedPermission] = useState(null);

    function handleSetRemovedPermission(isImplementationLevelType, permission) {
        setRemovedPermission({
            isImplementationLevelType,
            permission,
        });
    }

    function handleCloseRemovedPermissionDialog() {
        setRemovedPermission(null);
    }

    function handleRemovePermissionSuccess() {
        if (removedPermission.isImplementationLevelType) {
            setImplementationLevelPermissionsRequestId(
                prevId => prevId + 1);
        } else {
            setAdministrationPermissionsRequestId(
                prevId => prevId + 1);
        }
        setRemovedPermission(null);
    }

    const [
        administrationHistoryOn,
        setAdministrationHistoryOn
    ] = useState(false);
    const [
        implementationLevelHistoryOn,
        setImplementationLevelHistoryOn
    ] = useState(false);

    function handleOpenAdministrationHistory() {
        setImplementationLevelHistoryOn(false);
        setAdministrationHistoryOn(true);
    }

    function handleOpenImplementationLevelHistory() {
        setAdministrationHistoryOn(false);
        setImplementationLevelHistoryOn(true);
    }

    function handleCloseAdministrationHistory() {
        setAdministrationHistoryOn(false);
    }

    function handleCloseImplementationLevelHistory() {
        setImplementationLevelHistoryOn(false);
    }

    const permissionListAdditionalFetchData = useMemo(
        () => ({mode: 'modeUserCustom', authorized: id_}), [id_]);

    if (pageNotPermitted) {
        return <NotPermittedPage />
    }

    return (
        <>
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__link">
                    <Link to={URLS.users.path}>
                        Administracja użytkownikami i uprawnieniami
                    </Link>
                </li>
                <li className="breadcrumps__current">Użytkownik</li>
            </Breadcrumps>
            {loadingOn
                ? <Loader />
                : (fetchError
                    ? <Text error>{fetchError}</Text>
                    : (
                        <>
                            <Container>
                                <UserHeader user={user} />
                            </Container>
                            <TabBar
                                activeTabIndex={selectedTab}
                                onActivate={handleChangeTab}>
                                <Tab aria-label="Dane użytkownika, panel zakładek, lista 1 z 2" label="Dane użytkownika" />
                                <Tab aria-label="Uprawnienia, panel zakładek, lista 2 z 2" label="Uprawnienia" />
                            </TabBar>
                            {selectedTab === TAB_INDEXES.dane
                                ? (
                                    <>
                                        <UserDataForm
                                            user={user}
                                            onUpdateUserData={handleUpdateUserData} />
                                        <UserEconomicSubjectData
                                            economicSubject={user.danePodmiotu} />
                                        <UserContactPersonsList
                                            contactPersons={user.osobyDoKontaktu || []} />
                                    </>
                                ) : (
                                    <>
                                        <PermissionList
                                            additionalFetchData={permissionListAdditionalFetchData}
                                            initialRequestId={ADMINISTRATION_INITIAL_REQUEST_ID}
                                            isImplementationLevelType={false}
                                            hideAddPermissionButtonIfFullList={true}
                                            requestId={administrationPermissionsRequestId}
                                            RowComponent={UserPermissionsListRow}
                                            tableHeaderList={TABLE_HEADER_LIST}
                                            title="Administracja"
                                            url={API_URLS.usersPermissionsContentDictionariesUsers.path}
                                            withHistory={true}
                                            onOpenForm={handleOpenAdministrationForm}
                                            onOpenHistory={handleOpenAdministrationHistory}
                                            onSetEditedPermission={handleSetEditedPermission}
                                            onSetRemovedPermission={handleSetRemovedPermission} />
                                        <PermissionList
                                            additionalFetchData={permissionListAdditionalFetchData}
                                            initialRequestId={IMPLEMENTATION_LEVEL_INITIAL_REQUEST_ID}
                                            isImplementationLevelType={true}
                                            requestId={implementationLevelPermissionsRequestId}
                                            RowComponent={UserPermissionsListRow}
                                            tableHeaderList={TABLE_HEADER_LIST}
                                            title="Poziomy wdrożenia"
                                            url={API_URLS.usersPermissionsAdvertisementsProjectsEnrollments.path}
                                            withHistory={true}
                                            onOpenForm={handleOpenImplementationLevelForm}
                                            onOpenHistory={handleOpenImplementationLevelHistory}
                                            onSetEditedPermission={handleSetEditedPermission}
                                            onSetRemovedPermission={handleSetRemovedPermission} />
                                        {administrationFormOn && (
                                            <DialogCustom
                                                className="dialog-long-content"
                                                onClose={handleCloseAdministrationForm}>
                                                <UserPermissionsAdministrationForm
                                                    initialData={editedPermission}
                                                    userId={id_}
                                                    onCancel={handleCloseAdministrationForm}
                                                    onSavePermissionSuccess={
                                                        (data) => handleSavePermissionSuccess(data, true)
                                                    } />
                                            </DialogCustom>
                                        )}
                                        {implementationLevelFormOn && (
                                            <DialogCustom
                                                className="dialog-long-content"
                                                onClose={handleCloseImplementationLevelForm}>
                                                <UserPermissionsImplementationLevelForm
                                                    initialData={editedPermission}
                                                    userId={id_}
                                                    onCancel={handleCloseImplementationLevelForm}
                                                    onSavePermissionSuccess={
                                                        (data) => handleSavePermissionSuccess(data, false)
                                                    } />
                                            </DialogCustom>
                                        )}

                                        {removedPermission !== null && (
                                            <UserPermissionsRemovingPermissionDialog
                                                permission={removedPermission}
                                                userId={id_}
                                                onCloseRemovedPermissionDialog={handleCloseRemovedPermissionDialog}
                                                onRemovePermissionSuccess={handleRemovePermissionSuccess} />
                                        )}

                                        {administrationHistoryOn && (
                                            <PermissionsHistory
                                                isImplementationLevelType={false}
                                                mode="userHistory"
                                                tableHeaderList={['Data zmiany', "Nazwa uprawnienia", 'Rodzaj operacji', 'Użytkownik wprowadzający']}
                                                url={API_URLS.usersPermissionsHistoryContentDictionariesUsers.path}
                                                userId={id_}
                                                onClose={handleCloseAdministrationHistory} />
                                        )}

                                        {implementationLevelHistoryOn && (
                                            <PermissionsHistory
                                                isImplementationLevelType={true}
                                                mode="userHistory"
                                                tableHeaderList={['Data zmiany', "Nazwa poziomu", 'Rodzaj operacji', 'Użytkownik wprowadzający']}
                                                url={API_URLS.usersPermissionsHistoryAdvertisementsProjectsEnrollments.path}
                                                userId={id_}
                                                onClose={handleCloseImplementationLevelHistory} />
                                        )}
                                    </>
                                )
                            }
                        </>
                    )
                )
            }
        </>
    )
};
