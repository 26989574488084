import React from 'react';

import {
    RODZAJ_ADRESU_FILTERS_CHOICES,
    TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES
} from '../../../const';
import {
    FieldWithLabel,
    GridCustom,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { FormKraj, FormMiejscowosc } from '../common';


export const AdvertiserDataFilters = ({
    filters, onChangeAdvertiserIdentificationNumber, onChangeFilter,
    onChangePolishLocality, ...props
}) => {
    const { advertiserAddressType, advertiserIdentificationNumberType } = filters;
    const { value: advertiserAddressTypeValue } = advertiserAddressType;

    function handlePasteNumerIdentyfikacyjny(ev) {
         // stop data actually being pasted into input
        ev.stopPropagation();
        ev.preventDefault();

        // set pasted data via clipboard API
        const clipboardData = ev.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        onChangeAdvertiserIdentificationNumber(pastedData, true);
    }

    return (
        <>
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Nazwa"
                    labelFor="filters_advertiserName_id"
                    tag="label">
                    <TextFieldCustom
                        aria-label={`Nazwa, maksymalna liczba znaków: 100. Wpisano ${filters.advertiserName.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="nazwa"
                        id="filters_advertiserName_id"
                        maxLength={100}
                        value={filters.advertiserName}
                        onChange={ev => onChangeFilter('advertiserName', ev.target.value)}
                        onClear={ev => onChangeFilter('advertiserName', '')} />
                </FieldWithLabel>
            </GridCustom>

            <GridCustom fullwidth flexM className="filters-dialog__advertisement-filters-base">
                <FieldWithLabel
                    className="filters-dialog__advertisement-adress-type"
                    label="Rodzaj adresu"
                    selectRef={React.createRef()}
                    tag="label">
                    <Select
                        aria-label="Rodzaj adresu. Dalsze opcje filtrowania zostaną wyświetlone po wybraniu konkretnej opcji."
                        className="select-custom"
                        options={RODZAJ_ADRESU_FILTERS_CHOICES}
                        screenReaderStatus={() => {return ''}}
                        styles={customSelectStyles}
                        value={advertiserAddressType}
                        onChange={selectedOption =>
                            props.onChangeAdvertiserAddressType(selectedOption)
                        }
                    />
                </FieldWithLabel>
                {advertiserAddressTypeValue === 'poland' && (
                    <div className="filters-dialog__advertisement-adress-fields">
                        <FieldWithLabel
                            label="NIP/PESEL"
                            tag="label"
                            selectRef={React.createRef()}
                        >
                            <Select
                                aria-label="PESEL/NIP"
                                className="select-custom"
                                options={TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES}
                                screenReaderStatus={() => {return ''}}
                                styles={customSelectStyles}
                                value={advertiserIdentificationNumberType}
                                onChange={selectedOption =>
                                    props.onChangeAdvertiserIdentificationNumberType(selectedOption)
                                }
                            />
                            {advertiserIdentificationNumberType && advertiserIdentificationNumberType.value === 'all' && (
                                <Text info>Pole do wprowadzenia numeru pojawi się po wybraniu wartości NIP lub PESEL.</Text>
                            )}
                        </FieldWithLabel>
                        {['nip', 'pesel'].includes(advertiserIdentificationNumberType.value) && (
                            <FieldWithLabel
                                label={advertiserIdentificationNumberType.label}
                                labelFor="filters_advertiserIdentificationNumber_id"
                                tag="label">
                                <TextFieldCustom
                                    aria-describedby="advertiserIdentificationNumber_id"
                                    aria-label={`${advertiserIdentificationNumberType.label}, maksymalna liczba znaków: ${advertiserIdentificationNumberType.value === 'nip' ? 10 : 11}. Wpisano ${filters.advertiserIdentificationNumber.length} znaków.`}
                                    characterCount
                                    className="filters__input"
                                    clearFieldContext={advertiserIdentificationNumberType.label}
                                    id="filters_advertiserIdentificationNumber_poland"
                                    maxLength={advertiserIdentificationNumberType.value === 'nip' ? 10 : 11}
                                    value={filters.advertiserIdentificationNumber}
                                    onChange={ev => onChangeAdvertiserIdentificationNumber(ev.target.value, true)}
                                    onClear={ev => onChangeAdvertiserIdentificationNumber('')}
                                    onKeyUp={ev => onChangeAdvertiserIdentificationNumber(ev.target.value, true)}
                                    onPaste={handlePasteNumerIdentyfikacyjny} />
                                <Text info id="advertiserIdentificationNumber_poland">(dla podmiotów z Polski)</Text>
                            </FieldWithLabel>
                        )}
                    </div>
                )}
                {advertiserAddressTypeValue === 'abroad' && (
                    <FieldWithLabel
                        label="Numer identyfikacyjny"
                        labelFor="filters_advertiserIdentificationNumber_abroad"
                        tag="label">
                        <TextFieldCustom
                            aria-label={`Numer identyfikacyjny, maksymalna liczba znaków: 100. Wpisano ${filters.advertiserIdentificationNumber.length} znaków.`}
                            characterCount
                            className="filters__input"
                            clearFieldContext="numer identyfikacyjny"
                            id="filters_advertiserIdentificationNumber_id"
                            maxLength={100}
                            value={filters.advertiserIdentificationNumber}
                            onChange={ev => onChangeAdvertiserIdentificationNumber(ev.target.value)}
                            onClear={ev => onChangeAdvertiserIdentificationNumber('')} />
                        <Text info id="advertiserIdentificationNumber_abroad">(dla podmiotów zagranicznych)</Text>
                    </FieldWithLabel>
                )}
            </GridCustom>
            {advertiserAddressTypeValue === 'poland' && (
                <FormMiejscowosc
                    error=""
                    isClearable
                    invalid={false}
                    miejscowosc={filters.advertiserAddressLocality.length > 0
                        ? {
                            wojewodztwo: filters.advertiserAddressVoivodeship,
                            powiat: filters.advertiserAddressPoviat,
                            gmina: filters.advertiserAddressCommune,
                            miejscowosc: filters.advertiserAddressLocality,
                        }
                        : null
                    }
                    changeMiejscowosc={onChangePolishLocality} />
            )}
            {advertiserAddressTypeValue === 'abroad' && (
                <>
                    <FormKraj
                        error={''}
                        formLocked={false}
                        isClearable
                        invalid={false}
                        krajName={filters.advertiserAddressCountry}
                        changeKraj={selectedOption => onChangeFilter(
                            'advertiserAddressCountry',
                            selectedOption === null ? '' : selectedOption.label
                        )} />
                    <FieldWithLabel 
                        label="Miejscowość"
                        tag="label"
                        labelFor="filters_advertiserAddressLocality_id"
                    >
                        <TextFieldCustom
                            aria-label={`Miejscowość, maksymalna liczba znaków: 50. Wpisano ${filters.advertiserAddressLocality.length} znaków.`}
                            characterCount
                            className="filters__input"
                            clearFieldContext="miejscowość"
                            id="filters_advertiserAddressLocality_id"
                            maxLength={50}
                            value={filters.advertiserAddressLocality}
                            onChange={ev => onChangeFilter('advertiserAddressLocality', ev.target.value)}
                            onClear={ev => onChangeFilter('advertiserAddressLocality', '')} />
                    </FieldWithLabel>
                </>
            )}
        </>
    )
}
