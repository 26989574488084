import React from "react";
import { URLS } from "../../../urls/frontend";
import { useHistory } from "react-router-dom";
import { GridCustom, ButtonStyled, Container } from "../../common";
import { Typography } from "@rmwc/typography";
import { Icon } from "@rmwc/icon";

export const UnsubscribeSuccess = () => {
    const history = useHistory();
  
    const handleGoToMainPage = () => {
      history.push(URLS.home.path);
    };
  
    return (
      <Container>
        <GridCustom centerHorizontal>
          <Icon
            className="subscription-icon__success"
            icon={{ icon: "check_circle_outline" }}
            aria-hidden="true"
          />
        </GridCustom>
        <GridCustom fullheight centerHorizontal>
          <Typography
            use="headline3"
            tag="h3"
            style={{ textAlign: "center", margin: "2rem 0" }}
          >
            Newsletter i powiadomienia są wyłączone
          </Typography>
        </GridCustom>
        <GridCustom fullheight centerHorizontal>
          <Typography use="body1">
            Aby ponownie włączyć newsletter przejdź do{" "}
            <a href={URLS.newsletter.path}>edycji newslettera</a>.
          </Typography>
        </GridCustom>
        <GridCustom fullheight centerHorizontal>
          <Typography use="body1">
            Aby ponownie włączyć powiadomienia przejdź do{" "}
            <a href={`${URLS.newsletter.path}?sekcja=powiadomienia`}>
              edycji powiadomień
            </a>
            .
          </Typography>
        </GridCustom>
        <GridCustom fullheight centerHorizontal>
          <Typography use="body1" style={{ marginTop: "1rem" }}>
            Aby włączyć newsletter lub powiadomienia musisz być zalogowany.
          </Typography>
        </GridCustom>
        <GridCustom fullheight centerHorizontal>
          <ButtonStyled
            primary
            className="search__btn"
            onClick={handleGoToMainPage}
          >
            OK
          </ButtonStyled>
        </GridCustom>
      </Container>
    );
  };