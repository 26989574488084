import React from 'react';
import { LinearProgress } from '@rmwc/linear-progress';
import '@material/linear-progress/dist/mdc.linear-progress.css';


const LinearProgressCustom = (props) => {
    const {
        className,
        classNameInfo,
        progress
    } = props;

    let progressBarClassName = 'progress-bar ';
    className && (progressBarClassName += className);

    let progressBarInfoClassName = 'progress-bar__progress-info ';
    className && (progressBarInfoClassName += classNameInfo);

    return (
        <div className={progressBarClassName} >
            <p className={progressBarInfoClassName}>
                <span aria-hidden="true">0%</span>
                <span aria-hidden="true">100%</span>
            </p>
            <LinearProgress
                progress={progress}
                aria-valuemin={0}
                aria-valuemax={100}
                aria-valuenow={progress * 100}
            />
        </div>
    );
}

export { LinearProgressCustom };
