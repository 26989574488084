import React from 'react';
import { Link } from 'react-router-dom';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';
import { Icon } from '@rmwc/icon';

import { UserContext } from '../../../context/user-context';
import { getUsersFiltersCount, USERS_CLEANED_FILTERS } from '../../../filters';
import { API_URLS } from '../../../urls/api';
import { ButtonStyled, GridCustom, TableCustom, Text } from '../../common';
import { colors } from '../../vars/vars';
import { PaginatedBaseComponent } from '../common';
import { FiltryUzytkownikow } from './FiltryUzytkownikow';


class Uzytkownicy extends PaginatedBaseComponent {
    message403 = 'Nie masz uprawnień do przeglądania listy użytkowników.';

    componentDidMount() {
        if (!this.context.permissions.usersAndPermissionsPreviewListUsers) {
            window.scrollTo(0, 0);
            this.setState({
                initializingOn: false,
                pageNotPermitted : true,
            });
            return
        }
        super.componentDidMount();
    }

    // helpers

    preState(props) {
        super.preState(props);
        this.objectsName = 'uzytkownicy';
        this.paginateBy = 100;
        this.sortingValues = [
            ['identifier', 'Po identyfikatorze Użytkownika'],
            ['email', 'Po adresie e-mail'],
            ['forename', 'Po imieniu'],
            ['surname', 'Po nazwisku'],
            ['status', 'Po statusie'],
        ];
        this.sortingLabel = 'Sortuj użytkowników';
        this.filteringLabel = 'Filtruj listę';
        this.filtersComponentClass = FiltryUzytkownikow;

        this.getFiltersCount = getUsersFiltersCount;
    }

    getAdditionalState(props) {
        return {
            ...super.getAdditionalState(props),
            selectedSort: {value: this.sortingValues[3][0], label: this.sortingValues[3][1]},
            objects: {},
            filters: USERS_CLEANED_FILTERS,
        }
    }

    getFetchUrl() {
        return API_URLS.users.path
    }

    getFetchData() {
        let queryDict = super.getFetchData();

         // set filters values
        for (let [name, value] of Object.entries(this.state.filters)) {
            if (name === 'status') {
                queryDict[name] = value.value;
            } else if (value !== USERS_CLEANED_FILTERS[name]) {
                queryDict[name] = value.trim();
            }
        }

        return queryDict
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się wyszukać użytkowników. ${message}`
    }

     getFetchIncorrectStatusError(status) {
        return `Nie udało się wyszukać użytkowników. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.context.removePermission('usersAndPermissionsPreviewListUsers');
    }

    // rendering

    renderHeader() {
        return (
            <Text tag="h1" mainHeader>Użytkownicy Bazy Konkurencyjności</Text>
        )
    }

    renderFiltersButton() {
        return (
            <>
                {super.renderFiltersButton()}
                <GridCustom flexTop>
                    <a href='/konto/panel/uzytkownicy/' className="mdc-button mdc-button--unelevated btn--primary">Lista użytkowników CAS</a>
                </GridCustom>
            </>
        )
    }

    renderEmptyObjects() {
        return <Text error role="alert">Nie znaleziono użytkowników spełniających kryteria wyszukiwania.</Text>
    }

    renderList() {
        const total = this.state.total;
        return (
            <>
                <Text accentHeader tag="h2" role="status">
                    Znaleziono {total < this.paginateBy ? '' : 'około '}{total} {total === 1 ? 'użytkownika' : 'użytkowników'}.
                </Text>
                <TableCustom tableHeaderList={[
                    'Adres e-mail',
                    'Imię i nazwisko',
                    'Status, Identyfikator Użytkownika',
                    <span className="sr-only">Opcje</span>,
                ]}>
                    {this.state.objects.map(o => this.renderUzytkownik(o))}
                </TableCustom>
            </>
        )
    }

    renderUzytkownik(uzytkownik) {
        return (
            <DataTableRow key={uzytkownik.id}>
                <DataTableCell><Text>{uzytkownik.email}</Text></DataTableCell>
                <DataTableCell><Text>{uzytkownik.imie} {uzytkownik.nazwisko}</Text></DataTableCell>
                <DataTableCell>
                    {uzytkownik.kontoZablokowane ? <Text><Icon aria-hidden="true" icon="cancel" style={{ color: colors.textHintOnLight }} title="zablokowany" /> zablokowany</Text> : <Text><Icon aria-hidden="true" icon="check_circle" style={{ color: colors.secondary }} title="aktywny" /> aktywny</Text>}
                    <Text className="table__secondary-info">Id: {uzytkownik.identyfikator}</Text>
                </DataTableCell>
                <DataTableCell>
                    <ButtonStyled primary tag={Link} to={`/uzytkownicy/${uzytkownik.id}`}>Podgląd danych</ButtonStyled>
                </DataTableCell>
            </DataTableRow>
        )
    }
}

Uzytkownicy.contextType = UserContext;

export { Uzytkownicy };
