/*
<DataTableRow>
    <DataTableCell>
        <Text>Lista użytkowników</Text>
        <Text className="permissions__level-name"><b>EFGH - 01</b> Nazwa osi priorytetowej</Text>
        <Text className="permissions__sub-name">Wszystkie działania</Text>
    </DataTableCell>
    <DataTableCell>
        <Text className="permissions__main-name">Funkcjonalność - pełne uprawnienia</Text>
        <Text>Zarządzanie uprawnieniami</Text>
        <Text className="permissions__indented">Podgląd listy uprawnionych użytkowników</Text>
        <Text className="permissions__indented">Dla projektów i naborów</Text>
    </DataTableCell>
</DataTableRow>
*/

import React from 'react';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';

import {
    getImplementationLevelLabels,
    PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT
} from '../../../helpers_permissions';
import { ButtonStyled, Text } from '../../common';
import { UserPermissionsListGrantedCell } from './UserPermissionsListGrantedCell';


export const UserPermissionsListRow = ({
    isImplementationLevelType, mode = 'permissionData', permission,
    onSetEditedPermission, onSetRemovedPermission
}) => {
    /* possible modes:
        permissionData - set the first cell as permission name
        userData - set the first cell as user data (full name and email)
    */
    let implementationLevelLabels = {};
    if (mode==='permissionData' && isImplementationLevelType) {
        implementationLevelLabels = getImplementationLevelLabels(permission);
    }

    const { authorized: user = {} } = permission;
    return (
        <DataTableRow>
            <DataTableCell>
                {mode === 'permissionData' && isImplementationLevelType
                    ? (
                        <>
                            <Text className="permissions__level-name">
                                <b>{implementationLevelLabels.code}</b> {implementationLevelLabels.name}
                            </Text>
                            {implementationLevelLabels.description.length > 0 && (
                                <Text className="permissions__sub-name">
                                    {implementationLevelLabels.description}
                                </Text>
                            )}
                        </>
                    )
                    : <Text>{PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[permission.type]}</Text>
                }
                {mode === 'userData' && (
                    <>
                        <Text>{user.forename || ''} {user.surname || ''}</Text>
                        <Text className="permissions__sub-name">{user.email || ''}</Text>
                    </>
                )}
            </DataTableCell>
            <DataTableCell className="permissions__col-permissions-details">
                {isImplementationLevelType
                    ? <UserPermissionsListGrantedCell
                        groupNameList={[
                            'projectsEnrollmentsAdministration',
                            'advertisementAdministration',
                            'permissionsAdministration'
                        ]}
                        isImplementationLevelType={true}
                        permissions={permission.permissions} />
                    : <UserPermissionsListGrantedCell
                        groupNameList={
                            permission.type === 'usersAdministration'
                            ? [
                                'usersAdministration',
                                'permissionsAdministration'
                            ] : permission.type === 'contentAdministration'
                                ? [
                                    'contentAdministration',
                                    'permissionsAdministration'
                                ] : [
                                    'implementationLevelsDictionaryAdministration',
                                    'permissionsAdministration'
                                ]
                        }
                        isImplementationLevelType={false}
                        permissions={permission.permissions}
                    />
                }
            </DataTableCell>
            <DataTableCell className="permissions__col-buttons">
                {mode === 'userData' && permission.hasHigherLevelPermissions
                    ? <Text>Użytkownik ma uprawnienia wyższego poziomu</Text>
                    : (
                        <>
                            {permission.canEdit && (
                                <ButtonStyled
                                    lite
                                    onClick={() => onSetEditedPermission(
                                    isImplementationLevelType, permission)}
                                >
                                Edytuj
                                </ButtonStyled>
                            )}
                            {permission.canDelete && (
                                <ButtonStyled
                                    remove
                                    onClick={() => onSetRemovedPermission(
                                        isImplementationLevelType, permission
                                    )}>
                                    Usuń
                                </ButtonStyled>
                            )}
                        </>
                    )
                }
            </DataTableCell>
        </DataTableRow>
    )
};
