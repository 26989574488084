import React from 'react';
import {
    DataTable,
    DataTableBody,
    DataTableCell,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell,
    DataTableRow,
} from '@rmwc/data-table';

import { UserContext } from '../../../context/user-context';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { Breadcrumps, ButtonStyled, Text } from '../../common';
import { BaseComponent } from '../common';
import { TrescPreview } from './TrescPreview';


class TresciOgolne extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        this.setState({
            initializingOn: false,
            fetchError: '',
            tresciOgolne: getSerializedObject(
                data, {nonRecursiveKeys: ['tresc', ]}).tresciOgolne,
        });
    }

    // helpers

    getAdditionalState(props) {
        return {
            previewOn: false,
            tresciOgolne: [],
            trescForPreview: null,
        }
    }

    getFetchUrl() {
        return API_URLS.generalContentAdmin.path
    }

    getFetchError(message) {
        return `Nie udało się pobrać treści ogólnych. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania treści ogólnych wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__current">Treści ogólne</li>
                </Breadcrumps>
                <Text tag="h2" mainHeader>Treści ogólne</Text>
            </>
        )
    }

    renderContent() {
        return (
            <>
                <DataTable className="table">
                    <DataTableContent>
                        <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell>Nazwa treści</DataTableHeadCell>
                                <DataTableHeadCell></DataTableHeadCell>
                            </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                            {this.state.tresciOgolne.map(to => (
                                <DataTableRow key={to.id}>
                                    <DataTableCell>
                                        <Text>{to.naglowek}</Text>
                                    </DataTableCell>
                                    <DataTableCell>
                                        <ButtonStyled lite onClick={() => this.setState({previewOn: true, trescForPreview: to})}>Podgląd na ekranie</ButtonStyled>
                                        <ButtonStyled lite onClick={() => this.props.history.push(`/zarzadzanie-trescia/tresci-ogolne/${to.id}/edytuj`)}>Edytuj</ButtonStyled>
                                        <ButtonStyled primary onClick={() => this.props.history.push(`/zarzadzanie-trescia/tresci-ogolne/${to.id}`)}>Szczegóły</ButtonStyled>
                                    </DataTableCell>
                                </DataTableRow>
                            ))}
                        </DataTableBody>
                    </DataTableContent>
                </DataTable>
                {this.state.previewOn && this.renderPreview()}
            </>
        )
    }

    renderPreview() {
        return (
            <TrescPreview
                content={this.state.trescForPreview}
                screen='homePage'
                closePreview={() => this.setState({previewOn: false, trescForPreview: null})}
            />
        )
    }
}

TresciOgolne.contextType = UserContext;

export { TresciOgolne };
