
const ADVERTISEMENTS_SORTING_VALUES = [
    ['terminOfert_asc', 'termin składania ofert rosnąco'],
    ['terminOfert_desc', 'termin składania ofert malejąco'],
    ['ogloszenie_numer_asc', 'numer rosnąco'],
    ['ogloszenie_numer_desc', 'numer malejąco'],
    ['tytul_asc', 'tytuł rosnąco'],
    ['tytul_desc', 'tytuł malejąco'],
    ['ogloszenie_status_nazwa_asc', 'status rosnąco'],
    ['ogloszenie_status_nazwa_desc', 'status malejąco'],
];

const PROJECTS_SORTING_VALUES = [
    ['calyNumer_asc', 'numer rosnąco'],
    ['calyNumer_desc', 'numer malejąco'],
    ['nazwa_asc', 'nazwa rosnąco'],
    ['nazwa_desc', 'nazwa malejąco'],
];

const sortByAdvertisementNumber = (order, nr1, nr2) => {
    // if number mask will change, sort in other way
    const [year1, userId1, announcementId1] = nr1.split('-');
    const [year2, userId2, announcementId2] = nr2.split('-');
    if (year1 !== year2) {
        if (order === 'asc') {
             return year2 < year1 ? 1 : -1
        } else {
            return year2 > year1 ? 1 : -1
        }
    }
    if (userId1 !== userId2) {
        if (order === 'asc') {
             return parseInt(userId2) < parseInt(userId1) ? 1 : -1
        } else {
            return parseInt(userId2) > parseInt(userId1) ? 1 : -1
        }
    }
    if (order === 'asc') {
         return parseInt(announcementId2) < parseInt(announcementId1) ? 1 : -1
    } else {
        return parseInt(announcementId2) > parseInt(announcementId1) ? 1 : -1
    }
}

const sortAdvertisements = (advertisementList, sortingValue) => {
    return advertisementList.slice().sort((o1, o2) => {
            const splittingValue = sortingValue.split('_');
            let value1, value2, order, attribbuteName;
            if (splittingValue.length === 4) {
                attribbuteName = splittingValue[2];
                value1 = o1[splittingValue[0]][splittingValue[1]][attribbuteName];
                value2 = o2[splittingValue[0]][splittingValue[1]][attribbuteName];
                order = splittingValue[3];
            } else if (splittingValue.length === 3) {
                attribbuteName = splittingValue[1];
                value1 = o1[splittingValue[0]][attribbuteName];
                value2 = o2[splittingValue[0]][attribbuteName];
                order = splittingValue[2];
            } else {
                attribbuteName = splittingValue[0];
                value1 = o1[attribbuteName];
                value2 = o2[attribbuteName];
                order = splittingValue[1];
            }
            if ([null, ''].includes(value1)) {return 1}
            if ([null, ''].includes(value2)) {return -1}
            if (attribbuteName === 'numer') {
                return sortByAdvertisementNumber(order, value1, value2)
            }
            if (attribbuteName === 'tytul') {
                if (order === 'asc') {
                    return value1.toLowerCase().localeCompare(value2.toLowerCase(), 'pl')
                }
                return value2.toLowerCase().localeCompare(value1.toLowerCase(), 'pl')
            }
            if (order === 'asc') {
                return value2 < value1 ? 1 : -1
            }
            return value2 > value1 ? 1 : -1
        })
};

const sortProjects = (projectList, sortingValue) => {
    const p = projectList.slice().sort((p1, p2) => {
        const [attribbuteName, order] = sortingValue.split('_');
        const value1 = p1[attribbuteName];
        const value2 = p2[attribbuteName];
        if ([null, ''].includes(value1)) {return 1}
        if ([null, ''].includes(value2)) {return -1}
        if (order === 'asc') {
            return value1.toLowerCase().localeCompare(value2.toLowerCase(), 'pl')
        }
        return value2.toLowerCase().localeCompare(value1.toLowerCase(), 'pl')
    })
    return p
};

// search filters
const sortProgrammeFilter = (value1, value2) => {
    return value1[1].toLowerCase().localeCompare(value2[1].toLowerCase(), 'pl')
};
const sortMiejsceRealizacjiFilter = (mr1, mr2) => {
    const v1 = mr1[0], v2 = mr2[0];
    if (v1 === 'abroad') {
        return 1
    }
    if (v2 === 'abroad') {
        return -1
    }
    if (v1 === 'poland') {
        return 1
    }
    if (v2 === 'poland') {
        return -1
    }
    return v1.toLowerCase().localeCompare(v2.toLowerCase(), 'pl')
};


const setInitialSorterByUrlParam = (urlParams, sorters, defaultSorter) => {
    let sort = urlParams.get('sort');
    if (!Object.keys(sorters).includes(sort)) {
        sort = defaultSorter;
    }
    const sorter = sorters[sort];
    return {value: sorter[0], label: sorter[1]}
}


export {
    ADVERTISEMENTS_SORTING_VALUES,
    PROJECTS_SORTING_VALUES,
    setInitialSorterByUrlParam,
    sortAdvertisements,
    sortMiejsceRealizacjiFilter,
    sortProgrammeFilter,
    sortProjects,
};


if (typeof module === 'object' && module.exports) {
    module.exports = {
        sortProjects,
    };
}
