import React, { useEffect, useState } from "react";
import { fetchApi } from "../../../fetch";
import { GridCustom, FieldWithLabel } from "../../common";
import { Typography, Elevation, Switch } from "rmwc";
import { colors } from "../../vars/vars";

const translationMap = {
  MY_ADVERTISEMENT: "Zmiany mojego ogłoszenia",
  MY_OFFERS: "Zmiany ogłoszenia z moją ofertą",
  MY_FAVORITE_ADVERTISEMENT: "Zmiany ulubionych ogłoszeń",
  MY_ADVERTISEMENT_QUESTION_SUBMITTED:
    "Gdy w moim ogłoszeniu pojawi się nowe pytanie",
  MY_ADVERTISEMENT_OFFER_SUBMITTED_OR_CHANGED:
    "Gdy w moim ogłoszeniu pojawi się nowa lub zmieniona oferta",
  MY_ADVERTISEMENT_OFFER_WITHDRAWN:
    "Gdy w moim ogłoszeniu zostanie wycofana oferta",
  MY_ADVERTISEMENT_ENDS_IN_24_HOURS:
    "Gdy moje ogłoszenie skończy się w ciągu 24 godzin",
  MY_OFFERS_ADVERTISEMENT_SUBMITTED_CHANGES:
    "Gdy w ogłoszeniu zostaną opublikowane zmiany",
  MY_OFFERS_ADVERTISEMENT_STATUS_CHANGED: "Gdy w ogłoszeniu zmieni się status",
  MY_OFFERS_ADVERTISEMENT_ANSWER_SUBMITTED_OR_CHANGED:
    "Gdy w ogłoszeniu zostanie dodana lub zmieniona odpowiedź",
  MY_FAVORITE_ADVERTISEMENT_SUBMITTED_CHANGES:
    "Gdy w ulubionym ogłoszeniu zostaną opublikowane zmiany",
  MY_FAVORITE_ADVERTISEMENT_STATUS_CHANGED:
    "Gdy w ulubionym ogłoszeniu zmieni się status",
  MY_FAVORITE_ADVERTISEMENT_ANSWER_SUBMITTED_OR_CHANGED:
    "Gdy w ulubionym ogłoszeniu zostanie dodana lub zmieniona odpowiedź",
    MY_FAVORITE_ADVERTISEMENT_ENDS_IN_24_HOURS:
    "Gdy ulubione ogłoszenie skończy się w ciągu 24 godzin"
};

const CircleWithLetter = () => {
  return (
    <div className="circle">
      <span>N</span>
    </div>
  );
};

export default CircleWithLetter;

export const Notifications = (props) => {
  const [notificationOptions, setNotificationOptions] = useState();

  useEffect(() => {
    fetchApi("/api/notification-type-enabled", "GET", {}, {}, (data) => {
      setNotificationOptions(data);
    });
  }, []);

  const notificationsEnabled = notificationOptions?.notifications?.enabled;

  const handleToggleNotifications = (checked) => {
    fetchApi(
      "/api/notification-type-enabled/NOTIFICATIONS",
      "PUT",
      {},
      { enabled: checked },
      () => {
        setNotificationOptions({
          ...notificationOptions,
          notifications: {
            ...notificationOptions.notifications,
            enabled: checked,
          },
        });
      }
    );
  };

  const handleToggleNotification = (type, checked) => {
    const groups = [...notificationOptions.groups];

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      for (let j = 0; j < group.items.length; j++) {
        const item = group.items[j];
        if (item.type === type) {
          item.enabled = checked;
          break;
        }
      }
    }

    fetchApi(
      `/api/notification-type-enabled/${type}`,
      "PUT",
      {},
      { enabled: checked },
      () => {
        setNotificationOptions({
          ...notificationOptions,
          groups: [...groups],
        });
      }
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography use="headline5" tag="h5">
        Ustawienia powiadomień
      </Typography>
      <Elevation z={4} style={{ background: colors.warn }}>
        <GridCustom>
          <p style={{ margin: 10 }}>
            Uwaga: Jeśli wyłączysz powiadomienia, oznaczenia <CircleWithLetter /> nie będą się wyświetlać.
          </p>
        </GridCustom>

      </Elevation>
      <Elevation z={4}>
        <div
          style={{ background: colors.primary, color: "white", padding: 10 }}
        >
          <Typography use="headline6" tag="h6">
            Ustawienia powiadomień
          </Typography>
        </div>
        <GridCustom>
          <FieldWithLabel
            label="Wysyłaj powiadomienia e-mail"
            tag="label"
            labelFor="notifications_enabled"
            style={{ margin: 10 }}
          >
            <Switch
              aria-label={`${
                notificationsEnabled ? "Wyłącz" : "Włącz"
              } powiadomienia`}
              checked={notificationsEnabled}
              onChange={(ev) => handleToggleNotifications(ev.target.checked)}
              id="notifications_enabled"
            />
          </FieldWithLabel>
        </GridCustom>
      </Elevation>

      {notificationsEnabled && (
        <Elevation z={4}>
          <div
            style={{ background: colors.primary, color: "white", padding: 10 }}
          >
            <Typography use="headline6" tag="h6">
              Ustawienia typów powiadomień
            </Typography>
          </div>

          {notificationOptions.groups.map((group) => {
            return (
              <div style={{ marginLeft: 5 }}>
                <p style={{fontWeight: '600'}}>{translationMap[group.type]}</p>
                {group.items.map((item) => {
                  return (
                    <GridCustom>
                      <FieldWithLabel
                        label={translationMap[item.type]}
                        tag="label"
                        labelFor={`notification_${item.type}`}
                        className="switch-nonboldtext"
                      >
                        <Switch
                          aria-label={`${
                            notificationsEnabled ? "Wyłącz" : "Włącz"
                          } powiadomienia ${translationMap[group.type]}`}
                          checked={item.enabled}
                          onChange={(ev) =>
                            handleToggleNotification(
                              item.type,
                              ev.target.checked
                            )
                          }
                          id={`notification_${item.type}`}
                        />
                      </FieldWithLabel>
                    </GridCustom>
                  );
                })}
              </div>
            );
          })}
        </Elevation>
      )}
    </div>
  );
};
