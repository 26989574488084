import React from 'react';
import { Link } from 'react-router-dom';
import {
    DataTableCell,
    DataTableRow,
} from '@rmwc/data-table';

import { dateToString } from '../../../helpers';
import { Breadcrumps, ButtonStyled, TableCustom, Text } from '../../common';
import { Komunikaty } from './Komunikaty';


class KomunikatyFront extends Komunikaty {

    // helpers

    getFetchUrl() {
        return '/api/statements'
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps isFront>
                    <li className="breadcrumps__current">Komunikaty</li>
                </Breadcrumps>
                <Text tag="h2" mainHeader>Lista komunikatów</Text>
            </>
        )
    }

    renderContent() {
    document.title = "Baza Konkurencyjności - komunikaty";
        if (!this.state.komunikaty.length) {
            return <Text error role="alert">Brak komunikatów.</Text>
        }
        const sort = (k1, k2) => {
            return k1.dataOpublikowania > k2.dataOpublikowania ? -1 : 1
        };
        return (
            <TableCustom tableHeaderList={['Data opublikowania', 'Treść komunikatu', <span className="sr-only">Szczegóły</span>]}>
                {this.state.komunikaty.filter(
                    k => k.czyAktywny === true && (!k.dataWaznosci.length || k.dataWaznosci >= dateToString(new Date()))
                ).sort(sort).map(k => (
                    <DataTableRow key={k.id}>
                        <DataTableCell>
                            <Text>{k.dataOpublikowania}</Text>
                        </DataTableCell>
                        <DataTableCell>
                            <Text>{k.naglowek}</Text>
                        </DataTableCell>
                        <DataTableCell>
                            <ButtonStyled tag={Link} to={`/komunikaty/${k.id}-${k.slug}`} primary noMargin>
                                Szczegóły<span className="sr-only"> komunikatu</span>
                            </ButtonStyled>
                        </DataTableCell>
                    </DataTableRow>
                ))}
            </TableCustom>
            
        )
    }
}


export { KomunikatyFront };
