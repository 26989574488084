import React from "react";
import { PomocEkranowa } from "../zarzadzanieTrescia";
import { PaginatedBaseComponent } from "../common";

import { Link } from "react-router-dom";
import {
  getFavoritesAdvertFiltersCount,
  FAVORITES_ADVERT_CLEANED_FILTERS,
} from "../../../filters";
import { FavoritesFilters } from "./FavoritesFilters";

import {
  Box,
  FieldWithLabel,
  GridCustom,
  ButtonStyled,
  Text,
} from "../../common";
import { dateToString } from "../../../helpers";
import { isAdvertisementLocked } from "../../../helpers";
import { fetchApi } from "../../../fetch";
import { BadgeAnchor, Badge } from "@rmwc/badge";
import "@rmwc/badge/badge.css";

class Favorites extends PaginatedBaseComponent {
  preState(props) {
    super.preState(props);
    this.objectsName = "ogloszenia";
    this.paginateBy = 20;
    this.sortingValues = [
      ["publicationDate", "Po dacie publikacji ogłoszeń"],
      ["submissionDeadline", "Po terminie składania ofert"],
      ["default", "Domyślnie"],
    ];
    this.filteringLabel = "Filtruj listę";
    this.filtersComponentClass = FavoritesFilters;

    this.getFiltersCount = getFavoritesAdvertFiltersCount;
  }

  postState(props) {
    super.postState(props);
    this.dictionaries = {};
  }

  handleReload = (link = null) => {
    this.setState({ initializingOn: true, fetchError: "", objects: [] }, () => {
      this.fetchInitialData();
    });
  };

  getAdditionalState(props) {
    const params = new URLSearchParams(props.location.search);
    return Object.assign({}, super.getAdditionalState(props), {
      selectedSort: {
        value: this.sortingValues[2][0],
        label: this.sortingValues[2][1],
      },
      query: params.get("q") || "",
      filters: FAVORITES_ADVERT_CLEANED_FILTERS,
    });
  }

  renderHeader() {
    return (
      <>
        <PomocEkranowa
          ekran="favorites_list"
          content={this.props.isPreview ? this.props.content : null}
        />
        <Text tag="h1" mainHeader>
          Lista ulubionych ogłoszeń
        </Text>
      </>
    );
  }

  renderEmptyObjects() {
    return (
      <Text error role="alert">
        Nie znaleziono ogłoszeń spełniających kryteria wyszukiwania.
      </Text>
    );
  }

  getFetchUrl() {
    return "/api/announcements";
  }

  getFetchData() {
    let queryDict = super.getFetchData();

    if (this.state.query.length) {
      queryDict["query"] = this.state.query;
    }
    for (let [name, value] of Object.entries(this.state.filters)) {
      if (["publicationDateRange", "submissionDeadlineRange"].includes(name)) {
        for (let [subname, subvalue] of Object.entries(value)) {
          if (subname === "type") {
            continue;
          }
          if (!!subvalue) {
            queryDict[`${name}[${subname}]`] = subvalue;
          }
        }
      } else if (name === "cpvItem") {
        if (Object.keys(value).length) {
          queryDict[name] = value.map((kod) => kod.kod);
        }
      } else if ("programme" === name) {
        if (value.length) {
          queryDict[name] = value.map((mr) => mr.value.toUpperCase());
        }
      } else if ("fulfillmentPlaces" === name) {
        if (value.length) {
          queryDict[name] = value.map((mr) => mr.value);
        }
      } else if (["category", "subcategory"].includes(name)) {
        if (value !== null) {
          queryDict[name] = value.label;
        }
      } else if (
        (name === "status" && Object.keys(value).length) ||
        value !== FAVORITES_ADVERT_CLEANED_FILTERS[name]
      ) {
        queryDict[name] = value;
      } else if (name === "mode") {
        queryDict[name] = value;
      }
    }
    return queryDict;
  }

  renderFiltersComponent() {
    return (
      <this.filtersComponentClass
        dictionaries={this.dictionaries}
        filters={this.state.filters}
        closeFilters={() => this.setState({ filtersAreOn: false })}
        updateDictionaries={(dictionaries) =>
          (this.dictionaries = dictionaries)
        }
        updateFilters={this.handleApplyFilters}
      />
    );
  }

  renderList() {
    const total = this.state.total;
    return (
      <>
        <Text accentHeader tag="h2" role="alert">
          Znaleziono {total < this.paginateBy ? "" : "około "}
          {total} ogłoszeń
        </Text>
        <GridCustom flexL noWrap flexTop>
          <GridCustom centerHorizontal className="advertisements-list" tag="ul">
            {this.state.objects.map((o) => this.renderOgloszenie(o))}
          </GridCustom>
        </GridCustom>
      </>
    );
  }

  handleAddToFavorites = (id) => {
    fetchApi(`/api/favorite-announcements/${id}`, "POST", {}, {}, () => {
      this.handleReload();
    });
  };

  handleRemoveFromFavorites = (id) => {
    fetchApi(`/api/favorite-announcements/${id}`, "DELETE", {}, {}, () => {
      this.handleReload();
    });
  };

  renderOgloszenie(o) {
    const status = parseInt(o.ogloszenie.status?.id);
    const isLocked = isAdvertisementLocked(o.ogloszenie.status);
    return (
      <Box
        className="box--advertisement"
        flexiblewidth
        tag="li"
        titleOneRow={o.tytul || "-"}
        key={o.id}
      >
        <GridCustom fullwidth>
          <FieldWithLabel label="Numer ogłoszenia">
            <Text>{o.ogloszenie.numer || "-"}</Text>
          </FieldWithLabel>
          <FieldWithLabel label="Status">
            <Text>{o.ogloszenie.status?.nazwa}</Text>
          </FieldWithLabel>
        </GridCustom>
        <GridCustom fullwidth>
          <FieldWithLabel
            label={`Data ${
              o.dataOpublikowania?.length > 0 &&
              o.dataOpublikowania > dateToString(new Date())
                ? "odroczonej "
                : ""
            }publikacji`}
          >
            <Text>{o.dataOpublikowania || "-"}</Text>
          </FieldWithLabel>
          <FieldWithLabel label="Termin składania ofert">
            <Text>{o.terminOfert || "-"}</Text>
          </FieldWithLabel>
        </GridCustom>
        <FieldWithLabel label="Czy dopuszczalna oferta częściowa?">
          <Text>
            {o.czyDopuszczalnaOfertaCzesciowa ? "TAK" : "NIE"}
            {o.czyDopuszczalnaOfertaCzesciowa &&
              ` (Części: ${o.liczbaZamowien})`}
          </Text>
        </FieldWithLabel>

        <BadgeAnchor style={{ width: "100%" }}>
          <ButtonStyled
            className="corner-button"
            disabled={isLocked}
            primary
            tag={Link}
            to={`/ogloszenia/${o.ogloszenie.id}${
              status === this.roboczeStatusId ? "/robocza" : ""
            }`}
          >
            Pokaż szczegóły
            <span className="sr-only">{` ogłoszenia ${o.tytul}`}</span>
          </ButtonStyled>
          {o.badge_count > 0 && (
            <Badge label={o.badge_count} />
          )}
        </BadgeAnchor>

        {o.favorite === false && (
          <ButtonStyled
            onClick={() => this.handleAddToFavorites(o.ogloszenie.id)}
            primary
            icon="favorite"
          >
            DODAJ DO ULUBIONYCH
            <span className="sr-only"> ogłoszenie {o.tytul}</span>
          </ButtonStyled>
        )}
        {o.favorite === true && (
          <ButtonStyled
            onClick={() => this.handleRemoveFromFavorites(o.ogloszenie.id)}
            remove
            icon="close"
          >
            USUŃ Z ULUBIONYCH
            <span className="sr-only"> ogłoszenie {o.tytul}</span>
          </ButtonStyled>
        )}
      </Box>
    );
  }
}

export { Favorites };
