import React from 'react';

import { BasePytaniaForOgloszenieWidthPdf } from './BasePytaniaForOgloszenieWidthPdf';
import { FormPytanie } from './FormPytanie';
import { Pytanie } from './Pytanie';
import { ButtonStyled, Container, DialogCustom, GridCustom, Text } from '../../common';


class PytaniaForOgloszenieLoggedIn extends BasePytaniaForOgloszenieWidthPdf {

    handleAddQuestion = (data) => {
        this.setState(prevState => ({
            questionFormOn: false,
            pytania: [...prevState.pytania, data],
            confirmInfo: true,
        }));
    }

    // helpers

    getAdditionalState(props) {
        return {
            pytania: [],
            questionFormOn: false,
            confirmInfo: false,
        }
    }

    // rendering

    renderContent() {
        const { confirmInfo, pytania, questionFormOn, } = this.state;
        const ogloszenieStatus = this.props.ogloszenie.ogloszenie.status.label;
        const myQuestionsList = pytania.filter(q => q.jestAutorem);
        const publishedQuestionsList = pytania.filter(q => q.status.label === 'PUBLISHED');
        myQuestionsList.sort((a,b) => (a.numer > b.numer) ? 1 : ((b.numer > a.numer) ? -1 : 0));

        return (
            <>
                {ogloszenieStatus === 'PUBLISHED' && (
                    <div className="login-info-container">
                        <ButtonStyled
                            add
                            className="btn btn--main"
                            onClick={(e) => {
                                this.setState({questionFormOn: true});
                                e.currentTarget.blur();
                            }} >
                            Dodaj pytanie
                        </ButtonStyled>
                    </div>
                )}
                <Container className="questions-container">
                    {this.renderQuestionsList('Moje pytania', myQuestionsList, 'Nie zadałeś pytań do tego ogłoszenia.', true)}
                </Container>
                <Container className="questions-container">
                    {this.renderPrintButton()}
                    {this.renderQuestionsList('Lista wszystkich opublikowanych pytań i odpowiedzi', publishedQuestionsList, 'Do tego ogłoszenia nie ma opublikowanych pytań.')}
                </Container>
                {questionFormOn && this.renderQuestionForm()}
                {confirmInfo && this.renderConfirmationDialog()}
            </>
        )
    }

    renderQuestionsList(questionsListName, questionsList, noQuestionsInfo, noAuthor=false) {
        if (questionsList.length) {
            return (
                <div className="questions-list-container">
                    <Text tag="h3" mainHeader>{questionsListName}</Text>
                    <Text info>Liczba wyników: {questionsList.length}</Text>
                    <ul>
                        {questionsList.map((q) => <Pytanie key={q.id} question={q} noAuthorLabel={noAuthor} reloadAnnouncement={this.props.reloadAnnouncement} />)}
                    </ul>
                </div>
            )
        }

        return <Text info>{noQuestionsInfo}</Text>
    }

    renderQuestionForm() {
        return (
            <FormPytanie
                advertisementId={this.props.ogloszenie.ogloszenie.id}
                onCloseForm={() => this.setState({questionFormOn: false})}
                onAddQuestion={this.handleAddQuestion} />
        )
    }

    renderConfirmationDialog() {
        return (
            <DialogCustom
                dialogTitle="Wysłano pytanie."
                onClose={() => this.setState({confirmInfo: false})} >
                <GridCustom flexEnd>
                    <ButtonStyled save onClick={() => this.setState({ confirmInfo: false })}>OK</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }
}

export { PytaniaForOgloszenieLoggedIn };
