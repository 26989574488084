import React from 'react';

import { serializePerspectiveForSelect } from '../../../helpers';
import { useOneTimeFetchApi } from '../../../hooks';
import { API_URLS } from '../../../urls/api';
import { ButtonStyled, Loader, Text } from '../../common';
import {
    UserPermissionsImplementationLevelFields
} from './UserPermissionsImplementationLevelFields';


export const UserPermissionsImplementationLevelForm = ({
    initialData = null, userId, onCancel, onSavePermissionSuccess
}) => {
    const {data: dataPerspectives, loadingOn, fetchError} = useOneTimeFetchApi({
        url: API_URLS.perspectives.path,
        fetchData: {mode: 'permissions'},
        serializeFetchDataFunction: serializePerspectiveForSelect,
    });

    return loadingOn ? <Loader /> : (fetchError
        ? (
            <>
                <Text error>{fetchError}</Text>
                <ButtonStyled cancel onClick={onCancel}>OK</ButtonStyled>
            </>
        )
        : (
            <UserPermissionsImplementationLevelFields
                initialData={initialData}
                perspectives={dataPerspectives.perspectives}
                userId={userId}
                onCancel={onCancel}
                onSavePermissionSuccess={onSavePermissionSuccess} />
        )
    )
};
