import React, { Fragment, useEffect, useState } from 'react';

import { fetchApi } from '../../../fetch';
import { translateErrorMessage } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { Button } from '@rmwc/button';
import { LoaderIcon, Text } from '../../common';
import { PermissionsMoreOptionButton } from './PermissionsMoreOptionButton';


const OBJECT_TYPE_DICT = {
    programme: {
        buttonLabel: 'osi',
        dataObjectName: 'priorities',
        url: API_URLS.priorities,
    },
    priority: {
        buttonLabel: 'działań',
        dataObjectName: 'activities',
        url: API_URLS.activities,
    },
    subactivity: {
        buttonLabel: 'regionów',
        dataObjectName: 'regions',
        url: API_URLS.regions,
    },
    activity: {
        buttonLabel: 'instytucji',
        dataObjectName: 'institutions',
        url: API_URLS.institutions,
    },
}


export const PermissionListImplementationLevelCellProgrammePriorityActivity = ({
    fullPermission,
    hasRegions,
    object,
    objectType,
    onSetActiveHistoryPermission,
    onSetActiveUsersPermission, ...props
}) => {
    const [initializationOn, setInitializationOn] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [subObjects, setSubObjects] = useState(null);
    const [fetchError, setFetchError] = useState(null);

    const typeDict = OBJECT_TYPE_DICT[objectType];

    useEffect(() => {
        setFetchError(null);
        if (!isOpen || subObjects !== null) { return }
        setInitializationOn(true);

        function callbackSuccess(data) {
            xhrFetch = null;
            setSubObjects(data[typeDict.dataObjectName]);
            setInitializationOn(false);
        }

        function callbackError(data) {
            xhrFetch = null;
            setFetchError(
                `Nie udało się pobrać danych. ${translateErrorMessage(data.message)}`
            );
            setInitializationOn(false);
        }

        function callbackIncorrectStatus(status) {
            xhrFetch = null;
            setFetchError(
                `Nie udało się pobrać danych. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
            );
            setInitializationOn(false);
        }

        let xhrFetch = fetchApi(
            typeDict.url.getUrl(object.id), 'GET', {}, {},
            callbackSuccess, callbackError, callbackIncorrectStatus
        );

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [isOpen, object.id, subObjects, typeDict]);

    const permissionForActionDict = {
        isImplementationLevelType: true,
        permission: fullPermission
    };
    return (
        <>
            <div className="permissions__level-row">
                <PermissionsMoreOptionButton
                    onOpenHistory={() => onSetActiveHistoryPermission(
                        permissionForActionDict
                    )}
                    onOpenUserList={() => onSetActiveUsersPermission(
                        permissionForActionDict
                    )} />
                <Text className={props.isMainLevel ? 'permissions__main-name' : 'permissions__indented'}>{object.code} {object.name}
                    {isOpen
                        ? (
                            <Button
                                className="permissions__show-less"
                                icon='keyboard_arrow_up'
                                label={`zwiń listę ${typeDict.buttonLabel}`}
                                onClick={() => setIsOpen(false)}
                            />
                        )
                        : (
                            <Button
                                className="permissions__show-more"
                                icon='keyboard_arrow_down'
                                label={`rozwiń listę ${typeDict.buttonLabel}`}
                                onClick={() => setIsOpen(true)}
                            />
                        )
                    }
                </Text>
            </div>
            {isOpen && (
                <div className="permissions__level-list">
                    {initializationOn
                        ? <LoaderIcon />
                        : (
                            <>
                                {subObjects !== null && subObjects.map(obj => (
                                    <Fragment key={obj.id}>
                                        {objectType === 'programme' && (
                                            <PermissionListImplementationLevelCellProgrammePriorityActivity
                                                fullPermission={{...fullPermission, priority: obj}}
                                                hasRegions={hasRegions}
                                                isMainLevel={false}
                                                object={obj}
                                                objectType="priority"
                                                onSetActiveHistoryPermission={onSetActiveHistoryPermission}
                                                onSetActiveUsersPermission={onSetActiveUsersPermission} />
                                        )}
                                        {objectType === 'priority' && (
                                            <PermissionListImplementationLevelCellProgrammePriorityActivity
                                                fullPermission={{...fullPermission, activity: obj}}
                                                hasRegions={hasRegions}
                                                isMainLevel={false}
                                                object={obj}
                                                objectType={hasRegions ? 'subactivity' : 'activity'}
                                                onSetActiveHistoryPermission={onSetActiveHistoryPermission}
                                                onSetActiveUsersPermission={onSetActiveUsersPermission} />
                                        )}
                                        {['activity', 'subactivity'].includes(objectType) && (
                                            <div className="permissions__level-row permissions__level-row--deeper">
                                                <PermissionsMoreOptionButton
                                                    onOpenHistory={() => onSetActiveHistoryPermission({
                                                        isImplementationLevelType: true,
                                                        permission: {
                                                            ...fullPermission,
                                                            [objectType === 'activity' ? 'institution' : 'region']: obj
                                                        }
                                                    })}
                                                    onOpenUserList={() => onSetActiveUsersPermission({
                                                        isImplementationLevelType: true,
                                                        permission: {
                                                            ...fullPermission,
                                                            [objectType === 'activity' ? 'institution' : 'region']: obj
                                                        }
                                                    })}
                                                />
                                                <Text className="permissions__indented">{obj.code} {obj.name}</Text>
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                                {subObjects !== null && !subObjects.length && <Text error className="text--error">Lista jest pusta.</Text>}
                                {fetchError !== null && <Text error>{fetchError}</Text>}
                            </>
                        )
                    }
                </div>
            )} 
        </>
    )
};
