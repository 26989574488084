import React from 'react';

import { reformatDanePodmiotu } from '../../../helpers';
import { Container, Text } from '../../common';
import { DanePodmiotu } from '../common';


export const UserEconomicSubjectData = ({ economicSubject }) => {
    let content;
    if (Object.keys(economicSubject).length) {
        economicSubject = reformatDanePodmiotu(economicSubject);
        content = <DanePodmiotu danePodmiotu={economicSubject} />;
    } else {
        content = <Text error>Brak zdefiniowanych danych podmiotu.</Text>;
    }
    return (
        <Container className="users__container--middle">
            <Text tag="h2" mainHeader>Dane podmiotu</Text>
            {content}
        </Container>
    )
};
