import React from 'react';

import { customSelectStyles } from '../vars/vars';
import { DateCalendar } from './DateCalendar';
import { FieldWithLabel } from './FieldWithLabel';
import { Select } from './Select';

import { SEARCH_DATE_CHOICES } from '../../const';
import { dateToString } from '../../helpers';


const CHOICES_DICT = {};
for (let ch of SEARCH_DATE_CHOICES) {
    CHOICES_DICT[ch[0]] = ch[1];
}


const DateSelect = (props) => {
    const type = props.value.type || '0';
    const selectValue = {value: type, label: CHOICES_DICT[type]};

    const from = !!props.value.from ? new Date(props.value.from) : '';
    const to = !!props.value.to ? new Date(props.value.to) : '';
    const odInputRef = React.createRef();
    const doInputRef = React.createRef();

    return (
        <>
            <Select
                screenReaderStatus={() => {return ''}}
                className="select-custom"
                options={SEARCH_DATE_CHOICES.map(choice => ({value: choice[0], label: choice[1], data: {from: choice[2], to: choice[3]}}))}
                value={selectValue}
                onChange={selectedOption => props.updateDate(
                    {type: selectedOption.value, ...selectedOption.data})}
                styles={customSelectStyles}
                aria-describedby={props.ariaDescribedby}
                aria-label={props.selectAriaLabel || ''}
                noOptionsMessage={() => 'Brak wybranej opcji'}
            />
            {selectValue.value === 'custom' && (
                <>
                    <FieldWithLabel label="Od" tag="label" inputRef={odInputRef}>
                        <DateCalendar
                            dateName="Od"
                            maxDate={to || undefined}
                            parentRef={odInputRef}
                            value={from}
                            onChange={date => props.updateDate({from: dateToString(date)})} />
                    </FieldWithLabel>
                    <FieldWithLabel label="Do" tag="label" inputRef={doInputRef}>
                        <DateCalendar
                            dateName="Do"
                            minDate={from || undefined}
                            parentRef={doInputRef}
                            value={to}
                            onChange={date => props.updateDate({to: dateToString(date)})} />
                    </FieldWithLabel>
                </>
            )}
        </>
    )
}


export { DateSelect };
