const SELECT_CLASSNAME = 'select-custom';
const SELECT_INVALID_CLASSNAME = 'select-custom select-custom--invalid';

///////////////////////////////////////////////////////////////////////////////
//                                 projekty                                  //
///////////////////////////////////////////////////////////////////////////////

const CZY_PROJEKT_CHOICES = [
    ['all', 'Wszystkie'],
    ['true', 'Projekty'],
    ['false', 'Nabory'],
]

// used in projects management filters
const PROJECTS_MANAGEMENTS_CHOICES = [
    ['all', 'Wszystkie'],
    ['project', 'Projekt'],
    ['enrollment', 'Nabór'],
]

///////////////////////////////////////////////////////////////////////////////
//                                 ogloszenia                                //
///////////////////////////////////////////////////////////////////////////////
const SEARCH_DATE_CHOICES = [
    ['0', '--- Wybierz ---', '', ''],
    ['last_week', 'ostatni tydzień', '-1week', 'today'],
    ['last_two_weeks', 'ostatnie 2 tygodnie', '-2weeks', 'today'],
    ['last_month', 'ostatni miesiąc', '-1month', 'today'],
    ['custom', 'niestandardowo', '', ''],
];

const SEARCH_CUSTOM_NAME = SEARCH_DATE_CHOICES[4][0];

const ADVERTISEMENT_STATUS_FILTERS_CHOICES = [
    ['all', 'Wszystkie'],
    ['CANCELLED', 'Anulowane'],
    ['PUBLISHED', 'Opublikowane'],
    ['DRAFT', 'Robocze'],
    ['RESOLVED', 'Rozstrzygnięte'],
    ['LOCKED', 'Zablokowane'],
    ['CLOSED', 'Zamknięte'],
].map(choice => ({value: choice[0], label: choice[1]}));

// Current values versus values from old Baza Konkurencyjności
const MIEJSCE_REALIZACJI_OLD_BK_DICT = {
    'poland': 0,
    'abroad': -1,
    '02': 1,
    '04': 2,
    '06': 3,
    '08': 4,
    '10': 5,
    '12': 6,
    '14': 7,
    '16': 8,
    '18': 9,
    '20': 10,
    '22': 11,
    '24': 12,
    '26': 13,
    '28': 14,
    '30': 15,
    '32': 16,
};

const NIP_CHARS_IMPORTANCE = {
    0: 6,
    1: 5,
    2: 7,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    7: 6,
    8: 7,
};

const PESEL_CHARS_IMPORTANCE = {
    0: 9,
    1: 7,
    2: 3,
    3: 1,
    4: 9,
    5: 7,
    6: 3,
    7: 1,
    8: 9,
    9: 7,
};

///////////////////////////////////////////////////////////////////////////////
//                           zarządzanie treścią                             //
///////////////////////////////////////////////////////////////////////////////

const EKRANY = [
    ['form_ogloszenie_przedmiot','Formularz dodawania ogłoszenia - Przedmiot', 'form_announcement_item'],
    ['form_ogloszenie_terminy','Formularz dodawania ogłoszenia - Terminy', 'form_announcement_deadlines'],
    ['form_ogloszenie_warunki','Formularz dodawania ogłoszenia - Warunki', 'form_announcement_conditions'],
    ['form_ogloszenie_czesciKryteria','Formularz dodawania ogłoszenia - Części i kryteria', 'form_announcement_partscriteria'],
    ['form_ogloszenie_pozostale','Formularz dodawania ogłoszenia - Pozostałe', 'form_announcement_others'],
    ['form_ogloszenie_zalaczniki','Formularz dodawania ogłoszenia - Załączniki', 'form_announcement_attachments'],
    ['projekty_lista_ogloszeniodawca','Projekty - Lista projektów - Ogłoszeniodawca', 'projects_list_advertiser'],
    ['ogloszenia_lista_ogloszeniodawca','Ogłoszenia - Lista ogłoszeń - Ogłoszeniodawca', 'announcements_list_advertiser'],
    ['newsletter_ustawienia','Newsletter', 'newsletter_settings'],
    ['ulubione_lista','Ulubione', 'favorites_list'],
];

const EKRANY_DICT = {};
for (let e of EKRANY) {
    EKRANY_DICT[e[2]] = {id: e[0], label: e[1]};
}

// pomoc types
const EKRANOWA = 'screen_assigned';
const OGOLNA = 'general';


///////////////////////////////////////////////////////////////////////////////
//                                użytkownicy                                //
///////////////////////////////////////////////////////////////////////////////
const USER_STATUS_CHOICES = [
    ['all', 'Wszyscy'],
    ['active', 'Aktywni'],
    ['blocked', 'Zablokowani'],
];
const RODZAJ_ADRESU_CHOICES = [
    {
        value: 'poland',
        label: 'Polska',
    },
    {
        value: 'abroad',
        label: 'Zagranica',
    },
];
const RODZAJ_ADRESU_FILTERS_CHOICES = [
    {
        value: 'all',
        label: 'Wszystkie',
    },
    ...RODZAJ_ADRESU_CHOICES
];
const TYP_NUMERU_IDENTYFIKACYJNEGO = [
    ['nip', 'NIP'],
    ['pesel', 'PESEL'],
    ['numer_zagraniczny', 'Numer identyfikacyjny'],
].map(choice => ({value: choice[0], label: choice[1]}));
const TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES = [
    ['all', 'Wszystkie'],
    ['nip', 'NIP'],
    ['pesel', 'PESEL'],
].map(choice => ({value: choice[0], label: choice[1]}));
const TOKEN_COOKIE_NAME = 'auth_token';
const USER_DATA_COOKIE_NAME = 'auth_data';


///////////////////////////////////////////////////////////////////////////////
//                                   oferty                                  //
///////////////////////////////////////////////////////////////////////////////
const OFERTA_STATUSES = {
    draft: {
        label: 'DRAFT',
        nazwa: 'Robocza',
    },
    submitted: {
        label: 'SUBMITTED',
        nazwa: 'Złożona',
    },
    withdrawn: {
        label: 'WITHDRAWN',
        nazwa: 'Wycofana',
    },
};


export {
    ADVERTISEMENT_STATUS_FILTERS_CHOICES,
    CZY_PROJEKT_CHOICES,
    EKRANOWA,
    EKRANY_DICT,
    MIEJSCE_REALIZACJI_OLD_BK_DICT,
    NIP_CHARS_IMPORTANCE,
    OFERTA_STATUSES,
    OGOLNA,
    PESEL_CHARS_IMPORTANCE,
    PROJECTS_MANAGEMENTS_CHOICES,
    RODZAJ_ADRESU_CHOICES,
    RODZAJ_ADRESU_FILTERS_CHOICES,
    SEARCH_CUSTOM_NAME,
    SEARCH_DATE_CHOICES,
    SELECT_CLASSNAME,
    SELECT_INVALID_CLASSNAME,
    TOKEN_COOKIE_NAME,
    TYP_NUMERU_IDENTYFIKACYJNEGO,
    TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES,
    USER_DATA_COOKIE_NAME,
    USER_STATUS_CHOICES,
};
