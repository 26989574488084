import React from 'react';
import { Link } from 'react-router-dom';

import { getSerializedObject } from '../../serializers';
import { validateWysiwygEditor } from '../../validators';
import { ButtonStyled, Container, GridCellCustom, GridCustom, Text, TextFieldCustom } from '../common';
import { BaseComponent } from './common';
import { DraftToComponents } from './zarzadzanieTrescia';
import { redirectToLoginView } from '../../helpers';
import { URLS } from '../../urls/frontend';


class LandingPage extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        // if it is preview for zarzadzanie trescia, get data from props
        const content = this.props.content;
        let trescPreviewId = null;
        if (this.props.content) {
            trescPreviewId = content.id;
        }

        let state = {initializingOn: false,};
        const tresciOgolne = getSerializedObject(
            data, {nonRecursiveKeys: ['tresc', ]}).tresciOgolne;
        for (let i of [1, 2, 3]) {
            let to = tresciOgolne.filter(to => to.placement === `homepage_box${i}`)[0];
            if (to) {
                if (trescPreviewId !== null && to.id === trescPreviewId) {
                    to = content;
                }
            } else {
                to = {};
            }
            state[`homepageBox${i}`] = to;
        }
        this.setState(state);
    }

    handleFetchError(data) {
        // do nothing, don't render error
        this.xhrFetch = null;     // clean xhr object
        this.setState({initializingOn: false});
    }

    handleFetchIncorrectStatus(message) {
        this.handleFetchError({});
    }

    handleSearch = () => {
        this.props.history.push(this.getSearchUrl(this.state.query));
    }

    handleNewsletter = () => {
        if(this.props.user?.id !== null) {
            this.props.history.push(URLS.newsletter.path)
            return;
        }

        sessionStorage.setItem(
          "currentPathname",
          URLS.newsletter.path
        );

        redirectToLoginView(false);
    }

    // helpers

    getAdditionalState(props) {
        return {
            homepageBox1: {},
            homepageBox2: {},
            homepageBox3: {},
            query: '',
        }
    }

    getFetchUrl() {
        return '/api/general-content'
    }

    getSearchUrl(query) {
        if (!query.length) {
            return '/ogloszenia/szukaj'
        }
        return encodeURI(`/ogloszenia/szukaj?q=${query}`)
    }

    // rendering

    renderContent() {
        document.title = "Baza Konkurencyjności";
        const {
            homepageBox1,
            homepageBox2,
            homepageBox3
        } = this.state;

        const homepageBox1IsEmpty = Object.keys(homepageBox1).length === 0 || !validateWysiwygEditor(Object.keys(homepageBox1.tresc).length > 0 ? homepageBox1.tresc : '');
        const homepageBox2IsEmpty = Object.keys(homepageBox2).length === 0 || !validateWysiwygEditor(Object.keys(homepageBox2.tresc).length > 0 ? homepageBox2.tresc : '');
        const homepageBox3IsEmpty = Object.keys(homepageBox3).length === 0 || !validateWysiwygEditor(Object.keys(homepageBox3.tresc).length > 0 ? homepageBox3.tresc : '');
        if (homepageBox1IsEmpty && homepageBox2IsEmpty && homepageBox3IsEmpty) {
            return this.renderSearch()
        }

        return (
            <>
                {this.renderSearch()}
                {!homepageBox1IsEmpty && this.renderTrescOgolna(homepageBox1)}
                <GridCustom flexM fullwidth className=" landing-page-contacts">
                    <GridCellCustom>
                        {!homepageBox2IsEmpty && this.renderTrescOgolna(homepageBox2)}
                    </GridCellCustom>
                    <GridCellCustom fill>
                        {!homepageBox3IsEmpty && this.renderTrescOgolna(homepageBox3)}
                    </GridCellCustom>
                </GridCustom>
            </>
        )
    }

    renderSearch() {
        return (
          <Container className="search">
            <GridCustom flexL fullwidth centerVertical>
              <form
                onSubmit={this.handleSearch}
                className="search__form"
                role="search"
              >
                <label className="sr-only" htmlFor="search_input_id">
                  Wyszukaj ogłoszenie
                </label>
                <section className="search__input-container">
                  <div className="search__examples">
                    <Text>Na przykład:</Text>
                    <Link
                      to={this.getSearchUrl("roboty budowlane")}
                      className="link-text link-text--primary"
                    >
                      <span className="sr-only">Sugerowana fraza: </span>roboty
                      budowlane,
                    </Link>
                    <Link
                      to={this.getSearchUrl("dostawa komputerów")}
                      className="link-text link-text--primary"
                    >
                      <span className="sr-only">Sugerowana fraza: </span>dostawa
                      komputerów,
                    </Link>
                    <Link
                      to={this.getSearchUrl("szkolenie pracowników")}
                      className="link-text link-text--primary"
                    >
                      <span className="sr-only">Sugerowana fraza: </span>
                      szkolenie pracowników.
                    </Link>
                  </div>
                  <TextFieldCustom
                    className="search__input"
                    id="search_input_id"
                    maxLength={250}
                    onChange={(ev) => this.setState({ query: ev.target.value })}
                    onClear={(ev) => this.setState({ query: "" })}
                    placeholder="Wpisz, czego szukasz"
                    value={this.state.query}
                  />
                </section>
                <ButtonStyled
                  onClick={this.handleSearch}
                  primary
                  icon="search"
                  className="search__btn"
                >
                  Wyszukaj ogłoszenie
                </ButtonStyled>
                <ButtonStyled
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.handleNewsletter();
                  }}
                  lite
                  icon="mail"
                  className="search__btn"
                >
                  Zapisz się na newsletter ogłoszeń<span className="sr-only"> Zaloguj się, aby zapisać się na newsletter</span>
                </ButtonStyled>
              </form>
              <section className="search__secondary">
                {this.renderAddOgloszenieLink()}
              </section>
            </GridCustom>
          </Container>
        );
    }

    renderAddOgloszenieLink() {
        const user = this.props.user;
        if (!user || user.identifier === null) {
            return (
                <>
                    <ButtonStyled
                        className="search__btn"
                        icon="library_add"
                        lite
                        noMargin
                        onClick={this.props.onLoginClick}
                    >
                        Dodaj ogłoszenie<span className="sr-only"> Zaloguj się, aby dodać ogłoszenie</span>
                    </ButtonStyled>
                    <Text className="search__info">Wymaga logowania</Text>
                </>
            )
        }
        return (
            <ButtonStyled
                tag={Link}
                to="/ogloszenia/nowe"
                id="dodaj_ogloszenie_button_id"
                lite
                noMargin
                icon="library_add"
                className="search__btn">
                Dodaj ogłoszenie
            </ButtonStyled>
        )
    }

    renderTrescOgolna(element) {
        return (
            <Container title={element.naglowek}>
                <DraftToComponents content={element.tresc} />
            </Container>
        )
    }
}


export { LandingPage };
