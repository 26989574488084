import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../context/user-context';
import { API_URLS } from '../../../urls/api';
import { fetchApi } from '../../../fetch';
import {
    getSerializedErrorsFromServer,
    translateErrorMessage
} from '../../../serializers';
import {
    ButtonStyled,
    CancelSaveButtonsRow,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';


export const DeleteImplementationLevel = ({
    apiUrlName,
    item,
    parentFormLabel,
    perspectiveData,
    RelatedObjectsExistInfoComponent,
    titleFormLabel,
    onCancel,
    onDelete
}) => {
    const [deletingOn, setDeletingOn] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [
        objectRelatedWithOthers, setObjectRelatedWithOthers
    ] = useState(false);

    const { removePermission } = useContext(UserContext);

    useEffect(() => {
        if (!deletingOn) { return }

        setFetchError(null);
        let xhrFetch = null;

        function callbackSuccess(responseData) {
            xhrFetch = null;
            onDelete();
        }

        function callbackError(responseData) {
            xhrFetch = null;
            setFetchError(translateErrorMessage(responseData.message));
            setDeletingOn(false);
        }

        function callbackIncorrectStatus(status) {
            xhrFetch = null;
            setFetchError(
                status === 403
                    ? 'Nie masz uprawnień do usuwania elementów słownika poziomów wdrożenia.'
                    : `Wystąpił nieoczekiwany błąd o kodzie ${status}.`);
            setDeletingOn(false);
            if (status === 403) {
                removePermission('editingDictionaries');
            }
        }

        function callbackShowErrors(responseErrors) {
            xhrFetch = null;
            const errors = getSerializedErrorsFromServer(
                responseErrors, {doNotTranslateNames: true});
            if (((errors || {}).allFields || {}).id) {
                setObjectRelatedWithOthers(true);
            }
            setDeletingOn(false);
        }

        xhrFetch = fetchApi(
            API_URLS[apiUrlName].getUrl(item.id),
            'DELETE', {}, {},
            callbackSuccess,
            callbackError,
            callbackIncorrectStatus,
            callbackShowErrors
        );

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [deletingOn]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DialogCustom
            ariaLabelledby="addDialogLabel"
            className="dialog"
            onClose={onCancel}
        >
            <h2 id="addDialogLabel" className="text--main-header">
                {objectRelatedWithOthers
                    ? 'Niemożliwe usunięcie'
                    : 'Potwierdzenie usunięcia'
                } {titleFormLabel}
            </h2>
            <FieldWithLabel
                label="Kod"
                tag="label"
                labelFor="id_code"
            >
                <GridCustom noWrap centerVertical className="code-input-container code-input-container--edition">
                    {parentFormLabel && <Text accentHeader className="code-input-container__first-part">{parentFormLabel}</Text>}
                    <Text accentHeader>{item.code}</Text>
                </GridCustom>
            </FieldWithLabel>
            <FieldWithLabel
                label="Nazwa"
                tag="label"
                labelFor="id_name"
            >
                <Text>{item.name}</Text>
            </FieldWithLabel>

            {objectRelatedWithOthers
                ? (
                    <>
                        <RelatedObjectsExistInfoComponent
                            perspectiveData={perspectiveData} />
                        <GridCustom flexEnd>
                            <ButtonStyled cancel onClick={onCancel}>OK</ButtonStyled>
                        </GridCustom>
                    </>
                )
                : (
                    <>
                        <CancelSaveButtonsRow
                            disabled={deletingOn}
                            saveText="Usuń"
                            onCancel={onCancel}
                            onSave={() => setDeletingOn(true)} />
                        {!!fetchError && <Text error>{fetchError}</Text>}
                    </>
                )
            }

        </DialogCustom>
    )
};
