import React, { useContext } from 'react';

import { UserContext } from '../../../context/user-context';
import { InfoTextWithIcon } from '../../common';


const AlreadyPublishedInfo = ({info}) => {
    const { user } = useContext(UserContext);

    return <InfoTextWithIcon message={info || (
        user.advertisementManagerType.isAdmin
            ? 'Edytujesz opublikowane ogłoszenie jako pracownik instytucji.'
            : 'To ogłoszenie zostało już opublikowane i nie ma możliwości ' +
              'zmiany niektórych pól.'

    )} />
}

export { AlreadyPublishedInfo };
