import React, { useContext, useEffect, useState } from 'react';

import { PROJECTS_MANAGEMENTS_CHOICES } from '../../../const';
import { UserContext } from '../../../context/user-context';
import { usePaginatedFetchApi } from '../../../hooks';
import {
    getProjectsManagementsFiltersCount,
    PROJECTS_MANAGEMENTS_CLEANED_FILTERS
} from '../../../filters';
import { setInitialSorterByUrlParam } from '../../../sorters';
import {
    Breadcrumps,
    Container,
    Loader,
    NotPermittedPage,
    PaginatedListHeader,
    Pagination,
    SortersFiltersRow,
    TableCustom,
    Text
} from '../../common';
import { EditProjectForm } from './EditProjectForm';
import { ProjectListRow } from './ProjectListRow';
import { ProjectsFilters } from './ProjectsFilters';


const SORTERS = {
    type: ['type', 'Po kontekście', 0],
    userEmail: ['userEmail', 'Po adresie e-mail użytkownika', 1],
    fullNumber: ['fullNumber', 'Po numerze', 2],
};
const SORTING_VALUES = Object.values(SORTERS).sort((s1, s2) => s1[2] - s2[2]);
const DEFAULT_SORTER = 'type';
const OBJECTS_ATTR_NAME = 'projectsEnrollments';
const PAGINATED_BY = 20;
const BASE_API_URL = '/api/admin/project_enrollment';
const SERIALIZATION_URL_LIST = [
    ['userEmail', 'email'],
    ['userForename', 'imie'],
    ['userSurname', 'nazwisko'],
    ['type', 'typ'],
    ['fullNumber', 'numer'],
    ['name', 'nazwa'],
    ['perspective', 'perspektywa'],
    ['programme', 'programOperacyjny'],
    ['priority', 'osPriorytetowa'],
    ['activity', 'dzialanie'],
    ['institution', 'instytucja'],
    ['region', 'region'],
    ['page', 'strona'],
];
const SERIALIZATION_URL_DICT_TO_POLISH = {};
for (let [eng, pol] of SERIALIZATION_URL_LIST) {
    SERIALIZATION_URL_DICT_TO_POLISH[eng] = pol;
}


function setInitialPageNumber(urlParams) {
    let pageNumber = parseInt(urlParams.get('strona'));
    if (!(pageNumber > 0)) {
        pageNumber = 1;
    }
    return pageNumber
}

function setInitialFilters(urlParams) {
    let filters = {};
    for (let [name, value] of Object.entries(PROJECTS_MANAGEMENTS_CLEANED_FILTERS)) {
        let searchParamValue = urlParams.get(
            SERIALIZATION_URL_DICT_TO_POLISH[name]);
        if (name === 'type') {
            let filterSet = false;
            for (let type_ of PROJECTS_MANAGEMENTS_CHOICES) {
                if (searchParamValue && searchParamValue.toString() === type_[0]) {
                    filters[name] = {value: type_[0], label: type_[1]};
                    filterSet = true;
                    break;
                }
            }
            if (!filterSet) {
                filters[name] = value;
            }
        } else if ([
            'perspective', 'programme', 'priority', 'activity', 'institution',
            'region'
        ].includes(name)) {
            searchParamValue = parseInt(searchParamValue);
            // if it's possible to parse, set it, else set default value
            filters[name] = searchParamValue > 0 ? {value: searchParamValue} : value;
        } else {
            filters[name] = searchParamValue || value;
        }
    }
    return filters
}

function getFiltersFetchData(filters) {
    const data = {};
    for (let [name, value] of Object.entries(filters)) {
        if (['type', ].includes(name)) {
            if (['project', 'enrollment'].includes(value.value)) {
                data[name] = value.value;
            }
        } else if (value !== PROJECTS_MANAGEMENTS_CLEANED_FILTERS[name]) {
            if ([
                'perspective', 'programme', 'priority', 'activity',
                'institution', 'region'
            ].includes(name)) {
                data[name] = value.value;
            } else {
                data[name] = value.trim();
            }
        }
    }
    return data
}


export const Projects = (props) => {
    const params = new URLSearchParams(props.location.search);
    const [pageNumber, setPageNumber] = useState(setInitialPageNumber(params));

    const [selectedSort, setSelectedSort] = useState(null);
    const [filters, setFilters] = useState(null);
    const [filtersCounter, setFiltersCounter] = useState(0);
    const [filtersAreOn, setFiltersAreOn] = useState(false);

    function setInitialSorterAndFilters() {
        // configure sorter
        setSelectedSort(
            setInitialSorterByUrlParam(params, SORTERS, DEFAULT_SORTER));

        // configure filters
        const filters_ = setInitialFilters(params);
        setFilters(filters_);
        setFiltersCounter(getProjectsManagementsFiltersCount(filters_));
    }

    useEffect(setInitialSorterAndFilters, []);

    const {
        data, loadingOn, fetchError, notPermitted, permissionsCheckingOn,
        updateData
    } = usePaginatedFetchApi(
        pageNumber, selectedSort, filters, getFiltersFetchData,
        PAGINATED_BY, OBJECTS_ATTR_NAME, BASE_API_URL,
        SERIALIZATION_URL_DICT_TO_POLISH, {}, true);
    const [editedProject, setEditedProject] = useState(null);

    const { removePermission } = useContext(UserContext);
    useEffect(() => {
        if (notPermitted) {
            removePermission('projectsEnrollments');
        }
    }, [notPermitted]); // eslint-disable-line react-hooks/exhaustive-deps

    if (permissionsCheckingOn) {
        return <Loader />
    }

    if (notPermitted) {
        return <NotPermittedPage />
    }

    function handleChangeSort(selectedSort) {
        setPageNumber(1);
        setSelectedSort(selectedSort);
    }

    function handleOpenFilters() {
        setFiltersAreOn(true);
    }

    function handleCloseFilters() {
        setFiltersAreOn(false);
    }

    function handleUpdateFilters(filters) {
        setPageNumber(1);
        setFilters(filters);
        handleCloseFilters();
        setFiltersCounter(getProjectsManagementsFiltersCount(filters));
    }

    function handleFilterProjectsByUserEmail(userEmail) {
        setPageNumber(1);
        const sorter = SORTERS[DEFAULT_SORTER];
        setSelectedSort({value: sorter[0], label: sorter[1]});
        setFilters({...PROJECTS_MANAGEMENTS_CLEANED_FILTERS, userEmail});
    }

    function handleChangePage(next=true) {
        setPageNumber(prev => next ? prev + 1 : Math.max(prev -1, 1));
    }

    function handleSaveProject(projectId, data) {
        updateData(projectId, {name: data.name});
        setEditedProject(null);
    }

    return (
        <>
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__current">Administracja projektami i naborami</li>
            </Breadcrumps>
            <PaginatedListHeader
                title="Lista projektów i naborów"
                total={data ? data.total : 0} />
            <SortersFiltersRow
                // sorters
                selectedSort={selectedSort}
                sortingValues={SORTING_VALUES}
                onChangeSort={handleChangeSort}
                // filters
                filters={filters}
                filtersCounter={filtersCounter}
                filtersAreOn={filtersAreOn}
                FiltersComponent={ProjectsFilters}
                onCloseFilters={handleCloseFilters}
                onOpenFilters={handleOpenFilters}
                onUpdateFilters={handleUpdateFilters} />
            {loadingOn
                ? <Loader />
                : (fetchError
                    ? <Text error>{fetchError}</Text>
                    : (
                        data && data.objects.length > 0
                            ? (
                                <>
                                    <Container>
                                        <TableCustom
                                            tableHeaderList={['Kontekst', 'Użytkownik', 'Numer']}
                                        >
                                            {data.objects.map(project => (
                                                <ProjectListRow
                                                    key={project.id}
                                                    project={project}
                                                    onFilterProjectsByUserEmail={handleFilterProjectsByUserEmail}
                                                    onSelectProjectForEditing={project => setEditedProject(project)} />
                                            ))}
                                        </TableCustom>
                                        <Pagination
                                            currentPage={pageNumber}
                                            hasNext={data.hasNext}
                                            hasPrev={data.hasPrev}
                                            handleChangePage={handleChangePage} />
                                    </Container>
                                    {editedProject !== null && (
                                        <EditProjectForm
                                            project={editedProject}
                                            onClose={() => setEditedProject(null)}
                                            onSave={handleSaveProject} />
                                    )}
                                </>
                            )
                            : <Text error>Nie znaleziono projektów ani naborów spełniających kryteria wyszukiwania.</Text>
                    )
                )
            }
        </>
    )
};
