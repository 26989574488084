import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ButtonStyled, Ellipsis, FieldWithLabel, GridCustom, Text } from '../../common';


const Ogloszenie = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const ogloszenie = props.ogloszenie;
    const wersja = ogloszenie.wersja;

    return (
        <section className="separate-content">
            <Ellipsis>
                <Text className="text--title">{wersja.tytul || '-'}</Text>
            </Ellipsis>
            <GridCustom>
                <FieldWithLabel label="Numer">
                    <Text>{ogloszenie.numer || '-'}</Text>
                </FieldWithLabel>
                {showDetails && (
                    <FieldWithLabel label="Status">
                        <Text>{ogloszenie.status.nazwa}</Text>
                    </FieldWithLabel>
                )}
            </GridCustom>
            {showDetails && (
                <GridCustom>
                    <FieldWithLabel label="Data publikacji">
                        <Text>{wersja.dataOpublikowania || '-'}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Termin składania ofert">
                        <Text>{wersja.terminOfert || '-'}</Text>
                    </FieldWithLabel>
                </GridCustom>
            )}
            <ButtonStyled
                lite
                onClick={() => setShowDetails(prevShowDetails => !prevShowDetails)}
                aria-label={(showDetails ? 'Zwiń' : 'Rozwiń') + ' szczegóły ogłoszenia ' + (wersja.tytul || 'numer' + ogloszenie.numer)}
            >
                {(showDetails ? 'Zwiń ' : 'Rozwiń ') + ' szczegóły ogłoszenia'}
            </ButtonStyled>
            <ButtonStyled
                tag={Link}
                to={`/ogloszenia/${ogloszenie.id}${ogloszenie.status.label === 'DRAFT' ? '/robocza' : ''}`}
                primary
                aria-label={'Przejdź do ogłoszenia ' + (wersja.tytul || 'numer ' + ogloszenie.numer)} >
                Przejdź do ogłoszenia
            </ButtonStyled>
        </section>
    );
}

export { Ogloszenie };
