import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';
import { Icon } from '@rmwc/icon';

import { UserContext } from '../../../context/user-context';
import { URLS } from '../../../urls/frontend';
import { ButtonStyled, Ellipsis, MoreOptionsButton, Text } from '../../common';


const STATUS_ICONS = {
    DRAFT: 'radio_button_unchecked',
    PUBLISHED: 'calendar_today',
    CLOSED: 'event_busy',
    CANCELLED: 'arrow_circle_down',
    LOCKED: 'highlight_off',
    RESOLVED: 'event_available',
};


export const AdvertisementListRow = ({
    advertisement, onFilterAdvertisementByUserEmail
}) => {
    let history = useHistory();
    const {
        advertisement: advertisement_,
        publicationDate: versionPublicationDate,
        submissionDeadline,
    } = advertisement;
    const {
        createdBy: user,
        status,
        publicationDate: advertisementPublicationDate,
    } = advertisement_;

    const handleFilterProjectsByUserEmail = () => {
        history.push(
            `${URLS.managementProjects.path}?email=${user.email}&sort=type&strona=1`);
    };

    const handleGoToManageUserPage = () => {
        history.push(URLS.usersDetails.getUrl(user.id));
    };

    const handleFilterAdvertisementsByUserEmail = () => {
        onFilterAdvertisementByUserEmail(user.email);
    };

    const { permissions } = useContext(UserContext);

    return (
        <DataTableRow>
            <DataTableCell>
                <div className="table--administration__meta">
                    <Link to={`/ogloszenia/${advertisement_.id}${status.label === 'DRAFT' ? '/robocza' : ''}`} className="link-text">
                        {advertisement_.number}
                    </Link>
                    <Ellipsis lineHeight={19} lines={2}>
                        <Text use="caption" className="table--administration__user-info">{advertisement.title}</Text>
                    </Ellipsis>
                </div>
            </DataTableCell>
            <DataTableCell>
                <div className={`table--administration__type-col ${status.label === 'CANCELLED' ? 'type-cancelled' : ''}`}>
                    <Icon aria-hidden="true" icon={STATUS_ICONS[status.label]} />
                    <Text>{status.name}</Text>
                </div>
            </DataTableCell>
            <DataTableCell>
                <div className="table--administration__action-col">
                    <div className="table--administration__meta">
                        <Text>{user.forename} {user.surname}</Text>
                        <Text use="caption" className="table--administration__user-info">{user.email}</Text>
                    </div>
                    <MoreOptionsButton menuAriaLabel="Otwórz menu użytkownika: zarządzaj użytkownikiem, filtruj projekty i nabory dla tego użytkownika, filtruj ogłoszenia dla tego użytkownika.">
                        <Text use="caption" className="table--administration__user-id">ID {user.identifier}</Text>
                        <ul className="table--administration__user-menu-list">
                            {permissions.fullUsersAndPermissions &&
                                <li>
                                    <ButtonStyled noMargin onClick={handleGoToManageUserPage}>
                                        Zarządzaj użytkownikiem
                                    </ButtonStyled>
                                </li>
                            }
                            {permissions.projectsEnrollments &&
                                <li>
                                    <ButtonStyled noMargin onClick={handleFilterProjectsByUserEmail}>
                                        Filtruj projekty i nabory
                                    </ButtonStyled>
                                </li>
                            }
                            <li><ButtonStyled noMargin onClick={handleFilterAdvertisementsByUserEmail}>Filtruj ogłoszenia</ButtonStyled></li>
                        </ul>
                    </MoreOptionsButton>
                </div>
            </DataTableCell>
            <DataTableCell>
                <div className="table--administration__meta">
                    {status.label === 'DRAFT' ? (
                        <>
                            <Text use="caption" className="table--administration__user-info">
                                {advertisementPublicationDate
                                    ? `zaplanowana publikacja: ${advertisementPublicationDate}`
                                    : 'jeszcze nie opublikowane'
                                }
                            </Text>
                            {submissionDeadline?.length > 0 && (
                                <Text use="caption" className="table--administration__user-info">
                                    termin: {submissionDeadline}
                                </Text>
                            )}
                        </>
                    ) : (
                        ['CANCELLED', 'LOCKED'].includes(status.label)
                            ? (
                                <Text use="caption" className="table--administration__user-info">
                                    publikacja: {advertisementPublicationDate}
                                </Text>
                            )
                            : (
                                <>
                                    <Text use="caption" className="table--administration__user-info">
                                        publikacja: {advertisementPublicationDate}
                                    </Text>
                                    {advertisementPublicationDate !== versionPublicationDate && (
                                        <Text use="caption" className="table--administration__user-info last-change">
                                            ost. zmiana: {versionPublicationDate}
                                        </Text>
                                    )}
                                    <Text use="caption" className="table--administration__user-info">
                                        termin: {submissionDeadline}
                                    </Text>
                                </>
                            )
                    )}
                </div>
            </DataTableCell>
        </DataTableRow>
    )
};
