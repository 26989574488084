import React, { Component } from "react";
import { Icon } from "@rmwc/icon";

import {
  ButtonStyled,
  Container,
  FieldWithLabel,
  GridCustom,
  Text,
} from "../../common";
import { BadgeAnchor, Badge } from "@rmwc/badge";
import "@rmwc/badge/badge.css";
import { fetchApi } from "../../../fetch";
import { MenuContext } from "../../../context/menuContext";

class Pytanie extends Component {
  static contextType = MenuContext;

  // handlers

  handleAnswer = (e) => {
    this.props.onClickAnswer(this.props.question.id);
    e.currentTarget.blur();
  };

  handleRemoveBadge = () => {
    fetchApi(
      `/api/badge/question/${this.props.question.id}`,
      "DELETE",
      {},
      {},
      () => {
        this.props.reloadAnnouncement();
      }
    );
  };

  // rendering
  render() {
    const { jestAutorem, tresc, trescOryginalna, badge } = this.props.question;
    const { refreshBadges } = this.context;

    return (
      <Container containerTag="li" className="question">
        {jestAutorem && !this.props.noAuthorLabel && (
          <Text className="author-label">Moje pytanie</Text>
        )}
        <FieldWithLabel label={this.renderPytanieLabel()}>
          {trescOryginalna && trescOryginalna !== tresc && (
            <>
              <Text className="question__original-content--header">
                Zmieniono treść pytania. Oryginalna treść:
              </Text>
              <Text className="question__original-content long-text">
                {trescOryginalna}
              </Text>
            </>
          )}
          <Text className="long-text">
            {trescOryginalna && trescOryginalna !== tresc && (
              <span className="sr-only">Wyświetlana treść: </span>
            )}
            {tresc || ""}
          </Text>
        </FieldWithLabel>
        {this.renderOdpowiedz()}
        {this.props.allowAnswer && (
          <GridCustom flexEnd>{this.renderPytanieButton()}</GridCustom>
        )}
        {badge && (
          <GridCustom flexEnd>
            <BadgeAnchor>
              <ButtonStyled
                onClick={() => {
                  this.handleRemoveBadge();
                  refreshBadges();
                }}
                primary
                icon="check"
              >
                USUŃ OZNACZENIE JAKO NOWE
                <span className="sr-only"> dla pytania {this.renderPytanieLabel()}</span>
              </ButtonStyled>
              <Badge label="N" />
            </BadgeAnchor>
          </GridCustom>
        )}
      </Container>
    );
  }

  renderPytanieLabel() {
    const { dataOpublikowania, dataUtworzenia, numer, status } =
      this.props.question;

    let questionLabel = `Pytanie numer ${numer}, data wysłania ${dataUtworzenia}`;

    if (status.label === "PUBLISHED") {
      questionLabel += `, data opublikowania ${dataOpublikowania}`;
    }

    return questionLabel;
  }

  renderOdpowiedzLabel() {
    if (this.props.question.status.label === "PUBLISHED") {
      return "Udzielono odpowiedzi";
    } else {
      return "Robocza wersja odpowiedzi";
    }
  }

  renderOdpowiedz() {
    if (this.props.question.odpowiedz) {
      return (
        <FieldWithLabel
          label={this.renderOdpowiedzLabel()}
          className="question__answer"
        >
          <Icon
            aria-hidden="true"
            icon="subdirectory_arrow_right"
            className="question__answer-icon"
          />
          <Text className="long-text">
            {this.props.question.odpowiedz.tresc}
          </Text>
        </FieldWithLabel>
      );
    }

    return <Text info>Brak odpowiedzi</Text>;
  }

  renderPytanieButton() {
    const { status } = this.props.question;

    if (!this.props.annoucementOwner) {
      return null;
    }
    switch (status.label) {
      case "DRAFT":
        return (
          <ButtonStyled lite noMargin onClick={this.handleAnswer}>
            Edytuj / Publikuj
            <span className="sr-only"> {this.renderPytanieLabel()}</span>
          </ButtonStyled>
        );
      case "PUBLISHED":
        return (
          <ButtonStyled lite noMargin onClick={this.handleAnswer}>
            Edytuj<span className="sr-only"> {this.renderPytanieLabel()}</span>
          </ButtonStyled>
        );
      case "NEW":
        return (
          <ButtonStyled primary noMargin onClick={this.handleAnswer}>
            Udziel odpowiedzi
            <span className="sr-only"> na {this.renderPytanieLabel()}</span>
          </ButtonStyled>
        );
      default:
        return null;
    }
  }
}

export { Pytanie };
