import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';
import { Card, CardPrimaryAction, CardMedia } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import '@material/card/dist/mdc.card.css';

import { UserContext } from '../../../context/user-context';
import { refreshTimerAfterSativaRequest } from '../../../helpers';
import { NotPermittedPage, Text } from '../../common';


const ZarzadzanieTrescia = (props) => {
    const [timerRefreshed, setTimerRefreshed] = useState(false);
    if (!timerRefreshed) {
        refreshTimerAfterSativaRequest();
        setTimerRefreshed(true);
    }

    useEffect(() => {
        document.title = 'Baza Konkurencyjności - zarządzanie treścią';
    }, [])

    const { permissions } = useContext(UserContext);

    if (!permissions.fullContent) { return <NotPermittedPage /> }

    return (
        <>
            <Text tag="h2" mainHeader>Administracja treściami</Text>
            <nav>
                <ul className="content-manage grid-custom grid-custom--center">
                    {permissions.help && (
                        <li>
                            <Card tag={Link} to='/zarzadzanie-trescia/pomoc' className="content-manage__item">
                                <CardPrimaryAction>
                                    <CardMedia className="content-manage__icon">
                                        <Icon icon={{ icon: 'contact_support', size: 'xlarge' }} aria-hidden="true"/>
                                    </CardMedia>
                                    <Typography use="headline6" tag="h2" className="content-manage__text">Pomoc</Typography>
                                </CardPrimaryAction>
                            </Card>
                        </li>
                    )}
                    {permissions.generalContentCookiesRegulation && (
                        <>
                            <li>
                                <Card tag={Link} to='/zarzadzanie-trescia/regulaminy' className="content-manage__item">
                                    <CardPrimaryAction>
                                        <CardMedia className="content-manage__icon">
                                            <Icon icon={{ icon: 'menu_book', size: 'xlarge' }} aria-hidden="true"/>
                                        </CardMedia>
                                        <Typography use="headline6" tag="h2" className="content-manage__text">Regulamin</Typography>
                                    </CardPrimaryAction>
                                </Card>
                            </li>
                            <li>
                                <Card tag={Link} to='/zarzadzanie-trescia/ciasteczka' className="content-manage__item">
                                    <CardPrimaryAction>
                                        <CardMedia className="content-manage__icon">
                                            <Icon icon={{ icon: 'verified_user', size: 'xlarge' }} aria-hidden="true"/>
                                        </CardMedia>
                                        <Typography use="headline6" tag="h2" className="content-manage__text">Ciasteczka</Typography>
                                    </CardPrimaryAction>
                                </Card>
                            </li>
                            <li>
                                <Card tag={Link} to='/zarzadzanie-trescia/tresci-ogolne' className="content-manage__item">
                                    <CardPrimaryAction>
                                        <CardMedia className="content-manage__icon">
                                            <Icon icon={{ icon: 'view_quilt', size: 'xlarge' }} aria-hidden="true"/>
                                        </CardMedia>
                                        <Typography use="headline6" tag="h2" className="content-manage__text">Treści ogólne</Typography>
                                    </CardPrimaryAction>
                                </Card>
                            </li>
                        </>
                    )}
                    {permissions.statements && (
                        <li>
                            <Card tag={Link} to='/zarzadzanie-trescia/komunikaty' className="content-manage__item">
                                <CardPrimaryAction>
                                    <CardMedia className="content-manage__icon">
                                        <Icon icon={{ icon: 'announcement', size: 'xlarge' }} aria-hidden="true"/>
                                    </CardMedia>
                                    <Typography use="headline6" tag="h2" className="content-manage__text">Komunikaty</Typography>
                                </CardPrimaryAction>
                            </Card>
                        </li>
                    )}
                </ul>
            </nav>
        </>
    )
}


export { ZarzadzanieTrescia };
