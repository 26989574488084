import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';

import { UserContext } from '../../../context/user-context';
import { ButtonStyled, MoreOptionsButton, Text } from '../../common';


export const ImplementationLevelTableRow = ({
    getItemLinkFunction = (() => '/'),
    item,
    perspectiveData = {},
    withLink = false,
    onDelete,
    onEdit
}) => {
    const { permissions } = useContext(UserContext);
    return (
        <DataTableRow>
            <DataTableCell>
                <Text>{item.code}</Text>
            </DataTableCell>
            <DataTableCell>
                {withLink
                    ? (
                        <Link
                            className="link-text administration__table-link"
                            to={getItemLinkFunction(item, perspectiveData)}
                        >
                            {item.name}
                        </Link>
                    )
                    : <Text>{item.name}</Text>
                }
            </DataTableCell>
            {permissions.editingDictionaries && (
                <DataTableCell>
                    <MoreOptionsButton
                        className="administration__more-options"
                        menuAriaLabel="Otwórz menu: Zmien nazwę lub usuń"
                    >
                        <ul className="permissions__more-options-list">
                            <li>
                                <ButtonStyled noMargin onClick={() => onEdit(item)}>
                                    Zmień nazwę
                                </ButtonStyled>
                            </li>
                            <li>
                                <ButtonStyled noMargin onClick={() => onDelete(item)}>
                                    Usuń
                                </ButtonStyled>
                            </li>
                        </ul>
                    </MoreOptionsButton>
                </DataTableCell>
            )}

        </DataTableRow>
    )
};
