import React from 'react';
import { Icon } from '@rmwc/icon';
import {
    DataTable,
    DataTableBody,
    DataTableCell,
    DataTableContent,
    DataTableRow,
} from '@rmwc/data-table';

import { UserContext } from '../../../context/user-context';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    Breadcrumps,
    ButtonStyled,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';
import { colors } from '../../vars/vars';
import { BaseComponent } from '../common';


class Komunikaty extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        this.setState({
            initializingOn: false,
            komunikaty: getSerializedObject(
                data,
                {
                    nonRecursiveKeys: ['tresc', ],
                    dateAttributes: ['dataOpublikowania', ],
                    dateTimeAttributes: ['dataOpublikowania', ],
                }
            ).komunikaty,
        });
    }

    // helpers

    getAdditionalState(props) {
        return {komunikaty: []}
    }

    getFetchUrl() {
        return API_URLS.statementsAdmin.path
    }

    getFetchError(message) {
        return `Nie udało się pobrać komunikatów. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania komunikatów wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.context.removePermission('statements');
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__current">Komunikaty</li>
                </Breadcrumps>
                <GridCustom fullwidth centerVertical>
                    <Text tag="h2" mainHeader>Komunikaty</Text>
                    <ButtonStyled add onClick={() => this.props.history.push('/zarzadzanie-trescia/komunikaty/nowy')}>Dodaj komunikat</ButtonStyled>
                </GridCustom>
            </>
        )
    }

    renderContent() {
        if (!this.state.komunikaty.length) {
            return <Text error role="alert">Nie dodano jeszcze żadnego komunikatu.</Text>
        }
        const sort = (k1, k2) => {
            return k1.id > k2.id ? -1 : 1
        };
        return (
            <DataTable className="table">
                <DataTableContent>
                    <DataTableBody>
                        {this.state.komunikaty.sort(sort).map(k => (
                            <DataTableRow key={k.id}>
                                <DataTableCell>
                                    <Text>{k.naglowek}</Text>
                                    <GridCustom>
                                        <FieldWithLabel label="Aktywny">
                                            {k.czyAktywny ? (
                                                <Icon aria-hidden="true" icon="check_circle" style={{ color: colors.secondary }} title="Tak" />
                                            ) : (
                                                <Icon aria-hidden="true" icon="cancel" style={{ color: colors.textHintOnLight }} title="Nie" />
                                            )}
                                        </FieldWithLabel>
                                        <FieldWithLabel label="Żółty pasek">
                                            {k.czyZoltyPasek ? (
                                                <Icon aria-hidden="true" icon="check_circle" style={{ color: colors.secondary }} title="Tak" />
                                            ) : (
                                                <Icon aria-hidden="true" icon="cancel" style={{ color: colors.textHintOnLight }} title="Nie" />
                                            )}
                                        </FieldWithLabel>
                                        <FieldWithLabel label="Data opublikowania">
                                            {k.dataOpublikowania || '-'}
                                        </FieldWithLabel>
                                    </GridCustom>
                                </DataTableCell>
                                <DataTableCell>
                                    <ButtonStyled lite onClick={() => this.props.history.push(`/zarzadzanie-trescia/komunikaty/${k.id}-${k.slug}/edytuj`)}>Edytuj</ButtonStyled>
                                    <ButtonStyled primary onClick={() => this.props.history.push(`/zarzadzanie-trescia/komunikaty/${k.id}-${k.slug}`)}>Szczegóły</ButtonStyled>
                                </DataTableCell>
                            </DataTableRow>
                        ))}
                    </DataTableBody>
                </DataTableContent>
            </DataTable>
        )
    }
}

Komunikaty.contextType = UserContext;

export { Komunikaty };
