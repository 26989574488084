import React, { useContext, useEffect, useState } from 'react';

import {
    ADVERTISEMENT_STATUS_FILTERS_CHOICES,
    RODZAJ_ADRESU_FILTERS_CHOICES,
    TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES
} from '../../../const';
import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import {
    ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS,
    getAdvertisementsManagementsFiltersCount
} from '../../../filters';
import { usePaginatedFetchApi } from '../../../hooks';
import { setInitialSorterByUrlParam } from '../../../sorters';
import {
    Breadcrumps,
    Container,
    Loader,
    NotPermittedPage,
    PaginatedListHeader,
    Pagination,
    SortersFiltersRow,
    TableCustom,
    Text
} from '../../common';
import { AdvertisementListRow } from './AdvertisementListRow';
import { AdvertisementsFilters } from './AdvertisementsFilters';


const SORTERS = {
    announcementStatus_asc:
        ['announcementStatus_asc', 'Po statusie rosnąco', 0],
    announcementStatus_desc:
        ['announcementStatus_desc', 'Po statusie malejąco', 1],
    announcementNumber_asc:
        ['announcementNumber_asc', 'Po numerze ogłoszenia rosnąco', 2],
    announcementNumber_desc:
        ['announcementNumber_desc', 'Po numerze ogłoszenia malejąco', 3],
    userEmail_asc: [
        'userEmail_asc',
        'Po e-mailu użytkownika, który dodał ogłoszenie rosnąco', 4],
    userEmail_desc: [
        'userEmail_desc',
        'Po e-mailu użytkownika, który dodał ogłoszenie malejąco', 5],
    announcementPublicationDate_asc: [
        'announcementPublicationDate_asc',
        'Po dacie opublikowania ogłoszenia rosnąco', 6],
    announcementPublicationDate_desc: [
        'announcementPublicationDate_desc',
        'Po dacie opublikowania ogłoszenia malejąco', 7],
    submissionDeadline_asc: [
        'submissionDeadline_asc', 'Po terminie składania ofert rosnąco', 8],
    submissionDeadline_desc: [
        'submissionDeadline_desc', 'Po terminie składania ofert malejąco', 9],
    title_asc: ['title_asc', 'Po tytule ogłoszenia rosnąco', 10],
    title_desc: ['title_desc', 'Po tytule ogłoszenia malejąco', 11],
};
const SORTING_VALUES = Object.values(SORTERS).sort((s1, s2) => s1[2] - s2[2]);
const DEFAULT_SORTER = 'announcementNumber_desc';
const OBJECTS_ATTR_NAME = 'versions';
const PAGINATED_BY = 20;
const BASE_API_URL = '/api/admin/announcements';
const SERIALIZER_CONFIG = {
    dateAttributes: ['publicationDate', 'submissionDeadline'],
    dateTimeAttributes: ['submissionDeadline', ],
    dateTimeToLastMinuteAttributes: ['submissionDeadline', ],
    removeTemporaryId: true,
};
const SERIALIZATION_URL_LIST = [
    ['userEmail', 'email'],
    ['userForename', 'imie'],
    ['userSurname', 'nazwisko'],
    ['advertiserName', 'nazwaPodmiotu'],
    ['advertiserAddressType', 'typAdresu'],
    ['advertiserIdentificationNumberType', 'typNumeruIdent'],
    ['advertiserIdentificationNumber', 'numerIdent'],
    ['advertiserAddressCountry', 'kraj'],
    ['advertiserAddressVoivodeship', 'woj'],
    ['advertiserAddressPoviat', 'pow'],
    ['advertiserAddressCommune', 'gm'],
    ['advertiserAddressLocality', 'miejscowosc'],
    ['announcementStatus', 'statusOgl'],
    ['announcementNumber', 'numerOgl'],
    ['title', 'tytul'],
    ['announcementProjectEnrollmentPerspective', 'perspektywa'],
    ['announcementProjectEnrollmentProgramme', 'programOperacyjny'],
    ['announcementProjectEnrollmentPriority', 'osPriorytetowa'],
    ['announcementProjectEnrollmentActivity', 'dzialanie'],
    ['announcementProjectEnrollmentInstitution', 'instytucja'],
    ['announcementProjectEnrollmentRegion', 'region'],
    ['announcementProjectEnrollmentFullNumber', 'numerProj'],
    ['announcementPublicationDateFrom', 'dataPublOd'],
    ['announcementPublicationDateTo', 'dataPublDo'],
    ['submissionDeadlineFrom', 'terminOfertOd'],
    ['submissionDeadlineTo', 'terminOfertDo'],
    ['page', 'strona'],
];
const SERIALIZATION_URL_DICT_TO_POLISH = {};
for (let [eng, pol] of SERIALIZATION_URL_LIST) {
    SERIALIZATION_URL_DICT_TO_POLISH[eng] = pol;
}

const IMPLEMENTATION_LEVEL_SELECTS_NAMES_LIST = [
    'announcementProjectEnrollmentPerspective',
    'announcementProjectEnrollmentProgramme',
    'announcementProjectEnrollmentPriority',
    'announcementProjectEnrollmentActivity',
    'announcementProjectEnrollmentInstitution',
    'announcementProjectEnrollmentRegion'
];


function setInitialPageNumber(urlParams) {
    let pageNumber = parseInt(urlParams.get('strona'));
    if (!(pageNumber > 0)) {
        pageNumber = 1;
    }
    return pageNumber
}

function setInitialFilters(urlParams) {
    // set some default values
    let filters = {
        advertiserAddressType:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserAddressType,
        advertiserIdentificationNumberType:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserIdentificationNumberType,
        advertiserIdentificationNumber:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserIdentificationNumber,
        advertiserAddressCountry:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserAddressCountry,
        advertiserAddressVoivodeship:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserAddressVoivodeship,
        advertiserAddressPoviat:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserAddressPoviat,
        advertiserAddressCommune:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserAddressCommune,
        advertiserAddressLocality:
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS.advertiserAddressLocality,
    };

    const setValue = (valueName) => {
        const value = urlParams.get(SERIALIZATION_URL_DICT_TO_POLISH[valueName]);
        if (value && value.length) {
            filters[valueName] = value;
        }
    };

    for (let [name, value] of Object.entries(
            ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS)) {
        if ([
            'advertiserIdentificationNumberType',
            'advertiserIdentificationNumber',
            'advertiserAddressCountry',
            'advertiserAddressVoivodeship',
            'advertiserAddressPoviat',
            'advertiserAddressCommune',
            'advertiserAddressLocality'
        ].includes(name)) { continue }
        let searchParamValue = urlParams.get(
            SERIALIZATION_URL_DICT_TO_POLISH[name]);
        if (name === 'advertiserAddressType') {
            for (let choice of RODZAJ_ADRESU_FILTERS_CHOICES) {
                if (searchParamValue && choice.value === searchParamValue) {
                    filters[name] = choice;
                    if (searchParamValue === 'poland') {

                        // set locality if 4 parameters chosen
                        const advertiserAddressVoivodeship = urlParams.get(
                            SERIALIZATION_URL_DICT_TO_POLISH['advertiserAddressVoivodeship']) || null;
                        const advertiserAddressPoviat = urlParams.get(
                            SERIALIZATION_URL_DICT_TO_POLISH['advertiserAddressPoviat']) || null;
                        const advertiserAddressCommune = urlParams.get(
                            SERIALIZATION_URL_DICT_TO_POLISH['advertiserAddressCommune']) || null;
                        const advertiserAddressLocality = urlParams.get(
                            SERIALIZATION_URL_DICT_TO_POLISH['advertiserAddressLocality']) || null;
                        if (advertiserAddressVoivodeship
                            && advertiserAddressPoviat
                            && advertiserAddressCommune
                            && advertiserAddressLocality
                        ) {
                            filters = {...filters, ...{
                                advertiserAddressVoivodeship,
                                advertiserAddressPoviat,
                                advertiserAddressCommune,
                                advertiserAddressLocality
                            }};
                        }

                        const advertiserIdentificationNumberType = urlParams.get(
                            SERIALIZATION_URL_DICT_TO_POLISH['advertiserIdentificationNumberType']) || '';
                        if (!advertiserIdentificationNumberType.length) { break }

                        // find correct advertiserIdentificationNumberType
                        for (let type_ of TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES) {
                            if (advertiserIdentificationNumberType === type_.value) {
                                filters['advertiserIdentificationNumberType'] = type_;
                                // find advertiserIdentificationNumber
                                setValue('advertiserIdentificationNumber');
                                break
                            }
                        }
                    } else {
                        filters['advertiserIdentificationNumberType'] =
                            TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES.filter(
                                choice => choice.value === 'all'
                            )[0];
                        if (searchParamValue === 'abroad') {
                            // find advertiserIdentificationNumber
                            setValue('advertiserIdentificationNumber');
                            // find advertiserAddressCountry
                            setValue('advertiserAddressCountry');
                            // find advertiserAddressLocality
                            setValue('advertiserAddressLocality');
                        }
                    }
                    break
                }
            }
        } else if (name === 'announcementStatus') {
            // set from choices
            let filterSet = false;
            for (let choice of ADVERTISEMENT_STATUS_FILTERS_CHOICES) {
                if (searchParamValue && choice.value === searchParamValue) {
                    filters[name] = choice;
                    filterSet = true;
                    break
                }
            }
            if (!filterSet) {
                // set default value
                filters[name] = value;
            }
        } else if (IMPLEMENTATION_LEVEL_SELECTS_NAMES_LIST.includes(name)) {
            searchParamValue = parseInt(searchParamValue);
            // if it's possible to parse, set them, else set default value
            filters[name] = searchParamValue > 0
                ? {value: searchParamValue} : value;
        } else if ([
            // date fields
            'announcementPublicationDateFrom', 'announcementPublicationDateTo',
            'submissionDeadlineFrom', 'submissionDeadlineTo'
        ].includes(name)) {
            filters[name] = /\d{4}-\d{2}-\d{2}/.test(searchParamValue)
                ? searchParamValue : value;
        } else {
            filters[name] = searchParamValue || value;
        }
    }
    return filters
}

function getFiltersFetchData(filters) {
    const data = {};
    for (let [name, value] of Object.entries(filters)) {
        if ([
            'advertiserAddressType',
            'advertiserIdentificationNumberType',
            'announcementStatus'
        ].includes(name)) {
            data[name] = value.value;
        } else if (value !== ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS[name]) {
            if (IMPLEMENTATION_LEVEL_SELECTS_NAMES_LIST.includes(name)
            ) {
                data[name] = value.value;
            } else {
                data[name] = value.trim();
            }
        }
    }
    return data
}


export const Advertisements = (props) => {
    const params = new URLSearchParams(props.location.search);

    const [pageNumber, setPageNumber] = useState(setInitialPageNumber(params));

    const [selectedSort, setSelectedSort] = useState(null);
    const [filters, setFilters] = useState(null);
    const [filtersCounter, setFiltersCounter] = useState(0);
    const [filtersAreOn, setFiltersAreOn] = useState(false);

    function setInitialSorterAndFilters() {
        // configure sorter
        setSelectedSort(
            setInitialSorterByUrlParam(params, SORTERS, DEFAULT_SORTER));

        // configure filters
        const setCleanedLocalityFilters = (filtersData) => {
            filtersData = {...filtersData, ...{
                advertiserAddressVoivodeship: '',
                advertiserAddressPoviat: '',
                advertiserAddressCommune: '',
                advertiserAddressLocality: '',
            }};
            setFilters(filtersData);
            setFiltersCounter(getAdvertisementsManagementsFiltersCount(filtersData));
        }
        const filters_ = setInitialFilters(params);
        let xhrFetch = null;
        if (filters_.advertiserAddressType.value === 'poland' && filters_.advertiserAddressLocality.length) {
            xhrFetch = fetchApi(
                '/api/dictionaries/teryt',
                'GET',
                {},
                {locality_search_key: filters_.advertiserAddressLocality},
                (data) => {
                    for (let {voivodeship, poviat, commune, locality} of data.teryt_items || []) {
                        // if fits, set it as correct filters
                        if (voivodeship === filters_.advertiserAddressVoivodeship
                            && poviat === filters_.advertiserAddressPoviat
                            && commune === filters_.advertiserAddressCommune
                            && locality === filters_.advertiserAddressLocality
                        ) {
                            setFilters(filters_);
                            setFiltersCounter(getAdvertisementsManagementsFiltersCount(filters_));
                            return
                        }
                    }
                    setCleanedLocalityFilters(filters_);
                },
                () => setCleanedLocalityFilters(filters_),
                () => setCleanedLocalityFilters(filters_)
            );
        } else {
            setFilters(filters_);
            setFiltersCounter(getAdvertisementsManagementsFiltersCount(filters_));
        }
        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }
    useEffect(setInitialSorterAndFilters, []);

    const {
        data, loadingOn, fetchError, notPermitted, permissionsCheckingOn
    } = usePaginatedFetchApi(
        pageNumber, selectedSort, filters, getFiltersFetchData,
        PAGINATED_BY, OBJECTS_ATTR_NAME, BASE_API_URL,
        SERIALIZATION_URL_DICT_TO_POLISH, SERIALIZER_CONFIG);

    const { removePermission } = useContext(UserContext);
    useEffect(() => {
        if (notPermitted) {
            removePermission('advertisements');
        }
    }, [notPermitted]); // eslint-disable-line react-hooks/exhaustive-deps

    if (permissionsCheckingOn) {
        return <Loader />
    }

    if (notPermitted) {
        return <NotPermittedPage />
    }

    function handleChangePage(next=true) {
        setPageNumber(prev => next ? prev + 1 : Math.max(prev -1, 1));
    }

    function handleChangeSort(selectedSort) {
        setPageNumber(1);
        setSelectedSort(selectedSort);
    }

    function handleOpenFilters() {
        setFiltersAreOn(true);
    }

    function handleCloseFilters() {
        setFiltersAreOn(false);
    }

    function handleUpdateFilters(filters) {
        setPageNumber(1);
        setFilters(filters);
        handleCloseFilters();
        setFiltersCounter(getAdvertisementsManagementsFiltersCount(filters));
    }

    function handleFilterAdvertisementsByUserEmail(userEmail) {
        setPageNumber(1);
        const sorter = SORTERS[DEFAULT_SORTER];
        setSelectedSort({value: sorter[0], label: sorter[1]});
        setFilters({...ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS, userEmail});
    }

    return (
        <>
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__current">Administracja ogłoszeniami</li>
            </Breadcrumps>
            <PaginatedListHeader
                title="Lista ogłoszeń"
                total={data ? data.total : 0} />
            <SortersFiltersRow
                // sorters
                selectedSort={selectedSort}
                sortingValues={SORTING_VALUES}
                onChangeSort={handleChangeSort}
                // filters
                filters={filters}
                filtersCounter={filtersCounter}
                filtersAreOn={filtersAreOn}
                FiltersComponent={AdvertisementsFilters}
                onCloseFilters={handleCloseFilters}
                onOpenFilters={handleOpenFilters}
                onUpdateFilters={handleUpdateFilters} />
            {loadingOn
                ? <Loader />
                : (fetchError
                    ? <Text error>{fetchError}</Text>
                    : (
                        data && data.objects.length > 0
                            ? (
                                <>
                                    <Container>
                                        <TableCustom
                                            tableHeaderList={['Oznaczenie', 'Status', 'Użytkownik', 'Daty']}
                                        >
                                            {data.objects.map(advertisement => (
                                                <AdvertisementListRow
                                                    advertisement={advertisement}
                                                    key={advertisement.id}
                                                    onFilterAdvertisementByUserEmail={handleFilterAdvertisementsByUserEmail} />
                                            ))}
                                        </TableCustom>
                                        <Pagination
                                            currentPage={pageNumber}
                                            hasNext={data.hasNext}
                                            hasPrev={data.hasPrev}
                                            handleChangePage={handleChangePage} />
                                    </Container>
                                </>
                            )
                            : <Text error>Nie znaleziono ogłoszeń spełniających kryteria wyszukiwania.</Text>
                    )
                )
            }
        </>
    )
};
