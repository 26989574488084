import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GridCustom, ButtonStyled, Container } from "../../common";
import { Icon } from "@rmwc/icon";
import { Typography } from "@rmwc/typography";
import { URLS } from "../../../urls/frontend";
import { fetchApi } from "../../../fetch";
import { UnsubscribeSuccess } from "./UnsubscribeSuccess";

const UnsubscribeConfirmation = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [token, setToken] = useState(undefined);

  useEffect(() => {
    const token = location.search?.split("?token=")?.[1];
    if (token) {
      setToken(token);
    } else {
      handleGoToMainPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleGoToMainPage = () => {
    history.push(URLS.home.path);
  };

  const handleError = (error) => {
    throw new Error(error);
  };

  const handleConfirm = () => {
    fetchApi(
      `/api/notification-opt-out-link/${token}`,
      "POST",
      {},
      {},
      () => {
        props.setConfirmed(true);
      },
      handleError,
      handleError
    );
  };

  return (
    <Container>
      <GridCustom centerHorizontal>
        <Icon
          className="subscription-icon"
          icon={{ icon: "help_outline" }}
          aria-hidden="true"
        />
      </GridCustom>
      <GridCustom fullheight centerHorizontal>
        <Typography
          use="headline2"
          tag="h2"
          style={{ textAlign: "center", margin: "3rem 0" }}
        >
          Czy na pewno chcesz wyłączyć <br />
          newsletter i powiadomienia?
        </Typography>
      </GridCustom>
      <GridCustom fullheight centerHorizontal>
        <ButtonStyled primary className="search__btn" onClick={handleConfirm}>
          TAK, CHCĘ WYŁĄCZYĆ NEWSLETTER I POWIADOMIENIA
        </ButtonStyled>
        <ButtonStyled lite className="search__btn" onClick={handleGoToMainPage}>
          NIE, PRZEJDŹ DO STRONY GŁÓWNEJ
        </ButtonStyled>
      </GridCustom>
    </Container>
  );
};

export const Subscriptions = () => {
  const [confirmed, setConfirmed] = useState(false);

  return confirmed ? (
    <UnsubscribeSuccess />
  ) : (
    <UnsubscribeConfirmation setConfirmed={setConfirmed} />
  );
};
