import React from 'react';
import { TabBar, Tab } from '@rmwc/tabs';
import { BadgeAnchor, Badge } from '@rmwc/badge';
import '@rmwc/badge/badge.css';

import {
    dateStringToYearMonth,
    generatePdfDocument,
    isNotEmpty,
    redirectToLoginView,
    serializeAuditData
} from '../../../helpers';
import {
    translateErrorMessage,
    serializeObject,
} from '../../../serializers';
import {
    ButtonCreatePdf,
    ButtonStyled,
    Container,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';
import { BaseComponent } from '../common';
import {
    OfertyOffererClosedStatus,
    OfertyOffererPublishedStatus,
    OfertyOffererResolvedStatus,
    OfertyOwnerClosedStatus,
    OfertyOwnerPublishedStatus,
    OfertyOwnerResolvedStatus
} from '../oferty';
import {
    PytaniaForOgloszenieAnnouncementOwner,
    PytaniaForOgloszenieAnonymous,
    PytaniaForOgloszenieLoggedIn,
} from '../pytania';
import { ListaWersji } from'./ListaWersji';
import { ManageAdvertisementButtons } from'./ManageAdvertisementButtons';
import { OgloszenieDetails } from'./OgloszenieDetails';
import { OgloszeniePdf } from'./OgloszeniePdf';
import { fetchApi } from "../../../fetch";
import { MenuContext } from "../../../context/menuContext";


const ANNOUNCEMENT_STATUS_DRAFT = 'announcement_status_draft';
const ANNOUNCEMENT_STATUS_PUBLISHED = 'announcement_status_published';
const TAB_INDEXES = {
    ogloszenie: 0,
    oferty: 1,
    pytania: 2,
};
const REVERTED_TAB_INDEXES = {
    0: 'ogloszenie',
    1: 'oferty',
    2: 'pytania',
};


class Ogloszenie extends BaseComponent {

    static contextType = MenuContext;

    // basic functions

    componentDidMount() {
        super.componentDidMount();
        window.onpopstate = () => {
            this.handleReload();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.identifier !== this.props.user.identifier) {
            this.handleReload();
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.setAdvertisementManagerType(
            {isAuthor: false, isAdmin: false});
        window.onpopstate = () => {};
    }

    // handlers

    handleFetchSuccess = (data) => {
        this.xhrFetch = null;

        const ogloszenie = serializeObject(data.advertisement);
        this.props.setAdvertisementManagerType(
            {
                isAuthor: ogloszenie.ogloszenie.jestAutorem || false,
                isAdmin: ogloszenie.ogloszenie.isAdmin || false,
            },
            () => {
                // set values from dictionaries
                this.obublikowaneStatusId = data.dictionaries[ANNOUNCEMENT_STATUS_PUBLISHED];
                this.roboczeStatusId = parseInt(data.dictionaries[ANNOUNCEMENT_STATUS_DRAFT]);

                // reformat attachments
                ogloszenie.zalaczniki = this.sortAttachmentsByOrigins(
                    ogloszenie.zalaczniki || []);

                ogloszenie.czyUlubione = data.favorite;
                ogloszenie.badge = data.badge;
                ogloszenie.offer_set_badge_count = data.offer_set_badge_count;
                ogloszenie.question_badge_count = data.question_badge_count;

                const params = new URLSearchParams(this.props.location.search);
                let section = params.get('sekcja') || 'ogloszenie';
                const isCurrentVersion =
                    !(this.props.draftMode || this.props.versionMode);
                if (!isCurrentVersion) {
                    section = 'ogloszenie';
                }

                this.setState({
                    initializingOn: false,
                    ogloszenie: ogloszenie,
                    selectedTab: TAB_INDEXES[section] || 0,
                    auditData: serializeAuditData(isCurrentVersion
                        ? data.advertisement.advertisement
                        : data.advertisement
                    ),
                });
            }
        )
    }

    handleFetchError = (data) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            fetchError: translateErrorMessage(data.message),
            initializingOn: false,
        });
    }

    handleFetchIncorrectStatus = (status) => {
        this.xhrFetch = null;     // clean xhr object

        if (status === 404) {
            this.setState({pageNotFound: true, initializingOn: false});
            return
        }
        this.setState({
            initializingOn: false,
            fetchError: status === 403
                ? 'Nie masz uprawnień do przegladania tej strony.'
                : `Wystąpił nieoczekiwany błąd o kodzie ${status}.`
        });
    }

    handleCloseVersionDialog = () => {
        this.setState({showOtherVersionOn: false});
    }

    handleReload = (link=null) => {
        if (link !== null) {
            this.props.history.push(link);
        }
        this.setState(
            {initializingOn: true, showOtherVersionOn: false},
            this.fetchInitialData
        );
    }

    handleCreatePdf = () => {
        this.setState(
            {pdfCreatingOn: true},
            () => generatePdfDocument(
                <OgloszeniePdf
                    draftMode={this.props.draftMode}
                    ogloszenie={this.state.ogloszenie}
                    roboczeStatusId={this.roboczeStatusId}
                    versionMode={this.props.versionMode} />,
                `ogloszenie_${this.props.match.params.id}.pdf`,
                this.callbackPfdCreated
            )
        );
    }

    handleChangeTab = (ev) => {
        const selectedTab = ev.detail.index;
        this.setState(
            {selectedTab: selectedTab},
            () => {
                //update url "sekcja" param
                this.props.history.push(
                    `${window.location.pathname}?sekcja=${REVERTED_TAB_INDEXES[selectedTab] || REVERTED_TAB_INDEXES[0]}`);
            }
        );
    }

    handleChangeAdvertisementStatus = (status) => {
        this.setState(prevState => ({
            ogloszenie: {
                ...prevState.ogloszenie, ...{
                    ogloszenie: {...prevState.ogloszenie.ogloszenie, status}
                }
            }
        }));
    }

    handleAddToFavorite = () => {
        fetchApi(
            `/api/favorite-announcements/${this.props.match.params.id}`,
            "POST",
            {},
            {},
            () => {
                this.handleReload();
            }
          );
    }

    handleRemoveBadge = () => {
        fetchApi(
            `/api/badge/announcements/${this.props.match.params.id}`,
            "DELETE",
            {},
            {},
            () => {
                this.handleReload();
                this.context.refreshBadges();
            }
          );
    }

    handleRemoveFromFavorite = () => {
        fetchApi(
            `/api/favorite-announcements/${this.props.match.params.id}`,
            "DELETE",
            {},
            {},
            () => {
                this.handleReload();
            }
          );
    }

    // helpers

    getAdditionalState(props) {
        return {
            showOtherVersionOn: false,
            pdfCreatingOn: false,
            ogloszenie: null,
            selectedTab: null,
            auditData: {},
        }
    }

    postState(props) {
        super.postState(props);
        this.obublikowaneStatusId = null;
        this.roboczeStatusId = null;
    }

    getFetchUrl() {
        let url = `/api/announcements/${this.props.match.params.id}`;
        if (this.props.versionMode) {
            url += `/versions/${this.props.match.params.versionId}`;
        }
        return url
    }

    getFetchData() {
        let body = {dictionaries: [ANNOUNCEMENT_STATUS_DRAFT, ANNOUNCEMENT_STATUS_PUBLISHED]};
        if (this.props.draftMode) {
            body['version_mode'] = 'draft';
        }
        return body
    }

    callbackPfdCreated = () => {
        this.setState({pdfCreatingOn: false});
    }

    sortAttachmentsByOrigins(attachments) {
        let origins = {};
        let originsIds = [];
        for (let attachment of attachments) {
            const originId = (attachment.origin || {}).id || null;
            if (!originId) { continue }
            if (!originsIds.includes(originId)) {
                origins[originId] = {
                    dataOpublikowania: attachment.origin.dataOpublikowania || '',
                    zalaczniki: [
                        {
                            id: attachment.id,
                            nazwa: attachment.nazwa,
                            plik: attachment.plik,
                        },
                    ],
                };
                originsIds.push(originId);
            } else {
                origins[originId]['zalaczniki'] = [...origins[originId]['zalaczniki'], {
                    id: attachment.id,
                    nazwa: attachment.nazwa,
                    plik: attachment.plik,
                }];
            }
        }

        let originsList = Object.entries(origins).map(data => Object.assign(data[1], {id: parseInt(data[0])}));
        return originsList.sort((origin1, origin2) => {
            return origin1.id < origin2.id
        })
    }

    // rendering

    renderPublishedDates(ogloszenie) {
        if (this.props.draftMode) { return null }

        if (this.props.versionMode && isNotEmpty(ogloszenie.dataOpublikowania)) {
            return (
                <FieldWithLabel label="Data opublikowania wersji" className="announcement--date">
                    <Text>{ogloszenie.dataOpublikowania}</Text>
                </FieldWithLabel>
            )
        }
        return (
            <>
                {isNotEmpty(ogloszenie.ogloszenie.dataOpublikowania) && (
                    <FieldWithLabel label="Data opublikowania ogłoszenia" className="announcement--date">
                        <Text>{ogloszenie.ogloszenie.dataOpublikowania}</Text>
                    </FieldWithLabel>
                )}
                {isNotEmpty(ogloszenie.dataOpublikowania) && (
                    <FieldWithLabel label="Data ostatniej zmiany" className="announcement--date">
                        <Text>{ogloszenie.dataOpublikowania}</Text>
                    </FieldWithLabel>
                )}
            </>
        )
    }

    renderHeader() {
        if (this.state.ogloszenie === null) { return null }
        const o = this.state.ogloszenie;
        let isMyAdvertisement = false;
        if (this.props.user.id === o.ogloszenie.ktoUtworzyl.id) {
            isMyAdvertisement = true;
        }

        return (
            <>
                {!this.props.userAnonymous
                    && !o.ogloszenie.jestAutorem
                    && <ManageAdvertisementButtons
                        advertisement={o}
                        onChangeAdvertisementStatus={this.handleChangeAdvertisementStatus} />
                }
                <GridCustom flexS fullwidth centerVertical>
                    {isNotEmpty(o.ogloszenie.numer) && (
                        <Text tag="h2" mainHeader>Ogłoszenie nr {o.ogloszenie.numer}</Text>
                    )}
                    <GridCustom>
                    {(!isMyAdvertisement && o.czyUlubione === false) && <ButtonStyled onClick={this.handleAddToFavorite} primary
                    icon="favorite">DODAJ DO ULUBIONYCH<span className="sr-only"> ogłoszenie {this.state.ogloszenie.tytul}</span></ButtonStyled>}
                    {(!isMyAdvertisement && o.czyUlubione === true) && <ButtonStyled onClick={this.handleRemoveFromFavorite} remove
                    icon="close">USUŃ Z ULUBIONYCH<span className="sr-only"> ogłoszenie {this.state.ogloszenie.tytul}</span></ButtonStyled>}
                    <ButtonCreatePdf
                        pdfCreatingOn={this.state.pdfCreatingOn}
                        onClick={this.handleCreatePdf}
                    >
                        Pobierz plik PDF<span className="sr-only"> ogłoszenia {this.state.ogloszenie.tytul}</span>
                    </ButtonCreatePdf>
                    </GridCustom>

                </GridCustom>

                <Container>
                    {isNotEmpty(o.tytul) && (
                        <Text tag="h1" className="text--main-title long-text">{o.tytul}</Text>
                    )}
                    <GridCustom fullwidth flexM centerVertical>
                        <Text tag="h2" accentHeader>Status ogłoszenia: {o.ogloszenie.status.nazwa}</Text>
                        {o.badge && <BadgeAnchor>
                            <ButtonStyled onClick={this.handleRemoveBadge} primary icon="check">USUŃ OZNACZENIE JAKO NOWE<span className="sr-only"> ogłoszenia {this.state.ogloszenie.tytul}</span></ButtonStyled>
                            <Badge label="N" />
                        </BadgeAnchor>}
                        
                        {parseInt(o.status.id) === this.roboczeStatusId && <Text accentHeader>Status wersji: {o.status.nazwa}</Text>}
                        <ButtonStyled
                            className="announcement__btn-versions"
                            lite
                            noMargin
                            onClick={(e) => {
                                this.setState({showOtherVersionOn: true});
                                e.currentTarget.blur();
                            }}>
                            Podejrzyj inne wersje<span className="sr-only"> ogłoszenia {o.tytul}</span>
                        </ButtonStyled>
                        {this.state.showOtherVersionOn && (
                            <ListaWersji
                                id={o.id}
                                isAuthor={o.ogloszenie.ktoUtworzyl.id === this.props.user.id}
                                isPublished={this.obublikowaneStatusId === o.ogloszenie.status.id}
                                ogloszenieId={this.props.match.params.id}
                                roboczeStatusId={this.roboczeStatusId}
                                closeVersionDialog={this.handleCloseVersionDialog}
                                reload={this.handleReload}
                            />)}
                    </GridCustom>
                    <GridCustom fullwidth flexM>
                        {isNotEmpty(o.terminOfert) && (
                            <FieldWithLabel label="Termin składania ofert" className="announcement--date">
                                <Text>{o.terminOfert}</Text>
                            </FieldWithLabel>
                        )}
                        {this.renderPublishedDates(o)}
                        {isNotEmpty(o.planowanyTerminPodpisaniaUmowy) && (
                            <FieldWithLabel label="Planowany termin podpisania umowy" className="announcement--date">
                                <Text>{dateStringToYearMonth(o.planowanyTerminPodpisaniaUmowy)}</Text>
                            </FieldWithLabel>
                        )}
                    </GridCustom>
                </Container>
                <TabBar
                    activeTabIndex={this.state.selectedTab}
                    onActivate={this.handleChangeTab}
                >
                    <Tab label="Ogłoszenie" aria-label="Ogłoszenie, panel zakładek, lista 1 z 3"/>
                    <Tab label="Oferty" aria-label="Oferty, panel zakładek, lista 2 z 3" disabled={this.props.draftMode || this.props.versionMode}>
                        {o.offer_set_badge_count > 0 && <Badge label={o.offer_set_badge_count} align='inline'/>}
                    </Tab>
                    <Tab label="Pytania" aria-label="Pytania, panel zakładek, lista 3 z 3" disabled={this.props.draftMode || this.props.versionMode}>
                        {o.question_badge_count > 0 && <Badge label={o.question_badge_count}  align='inline'/>}
                    </Tab>
                </TabBar>
            </>
        )
    }

    renderContent() {
        switch (this.state.selectedTab) {
            case TAB_INDEXES['oferty']:
                return this.renderOferty()
            case TAB_INDEXES['pytania']:
                return this.renderPytania()
            default:
                return this.renderOgloszenie()
        }
    }

    renderOferty() {
        if (this.props.draftMode || this.props.versionMode) { return null }
        switch (this.state.ogloszenie.ogloszenie.status.label) {
            case 'DRAFT':
                return null
            case 'PUBLISHED':
                return this.renderOfertyForPublishedStatus()
            case 'CANCELLED':
                return this.renderOfertyForCancelledStatus()
            case 'CLOSED':
                return this.renderOfertyForClosedStatus()
            case 'RESOLVED':
                return this.renderOfertyForResolvedStatus()
            default:
                return null
        }
    }

    renderOfertyForPublishedStatus() {
        if (this.props.userAnonymous) {
            return (
                <div className="login-info-container">
                    <ButtonStyled add onClick={redirectToLoginView} className="btn btn--main">Utwórz ofertę</ButtonStyled>
                    <Text info>Wymaga logowania</Text>
                </div>
            )
        }
        // check if advertisement owner
        if (this.props.user.advertisementManagerType.isAuthor) {
            return <OfertyOwnerPublishedStatus ogloszenie={this.state.ogloszenie} refresh={() => this.handleReload()} />
        }
        return <OfertyOffererPublishedStatus ogloszenie={this.state.ogloszenie} />
    }

    renderOfertyForCancelledStatus() {
        return <Text info>Ogłoszenie zostało anulowane dla wszystkich oferentów.</Text>
    }

    renderOfertyForClosedStatus() {
        return this.renderOfertyForAfterPublishedStatus(
            OfertyOwnerClosedStatus, OfertyOffererClosedStatus)
    }

    renderOfertyForResolvedStatus() {
        return this.renderOfertyForAfterPublishedStatus(
            OfertyOwnerResolvedStatus, OfertyOffererResolvedStatus)
    }

    renderOfertyForAfterPublishedStatus(OwnerComponent, OffererComponent) {
        const { advertisementManagerType } = this.props.user;
        const { isAuthor: isAdvertisementOwner } = advertisementManagerType;
        if (isAdvertisementOwner || advertisementManagerType.isAdmin) {
            return <OwnerComponent
                       {...this.props}
                       isAdvertisementOwner={isAdvertisementOwner}
                       ogloszenie={this.state.ogloszenie}
                       onlyAdmin={!isAdvertisementOwner} />
        }
        return <OffererComponent {...this.props} ogloszenie={this.state.ogloszenie} />
    }

    renderPytania() {
		
        if (this.props.userAnonymous) {
            return <PytaniaForOgloszenieAnonymous ogloszenie={this.state.ogloszenie} reloadAnnouncement={this.handleReload} />
        } else if (this.state.ogloszenie.ogloszenie.ktoUtworzyl.id === this.props.user.id) {
            return <PytaniaForOgloszenieAnnouncementOwner ogloszenie={this.state.ogloszenie} reloadAnnouncement={this.handleReload} />
        }

        return <PytaniaForOgloszenieLoggedIn ogloszenie={this.state.ogloszenie} reloadAnnouncement={this.handleReload} />
    }

    renderOgloszenie() {
        if (this.state.ogloszenie === null) { return null }
        const o = this.state.ogloszenie;
        return (
            <OgloszenieDetails
                auditData={this.state.auditData}
                ogloszenie={o}
                versionMode={this.props.versionMode}
                draftMode={this.props.draftMode} />
        )
    }
}


export { Ogloszenie };
