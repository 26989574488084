import React from 'react';
import {
    DataTable,
    DataTableBody,
    DataTableCell,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell,
    DataTableRow,
} from '@rmwc/data-table';

import { UserContext } from '../../../context/user-context';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { AuditData, Breadcrumps, ButtonStyled, Text } from '../../common';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';
import {serializeAuditData} from "../../../helpers";


class Ciasteczka extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        if (data === null) {
            this.setState({initializingOn: false});
        } else {
            const cookies = getSerializedObject(
                data,
                {
                    nonRecursiveKeys: ['tresc', ],
                    dateAttributes: ['dataOpublikowania'],
                    dateTimeAttributes: ['dataOpublikowania'],
                }
            );
            this.setState({
                initializingOn: false,
                ciasteczka: cookies,
                auditData: serializeAuditData(cookies),
            });
        }
    }

    // helpers

    getAdditionalState(props) {
        return {
            ciasteczka: {
                tresc: '',
                dataOpublikowania: '',
            },
            auditData: {},
        }
    }

    getFetchUrl() {
        return API_URLS.cookiesAdmin.path
    }

    getFetchError(message) {
        return `Nie udało się pobrać ciasteczek. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania ciasteczek wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.removePermission();
    }

    removePermission() {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__current">Ciasteczka</li>
                </Breadcrumps>
                <Text tag="h2" mainHeader>Ciasteczka</Text>
            </>
        )
    }

    renderContent() {
        if (this.state.ciasteczka.tresc === '') {
            return <ButtonStyled add onClick={() => this.props.history.push('/zarzadzanie-trescia/ciasteczka/nowe')}>Dodaj ciasteczka</ButtonStyled>;
        } else {
            const ciasteczka = this.state.ciasteczka;
            return (
                <>
                    <DataTable className="table">
                        <DataTableContent>
                            <DataTableHead>
                                <DataTableRow>
                                    <DataTableHeadCell>Treść</DataTableHeadCell>
                                    <DataTableHeadCell>Aktualna wersja</DataTableHeadCell>
                                    <DataTableHeadCell></DataTableHeadCell>
                                </DataTableRow>
                            </DataTableHead>
                            <DataTableBody>
                                <DataTableRow>
                                    <DataTableCell>
                                        <DraftToComponents content={ciasteczka.tresc} />
                                    </DataTableCell>
                                    <DataTableCell>z dn. {ciasteczka.dataOpublikowania}</DataTableCell>
                                    <DataTableCell><ButtonStyled lite noMargin onClick={() => this.props.history.push('/zarzadzanie-trescia/ciasteczka/edytuj')}>Edytuj</ButtonStyled></DataTableCell>
                                </DataTableRow>
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable>
                    <AuditData {...this.state.auditData} />
                </>
            );
        }
    }
}

Ciasteczka.contextType = UserContext;

export { Ciasteczka };
