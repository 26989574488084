import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

import { Breadcrumps, Container, FieldWithLabel, GridCustom, Text } from '../../common';
import { DraftToComponents } from './DraftToComponents';
import { Pomoc } from './Pomoc';


class PomocFront extends Pomoc {

    // handlers

    handleChangePomoc(newId, newSlug) {
        this.props.history.push(`/pomoc/${newId}-${newSlug}`);
        this.handleReload();
    }

    // helpers

    getFetchUrl() {
        return `/api/help/${this.props.match.params.id}`
    }

    setUrlWithCorrectSlug(pomoc) {
        // update url if slugs are not equal
        const urlParams = this.props.match.params;
        if (pomoc.slug !== urlParams.slug) {
            this.props.history.push(`/pomoc/${urlParams.id}-${pomoc.slug}`);
        }
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps isFront>
                <li className="breadcrumps__link"><Link to='/pomoc'>Pomoc</Link></li>
                <li className="breadcrumps__current">Szczegóły pomocy</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        document.title = "Baza Konkurencyjności - pomoc";
        const pomoc = this.state.pomoc;
        return (
            <>
                <Container className="article" tag="article">
                    <Typography use="headline4" tag="h2" className="article__header">{this.state.pomoc.naglowek}</Typography>
                    <DraftToComponents content={pomoc.tresc} className="article__text"/>
                </Container>
                <GridCustom>
                    {pomoc.powiazane.length > 0 && (
                        <FieldWithLabel label="Powiązane">
                            {pomoc.powiazane.map(p => (
                                <button key={p.id} onClick={() => this.handleChangePomoc(p.id, p.slug)} className="link-text"><Icon icon="arrow_right" aria-hidden="true" className="help__help-item-icon" />{p.naglowek}</button>
                            ))}
                        </FieldWithLabel>
                    )}
                    <FieldWithLabel label="Data opublikowania">
                        <Text>{pomoc.dataOpublikowania}</Text>
                    </FieldWithLabel>
                </GridCustom>
            </>
        )
    }
}


export { PomocFront };
