import React from 'react';
import { useParams } from 'react-router-dom';

import { API_URLS } from '../../../urls/api';
import { URLS } from '../../../urls/frontend';
import { ImplementationLevelManagement, Text } from '../../common';


const RelatedObjectsExistInfoComponent = (props) => (
    <>
        <Text error>Nie można usunąć działania i poddziałania, ponieważ jest ono powiązane z innym obiektem.</Text>
        <Text error>Upewnij się, że działanie i poddziałanie nie ma regionów, nie istnieją projekty lub nabory oparte o to działanie i poddziałanie oraz nie są przyznane uprawnienia do tego działania i poddziałania, a następnie spróbuj ponownie.</Text>
    </>
);


export const Subactivities = (props) => {
    const { priorityId } = useParams();

    function getItemLinkUrl(item) {
        return URLS.managementRegionsForSubactivity.getUrl(item.id)
    }

    function getParentFormLabel(programmeCode, priorityCode, activityCode) {
        return `${programmeCode}.${priorityCode}.`
    }

    return (
        <ImplementationLevelManagement
            addApiUrlName="activitiesAdd"
            addItemButtonLabel="Dodaj działanie i poddziałanie"
            additionalSaveData={{priority: parseInt(priorityId)}}   // for form
            apiUrl={API_URLS.activitiesAdmin.getUrl(priorityId)}
            auditDataBoxTitle="Dane audytowe dla osi priorytetowej"
            auditDataObjectName="priority"
            codeMaxLength={5}
            editApiUrlName="activitiesEdit"
            getParentFormLabel={getParentFormLabel}
            listObjectName="activities"
            RelatedObjectsExistInfoComponent={RelatedObjectsExistInfoComponent}
            rowComponentProps={{
                getItemLinkFunction: getItemLinkUrl, withLink: true
            }}
            showAuditData={true}
            tableName="Lista działań i poddziałań"
            titleFormLabel="działania i poddziałania"
        />
    )
};
