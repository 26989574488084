import React from 'react';

import { ADVERTISEMENT_STATUS_FILTERS_CHOICES } from '../../../const';
import { dateToString } from '../../../helpers';
import {
    DateCalendar,
    FieldWithLabel,
    GridCustom,
    Select,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { ProjectNumberSelectsWithPerspectives } from '../common';


export const AdvertisementDataFilters = ({
    filters, perspectives, onChangeFilter, onChangeProjectNumberSelect
}) => {
    const announcementPublicationDateFrom = !!filters.announcementPublicationDateFrom
        ? new Date(filters.announcementPublicationDateFrom)
        : '';
    const announcementPublicationDateTo = !!filters.announcementPublicationDateTo
        ? new Date(filters.announcementPublicationDateTo)
        : '';
    const submissionDeadlineFrom = !!filters.submissionDeadlineFrom
        ? new Date(filters.submissionDeadlineFrom)
        : '';
    const submissionDeadlineTo = !!filters.submissionDeadlineTo
        ? new Date(filters.submissionDeadlineTo)
        : '';
    const announcementPublicationDateFromInputRef = React.createRef();
    const announcementPublicationDateToInputRef = React.createRef();
    const submissionDeadlineFromInputRef = React.createRef();
    const submissionDeadlineToInputRef = React.createRef();

    return (
        <>
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Status"
                    selectRef={React.createRef()}
                    tag="label">
                    <Select
                        aria-label="Status"
                        className="select-custom"
                        options={ADVERTISEMENT_STATUS_FILTERS_CHOICES}
                        screenReaderStatus={() => {return ''}}
                        styles={customSelectStyles}
                        value={filters.announcementStatus}
                        onChange={selectedOption => onChangeFilter(
                            'announcementStatus', selectedOption)}
                    />
                </FieldWithLabel>
                <FieldWithLabel
                    label="Numer"
                    labelFor="filters_announcementNumber_id"
                    tag="label">
                    <TextFieldCustom
                        aria-label={`Numer, maksymalna liczba znaków: 50. Wpisano ${filters.announcementNumber.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="numer"
                        id="filters_announcementNumber_id"
                        maxLength={50}
                        value={filters.announcementNumber}
                        onChange={ev => onChangeFilter('announcementNumber', ev.target.value)}
                        onClear={ev => onChangeFilter('announcementNumber', '')} />
                </FieldWithLabel>
            </GridCustom>
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Tytuł"
                    labelFor="filters_title_id"
                    tag="label">
                    <TextFieldCustom
                        characterCount
                        aria-label={`Tytuł, maksymalna liczba znaków: 500. Wpisano ${filters.title.length} znaków.`}
                        className="filters__input"
                        clearFieldContext="tytuł"
                        id="filters_title_id"
                        maxLength={500}
                        value={filters.title}
                        onChange={ev => onChangeFilter('title', ev.target.value)}
                        onClear={ev => onChangeFilter('title', '')} />
                </FieldWithLabel>
            </GridCustom>
            <ProjectNumberSelectsWithPerspectives
                activity={filters.announcementProjectEnrollmentActivity}
                activityErrors={[]}
                disabled={false}
                institution={filters.announcementProjectEnrollmentInstitution}
                institutionErrors={[]}
                isClearable={true}
                mode="advertisements"
                perspective={filters.announcementProjectEnrollmentPerspective}
                perspectiveErrors={[]}
                perspectives={perspectives}
                perspectivesAsSelect={true}
                priority={filters.announcementProjectEnrollmentPriority}
                priorityErrors={[]}
                programme={filters.announcementProjectEnrollmentProgramme}
                programmeErrors={[]}
                region={filters.announcementProjectEnrollmentRegion}
                regionErrors={[]}
                withInstitutionNumber={false}
                onChangeActivity={onChangeProjectNumberSelect}
                onChangeInstitution={onChangeProjectNumberSelect}
                onChangePerspective={onChangeProjectNumberSelect}
                onChangePriority={onChangeProjectNumberSelect}
                onChangeProgramme={onChangeProjectNumberSelect}
                onChangeRegion={onChangeProjectNumberSelect}
            />
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Numer projektu lub naboru"
                    labelFor="filters_announcementProjectEnrollmentFullNumber_id"
                    tag="label">
                    <TextFieldCustom
                        aria-label={`Numer projektu lub naboru, maksymalna liczba znaków: 50. Wpisano ${filters.announcementProjectEnrollmentFullNumber.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="numer projektu lub naboru"
                        id="filters_announcementProjectEnrollmentFullNumber_id"
                        maxLength={50}
                        value={filters.announcementProjectEnrollmentFullNumber}
                        onChange={ev => onChangeFilter('announcementProjectEnrollmentFullNumber', ev.target.value)}
                        onClear={ev => onChangeFilter('announcementProjectEnrollmentFullNumber', '')} />
                </FieldWithLabel>
            </GridCustom>
            <GridCustom fullwidth flexM>
                <FieldWithLabel
                    label="Data publikacji"
                    tag="label">
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel
                            inputRef={announcementPublicationDateFromInputRef}
                            label="od"
                            tag="label">
                            <DateCalendar
                                dateName="Od"
                                maxDate={announcementPublicationDateTo || undefined}
                                parentRef={announcementPublicationDateFromInputRef}
                                value={announcementPublicationDateFrom}
                                onChange={date => onChangeFilter('announcementPublicationDateFrom', dateToString(date))} />
                        </FieldWithLabel>
                        <FieldWithLabel
                            inputRef={announcementPublicationDateToInputRef}
                            label="do"
                            tag="label">
                            <DateCalendar
                                dateName="Do"
                                minDate={announcementPublicationDateFrom || undefined}
                                parentRef={announcementPublicationDateToInputRef}
                                value={announcementPublicationDateTo}
                                onChange={date => onChangeFilter('announcementPublicationDateTo', dateToString(date))} />
                        </FieldWithLabel>
                    </GridCustom>
                </FieldWithLabel>
                <FieldWithLabel
                    label="Termin składania ofert"
                    tag="label">
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel
                            inputRef={submissionDeadlineFromInputRef}
                            label="od"
                            tag="label">
                            <DateCalendar
                                dateName="Od"
                                maxDate={submissionDeadlineTo || undefined}
                                parentRef={submissionDeadlineFromInputRef}
                                value={submissionDeadlineFrom}
                                onChange={date => onChangeFilter('submissionDeadlineFrom', dateToString(date))} />
                        </FieldWithLabel>
                        <FieldWithLabel
                            inputRef={submissionDeadlineToInputRef}
                            label="do"
                            tag="label">
                            <DateCalendar
                                dateName="Do"
                                minDate={submissionDeadlineFrom || undefined}
                                parentRef={submissionDeadlineToInputRef}
                                value={submissionDeadlineTo}
                                onChange={date => onChangeFilter('submissionDeadlineTo', dateToString(date))} />
                        </FieldWithLabel>
                    </GridCustom>
                </FieldWithLabel>
            </GridCustom>
        </>
    )
}
