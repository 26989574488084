import React from 'react';

import { ButtonStyled } from'../ButtonStyled';


export const FiltersButton = ({filtersCounter: counter, filteringLabel, onOpenFilters}) => {
    return (
        <ButtonStyled
            lite
            onClick={e => {
                onOpenFilters();
                e.currentTarget.blur();
            }}>
            {filteringLabel || 'Filtruj listę'}
            <span className="sr-only"> Liczba aktywnych filtrów: </span>
            <span className="btn--counter  btn--counter-primary" title="Wybrane filtry">{counter}</span>
        </ButtonStyled>
    )
}
