import React from 'react';

import { ButtonStyled, MoreOptionsButton } from '../../common';


export const PermissionsMoreOptionButton = ({
    onOpenHistory, onOpenUserList
}) => {
    return (
        <MoreOptionsButton
            className="permissions__more-options"
            menuAriaLabel="Otwórz menu: Lista użytkowników lub historia zmian"
        >
            <ul className="permissions__more-options-list">
                <li>
                    <ButtonStyled onClick={onOpenUserList}>
                        Lista użytkowników
                    </ButtonStyled>
                </li>
                <li>
                    <ButtonStyled onClick={onOpenHistory}>
                        Historia zmian
                    </ButtonStyled>
                </li>
            </ul>
        </MoreOptionsButton>
)
};

