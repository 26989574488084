import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../context/user-context';
import { useCheckPermissionExists } from '../../../hooks';
import { API_URLS } from '../../../urls/api';
import { customSelectStyles } from '../../vars/vars';
import { FieldWithLabel, Select, Text } from '../../common';
import {
    UserPermissionsFormCommonFields
} from './UserPermissionsFormCommonFields';


const SCOPES = {
    users: {
        id: 0,
        label: 'Zarządzanie użytkownikami',
        permissionType: 'usersAdministration',
    },
    contents: {
        id: 1,
        label: 'Zarządzanie treściami',
        permissionType: 'contentAdministration',
    },
    dictionaries: {
        id: 2,
        label: 'Zarządzanie słownikami poziomów wdrożenia',
        permissionType: 'implementationLevelsDictionaryAdministration',
    },
};
const SELECT_CHOICES = Object.values(SCOPES)
    .map(scope => [scope.id, scope.label, scope.permissionType])
    .sort((s1, s2) => s1.id < s2.id);
const DEFAULT_CHOICE = SELECT_CHOICES[0]
const USERS_ADMINISTRATION_DEFAULT_CHECKS_DICT = {
    usersAdministration: {
        previewListUsers: false,
        blockingUsers: false,
    },
    permissionsAdministration: {
        previewListUsersAuthorized: false,
        functionalityManagement: false,
        permissionsManagement: false,
    },
};
const CONTENT_ADMINISTRATION_DEFAULT_CHECKS_DICT = {
    contentAdministration: {
        generalContentCookiesRegulation: false,
        statements: false,
        help: false,
    },
    permissionsAdministration: {
        previewListUsersAuthorized: false,
        functionalityManagement: false,
        permissionsManagement: false,
    },
};
const IMPLEMENTATION_LEVELS_DICTIONARY_ADMINISTRATION_DEFAULT_CHECKS_DICT = {
    implementationLevelsDictionaryAdministration: {
        previewData: false,
        editData: false,
    },
    permissionsAdministration: {
        previewListUsersAuthorized: false,
        functionalityManagement: false,
        permissionsManagement: false,
    },
};
const USERS_ADMINISTRATION_CHECKS_SORT_DICT = {
    groups: ['usersAdministration', 'permissionsAdministration'],
    usersAdministration: ['previewListUsers', 'blockingUsers'],
    permissionsAdministration: [
        'previewListUsersAuthorized', 'functionalityManagement',
        'permissionsManagement'
    ],
};
const CONTENT_ADMINISTRATION_CHECKS_SORT_DICT = {
    groups: ['contentAdministration', 'permissionsAdministration'],
    contentAdministration: [
        'generalContentCookiesRegulation', 'statements', 'help'],
    permissionsAdministration: [
        'previewListUsersAuthorized', 'functionalityManagement',
        'permissionsManagement'
    ],
};
const IMPLEMENTATION_LEVELS_DICTIONARY_ADMINISTRATION_CHECKS_SORT_DICT = {
    groups: [
        'implementationLevelsDictionaryAdministration',
        'permissionsAdministration'
    ],
    implementationLevelsDictionaryAdministration: ['previewData', 'editData'],
    permissionsAdministration: [
        'previewListUsersAuthorized', 'functionalityManagement',
        'permissionsManagement'
    ],
};
const EXISTS_ERROR_LABEL = 'Nazwę obszaru administracji';


export const UserPermissionsAdministrationForm = ({
    initialData = null, userId, onCancel, onSavePermissionSuccess
}) => {
    const [editionModeOn, setEditionModeOn] = useState(initialData !== null);
    const [savingOn, setSavingOn] = useState(false);
    const [selectedScope, setSelectedScope] = useState(null);

    function setInitialSelectedScope() {
        // set correct scope
        if (editionModeOn) {
            for (let [id_, label_, permissionType_] of SELECT_CHOICES) {
                if (permissionType_ === initialData.type) {
                    setSelectedScope({value:id_, label: label_});
                    return
                }
            }
        }
        setSelectedScope({
            value: DEFAULT_CHOICE[0], label: DEFAULT_CHOICE[1]});
    }
    useEffect(setInitialSelectedScope, []);

    // this one is set if request for checkboxes values returns 403
    const [permissions403errorOn, setPermissions403errorOn] = useState(false);
    const [existsErrorOn, setExistsErrorOn] = useState(false);
    const [
        checkingExistedPermissionOn, setCheckingExistedPermissionOn
    ]= useState(false);

    function callbackSelectedScopeChanging() {
        if (selectedScope === null || editionModeOn) { return }
        setPermissions403errorOn(false);
        setCheckingExistedPermissionOn(true);
    }
    useEffect(callbackSelectedScopeChanging, [selectedScope]);

    const { existingPermissionData } = useCheckPermissionExists(
        checkingExistedPermissionOn, setCheckingExistedPermissionOn,
        selectedScope === null ? '' : (SCOPES.users.id === selectedScope.value
            ? API_URLS.usersPermissionsUsersDetails.path
            : SCOPES.contents.id === selectedScope.value
                ? API_URLS.usersPermissionsContentDetails.path
                : API_URLS.usersPermissionsDictionariesDetails.path
        ),
        {}, setExistsErrorOn, setPermissions403errorOn, userId
    );

    const { permissions } = useContext(UserContext);

    if (selectedScope === null) { return null }

    const disabledFields = savingOn || checkingExistedPermissionOn;
    const disabledSaveButton = disabledFields || permissions403errorOn;
    return (
        <>
            <FieldWithLabel label="Nazwa obszaru administracji" tag="label">
                <Select
                    aria-label="Nazwa obszaru administracji"
                    className="select-custom"
                    isDisabled={editionModeOn || savingOn}
                    options={SELECT_CHOICES.map(
                        choice => ({value: choice[0], label: choice[1]}))}
                    screenReaderStatus={() => {return ''}}
                    styles={customSelectStyles}
                    value={selectedScope}
                    onChange={selectedOption => setSelectedScope(selectedOption)} />
            </FieldWithLabel>
            {permissions403errorOn && (
                <Text error role="alert">
                    Nie masz uprawnień do nadawania uprawnień dla wybranego obszaru administracji. Zmień obszar administracji.
                </Text>
            )}
            {SCOPES.users.id === selectedScope.value && (
                <UserPermissionsFormCommonFields
                    checksSortDict={USERS_ADMINISTRATION_CHECKS_SORT_DICT}
                    defaultChecksDict={editionModeOn
                        ? (existingPermissionData !== null
                            ? existingPermissionData
                            : initialData.permissions
                        )
                        : USERS_ADMINISTRATION_DEFAULT_CHECKS_DICT
                    }
                    disabled={disabledFields}
                    disabledCancelButton={disabledFields}
                    disabledSaveButton={disabledSaveButton}
                    editionModeOn={editionModeOn}
                    existingPermissionData={existingPermissionData}
                    existsErrorOn={existsErrorOn}
                    existsErrorLabel={EXISTS_ERROR_LABEL}
                    permissions={permissions.usersPermissionsManagement}
                    saveUrl={API_URLS.usersPermissionsUsers.path}
                    savingOn={savingOn}
                    setEditionModeOn={setEditionModeOn}
                    setExistsErrorOn={setExistsErrorOn}
                    userId={userId}
                    onCancel={onCancel}
                    onSavePermissionSuccess={onSavePermissionSuccess}
                    onSetSavingOn={setSavingOn} />
            )}
            {SCOPES.contents.id === selectedScope.value && (
                <UserPermissionsFormCommonFields
                    checksSortDict={CONTENT_ADMINISTRATION_CHECKS_SORT_DICT}
                    defaultChecksDict={editionModeOn
                        ? (existingPermissionData !== null
                            ? existingPermissionData
                            : initialData.permissions
                        )
                        : CONTENT_ADMINISTRATION_DEFAULT_CHECKS_DICT
                    }
                    disabled={disabledFields}
                    disabledCancelButton={disabledFields}
                    disabledSaveButton={disabledSaveButton}
                    editionModeOn={editionModeOn}
                    existingPermissionData={existingPermissionData}
                    existsErrorOn={existsErrorOn}
                    existsErrorLabel={EXISTS_ERROR_LABEL}
                    permissions={permissions.contentPermissionsManagement}
                    saveUrl={API_URLS.usersPermissionsContent.path}
                    savingOn={savingOn}
                    setEditionModeOn={setEditionModeOn}
                    setExistsErrorOn={setExistsErrorOn}
                    userId={userId}
                    onCancel={onCancel}
                    onSavePermissionSuccess={onSavePermissionSuccess}
                    onSetSavingOn={setSavingOn} />
            )}
            {SCOPES.dictionaries.id === selectedScope.value && (
                <UserPermissionsFormCommonFields
                    checksSortDict={IMPLEMENTATION_LEVELS_DICTIONARY_ADMINISTRATION_CHECKS_SORT_DICT}
                    defaultChecksDict={editionModeOn
                        ? (existingPermissionData !== null
                             ? existingPermissionData
                             : initialData.permissions
                        )
                        : IMPLEMENTATION_LEVELS_DICTIONARY_ADMINISTRATION_DEFAULT_CHECKS_DICT
                    }
                    disabled={disabledFields}
                    disabledCancelButton={disabledFields}
                    disabledSaveButton={disabledSaveButton}
                    existingPermissionData={existingPermissionData}
                    editionModeOn={editionModeOn}
                    existsErrorOn={existsErrorOn}
                    existsErrorLabel={EXISTS_ERROR_LABEL}
                    permissions={permissions.implementationLevelsDictionaryPermissionsManagement}
                    saveUrl={API_URLS.usersPermissionsDictionaries.path}
                    savingOn={savingOn}
                    setEditionModeOn={setEditionModeOn}
                    setExistsErrorOn={setExistsErrorOn}
                    userId={userId}
                    onCancel={onCancel}
                    onSavePermissionSuccess={onSavePermissionSuccess}
                    onSetSavingOn={setSavingOn} />
            )}
        </>
    )
};
