import React, { useState } from 'react';

import { TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES } from '../../../const';
import { serializePerspectiveForSelect } from '../../../helpers';
import { useOneTimeFetchApi } from '../../../hooks';
import { ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS } from '../../../filters';
import { API_URLS } from '../../../urls/api';
import {
    ButtonStyled,
    GridCustom,
    Loader,
    Text,
} from '../../common';
import { AdvertisementDataFilters } from './AdvertisementDataFilters';
import { AdvertiserDataFilters } from './AdvertiserDataFilters';
import { UserDataFilters } from './UserDataFilters';


export const AdvertisementsFilters = (props) => {
    const [filters, setFilters] = useState(props.filters);
    const {data, loadingOn, fetchError} = useOneTimeFetchApi({
        url: API_URLS.perspectives.path,
        fetchData: {mode: 'advertisements'},
        serializeFetchDataFunction: serializePerspectiveForSelect,
    });

    function handleChangeFilter(name, value) {
        setFilters({...filters, [name]: value});
    }

    function handleApplyFilters() {
        props.onUpdateFilters(filters);
    }

    function handleChangeAdvertiserAddressType(value) {
        setFilters(prevFilters => {
            let dct = {
                advertiserAddressType: value,
                advertiserIdentificationNumber: '',
                advertiserAddressLocality: '',
            };
            dct['advertiserIdentificationNumberType'] =
                value.value !== 'poland'
                    ? TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES.filter(
                        choice => choice.value === 'all')[0]
                    : ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS[
                        'advertiserIdentificationNumberType'];
            if (value.value !== 'poland') {
                dct = {...dct, ...{
                    advertiserAddressVoivodeship: '',
                    advertiserAddressPoviat: '',
                    advertiserAddressCommune: '',
                    advertiserAddressLocality: '',
                }};
            }
            if (value.value !== 'abroad') {
                dct['advertiserAddressCountry'] = '';
            }
            return {...prevFilters, ...dct}
        });
    }

    function handleChangeAdvertiserIdentificationNumberType(value) {
        setFilters(prevFilters => {
            let dct = {advertiserIdentificationNumberType: value};
            if (prevFilters.advertiserIdentificationNumberType.value !== value.value) {
                // clean advertiserIdentificationNumber
                dct['advertiserIdentificationNumber'] = '';
            }
            return {...filters, ...dct}}
        );
    }

    function handleChangeAdvertiserIdentificationNumber(value, onlyDigits=false) {
        if (onlyDigits) {
            value = value.replace(/[^\d]/g, '');
        }
        handleChangeFilter('advertiserIdentificationNumber', value);
    }

    function setSerializedData(data, serializationDict) {
        let serializedData = {};
        for (let [name, value] of Object.entries(data)) {
            serializedData[serializationDict[name]] = value;
        }
        setFilters({...filters, ...serializedData});
    }

    function handleChangeProjectNumberSelect(data) {
        setSerializedData(data, {
            perspective: 'announcementProjectEnrollmentPerspective',
            programme: 'announcementProjectEnrollmentProgramme',
            priority: 'announcementProjectEnrollmentPriority',
            activity: 'announcementProjectEnrollmentActivity',
            institution: 'announcementProjectEnrollmentInstitution',
            region: 'announcementProjectEnrollmentRegion',
        });
    }

    function handleChangePolishLocality(data) {
        if (data === null) {
            // it's possible to get data as null from locality form
            data = {
                wojewodztwo: '',
                powiat: '',
                gmina: '',
                miejscowosc: '',
            };
        }
        setSerializedData(data, {
            wojewodztwo: 'advertiserAddressVoivodeship',
            powiat: 'advertiserAddressPoviat',
            gmina: 'advertiserAddressCommune',
            miejscowosc: 'advertiserAddressLocality',
        });
    }

    return loadingOn ? <Loader /> : (fetchError
        ? (
            <>
                <Text error>{fetchError}</Text>
                <ButtonStyled cancel onClick={props.onCloseFilters}>OK</ButtonStyled>
            </>
        )
        : (
            <>
                <section className="filters-dialog__filters-section">
                    <Text className="filters-dialog__filters-headers">Informacje o użytkowniku</Text>
                    <UserDataFilters
                        userEmail={filters.userEmail}
                        userForename={filters.userForename}
                        userSurname={filters.userSurname}
                        onChangeFilter={handleChangeFilter} />
                    <Text className="filters-dialog__filters-headers">Informacje o podmiocie</Text>
                    <AdvertiserDataFilters
                        filters={filters}
                        onChangeAdvertiserAddressType={handleChangeAdvertiserAddressType}
                        onChangeAdvertiserIdentificationNumber={handleChangeAdvertiserIdentificationNumber}
                        onChangeAdvertiserIdentificationNumberType={handleChangeAdvertiserIdentificationNumberType}
                        onChangeFilter={handleChangeFilter}
                        onChangePolishLocality={handleChangePolishLocality} />
                    <Text className="filters-dialog__filters-headers">Informacje o ogłoszeniu</Text>
                    <AdvertisementDataFilters
                        filters={filters}
                        perspectives={data.perspectives}
                        onChangeFilter={handleChangeFilter}
                        onChangeProjectNumberSelect={handleChangeProjectNumberSelect} />
                </section>
                <GridCustom flexEnd>
                    <ButtonStyled cancel onClick={props.onCloseFilters}>Anuluj</ButtonStyled>
                    <ButtonStyled primary onClick={handleApplyFilters}>Zastosuj kryteria</ButtonStyled>
                </GridCustom>
            </>
        )
    )
};
