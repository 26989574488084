import React from 'react';
import { Icon } from '@rmwc/icon';
import '@material/form-field/dist/mdc.form-field.css';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { fetchApi } from '../../../fetch';
import { computeProgress, dateToString, getOrderItemsLength, setFocusOnElement } from '../../../helpers';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { URLS } from '../../../urls/frontend';
import {
    ButtonStyled,
    DialogCustom,
    GridCellCustom,
    GridCustom,
    LinearProgressCustom,
    MenuBasic,
    Message,
    Text,
} from '../../common';
import { BaseComponent } from '../common';
import { FormPublikowanie } from './FormPublikowanie';
import { StepCzesciKryteria } from './StepCzesciKryteria';
import { StepPozostale } from './StepPozostale';
import { StepPrzedmiotZamowienia } from './StepPrzedmiotZamowienia';
import { StepTerminy } from './StepTerminy';
import { StepWarunki } from './StepWarunki';
import { StepZalaczniki } from './StepZalaczniki';


const DEFAULT_OGLOSZENIE = {
    ogloszenie: {
        id: null,
        status: {},
        numer: null,
        dataOpublikowania : '',
    },
    id: null,
    projekty: [],
    tytul: '',
    zamowieniaUzupelniajace: '',
    nabor: null,
    warunkiZmianyUmowy: '',
    czyDopuszczalnaOfertaCzesciowa: false,
    dataOpublikowania: '',
    terminOfert: '',
    planowanyTerminPodpisaniaUmowy: '',
    zamowienia: [],
    osobyDoKontaktu: [],
    zalaczniki: [],
};


class FormOgloszenie extends BaseComponent {
    componentDidMount() {
        // clear context state before first request to server
        this.context.clearState(
            () => {
                if (this.props.preview) { return }

                document.title = `Baza Konkurencyjności - ${this.state.isNew ? 'dodaj ogłoszenie' : 'edycja ogłoszenia'}`;
                super.componentDidMount();
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userId !== this.props.userId) {
            this.setState(prevState => {
                if (!this.state.isNew) {
                    this.initializeData();  // TODO: check if dictionaries are valid for new user
                }
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.xhrSaveFetch !== null) {
            this.xhrSaveFetch.abort();
        }

        if (this.clearMenuErrorTimeout !== null) {
            clearTimeout(this.clearMenuErrorTimeout);
        }

        // don't leave not default data and errors in state
        this.context.clearState();
        if (this.props.setAdvertisementManagerType) {
            this.props.setAdvertisementManagerType(
                {isAuthor: false, isAdmin: false});
        }
    }

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        const { isNew } = this.state;
        if (!isNew) {
            // clean some data, it will be set from advertisement dictionaries data
            Object.assign(data, {
                projects: [],
                enrollment_numbers: [],
                contact_persons: [],
            })
        }

        this.context.setConstsFromServer(
            data,
            () => {
                // prepare advertisement data
                if (isNew) {
                    this.props.setAdvertisementManagerType(
                        {isAuthor: true, isAdmin: false},
                        this.context.setOgloszenie(
                            null,
                            () => this.setState({initializingOn: false})
                        )
                    );
                } else {
                    this.getAdvertisementDataFromServer(this.props.match.params.id);
                }
            }
        );
    }

    handleSetCurrentMenuLink(id) {
        this.setState({currentMenuLink: id});
        setFocusOnElement(this.formContentRef);
    }

    handleChangeCurrentMenuLink = (stepName) => {
        this.handleSetCurrentMenuLink(this.menuLinksMapNameId[stepName]);
    }

    handleChangeProjectsSelected = (ev) => {
        const checked = ev.target.checked;
        this.setState(
            {projectsSelected: checked},
            () => this.context.changeProjectsSelected(checked)
        );
    }

    handleReturnToList = () => {
        this.props.history.push(URLS[
            this.props.userData.advertisementManagerType.isAdmin
                ? 'managementAdvertisements'
                : 'advertisements'
        ].path);
    }

    handleFetchOgloszenieSuccess = (data, additionalSuccessFunction=null) => {
        this.xhrFetch = null;     // clean xhr object

        let ogloszenie = Object.assign(
            {}, DEFAULT_OGLOSZENIE,
            getSerializedObject(data.advertisement, {
                dateAttributes: [
                    'planowanyTerminPodpisaniaUmowy', 'dataModyfikacji',
                    'poczatekRealizacji', 'koniecRealizacji', 'dataOpublikowania'],
                dateTimeAttributes: ['dataUtworzenia', ],
                addTemporaryId: true,
            })
        );

        const nabor = ogloszenie.ogloszenie.nabor || null;
        Object.assign(ogloszenie, {
            zamowienia: this.reformatPrzedmiotyZamowieniaData(ogloszenie.zamowienia),
            projekty: ogloszenie.ogloszenie.projekty.map(p => p.id),
            osobyDoKontaktu: ogloszenie.osobyDoKontaktu.map(o => o.id),
            nabor: nabor !== null ? {value: nabor.id, label: nabor.calyNumer} : null,
        });
        delete ogloszenie.ogloszenie['projekty'];
        delete ogloszenie.ogloszenie['nabor'];

        document.title = "Baza Konkurencyjności - edycja ogłoszenia " + ogloszenie.tytul;
        this.isAlreadyPublished = !!this.props.preview ? false : parseInt(
            ogloszenie.ogloszenie.status.id) !== parseInt(this.context.roboczeStatus);

        this.context.setConstsFromAdvertisementData(
            data.dictionaries,
            () => {
                const {jestAutorem = false} = ogloszenie.ogloszenie;
                this.props.setAdvertisementManagerType(
                    {isAuthor: jestAutorem, isAdmin: !jestAutorem},
                    () => {
                        this.context.setOgloszenie(
                            ogloszenie,
                            () => this.setState(
                                {
                                    initializingOn: false,
                                    projectsSelected: ogloszenie.projekty.length > 0 || ogloszenie.nabor === null,
                                },
                                () => {
                                    // do some additional steps
                                    if (additionalSuccessFunction) {
                                        additionalSuccessFunction();
                                    }
                                }
                            )
                        );
                    }
                );
            }
        )
    }

    handleFetchError = (data) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState(
            {
                fetchError: translateErrorMessage(data.message),
                initializingOn: false,
            },
            () => {
                if (this.context.savingOn) {
                    this.context.changeFormLockedState('savingOn', false);
                }
            }
        );
    }

    handleFetchIncorrectStatus = (status) => {
        this.xhrFetch = null;     // clean xhr object

        if ([403, 404].includes(status)) {
            this.setState({pageNotFound: true, initializingOn: false});
            return
        }
        this.setState(
            {
                fetchError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
                initializingOn: false,
            },
            () => {
                if (this.context.savingOn) {
                    this.context.changeFormLockedState('savingOn', false);
                }
            }
        );
    }

    handleValidate = () => {
        this.context.changeFormLockedState('validationOn', true);

        this.setState({
            instruction: true,
            validationAria: 'Sprawdź poprawność danych. Zwalidowano ogłoszenie. Wskaż element w menu, aby odczytać wynik walidacji.'
        });
        setTimeout((prevState) => {
            this.setState({ validationAria: prevState });
        }, 500);

        let projectsSelected = this.state.projectsSelected;
        if (
            this.isAlreadyPublished &&
            this.props.userData.advertisementManagerType.isAuthor
        ) {
            // set as null and don't validate it; owner can't edit projects/enrollment
            projectsSelected = null;
        }

        const isValid = this.context.validate(
            projectsSelected,
            this.isAlreadyPublished,
            this.props.userData.advertisementManagerType
        );

        // clear error/info for current step in menu after 10 seconds
        this.clearMenuErrorTimeout = setTimeout(() => {
            this.context.clearStepError(this.menuLinksMapIdName[this.state.currentMenuLink]);
        }, 10000);

        this.context.changeFormLockedState('validationOn', false);

        return isValid
    }

    handleSave = (
        ev=null,
        callbackSuccess=null,
        callbackError=null,
        callbackIncorrectStatus=null
    ) => {

        const cleanData = () => {
            this.xhrSaveFetch = null;     // clean xhr object
            this.context.changeFormLockedState('savingOn', false);
        }

        let callbackError_ = null;
        if (callbackError) {
            callbackError_ = (data) => {
                callbackError(data);
                cleanData();
            }
        }

        let callbackIncorrectStatus_ = null;
        if (callbackIncorrectStatus) {
            callbackIncorrectStatus_ = (status) => {
                callbackIncorrectStatus(status);
                cleanData();
            }
        }

        this.context.changeFormLockedState(
            'savingOn', true,
            () => {
                const isNew = this.state.isNew;
                const data = this.getDataForSaving();
                // save advertisement on server
                this.xhrSaveFetch = fetchApi(
                    isNew ? '/api/announcements' : `/api/announcements/${data.ogloszenie.id}`,
                    isNew ? 'POST' : 'PUT',
                    {},
                    getSerializedObject(data, {toServer: true}),
                    callbackSuccess || this.handleFetchSaveSuccess,
                    callbackError_ || this.handleFetchSaveError,
                    callbackIncorrectStatus_ || this.handleFetchSaveIncorrectStatus,
                );
            }
        )
    }

    handleFetchSaveSuccess = (data, callback=null) => {
        this.xhrSaveFetch = null;
        // clear errors
        this.context.clearFormErrors();

        const ogloszenie = getSerializedObject(data.advertisement);
        this.context.saveOgloszenie(
            ogloszenie,
            () => this.getAdvertisementDataFromServer(
                this.context.ogloszenie.ogloszenie.id,
                () => {
                    this.context.changeFormLockedState(
                        'savingOn', false,
                        () => {
                            this.setState(
                                {
                                    isNew: false,
                                    fetchSaveError: '',
                                    saveBtnText: 'Zapisano',
                                },
                                () => {
                                    this.saveMsgTimeout = setTimeout(() => {
                                        this.setState({saveBtnText: 'Zapisz wersję roboczą'});
                                    }, 5000)
                                    if (callback) { callback() }
                                }
                            );
                        }
                    );
                }
            )
        );
    }

    handleFetchSaveError = (data) => {
        this.xhrSaveFetch = null;     // clean xhr object
        this.setState(
            {fetchSaveError: translateErrorMessage(data.message)},
            () => this.context.changeFormLockedState('savingOn', false)
        );
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.xhrSaveFetch = null;     // clean xhr object
        let msg;
        if (status === 403) {
            msg = 'Nie masz uprawnień do edycji tego ogłoszenia.';
        } else {
            msg = `Wystąpił nieoczekiwany błąd o kodzie ${status}.`;
        }
        this.setState(
            {fetchSaveError: msg},
            () => this.context.changeFormLockedState('savingOn', false)
        );
    }

    setPublishingSuccessInfo(info) {
        this.setState({
            publishingOn: false,
            publishingSuccessInfo: info,
        });
    }

    handlePublishByAdmin = () => {
        if (this.handleValidate()) {
            this.handleSave(
                null,
                () => this.setPublishingSuccessInfo(
                    'Ogłoszenie zostało pomyślnie opublikowane.')
            );
        } else {
            this.setState({
                notValidByAdminError:
                    'Ogłoszenie zawiera błędy. Po ich poprawieniu spróbuj ' +
                    'opublikować ogłoszenie jeszcze raz.',
            });
        }
    }

    handlePublish = () => {
        const { advertisementManagerType } = this.props.userData;
        if (advertisementManagerType.isAuthor) {
            this.setState({publishingOn: true});
        } else if (advertisementManagerType.isAdmin) {
            this.handlePublishByAdmin();
        }
    }

    handleClosePublishingSuccessDialog = () => {
        this.props.history.push(
            this.props.userData.advertisementManagerType.isAuthor
                ? URLS.advertisements.path
                : URLS.advertisementsDetails.getUrl(this.props.match.params.id)
        );
    }

    handleClosePublishingByAdminErrorDialog = () => {
        this.setState({notValidByAdminError: ''});
    }

    // helpers

    preState(props) {
        this.handleSetCurrentMenuLink = this.handleSetCurrentMenuLink.bind(this);
        this.menuLinks = [
            {name: 'przedmiot', id: 1, label: 'Przedmiot', onClick: this.handleSetCurrentMenuLink},
            {name: 'terminy', id: 2, label: 'Terminy', onClick: this.handleSetCurrentMenuLink},
            {name: 'warunki', id: 3, label: 'Warunki', onClick: this.handleSetCurrentMenuLink},
            {name: 'czesciKryteria', id: 4, label: 'Części i kryteria', onClick: this.handleSetCurrentMenuLink},
            {name: 'pozostale', id: 5, label: 'Pozostałe', onClick: this.handleSetCurrentMenuLink},
            {name: 'zalaczniki', id: 6, label: 'Załączniki', onClick: this.handleSetCurrentMenuLink},
        ];
        this.menuLinksMapNameId = {};
        this.menuLinksMapIdName = {};
        for (let link of this.menuLinks) {
            this.menuLinksMapNameId[link.name] = link.id;
            this.menuLinksMapIdName[link.id] = link.name;
        }

        this.isAlreadyPublished = false;
    }

    getAdditionalState(props) {
        return {
            initializingOn: props.preview ? false : true,
            progress: 0,
            currentMenuLink: props.preview && props.step ? this.menuLinksMapNameId[props.step] || 1 : 1,
            saveBtnText: 'Zapisz wersję roboczą',
            instruction: false,
            isNew: props.isNew,
            validationAria: null,
            publishingOn: false,
            projectsSelected: true,
            fetchSaveError: '',
            notValidByAdminError: '',
            publishingSuccessInfo: '',
        }
    }

    postState(props) {
        super.postState(props);
        this.clearMenuErrorTimeout = null;
        this.formContentRef = React.createRef();
        this.menuBasicRef = React.createRef();
        this.xhrSaveFetch = null;
    }

    getFetchUrl() {
        return '/api/dictionaries'
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się pobrać danych do formularza. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania danych do formularza wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    getAdvertisementDataFromServer(id_, additionalSuccessFunction) {
        this.xhrFetch = fetchApi(
            `/api/announcements/${id_}`,
            'GET',
            {},
            {
                dictionaries: ['projects', 'enrollment_numbers', 'contact_persons'],
                version_mode: 'draft_or_last_published'
            },
            (data) => this.handleFetchOgloszenieSuccess(data, additionalSuccessFunction),
            this.handleFetchError,
            this.handleFetchIncorrectStatus,
        );
    }

    computeProgress() {
        let progress;
        if (this.state.currentMenuLink === this.menuLinksMapNameId['przedmiot']) {
            progress = computeProgress(getOrderItemsLength(this.context.ogloszenie.zamowienia) > 0 ? 1 : 0, 1);
        } else {
            progress = computeProgress();
        }
        if (progress !== this.state.progress) {
            this.setState({progress});
        }
    }

    reformatPrzedmiotyZamowieniaData(zamowienia) {
        let temporaryId = 1;
        return zamowienia.map(z => {
            let przedmiotyZamowienia = [];
            for (let pz of z.przedmiotyZamowienia) {
                pz.temporaryId = temporaryId++;
                pz.kategoria = pz.kategoria ? pz.kategoria.id : null;
                pz.podkategoria = pz.podkategoria ? pz.podkategoria.id : null;
                for (let mr of pz.miejscaRealizacji) {
                    mr.typ = mr.typ.id;
                }
                for (let wu of pz.warunkiUdzialu) {
                    wu.typ = wu.typ.id;
                }
                przedmiotyZamowienia.push(pz);
            }
            z.przedmiotyZamowienia = przedmiotyZamowienia;
            return z
        })
    }

    getDataForSaving() {
        if (this.isAlreadyPublished) {
            return this.getPublishedDataForSaving()
        }

        const ogloszenie = this.context.ogloszenie;
        return {
            ogloszenie: {
                id: ogloszenie.ogloszenie.id,
                projekty: ogloszenie.projekty,
                nabor: ogloszenie.nabor === null ? null : ogloszenie.nabor.value,
            },
            id: ogloszenie.id,
            tytul: ogloszenie.tytul.trim(),
            zamowieniaUzupelniajace: ogloszenie.zamowieniaUzupelniajace.trim(),
            warunkiZmianyUmowy: ogloszenie.warunkiZmianyUmowy.trim(),
            czyDopuszczalnaOfertaCzesciowa: ogloszenie.czyDopuszczalnaOfertaCzesciowa,
            planowanyTerminPodpisaniaUmowy: ogloszenie.planowanyTerminPodpisaniaUmowy,
            zamowienia: ogloszenie.zamowienia.map(z => (
                {
                    id: z.id,
                    zamowienieNode: z.zamowienieNode ? z.zamowienieNode.id : null,
                    tytul: z.tytul,
                    czyWariantowe: z.czyWariantowe,
                    przedmiotyZamowienia: z.przedmiotyZamowienia.map(pz => {
                        return Object.assign({}, pz, {kodyCPV: pz.kodyCPV.map(kod_ => kod_.id)})
                    }),
                    szacunkowaWartosc: z.szacunkowaWartosc,
                    kryteriaOceny: z.kryteriaOceny,
                }
            )),
            osobyDoKontaktu: ogloszenie.osobyDoKontaktu,
            zalaczniki: ogloszenie.zalaczniki.map(z => ({id: z.id, nazwa: z.nazwa, plik: z.plik.id})),
        }
    }

    getPublishedDataForSaving() {
        const { ogloszenie} = this.context;
        const { advertisementManagerType } = this.props.userData;
        return {
            ogloszenie: {
                id: ogloszenie.ogloszenie.id,
                // institution administrator can edit projects and enrollments
                ...(advertisementManagerType.isAdmin
                    ? {
                        projekty: ogloszenie.projekty,
                        nabor: ogloszenie.nabor === null ? null : ogloszenie.nabor.value,
                    }
                    : {}
                )
            },
            id: ogloszenie.id,
            tytul: ogloszenie.tytul.trim(),
            planowanyTerminPodpisaniaUmowy: ogloszenie.planowanyTerminPodpisaniaUmowy,
            zamowienia: ogloszenie.zamowienia.map(z => (
                {
                    id: z.id,
                    zamowienieNode: z.zamowienieNode.id,
                    przedmiotyZamowienia: z.przedmiotyZamowienia.map(pz => (
                        {
                            id: pz.id,
                            okresGwarancji: pz.okresGwarancji,
                            opis: pz.opis,
                            kodyCPV: pz.kodyCPV.map(kod_ => kod_.id),
                            miejscaRealizacji: pz.miejscaRealizacji,
                            harmonogramy: pz.harmonogramy,
                            groupUuid: pz.group_uuid,
                        }
                    )),
                    szacunkowaWartosc: z.szacunkowaWartosc,
                }
            )),
            // advertisement owner can edit contact persons
            ...(advertisementManagerType.isAuthor
                ? {osobyDoKontaktu: ogloszenie.osobyDoKontaktu}
                : {}
            ),
            zalaczniki: ogloszenie.zalaczniki.map(
                z => ({id: z.id, nazwa: z.nazwa, plik: z.plik.id})),
        }
    }

    // rendering

    renderContent() {
        const currentMenuLink = this.state.currentMenuLink;

        let fetchSaveError = null;
        if (this.state.fetchSaveError.length) {
            fetchSaveError = (
                <GridCustom centerHorizontal>
                    <Text error role="alert">{this.state.fetchSaveError}</Text>
                    <ButtonStyled onClick={() => this.setState({fetchSaveError: ''})} lite>OK</ButtonStyled>
                </GridCustom>
            );
        }

        return (
            <GridCustom flexL noWrap>
                <GridCellCustom>
                    <MenuBasic
                        addProgressIcon={true}
                        children={this.menuLinks}
                        currentMenuLink={currentMenuLink}
                        disabled={!!this.props.preview || this.context.validationOn || this.context.savingOn}
                        errors={this.context.stepErrors}
                        ref={this.menuBasicRef}
                        title={`${this.state.isNew ? 'Dodanie' : 'Edycja'} ogłoszenia`} />
                    {this.state.instruction && this.renderInstruction()}
                </GridCellCustom>
                <GridCellCustom fill customRef={this.formContentRef} tabIndex="-1">
                    {this.renderFuturePublishingInfo()}
                    <LinearProgressCustom progress={this.state.progress} />
                    {currentMenuLink === this.menuLinksMapNameId['przedmiot'] && this.renderPrzedmiotZamowienia()}
                    {currentMenuLink === this.menuLinksMapNameId['terminy'] && this.renderTerminy()}
                    {currentMenuLink === this.menuLinksMapNameId['warunki'] && this.renderWarunki()}
                    {currentMenuLink === this.menuLinksMapNameId['czesciKryteria'] && this.renderCzesciKryteria()}
                    {currentMenuLink === this.menuLinksMapNameId['pozostale'] && this.renderPozostale()}
                    {currentMenuLink === this.menuLinksMapNameId['zalaczniki'] && this.renderZalaczniki()}

                    {this.renderButtons()}
                    {fetchSaveError}
                </GridCellCustom>
                {this.state.notValidByAdminError.length > 0 &&
                    this.renderPublishingByAdminError()
                }
                {this.state.publishingSuccessInfo.length > 0 &&
                    this.renderPublishingSuccessInfo()
                }
            </GridCustom>
        )
    }

    renderInstruction() {
        return (
            <section className="instruction">
                <Text info><Icon icon="done" aria-hidden="true" /> Poprawna walidacja</Text>
                <Text info><Icon icon="error_outline" aria-hidden="true" /> Wymaga uwagi</Text>
            </section>
        )
    }

    renderFuturePublishingInfo() {
        if (this.props.preview) { return }
        const dataOpublikowania = this.context.ogloszenie.dataOpublikowania;
        if (dataOpublikowania && dataOpublikowania > dateToString(new Date())) {
            return (
                <Text info>
                    To ogłoszenie ma ustawiony przyszły termin publikacji oraz przyszły termin składania ofert. Po zapisie kopii roboczej pola te zostaną wyczyszczone.
                </Text>
            )
        }
        return null
    }

    renderPrzedmiotZamowienia() {
        const isPreview = !!this.props.preview;
        return <StepPrzedmiotZamowienia
                    elementToFocusRef={this.formContentRef}
                    isAlreadyPublished={this.isAlreadyPublished}
                    isPreview={isPreview}
                    pomocEkranowaContent={isPreview ? this.props.content : null}
                    onChangeCurrentMenuLink={this.handleChangeCurrentMenuLink}
                />
    }

    renderTerminy() {
        const isPreview = !!this.props.preview;
        return <StepTerminy
                    elementToFocusRef={this.formContentRef}
                    isPreview={isPreview}
                    pomocEkranowaContent={isPreview ? this.props.content : null}
                    onChangeCurrentMenuLink={this.handleChangeCurrentMenuLink}
                />
    }

    renderWarunki() {
        const isPreview = !!this.props.preview;
        return <StepWarunki
                    elementToFocusRef={this.formContentRef}
                    isAlreadyPublished={this.isAlreadyPublished}
                    isPreview={isPreview}
                    pomocEkranowaContent={isPreview ? this.props.content : null}
                    onChangeCurrentMenuLink={this.handleChangeCurrentMenuLink}
                />
    }

    renderCzesciKryteria() {
        const isPreview = !!this.props.preview;
        return <StepCzesciKryteria
                    elementToFocusRef={this.formContentRef}
                    isAlreadyPublished={this.isAlreadyPublished}
                    isPreview={isPreview}
                    pomocEkranowaContent={isPreview ? this.props.content : null}
                    onChangeCurrentMenuLink={this.handleChangeCurrentMenuLink}
                />
    }

    renderPozostale() {
        const isPreview = !!this.props.preview;
        return <StepPozostale
                    elementToFocusRef={this.formContentRef}
                    isAlreadyPublished={this.isAlreadyPublished}
                    isPreview={isPreview}
                    pomocEkranowaContent={isPreview ? this.props.content : null}
                    projectsSelected={this.state.projectsSelected}
                    onChangeCurrentMenuLink={this.handleChangeCurrentMenuLink}
                    onChangeProjectsSelected={this.handleChangeProjectsSelected}
                />
    }

    renderZalaczniki() {
        const isPreview = !!this.props.preview;
        return <StepZalaczniki
                    elementToFocusRef={this.formContentRef}
                    isAlreadyPublished={this.isAlreadyPublished}
                    isPreview={isPreview}
                    pomocEkranowaContent={isPreview ? this.props.content : null}
                    onChangeCurrentMenuLink={this.handleChangeCurrentMenuLink}
                />
    }

    renderButtons() {
        const isPreview = !!this.props.preview;
        const formLocked = isPreview || this.context.validationOn || this.context.savingOn;
        const { instruction, saveBtnText, validationAria } = this.state;
        return (
            <>
                <GridCustom flexEnd>
                    <ButtonStyled
                        disabled={formLocked}
                        lite
                        noMargin
                        onClick={this.handleValidate}
                    >Sprawdź poprawność danych</ButtonStyled>
                    {validationAria && (
                        <span className="sr-only" role="alert" aria-live="assertive">{validationAria}</span>
                    )}
                    {instruction && (
                        <button type="button" onClick={() => setFocusOnElement(this.menuBasicRef)} className="sr-only">Przejdź do menu</button>
                    )}
                </GridCustom>
                <GridCustom fullwidth className="advertisement-form-save">
                    {(isPreview || this.props.userData.advertisementManagerType.isAuthor) && (
                        <>
                            <ButtonStyled disabled={formLocked} onClick={this.handleSave} save noMargin>{saveBtnText}</ButtonStyled>
                            {saveBtnText === 'Zapisano' && <span role="alert" className="sr-only">Zapisano!</span>}
                        </>
                    )}
                    <ButtonStyled
                        className="btn btn--main btn--save-main"
                        disabled={formLocked}
                        noMargin
                        onClick={this.handlePublish}
                        save
                    >
                        Publikuj ogłoszenie
                    </ButtonStyled>
                </GridCustom>
                <GridCustom>
                    <ButtonStyled disabled={formLocked} onClick={this.handleReturnToList} prev noMargin>Powrót do listy ogłoszeń</ButtonStyled>
                </GridCustom>
                {this.state.publishingOn && (
                    <FormPublikowanie {...this.props} formOgloszenie={this} roboczeStatus={this.context.roboczeStatus} />)}
            </>
        )
    }

    renderPublishingSuccessInfo() {
        return (
            <DialogCustom onClose={this.handleClosePublishingSuccessDialog}>
                <Message
                    success
                    messageIcon="check_circle_outline"
                    messageTitle={this.state.publishingSuccessInfo} />
                <GridCustom flexEnd>
                    <ButtonStyled onClick={this.handleClosePublishingSuccessDialog} primary>OK</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }

    renderPublishingByAdminError() {
        return (
            <DialogCustom onClose={this.handleClosePublishingByAdminErrorDialog}>
                <Message
                    error
                    messageIcon="clear"
                    messageTitle={this.state.notValidByAdminError} />
                <GridCustom flexEnd>
                    <ButtonStyled
                        primary
                        onClick={this.handleClosePublishingByAdminErrorDialog}
                    >
                        OK
                    </ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }
}


FormOgloszenie.contextType = AdvertisementContext;

export { FormOgloszenie };
