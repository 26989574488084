import React from 'react';
import '@material/form-field/dist/mdc.form-field.css';

import {
    Box, ButtonStyled, FieldWithLabel, GridCustom, NumberTextField, Text,
    TextFieldCustom
} from '../../common';


const FormWariant = (props) => {
    const { errors, formLocked, onlyCena, priceAutofocus, wariant } = props;

    const renderCena = () => {
        return (
            <>
                <NumberTextField
                    attrName="cena"
                    autoFocus={priceAutofocus}
                    errors={errors.cena}
                    formLocked={formLocked}
                    fullwidth
                    label="Cena"
                    parentTemporaryId={wariant.temporaryId}
                    value={wariant.cena}
                    onChange={value => props.onChangeWariantAttribute('cena', value)} />

                { errors.cena && <span role="alert" className="sr-only">Formularz zawiera błędy!</span>}
            </>
        )
    }

    if (onlyCena) {
        return renderCena()
    }

    const wariantOpisId = `wariant_${wariant.temporaryId}_opis_id`;
    const isOpisInvalid = (errors.opis || []).length > 0;
    return (
        <Box flexiblewidth tag="li">
            {renderCena()}
            <FieldWithLabel label="Opis" tag="label" labelFor={wariantOpisId}>
                <TextFieldCustom
                    aria-describedby={isOpisInvalid ? 'wariant_error' : null}
                    aria-label={`Opis, maksymalna liczba znaków: 120. Wpisano ${wariant.opis.length} znaków.`}
                    aria-valuemax={120}
                    characterCount
                    clearFieldContext="opis"
                    disabled={formLocked}
                    fullwidth
                    id={wariantOpisId}
                    invalid={isOpisInvalid}
                    maxLength={120}
                    value={wariant.opis}
                    onChange={ev => props.onChangeWariantAttribute('opis', ev.target.value)}
                    onClear={ev => props.onChangeWariantAttribute('opis', '')}
                    textarea />
                {isOpisInvalid && (
                    <>
                        <ul id="wariant_error">{errors.opis.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>
                        <span role="alert" className="sr-only">Formularz zawiera błędy!</span>
                    </>
                )}
            </FieldWithLabel>
            <GridCustom flexEnd>
                <ButtonStyled
                    disabled={formLocked}
                    remove
                    onClick={props.onRemoveWariant}>Usuń<span className="sr-only"> wariant</span></ButtonStyled>
            </GridCustom>
        </Box>
    )
}


export { FormWariant };
