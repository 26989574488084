import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';

import { fetchApi } from '../../../fetch';
import { serializeObjectList } from '../../../serializers';
import {
    sortMiejsceRealizacjiFilter,
    sortProgrammeFilter
} from '../../../sorters';
import { validateSearchFilters } from '../../../validators';
import {
    ButtonStyled,
    DateSelect,
    FieldWithLabel,
    GridCustom,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { FormKodCPV } from './FormKodCPV';


const EMPTY_VALUE = {
    value: 0,
    label: 'Wybierz',
};
const ORDER_ITEM_CATEGORIES = 'order_item_categories';
const ANULOWANE_STATUS = 'CANCELLED';
const OPUBLIKOWANE_STATUS = 'PUBLISHED';
const ZAMKNIETE_STATUS = 'CLOSED';
const ROZSTRZYGNIETE_STATUS = 'RESOLVED';


const serializeProgrammeSelectStructure = (data) => {
    let data_ = {};
    for (let programme of Object.values(data)) {
        data_[programme.id] =
            `${programme.code.toUpperCase()} - ${programme.name}`;
    }
    return Object.entries(data_).sort(sortProgrammeFilter) || []
}


class FiltryWyszukiwarki extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initializingOn: true,
            filters: props.filters,
            errors: {},
            fetchError: '',
            dictionaries: props.dictionaries,
        };

        this.xhrFetch = null;

        this.handleFetchSuccess = this.handleFetchSuccess.bind(this);
        this.handleFetchError = this.handleFetchError.bind(this);
        this.handleFetchIncorrectStatus = this.handleFetchIncorrectStatus.bind(this);
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
    }

    // basic functions

    componentDidMount() {
        if (!Object.keys(this.state.dictionaries).length) {
            // get dictionaries from server
            this.xhrFetch = fetchApi('/api/dictionaries/search-filters',
                'GET',
                {},
                {},
                this.handleFetchSuccess,
                this.handleFetchError,
                this.handleFetchIncorrectStatus,
            );
        } else {
            this.setState({initializingOn: false});
        }
    }

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }
    }

    //handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        let dictionaries = {
            przedmiotZamowieniaKategoria: serializeObjectList(data[ORDER_ITEM_CATEGORIES]),
            programOperacyjny: serializeProgrammeSelectStructure(
                data.operation_programme),
            miejsceRealizacji: Object.entries(data.location_id).sort(
                sortMiejsceRealizacjiFilter) || [],
        };

        this.setState(
            {
                initializingOn: false,
                dictionaries
            },
            () => this.props.updateDictionaries(dictionaries)
        );
    }

    handleFetchError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            initializingOn: false,
            fetchError: `Nie udało się pobrać danych do filtrów. ${data.message}`,
        });
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            initializingOn: false,
            fetchError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    handleChangeFilter(name, value) {
        this.setState(prevState => {
            let dict = {[name]: value};
            if (name === 'advertiserIdentificationNumber') {
                // allow to insert only letters and digits
                dict['advertiserIdentificationNumber'] = value.replace(/[^a-zA-Z\d]/, '');
            } else {
                dict[name] = value;
            }
            if (name === 'category') {
                dict['subcategory'] = null;
            }
            // clean error (just in case if exists)
            let errors = Object.assign({}, prevState.errors);
            delete errors[name];
            return {errors, filters: Object.assign({}, prevState.filters, dict)}
        });
    }

    handleChangeStatusFilter(value, checked) {
        this.setState(prevState => {
            if (checked) {
                if (!prevState.filters.status.includes(value)) {
                    // clean error
                    let errors = Object.assign({}, prevState.errors);
                    delete errors['status'];
                    return {
                        errors,
                        filters: Object.assign({}, prevState.filters, {status: [...prevState.filters.status, value]}),
                    }
                }
            } else {
                if (prevState.filters.status.includes(value)) {
                    // clean error
                    let errors = Object.assign({}, prevState.errors);
                    delete errors['status'];
                    return {
                        errors,
                        filters: Object.assign({}, prevState.filters, {status: prevState.filters.status.filter(s => s !== value)}),
                    }
                }
            }
            return {}
        });
    }

    handleChangeDateFilter(filterName, dataDict) {
        this.setState(prevState => {
            // clean error
            let errors = Object.assign({}, prevState.errors);
            delete errors[filterName];
            return {
                errors,
                filters: Object.assign({}, prevState.filters,
                    {[filterName]: Object.assign({}, prevState.filters[filterName], dataDict)}),
            }
        });
    }

    handleApplyFilters() {
        const filters = this.state.filters;
        const [isValid, errors] = validateSearchFilters(filters);
        if (isValid) {
            this.props.updateFilters(filters);
            return
        }
        this.setState({errors});
    }

    // rendering

    render() {
        if (this.state.initializingOn) {
            return <Text info tabIndex="1">Trwa inicjalizacja danych...</Text>;
        } else if (this.state.fetchError.length) {
            return (
                <>
                    <Text error role="alert">{this.state.fetchError}</Text>
                    <GridCustom flexEnd>
                        <ButtonStyled onClick={this.props.closeFilters} lite>OK</ButtonStyled>
                    </GridCustom>
                </>
            );
        } else {
            return (
                <>
                    {this.renderInformacjeOogloszeniu()}
                    {this.renderPrzedmiotZamowienia()}
                    {this.renderDaneOgloszeniodawcy()}
                    <GridCustom flexEnd>
                        <ButtonStyled onClick={this.props.closeFilters} cancel>Anuluj</ButtonStyled>
                        <ButtonStyled id="zastosuj_kryteria_wyszukiwania_button_id" onClick={this.handleApplyFilters} primary>Zastosuj kryteria</ButtonStyled>
                    </GridCustom>
                </>
            );
        }
    }

    renderInformacjeOogloszeniu() {
        const {
            errors,
            filters,
        } = this.state;
        const statuses = filters.status;
        return (
            <section className="filters-dialog__filters-section">
                <Text>Informacje o ogłoszeniu</Text>
                <GridCustom fullwidth flexM>
                    <FieldWithLabel label="Tytuł ogłoszenia" tag="label" labelFor="filters_title_id">
                        <TextFieldCustom
                            aria-label={`Tytuł ogłoszenia, maksymalna liczba znaków: 1000. Wpisano ${filters.title.length} znaków.`}
                            aria-valuemax={1000}
                            characterCount
                            className="filters__input"
                            clearFieldContext="tytuł ogłoszenia"
                            id="filters_title_id"
                            maxLength={1000}
                            onChange={(ev) => this.handleChangeFilter('title', ev.target.value)}
                            onClear={(ev) => this.handleChangeFilter('title', '')}
                            value={filters.title} />
                    </FieldWithLabel>
                    <fieldset>
                        <Text tag="legend" className="label">Status ogłoszenia</Text>
                        <Checkbox
                            label="Aktywne opublikowane"
                            checked={statuses.includes(OPUBLIKOWANE_STATUS)}
                            onChange={(event) => this.handleChangeStatusFilter(OPUBLIKOWANE_STATUS, event.target.checked)}
                        />
                        <Checkbox
                            label="Zamknięte"
                            checked={statuses.includes(ZAMKNIETE_STATUS)}
                            onChange={(event) => this.handleChangeStatusFilter(ZAMKNIETE_STATUS, event.target.checked)}
                        />
                        <Checkbox
                            label="Anulowane"
                            checked={statuses.includes(ANULOWANE_STATUS)}
                            onChange={(event) => this.handleChangeStatusFilter(ANULOWANE_STATUS, event.target.checked)}
                        />
                        <Checkbox
                            label="Rozstrzygnięte"
                            checked={statuses.includes(ROZSTRZYGNIETE_STATUS)}
                            onChange={(event) => this.handleChangeStatusFilter(ROZSTRZYGNIETE_STATUS, event.target.checked)}
                        />
                        {errors.status && errors.status.length > 0 && <Text error role="alert">{errors.status}</Text>}
                    </fieldset>
                </GridCustom>
                <GridCustom fullwidth flexM>
                    <FieldWithLabel label="Data publikacji" tag="label" inputRef={React.createRef()}>
                        <DateSelect
                            selectAriaLabel={`Data publikacji ${errors.publicationDateRange && errors.publicationDateRange.length > 0 ? errors.publicationDateRange : ''}`}
                            value={filters.publicationDateRange}
                            updateDate={(name, value) => this.handleChangeDateFilter('publicationDateRange', name, value)}
                        />
                        {errors.publicationDateRange && errors.publicationDateRange.length > 0 && <Text error>{errors.publicationDateRange}</Text>}
                    </FieldWithLabel>
                    <FieldWithLabel label="Termin składania ofert" tag="label">
                        <DateSelect
                            selectAriaLabel={`Termin składania ofert ${errors.submissionDeadlineRange && errors.submissionDeadlineRange.length > 0 ? errors.submissionDeadlineRange : ''}`}
                            value={filters.submissionDeadlineRange}
                            updateDate={(name, value) => this.handleChangeDateFilter('submissionDeadlineRange', name, value)}
                        />
                        {errors.submissionDeadlineRange && errors.submissionDeadlineRange.length > 0 && <Text error role="alert">{errors.submissionDeadlineRange}</Text>}
                    </FieldWithLabel>
                </GridCustom>
                <FieldWithLabel
                    label="Program Operacyjny"
                    tag="label"
                    selectRef={React.createRef()}
                >
                    <Select
                        screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                        className="select-custom"
                        isMulti
                        options={this.state.dictionaries.programOperacyjny.map(
                            ([value, label]) => ({value, label}))
                        }
                        placeholder='Zacznij wpisywać...'
                        value={filters.programme}
                        onChange={(selectedOption) => this.handleChangeFilter('programme', selectedOption || [])}
                        styles={customSelectStyles}
                        noOptionsMessage={() => 'Brak wybranej opcji'}
                    />
                </FieldWithLabel>
            </section>
        )
    }

    renderDaneOgloszeniodawcy() {
        const filters = this.state.filters;
        const errorNipOgloszeniodawcy = this.state.errors.advertiserIdentificationNumber;
        return (
            <section className="filters-dialog__filters-section">
                <Text>Dane ogłoszeniodawcy</Text>
                <GridCustom fullwidth flexM>
                    <FieldWithLabel label="Nazwa ogłoszeniodawcy" tag="label" labelFor="filters_nazwa_ogloszeniodawcy_id">
                        <TextFieldCustom
                            aria-label={`Nazwa ogłoszeniodawcy, maksymalna liczba znaków: 250. Wpisano ${filters.advertiserName.length} znaków.`}
                            aria-valuemax={250}
                            characterCount
                            className="filters__input"
                            clearFieldContext="nazwa ogłoszeniodawcy"
                            id="filters_nazwa_ogloszeniodawcy_id"
                            maxLength={250}
                            onChange={(ev) => this.handleChangeFilter('advertiserName', ev.target.value)}
                            onClear={(ev) => this.handleChangeFilter('advertiserName', '')}
                            value={filters.advertiserName} />
                    </FieldWithLabel>
                    <FieldWithLabel label="Numer identyfikacyjny ogłoszeniodawcy" tag="label" labelFor="filters_numer_identyfikacyjny_ogloszeniodawcy_id">
                        <TextFieldCustom
                            aria-describedby={(errorNipOgloszeniodawcy || '').length > 0 ? 'filters_numer_identyfikacyjny_ogloszeniodawcy_error' : null}
                            aria-label={`Numer identyfikacyjny ogłoszeniodawcy: 255. Wpisz tylko cyfry. Wpisano ${filters.advertiserIdentificationNumber.length} znaków.`}
                            aria-valuemax={255}
                            characterCount
                            className="filters__input"
                            clearFieldContext="numer identyfikacyjny ogłoszeniodawcy"
                            id="filters_numer_identyfikacyjny_ogloszeniodawcy_id"
                            invalid={(errorNipOgloszeniodawcy || '').length > 0}
                            maxLength={255}
                            onChange={(ev) => this.handleChangeFilter('advertiserIdentificationNumber', ev.target.value)}
                            onClear={(ev) => this.handleChangeFilter('advertiserIdentificationNumber', '')}
                            onKeyUp={(ev) => this.handleChangeFilter('advertiserIdentificationNumber', ev.target.value)}
                            value={filters.advertiserIdentificationNumber} />
                        {(errorNipOgloszeniodawcy || '').length > 0 && <Text error id="filters_numer_identyfikacyjny_ogloszeniodawcy_error">{errorNipOgloszeniodawcy}</Text>}
                    </FieldWithLabel>
                </GridCustom>
            </section>
        )
    }

    renderPrzedmiotZamowienia() {
        const {
            miejsceRealizacji,
            przedmiotZamowieniaKategoria,
        } = this.state.dictionaries;
        const {
            category,
            cpvItem,
            fulfillmentPlaces,
            subcategory,
        } = this.state.filters;
        return (
            <section className="filters-dialog__filters-section">
                <Text>Przedmiot zamówienia w ogłoszeniu</Text>
                <GridCustom fullwidth flexM>
                    <FieldWithLabel
                        label="Kategoria przedmiotu zamówienia"
                        tag="label"
                        selectRef={React.createRef()}
                    >
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom"
                            options={[EMPTY_VALUE, ].concat(...przedmiotZamowieniaKategoria.map(t => ({value: t.id, label: t.nazwa})))}
                            value={category || EMPTY_VALUE}
                            onChange={(selectedOption) => {
                                this.handleChangeFilter('category', selectedOption.value === 0 ? null : selectedOption);
                            }}
                            styles={customSelectStyles}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                    </FieldWithLabel>
                    <FieldWithLabel
                        label="Podkategoria"
                        aria-label={category !== null ? null : 'Podkategoria, pole nieaktywne. Aby wybrać podkategorię, musisz wybrać kategorię przedmiotu zamówienia.'}
                        selectRef={React.createRef()}
                        tag="label"
                        tabIndex={category !== null ? null : '0'}
                    >
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom"
                            isDisabled={category === null}
                            options={category === null ? [EMPTY_VALUE, ] : [EMPTY_VALUE, ].concat(
                                ...przedmiotZamowieniaKategoria.filter(k => parseInt(k.id) === parseInt(category.value))[0].podkategorie.map(p => ({value: p.id, label: p.nazwa})))}
                            value={subcategory || EMPTY_VALUE}
                            onChange={(selectedOption) => this.handleChangeFilter('subcategory', selectedOption.value === 0 ? null : selectedOption)}
                            styles={customSelectStyles}
                            aria-hidden={category === null}
                            aria-label={category !== null && 'Podkategoria'}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                    </FieldWithLabel>
                </GridCustom>
                <GridCustom fullwidth flexM>
                    <FormKodCPV
                        isMulti
                        initial={cpvItem}
                        addKodCPV={selectedOptions => this.handleChangeFilter('cpvItem', selectedOptions || [])} />
                    <FieldWithLabel
                        label="Miejsce realizacji"
                        tag="label"
                        selectRef={React.createRef()}
                    >
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom"
                            isMulti
                            options={miejsceRealizacji.map(m => ({value: m[0], label: m[1]}))}
                            placeholder='Zacznij wpisywać...'
                            value={fulfillmentPlaces}
                            onChange={(selectedOption) => this.handleChangeFilter('fulfillmentPlaces', selectedOption || [])}
                            styles={customSelectStyles}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                    </FieldWithLabel>
                </GridCustom>
            </section>
        )
    }
}

export { FiltryWyszukiwarki };
