import React, { Component } from 'react';
import { Radio } from '@rmwc/radio';

import { fetchApi } from '../../../fetch';
import {
    hasPerspectiveInstitutions,
    hasPerspectiveRegions,
    hasSubactivities,
    sortProgrammes
} from '../../../helpers';
import { API_URLS } from '../../../urls/api';
import {
    ErrorList,
    FieldWithLabel,
    GridCustom,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';


const SELECT_CLASS_NAME = 'select-custom';
const SELECT_INVALID_CLASS_NAME = 'select-custom select-custom--invalid';
export const INSTITUTION_NUMBER_DEFAULT_DICT = {
    value: '',
    code: 'AA.00',
    label: 'AA.00',
};


export class ProjectNumberSelectsWithPerspectives extends Component {

    constructor(props) {
        super(props);
        // please, don't change nulls into empty lists, otherwise you destroy
        // a mechanism for filling selects
        this.state = {
            programmes: null,
            priorities: null,
            activities: null,
            regions: null,
            institutions: null,
            activityFetchError: '',
            priorityFetchError: '',
            programmeFetchError: '',
            regionFetchError: '',
            institutionFetchError: '',
        }
        this.xhrFetch = null;
    }

    componentDidMount() {
        if (!this.props.perspectives.length) { return }

        if (this.props.perspectivesAsSelect) {
            const { perspective } = this.props;
            if (!(perspective && perspective.value)) { return }

            if (!perspective.label) {
                this.setDefaultSelectValueLabelBasedOnId(
                    this.props.perspectives, perspective, 'perspective',
                    this.props.onChangePerspective
                );
                return
            }
        }
        this.fetchProgrammes();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.programmeFetchError.length ||
            this.state.activityFetchError.length ||
            this.state.priorityFetchError.length ||
            this.xhrFetch !== null) { return }

        // set perspective, programme, priority, activity, institution, region
        if (!this.props.perspectives.length) { return }

        const { perspective } = this.props;
        if (!(perspective && perspective.value)) { return }

        if (perspective !== prevProps.perspective) {
            this.fetchProgrammes();
            return
        }

        const { programme } = this.props;
        if (!this.state.programmes || !(programme && programme.value)) {
            return
        }

        if (!programme.label) {
            this.setDefaultSelectValueLabelBasedOnId(
                this.state.programmes, programme, 'programme',
                this.props.onChangeProgramme
            );
        } else if (
            programme !== prevProps.programme ||
            (programme && !this.state.priorities && !prevState.priorities)
        ) {
            this.fetchPriorities();
        } else {
            const { priority } = this.props;
            if (
                !this.state.priorities ||
                !(priority && priority.value)
            ) { return }

            if (!priority.label) {
                this.setDefaultSelectValueLabelBasedOnId(
                    this.state.priorities, priority, 'priority',
                    this.props.onChangePriority
                );
            } else if (
                priority !== prevProps.priority ||
                (priority && !this.state.activities && !prevState.activities)
            ) {
                this.fetchActivities();
            } else {
                const { activity } = this.props;
                if (
                    !this.state.activities ||
                    !(activity && activity.value)
                ) { return }

                const hasRegions = hasPerspectiveRegions(perspective);
                const hasInstitutions = hasPerspectiveInstitutions(perspective);

                if (!activity.label) {
                    this.setDefaultSelectValueLabelBasedOnId(
                        this.state.activities, activity, 'activity',
                        this.props.onChangeActivity
                    );
                } else if (
                    activity !== prevProps.activity ||
                    (hasRegions && !this.state.regions && !prevState.regions) ||
                    (hasInstitutions && !this.state.institutions && !prevState.institutions)
                ) {
                    if (hasRegions) {
                        this.fetchRegions();
                    } else if (hasInstitutions) {
                        this.fetchInstitutions();
                    }
                } else {
                    if (hasRegions) {
                        const {region} = this.props;
                        if (
                            !this.state.regions ||
                            !(region && region.value)
                        ) {
                            return
                        }

                        if (!region.label) {
                            this.setDefaultSelectValueLabelBasedOnId(
                                this.state.regions, region, 'region',
                                this.props.onChangeActivity
                            );
                        }
                    } else if (hasInstitutions) {
                        const {institution} = this.props;
                        if (
                            !this.state.institutions ||
                            !(institution && institution.value)
                        ) {
                            return
                        }

                        if (!institution.label) {
                            this.setDefaultSelectValueLabelBasedOnId(
                                this.state.institutions, institution,
                                'institution', this.props.onChangeActivity
                            );
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }
    }

    // handlers

    handleChangePerspectiveSelect = (selectedOption) => {
        selectedOption = selectedOption || null;
        // if user clicked the same option
        if (this.props.perspective === selectedOption) { return }
        this.setState(
            {
                programmes: null,
                priorities: null,
                activities: null,
                regions: null,
                institutions: null,
                priorityFetchError: '',
                activityFetchError: '',
                regionFetchError: '',
                institutionFetchError: '',
            },
            () => this.props.onChangePerspective(
                {
                    perspective: selectedOption,
                    programme: null,
                    priority: null,
                    activity: null,
                    region: null,
                    institution: null,
                }
            )
        );
    }

    handleChangePerspective = (ev) => {
        const value = ev.currentTarget.value;
        this.setState(
            {
                programmes: null,
                priorities: null,
                activities: null,
                regions: null,
                institutions: null,
                priorityFetchError: '',
                activityFetchError: '',
                programmeFetchError: '',
                regionFetchError: '',
                institutionFetchError: '',
            },
            () => {
                this.props.onChangePerspective(
                    {
                        perspective: this.props.perspectives.filter(
                            perspective_ =>
                                perspective_.value.toString() === value.toString()
                        )[0],
                        programme: null,
                        priority: null,
                        activity: null,
                        ...(this.props.withInstitutionNumber
                            ? {institutionNumber: {...INSTITUTION_NUMBER_DEFAULT_DICT}}
                            : {}
                        ),
                        region: null,
                        institution: null,
                    }
                )
            }
        );
    };

    handleChangeProgramme = (selectedOption) => {
        selectedOption = selectedOption || null;
        // if user clicked the same option
        if (this.props.programme === selectedOption) { return }
        this.setState(
            {
                priorities: null,
                activities: null,
                regions: null,
                institutions: null,
                priorityFetchError: '',
                activityFetchError: '',
                regionFetchError: '',
                institutionFetchError: '',
            },
            () => this.props.onChangeProgramme(
                {
                    programme: selectedOption,
                    priority: null,
                    activity: null,
                    region: null,
                    institution: null,
                }
            )
        );
    };

    handleChangePriority = (selectedOption) => {
        selectedOption = selectedOption || null;
        // if user clicked the same option
        if (this.props.priority === selectedOption) { return }
        this.setState(
            {
                activities: null,
                regions: null,
                institutions: null,
                activityFetchError: '',
                regionFetchError: '',
                institutionFetchError: '',
            },
            () => this.props.onChangePriority(
                {
                    priority: selectedOption,
                    activity: null,
                    region: null,
                    institution: null,
                })
        );
    };

    handleChangeActivity = (selectedOption) => {
        selectedOption = selectedOption || null;
        // if user clicked the same option
        if (this.props.activity === selectedOption) { return }
        this.setState(
            {
                regions: null,
                institutions: null,
                regionFetchError: '',
                institutionFetchError: '',
            },
            () => this.props.onChangeActivity(
                {
                    activity: selectedOption,
                    region: null,
                    institution: null,
                })
        );
    };

    handleChangInstitutionNumber = (value) => {
        this.props.onChangeInstitutionNumber(value);
    }

    handleChangeRegion = (selectedOption) => {
        selectedOption = selectedOption || null;
        this.props.onChangeRegion({region: selectedOption});
    }

    handleChangeInstitution = (selectedOption) => {
        selectedOption = selectedOption || null;
        this.props.onChangeInstitution({institution: selectedOption});
    }

    handleFetchObjectsSuccess = (data, objectListName) => {
        this.xhrFetch = null;
        this.setState({
            [objectListName]: data[objectListName].map(p => ({
                value: p.id,
                code: p.code,
                label: `${p.code} - ${p.name}`
            }))
        });
    }

    handleFetchObjectsError = (data, errorObjectLabel, errorName) => {
        this.xhrFetch = null;
        this.setState({
            [errorName]: `Nie udało się pobrać ${errorObjectLabel}.`,
        });
    }

    handleFetchObjectsIncorrectStatus = (status, errorObjectLabel, errorName) => {
        this.xhrFetch = null;
        this.setState({
            [errorName]: `Nie udało się pobrać ${errorObjectLabel}. ` +
            `Wystąpił nieoczekiwany błąd o kodzie ${status}.`
        });
    }

    // helpers

    setDefaultSelectValueLabelBasedOnId(
        objectsList, object, objectName, changeObjectHandler
    ) {
        for (let obj of objectsList) {
            if (obj.value === object.value) {
                changeObjectHandler({[objectName]: obj});
                return
            }
        }
        changeObjectHandler({[objectName]: null});
    }

    fetchObjects({
        apiUrlName,
        errorName,
        errorObjectLabel,
        objectListName,
        parentObjectName
    }) {
        const parent = this.props[parentObjectName];
        if (!parent || !(parent.value || parent.id)) { return }
        this.xhrFetch = fetchApi(
            API_URLS[apiUrlName].getUrl(parent.value || parent.id),
            'GET',
            {},
            {mode: this.props.mode || 'default'},
            (data) => this.handleFetchObjectsSuccess(data, objectListName),
            (data) => this.handleFetchObjectsError(
                data, errorObjectLabel, errorName),
            (status) => this.handleFetchObjectsIncorrectStatus(
                status, errorObjectLabel, errorName),
        );
    }

    fetchProgrammes() {
        this.fetchObjects({
            apiUrlName: 'programmes',
            errorName: 'programmeFetchError',
            errorObjectLabel: 'programów operacyjnych',
            objectListName: 'programmes',
            parentObjectName: 'perspective',
        });
    }

    fetchPriorities() {
        this.fetchObjects({
            apiUrlName: 'priorities',
            errorName: 'priorityFetchError',
            errorObjectLabel: 'osi priorytetowych',
            objectListName: 'priorities',
            parentObjectName: 'programme',
        });
    }

    fetchActivities() {
        this.fetchObjects({
            apiUrlName: 'activities',
            errorName: 'activityFetchError',
            errorObjectLabel: hasSubactivities(this.props.perspective)
                ? 'działań i poddziałań' : 'działań',
            objectListName: 'activities',
            parentObjectName: 'priority',
        });
    }

    fetchRegions() {
        this.fetchObjects({
            apiUrlName: 'regions',
            errorName: 'regionFetchError',
            errorObjectLabel: 'regionów',
            objectListName: 'regions',
            parentObjectName: 'activity',
        });
    }

    fetchInstitutions() {
        this.fetchObjects({
            apiUrlName: 'institutions',
            errorName: 'institutionFetchError',
            errorObjectLabel: 'instytucji',
            objectListName: 'institutions',
            parentObjectName: 'activity',
        });
    }

    // rendering

    render() {
        const {
            activity,
            activityErrors,
            disabled,
            isClearable,
            institution,
            institutionErrors,
            institutionNumber,
            institutionNumberErrors,
            perspective,
            perspectiveErrors = [],
            perspectives,
            perspectivesAsSelect,
            priority,
            priorityErrors,
            programme,
            programmeErrors,
            region,
            regionErrors,
            withInstitutionNumber
        } = this.props;
        const {
            activityFetchError,
            institutionFetchError,
            priorityFetchError,
            programmeFetchError,
            regionFetchError
        } = this.state;

        const hasRegions = hasPerspectiveRegions(perspective);
        const hasInstitutions = hasPerspectiveInstitutions(perspective);

        const isPerspectiveInvalid = perspectivesAsSelect &&
            perspectiveErrors.length > 0;
        const isProgrammeInvalid = programmeErrors.length > 0;
        const isPriorityInvalid = priorityErrors.length > 0;
        const isActivityInvalid = activityErrors.length > 0;
        let isInstitutionNumberInvalid = false;
        if (withInstitutionNumber) {
            isInstitutionNumberInvalid =
                hasRegions && institutionNumberErrors.length > 0;
        }
        const isRegionInvalid = hasRegions && regionErrors.length > 0;
        const isInstitutionInvalid = hasInstitutions && institutionErrors.length > 0;
        const formInvalid = isPerspectiveInvalid || isProgrammeInvalid ||
            isPriorityInvalid || isActivityInvalid ||
            isInstitutionNumberInvalid || isRegionInvalid ||
            isInstitutionInvalid;

        const activityLabel = hasSubactivities(perspective)
            ? 'Działanie i poddziałanie' : 'Działanie';

        return (
            <>
                <GridCustom fullwidth>
                    {perspectivesAsSelect
                        ? (
                            <FieldWithLabel
                                label="Perspektywa finansowa"
                                tag="label"
                                selectRef={React.createRef()}
                            >
                                <Select
                                    className={SELECT_CLASS_NAME}
                                    isClearable={isClearable}
                                    isDisabled={disabled}
                                    noOptionsMessage={() => 'Brak wybranej opcji'}
                                    options={perspectives}
                                    placeholder="Zacznij wpisywać..."
                                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                    styles={customSelectStyles}
                                    value={perspective}
                                    onChange={this.handleChangePerspectiveSelect} />
                                {isPerspectiveInvalid &&
                                    <ErrorList
                                        containerId="perspective"
                                        errorList={perspectiveErrors}
                                    />
                                }
                            </FieldWithLabel>
                        )
                        : (
                            <fieldset>
                                <Text tag="legend" className="label">Perspektywa finansowa</Text>
                                {perspectives.map(perspective_ => (
                                    <Radio
                                        checked={perspective.value.toString() === perspective_.value.toString()}
                                        disabled={disabled}
                                        key={perspective_.value}
                                        label={perspective_.label}
                                        name="typRadioGroup"
                                        value={perspective_.value}
                                        onChange={this.handleChangePerspective}
                                    />
                                ))}
                            </fieldset>
                        )
                    }
                </GridCustom>
                <GridCustom>
                    <FieldWithLabel
                        label="Program Operacyjny"
                        tag="label"
                        selectRef={React.createRef()}
                    >
                        <Select
                            aria-describedby={programmeFetchError.length > 0 ? 'programmeFetch_error' : null}
                            className={isProgrammeInvalid ? SELECT_INVALID_CLASS_NAME : SELECT_CLASS_NAME}
                            isClearable={isClearable}
                            isDisabled={disabled || (perspectivesAsSelect && !perspective)}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                            options={(this.state.programmes || []).sort(sortProgrammes)}
                            placeholder="Zacznij wpisywać..."
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            styles={customSelectStyles}
                            value={programme}
                            onChange={this.handleChangeProgramme} />
                        {isProgrammeInvalid &&
                            <ErrorList
                                containerId="programOperacyjny_error"
                                errorList={programmeErrors}
                            />
                        }
                        {programmeFetchError.length > 0 && <Text error id="programmeFetch_error">{programmeFetchError}</Text>}
                    </FieldWithLabel>
                    <FieldWithLabel
                        label="Oś priorytetowa"
                        aria-label={programme !== null ? null : 'Oś priorytetowa, pole nieaktywne. Aby wybrać oś priorytetową, musisz wybrać Program operacyjny.'}
                        tag="label"
                        selectRef={React.createRef()}
                        tabIndex={programme !== null ? null : '0'}>
                        <Select
                            aria-describedby={priorityFetchError.length > 0 ? 'osPriorytetowaFetch_error' : null}
                            className={isPriorityInvalid ? SELECT_INVALID_CLASS_NAME : SELECT_CLASS_NAME}
                            isClearable={isClearable}
                            isDisabled={disabled || programme === null}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                            options={programme !== null ? (this.state.priorities || []) : null}
                            placeholder="Zacznij wpisywać..."
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            styles={customSelectStyles}
                            value={priority}
                            onChange={this.handleChangePriority} />
                        {isPriorityInvalid &&
                            <ErrorList
                                containerId="osPriorytetowa_error"
                                errorList={priorityErrors}
                            />
                        }
                        {priorityFetchError.length > 0 && <Text error id="osPriorytetowaFetch_error">{priorityFetchError}</Text>}
                    </FieldWithLabel>
                </GridCustom>
                <GridCustom>
                    <FieldWithLabel
                        label={activityLabel}
                        aria-label={priority !== null ? null : `${activityLabel}, pole nieaktywne. Aby wybrać działanie i poddziałanie, musisz wybrać oś priorytetową.`}
                        tag="label"
                        selectRef={React.createRef()}
                        tabIndex={priority !== null ? null : '0'}
                    >
                        <Select
                            aria-describedby={activityFetchError.length > 0 ? 'dzialanie_fetch_error' : null}
                            className={isActivityInvalid ? SELECT_INVALID_CLASS_NAME : SELECT_CLASS_NAME}
                            isClearable={isClearable}
                            isDisabled={disabled || priority === null}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                            options={priority !== null ? (this.state.activities || []) : null}
                            placeholder="Zacznij wpisywać..."
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            styles={customSelectStyles}
                            value={activity}
                            onChange={this.handleChangeActivity} />
                        {isActivityInvalid &&
                            <ErrorList
                                containerId="dzialanie_error"
                                errorList={activityErrors}
                            />
                        }
                        {activityFetchError.length > 0 && <Text error id="dzialanie_fetch_error">{activityFetchError}</Text>}
                    </FieldWithLabel>
                </GridCustom>
                {hasRegions && (
                    <>
                        {withInstitutionNumber && (
                            <GridCustom>
                                <FieldWithLabel
                                    label="Numer instytucji"
                                    labelFor="numer_instytucji_id"
                                    tag="label"
                                    selectRef={React.createRef()}
                                >
                                    <TextFieldCustom
                                        aria-describedby={`institution_number_mask_info${isInstitutionNumberInvalid ? ' numer_instytucji_error' : ''}`}
                                        aria-label={`Numer instytucji. Wpisano ${institutionNumber.value.length} znaków.`}
                                        aria-required={true}
                                        aria-valuemax={5}
                                        characterCount
                                        clearFieldContext="numer instytucji"
                                        disabled={disabled}
                                        fullwidth
                                        id="numer_instytucji_id"
                                        invalid={isInstitutionNumberInvalid}
                                        maxLength={5}
                                        value={institutionNumber.value}
                                        onChange={event => this.handleChangInstitutionNumber(event.target.value)}
                                        onClear={event => this.handleChangInstitutionNumber('')} />
                                    {isInstitutionNumberInvalid &&
                                        <ErrorList
                                            containerId="numer_instytucji_error"
                                            errorList={institutionNumberErrors}
                                        />
                                    }
                                    <Text info id="institution_number_mask_info">
                                        <span className="sr-only">Wpisz numer zgodnie ze wzorem: </span>AA.00
                                    </Text>
                                </FieldWithLabel>
                            </GridCustom>
                        )}
                        <GridCustom>
                            <FieldWithLabel
                                label="Region"
                                aria-label={activity !== null ? null : 'Region, pole nieaktywne. Aby wybrać region, musisz wybrać działanie i poddziałanie.'}
                                tag="label"
                                selectRef={React.createRef()}
                                tabIndex={activity !== null ? null : '0'}
                            >
                                <Select
                                    aria-describedby={regionFetchError.length > 0 ? 'region_fetch_error' : null}
                                    className={isRegionInvalid ? SELECT_INVALID_CLASS_NAME : SELECT_CLASS_NAME}
                                    isClearable={isClearable}
                                    isDisabled={disabled || activity === null}
                                    noOptionsMessage={() => 'Brak wybranej opcji'}
                                    options={activity !== null ? (this.state.regions || []) : null}
                                    placeholder="Zacznij wpisywać..."
                                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                    styles={customSelectStyles}
                                    value={region}
                                    onChange={this.handleChangeRegion} />
                                {isRegionInvalid &&
                                    <ErrorList
                                        containerId="region_error"
                                        errorList={regionErrors}
                                    />
                                }
                                {regionFetchError.length > 0 &&
                                    <Text error id="region_fetch_error">{regionFetchError}</Text>
                                }
                                {formInvalid && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
                            </FieldWithLabel>
                        </GridCustom>
                    </>
                )}
                {hasInstitutions && (
                    <GridCustom>
                        <FieldWithLabel
                            label="Instytucja"
                            aria-label={activity !== null ? null : 'Instytucja, pole nieaktywne. Aby wybrać instytucję, musisz wybrać działanie.'}
                            tag="label"
                            selectRef={React.createRef()}
                            tabIndex={activity !== null ? null : '0'}
                        >
                            <Select
                                aria-describedby={institutionFetchError.length > 0 ? 'institution_fetch_error' : null}
                                className={isInstitutionInvalid ? SELECT_INVALID_CLASS_NAME : SELECT_CLASS_NAME}
                                isClearable={isClearable}
                                isDisabled={disabled || activity === null}
                                noOptionsMessage={() => 'Brak wybranej opcji'}
                                options={activity !== null ? (this.state.institutions || []) : null}
                                placeholder="Zacznij wpisywać..."
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                styles={customSelectStyles}
                                value={institution}
                                onChange={this.handleChangeInstitution} />
                            {isInstitutionInvalid &&
                                <ErrorList
                                    containerId="institution_error"
                                    errorList={institutionErrors}
                                />
                            }
                            {institutionFetchError.length > 0 &&
                                <Text error id="institution_fetch_error">{institutionFetchError}</Text>
                            }
                            {formInvalid && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
                        </FieldWithLabel>
                    </GridCustom>
                )}
            </>
        )
    }
}
