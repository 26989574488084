const foreignCountries = {
    1: 'Afganistan',
    2: 'Albania',
    3: 'Algieria',
    4: 'Andora',
    5: 'Angola',
    6: 'Anguilla',
    7: 'Antarktyda',
    8: 'Antigua i Barbuda',
    9: 'Arabia Saudyjska',
    10: 'Argentyna',
    11: 'Armenia',
    12: 'Aruba',
    13: 'Australia',
    14: 'Austria',
    15: 'Azerbejdżan',
    16: 'Bahamy',
    17: 'Bahrajn',
    18: 'Bangladesz',
    19: 'Barbados',
    20: 'Belgia',
    21: 'Belize',
    22: 'Benin',
    23: 'Bermudy',
    24: 'Bhutan',
    25: 'Białoruś',
    26: 'Boliwia',
    27: 'Bonaire, Sint Eustatius i Saba',
    28: 'Botswana',
    29: 'Bośnia i Hercegowina',
    30: 'Brazylia',
    31: 'Brunei Darussalam',
    32: 'Brytyjskie Terytorium Oceanu Indyjskiego',
    33: 'Brytyjskie Wyspy Dziewicze',
    34: 'Bułgaria',
    35: 'Burkina Faso',
    36: 'Burundi',
    37: 'Ceuta',
    38: 'Chile',
    39: 'Chiny',
    40: 'Chorwacja',
    41: 'Curaçao',
    42: 'Cypr',
    43: 'Czad',
    44: 'Czarnogóra',
    45: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
    46: 'Dania',
    47: 'Demokratyczna Republika Kongo',
    48: 'Dominika',
    49: 'Dominikana',
    50: 'Dżibuti',
    51: 'Egipt',
    52: 'Ekwador',
    53: 'Erytrea',
    54: 'Estonia',
    55: 'Etiopia',
    56: 'Falklandy',
    57: 'Fidżi',
    58: 'Filipiny',
    59: 'Finlandia',
    60: 'Francja',
    61: 'Francuskie Terytorium Południowe',
    62: 'Gabon',
    63: 'Gambia',
    64: 'Ghana',
    65: 'Gibraltar',
    66: 'Grecja',
    67: 'Grenada',
    68: 'Grenlandia',
    69: 'Gruzja',
    70: 'Guam',
    71: 'Gujana',
    72: 'Gujana Francuska',
    73: 'Gwadelupa',
    74: 'Gwatemala',
    75: 'Gwinea',
    76: 'Gwinea Równikowa',
    77: 'Gwinea-Bissau',
    78: 'Haiti',
    79: 'Hiszpania',
    80: 'Holandia',
    81: 'Honduras',
    82: 'Hongkong',
    83: 'Indie',
    84: 'Indonezja',
    85: 'Irak',
    86: 'Iran',
    87: 'Irlandia',
    88: 'Islandia',
    89: 'Izrael',
    90: 'Jamajka',
    91: 'Japonia',
    92: 'Jemen',
    93: 'Jordania',
    94: 'Kajmany',
    95: 'Kambodża',
    96: 'Kamerun',
    97: 'Kanada',
    98: 'Katar',
    99: 'Kazachstan',
    100: 'Kenia',
    101: 'Kirgistan',
    102: 'Kiribati',
    103: 'Kolumbia',
    104: 'Komory',
    105: 'Kongo',
    106: 'Koreańska Republika Ludowo-Demokratyczna',
    107: 'Kosowo',
    108: 'Kostaryka',
    109: 'Kuba',
    110: 'Kuwejt',
    111: 'Laos',
    112: 'Lesotho',
    113: 'Liban',
    114: 'Liberia',
    115: 'Libia',
    116: 'Liechtenstein',
    117: 'Litwa',
    118: 'Luksemburg',
    119: 'Łotwa',
    120: 'Macedonia',
    121: 'Madagaskar',
    122: 'Majotta',
    123: 'Makau',
    124: 'Malawi',
    125: 'Malediwy',
    126: 'Malezja',
    127: 'Mali',
    128: 'Malta',
    129: 'Mariany Północne',
    130: 'Maroko',
    131: 'Martynika',
    132: 'Mauretania',
    133: 'Mauritius',
    134: 'Meksyk',
    135: 'Melilla',
    136: 'Mikronezja',
    137: 'Mołdawia',
    138: 'Monako',
    139: 'Mongolia',
    140: 'Montserrat',
    141: 'Mozambik',
    142: 'Myanmar (Burma)',
    143: 'Namibia',
    144: 'Nauru',
    145: 'Nepal',
    146: 'Niemcy',
    147: 'Niger',
    148: 'Nigeria',
    149: 'Nikaragua',
    150: 'Niue',
    151: 'Norfolk',
    152: 'Norwegia',
    153: 'Nowa Kaledonia',
    154: 'Nowa Zelandia',
    155: 'Oman',
    156: 'Pakistan',
    157: 'Palau',
    158: 'Palestyna',
    159: 'Panama',
    160: 'Papua Nowa Gwinea',
    161: 'Paragwaj',
    162: 'Peru',
    163: 'Pitcairn',
    164: 'Polinezja Francuska',
    165: 'Południowa Georgia i Południowe Wyspy Sandwich',
    166: 'Portoryko',
    167: 'Portugalia',
    168: 'Rep.Połud.Afryki',
    169: 'Rep.Środkowoafryańska',
    170: 'Republika Czeska',
    171: 'Republika Korei',
    172: 'Reunion',
    173: 'Rosja',
    174: 'Rumunia',
    175: 'Rwanda',
    176: 'Sahara Zachodnia',
    177: 'Saint Barthelemy',
    178: 'Saint Kitts i Nevis',
    179: 'Saint Lucia',
    180: 'Saint Vincent i Grenadyny',
    181: 'Saint-Pierre i Miquelon',
    182: 'Salwador',
    183: 'Samoa',
    184: 'Samoa Amerykańskie',
    185: 'San Marino',
    186: 'Senegal',
    187: 'Serbia',
    188: 'Seszele',
    189: 'Sierra Leone',
    190: 'Singapur',
    191: 'Słowacja',
    192: 'Słowenia',
    193: 'Somalia',
    194: 'Sri Lanka',
    195: 'Stany Zjednoczone Ameryki',
    196: 'Suazi',
    197: 'Sudan',
    198: 'Sudan Południowy',
    199: 'Surinam',
    200: 'Svalbard i Jan Mayen',
    201: 'Syria',
    202: 'Szwajcaria',
    203: 'Szwecja',
    204: 'Tadżykistan',
    205: 'Tajlandia',
    206: 'Tajwan',
    207: 'Tanzania',
    208: 'Togo',
    209: 'Tokelau',
    210: 'Tonga',
    211: 'Trynidad i Tobago',
    212: 'Tunezja',
    213: 'Turcja',
    214: 'Turkmenistan',
    215: 'Tuvalu',
    216: 'Uganda',
    217: 'Ukraina',
    218: 'Urugwaj',
    219: 'Uzbekistan',
    220: 'Vanuatu',
    221: 'Wallis i Futuna',
    222: 'Watykan',
    223: 'Wenezuela',
    224: 'Węgry',
    225: 'Wielka Brytania',
    226: 'Wietnam',
    227: 'Włochy',
    228: 'Wschodni Timor',
    229: 'Wybrzeże Kości Słoniowej',
    230: 'Wyspa Bouveta',
    231: 'Wyspa Bożego Narodzenia',
    232: 'Wyspa Sint Maarten',
    233: 'Wyspa Świętej Heleny',
    234: 'Wyspy Cooka',
    235: 'Wyspy Dziewicze Stanów Zjednoczonych',
    236: 'Wyspy Heard i McDonald',
    237: 'Wyspy Kokosowe (Keelinga)',
    238: 'Wyspy Marshalla',
    239: 'Wyspy Owcze',
    240: 'Wyspy Salomona',
    241: 'Wyspy Turks i Caicos',
    242: 'Wyspy Świętego Tomasza i Książęca',
    243: 'Zambia',
    244: 'Zielony Przylądek',
    245: 'Zimbabwe',
    246: 'Zjednoczone Emiraty Arabskie'}


export { foreignCountries }
