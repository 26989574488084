import React, { Component } from 'react';

import { getLastTemporaryIdFromList } from '../../../helpers';
import { ButtonStyled, GridCustom, Text } from '../../common';
import { FormMiejsceRealizacji } from './FormMiejsceRealizacji';
import { MiejsceRealizacji } from './MiejsceRealizacji';


class MiejscaRealizacji extends Component{

    constructor(props) {
        super(props);
        this.state = {
            newMiejsceRealizacjiOn: false,
        };

        this.handleSaveMiejsceRealizacji = this.handleSaveMiejsceRealizacji.bind(this);
    }

    render() {
        const miejscaRealizacji = this.props.miejscaRealizacji;
        return (
            <>
                <Text info className="mr-counter">Liczba miejsc realizacji: {miejscaRealizacji.length}</Text>
                <ul>
                    {miejscaRealizacji.map((mr) =>
                        <MiejsceRealizacji
                            key={mr.temporaryId}
                            miejsceRealizacji={mr}
                            removeMiejsceRealizacji={this.props.removeMiejsceRealizacji} />
                    )}
                </ul>
                {this.renderNewMiejsceRealizacjiForm()}
            </>
        )
    }

    renderNewMiejsceRealizacjiForm() {
        if (this.state.newMiejsceRealizacjiOn) {
            // temporaryId is computed based on temporaryIds of all of miejscaRealizacji
            // in ogloszenie and all not saved yet miejscaRealizacji in the przedmiotZamowienia
            const temporaryId = Math.max(this.props.lastTemporaryId, getLastTemporaryIdFromList(this.props.miejscaRealizacji)) + 1;
            return <FormMiejsceRealizacji
                        temporaryId={temporaryId}
                        cancelNewMiejsceRealizacji={() => this.setState({newMiejsceRealizacjiOn: false})}
                        saveMiejsceRealizacji={this.handleSaveMiejsceRealizacji}
                        computeProgress={this.props.computeProgress} />
        } else {
            if (this.props.miejscaRealizacji.length === 10) {
                return null
            }
            return (
                <GridCustom flexEnd>
                    <ButtonStyled add id="dodaj_miejsceRealizacji_button_id" onClick={() => this.setState({newMiejsceRealizacjiOn: true})}>
                        Dodaj miejsce realizacji<span className="sr-only"> {this.props.errorsKodyMiejscaRealizacji}</span>
                    </ButtonStyled>
                </GridCustom>
            )
        }
    }

    handleSaveMiejsceRealizacji(data) {
        this.props.saveMiejsceRealizacji(data).then(
            () => this.setState({newMiejsceRealizacjiOn: false}));
    }
}


export { MiejscaRealizacji };
