import React, { Component } from 'react';

import { fetchApi } from '../../../fetch';
import { getSerializedObject } from '../../../serializers';
import { customSelectStyles } from '../../vars/vars';
import { ButtonStyled, FieldWithLabel, GridCustom, Select, Text } from '../../common';


class FormKodCPV extends Component{

    constructor(props) {
        super(props);
        const initial = props.initial ? this.setInitialOptions() : (props.isMulti ? [] : null);
        this.state = {
            selectedKod: initial,
            selectedKodError: '',
            selectMenuIsOpen: false,
            inputOptions: initial,
            moreOptionsAvailable: false,
            fetchError: '',
        };

        this.requestId = 0;

        this.xhrFetch = null;
        this.xhrTimeout = null;

        this.handleChangeInputKodCPV = this.handleChangeInputKodCPV.bind(this);
        this.handleFetchSuccess = this.handleFetchSuccess.bind(this);
        this.handleFetchError = this.handleFetchError.bind(this);
        this.handleFetchIncorrectStatus = this.handleFetchIncorrectStatus.bind(this);
        this.handleBlurKodCPV = this.handleBlurKodCPV.bind(this);
        this.handleChangeKodCPV = this.handleChangeKodCPV.bind(this);
        this.handleAddKodCPV = this.handleAddKodCPV.bind(this);
    }

    // basic functions

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }

        // clear timeouts if exist
        if (this.xhrTimeout !== null) {
            clearTimeout(this.xhrTimeout);
        }
    }

    // handlers

    handleChangeInputKodCPV(inputValue) {
        if (this.xhrTimeout !== null) {
            clearTimeout(this.xhrTimeout);
            this.xhrTimeout = null;
        }

        if (inputValue.length < 5 && (inputValue.length < 3 || !/^[A-Za-z ąĄćĆęĘłŁńŃóÓśŚźŹżŹ]{3,4}$/.test(inputValue))) {
            this.setState({
                selectMenuIsOpen: false,
                inputOptions: [],
                moreOptionsAvailable: false,
                fetchError: ''
            });
        } else {
            this.requestId++;
            this.xhrTimeout = setTimeout(() => {
                this.setState(
                    {inputOptions: [], moreOptionsAvailable: false, fetchError: ''},
                    () => {
                        // get data from server
                        this.xhrFetch = fetchApi(
                            '/api/dictionaries/cpv',
                            'GET',
                            {},
                            {search_key: inputValue, request_id: this.requestId},
                            this.handleFetchSuccess,
                            this.handleFetchError,
                            this.handleFetchIncorrectStatus,
                        );
                    }
                );
            }, 500);
        }
    }

    handleFetchSuccess(data) {
        this.xhrFetch = null;

        if (parseInt(data.request_id) < this.requestId) {
            // do nothing, there is another fetch on the fly
            return
        }
        let inputOptions = getSerializedObject(data).kodyCPV || [];
        if (inputOptions.length && this.props.usedIds) {
            inputOptions = inputOptions.filter(
                option => !this.props.usedIds.includes(option.id));
        }
        this.setState({
            selectMenuIsOpen: true,
            inputOptions: inputOptions.map(
                option => ({
                    value: option.id,
                    data: {id: String(option.id), kod: option.kod, nazwa: option.nazwa},
                    label: `${option.kod} ${option.nazwa}`
                })
            ),
            moreOptionsAvailable: data.meta.last_page > 1,
        });
    }

    handleFetchError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            fetchError: `Nie udało się pobrać kodów CPV. ${data.message}`,
        });
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            fetchError: `Nie udało się pobrać kodów CPV. Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    handleBlurKodCPV(ev) {
        this.setState({selectMenuIsOpen: false});
    }

    handleChangeKodCPV(selectedOption) {
        let selectedKod;
        if (this.props.isMulti) {
            selectedKod = (selectedOption || []).map(o => o.data);
        } else {
            selectedKod = selectedOption.data;
        }
        this.setState(
            {
                selectMenuIsOpen: false,
                selectedKod,
            },
            () => {
                if (!this.props.withButtons) {
                    this.props.addKodCPV(this.state.selectedKod);
                }
            }
        );
    }

    handleAddKodCPV(event) {
        if (!this.validate()) { return }
        this.props.addKodCPV(this.state.selectedKod);
    }

    // helpers

    setInitialOptions() {
        const initial = this.props.initial;
        if (this.props.isMulti) {
            return initial.map(i => {
                return {
                    value: parseInt(i.id),
                    data: i,
                    label: `${i.kod} ${i.nazwa}`}
            })
        } else {
            return {
                value: parseInt(initial.id),
                data: initial,
                label: `${initial.kod} ${initial.nazwa}`
            }
        }
    }

    validate() {
        // clean errors
        this.setState({selectedKodError: ''});
        if (this.state.selectedKod === null) {
            this.setState({selectedKodError: 'To pole jest wymagane.'});
            return false
        }
        return true
    }

    // rendering

    render() {
        const {
            fetchError,
            inputOptions,
            moreOptionsAvailable,
            selectedKod,
            selectedKodError,
            selectMenuIsOpen,
        } = this.state;
        const isSelectInvalid = selectedKodError.length > 0;
        const moreOptionsAvailableText = 'Istnieje więcej pasujących wyników. Jeśli na liście nie ma szukanego kodu, wpisz dodatkowe znaki.';
        return (
            <>
                <FieldWithLabel label="Kod CPV" tag="label" selectRef={React.createRef()}>
                    {moreOptionsAvailable && <Text info>{moreOptionsAvailableText}</Text>}
                    <Select
                        screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                        defaultValue={selectedKod}
                        isMulti={this.props.isMulti || false}
                        menuIsOpen={selectMenuIsOpen}
                        options={inputOptions}
                        placeholder='Wpisz co najmniej 5 cyfr lub 3 litery...'
                        styles={customSelectStyles}
                        className={isSelectInvalid ? 'select-custom select-custom--invalid' : 'select-custom'}
                        onBlur={this.handleBlurKodCPV}
                        onChange={this.handleChangeKodCPV}
                        onInputChange={this.handleChangeInputKodCPV}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        noOptionsMessage={() => 'Brak wybranej opcji'}
                        aria-label={`Kody CPV, wpisz co najmniej 5 cyfr lub 3 litery ${moreOptionsAvailable ? moreOptionsAvailableText : ''} ${isSelectInvalid ? selectedKodError : ''} ${fetchError.length > 0 ? fetchError : ''}`}
                    />
                    {isSelectInvalid && <Text error id="selected_error">{selectedKodError}</Text>}
                    {fetchError.length > 0 && <Text error id="selected_fetch_error">{fetchError}</Text>}
                </FieldWithLabel>
                {this.props.withButtons && this.renderButtons()}
            </>
        )
    }

    renderButtons() {
        const addButtonDisabled = this.state.selectedKod === null;
        return (
            <GridCustom flexEnd>
                <ButtonStyled
                    cancel
                    onClick={this.props.onCancelKodCPV}
                >Anuluj<span className="sr-only"> dodawanie kodu CPV</span></ButtonStyled>
                <ButtonStyled
                    add={!addButtonDisabled}
                    disabled={addButtonDisabled}
                    id="potwierdz_dodaj_kodCPV_button_id"
                    onClick={this.handleAddKodCPV}
                >Dodaj<span className="sr-only"> kod CPV. {addButtonDisabled && 'Guzik nieaktywny, musisz wybrać kod, aby go dodać.'}</span></ButtonStyled>
            </GridCustom>
        )
    }
}


export { FormKodCPV };
