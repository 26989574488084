import React, { Component } from 'react';

import { fetchApi } from '../../../fetch';
import {
    getSerializedErrorsFromServer,
    getSerializedObject,
    translateErrorMessage,
} from '../../../serializers';

import {
    ButtonStyled,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Text,
    TextFieldCustom,
} from '../../common';


class FormPytanie extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tresc: '',
            savingOn: false,
            fetchError: '',
            errors: {},
        }
    }

    handleSave = () => {
        this.setState(
            {savingOn: true},
            () => {
                // save object on server
                this.xhrSave = fetchApi(
                    '/api/question',
                    'POST',
                    {},
                    getSerializedObject(
                        {
                            tresc: this.state.tresc,
                            ogloszenie: this.props.advertisementId,
                        },
                        {toServer: true}
                    ),
                    this.handleFetchSaveSuccess,
                    this.handleFetchSaveError,
                    this.handleFetchSaveIncorrectStatus,
                    this.handleShowSaveErrors,
                );
            }
        )
    }

    handleFetchSaveSuccess = (data) => {
        this.xhrSave = null;
        this.props.onAddQuestion(getSerializedObject(data).pytanie);
    }

    handleFetchSaveError = (data) => {
        this.xhrSave = null;

        this.setState({
            fetchError: translateErrorMessage(data.message),
            savingOn: false,
        });
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.xhrSave = null;

        this.setState({
            fetchError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            savingOn: false,
        });
    }

    handleShowSaveErrors = (errors) => {
        this.xhrSave = null;

        this.setState({
            errors: getSerializedErrorsFromServer(errors),
            savingOn: false,
        });
    }

    render() {
        const { onCloseForm } = this.props;
        const { errors, fetchError, savingOn, tresc } = this.state;
        const isTrescInvalid = (errors.tresc || []).length > 0;

        return (
            <DialogCustom
                dialogTitle="Zadaj pytanie"
                onClose={onCloseForm}
            >
                <>
                    <FieldWithLabel label="Treść pytania" tag="label" labelFor="tresc_id">
                        <TextFieldCustom
                            aria-describedby={isTrescInvalid ? 'tresc_error' : null}
                            aria-label={`Treść pytania, maksymalna liczba znaków: 2000. Wpisano ${tresc.length} znaków.`}
                            aria-valuemax={2000}
                            characterCount
                            clearFieldContext="treść pytania"
                            disabled={savingOn}
                            fullwidth
                            id="tresc_id"
                            invalid={isTrescInvalid}
                            onClear={(ev) => this.setState({tresc: ''})}
                            onChange={(ev) => this.setState({tresc: ev.target.value})}
                            maxLength={2000}
                            value={tresc}
                            textarea />
                        {isTrescInvalid && <ul id="tresc_error" role="alert">{errors.tresc.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                    {fetchError.length > 0 && <Text error role="alert">{fetchError}</Text>}
                    <GridCustom fullwidth flexTop>
                        <ButtonStyled
                            cancel
                            disabled={savingOn}
                            onClick={onCloseForm}>
                            Anuluj
                        </ButtonStyled>
                        <ButtonStyled
                            className="btn btn--main"
                            disabled={savingOn}
                            icon={savingOn ? 'send' : 'done'}
                            onClick={this.handleSave}
                            primary >
                            {savingOn ? 'Wysyłam...' : 'Zadaj pytanie'}
                        </ButtonStyled>
                    </GridCustom>
                </>
            </DialogCustom>
        )
    }
}

export { FormPytanie };
