import React from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { Steps, Text } from '../../common';
import { Zalaczniki } from '../common';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { AlreadyPublishedInfo } from './AlreadyPublishedInfo';
import { BaseStepComponent } from './BaseStepComponent';


const MAX_ATTACHMENTS_NUMBER = 20;


class StepZalaczniki extends BaseStepComponent {
    stepName = 'zalaczniki';

    // helpers
    distinguishAttachments() {
        let oldAttachments = [];
        let currentAttachments = [];
        const ogloszenie = this.context.ogloszenie;
        for (let attachment of ogloszenie.zalaczniki) {
            if (!attachment.origin) { continue }
            if ((ogloszenie.status.label === 'DRAFT' && attachment.origin.id === this.context.ogloszenie.id) || (ogloszenie.status.label !== 'DRAFT' && attachment.origin.id === null)) {
                currentAttachments.push(attachment);
            } else {
                oldAttachments.push(attachment);
            }
        }
        return [oldAttachments, currentAttachments]
    }

    // rendering

    render() {
        const { isAlreadyPublished, isPreview } = this.props;
        const formLocked = isPreview || this.context.validationOn || this.context.savingOn;
        const [oldAttachments, currentAttachments] = isPreview ? [[], []] : this.distinguishAttachments();
        const oldAttachmentsLength = oldAttachments.length;
        return (
            <>
                <PomocEkranowa ekran="form_announcement_attachments" content={this.props.pomocEkranowaContent} />
                <Text tag="h2" sectionHeader>Załączniki</Text>
                <Text info className="text--counter">Liczba wyników: {oldAttachments.length + currentAttachments.length}</Text>
                <Text info>Możesz załączyć maksymalnie {MAX_ATTACHMENTS_NUMBER} plików.</Text>
                {this.renderCurrentAttachments(
                    currentAttachments, oldAttachmentsLength, isAlreadyPublished, formLocked)}
                {this.renderOldAttachments(oldAttachments, oldAttachmentsLength, isAlreadyPublished)}
                <Steps
                    disabled={formLocked}
                    elementToFocusRef={this.props.elementToFocusRef}
                    hasPrev
                    prevLabel="Pozostałe"
                    onPrevClick={() => this.props.onChangeCurrentMenuLink('pozostale')}
                />
            </>
        )
    }

    renderCurrentAttachments(attachments, oldAttachmentsLength, isAlreadyPublished, formLocked) {
        return (
            <Zalaczniki
                isAlreadyPublished={false}
                contextForSaving='advertisement'
                errors={this.context.formErrors.zalaczniki}
                formLocked={formLocked}
                header={isAlreadyPublished ? 'Załączniki dodane w bieżącej wersji' : ''}
                maxLength={MAX_ATTACHMENTS_NUMBER}
                withoutHeader={!(isAlreadyPublished && oldAttachmentsLength)}
                zalaczniki={attachments}
                zalacznikiLength={attachments.length + oldAttachmentsLength}
                addZalacznik={this.context.addZalacznik}
                removeZalacznik={this.context.removeZalacznik}
                saveZalacznikAttributes={this.context.setZalacznikAttributes}>
                {oldAttachmentsLength > 0 && <Text info className="text--counter">Liczba wyników: {attachments.length}</Text>}
            </Zalaczniki>
        )
    }

    renderOldAttachments(attachments, attachmentsLength, isAlreadyPublished) {
        if (!attachmentsLength) { return null }
        return (
            <Zalaczniki
                isAlreadyPublished={isAlreadyPublished}
                contextForSaving='advertisement'
                errors={this.context.formErrors.zalaczniki}
                header={'Załączniki dodane w poprzednich wersjach'}
                formLocked={true}
                maxLength={MAX_ATTACHMENTS_NUMBER}
                zalaczniki={attachments}
                addZalacznik={this.context.addZalacznik}
                removeZalacznik={this.context.removeZalacznik}
                saveZalacznikAttributes={this.context.setZalacznikAttributes}>
                <Text info className="text--counter">Liczba wyników: {attachments.length}</Text>
                <AlreadyPublishedInfo info='To ogłoszenie zostało już opublikowane i nie ma możliwości edycji załączników dodanych w poprzednich wersjach.' />
            </Zalaczniki>
        )
    }
}


StepZalaczniki.contextType = AdvertisementContext;


export { StepZalaczniki }
