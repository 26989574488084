import React from 'react';
import { Link } from 'react-router-dom';

import { getOffersFiltersCount } from '../../../filters';
import { isAdvertisementLocked } from '../../../helpers';
import { Box, Breadcrumps, ButtonStyled, FieldWithLabel, GridCustom, Text } from '../../common';
import { PaginatedBaseComponent } from '../common';
import { FiltryOfert, OFERTA_STATUS_CHOICES, OGLOSZENIE_STATUS_CHOICES } from './FiltryOfert';
import { OfertaPreview } from './OfertaPreview';
import { BadgeAnchor, Badge } from '@rmwc/badge';
import '@rmwc/badge/badge.css';

class Oferty extends PaginatedBaseComponent {

    // handlers

    handleClosePreview = () => {
        this.setState({
            previewOn: false,
            previewedOfferAnnouncementId: null,
        });
    }

    // helpers

    preState(props) {
        super.preState(props);
        this.objectsName = 'pakietyOfert';
        this.sortingValues = [
            ['announcementTitle_asc', 'Po tytule ogłoszenia rosnąco'],
            ['announcementTitle_desc', 'Po tytule ogłoszenia malejąco'],
            ['announcementNumber_asc', 'Po numerze ogłoszenia rosnąco'],
            ['announcementNumber_desc', 'Po numerze ogłoszenia malejąco'],
            ['submissionDeadline_asc', 'Po terminie składania ofert rosnąco'],
            ['submissionDeadline_desc', 'Po terminie składania ofert malejąco'],
            ['announcementStatus_asc', 'Po statusie ogłoszenia rosnąco'],
            ['announcementStatus_desc', 'Po statusie ogłoszenia malejąco'],
            ['offerStatus_asc', 'Po statusie oferty rosnąco'],
            ['offerStatus_desc', 'Po statusie oferty malejąco'],
            ['announcementPublicationDate_asc', 'Po dacie publikacji ogłoszenia rosnąco'],
            ['announcementPublicationDate_desc', 'Po dacie publikacji ogłoszenia malejąco'],
        ];
        this.sortingLabel = 'Sortuj oferty';
        this.filteringLabel = 'Filtruj listę';
        this.filtersComponentClass = FiltryOfert;

        this.getFiltersCount = getOffersFiltersCount;
    }

    getAdditionalState(props) {
        let selectedSort;
        for (let s of this.sortingValues) {
            if (s[0] === 'submissionDeadline_desc') {
                selectedSort = s;
                break
            }
        }
        return {
            ...super.getAdditionalState(props),
            selectedSort: {value: selectedSort[0], label: selectedSort[1]},
            objects: {},
            filters: this.getEmptyFilters(),
            previewOn: false,
            previewedOfferAnnouncementId: null,
        }
    }

    getFetchUrl() {
        return '/api/offerset/my'
    }

    getFetchData() {
        let queryDict = super.getFetchData();

        // set filters values
        const emptyFilters = this.getEmptyFilters();
        for (let [name, value] of Object.entries(this.state.filters)) {
            if (['announcementStatus', 'offerStatus'].includes(name)) {
                if (value.value !== 'all') {
                    queryDict[name] = value.value.toUpperCase();
                }
            } else if (value !== emptyFilters[name]) {
                queryDict[name] = value.trim();
            }
        }

        return queryDict
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się pobrać ofert. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania ofert wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    getEmptyFilters() {
        return {
            title: '',
            submissionDeadline: '',
            announcementPublicationDate: '',
            announcementNumber: '',
            announcementStatus: {value: OGLOSZENIE_STATUS_CHOICES[0][0], label: OGLOSZENIE_STATUS_CHOICES[0][1]},
            offerStatus: {value: OFERTA_STATUS_CHOICES[0][0], label: OFERTA_STATUS_CHOICES[0][1]},
        }
    }

    // rendering

    renderHeader() {
        document.title = "Baza Konkurencyjności - oferty";
        return (
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__current">Moje oferty</li>
            </Breadcrumps>
        )
    }

    renderFiltersCount() {
        return this.getFiltersCount(this.state.filters, this.getEmptyFilters())
    }

    renderEmptyObjects() {
        return <Text info>Brak ofert spełniających kryteria wyszukiwania.</Text>
    }

    renderList() {
        const total = this.state.total;
        let ofertaLabel = '';
        if (total === 1) {
            ofertaLabel = 'ofertę';
        } else {
            const totalString = total.toString();
            if (['2', '3', '4'].includes(totalString.slice(totalString.length - 1))) {
                ofertaLabel = 'oferty';
            } else {
                ofertaLabel = 'ofert';
            }
        }
        return (
            <>
                <Text accentHeader tag="h2" className="offer__count">Znaleziono {total < this.paginateBy ? '' : 'około '}{total} {ofertaLabel}</Text>
                <GridCustom tag="ul">
                    {this.state.objects.map(pakietOfert => this.renderPakietOfert(pakietOfert))}
                </GridCustom>
            </>
        )
    }

    renderPakietOfert(pakietOfert) {
        const { dataOpublikowania, numer, status } = pakietOfert.ogloszenie;
        // only published and closed statuses of announcement can show more content
        const zamowieniaNodes = (pakietOfert.oferty || []).map(o => o.zamowienieNode);
        const wersja = pakietOfert.ogloszenie.wersja || {};
        const ogloszenieId = pakietOfert.ogloszenie.id;
        const offerSubtitle = (
            <><span className="sr-only">Oferta złożona do ogłoszenia: </span>{numer}</>
        )
        const isLocked = isAdvertisementLocked(status);

        return (
            <Box
                className="offer__box"
                flexiblewidth
                headerTag="h3"
                key={pakietOfert.id}
                subtitle={offerSubtitle}
                tag="li"
                titleTwoRows={isLocked ? '' : `Data opublikowania: ${dataOpublikowania}`}
            >
                <GridCustom fullwidth>
                    <FieldWithLabel label="Status ogłoszenia">
                        <Text>{status.nazwa}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Termin składania ofert">
                        {isLocked ? <Text className="offer__box-hidden-text"></Text> : <Text>{wersja.terminOfert}</Text>}
                    </FieldWithLabel>
                </GridCustom>
                <GridCustom fullwidth>
                    <FieldWithLabel label="Tytuł ogłoszenia">
                        {isLocked ? <Text className="offer__box-hidden-text"></Text> : <Text className="long-text">{wersja.tytul}</Text>}
                    </FieldWithLabel>
                </GridCustom>
                <GridCustom fullwidth>
                    <FieldWithLabel label="Status oferty">
                        <Text>{pakietOfert.status.nazwa}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Części, których dotyczy oferta">
                        {isLocked ? <Text className="offer__box-hidden-text"></Text> :
                            (wersja.zamowienia || []).length < 2 ? 'Całe ogłoszenie' :
                                zamowieniaNodes.map(z => (
                                    <Text key={z.id}>- {z.ostatniaWersja.tytul || ''}</Text>)
                            )
                        }
                    </FieldWithLabel>
                </GridCustom>
                <div className="btn-container">
                    <ButtonStyled
                        disabled={isLocked}
                        primary
                        onClick={(e) => {
                            this.setState({previewOn: true, previewedOfferAnnouncementId: ogloszenieId});
                            e.currentTarget.blur();
                        }}>
                        Podgląd oferty
                    </ButtonStyled>
                    <BadgeAnchor style={{width: '100%'}}>
                        <ButtonStyled
                            disabled={isLocked}
                            primary
                            tag={Link}
                            to={`/ogloszenia/${ogloszenieId}`}>
                            Przejdź do szczegółów ogłoszenia
                        </ButtonStyled>
                        {pakietOfert.badge_count > 0 && <Badge label={pakietOfert.badge_count} />}
                    </BadgeAnchor>
                </div>
            </Box>
        )
    }

    renderAdditionalAfterBaseContent() {
        return this.state.previewOn ? this.renderPreview() : null
    }

    renderPreview() {
        return <OfertaPreview
            ogloszenieId={this.state.previewedOfferAnnouncementId}
            onClosePreview={this.handleClosePreview} />
    }
}


export { Oferty };
