// Don't forget to add menu button trigger in component where <MenuDrawer /> is placed
// e.g. <IconButton onClick={() => {this.setState(prevState => ({openMenu: !prevState.openMenu}))}} icon="menu" />

import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Drawer, DrawerContent } from '@rmwc/drawer';
import { ListDivider, ListItem, ListItemGraphic, ListItemText } from '@rmwc/list';
import '@material/drawer/dist/mdc.drawer.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/list/dist/mdc.list.css';

import { MenuContext } from '../../context/menuContext';
import { UserContext } from '../../context/user-context';

import ueLogo from '../../static/img/fe_ue_logos.png'
import { Badge } from '@rmwc/badge';
import '@rmwc/badge/badge.css';
import { fetchApi } from '../../fetch';

const MenuDrawer = (props) => {
    const {
        currentMenuLink,
        mobile,
        openMenu,
        onMenuClose,
    } = props;

    const { updateMenuKey, refreshBadgesCount } = useContext(MenuContext);

    const location = useLocation();

    const [badges, setBadges] = useState();

    const { user } = useContext(UserContext);

    useEffect(() => {
        if(user?.id !== null) {
            fetchApi(
                '/api/badge/menu',
                'GET',
                {},
                {},
                (response) => {
                    setBadges(response);
                },
                () => {},
                () => {})
        }

    }, [user, refreshBadgesCount]);

    function renderUeLogo() {
        if (location.pathname != '/') {
            return (
                <footer style={{textAlign: "center"}}>
                    <img src={ueLogo} alt='Zestawienie znaków: Fundusze Europejskie, Barwy Rzeczypospolitej Polskiej, Unia Europejska' />
                </footer>
            )
        }
    }

    const renderBadge = (linkName) => {

        const map = {
            advertisements: badges?.my_advertisement_badge_count,
            offers: badges?.my_offer_set_badge_count,
            newsletter: badges?.favorite_advertisement_badge_count
        };

        const value = map[linkName];

        return value > 0 ? <Badge align="inline" label={value} size="small"/> : <></>
    }

    return (
        <Drawer modal={mobile} dismissible={!mobile} open={!mobile ? true : openMenu} onClose={onMenuClose}>
                <DrawerContent style={{display: "flex", flexDirection: "column"}}>
                    <nav style={{flexGrow: "1"}}>
                        <ul>
                            {props.children.map((link) =>
                                <li
                                    key={link.id}
                                    onClick={updateMenuKey}
                                >
                                    <ListItem tag={Link} to={link.linkTo} className="menu-item">
                                        <ListItemGraphic
                                            aria-hidden="true"
                                            icon={link.icon}
                                            theme={(currentMenuLink && link.id === currentMenuLink) ? 'primary' : 'textSecondaryOnLight'} />
                                            <ListItemText theme={(currentMenuLink && link.id === currentMenuLink) ? 'primary' : 'textSecondaryOnLight'}>
                                                {link.label}
                                            </ListItemText>
                                            {renderBadge(link.name)}
                                    </ListItem>
                                    <ListDivider />
                                </li>
                            )}
                        </ul>
                    </nav>
                    {renderUeLogo()}
                </DrawerContent>
        </Drawer>
    )
}


export { MenuDrawer };
