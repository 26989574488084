import React from 'react';

import { Message } from '../Message';


export const NotPermittedPage = ({ message }) => {
    return (
        <Message
            messageIcon="report_problem"
            messageTitle="Wystąpił błąd" >
            {message || 'Nie masz uprawnień do przeglądania tej strony.'}
        </Message>
    );
}
