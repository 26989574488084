import React, { Component } from 'react';

import { roundStringTo } from '../../helpers';
import { FieldWithLabel } from './FieldWithLabel';
import { Text } from './Text';
import { TextFieldCustom } from './TextFieldCustom';


class NumberTextField extends Component {

    constructor(props) {
        super(props);
        this.state = {value: props.value || ''};
    }

    // handlers

    handleBlur = (ev) => {
        let value = roundStringTo(ev.target.value);
        this.setState(
            {value},
            () => this.props.onChange(value)
        );
    }

    handleChange = (ev) => {
        let value = ev.target.value;
        this.setState(
            prevState => {
                if (value.indexOf(',') === -1 && value.length > 24) {
                    value = prevState.szacunkowaWartosc;
                }
                return {value: value}
            },
            () => this.props.onChange(value)
        );
    }

    handleKeyPress = (ev) => {
        const value = this.state.value;
        const valueLenth = value.length;
        const key = ev.key;
        const keyIndex = ev.target.selectionStart;
        if (!/[\d,]/.test(key)) {
            ev.preventDefault();
            return
        }
        if (key === ',') {
            if (value.indexOf(',') > -1 || valueLenth === 0) {
                ev.preventDefault();
                return
            }
            if (keyIndex < valueLenth - 2) {
                ev.preventDefault();
                return
            }
        } else {
            // it's digit

            const comaIndex = value.indexOf(',');

            if (comaIndex === -1 && valueLenth === 24) {
                ev.preventDefault();
                return
            }

            if (comaIndex === 24 && keyIndex <= 24) {
                ev.preventDefault();
                return
            }

            if (comaIndex > -1 && valueLenth === comaIndex + 3 && keyIndex > comaIndex) {
                ev.preventDefault();
            }
        }
    }
    handlePaste = (ev) => {
        // stop data actually being pasted into input
        ev.preventDefault();
        // set pasted data via clipboard API
        const clipboardData = ev.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (/^\d{1,24}(,\d{2})?$/.test(pastedData)) {
            this.setState({value: pastedData});
        }
    }

    // rendering

    render() {
        const { errors, label } = this.props;

        const isFieldInvalid = (errors || []).length > 0
        const fieldId = `${this.props.attrName}_id_${this.props.parentTemporaryId}`;

        return (
            <FieldWithLabel label={label} tag="label" labelFor={fieldId}>
                <TextFieldCustom
                    aria-describedby={`text_field_custom_id${isFieldInvalid ? ' text_field_custom_error' : ''}`}
                    aria-valuemax={27}
                    autoFocus={this.props.autoFocus || false}
                    clearFieldContext={label || null}
                    characterCount
                    disabled={this.props.formLocked}
                    fullwidth={this.props.fullwidth}
                    id={fieldId}
                    invalid={isFieldInvalid}
                    maxLength={27}
                    value={this.state.value}
                    onBlur={this.handleBlur}
                    onFocus={this.props.onFocus}
                    onChange={this.handleChange}
                    onClear={(ev) => this.setState({value: ''}, () => {
                        this.props.onChange('')
                    })}
                    onKeyPress={this.handleKeyPress}
                    onPaste={this.handlePaste}
                />
                {isFieldInvalid && <ul id="text_field_custom_error">{errors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                <Text id="text_field_custom_id" info>Wpisz wartość zgodnie ze wzorem: 1000000,00</Text>
            </FieldWithLabel>
        )
    }
}

export { NumberTextField };
