import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';

import { EKRANY_DICT } from '../../../const';
import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { ButtonStyled, DialogCustom, GridCustom, Text } from '../../common';
import { BaseComponent } from '../common';


class PomocUsun extends BaseComponent {

    // basic functions

    componentDidMount() {
        if (this.props.typOgolna) {
            this.fetchInitialData();
        } else {
            this.setState({initializingOn: false});
        }
    }

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        this.setState({
            initializingOn: false,
            ...getSerializedObject(data).pomoc
        });
    }

    handleRemovePomoc() {
        this.setState({removingOn: true});
        this.xhrFetch = fetchApi(
            API_URLS.helpAdminDetails.getUrl(this.props.pomoc.id),
            'DELETE',
            {},
            {},
            this.handleFetchRemoveSuccess,
            this.handleFetchRemoveError,
            this.handleFetchRemoveIncorrectStatus,
        );
    }

    handleFetchRemoveSuccess(data) {
        this.xhrFetchSave = null;
        this.props.removePomoc(this.props.pomoc.id);
    }

    handleFetchRemoveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            removingOn: false,
            fetchRemoveError: `Nie udało się usunąć pomocy. ${data.message}`,
        });
    }

    handleFetchRemoveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        let message = '';
        if (status === 403) {
            message = 'Nie masz uprawnień do usuwania pomocy.';
            // update permission value in context
            this.context.removePermission('help');
        }
        this.setState({
            removingOn: false,
            fetchRemoveError: message || `Podczas usuwania pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    // helpers

    getAdditionalState(props) {
        return {
            removingOn: false,
            fetchRemoveError: '',
            root: false,
            dzieci: [],
            powiazaneDlaObecnego: [],
        }
    }

    setAdditionalHandlers() {
        this.handleRemovePomoc = this.handleRemovePomoc.bind(this);
        this.handleFetchRemoveSuccess = this.handleFetchRemoveSuccess.bind(this);
        this.handleFetchRemoveError = this.handleFetchRemoveError.bind(this);
        this.handleFetchRemoveIncorrectStatus = this.handleFetchRemoveIncorrectStatus.bind(this);
    }

    getFetchUrl() {
        return `/api/help/${this.props.pomoc.id}/related`
    }

    getFetchError(message) {
        return `Nie udało się pobrać danych pomocy. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania danych pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // rendering

    render() {
        let content = null,
            children = null;
        if (this.state.initializingOn) {
            content = <Text info tabIndex="1">Trwa inicjalizacja danych...</Text>;
        } else {
            const pomoc = this.props.pomoc;
            if (this.props.typOgolna) {
                if (this.state.root) {
                    children = <Text error role="status">Nie można usunąć pomocy "{pomoc.naglowek}", ponieważ jest to pierwsza pomoc w systemie.</Text>;
                    content = this.renderRemovingImpossible(children);
                } else if (this.state.dzieci.length || this.state.powiazaneDlaObecnego.length) {
                    children = this.renderRelatives();
                    content = this.renderRemovingImpossible(children);
                } else {
                    content = this.renderConfirmation();
                }
            } else {
                if (!pomoc.ekran) {
                    content = this.renderConfirmation();
                } else {
                    children = <Text error role="status">Pomocy "{pomoc.naglowek}" nie można usunąć, ponieważ jest przypisana do ekranu "{!pomoc.ekran ? '---' : EKRANY_DICT[pomoc.ekran].label}". Odepnij pomoc od ekranu, a następnie spróbuj ponownie.</Text>;
                    content = this.renderRemovingImpossible(children);
                }
            }
        }

        return (
            <DialogCustom
                onClose={this.props.closePomocForRemoving}
            >
                {content}
            </DialogCustom>
        )
    }

    renderRemovingImpossible(children) {
        return (
            <>
                <h2 id="dialogLabel">{children}</h2>
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled onClick={this.props.closePomocForRemoving} primary>OK</ButtonStyled>
                </GridCustom>
            </>
        )
    }

    renderRelatives() {
        return (
            <>
                <Text error role="status">Nie można usunąć pomocy "{this.props.pomoc.naglowek}", ponieważ jest ona powiązana z innymi obiektami:</Text>
                {this.state.dzieci.length > 0 && this.renderList('Pomoce, dla których jest rodziałem nadrzędnym:', this.state.dzieci)}
                {this.state.powiazaneDlaObecnego.length > 0 && this.renderList('Pomoce, z którymi jest powiązana:', this.state.powiazaneDlaObecnego)}
            </>
        )
    }

    renderList(header, objects) {
        return (
            <>
                <Text>{header}</Text>
                <ul>
                    {objects.map(o => (
                        <li key={o.id}><Link to={`/zarzadzanie-trescia/pomoc/${o.id}-${o.slug}`} className="link-text"><Icon icon="arrow_right" aria-hidden="true" className="help__help-item-icon" />{o.naglowek}</Link></li>
                    ))}
                </ul>
            </>
        )
    }

    renderConfirmation() {
        const {
            fetchRemoveError,
            removingOn,
        } = this.state;
        return (
            <>
                <h2 id="dialogLabel">Czy na pewno chcesz usunąć pomoc "{this.props.pomoc.naglowek}"?</h2>
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled disabled={removingOn} onClick={this.props.closePomocForRemoving} cancel>Nie, wróć do listy</ButtonStyled>
                    <ButtonStyled disabled={removingOn} onClick={this.handleRemovePomoc} remove>Usuń pomoc</ButtonStyled>
                </GridCustom>
                {fetchRemoveError.length > 0 && <Text error role="alert">{fetchRemoveError}</Text>}
            </>
        )
    }
}

PomocUsun.contextType = UserContext;

export { PomocUsun };
