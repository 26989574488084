import React from 'react';
import { setFocusOnElement } from '../../helpers';
import { NextStep } from '.';


const Steps = (props) => {
    const className = "next-step-container" + (!props.hasPrev ? ' next-step-container--one-item' : '');
    return (
        <section className={className}>
            {props.hasPrev && <NextStep
                disabled={props.disabled || false}
                onClick={() => {
                    setFocusOnElement(props.elementToFocusRef)
                    props.onPrevClick()
                }}
                stepTitle={props.prevLabel}
                prevStep
            />}
            {props.hasNext && <NextStep
                disabled={props.disabled || false}
                onClick={() => {
                    setFocusOnElement(props.elementToFocusRef)
                    props.onNextClick()
                }}
                stepTitle={props.nextLabel}
            />}
        </section>
    );
}

export { Steps };
