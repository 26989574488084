import React, { Component } from 'react';

import { fetchApi } from '../../../fetch';
import {
    getSerializedObject,
    getSerializedObjectErrorsFormServer,
    translateErrorMessage,
} from '../../../serializers';

import {
    ButtonStyled,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Text,
    TextFieldCustom,
} from '../../common';


class FormOdpowiedz extends Component {
    constructor(props) {
        super(props);
        const { pytanie } = this.props;
        this.state = {
            id: pytanie.odpowiedz.id || '',
            tresc: pytanie.odpowiedz.tresc || '',
            pytanieTresc: pytanie.tresc,
            savingOn: false,
            fetchError: '',
            errors: {},
            isNew: !pytanie.odpowiedz.id
        };
    }

    handleSaveDraft = () => this.handleSave();

    handleSavePublished = () => this.handleSave(true);

    handleSave = (published=false) => {
        this.setState(
            {savingOn: true},
            () => {
                // Save edited question and add/edit answer
                this.xhrSave = fetchApi(
                    `/api/question/${this.props.pytanie.id}${published ? '?mode=publish' : ''}`,
                    'PUT',
                    {},
                    getSerializedObject(this.getData(), { toServer: true }),
                    this.handleFetchSaveSuccess,
                    this.handleFetchSaveError,
                    this.handleFetchSaveIncorrectStatus,
                    this.handleShowSaveErrors,
                );
            }
        )
    }

    handleFetchSaveSuccess = (data) => {
        this.xhrSave = null;
        this.props.onUpdate(
            this.props.pytanie.id,
            getSerializedObject(data).pytanie
        );
    }

    handleFetchSaveError = (data) => {
        this.xhrSave = null;

        this.setState({
            fetchError: translateErrorMessage(data.message),
            savingOn: false,
        });
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.xhrSave = null;

        this.setState({
            fetchError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            savingOn: false,
        });
    }

    handleShowSaveErrors = (errors) => {
        this.xhrSave = null;

        this.setState({
            errors: getSerializedObjectErrorsFormServer(errors, this.getData()),
            savingOn: false,
        });
    }

    getData(){
        return {
            tresc: this.state.pytanieTresc,
            odpowiedz: {tresc: this.state.tresc},
        }
    }

    render() {
        const { onCloseForm } = this.props;
        const {
            dataOpublikowania,
            dataUtworzenia,
            numer
        } = this.props.pytanie;

        const {
            errors,
            fetchError,
            pytanieTresc,
            savingOn,
            tresc
        } = this.state;

        const isTrescInvalid = ((errors.odpowiedz || {}).tresc || []).length > 0;
        const isPytanieTrescInvalid = (errors.tresc || []).length > 0;
        const isFormInvalid = isTrescInvalid || isPytanieTrescInvalid;

        let questionLabel = `Pytanie nr ${numer}, data wysłania ${dataUtworzenia}`;

        if (dataOpublikowania) {
            questionLabel += `, data opublikowania ${dataOpublikowania}`
        }

        return (
            <DialogCustom
                dialogTitle="Odpowiedz na pytanie"
                onClose={onCloseForm}
            >
                <>
                    <Text className="question__label">{questionLabel}</Text>
                    <FieldWithLabel label="Treść pytania" tag="label" labelFor="trescPytania_id">
                        <TextFieldCustom
                            aria-describedby={isPytanieTrescInvalid ? 'trescPytania_error' : null}
                            aria-label={`Treść pytania, maksymalna liczba znaków: 2000. Wpisano ${pytanieTresc.length} znaków.`}
                            aria-valuemax={2000}
                            characterCount
                            clearFieldContext="treść pytania"
                            disabled={savingOn}
                            fullwidth
                            id="trescPytania_id"
                            invalid={isPytanieTrescInvalid}
                            maxLength={2000}
                            onClear={(ev) => this.setState({pytanieTresc: ''})}
                            onChange={(ev) => this.setState({pytanieTresc: ev.target.value})}
                            textarea
                            value={pytanieTresc} />
                        {isPytanieTrescInvalid && <ul id="trescPytania_error">{errors.tresc.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                    <FieldWithLabel label="Odpowiedź" tag="label" labelFor="trescOdpowiedzi_id">
                        <TextFieldCustom
                            aria-describedby={isTrescInvalid ? 'trescOdpowiedzi_error' : null}
                            aria-label={`Odpowiedź, maksymalna liczba znaków: 5000. Wpisano ${tresc.length} znaków.`}
                            aria-valuemax={5000}
                            characterCount
                            clearFieldContext="odpowiedź"
                            disabled={savingOn}
                            fullwidth
                            id="trescOdpowiedzi_id"
                            invalid={isTrescInvalid}
                            maxLength={5000}
                            onClear={(ev) => this.setState({tresc: ''})}
                            onChange={(ev) => this.setState({tresc: ev.target.value})}
                            textarea
                            value={tresc} />
                        {isTrescInvalid && <ul id="trescOdpowiedzi_error">{errors.odpowiedz.tresc.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                    {fetchError.length > 0 && <Text error role="alert">{fetchError}</Text>}
                    <GridCustom flexTop fullwidth>
                        <GridCustom>
                            <ButtonStyled
                                cancel
                                disabled={savingOn}
                                onClick={onCloseForm}>
                                Anuluj
                            </ButtonStyled>
                            {(this.props.pytanie.status.label !== 'PUBLISHED') && (
                                <ButtonStyled
                                    disabled={savingOn}
                                    lite
                                    onClick={this.handleSaveDraft} >
                                    Zapisz wersję roboczą
                                </ButtonStyled>
                            ) }
                        </GridCustom>
                        <ButtonStyled
                            className="btn btn--main"
                            disabled={savingOn}
                            onClick={this.handleSavePublished}
                            save >
                            Opublikuj
                        </ButtonStyled>
                    </GridCustom>
                    {isFormInvalid && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
                </>
            </DialogCustom>
        )
    }
}

export { FormOdpowiedz };
