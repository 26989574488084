import React from 'react';
import { Link } from 'react-router-dom';
import { fetchApi } from '../../../fetch';
import { MIEJSCE_REALIZACJI_OLD_BK_DICT } from '../../../const';
import { getSearchFiltersCount, SEARCH_CLEANED_FILTERS } from '../../../filters';
import { dateToStringOldBK } from '../../../helpers';
import { ButtonStyled, Container, GridCustom, GridCellCustom, Text, TextFieldCustom } from '../../common';
import { PaginatedBaseComponent } from '../common';
import { FiltryWyszukiwarki } from './FiltryWyszukiwarki';


const STATUS_MAP = {
    CANCELLED: 'canceled',
    PUBLISHED: 'active',
    CLOSED: 'archived',
};


class Wyszukiwarka extends PaginatedBaseComponent {

    // basic functions

    componentDidMount() {
        document.title = "Baza Konkurencyjności - wyszukiwarka";

        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userIdentifier !== this.props.userIdentifier) {
            this.handleReload();
        }
    }

    // handlers

    handleReload = (link=null) => {
        this.setState(
            {initializingOn: true, fetchError: '', objects: []},
            () => {this.fetchInitialData()}
        );
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.handleReload();
    }

    handleChangeQuery = (value) => {
        this.setState(
            {query: value, pageNumber: 1},
            () => {
                // change url
                this.props.history.push(
                    encodeURI(`${window.location.pathname}${value.length > 0 ? `?q=${value}` : ''}`));
            }
        );
    }

    handleAddToFavorites = (object) => {
        fetchApi(
            `/api/favorite-announcements/${object.id}`,
            "POST",
            {},
            {},
            () => {
                const index = this.state.objects.findIndex(obj => obj.id === object.id);
                const updatedObjects = [...this.state.objects];
                updatedObjects[index] = { ...updatedObjects[index], favorite: true};
                this.setState({ objects: updatedObjects });
            }
          );
    }

    handleRemoveFromFavorites = (object) => {
        fetchApi(
            `/api/favorite-announcements/${object.id}`,
            "DELETE",
            {},
            {},
            () => {
                const index = this.state.objects.findIndex(obj => obj.id === object.id);
                const updatedObjects = [...this.state.objects];
                updatedObjects[index] = { ...updatedObjects[index], favorite: false};
                this.setState({ objects: updatedObjects });
            }
          );
    }

    // helpers

    preState(props) {
        super.preState();

        // redefine from base class
        this.objectsName = 'ogloszenia';
        this.paginateBy = 20;
        this.sortingValues = [
            ['publicationDate', 'Po dacie publikacji ogłoszeń'],
            ['submissionDeadline', 'Po terminie składania ofert'],
            ['default', 'Domyślnie'],
        ];
        this.sortingLabel = 'Sortuj wyniki wyszukiwania';
        this.filtersComponentClass = FiltryWyszukiwarki;

        this.getFiltersCount = getSearchFiltersCount;
    }

    getAdditionalState(props) {
        const params = new URLSearchParams(props.location.search);
        return Object.assign({}, super.getAdditionalState(props), {
            selectedSort: {value: this.sortingValues[2][0], label: this.sortingValues[2][1]},
            query: params.get('q') || '',
            filters: SEARCH_CLEANED_FILTERS,
        })
    }

    postState(props) {
        super.postState(props);
        this.dictionaries = {};
    }

    getFetchUrl() {
        return '/api/announcements/search'
    }

    getFetchData() {
        let queryDict = super.getFetchData();

        if (this.state.query.length) {
            queryDict['query'] = this.state.query;
        }
        for (let [name, value] of Object.entries(this.state.filters)) {
            if (['publicationDateRange', 'submissionDeadlineRange'].includes(name)) {
                for (let [subname, subvalue] of Object.entries(value)) {
                    if (subname === 'type') { continue }
                    if (!!subvalue) {
                        queryDict[`${name}[${subname}]`] = subvalue;
                    }
                }
            } else if (name === 'cpvItem') {
                if (Object.keys(value).length) {
                    queryDict[name] = value.map(kod => kod.kod);
                }
            } else if ('programme' === name) {
                if (value.length) {
                        queryDict[name] = value.map(mr => mr.value.toUpperCase());
                }
            } else if ('fulfillmentPlaces' === name) {
                    if (value.length) {
                            queryDict[name] = value.map(mr => mr.value);
                    }
            } else if (['category', 'subcategory'].includes(name)) {
                if (value !== null) {
                    queryDict[name] = value.label;
                }
            } else if ((name === 'status' && Object.keys(value).length) ||
                    value !== SEARCH_CLEANED_FILTERS[name]
            ) {
                queryDict[name] = value;
            }
        }
        return queryDict
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się wyszukać ogłoszeń. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się wyszukać ogłoszeń. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // rendering

    renderHeader() {
        return (
            <Container>
                <form onSubmit={this.handleSubmit}>
                    <label htmlFor="search_input" className="sr-only">Wyszukaj ogłoszenie</label>
                    <GridCustom flexS fullwidth>
                        <TextFieldCustom
                            clearFieldContext="szukaj"
                            fullwidth
                            maxLength={250}
                            value={this.state.query}
                            onChange={ev => this.handleChangeQuery(ev.target.value)}
                            onClear={ev => this.handleChangeQuery('')}
                            id="search_input" />
                        <ButtonStyled className="search__btn" icon="search" id="szukaj_button_id" primary onClick={this.handleReload}>Szukaj</ButtonStyled>
                    </GridCustom>
                </form>
            </Container>
        )
    }

    renderEmptyObjects() {
        return <Text error role="alert">Nie znaleziono ogłoszeń spełniających kryteria wyszukiwania.</Text>
    }

    renderList() {
        const total = this.state.total;
        return (
            <>
                <Text accentHeader tag="h2" role="alert">Znaleziono {total < this.paginateBy ? '' : 'około '}{total} ogłoszeń</Text>
                <ul>
                    {this.state.objects.map(o => this.renderObject(o))}
                </ul>
            </>
        )
    }

    renderFiltersComponent() {
        return (
            <this.filtersComponentClass
                dictionaries={this.dictionaries}
                filters={this.state.filters}
                closeFilters={() => this.setState({filtersAreOn: false})}
                updateDictionaries={dictionaries => this.dictionaries = dictionaries}
                updateFilters={this.handleApplyFilters}
            />
        )
    }

    renderObject(object) {
        return (
            <li key={object.id} className="separate-content search__item">              
                <GridCustom fullwidth>
                    <GridCellCustom>
                        <Link to={`/ogloszenia/${object.id}`} className="link-text"><span dangerouslySetInnerHTML={{__html: object.tytul}}></span></Link>
                        {object.moje && <span className="author-label">Moje ogłoszenie</span>}
                    </GridCellCustom>
                    {!object.moje && (
                        <GridCellCustom style={{minWidth: '255px'}}>
                            {(object.favorite === false) && <ButtonStyled onClick={() => this.handleAddToFavorites(object)} primary
                                icon="favorite">DODAJ DO ULUBIONYCH<span className="sr-only"> ogłoszenie {object.tytul}</span></ButtonStyled>}
                            {(object.favorite === true) && <ButtonStyled onClick={() => this.handleRemoveFromFavorites(object)} remove
                                icon="close">USUŃ Z ULUBIONYCH<span className="sr-only"> ogłoszenie {object.tytul}</span></ButtonStyled>}
                        </GridCellCustom>
                    )}
                </GridCustom>

                <p dangerouslySetInnerHTML={{__html: object.tresc}}></p>
                <GridCustom flexM fullwidth>
                    <Text info className="search__results-meta">Opublikowano: {object.dataOpublikowania}</Text>
                    <Text info className="search__results-meta">Termin składania ofert: {object.terminOfert}</Text>
                    <Text info className="search__results-meta search__results-meta--company-name"><span dangerouslySetInnerHTML={{__html: object.nazwaOgloszeniodawcy}}></span></Text>
                    {(object.miejsceRealizacji || '').length > 0 && <Text info className="search__results-meta">{object.miejsceRealizacji}</Text>}
                </GridCustom>
            </li>
        )
    }

    renderAdditionalAfterBaseContent() {
        return this.renderOldBkLink()
    }

    renderOldBkLink() {
        const filters = this.state.filters;
        const isPublicationDateRangeCustom = filters.publicationDateRange.type === 'custom'
        const isSubmissionDeadlineRangeCustom = filters.submissionDeadlineRange.type === 'custom'
        return (
            <form name="search" method="post" action="https://archiwum-bazakonkurencyjnosci.funduszeeuropejskie.gov.pl/publication/list/" target="_blank">
                {this.state.query.length > 0 && <input type="hidden" id="filter_keyword" name="filter[keyword]" value={this.state.query} />}
                {filters.status.map((s, i) => <input type="hidden" key={i} id={`search_status_${i}`} name="search[status][]" value={`${STATUS_MAP[s]}`} />)}
                {isPublicationDateRangeCustom && filters.publicationDateRange.from && (
                    <input type="hidden" id="search_dateStart" name="search[dateStart]" value={`${dateToStringOldBK(new Date(filters.publicationDateRange.from))}`} />
                )}
                {isPublicationDateRangeCustom && filters.publicationDateRange.to && (
                    <input type="hidden" id="search_dateStop" name="search[dateStop]" value={`${dateToStringOldBK(new Date(filters.publicationDateRange.to))}`} />
                )}
                {isSubmissionDeadlineRangeCustom && filters.submissionDeadlineRange.from && (
                    <input type="hidden" id="search_datePublicationStart" name="search[datePublicationStart]" value={`${dateToStringOldBK(new Date(filters.submissionDeadlineRange.from))}`} />
                )}
                {isSubmissionDeadlineRangeCustom && filters.submissionDeadlineRange.to && (
                    <input type="hidden" id="search_datePublicationStop" name="search[datePublicationStop]" value={`${dateToStringOldBK(new Date(filters.submissionDeadlineRange.to))}`} />
                )}
                <input type="hidden" id="search_adUsers" name="search[adUsers]" value="all" />
                {filters.category && <input type="hidden" id="search_category_0" name="search[category][]" value={filters.category} />}
                {filters.subcategory && <input type="hidden" id="search_subcategory_0" name="search[subcategory][]" value={filters.subcategory} />}
                {filters.fulfillmentPlaces.map(m => {
                    const value = MIEJSCE_REALIZACJI_OLD_BK_DICT[m.value];
                    if (!!value) {
                        return <input type="hidden" key={value} id={`search_province_${value}`} name="search[province][]" value={value} />
                    } else {
                        return null
                    }
                })}
                <ButtonStyled lite onClick={() => { }} type="submit">Przeszukaj starą Bazę Konkurencyjności<span className="sr-only"> Strona zostanie otwarta w nowym oknie</span></ButtonStyled>
            </form>
        )
    }
}

export { Wyszukiwarka };
