import React, { useEffect, useState } from 'react';
import { TabBar, Tab } from '@rmwc/tabs';

import { fetchApi } from '../../../fetch';
import { checkHasNext, checkHasPrev } from '../../../helpers';
import {
    getSerializedObject,
    translateErrorMessage
} from '../../../serializers';
import {
    ButtonStyled,
    GridCustom,
    Loader,
    Pagination,
    TableCustom,
    Text
} from '../../common';
import { UserPermissionsListRow } from './UserPermissionsListRow';


const PAGINATED_BY = 20;
const TAB_INDEXES = {
    direct: 0,
    inherited: 1,
};
const REVERTED_TAB_INDEXES = {
    0: 'direct',
    1: 'inherited',
};


function getRowKey(objectData, isImplementationLevelType) {
    if (!isImplementationLevelType) {
        return objectData.authorized.id
    }
    let key = objectData.authorized.id;
    for (let implementationLevelName of [
        'programme', 'priority', 'activity', 'region', 'institution'
    ]) {
        if (objectData[implementationLevelName]?.id) {
            key += `_${objectData[implementationLevelName].id}`;
        }
    }
    return key
}


export const PermissionUsers = ({
    activeUsersPermission, isImplementationLevelType, onClose, ...props
}) => {
    const [initializingOn, setInitializingOn] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [fetchError, setFetchError] = useState(null)
    const [data, setData] = useState(null)
    const [selectedTab, setSelectedTab] = useState(TAB_INDEXES.direct);

    function getUsers() {
        let xhrFetch = null;

        function callbackSuccess(data) {
            xhrFetch = null;
            const { total } = data.meta;
            setData({
                permissions: getSerializedObject(
                    data, {mapToCamelCaseName: true}).permissions || [],
                total: total,
                hasPrev: checkHasPrev(pageNumber),
                hasNext: checkHasNext(PAGINATED_BY, pageNumber, total),
            });
            setInitializingOn(false);
        }

        function callbackFetchError(message) {
            xhrFetch = null;
            setFetchError(message);
            setInitializingOn(false);
        }

        function callbackError(data) {
            callbackFetchError(
                `Nie udało się pobrać listy użytkowników. ${translateErrorMessage(data.message)}`
            );
        }

        function callbackIncorrectStatus(status) {
            callbackFetchError(status === 403
                ? `Nie masz uprawnień do przeglądania listy użytkowników dla tego ${isImplementationLevelType ? 'poziomu wdrożenia' : 'uprawnienia'}.`
                : `Nie udało się pobrać listy użytkowników. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
            );
        }

        setInitializingOn(true);
        setFetchError(null);
        xhrFetch = fetchApi(
            props.url, 'GET', {}, {
                limit: PAGINATED_BY,
                page: pageNumber,
                ...(props.additionalFetchData || {}),
                ...(isImplementationLevelType
                    ? {listType: REVERTED_TAB_INDEXES[selectedTab]}
                    : {}
                ),
            },
            callbackSuccess, callbackError, callbackIncorrectStatus
        );
        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }
    useEffect(getUsers, [pageNumber, selectedTab]);

    function handleChangePage(next=true) {
        setPageNumber(prev => next ? prev + 1 : Math.max(prev -1, 1));
    }

    function handleChangeTab(ev) {
        setPageNumber(1);
        setSelectedTab(ev.detail.index);
    }

    return (
        <>
            {isImplementationLevelType &&
                activeUsersPermission.permission.priority &&
                (
                    <TabBar
                        activeTabIndex={selectedTab}
                        onActivate={handleChangeTab}>
                        <Tab label="Przyznane bezpośrednio" />
                        <Tab label="Dziedziczone" />
                    </TabBar>
                )
            }
            {initializingOn
                ? <Loader />
                : (fetchError
                    ? <Text error>{fetchError}</Text>
                    : (
                        <>
                            {data.total === 0
                                ? <Text error>Lista użytkowników jest pusta</Text>
                                : (
                                    <TableCustom
                                        tableHeaderList={['Użytkownik', "Uprawnienia", 'Działania']}>
                                        {data.permissions.map(object => (
                                            <UserPermissionsListRow
                                                key={getRowKey(object, isImplementationLevelType)}
                                                isImplementationLevelType={isImplementationLevelType}
                                                mode="userData"
                                                permission={{
                                                    ...object,
                                                    ...(isImplementationLevelType
                                                        ? {}
                                                        : {type: activeUsersPermission.permission.type}
                                                    )
                                                }}
                                                onSetEditedPermission={props.onSetEditedPermission}
                                                onSetRemovedPermission={props.onSetRemovedPermission} />
                                        ))}
                                    </TableCustom>
                                )
                            }
                            {data.total > PAGINATED_BY && (
                                <Pagination
                                    currentPage={pageNumber}
                                    disabled={initializingOn}
                                    hasNext={data.hasNext}
                                    hasPrev={data.hasPrev}
                                    handleChangePage={handleChangePage} />
                            )}
                        </>
                    )
                )
            }
            <GridCustom flexEnd>
                <ButtonStyled primary onClick={onClose}>
                    Zamknij
                </ButtonStyled>
            </GridCustom>
        </>
    )
};
