import { Font, StyleSheet } from '@react-pdf/renderer';

import robotoBold from '../../static/fonts/Roboto-Bold.ttf';
import robotoItalic from '../../static/fonts/Roboto-Italic.ttf';
import robotoRegular from '../../static/fonts/Roboto-Regular-with-special.ttf';

Font.register({ family: 'Roboto', fonts: [
    { src: robotoRegular}, // font-style: normal, font-weight: normal
    { src: robotoItalic, fontStyle: 'italic' },
    { src: robotoBold, fontWeight: 700 },
]});

Font.registerHyphenationCallback(word => {
    // Breaks the lines with long strings of the same character, without white spaces.
    const wordPartSize = 40;
    let parts = [];

    if (word.length && word.length < wordPartSize) {
        parts = [word];
    } else {
        for (let i = 0; i < word.length; i += wordPartSize) {
            parts.push(word.slice(i, i + wordPartSize));
        }
    }

    return parts;
});


const pdfStyles = StyleSheet.create({
    mainPdfContainerStyle: {
        padding: 40,
        fontSize: 11,
        lineHeight: 1.6,
        fontFamily: 'Roboto',
    },
    flexContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    gridSection: {
        width: 300
    },
    borderedSectionStyle: {
        paddingVertical: 0,
        paddingHorizontal: 10,
        marginVertical: 10,
        marginLeft: 10,
        borderLeftWidth: 1,
        borderLeftColor: '#666A6D'
    },
    borderedDashedSectionStyle: {
        padding: 10,
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: '#666A6D'
    },
    borderedSeparateSectionStyle: {
        paddingBottom: 5,
        marginBottom: 50,
        borderBottomWidth: 1,
        borderBottomColor: '#666A6D'
    },
    pzTitleSection: {
        padding: 5,
        marginBottom: 10,
        borderWidth: 1,
        borderLeftWidth: 5,
        borderColor: '#666A6D'
    },
    textHeaderStyle: {
        marginTop: 20,
        marginBottom: 5,
        fontSize: 16,
        fontWeight: 700
    },
    textWithLabelStyle: {
        minWidth: '45%',
        marginBottom: 10,
    },
    labelStyle: {
        marginTop: 5,
        marginBottom: 3,
        fontSize: 12,
        fontWeight: 700,
        color: '#666A6D'
    },
    labelHeaderStyle: {
        fontSize: 16,
        marginTop: 30,
    },
    dateTextStyle: {
        fontWeight: 700
    },
    textInfoStyle: {
        marginBottom: 5,
        fontSize: 10,
        fontStyle: 'italic',
        color: '#666A6D'
    },
    errorStyle: {
        color: '#ff0000'
    },
    titleStyle: {
        fontSize: 18,
    },
    sectionTitleStyle: {
        padding: 3,
        paddingLeft: 15,
        marginBottom: 10,
        fontSize: 16,
        fontWeight: 700,
        borderColor: '#666A6D',
        borderLeftWidth: 5,
    },
});


export { pdfStyles };
