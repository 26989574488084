import React, { useEffect,useState } from 'react';

import { fetchApi } from '../../../fetch';
import { getSerializedErrorsFromServer } from '../../../serializers';
import {
    CancelSaveButtonsRow,
    DialogCustom,
    FetchErrorWithOkayButton,
    FieldWithLabel,
    InfoTextWithIcon,
    Text,
    TextFieldCustom
} from '../../common';


export const EditProjectForm = ({project, onClose, onSave}) => {
    const isProject = project.type === 'project';

    const [name, setName] = useState(project.name);
    const [savingOn, setSavingOn] = useState(false);
    const [errors, setErrors] = useState({});
    const [fetchSaveError, setFetchSaveError] = useState(null);

    function save() {
        if (!savingOn) { return }

        let xhrFetch = null;

        function callbackSuccess(data) {
            xhrFetch = null;
            onSave(project.id, data[isProject ? 'project' : 'enrollment']);
        }

        function callbackError(data) {
            xhrFetch = null;
            setFetchSaveError(data.message);
            setSavingOn(false);
        }

        function callbackIncorrectStatus(status) {
            xhrFetch = null;
            setFetchSaveError(status === 403
                ? `Nie masz uprawnień do edycji tego ${isProject ? 'projektu' : 'naboru'}.`
                : `Wystąpił nieoczekiwany błąd o kodzie ${status}.`
            );
            setSavingOn(false);
        }

        function callbackShowSaveErrors(errors) {
            xhrFetch = null;
            setErrors(getSerializedErrorsFromServer(errors, {mapToCamelCaseName: true}));
            setSavingOn(false);
        }

        xhrFetch = fetchApi(
            `/api/admin/${isProject ? 'project' : 'enrollment'}/${project.id}`,
            'PUT', {}, {name}, callbackSuccess, callbackError,
            callbackIncorrectStatus, callbackShowSaveErrors
        );
        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }
    useEffect(save, [savingOn])

    function handleSave() {
        setErrors({});
        setSavingOn(true);
    }

    const isNameInvalid = (errors.name || []).length > 0;
    return (
        <DialogCustom
            ariaLabelledby="addDialogLabel"
            className="dialog"
            onClose={onClose}
        >
            <h2 id="addDialogLabel" className="text--main-header">Edytuj {isProject ? 'projekt' : 'nabór'}</h2>
            <Text className="text--project-info">Numer</Text> <Text accentHeader>{project.fullNumber}</Text>
            <FieldWithLabel
                label="Tytuł"
                tag="label"
                labelFor="name_id"
            >
                <TextFieldCustom
                    aria-describedby={isNameInvalid ? 'name_error' : null}
                    aria-label={`Tytuł, maksymalna liczba znaków: 200. Wpisano ${name.length} znaków.`}
                    aria-required={true}
                    aria-valuemax={200}
                    characterCount
                    clearFieldContext="tytuł"
                    disabled={savingOn}
                    fullwidth
                    id="name_id"
                    invalid={isNameInvalid}
                    maxLength={200}
                    required={isProject}
                    value={name}
                    onChange={ev => setName(ev.target.value)}
                    onClear={ev => setName('')} />
                {isNameInvalid && <ul id="name_error">{errors.name.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
            </FieldWithLabel>
            {project.attachedAdvertisementsAmount > 0 && (
                <InfoTextWithIcon message={`Tytuł zostanie zaktualizowany we wszystkich ogłoszeniach, z którymi powiązany jest ten ${isProject ? 'projekt' : 'nabór'}.`} />
            )}
            <CancelSaveButtonsRow disabled={savingOn} onCancel={onClose} onSave={handleSave} />
            {fetchSaveError !== null && (
                <FetchErrorWithOkayButton
                    message={fetchSaveError}
                    onClickOkay={() => setFetchSaveError(null)} />
            )}
        </DialogCustom>
    )
}
