import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import { datetimeToString } from '../../helpers';


const PdfHeader = (props) => {

    const {
        flexContainerStyle,
        metaSectionStyle,
        textInfoStyle,
    } = styles;

    return (
        <View style={metaSectionStyle} fixed>
            <View style={flexContainerStyle}>
                <Text style={textInfoStyle}>Wygenerowano: {datetimeToString(new Date())}</Text>
                <Text render={({ pageNumber, totalPages }) => (
                        `Strona ${pageNumber} / ${totalPages}`
                    )} fixed style={textInfoStyle} />
            </View>
            <Text style={textInfoStyle}>Wygenerowano za pośrednictwem serwisu Baza Konkurencyjności.</Text>
            {props.title && props.title.length > 0 && <Text style={[textInfoStyle, {maxHeight: 30}]}>{props.title}</Text>}
        </View>
    );
}

const styles = StyleSheet.create({
    metaSectionStyle: {
        paddingHorizontal: 20,
        paddingVertical: 5,
        paddingBottom: 0,
        marginHorizontal: -20,
        marginTop: -10,
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#666A6D',
        borderBottomStyle: 'dashed',
    },
    flexContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textInfoStyle: {
        marginBottom: 5,
        fontSize: 10,
        fontStyle: 'italic',
        color: '#666A6D'
    },
});


export { PdfHeader };
