import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';

import { AdvertisementConsumer } from '../../../context/advertisementContext';
import { ButtonStyled, DialogCustom, GridCustom, FieldWithLabel, Text, TextFieldCustom } from '../../common';


class FormKryteriumOceny extends Component{

    constructor(props) {
        super(props);

        if (props.isNew) {
            this.state = Object.assign({}, props.kryteriumOceny, {
                czyKryteriumCenowe: false,
                opis: '',
            });
        } else {
            this.state = Object.assign({}, props.kryteriumOceny);
        }
    }

    // basic functions

    componentDidMount() {
        window.formOgloszenie.computeProgress();
    }

    componentDidUpdate(prevProps, prevState) {
        if (window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }

    // handlers

    handleSave = () => {
        const {isNew, kryteriumOceny} = this.props;

        this.props.onSave({
            id: isNew ? null : kryteriumOceny.id,
            temporaryId: kryteriumOceny.temporaryId,
            zamowienieTemporaryId: this.props.zamowienieTemporaryId,
            czyKryteriumCenowe: this.state.czyKryteriumCenowe,
            opis: this.state.opis.trim(),
        }, isNew);
    }

    handleCloseDialog = () => {
        this.props.onCloseForm();
    }

    // rendering

    render() {
        return (
            <DialogCustom 
                className="dialog"
                dialogTitle="Dodaj kryterium oceny"
                onClose={this.handleCloseDialog}
            >
                <>
                    <Checkbox
                        label="Czy kryterium cenowe?"
                        checked={this.state.czyKryteriumCenowe}
                        onChange={(event) => this.setState({czyKryteriumCenowe: event.target.checked})}
                    />
                    <AdvertisementConsumer>
                        {({formErrors}) => {
                            const temporaryId = this.props.kryteriumOceny.temporaryId;
                            const errors = formErrors.kryteriaOceny[temporaryId] || {};
                            return (
                                <FieldWithLabel label="Opis" tag="label" labelFor="kryteriumOceny_opis_id">
                                    <TextFieldCustom
                                        aria-describedby={(errors.opis || '').length > 0 ? 'kryteriumOceny_opis_error' : null}
                                        clearFieldContext="opis"
                                        textarea
                                        invalid={(errors.opis || '').length > 0}
                                        fullwidth
                                        maxLength={300}
                                        aria-valuemax={300}
                                        characterCount
                                        value={this.state.opis}
                                        onChange={(event) => this.setState({opis: event.target.value})}
                                        onClear={(ev) => this.setState({opis: ''})}
                                        id="kryteriumOceny_opis_id"
                                        aria-label={`Opis, maksymalna liczba znaków: 300. Wpisano ${this.state.opis.length} znaków.`} />
                                    {(errors.opis || '').length > 0 && <Text error id="kryteriumOceny_opis_error" role="alert">{errors.opis}</Text>}
                                </FieldWithLabel>
                            )
                        }}
                    </AdvertisementConsumer>
                    <GridCustom flexEnd>
                        <ButtonStyled onClick={this.handleCloseDialog} cancel>Anuluj</ButtonStyled>
                        <ButtonStyled onClick={this.handleSave} save>{this.props.isNew ? 'Dodaj' : 'Zapisz zmiany'}</ButtonStyled>
                    </GridCustom>
                </>
            </DialogCustom>
        )
    }
}


export { FormKryteriumOceny };
