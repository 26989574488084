import React, { Fragment } from 'react';

import {
    getGrantedPermissionsForGroup,
    getGroupChecked,
    translatePermissionName
} from '../../../helpers_permissions';
import { Text } from '../../common';


export const UserPermissionsListGrantedCell = ({
    groupNameList, isImplementationLevelType, permissions
}) => {
    return (
        <>
            {groupNameList.map(groupName => {
                if (getGroupChecked(permissions, groupName)) {
                    return (
                        <Text className="permissions__main-name" key={groupName}>
                            {translatePermissionName(groupName)} - pełne uprawnienia
                        </Text>
                    )
                }

                const groupGranted = getGrantedPermissionsForGroup(
                    permissions[groupName]);
                if (!groupGranted.length) { return null }

                return (
                    <Fragment key={groupName}>
                        <Text className="permissions__main-name">{translatePermissionName(groupName)}</Text>
                        {groupGranted.map(permissionName => (
                            <Text
                                className="permissions__indented"
                                key={`${groupName}_${permissionName}`}>
                                {translatePermissionName(
                                    isImplementationLevelType && permissionName === 'functionalityManagement'
                                        ? 'functionalityManagementImplementationLevel'
                                        : permissionName
                                )}
                            </Text>
                        ))}
                    </Fragment>
                )
            })}
        </>
    )
};
