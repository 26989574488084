// TextFieldCustom adds clear field (onClear) function to text field

import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';
import { TextField } from '@rmwc/textfield';


class TextFieldCustom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isInputFocused: false,
            isIconClicked: false,
        };
        this.blurInputTimeout = null;

        this.handleBlurInput = this.handleBlurInput.bind(this);
        this.handleClickIcon = this.handleClickIcon.bind(this);
        this.handleFocusInput = this.handleFocusInput.bind(this);
    }

    // basic functions

    componentWillUnmount() {
        if (this.blurInputTimeout !== null) {
            clearTimeout(this.blurInputTimeout);
            this.blurInputTimeout = null;
        }
    }

    // handlers

    handleClickIcon(ev) {
        this.setState({isIconClicked: true}, () => {
            if (this.props.onClear) {
                this.props.onClear(ev);
            }
            this.setState({isIconClicked: false});
        });
    }

    // TODO: if icon to clear text field is visible always when the field is not empty, handleBlurInput(ev) and handleFocusInput(ev) should be deleted.
    handleBlurInput(ev) {
        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }
        this.blurInputTimeout = setTimeout(() => {
            if (!this.state.isIconClicked) {
                this.setState({isInputFocused: false});
            }
        }, 150);
    }

    handleFocusInput(ev) {
        if (this.props.onFocus) {
            this.props.onFocus(ev);
        }
        this.setState({isInputFocused: true});
    }

    // rendering

    render() {
        const props_ = Object.assign({}, this.props, {
            onBlur: this.handleBlurInput,
            onFocus: this.handleFocusInput,
        });
        // this is not a basic component prop, it throws error if declared and not used
        delete props_['onClear'];
        // this is not a basic component prop, it should be deleted not to propagate when not used
        delete props_['clearFieldContext'];

        return (
            <div className="text-field-custom-container">
                <TextField autoFocus={this.props.autoFocus || false} {...props_} />
                {props_.value.length ? (
                    <button
                        className="text-field-clear"
                        onClick={this.handleClickIcon}
                        type="button"
                    >
                        <span className="sr-only">Wyczyść pole {this.props.clearFieldContext}</span>
                        <Icon aria-hidden="true" icon="cancel" />
                    </button>
                ) : null}
            </div>
        )
    }
};


export { TextFieldCustom };
