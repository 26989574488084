import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';

import { generatePdfDocument } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { Breadcrumps, ButtonCreatePdf, Container, GridCustom, Text } from '../../common';
import { BaseComponent } from '../common';
import { PomoceFrontPdf } from './PomoceFrontPdf';


class PomoceFront extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        this.setState({
            initializingOn: false,
            pomoce: getSerializedObject(
                data, {nonRecursiveKeys: ['tresc', ]}).pomoce,
        });
    }

    handleCreatePdf = () => {
        this.setState(
            {pdfCreatingOn: true},
            () => generatePdfDocument(
                <PomoceFrontPdf pomoce={this.state.pomoce.sort(this.sortPomoce)} />,
                'pomoc.pdf',
                this.callbackPfdCreated
            )
        );
    }

    // helpers

    getAdditionalState(props) {
        return {
            pomoce: [],
            pdfCreatingOn: false,
        }
    }

    getFetchUrl() {
        return '/api/help'
    }

    getFetchError(message) {
        return `Nie udało się pobrać pomocy. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callbackPfdCreated = () => {
        this.setState({pdfCreatingOn: false});
    }

    sortPomoce(p1, p2) {
        return p1.id > p2.id ? 1 : -1
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps isFront>
                <li className="breadcrumps__current">Pomoc</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        document.title = "Baza Konkurencyjności - pomoc";
        if (!this.state.pomoce.length) {
            return <Text error role="alert">Brak dostępnych pomocy.</Text>
        }
        const pomoce = this.state.pomoce.sort(this.sortPomoce);
        return (
            <>
                <GridCustom fullwidth centerVertical>
                    <Text tag="h2" mainHeader>Pomoc</Text>
                    <GridCustom flexEnd>
                        <ButtonCreatePdf
                            pdfCreatingOn={this.state.pdfCreatingOn}
                            onClick={this.handleCreatePdf} />
                    </GridCustom>
                </GridCustom>

                <Container className="help__list-of-content">
                    <ul>
                        {this.renderPomoc(pomoce[0], pomoce, 1)}
                    </ul>
                </Container>
            </>
        )
    }

    renderPomoc(pomoc, pomoce, deepLevel) {
        return (
            <li key={pomoc.id}>
                <Link to={`/pomoc/${pomoc.id}-${pomoc.slug}`} className="link-text"><Icon icon="arrow_right" aria-hidden="true" className="help__help-item-icon" />{pomoc.naglowek}</Link>
                {this.renderPomocePodrzedne(pomoc.id, pomoce, deepLevel)}
            </li>
        )
    }

    renderPomocePodrzedne(pomocId, pomoce, deepLevel) {
        const pomocePodrzedne = pomoce.filter(p => p.rozdzialNadrzedny.id === pomocId);
        if (!pomocePodrzedne.length) { return null }

        let pomocePodrzedneClassName = 'help__separate-list';
        if (deepLevel >= 5) { pomocePodrzedneClassName += ' help__separate-list--deep'}
        return (
            <ul className={pomocePodrzedneClassName}>
                {pomocePodrzedne.map(p => this.renderPomoc(p, pomoce, deepLevel + 1))}
            </ul>
        )
    }

}


export { PomoceFront };
