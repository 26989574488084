import React from 'react';
import { Link } from 'react-router-dom';

import { isNotEmpty } from '../../../helpers';
import { Breadcrumps, Container, FieldWithLabel, GridCustom, Text } from '../../common';
import { Komunikat } from './Komunikat';
import { DraftToComponents } from './DraftToComponents';


class KomunikatFront extends Komunikat {

    // helpers

    getFetchUrl() {
        return `/api/statements/${this.props.match.params.id}`
    }

    getFetchData() {
        return {}
    }

    setUrlWithCorrectSlug(komunikat) {
        // update url if slugs are not equal
        const urlParams = this.props.match.params;
        if (komunikat.slug !== urlParams.slug) {
            this.props.history.push(`/komunikaty/${urlParams.id}-${komunikat.slug}`);
        }
    }

    callback403 = () => {}

    // rendering

    renderHeader() {
        return (
            <Breadcrumps isFront>
                <li className="breadcrumps__link"><Link to='/komunikaty'>Komunikaty</Link></li>
                <li className="breadcrumps__current">Szczegóły komunikatu</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        document.title = "Baza Konkurencyjności - komunikat";
        const komunikat = this.state.komunikat;
        return (
            <>
                <Text tag="h2" mainHeader>Komunikat "{this.state.komunikat.naglowek}"</Text>
                <GridCustom fullwidth>
                    <FieldWithLabel label="Data opublikowania">
                        <Text>{komunikat.dataOpublikowania}</Text>
                    </FieldWithLabel>
                    {isNotEmpty(komunikat.dataWaznosci) && (
                        <FieldWithLabel label="Data ważności">
                            <Text>{komunikat.dataWaznosci}</Text>
                        </FieldWithLabel>
                    )}
                </GridCustom>
                <Container>
                    <FieldWithLabel label="Treść">
                        <DraftToComponents content={komunikat.tresc} />
                    </FieldWithLabel>
                </Container>
            </>
        )
    }
}


export { KomunikatFront };
