import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';

import { AdvertisementConsumer } from '../../../context/advertisementContext';
import { Box, ButtonStyled, Ellipsis, FieldWithLabel, GridCustom, Text } from '../../common';
import { FormWarunekUdzialu } from './FormWarunekUdzialu';


class WarunkiUdzialu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newWarunekUdzialuOn: false,
            editingWarunekUdzialuTemporaryId: null,
        };
    }

    // handlers

    handleCloseForm = () => {
        this.setState(
            {
                newWarunekUdzialuOn: false,
                editingWarunekUdzialuTemporaryId: null,
            },
            () => {
                if (window.formOgloszenie) { window.formOgloszenie.computeProgress()}
            }
        );
    }

    handleSave = (data, isNew) => {
        this.props.onSaveWarunekUdzialu(data, isNew, this.handleCloseForm);
    }

    // rendering

    render() {
        const {
            opis,
            podkategoria,
            typ,
            warunkiUdzialu,
        } = this.props;
        return (
             <AdvertisementConsumer>
                {({
                    formErrors,
                    przedmiotZamowieniaPodkategoriaDict,
                    przedmiotZamowieniaKategoriaDict,
                    typWarunku
                }) => (
                    <Box
                        tag="li"
                        titleWithSubtitle={przedmiotZamowieniaKategoriaDict[typ] || '-'}
                        subtitle={przedmiotZamowieniaPodkategoriaDict[podkategoria] || '-'}
                        fullwidth >
                        <FieldWithLabel label="Opis">
                            <Ellipsis>
                                <Text className="long-text separate-part">{opis}</Text>
                            </Ellipsis>
                        </FieldWithLabel>
                        <Text tag="h3" sectionHeader>Wymagania</Text>
                        <Text info>Liczba wyników: {warunkiUdzialu.length}</Text>
                        <GridCustom tag="ul">
                            {warunkiUdzialu.map((wu) => this.renderWarunekUdzialu(wu, formErrors, typWarunku))}
                        </GridCustom>
                        {this.renderWarunekUdzialuForm()}
                        {this.renderNewWarunekUdzialuButton(typWarunku)}
                    </Box>
                )}
            </AdvertisementConsumer>
        )
    }

    renderWarunekUdzialu(warunekUdzialu, formErrors, typWarunku) {
        const formLocked = this.props.formLocked;
        return (
            <Box key={warunekUdzialu.temporaryId} tag="li">
                {Object.keys(formErrors.warunkiUdzialu[warunekUdzialu.temporaryId] || {}).length > 0 && <Text error role="alert"><Icon aria-hidden="true" icon={{ icon: 'warning', size: 'xsmall' }} style={{marginRight: 3}} /> Błędy!</Text>}
                <FieldWithLabel label="Typ wymagania">
                    <Text>{typWarunku[warunekUdzialu.typ] || '-'}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Opis wymagania">
                    <Ellipsis>
                        <Text className="long-text">{warunekUdzialu.opis || '-'}</Text>
                    </Ellipsis>
                </FieldWithLabel>
                {!this.props.isAlreadyPublished && (
                    <GridCustom flexEnd className="btn-container-bottom">
                        <ButtonStyled
                            disabled={formLocked}
                            remove
                            onClick={() => this.props.onRemoveWarunekUdzialu(
                                this.props.przedmiotZamowieniaTemporaryId, warunekUdzialu.temporaryId)
                            }>
                            Usuń
                        </ButtonStyled>
                        <ButtonStyled
                            disabled={formLocked}
                            lite
                            onClick={() => this.setState(
                                {newWarunekUdzialuOn: null, editingWarunekUdzialuTemporaryId: warunekUdzialu.temporaryId})
                            }>
                            Edytuj
                        </ButtonStyled>
                    </GridCustom>
                )}
            </Box>
        )
    }

    renderWarunekUdzialuForm() {
        const {
            editingWarunekUdzialuTemporaryId,
            newWarunekUdzialuOn,
        } = this.state;
        if (newWarunekUdzialuOn || editingWarunekUdzialuTemporaryId !== null) {
            const {
                lastTemporaryId,
                przedmiotZamowieniaTemporaryId,
                warunkiUdzialu,    // list of warunekUdzialu for this przedmiotZamowienia
            } = this.props;

            let wu, usedWarunkiUdzialuIds;
            if (newWarunekUdzialuOn) {
                wu = {temporaryId: lastTemporaryId + 1};
                usedWarunkiUdzialuIds = warunkiUdzialu.map(wu => parseInt(wu.typ));
            } else {
                wu = warunkiUdzialu.filter(
                    w => w.temporaryId === editingWarunekUdzialuTemporaryId)[0];
                usedWarunkiUdzialuIds = warunkiUdzialu.map(
                    wu => parseInt(wu.typ)).filter((id) => id !== parseInt(wu.typ));
            }

            return <FormWarunekUdzialu
                        isNew={newWarunekUdzialuOn}
                        przedmiotZamowieniaTemporaryId={przedmiotZamowieniaTemporaryId}
                        usedWarunkiUdzialuIds={usedWarunkiUdzialuIds}
                        warunekUdzialu={wu}
                        onCloseForm={this.handleCloseForm}
                        onSave={this.handleSave} />
        }
        return null
    }

    renderNewWarunekUdzialuButton(typWarunku) {
        if (this.props.isAlreadyPublished) { return null }

        if (!this.state.newWarunekUdzialuOn &&
                Object.keys(typWarunku).filter(id => !this.props.warunkiUdzialu.map(
                    wu => parseInt(wu.typ)).includes(parseInt(id))).length) {
            return (
                <GridCustom flexEnd additionalClassName=" box__button-container">
                    <ButtonStyled
                        add
                        disabled={this.props.formLocked}
                        id={`pz${this.props.przedmiotZamowieniaTemporaryId}_dodaj_warunekUdzialu_button_id`}
                        onClick={() => this.setState({newWarunekUdzialuOn: true, editingWarunekUdzialuTemporaryId: null})}>
                        Dodaj wymaganie
                    </ButtonStyled>
                </GridCustom>
            )
        }
        return null
    }
}


export { WarunkiUdzialu };
