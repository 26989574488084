import React, { Component } from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { ButtonStyled, GridCustom, FieldWithLabel, Select, Text,
         TextFieldCustom } from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { KodyCPV } from './KodyCPV';
import { MiejscaRealizacji } from './MiejscaRealizacji';


const EMPTY_VALUE = {
    value: 0,
    label: 'Wybierz',
}


class FormPrzedmiotZamowienia extends Component {

    constructor(props) {
        super(props);
        const pz = props.przedmiotZamowienia || {};
        this.state = {
            temporaryId: pz.temporaryId,
            id: pz.id || null,
            kategoria: pz.kategoria || null,
            podkategoria: pz.podkategoria || null,
            okresGwarancji: pz.okresGwarancji || '',
            opis: pz.opis || '',
            kodyCPV: pz.kodyCPV || [],
            miejscaRealizacji: pz.miejscaRealizacji || [],
            errors: {},
        };

    }

    // basic functions

    componentDidMount() {
        this.setState(
            {errors: this.context.formErrors.przedmiotyZamowienia[this.props.przedmiotZamowienia.temporaryId] || {}},
            () => window.formOgloszenie.computeProgress()
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }

    // handlers

    handleAddKodCPV = (kod) => {
        this.setState(
            prevState => ({
                kodyCPV: [...prevState.kodyCPV, kod]
            }),
        );
    }

    handleRemoveKodCPV = (id_) => {
        this.setState(prevState => ({
            kodyCPV: prevState.kodyCPV.filter(code => parseInt(code.id) !== parseInt(id_)),
        }));
    }

    handleRemoveMiejsceRealizacji = (temporaryId) => {
        this.setState(prevState => ({
            miejscaRealizacji: prevState.miejscaRealizacji.filter(mr => mr.temporaryId !== temporaryId),
        }));
    }

    handleSaveMiejsceRealizacji = (data, isNew) => {
        return new Promise((resolve, reject) => {
            this.setState(prevState => ({
                miejscaRealizacji: [...prevState.miejscaRealizacji, data]
            }), resolve());
        })
    }

    handleSave = () => {
        const data = this.getData();
        this.context.clearPrzedmiotZamowieniaErrors(
            this.props.przedmiotZamowienia.temporaryId,
            () => this.props.onSave(data)
        );
    }

    // helpers

    getData() {
        let data = Object.assign({}, this.state);
        delete data['errors'];
        return data
    }

    // rendering

    render() {
        const {
            errors,
            kategoria,
            kodyCPV,
            miejscaRealizacji,
            okresGwarancji,
            opis,
            podkategoria,
        } = this.state;
        const{
            przedmiotZamowieniaPodkategoriaDict,
            przedmiotZamowieniaKategoria,
            przedmiotZamowieniaKategoriaDict,
        } = this.context;

        const selectClassName = 'select-custom';
        const selectInvalidClassName = 'select-custom select-custom--invalid';
        const isAlreadyPublished = this.props.isAlreadyPublished;
        const kategoriaInvalid = (errors.kategoria || '').length > 0

        return (
            <>
                <GridCustom fullwidth flexBottom flexM>
                    <FieldWithLabel
                        label="Kategoria przedmiotu zamówienia"
                        tag="label"
                        selectRef={React.createRef()}
                        className="select-custom--long">
                        <Select
                            aria-label={`Kategoria przedmiotu zamówienia ${errors.kategoria || ''}`}
                            className={((errors.kategoria || '').length > 0) ? selectInvalidClassName : selectClassName}
                            indicatorId="pz_kategoria_id"
                            isDisabled={isAlreadyPublished}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                            options={[EMPTY_VALUE, ].concat(...przedmiotZamowieniaKategoria.map(t => ({value: t.id, label: t.nazwa})))}
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            styles={customSelectStyles}
                            value={{value: kategoria || EMPTY_VALUE.value, label: przedmiotZamowieniaKategoriaDict[kategoria] || EMPTY_VALUE.label}}
                            onChange={(selectedOption) => {
                                this.setState({kategoria: parseInt(selectedOption.value) || null, podkategoria: null});
                            }}
                        />
                        {kategoriaInvalid && <Text error>{errors.kategoria}</Text>}
                    </FieldWithLabel>
                    <FieldWithLabel
                        label="Podkategoria"
                        aria-label={kategoria !== null ? null : `Podkategoria, pole nieaktywne. Aby wybrać podkategorię, musisz wybrać kategorię przedmiotu zamówienia. ${errors.podkategoria || ''}`}
                        selectRef={React.createRef()}
                        tag="label"
                        tabIndex={kategoria !== null ? null : '0'}
                    >
                        <Select
                            className={((errors.podkategoria || '').length > 0) ? selectInvalidClassName : selectClassName}
                            indicatorId="pz_podkategoria_id"
                            isDisabled={isAlreadyPublished || kategoria === null}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                            options={kategoria === null ? [EMPTY_VALUE, ] : [EMPTY_VALUE, ].concat(
                                ...przedmiotZamowieniaKategoria.filter(k => parseInt(k.id) === parseInt(kategoria))[0].podkategorie.map(p => ({value: p.id, label: p.nazwa})))}
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            styles={customSelectStyles}
                            value={{value: podkategoria || EMPTY_VALUE.value, label: przedmiotZamowieniaPodkategoriaDict[podkategoria] || EMPTY_VALUE.label}}
                            onChange={(selectedOption) => this.setState({podkategoria: parseInt(selectedOption.value) || null})}
                        />
                        {(errors.podkategoria || '').length > 0 && <Text error>{errors.podkategoria}</Text>}
                    </FieldWithLabel>
                    <FieldWithLabel label="Okres gwarancji" tag="label" labelFor="okresGwarancji_id">
                        <TextFieldCustom
                            aria-valuemax={200}
                            clearFieldContext="okres gwarancji"
                            fullwidth
                            characterCount
                            maxLength={200}
                            value={okresGwarancji}
                            onChange={(event) => this.setState({okresGwarancji: event.target.value})}
                            onClear={(event) => this.setState({okresGwarancji: ''})}
                            id="okresGwarancji_id"
                            aria-label={`Okres gwarancji, maksymalna liczba znaków: 200. Wpisano ${okresGwarancji.length} znaków.`} />
                    </FieldWithLabel>
                </GridCustom>

                <FieldWithLabel label="Opis" tag="label" labelFor="pz_opis_id">
                    <TextFieldCustom
                        aria-describedby={(errors.opis || '').length > 0 ? 'pz_opis_error' : null}
                        textarea
                        fullwidth
                        maxLength={10000}
                        aria-valuemax={10000}
                        clearFieldContext="opis"
                        characterCount
                        invalid={(errors.opis || '').length > 0}
                        value={opis}
                        onChange={(event) => this.setState({opis: event.target.value})}
                        onClear={(ev) => this.setState({opis: ''})}
                        id="pz_opis_id"
                        aria-label={`Opis, maksymalna liczba znaków: 10000. Wpisano ${opis.length} znaków.`} />
                    {(errors.opis || '').length > 0 && <Text error id="pz_opis_error">{errors.opis}</Text>}
                </FieldWithLabel>
                <FieldWithLabel label="Kody CPV">
                    {(errors.kodyCPV || '').length > 0 && <Text error>{errors.kodyCPV}</Text>}
                    <KodyCPV
                        errorsKodyCPV={errors.kodyCPV}
                        kodyCPV={kodyCPV}
                        addKodCPV={this.handleAddKodCPV}
                        removeKodCPV={this.handleRemoveKodCPV} />
                </FieldWithLabel>
                <FieldWithLabel label="Miejsce realizacji">
                    {(errors.miejscaRealizacji || '').length > 0 && <Text error><span className="sr-only">Miejsce realizacji - </span>{errors.miejscaRealizacji}</Text>}
                    <MiejscaRealizacji
                        errorsKodyMiejscaRealizacji={errors.miejscaRealizacji}
                        miejscaRealizacji={miejscaRealizacji}
                        lastTemporaryId={this.props.miejsceRealizacjiLastTemporaryId}
                        removeMiejsceRealizacji={this.handleRemoveMiejsceRealizacji}
                        saveMiejsceRealizacji={this.handleSaveMiejsceRealizacji} />
                </FieldWithLabel>
                <GridCustom flexEnd>
                    <ButtonStyled cancel onClick={this.props.onCloseForm}>Anuluj</ButtonStyled>
                    <ButtonStyled
                        className="btn btn--save-main"
                        id="zapisz_przedmiotZamowienia_button_id"
                        save
                        onClick={this.handleSave}>
                        Zapisz przedmiot zamówienia
                    </ButtonStyled>
                </GridCustom>
            </>
        )
    }
}

FormPrzedmiotZamowienia.contextType = AdvertisementContext;


export { FormPrzedmiotZamowienia };
