import React from 'react';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';

import {
    PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT
} from '../../../helpers_permissions';


const OPERATION_TYPE_SERIALIZERS = {
    permissionsDenied: 'Odebrano uprawnienia',
    permissionsGranted: 'Przyznano uprawnienia',
    permissionsModified: 'Zmieniono zakres uprawnień',
};


export const PermissionsHistoryListRow = ({
    event, isImplementationLevelType, mode
}) => {
    return (
        <DataTableRow>
            <DataTableCell>{event.addedAt || ''}</DataTableCell>
            <DataTableCell>
                {mode === 'userHistory'
                    ? isImplementationLevelType
                        ? event.implementationLevelName
                        : (PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[event.context] ||
                            event.context)
                    : event.authorizedUserEmail
                }
            </DataTableCell>
            <DataTableCell>
                {OPERATION_TYPE_SERIALIZERS[event.operationType] ||
                    event.operationType}
            </DataTableCell>
            <DataTableCell>{(event.addedBy || {}).email || ''}</DataTableCell>
        </DataTableRow>
    )
};
