import React from 'react';

import { DialogCustom, FiltersButton, GridCustom, SortersSelect } from '../../common';


export const SortersFiltersRow = ({
        filters, filtersAreOn, FiltersComponent, onCloseFilters, onUpdateFilters, ...props}) => {
    return (
        <>
            <GridCustom className=" search__options">
                <SortersSelect {...props} />
                <FiltersButton {...props} />
            </GridCustom>
            {filtersAreOn && (
                <DialogCustom
                    className="dialog-long-content"
                    onClose={onCloseFilters}
                >
                    <FiltersComponent
                        filters={filters}
                        onCloseFilters={onCloseFilters}
                        onUpdateFilters={onUpdateFilters} />
                </DialogCustom>
            )}
        </>
    )
}
