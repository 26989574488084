import React, { useEffect, useState } from "react";
import { PomocEkranowa } from "../zarzadzanieTrescia";
import { Text } from "../../common";
import { Elevation, Switch } from "rmwc";
import {
  GridCustom,
  ButtonStyled,
  GridCellCustom,
  DialogCustom,
} from "../../common";
import { Badge } from "@rmwc/badge";
import "@rmwc/badge/badge.css";
import { NewsletterSettingsFilter } from "./NewsletterSettingsFilter";
import { generateUUIDV4 } from "../../../helpers";
import { fetchApi } from "../../../fetch";
import { Link } from "react-router-dom";
import { dateToString } from "../../../helpers";
import { Pagination } from "../../common";

export const NewsletterSettings = ({ isPreview, content }) => {
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(undefined);
  const [filterModalData, setFilterModalData] = useState({
    visible: false,
    filter: undefined,
  });
  const [filters, setFilters] = useState([]);
  const [advertisements, setAdvertisements] = useState([]);

  const yesterdayDate = dateToString(
    new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
  );

  const dayBeforeYesterdayDate = dateToString(
    new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 2)
  );

  const [advertisementsFilters, setAdvertisementsFilters] = useState({
    page: 1,
    total: 1,
    day: yesterdayDate,
    status: "new",
  });

  const [updatedCount, setUpdatedCount] = useState(0);
  const [newCount, setNewCount] = useState(0);
  const [isBusy, setIsBusy] = useState(false);

  const PAGE_LIMIT = 5;

  const refreshUpdatedAnnouncementsCount = () => {
    return new Promise((resolve, reject) => {
      fetchApi(
        `/api/announcements?mode=newsletter&page=1&limit=1&day=${advertisementsFilters.day}&newsletter_status=updated`,
        "GET",
        {},
        {},
        (announcements) => {
          setUpdatedCount(announcements.meta.total);
          resolve();
        },
        () => {
          reject();
        },
        () => {
          reject();
        }
      );
    });
  };

  const refreshNewAnnouncementsCount = () => {
    return new Promise((resolve, reject) => {
      fetchApi(
        `/api/announcements?mode=newsletter&page=1&limit=1&day=${advertisementsFilters.day}&newsletter_status=new`,
        "GET",
        {},
        {},
        (announcements) => {
          setNewCount(announcements.meta.total);
          resolve();
        },
        () => {
          reject();
        },
        () => {
          reject();
        }
      );
    });
  };

  const refreshFilters = () => {
    fetchApi(
      "/api/newsletter-filters",
      "GET",
      {},
      {},
      (filters) => {
        const { newsletter_filters } = filters;

        const mappedFilters = newsletter_filters.map((filter) => {
          const modalFilter = filter.modal_settings;

          return {
            id: generateUUIDV4(),
            name: buildFilterName(modalFilter),
            filter: { ...modalFilter, id: filter.id },
            filterByPropertiesCount: countFilters(modalFilter),
            enabled: filter.enabled,
          };
        });

        setFilters(mappedFilters);
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    refreshFilters();
  }, []);

  useEffect(() => {
    setIsBusy(true);

    var promise1 = refreshAnnouncements();
    var promise2 = refreshUpdatedAnnouncementsCount();
    var promise3 = refreshNewAnnouncementsCount();

    Promise.all([promise1, promise2, promise3]).then(() => {
      setIsBusy(false);
    });
  }, [
    advertisementsFilters.page,
    advertisementsFilters.status,
    advertisementsFilters.day,
  ]);

  const countFilters = (filter) => {
    const modalFilterProperties = Object.keys(filter)
      .map((key) => {
        if (Array.isArray(filter[key]) && filter[key].length > 0) {
          return filter[key];
        } else if (!Array.isArray(filter[key]) && filter[key] && key !== "id") {
          return filter[key];
        }
        return null;
      })
      .filter((x) => x);

    return modalFilterProperties.length;
  };

  const refreshAnnouncements = () => {
    const filters = advertisementsFilters;

    return new Promise((resolve, reject) => {
      fetchApi(
        `/api/announcements?mode=newsletter&page=${filters.page}&limit=${PAGE_LIMIT}&day=${filters.day}&newsletter_status=${filters.status}`,
        "GET",
        {},
        {},
        (announcements) => {
          setAdvertisements(announcements.advertisements);
          setAdvertisementsFilters({
            ...advertisementsFilters,
            total: announcements.meta.total,
          });

          if (filters.status === "new") {
            setNewCount(announcements.meta.total);
          } else if (filters.status === "updated") {
            setUpdatedCount(announcements.meta.total);
          }
          resolve();
        },
        () => {
          reject();
        },
        () => {
          reject();
        }
      );
    });
  };

  const addFilter = (filter) => {
    if (Object.keys(filter)?.length > 0) {
      mapAndSaveFilter(null, filter, () => {
        refreshFilters();
        refreshAnnouncements();
        refreshUpdatedAnnouncementsCount();
        refreshNewAnnouncementsCount();
      });
    }
  };

  const editFilter = (id, filter) => {
    mapAndSaveFilter(filter.id, filter, () => {
      const updatedFilters = filters.map((existingFilter) => {
        if (existingFilter.id === id) {
          return {
            id: id,
            name: buildFilterName(filter),
            filter: filter,
            filterByPropertiesCount: countFilters(filter),
          };
        } else {
          return existingFilter;
        }
      });

      setFilters(updatedFilters);
      refreshAnnouncements();
      refreshUpdatedAnnouncementsCount();
    });
  };

  const mapAndSaveFilter = (id, filter, onSuccess) => {
    const filterToSave = {
      advertiser_identification_number: filter.advertiserIdentificationNumber,
      programme_ids: filter.programme?.map((x) => x.value) ?? [],
      category_id: filter.category?.value,
      subcategory_id: filter.subcategory?.value,
      cpv_item_ids: filter.cpvItem?.map((x) => x.id) ?? [],
      fulfillment_place_codes:
        filter.fulfillmentPlaces?.map((x) => x.value) ?? [],
    };

    const requestUrl = id
      ? `/api/newsletter-filters/${id}`
      : `/api/newsletter-filters`;

    const requestMethod = id ? "PUT" : "POST";

    fetchApi(
      requestUrl,
      requestMethod,
      {},
      filterToSave,
      () => onSuccess(),
      () => {},
      () => {}
    );
  };

  const enableDisableFilter = (checked, filter) => {
    fetchApi(
      `/api/newsletter-filters/${filter.filter.id}/enabled`,
      "PUT",
      {},
      { enabled: checked },
      () => {
        const updatedFilters = [
          ...filters.map((x) => {
            if (x.id === filter.id) {
              x.enabled = checked;
            }

            return x;
          }),
        ];
        setFilters(updatedFilters);
        refreshAnnouncements();
        refreshUpdatedAnnouncementsCount();
        refreshNewAnnouncementsCount();
      },
      () => {},
      () => {}
    );
  };

  const deleteFilter = (filter) => {
    fetchApi(
      `/api/newsletter-filters/${filter.filter.id}`,
      "DELETE",
      {},
      {},
      () => {
        const newFilters = filters.filter((x) => x.id !== filter.id);
        setFilters(newFilters);
        refreshAnnouncements();
        refreshUpdatedAnnouncementsCount();
        refreshNewAnnouncementsCount();
        setShowDeleteFilterModal(undefined);
      },
      () => {},
      () => {}
    );
  };

  const buildFilterName = (filter) => {
    const { id, ...rest } = filter;

    const propValues = Object.values(rest)
      .filter((x) => x)
      .map((value) => {
        if (Array.isArray(value)) {
          return value
            .map((item) => {
              if (item.hasOwnProperty("label")) {
                return item.label;
              } else {
                return `${item.kod} ${item.nazwa}`;
              }
            })
            .join(", ");
        } else if (typeof value === "object") {
          return value?.label;
        } else {
          return value;
        }
      });
    return propValues.filter((x) => x).join("; ");
  };

  const handleChangePage = (next = true) => {
    if (next) {
      setAdvertisementsFilters({
        ...advertisementsFilters,
        page: advertisementsFilters.page + 1,
      });
    } else {
      if (advertisementsFilters.page > 1) {
        setAdvertisementsFilters({
          ...advertisementsFilters,
          page: advertisementsFilters.page - 1,
        });
      }
    }
  };

  const handleAddToFavorites = (object) => {
    fetchApi(
      `/api/favorite-announcements/${object.advertisement.id}`,
      "POST",
      {},
      {},
      () => {
        const index = advertisements.findIndex((obj) => obj.id === object.id);
        const updatedObjects = [...advertisements];
        updatedObjects[index] = { ...updatedObjects[index], favorite: true };
        setAdvertisements(updatedObjects);
      }
    );
  };

  const handleRemoveFromFavorites = (object) => {
    fetchApi(
      `/api/favorite-announcements/${object.advertisement.id}`,
      "DELETE",
      {},
      {},
      () => {
        const index = advertisements.findIndex((obj) => obj.id === object.id);
        const updatedObjects = [...advertisements];
        updatedObjects[index] = { ...updatedObjects[index], favorite: false };
        setAdvertisements(updatedObjects);
      }
    );
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <PomocEkranowa
          ekran="newsletter_settings"
          content={isPreview ? content : null}
        />
        <Text tag="h2" mainHeader>
          Ustawienia newslettera
        </Text>

        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {filters.map((filter, index) => {
            return (
              <Elevation z={4} style={{ padding: 10 }} key={index}>
                <GridCustom
                  centerVertical
                  fullwidth
                  className="newsletter-settings"
                >
                  <Switch
                    aria-label={`Włącz filtry dla ${filter.name}`}
                    id={`filters_${index}`}
                    checked={filter.enabled}
                    onChange={(ev) =>
                      enableDisableFilter(ev.target.checked, filter)
                    }
                  >
                    {filter.name}
                  </Switch>
                  <GridCustom
                    noWrap
                    className="newsletter-settings-filter-buttons"
                  >
                    <ButtonStyled
                      icon="list"
                      lite
                      onClick={() => {
                        setFilterModalData({
                          visible: true,
                          filter: filter.filter,
                          id: filter.id,
                        });
                      }}
                    >
                      EDYTUJ
                      <Badge
                        className="bluebadge"
                        label={filter.filterByPropertiesCount}
                        align="inline"
                      />
                    </ButtonStyled>

                    <ButtonStyled
                      remove
                      icon="delete"
                      onClick={() => {
                        setShowDeleteFilterModal(filter);
                      }}
                    >
                      USUŃ
                    </ButtonStyled>
                  </GridCustom>
                </GridCustom>
              </Elevation>
            );
          })}
        </div>
        <ButtonStyled
          icon="add"
          lite
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setFilterModalData({ visible: true, filter: undefined });
          }}
        >
          Dodaj
        </ButtonStyled>
        <Text tag="h2" mainHeader>
          Wyniki
        </Text>

        <GridCustom centerVertical centerHorizontal>
          <p>Data opublikowania:</p>
          <ButtonStyled
            primary={advertisementsFilters.day === dayBeforeYesterdayDate}
            lite={advertisementsFilters.day !== dayBeforeYesterdayDate}
            disabled={isBusy}
            onClick={() => {
              setAdvertisementsFilters({
                ...advertisementsFilters,
                day: dayBeforeYesterdayDate,
                page: 1,
              });
            }}
          >
            {dayBeforeYesterdayDate}
          </ButtonStyled>
          <ButtonStyled
            primary={advertisementsFilters.day === yesterdayDate}
            lite={advertisementsFilters.day !== yesterdayDate}
            disabled={isBusy}
            onClick={() => {
              setAdvertisementsFilters({
                ...advertisementsFilters,
                day: yesterdayDate,
                page: 1,
              });
            }}
          >
            {yesterdayDate}
          </ButtonStyled>
        </GridCustom>

        <GridCustom centerVertical centerHorizontal>
          <ButtonStyled
            primary={advertisementsFilters.status === "new"}
            lite={advertisementsFilters.status !== "new"}
            disabled={isBusy}
            onClick={() => {
              setAdvertisementsFilters({
                ...advertisementsFilters,
                status: "new",
                page: 1,
              });
            }}
          >
            NOWE ({newCount} OGŁOSZEŃ)
          </ButtonStyled>
          <ButtonStyled
            primary={advertisementsFilters.status === "updated"}
            lite={advertisementsFilters.status !== "updated"}
            disabled={isBusy}
            onClick={() => {
              setAdvertisementsFilters({
                ...advertisementsFilters,
                status: "updated",
                page: 1,
              });
            }}
          >
            ZAKTUALIZOWANE ({updatedCount} OGŁOSZEŃ)
          </ButtonStyled>
        </GridCustom>

        <ul style={{ listStyleType: "none" }}>
          {advertisements.map((advertisement) => {
            return (
              <li
                key={advertisement.id}
                className="separate-content search__item"
              >
                <GridCustom fullwidth key={advertisement.advertisement.id}>
                  <GridCellCustom>
                    <Link
                      to={`/ogloszenia/${advertisement.advertisement.id}`}
                      className="link-text"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: advertisement.title,
                        }}
                      ></span>
                    </Link>
                    {advertisement.is_mine && (
                      <span className="author-label">Moje ogłoszenie</span>
                    )}
                  </GridCellCustom>
                  {!advertisement.is_mine && (
                    <GridCellCustom style={{ minWidth: "255px" }}>
                      {advertisement.favorite === false && (
                        <ButtonStyled
                          onClick={() => handleAddToFavorites(advertisement)}
                          primary
                          icon="favorite"
                        >
                          DODAJ DO ULUBIONYCH
                          <span className="sr-only">
                            ogłoszenie {advertisement.title}
                          </span>
                        </ButtonStyled>
                      )}
                      {advertisement.favorite === true && (
                        <ButtonStyled
                          onClick={() =>
                            handleRemoveFromFavorites(advertisement)
                          }
                          remove
                          icon="close"
                        >
                          USUŃ Z ULUBIONYCH
                          <span className="sr-only">
                            {" "}
                            ogłoszenie {advertisement.title}
                          </span>
                        </ButtonStyled>
                      )}
                    </GridCellCustom>
                  )}
                </GridCustom>

                <p
                  dangerouslySetInnerHTML={{
                    __html: advertisement.description,
                  }}
                ></p>
                <GridCustom flexM fullwidth>
                  <Text info className="search__results-meta">
                    Opublikowano: {advertisement.publication_date}
                  </Text>
                  <Text info className="search__results-meta">
                    Termin składania ofert: {advertisement.submission_deadline}
                  </Text>
                  <Text
                    info
                    className="search__results-meta search__results-meta--company-name"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: advertisement?.advertiser_address_details?.name,
                      }}
                    ></span>
                  </Text>
                  {(advertisement.fulfillment_place || "").length > 0 && (
                    <Text info className="search__results-meta">
                      {advertisement.fulfillment_place}
                    </Text>
                  )}
                </GridCustom>
              </li>
            );
          })}
        </ul>
        <Pagination
          currentPage={advertisementsFilters.page}
          hasNext={
            advertisementsFilters.page * PAGE_LIMIT <
            advertisementsFilters.total
          }
          hasPrev={advertisementsFilters.page > 1}
          handleChangePage={handleChangePage}
        />
      </div>
      {filterModalData.visible && (
        <NewsletterSettingsFilter
          filter={filterModalData.filter}
          save={(filter) => {
            if (filterModalData.filter) {
              editFilter(filterModalData.id, filter);
            } else {
              addFilter(filter);
            }

            setFilterModalData({ visible: false, filter: undefined });
          }}
          close={() => {
            setFilterModalData({ visible: false, filter: undefined });
          }}
        />
      )}
      {showDeleteFilterModal && (
        <DialogCustom
          className="dialog"
          dialogTitle="Czy na pewno chcesz usunąć filtr newslettera?"
          onClose={() => {
            setShowDeleteFilterModal(undefined);
          }}
        >
          <GridCustom centerVertical flexEnd>
            <ButtonStyled
              onClick={() => {
                setShowDeleteFilterModal(undefined);
              }}
              cancel
            >
              Anuluj
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                deleteFilter(showDeleteFilterModal);
              }}
              remove
            >
              Usuń
            </ButtonStyled>
          </GridCustom>
        </DialogCustom>
      )}
    </>
  );
};
