import React, { useContext, useState } from 'react';
import { Checkbox } from '@rmwc/checkbox';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import { usePutFormFetchApi } from '../../../hooks';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';

import {
    ButtonStyled,
    Container,
    GridCustom,
    FieldWithLabel,
    Text
} from '../../common';


export const UserDataForm = ({ user, onUpdateUserData }) => {
    const [loadingOn, setLoadingOn] = useState(false);
    const [blockedAccount, setBlockedAccount] = useState(user.kontoZablokowane);
    const [originalBlockedAccount, setOriginalBlockedAccount] = useState(
        user.kontoZablokowane);

    const { permissions, removePermission } = useContext(UserContext);

    function handleFetchSuccess(data) {
        setLoadingOn(false);
        setOriginalBlockedAccount(blockedAccount);
        onUpdateUserData(getSerializedObject(data).uzytkownik);
    }

    const { id: id_ } = useParams();
    const { fetchError, errors, saveBtnText } = usePutFormFetchApi({
        url: API_URLS.usersDetails.getUrl(id_),
        fetchData: getSerializedObject(
            {kontoZablokowane: blockedAccount}, {toServer: true}),
        callbackSuccess: handleFetchSuccess,
        loadingOn,
        setLoadingOn,
        message403error: 'Nie masz uprawnień do blokowania użytkowników',
        callback403: () => removePermission('blockingUsers'),
    });

    const blockedAccountsErrors = errors.kontoZablokowane || [];
    const hasBlockingUserPermission = permissions.blockingUsers;
    return (
        <Container>
            <Text tag="h2" mainHeader>Blokada profilu</Text>
            <GridCustom fullwidth>
                <FieldWithLabel label="Stan">
                    {hasBlockingUserPermission
                        ? (
                            <>
                                <Checkbox
                                    checked={blockedAccount}
                                    disabled={loadingOn}
                                    label="Zablokuj użytkownika"
                                    onChange={(ev) => setBlockedAccount(ev.target.checked)}
                                />
                                {blockedAccountsErrors.length > 0 && blockedAccountsErrors.map(
                                    (e, i) => <Text error key={i}>{e}</Text>
                                )}
                            </>
                        )
                        : (
                            <Text>
                                Profil {originalBlockedAccount ? 'zablokowany' : 'aktywny'}
                            </Text>
                        )
                    }
                </FieldWithLabel>
                <FieldWithLabel label="Data zablokowania konta">
                    <Text>{user.dataZablokowaniaKonta || '---'}</Text>
                </FieldWithLabel>
            </GridCustom>
            {hasBlockingUserPermission && (
                <>
                    {blockedAccount !== originalBlockedAccount && (
                        <>
                            <Text info>Upewnij się, że na pewno chcesz zapisać zmiany.</Text>
                            <Text info>Zmieniasz wartość pola "Zablokuj użytkownika".</Text>
                        </>
                    )}
                    <GridCustom flexEnd>
                        <ButtonStyled
                            disabled={loadingOn}
                            primary
                            onClick={() => setLoadingOn(true)}>
                            {saveBtnText}
                        </ButtonStyled>
                    </GridCustom>
                </>
            )}
            {fetchError !== null && <Text error>{fetchError}</Text>}
        </Container>
    )
};
