import React, { Component } from 'react';

import { AdvertisementConsumer } from '../../../context/advertisementContext';
import { ButtonStyled, DialogCustom, GridCustom, FieldWithLabel, Select, Text, TextFieldCustom } from '../../common';
import { customSelectStyles } from '../../vars/vars';


const EMPTY_VALUE = {
    value: 0,
    label: '--- Wybierz ---',
}


class FormWarunekUdzialu extends Component{

    constructor(props) {
        super(props);

        let stateDict = {
            opisError: '',
            typError: '',
            isValidating: false,
        }
        if (props.isNew) {
            this.state = Object.assign(stateDict, props.warunekUdzialu, {
                typ: null,
                opis: '',
            });
        } else {
            this.state = Object.assign(stateDict, props.warunekUdzialu);
        }
    }

    // basic functions

    componentDidMount() {
        window.formOgloszenie.computeProgress();
    }

    componentDidUpdate(prevProps, prevState) {
        if (window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }

    // handlers

    handleChangeTyp(value) {
        this.setState({typ: parseInt(value) || null});
    }

    handleSave = (clearFunct) => {
        if (!this.validate(clearFunct)) { return }
        const {isNew, warunekUdzialu} = this.props;

        this.props.onSave({
            id: isNew ? null : warunekUdzialu.id,
            temporaryId: warunekUdzialu.temporaryId,
            przedmiotZamowieniaTemporaryId: this.props.przedmiotZamowieniaTemporaryId,
            typ: this.state.typ,
            opis: this.state.opis.trim(),
        }, isNew);
    }

    handleCloseDialog = () => {
        this.props.onCloseForm();
    }

    // helpers

    mergeErrors(globalErrors) {
        // copy global errors
        let errors = Object.assign({}, globalErrors);

        // get state errors
        for (let name of ['typ', 'opis']) {
            const error = this.state[`${name}Error`];
            if (error.length) {
                errors[name] = error;
            }
        }

        return errors
    }

    validate(clearFunct) {
        // clean errors
        const temporaryId = this.props.warunekUdzialu.temporaryId;
        clearFunct('warunkiUdzialu', temporaryId, 'opis')
        clearFunct('warunkiUdzialu', temporaryId, 'typ')
        this.setState({isValidating: true, opisError: '', typError: ''});

        let isValid = true;
        if (this.state.typ === null) {
            this.setState({typError: 'To pole jest wymagane.'});
            isValid = false;
        }
        if (!this.state.opis.trim()) {
            this.setState({opisError: 'To pole jest wymagane.'});
            isValid = false;
        }
        this.setState({isValidating: false});
        return isValid
    }

    // rendering

    render() {
        const isValidating = this.state.isValidating;
        return (
            <AdvertisementConsumer>
                {({clearAttributeError, formErrors, typWarunku}) => {
                    const temporaryId = this.props.warunekUdzialu.temporaryId;
                    const errors = this.mergeErrors(formErrors.warunkiUdzialu[temporaryId]);
                    const selectClassName = 'select-custom';
                    const selectInvalidClassName = 'select-custom select-custom--invalid';
                    const errorsOpisVisible = (errors.opis || '').length > 0;
                    const errorsTypVisible = (errors.typ || '').length > 0;

                    return (
                        <DialogCustom 
                            className="dialog"
                            dialogTitle="Dodaj Warunek udziału"
                            onClose={this.handleCloseDialog}
                        >
                            <>
                                <FieldWithLabel label="Typ wymagania" tag="label" selectRef={React.createRef()}>
                                    <Select
                                        aria-label={`Typ wymagania ${errors.typ || ''}`}
                                        aria-describedby={errorsTypVisible ? 'typ_error' : null}
                                        screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                        className={errorsTypVisible ? selectInvalidClassName : selectClassName}
                                        isDisabled={isValidating}
                                        aria-required={true}
                                        options={[EMPTY_VALUE, ].concat(...Object.entries(typWarunku).filter(([id, _]) => !this.props.usedWarunkiUdzialuIds.includes(parseInt(id))).sort().map(([key, value]) => ({value: key, label: value})))}
                                        value={{value: this.state.typ || EMPTY_VALUE.value, label: typWarunku[this.state.typ] || EMPTY_VALUE.label}}
                                        onChange={(selectedOption) => this.handleChangeTyp(selectedOption.value)}
                                        styles={customSelectStyles}
                                        noOptionsMessage={() => 'Brak wybranej opcji'}
                                    />
                                    {errorsTypVisible && <Text id="typ_error" error>{errors.typ}</Text>}
                                </FieldWithLabel>
                                <FieldWithLabel label="Opis wymagania" tag="label" labelFor="warunekUdzialu_opis_id">
                                    <TextFieldCustom
                                        aria-describedby={errorsOpisVisible ? 'opis_error' : null}
                                        clearFieldContext="opis wymagania"
                                        disabled={isValidating}
                                        textarea
                                        fullwidth
                                        invalid={errorsOpisVisible}
                                        maxLength={6000}
                                        characterCount
                                        value={this.state.opis}
                                        onChange={(event) => this.setState({opis: event.target.value})}
                                        onClear={(ev) => this.setState({opis: ''})}
                                        aria-required={true}
                                        id="warunekUdzialu_opis_id"
                                        aria-label={`Opis wymagania, maksymalna liczba znaków: 6000. Wpisano ${this.state.opis.length} znaków.`}
                                    />
                                    {errorsOpisVisible && <Text error id="opis_error">{errors.opis}</Text>}
                                </FieldWithLabel>
                                <GridCustom flexEnd>
                                    <ButtonStyled
                                        cancel
                                        disabled={isValidating}
                                        onClick={this.handleCloseDialog} >Anuluj</ButtonStyled>
                                    <ButtonStyled
                                        aria-label={this.props.isNew ? 'Dodaj warunek udziału' : 'Zapisz zmiany w warunku udziału'}
                                        disabled={isValidating}
                                        save
                                        onClick={() => this.handleSave(clearAttributeError)} >
                                        {this.props.isNew ? 'Dodaj' : 'Zapisz zmiany'}
                                    </ButtonStyled>
                                </GridCustom>
                                {(errors.typ || errors.opis || '').length > 0 && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
                            </>
                        </DialogCustom>
                    )
                }}
            </AdvertisementConsumer>
        )
    }
}


export { FormWarunekUdzialu };
