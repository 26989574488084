import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';

import { dateStringToYearMonth, isNotEmpty, splitLineByChar } from '../../../helpers';
import { PdfHeader } from '../../common';
import { pdfStyles } from '../../vars/pdfStyles';
import { OgloszenieDetails, TYP_NUMERU_IDENTYFIKACYJNEGO } from './OgloszenieDetails';


class OgloszeniePdf extends OgloszenieDetails {

    // base functions

    componentDidMount() {
        // it's redefined, because we don't want to scroll page and change its
        // title, like in base class
    }

    // rendering

    render() {
        const {
            flexContainerStyle,
            labelStyle,
            textWithLabelStyle,
            mainPdfContainerStyle,
            sectionTitleStyle,
            titleStyle
        } = pdfStyles;

        const o = this.props.ogloszenie;
        const nabor = o.ogloszenie.nabor;
        const title = `${parseInt(this.props.roboczeStatusId) === parseInt(o.status.id) ? 'Wersja robocza ogłoszenia: ' : ''}${o.tytul}`;
        const showVersionModeInfo = this.props.versionMode && o.status.label !== 'DRAFT' && o.ogloszenie.ostatniaOpublikowanaWersjaId !== o.id;
        return (
            <Document
                title={title}
                creator='Wygenerowano za pośrednictwem serwisu Baza Konkurencyjności.' >
                <Page size="A4" style={mainPdfContainerStyle} wrap>
                    <PdfHeader title={title} />

                    {showVersionModeInfo && <Text style={labelStyle}>To jest nieaktualna wersja ogłoszenia. Ta wersja została opublikowana dnia {o.dataOpublikowania}.</Text>}
                    <Text style={sectionTitleStyle}>Ogłoszenie</Text>
                    <View style={flexContainerStyle}>
                        {isNotEmpty(o.ogloszenie.numer) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Numer</Text>
                                <Text>{o.ogloszenie.numer}</Text>
                            </View>
                        )}
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Id</Text>
                            <Text>{o.ogloszenie.id}</Text>
                        </View>
                    </View>
                    {isNotEmpty(o.ogloszenie.projekty, 'list') && (
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Powstaje w kontekście projektu</Text>
                            {o.ogloszenie.projekty.map(p => <Text key={p.id}>{p.calyNumer} - {p.nazwa}</Text>)}
                        </View>
                    )}
                    {!!nabor && (
                       <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Powstaje w kontekście naboru</Text>
                            <Text>{nabor.calyNumer}{nabor.nazwa.length > 0 ? ` - ${nabor.nazwa}` : ''}</Text>
                        </View>
                    )}
                    {isNotEmpty(o.tytul) && (
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Tytuł</Text>
                            {splitLineByChar(o.tytul).map((line, i) => <Text key={i} style={titleStyle}>{line}</Text>)}
                        </View>
                    )}
                    {isNotEmpty(o.zamowieniaUzupelniajace) && (
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Zamówienia uzupełniające</Text>
                            {splitLineByChar(o.zamowieniaUzupelniajace).map((line, i) => <Text key={i}>{line}</Text>)}
                        </View>
                    )}
                    {isNotEmpty(o.warunkiZmianyUmowy) && (
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Warunki zmiany umowy</Text>
                            {splitLineByChar(o.warunkiZmianyUmowy).map((line, i) => <Text key={i}>{line}</Text>)}
                        </View>
                    )}
                    {isNotEmpty(o.zalaczniki, 'list') && (
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Załączniki</Text>
                            {/* attachments are sorted by origin announcement version */}
                            {o.zalaczniki.map(origin => this.renderOrigin(origin))}
                        </View>
                    )}
                    <View style={textWithLabelStyle}>
                        <Text style={labelStyle}>Czy dopuszczalna oferta częściowa?</Text>
                        <Text>{o.czyDopuszczalnaOfertaCzesciowa === true ? 'TAK' : 'NIE'}</Text>
                    </View>
                    <View style={flexContainerStyle}>
                        {this.renderPublishedDates(o)}
                    </View>
                    <View style={flexContainerStyle}>
                        {isNotEmpty(o.terminOfert) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Termin składania ofert</Text>
                                <Text>{o.terminOfert}</Text>
                            </View>
                        )}
                        {isNotEmpty(o.planowanyTerminPodpisaniaUmowy) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Planowany termin podpisania umowy</Text>
                                <Text>{dateStringToYearMonth(o.planowanyTerminPodpisaniaUmowy)}</Text>
                            </View>
                        )}
                    </View>
                    {this.renderDaneAdresoweOgloszeniodawcy()}
                    {isNotEmpty(o.osobyDoKontaktu, 'list') && this.renderOsobyDoKontaktu()}
                    <Text style={sectionTitleStyle}>Części zamówienia</Text>
                    {this.renderZamowienia()}
                    <Text style={sectionTitleStyle}>Podsumowanie</Text>
                    {this.renderPodsumowanie()}
                </Page>
              </Document>
        )
    }

    renderAttachmentsForOrigin(origin, label) {
        return (
            <View key={origin.id}>
                <Text>Dodane do ogłoszenia w {label}</Text>
                {origin.zalaczniki.map((z, i) => {
                    if (z.nazwa.length ||  Object.keys(z.plik).length) {
                        return <Text key={z.id}>{i + 1}. {z.nazwa || 'Załącznik bez nazwy'}</Text>
                    } else {return null}
                })}
            </View>
        )
    }

    renderPublishedDates(ogloszenie) {
        if (this.props.draftMode) { return null }
        const {
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;
        if (this.props.versionMode && isNotEmpty(ogloszenie.dataOpublikowania)) {
            return (
                <View style={textWithLabelStyle}>
                    <Text style={labelStyle}>Data opublikowania wersji</Text>
                    <Text>{ogloszenie.dataOpublikowania}</Text>
                </View>
            )
        }
        return (
            <>
                {isNotEmpty(ogloszenie.ogloszenie.dataOpublikowania) && (
                    <View style={textWithLabelStyle}>
                        <Text style={labelStyle}>Data opublikowania ogłoszenia</Text>
                        <Text>{ogloszenie.ogloszenie.dataOpublikowania}</Text>
                    </View>
                )}
                {isNotEmpty(ogloszenie.dataOpublikowania) && (
                    <View style={textWithLabelStyle}>
                        <Text style={labelStyle}>Data ostatniej zmiany</Text>
                        <Text>{ogloszenie.dataOpublikowania}</Text>
                    </View>
                )}
            </>
        )
    }

    renderDaneAdresoweOgloszeniodawcy() {
        if (!this.props.ogloszenie.daneAdresoweOgloszeniodawcy) {
            return null
        }
        const {
            borderedSectionStyle,
            gridSection,
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        const daneAdresowe = this.props.ogloszenie.daneAdresoweOgloszeniodawcy || {};
        const adres = daneAdresowe.adres || {};
        return (
            <View style={textWithLabelStyle} wrap={false}>
                <Text style={labelStyle}>Dane adresowe ogłoszeniodawcy</Text>
                <View style={[borderedSectionStyle, gridSection]}>
                    <Text>{daneAdresowe.nazwa}</Text>
                    <Text>{adres.ulica} {adres.numerDomu}</Text>
                    <Text>{adres.kodPocztowy} {adres.miejscowosc}</Text>
                    {adres.country === 'Polska' && (
                        <>
                            <Text>gmina/dzielnica: {adres.gmina}</Text>
                            <Text>powiat: {adres.powiat}</Text>
                            <Text>woj.: {adres.wojewodztwo}</Text>
                        </>
                    )}

                    {adres.kraj !== 'Polska' && (
                        <Text>{adres.kraj}</Text>
                    )}
                    <Text>{TYP_NUMERU_IDENTYFIKACYJNEGO[daneAdresowe.typNumeruIdentyfikacyjnego]}: {daneAdresowe.numerIdentyfikacyjny}</Text>
                </View>
            </View>
        )
    }

    renderOsobyDoKontaktu() {
        const {
            borderedSectionStyle,
            flexContainerStyle,
            gridSection,
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        return (
            <View style={textWithLabelStyle} wrap={false}>
                <Text style={labelStyle}>Osoby do kontaktu</Text>
                <View style={[flexContainerStyle, {flexWrap: 'wrap'}]}>
                    {this.props.ogloszenie.osobyDoKontaktu.map(osoba => {
                        return (
                            <View key={osoba.id} style={[borderedSectionStyle, gridSection]}>
                                <Text>{osoba.imie} {osoba.nazwisko}</Text>
                                <Text>tel.: {osoba.numerTelefonu || '-'}</Text>
                                <Text>e-mail: {osoba.email}</Text>
                            </View>
                        )
                    })}
                </View>
            </View>
        )
    }

    renderZamowienia() {
        const o = this.props.ogloszenie;
        if (!isNotEmpty(o.zamowienia)) {
            const {
                textInfoStyle,
                errorStyle,
            } = pdfStyles;
            return (
                <Text style={[textInfoStyle, errorStyle]}>Brak zdefiniowanych części zamówienia</Text>
            )
        }
        const {
            labelStyle,
            sectionTitleStyle,
            textWithLabelStyle,
            titleStyle
        } = pdfStyles;

        return (
            <View>
                {o.zamowienia.map((z, i) => (
                    <View key={z.id}>
                        <Text style={sectionTitleStyle}>Część: {i + 1}</Text>
                        {isNotEmpty(z.tytul) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Tytuł części {i + 1}</Text>
                                <Text style={titleStyle}>{z.tytul}</Text>
                            </View>
                        )}
                        {isNotEmpty(z.szacunkowaWartosc) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Budżet części {i + 1}</Text>
                                <Text style={titleStyle}>{z.szacunkowaWartosc.replace('.', ',')} PLN</Text>
                            </View>
                        )}
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Czy dopuszczalne oferty wariantowe</Text>
                            <Text>{z.czyWariantowe === true ? 'TAK' : 'NIE'}</Text>
                        </View>
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Przedmioty zamówienia do części {i + 1}</Text>
                            {this.renderPrzedmiotyZamowienia(z.przedmiotyZamowienia)}
                        </View>
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Kryteria oceny do części {i + 1}</Text>
                            {this.renderKryteriaOceny(z.kryteriaOceny)}
                        </View>
                    </View>
                ))}
            </View>
        )
    }

    renderPrzedmiotyZamowienia(przedmiotyZamowienia) {
         if (isNotEmpty(przedmiotyZamowienia, 'list')) {
            return przedmiotyZamowienia.map((pz) => this.renderPrzedmiotZamowienia(pz))
        }
        const {
            errorStyle,
            textInfoStyle,
        } = pdfStyles;
        return (
            <Text style={[textInfoStyle, errorStyle]}>Brak zdefiniowanych przedmiotów zamówienia</Text>
        )
    }

    renderPrzedmiotZamowienia(przedmiotZamowienia) {
        const {
            borderedSectionStyle,
            labelStyle,
            pzTitleSection,
            textWithLabelStyle,
        } = pdfStyles;

        return (
            <View key={przedmiotZamowienia.id} style={borderedSectionStyle}>
                <View style={pzTitleSection}>
                    <View style={textWithLabelStyle}>
                        <Text style={labelStyle}>Typ</Text>
                        <Text>{przedmiotZamowienia.kategoria.nazwa || '-'}</Text>
                    </View>
                    <View style={textWithLabelStyle}>
                        <Text style={labelStyle}>Podkategoria</Text>
                        <Text>{przedmiotZamowienia.podkategoria.nazwa || '-'}</Text>
                    </View>
                </View>
                <View style={textWithLabelStyle}>
                    {isNotEmpty(przedmiotZamowienia.opis) && (
                        <>
                            <Text style={labelStyle}>Opis</Text>
                            {splitLineByChar(przedmiotZamowienia.opis).map((line, i) => <Text key={i}>{line}</Text>)}
                        </>
                    )}
                </View>
                <View style={textWithLabelStyle}>
                    {isNotEmpty(przedmiotZamowienia.okresGwarancji) && (
                        <>
                            <Text style={labelStyle}>Okres gwarancji</Text>
                            <Text>{przedmiotZamowienia.okresGwarancji}</Text>
                        </>
                    )}
                </View>
                {isNotEmpty(przedmiotZamowienia.kodyCPV, 'list') && this.renderKodyCPV(przedmiotZamowienia.kodyCPV)}
                {isNotEmpty(przedmiotZamowienia.miejscaRealizacji, 'list') && this.renderMiejscaRealizacji(przedmiotZamowienia.miejscaRealizacji)}
                {isNotEmpty(przedmiotZamowienia.harmonogramy, 'list') && this.renderHarmonogram(przedmiotZamowienia.harmonogramy)}
                {isNotEmpty(przedmiotZamowienia.warunkiUdzialu, 'list') && this.renderWarunkiUdziału(przedmiotZamowienia.warunkiUdzialu)}
            </View>
        )
    }

    renderKodyCPV(kodyCPV) {
        const {
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        return (
            <View style={textWithLabelStyle}>
                <Text style={labelStyle}>Kody CPV</Text>
                {kodyCPV.map(kod_ =>  <Text key={kod_.id}>{kod_.kod} {kod_.nazwa}</Text>)}
            </View>
        )
    }

    renderMiejscaRealizacji(miejscaRealizacji) {
        const {
            flexContainerStyle,
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        return (
            <View style={textWithLabelStyle}>
                <Text style={labelStyle}>Miejsca realizacji</Text>
                {miejscaRealizacji.map(mr => {
                    let address = null;
                    if (mr.kraj || mr.wojewodztwo || mr.powiat || mr.gmina || mr.miejscowosc) {
                        address = (
                            <View style={flexContainerStyle}>
                                {isNotEmpty(mr.kraj) && (
                                    <View style={textWithLabelStyle}>
                                        <Text style={labelStyle}>Kraj</Text>
                                        <Text>{mr.kraj}</Text>
                                    </View>
                                )}
                                {isNotEmpty(mr.wojewodztwo) && (
                                    <View style={textWithLabelStyle}>
                                        <Text style={labelStyle}>Województwo</Text>
                                        <Text>{mr.wojewodztwo}</Text>
                                    </View>
                                )}
                                {isNotEmpty(mr.powiat) && (
                                    <View style={textWithLabelStyle}>
                                        <Text style={labelStyle}>Powiat</Text>
                                        <Text>{mr.powiat || '-'}</Text>
                                    </View>
                                )}
                                {isNotEmpty(mr.gmina) && (
                                    <View style={textWithLabelStyle}>
                                        <Text style={labelStyle}>Gmina</Text>
                                        <Text>{mr.gmina || '-'}</Text>
                                    </View>
                                )}
                                {isNotEmpty(mr.miejscowosc) && (
                                    <View>
                                        <Text style={labelStyle}>Miejscowość</Text>
                                        <Text>{mr.miejscowosc || '-'}</Text>
                                    </View>
                                )}
                            </View>
                        );
                    }
                    return (
                        <View key={mr.id}>
                            <Text style={labelStyle}>{mr.typ.nazwa}</Text>
                            {address}
                        </View>
                    )
                })}
            </View>
        )
    }

    renderHarmonogram(harmonogramy) {
        const {
            borderedSeparateSectionStyle,
            flexContainerStyle,
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        let i = 0;
        return (
            <>
                <Text style={labelStyle}>Harmonogram</Text>
                {harmonogramy.map((h) => {
                    i++;
                    return (
                        <View key={h.id} style={borderedSeparateSectionStyle}>
                            <Text style={labelStyle}>Etap {i}</Text>
                            <View style={flexContainerStyle}>
                                {isNotEmpty(h.poczatekRealizacji) && (
                                    <View style={textWithLabelStyle}>
                                        <Text style={labelStyle}>Początek realizacji</Text>
                                        <Text>{h.poczatekRealizacji}</Text>
                                    </View>
                                )}
                                {isNotEmpty(h.koniecRealizacji) && (
                                    <View style={textWithLabelStyle}>
                                        <Text style={labelStyle}>Koniec realizacji</Text>
                                        <Text>{h.koniecRealizacji}</Text>
                                    </View>
                                )}
                            </View>
                            {isNotEmpty(h.opis) && (
                                <View style={textWithLabelStyle}>
                                    <Text style={labelStyle}>Opis</Text>
                                    {splitLineByChar(h.opis).map((line, i) => <Text key={i}>{line}</Text>)}
                                </View>
                            )}
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Czy występuje płatność częściowa</Text>
                                <Text>{h.czyWystepujePlatnoscCzesciowa === true ? 'TAK' : 'NIE'}</Text>
                            </View>
                        </View>
                    )
                })}
            </>
        )
    }

    renderWarunkiUdziału(warunkiUdzialu) {
        const {
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        return (
            <View>
                <Text style={labelStyle}>Warunki, jakie musi spełniać oferent</Text>
                {warunkiUdzialu.map((wu) =>
                    <View key={wu.id}>
                        {wu.typ.id !== null && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Typ</Text>
                                <Text>{wu.typ.nazwa}</Text>
                            </View>
                        )}
                        {isNotEmpty(wu.opis) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Opis</Text>
                                {splitLineByChar(wu.opis).map((line, i) => <Text key={i}>{line}</Text>)}
                            </View>
                        )}
                    </View>
                )}
            </View>
        )
    }

    renderKryteriaOceny(kryteriaOceny) {
        if (!isNotEmpty(kryteriaOceny, 'list')) {
            const {
                errorStyle,
                textInfoStyle,
            } = pdfStyles;
            return (
                <Text style={[textInfoStyle, errorStyle]}>Brak zdefiniowanych kryteriów oceny</Text>
            )
        }
        const {
            borderedSeparateSectionStyle,
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;
        return (
            <>
                {kryteriaOceny.map((ko) => (
                    <View key={ko.id} style={borderedSeparateSectionStyle}>
                        <View style={textWithLabelStyle}>
                            <Text style={labelStyle}>Czy kryterium cenowe?</Text>
                            <Text>{ko.czyKryteriumCenowe === true ? 'TAK' : 'NIE'}</Text>
                        </View>
                        {isNotEmpty(ko.opis) && (
                            <View style={textWithLabelStyle}>
                                <Text style={labelStyle}>Opis</Text>
                                {splitLineByChar(ko.opis).map((line, i) => <Text key={i}>{line}</Text>)}
                            </View>
                        )}
                    </View>
                ))}
            </>
        )
    }

    renderPodsumowanie() {
        const {
            labelStyle,
            textWithLabelStyle,
        } = pdfStyles;

        return (
            <>
                <View style={textWithLabelStyle}>
                    <Text style={labelStyle}>Oś czasu związana z ogłoszeniem i ofertowaniem</Text>
                    {this.renderOgloszeniePodsumowanie()}
                </View>
                <View style={textWithLabelStyle}>
                    <Text style={labelStyle}>Oś czasu realizacji przedmiotów zamówienia</Text>
                    {this.renderPrzedmiotyZamowieniaPodsumowanie()}
                </View>
            </>
        )
    }

    renderChartData(harmonogramy) {
        this.prepareChartData(harmonogramy);
        return harmonogramy.map((h) => (
            <View key={h.key}>
                <Text style={!h.hasPointer && {paddingLeft: 12}}>
                    <Text style={pdfStyles.dateTextStyle}>{h.hasPointer ? '-> ' : '' }{h.dateForShowing || h.date || '????-??-??'}</Text> - {h.label}
                </Text>
                <Text style={{height: `${h.height}px`, borderLeftWidth: 2, borderLeftStyle: 'dashed'}}></Text>
            </View>
        ))
    }

    renderTextError(text) {
        return <Text style={[pdfStyles.textInfoStyle, pdfStyles.errorStyle]}>{text}</Text>
    }
}

export { OgloszeniePdf };
