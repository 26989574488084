import React from 'react';

import { Text } from '../Text';


export const ErrorList = ({containerId, errorList}) => {
    return (
        <ul id={containerId}>
            {errorList.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}
        </ul>
    )
}
