const colors = {
    primary: '#0d47a1',
    secondary: '#5eb96a',
    warn: '#fff2b2',
    error: '#eb0000',
    background: '#fff',
    disabled: '#f2f2f2',
    surface: '#fff',
    onPrimary: '#fff',
    onSecondary: 'rgba(0, 0, 0, 0.87)',
    onSurface: 'rgba(0, 0, 0, 0.87)',
    onError: '#fff',
    darkBg: '#757575',
    textPrimaryOnBackground: 'rgba(0, 0, 0, 0.87)',
    textSecondaryOnBackground: 'rgba(0, 0, 0, 0.7)',
    textHintOnBackground: 'rgba(0, 0, 0, 0.7)',
    textDisabledOnBackground: 'rgba(0, 0, 0, 0.7)',
    textIconOnBackground: 'rgb(0, 0, 0)',
    textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
    textSecondaryOnLight: 'rgba(0, 0, 0, 0.7)',
    textHintOnLight: '#757575',
    textDisabledOnLight: 'rgba(0, 0, 0, 0.7)',
    textIconOnLight: 'rgba(0, 0, 0, 0.7)',
    textPrimaryOnDark: 'white',
    textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
    textHintOnDark: 'rgba(255, 255, 255, 0.5)',
    textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
    textIconOnDark: 'rgba(255, 255, 255, 0.5)',
};

const customSelectStyles = {
    input: (styles, state) => {
        return {
            ...styles,
            color: '#000',
            minWidth: 200,
            fontSize: 16,
        }
    },
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#fff' : (state.isSelected ? colors.primary : '#000'),
        backgroundColor: state.isFocused ? colors.primary : (state.isSelected ? 'rgba(0, 0, 0, .04)' : '#fff'),
        fontWeight: state.isSelected && '900',
        fontSize: 16,
        borderWidth: state.isSelected && 1,
        ':active': {
            backgroundColor: colors.primary,
            color: '#fff',
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#000'
    }),
    placeholder: (styles) => ({
        ...styles,
        color: 'rgba(0, 0, 0, 0.7)'
    }),
    multiValue: (styles) => {
        return {
            ...styles,
            color: '#fff',
            backgroundColor: colors.primary,
        }
    },
    multiValueLabel: (styles) => {
        return {
            ...styles,
            color: '#fff'
        }
    },
    multiValueRemove: (styles) => {
        return {
            ...styles,
            ':hover': {
                backgroundColor: colors.primary,
                color: '#fff',
                cursor: 'pointer'
            }
        }
    },
}


export { colors, customSelectStyles };
