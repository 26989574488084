import React from 'react';
import ReactSelect from 'react-select';
import { Icon } from '@rmwc/icon';


const instructionsAriaMessage = (event, context) => {
    const { isSearchable, isMulti, label, isDisabled } = context;
    switch (event) {
        case 'menu':
            return `Użyj strzałek góra, dół, aby wybrać opcję ${isDisabled ? '' : ', wciśnij Enter, aby wybrać aktualnie zaznaczoną opcję'}, wciśnij Escape żeby wyjść z menu, wciśnij Tab żeby wybrać opcję i wyjść z menu.`;
        case 'input':
            return `${label ? label : 'Select'} jest zaznaczona ${
                isSearchable ? ', wpisz, żeby odświeżyć listę. Lista opcji zawęzi się po wpisaniu kolejnych znaków.' : ''
            }, wciśnij strzałkę w dół, aby otworzyć menu, ${
                isMulti ? ' wciśnij strzałkę w lewo, żeby zaznaczyć wybraną wartość.' : ''
            }`;
        case 'value':
            return 'Użyj strzałek w lewo i w prawo aby przełączać się między zaznaczonymi wartościami, wciśnij Backspace, aby nie zaznaczyć wartości.';
        default:
            return null;
    }
};


const valueEventAriaMessage = (event, context) => {
    const { value, isDisabled } = context;
    if (!value) return;
    switch (event) {
        case 'deselect-option':
        case 'pop-value':
        case 'remove-value':
            return `opcja ${value}, niewybrana.`;
        case 'select-option':
            return isDisabled ? `opcja ${value} jest nieaktywna. Wybierz inną opcję.` : `Wybrano opcję ${value}.`;
            default:
                return null;
    }
};


const valueFocusAriaMessage = ({focusedValue, getOptionLabel, selectValue}) => {
    return `wartość ${getOptionLabel(focusedValue)} jest zaznaczona, ${selectValue.indexOf(focusedValue) + 1} z ${selectValue.length}.`
}


export const optionFocusAriaMessage = ({focusedOption, getOptionLabel, options}) => {
    return `Opcja ${getOptionLabel(focusedOption)}, zaznaczona ${focusedOption.isDisabled ? ' jest nieakywna' : ''}, ${options.indexOf(
        focusedOption) + 1} z ${options.length}.`
}


export const resultsAriaMessage = ({inputValue, screenReaderMessage}) => {
    return `${screenReaderMessage}${inputValue ? ' dla szukanej frazy ' + inputValue : ''}.`
}


class Select extends ReactSelect {

    componentDidMount() {
        const this_ = this.select;
        this_.announceAriaLiveContext = ({event, context}) => {
            this_.setState({
                ariaLiveContext: instructionsAriaMessage(event, {
                    ...context,
                    label: this_.props['aria-label'],
                }),
            });
        };
        this_.announceAriaLiveSelection = ({event, context}) => {
            this_.setState({
                ariaLiveSelection: valueEventAriaMessage(event, context),
            });
        };
        this_.constructAriaLiveMessage = () => {
            const {
                ariaLiveContext,
                selectValue,
                focusedValue,
                focusedOption,
            } = this_.state;
            const { options, menuIsOpen, inputValue, screenReaderStatus } = this_.props;

            // An aria live message representing the currently focused value in the select.
            const focusedValueMsg = focusedValue ? valueFocusAriaMessage({focusedValue, getOptionLabel: this_.getOptionLabel, selectValue}) : '';
            // An aria live message representing the currently focused option in the select.
            const focusedOptionMsg = focusedOption && menuIsOpen ? optionFocusAriaMessage({focusedOption, getOptionLabel: this_.getOptionLabel, options}): '';
            // An aria live message representing the set of focusable results and current searchterm/inputvalue.
            const resultsMsg = resultsAriaMessage({inputValue, screenReaderMessage: screenReaderStatus({ count: this_.countOptions() })});

            return `${focusedValueMsg} ${focusedOptionMsg} ${resultsMsg} ${ariaLiveContext}`;
        };

        this_.renderDropdownIndicator = () => {
            const { DropdownIndicator } = this_.components;
            if (!DropdownIndicator) return null;

            const innerProps = {
                onMouseDown: this_.onDropdownIndicatorMouseDown,
                onTouchEnd: this_.onDropdownIndicatorTouchEnd,
                'aria-hidden': 'true',
                id: this.props.indicatorId,
            };

            return (
                <DropdownIndicator
                    {...this_.commonProps}
                    innerProps={innerProps}
                    isDisabled={this_.props.isDisabled}
                    isFocused={this_.state.isFocused}
                />
            );
        };

        this_.renderClearIndicator = () => {
            const { ClearIndicator } = this_.components;
            const { isDisabled, isLoading } = this_.props;

            if (
                !this_.isClearable() ||
                !ClearIndicator ||
                isDisabled ||
                !this_.hasValue() ||
                isLoading
            ) {
                return null;
            }

            return (
                <button
                    aria-label="Wyczyść pole"
                    className='text-field-clear'
                    type="button"
                    onClick={() => { this_.clearValue()} }
                >
                    <Icon aria-hidden="true" icon="cancel" className="select-custom__icon" />
                </button>
            );
        }
    }
}


export { Select };
