import React from 'react';

import { ButtonStyled, GridCustom } from'../../common';


export const CancelSaveButtonsRow = ({
    disabled, disabledSave, cancelText, saveText, onCancel, onSave
}) => {
    return (
        <GridCustom flexEnd className=" box__button-container">
            <ButtonStyled cancel disabled={disabled} onClick={onCancel}>
                {cancelText || 'Anuluj'}
            </ButtonStyled>
            <ButtonStyled
                disabled={disabled || disabledSave}
                primary
                onClick={onSave}>
                {saveText || 'Zapisz'}
            </ButtonStyled>
        </GridCustom>
    )
}
