import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';

import { UserContext } from '../../../context/user-context';
import { serializeAuditData } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    AuditData,
    Breadcrumps,
    ButtonStyled,
    Container,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';
import { colors } from '../../vars/vars';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';


class Komunikat extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const komunikat = getSerializedObject(
            data,
            {
                nonRecursiveKeys: ['tresc'],
                dateAttributes: ['dataOpublikowania', 'dataWaznosci'],
                dateTimeAttributes: ['dataOpublikowania', 'dataWaznosci'],
            }
        ).komunikat;
        if (!komunikat) {
            this.setState({initializingOn: false, pageNotFound: true});
        } else {
            this.setState(
                {
                    initializingOn: false,
                    komunikat,
                    auditData: serializeAuditData(komunikat),
                },
                () => this.setUrlWithCorrectSlug(komunikat)
            );
        }
    }

    // helpers

    getAdditionalState(props) {
        return {komunikat: {}, auditData: {}}
    }

    getFetchUrl() {
        return API_URLS.statementsAdminDetails.getUrl(
            this.props.match.params.id)
    }

    getFetchData() {
        return {context: 'admin_panel'}
    }

    getFetchError(message) {
        return `Nie udało się pobrać komunikatu. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania komunikatu wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    setUrlWithCorrectSlug(komunikat) {
        // update url if slugs are not equal
        const urlParams = this.props.match.params;
        if (komunikat.slug !== urlParams.slug) {
            this.props.history.push(`/zarzadzanie-trescia/komunikaty/${urlParams.id}-${komunikat.slug}`);
        }
    }

    callback403 = () => {
        this.context.removePermission('statements');
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__link"><Link
                        to='/zarzadzanie-trescia/komunikaty'>Komunikaty</Link>
                    </li>
                    <li className="breadcrumps__current">Szczegóły komunikatu
                    </li>
                </Breadcrumps>
                <Text tag="h2" mainHeader>Szczegóły
                    komunikatu: {this.state.komunikat.naglowek}</Text>
            </>
        )
    }

    renderContent() {
        const komunikat = this.state.komunikat;
        return (
            <>
                <Container className="details-preview separate-container">
                    <GridCustom flexEnd>
                        <ButtonStyled primary noMargin
                                      onClick={() => this.props.history.push(`/zarzadzanie-trescia/komunikaty/${this.props.match.params.id}-${komunikat.slug}/edytuj`)}>Edytuj
                            komunikat</ButtonStyled>
                    </GridCustom>
                    <GridCustom flexL fullwidth noWrap>
                        <section
                            className="details-preview__part details-preview__part--main">
                            <FieldWithLabel label="Nagłówek">
                                <Text>{komunikat.naglowek}</Text>
                            </FieldWithLabel>
                            <FieldWithLabel label="Treść">
                                <DraftToComponents content={komunikat.tresc}/>
                            </FieldWithLabel>
                            <FieldWithLabel label="Slug">
                                <Text>{komunikat.slug}</Text>
                            </FieldWithLabel>
                        </section>
                        <aside
                            className="details-preview__part details-preview__part--aside">
                            <GridCustom>
                                <FieldWithLabel label="Aktywny">
                                    {komunikat.czyAktywny ? (
                                        <Icon icon="check_circle"
                                              style={{color: colors.secondary}}
                                              title="Tak" aria-hidden="true"/>
                                    ) : (
                                        <Icon icon="cancel"
                                              style={{color: colors.textHintOnLight}}
                                              title="Nie" aria-hidden="true"/>
                                    )}
                                </FieldWithLabel>
                                <FieldWithLabel label="Żółty pasek">
                                    {komunikat.czyZoltyPasek ? (
                                        <Icon icon="check_circle"
                                              style={{color: colors.secondary}}
                                              title="Tak" aria-hidden="true"/>
                                    ) : (
                                        <Icon icon="cancel"
                                              style={{color: colors.textHintOnLight}}
                                              title="Nie" aria-hidden="true"/>
                                    )}
                                </FieldWithLabel>
                            </GridCustom>
                            <GridCustom>
                                <FieldWithLabel label="Bezterminowy">
                                    {komunikat.dataWaznosci.length === 0 ? (
                                        <Icon icon="check_circle"
                                              style={{color: colors.secondary}}
                                              title="Tak" aria-hidden="true"/>
                                    ) : (
                                        <Icon icon="cancel"
                                              style={{color: colors.textHintOnLight}}
                                              title="Nie" aria-hidden="true"/>
                                    )}
                                </FieldWithLabel>
                                <FieldWithLabel label="Data ważności">
                                    <Text>{komunikat.dataWaznosci || '-'}</Text>
                                </FieldWithLabel>
                            </GridCustom>
                            <FieldWithLabel label="Data opublikowania">
                                <Text>{komunikat.dataOpublikowania}</Text>
                            </FieldWithLabel>
                        </aside>
                    </GridCustom>
                </Container>
                <AuditData {...this.state.auditData} />
            </>
        )
    }
}

Komunikat.contextType = UserContext;

export { Komunikat };
