import React, { useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { useCheckPermissionExists } from '../../../hooks';
import { API_URLS } from '../../../urls/api';
import { FieldWithLabel, Text } from '../../common';
import { ProjectNumberSelectsWithPerspectives } from '../common';
import {
    UserPermissionsFormCommonFields
} from './UserPermissionsFormCommonFields';
import {
    hasPerspectiveInstitutions,
    hasPerspectiveRegions
} from "../../../helpers";


const DEFAULT_CHECKS_DICT = {
    projectsEnrollmentsAdministration: {
      searchNPreviewData: false,
      editData: false,
    },
    advertisementAdministration: {
      searchNPreviewData: false,
      blocking: false,
      editData: false,
    },
    permissionsAdministration: {
        previewListUsersAuthorized: false,
        functionalityManagement: false,
        permissionsManagement: false,
    },
};

const CHECKS_SORT_DICT = {
    groups: [
        'projectsEnrollmentsAdministration',
        'advertisementAdministration',
        'permissionsAdministration'
    ],
    projectsEnrollmentsAdministration: ['searchNPreviewData', 'editData'],
    advertisementAdministration: [
        'searchNPreviewData', 'blocking', 'editData'],
    permissionsAdministration: [
        'previewListUsersAuthorized', 'functionalityManagement',
        'permissionsManagement'
    ],
};


function serializeImplementationLevel(dataDict) {
    if (!dataDict) { return null }  // it can be even 'undefined'

    // change id into value according to needed key for implementation level
    let dct = {...dataDict};
    dct['value'] = dct.id;
    delete dct['id'];
    return dct
};


export const UserPermissionsImplementationLevelFields = ({
    initialData, perspectives, userId, onCancel, onSavePermissionSuccess
}) => {
    const [editionModeOn, setEditionModeOn] = useState(initialData !== null);
    const [savingOn, setSavingOn] = useState(false);
    const [implementationLevels, setImplementationLevels] = useState(
        {
            perspective: null, programme: null, priority: null, activity: null,
            institution: null, region: null
        });
    const [
        implementationLevelsErrors, setImplementationLevelsErrors
    ] = useState({});

    useEffect(() => {
        if (!editionModeOn || !initialData) { return }
        // use initial data if edition mode
        setImplementationLevels({
            perspective: serializeImplementationLevel(initialData.perspective),
            programme: serializeImplementationLevel(initialData.programme),
            priority: serializeImplementationLevel(initialData.priority),
            activity: serializeImplementationLevel(initialData.activity),
            institution: serializeImplementationLevel(initialData.institution),
            region: serializeImplementationLevel(initialData.region),
        });
    }, [editionModeOn, initialData]);

    /*
        this one is set if request for permissions for checkboxes  or just
        request for checkboxes values returns 403
    */
    const [permissions403errorOn, setPermissions403errorOn] = useState(false);
    // these say if user has permissions for checkboxes groups
    const [
        permissionsForCheckboxes, setPermissionsForCheckboxes
    ] = useState({functionality: true, permissions: true});
    const [
        checkingCheckboxesPermissionOn, setCheckingCheckboxesPermissionOn
    ] = useState(false);

    const [existsErrorOn, setExistsErrorOn] = useState(false);
    const [
        checkingExistedPermissionOn, setCheckingExistedPermissionOn
    ] = useState(false);

    useEffect(() => {
        setPermissions403errorOn(false);
        if (implementationLevels.programme === null) { return }
        setPermissionsForCheckboxes(
            {functionality: false, permissions: false});
        setCheckingCheckboxesPermissionOn(true);
    }, [implementationLevels]);

    const fetchData = useMemo(
        () => {
            let levelNames =
                ['perspective', 'programme', 'priority', 'activity'];
            if (hasPerspectiveRegions(implementationLevels.perspective)) {
                levelNames.push('region');
            }
            if (hasPerspectiveInstitutions(implementationLevels.perspective)) {
                levelNames.push('institution');
            }
            const dataDict = {};
            for (let levelName of levelNames) {
                dataDict[levelName] = implementationLevels[levelName]
                    ? implementationLevels[levelName].value : null
            }
            return dataDict
        }, [implementationLevels]);

    const { user } = useContext(UserContext);
    const { id: currentUserId } = user;

    useEffect(() => {
        if (!checkingCheckboxesPermissionOn) { return }
        // set checkboxes permissions
        let xhrFetch = null;

        function callbackPermissionExists(data) {
            xhrFetch = null;
            const permissionsAdministration = (data.permission || {})
                .permissionsAdministration || {};
            const grantedPermissions = {
                functionality:
                    permissionsAdministration.functionalityManagement || false,
                permissions:
                    permissionsAdministration.permissionsManagement || false,
            };
            setPermissionsForCheckboxes(grantedPermissions);
            setCheckingCheckboxesPermissionOn(false);
            if (!editionModeOn && (
                grantedPermissions.functionality ||
                grantedPermissions.permissions
            )) {
                setCheckingExistedPermissionOn(true);
            }
        }

        function callbackPermissionDoesNotExists() {
            xhrFetch = null;
            setCheckingCheckboxesPermissionOn(false);
        }

        function callbackPermissionIncorrectStatus(statusCode) {
            xhrFetch = null;
            if (statusCode === 403) {
                setPermissions403errorOn(true);
            }
            callbackPermissionDoesNotExists();
        }

        xhrFetch = fetchApi(
            API_URLS.usersPermissionsAdvertisementsProjectsEnrollmentsDetails.path,
            'GET', {},
            {
                authorized: currentUserId,
                mode: 'inherited',
                ...fetchData
            },
            callbackPermissionExists, callbackPermissionDoesNotExists,
            callbackPermissionIncorrectStatus
        );
        return () => {
            if (xhrFetch !== null) {
                xhrFetch.abort();
            }
        }
    }, [checkingCheckboxesPermissionOn]); // eslint-disable-line react-hooks/exhaustive-deps

    const { existingPermissionData } = useCheckPermissionExists(
        checkingExistedPermissionOn, setCheckingExistedPermissionOn,
        API_URLS.usersPermissionsAdvertisementsProjectsEnrollmentsDetails.path,
        fetchData, setExistsErrorOn, setPermissions403errorOn, userId
    );

    function handleChangeProjectNumberSelect(data) {
        setImplementationLevels(prevValue => ({...prevValue, ...data}));
        if (data.programme === null) {
            setExistsErrorOn(false);
        }
    }

    const disabledFields = checkingExistedPermissionOn ||
                           checkingCheckboxesPermissionOn ||
                           savingOn;
    const permission403exists = permissions403errorOn || (
        !checkingCheckboxesPermissionOn &&
        !permissionsForCheckboxes.functionality &&
        !permissionsForCheckboxes.permissions
    )

    const implementationLevelsPermissionErrors =
        implementationLevelsErrors.permission || {};

    return (
        <>
            <FieldWithLabel label="Poziom wdrożenia" tag="label">
                {(!editionModeOn || implementationLevels.perspective !== null) && (
                    <ProjectNumberSelectsWithPerspectives
                        activity={implementationLevels.activity}
                        activityErrors={implementationLevelsPermissionErrors.activity || []}
                        disabled={disabledFields || editionModeOn}
                        institution={implementationLevels.institution}
                        institutionErrors={implementationLevelsPermissionErrors.institution || []}
                        isClearable={true}
                        mode="permissions"
                        perspective={implementationLevels.perspective}
                        perspectiveErrors={implementationLevelsPermissionErrors.perspective || []}
                        perspectives={perspectives}
                        perspectivesAsSelect={true}
                        priority={implementationLevels.priority}
                        priorityErrors={implementationLevelsPermissionErrors.priority || []}
                        programme={implementationLevels.programme}
                        programmeErrors={implementationLevelsPermissionErrors.programme || []}
                        region={implementationLevels.region}
                        regionErrors={implementationLevelsPermissionErrors.region || []}
                        withInstitutionNumber={false}
                        onChangeActivity={handleChangeProjectNumberSelect}
                        onChangeInstitution={handleChangeProjectNumberSelect}
                        onChangePerspective={handleChangeProjectNumberSelect}
                        onChangePriority={handleChangeProjectNumberSelect}
                        onChangeProgramme={handleChangeProjectNumberSelect}
                        onChangeRegion={handleChangeProjectNumberSelect}
                    />
                )}
            </FieldWithLabel>
            {checkingCheckboxesPermissionOn && (
                <Text info role="alert">
                    Trwa sprawdzanie uprawnień dla wybranego poziomu wdrożenia.
                </Text>
            )}
            {permission403exists && (
                <Text error role="alert">
                    Nie masz uprawnień do nadawania uprawnień dla wybranego poziomu wdrożenia.
                    {implementationLevels.programme !== null && ' Zmień poziom wdrożenia.'}
                </Text>
            )}
            <UserPermissionsFormCommonFields
                additionalFetchData={fetchData}
                checksSortDict={CHECKS_SORT_DICT}
                defaultChecksDict={editionModeOn
                    ? (existingPermissionData !== null
                        ? existingPermissionData
                        : initialData.permissions
                    )
                    : DEFAULT_CHECKS_DICT
                }
                disabled={disabledFields}
                disabledCancelButton={savingOn}
                disabledSaveButton={disabledFields || permission403exists}
                editionModeOn={editionModeOn}
                existingPermissionData={existingPermissionData}
                existsErrorOn={existsErrorOn}
                existsErrorLabel="Poziomy wdrożenia"
                isImplementationLevelType={true}
                permissions={permissionsForCheckboxes}
                saveUrl={API_URLS.usersPermissionsAdvertisementsProjectsEnrollments.path}
                savingOn={savingOn}
                setAdditionalErrors={setImplementationLevelsErrors}
                setEditionModeOn={setEditionModeOn}
                setExistsErrorOn={setExistsErrorOn}
                userId={userId}
                onCancel={onCancel}
                onSavePermissionSuccess={onSavePermissionSuccess}
                onSetSavingOn={setSavingOn} />
        </>
    )
};
