import React, { useState } from 'react';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

import { refreshTimerAfterSativaRequest } from '../../helpers';
import { Container } from '../common';


const DeklaracjaDostepnosci = (props) => {
    document.title = 'Deklaracja dostępności serwisu Baza Konkurencyjności';
    window.scrollTo(0, 0);

    const [timerRefreshed, setTimerRefreshed] = useState(false);
    if (!timerRefreshed) {
        refreshTimerAfterSativaRequest();
        setTimerRefreshed(true);
    }

    return (
        <Container className="article">
            <Typography id="a11y-deklaracja" use="headline4" tag="h2" className="article__header">Deklaracja dostępności serwisu Baza Konkurencyjności</Typography>
            <Typography id="a11y-wstep" use="body1" tag="p" className="article__text"><span id="a11y-podmiot">Ministerstwo Funduszy i Polityki Regionalnej</span> zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z ustawą z dnia 4 kwietnia 2019 r.o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do serwisu internetowego Baza Konkurencyjności należącego do <span id="a11y-podmiot">Ministerstwa Funduszy i Polityki Regionalnej</span> - <a id="a11y-url" href="/" className="link-text">https://bazakonkurencyjnosci.funduszeeuropejskie.gov.pl/</a>.</Typography>
            <ul className="article__list">
                <li className="article__list-item">
                    <Icon icon={{ icon: 'arrow_right', size: 'medium' }} aria-hidden="true"/>
                    <Typography use="body1" tag="p">Data publikacji strony internetowej: <span id="a11y-data-publikacja">2019-09-07</span></Typography>
                </li>
                <li className="article__list-item">
                    <Icon icon={{ icon: 'arrow_right', size: 'medium' }} aria-hidden="true"/>
                    <Typography use="body1" tag="p">Data ostatniej aktualizacji: <span id="a11y-data-aktualizacja">2024-02-01</span></Typography>
                </li>
                <li className="article__list-item">
                    <Icon icon={{ icon: 'arrow_right', size: 'medium' }} aria-hidden="true"/>
                    <Typography id="a11y-status" use="body1" tag="p">Strona internetowa jest zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.</Typography>
                </li>
                <li className="article__list-item">
                    <Icon icon={{ icon: 'arrow_right', size: 'medium' }} aria-hidden="true"/>
                    <Typography use="body1" tag="p">Oświadczenie sporządzono dnia <span id="a11y-data-sporzadzenie">2024-02-01</span>. Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</Typography>
                </li>
                <li className="article__list-item">
                    <Icon icon={{ icon: 'arrow_right', size: 'medium' }} aria-hidden="true"/>
                    <Typography use="body1" tag="p">Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych</Typography>
                </li>
            </ul>

            <Typography id="a11y-kontakt" use="headline6" tag="h2" className="article__section-title">Informacje zwrotne i dane kontaktowe</Typography>
            <Typography use="body1" tag="p" className="article__text">W przypadku problemów z dostępnością strony internetowej prosimy o kontakt. Osobami odpowiedzialnymi są <span id="a11y-osoba">Dariusz Kamiński i Patrycja Myszczyńska</span>, kontaktować można się drogą mailową używając adresu <a id="a11y-email" href="mailto:konkurencyjnosc@mfipr.gov.pl" className="link-text">konkurencyjnosc@mfipr.gov.pl</a> lub dzwoniąc na numer telefonu <a id="a11y-telefon" href="tel:222737912" className="link-text">22 273 79 12</a>. Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać skargi na brak zapewnienia dostępności.</Typography>
            <div id="a11y-procedura">
                <Typography use="body1" tag="p" className="article__text">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji w formach alternatywnych, na przykład odczytanie niedostępnego cyfrowo dokumentu, opisania zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji w formie alternatywnej, powinna także określić formę tej informacji.</Typography>
                <Typography use="body1" tag="p" className="article__text">Podmiot publiczny powinien zrealizować żądanie niezwłocznie i nie później, niż w ciągu 7 dni. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące. Jeżeli zapewnienie dostępności nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji.</Typography>
                <Typography use="body1" tag="p" className="article__text">W przypadku, gdy podmiot odmówi realizacji żądania zapewnienia dostępności lub alternatywnego dostępu do informacji, można złożyć skargę na takie działanie. Po wyczerpaniu wszystkich możliwości skargę można przesłać także do <a href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich" className="link-text">Rzecznika Praw Obywatelskich</a>.</Typography>
            </div>

            <Typography id="a11y-architektura" use="headline6" tag="h2" className="article__section-title">Dostępność architektoniczna</Typography>
            <Typography use="subtitle2" tag="h3" className="article__address">Ministerstwo Funduszy i Polityki Regionalnej, ul. Wspólna 2/4, 00-926 Warszawa</Typography>
            <Typography use="body1" tag="p" className="article__text">Do budynku prowadzi wejście główne od ul. Wspólnej. Przy wejściu znajduje się podjazd dla wózków.</Typography>
            <Typography use="body1" tag="p" className="article__text">Recepcja znajduje się po prawej stronie od wejścia głównego. Dalsze przejście zabezpieczone jest bramkami, przez które może przejechać osoba na wózku.</Typography>
            <Typography use="body1" tag="p" className="article__text">Na parkingu od ulicy ul. Wspólnej zapewnione są miejsca parkingowe przeznaczone dla osób niepełnosprawnych. W budynku drzwi wejściowe i korytarze umożliwiają swobodny poruszanie się osób na wózkach inwalidzkich. Sale konferencyjne są dostępne dla osób z ograniczeniami mobilności. Wejście do sali konferencyjnej na 5. piętrze wyposażone jest w mobilny „schodołaz” gąsienicowy.</Typography>
            <Typography use="body1" tag="p" className="article__text">Windy w budynku są dostosowane do przewozu osób z trudnościami w poruszaniu się (poręcze, odpowiednia wielkość). Na każdym piętrze znajduje się toaleta spełniająca kryteria dostępności.</Typography>
            <Typography use="body1" tag="p" className="article__text">Do dyspozycji osób niewidzących lub słabowidzących są windy z przyciskami na panelu sterowania z oznaczeniami w alfabecie Braille’a oraz powiadomieniami głosowymi.</Typography>
            <Typography use="body1" tag="p" className="article__text">Korytarze są szerokie, oznaczone kontrastowymi kolorami (różne kolory na różnych piętrach) i w miarę możliwości pozbawione są większych przeszkód przed wejściem na recepcję i salę konferencyjną umieszczone są właściwe oznaczenia – piktogramy.</Typography>
            <Typography use="body1" tag="p" className="article__text">Do budynku i wszystkich jego pomieszczeń można wejść z psem asystującym i psem przewodnikiem.</Typography>
            <Typography use="body1" tag="p" className="article__text">Aby skutecznie komunikować się z naszym urzędem osoby niesłyszące lub słabo słyszące mogą skorzystać z usługi <a href="https://tlumacz.migam.org/ministerstwo_inwestycji_i_rozwoju" className="link-text">Tłumacz Migam</a>, która umożliwia połączenia wideo z tłumaczem języka migowego z poziomu przeglądarki internetowej, aplikacji mobilnej oraz dowolnego urządzenia z kamerą. Usługa ta jest dostępna w dni robocze w godzinach pracy urzędu:&nbsp;8:00&nbsp;-&nbsp;16:00.</Typography>
        </Container>
    );
}

export { DeklaracjaDostepnosci };
