import React from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { countWysiwygChars } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { validateWysiwygEditor } from '../../../validators';
import {
    Breadcrumps,
    ButtonStyled,
    Container,
    FieldWithLabel,
    GridCustom,
    Text,
    WysiwygEditor,
} from '../../common';
import { Ciasteczka } from './Ciasteczka';


const SAVE_BUTTON_DEFAULT_LABEL = 'Zapisz ciasteczka';


class FormCiasteczka extends Ciasteczka {

    // basic functions

    componentDidMount() {
        if (!this.props.editing) {
            this.setState({initializingOn: false});
        } else {
            // get ciasteczka from server
            super.componentDidMount();
        }
    }

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        if (data === null) {
            this.setState({initializingOn: false, pageNotFound: true});
        } else {
            this.setState({
                initializingOn: false,
                tresc: getSerializedObject(
                    data, {nonRecursiveKeys: ['tresc', ]}).tresc
            });
        }
    }

    handleChangeAttribute(name, value) {
        this.setState(prevState => ({
            [name]: value,
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleSave() {
        this.setState(
            {savingOn: true, fetchSaveError: '', saveBtnText: 'Trwa zapisywanie...'},
            () => {
                const [isValid, errors] = this.validate();
                if (isValid) {
                    // save ciasteczka on server
                    this.xhrFetch = fetchApi(
                        API_URLS.cookiesAdmin.path,
                        this.firstSaving ? 'POST' : 'PUT',
                        {},
                        getSerializedObject(
                            {tresc: this.state.tresc},
                            {toServer: true, nonRecursiveKeys: ['tresc',]}
                        ),
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors, saveBtnText: SAVE_BUTTON_DEFAULT_LABEL});
                }
            }
        );
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetch = null;
        this.setState(
            {savingOn: false, saveBtnText: 'Zapisano'},
            () => {
                this.firstSaving = false;
                this.saveMsgTimeout = setTimeout(() => {
                    this.setState({saveBtnText: SAVE_BUTTON_DEFAULT_LABEL})
                }, 5000)
            }
        );
    }

    handleFetchSaveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            savingOn: false,
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: `Nie udało się zapisać ciasteczek. ${data.message}`,
        });
    }

    handleFetchSaveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        let message = '';
        if (status === 403) {
            message = `Nie masz uprawnień do ${this.firstSaving
                ? 'dodawania' : 'edycji'} ciasteczek.`;
            // update permission value in context
            this.removePermission();
        }
        this.setState({
            savingOn: false,
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: message || `Podczas zapisu ciasteczek wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    // helpers

    preState(props) {
        this.trescMaxLength = 1000;
        this.firstSaving = true;
    }

    getAdditionalState(props) {
        return {
            tresc: '',
            savingOn: false,
            fetchSaveError: '',
            errors: {
                tresc: '',
            },
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
        }
    }

    setAdditionalHandlers() {
        this.handleSave = this.handleSave.bind(this);
        this.handleFetchSaveSuccess = this.handleFetchSaveSuccess.bind(this);
        this.handleFetchSaveError = this.handleFetchSaveError.bind(this);
        this.handleFetchSaveIncorrectStatus = this.handleFetchSaveIncorrectStatus.bind(this);
    }

    validate() {
        // clean errors
        this.setState({errors: {}});

        let isValid = true;
        let errors = {};

        // validate tresc
        const tresc = this.state.tresc;
        if (!validateWysiwygEditor(tresc)) {
            errors['tresc'] = 'To pole jest wymagane.';
            isValid = false;
        } else if (countWysiwygChars(tresc) > this.trescMaxLength) {
            errors['tresc'] = `To pole może mieć maksymalnie ${this.trescMaxLength} znaków.`;
            isValid = false;
        }

        return [isValid, errors]
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps>
                <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/ciasteczka'>Ciasteczka</Link></li>
                <li className="breadcrumps__current">{this.props.editing ? 'Edytuj' : 'Dodaj'} ciasteczka</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        const {
            errors,
            fetchSaveError,
            saveBtnText,
            savingOn,
            tresc,
        } = this.state;
        const isTrescInvalid = (errors.tresc || '').length > 0;
        return (
            <>
                <Text tag="h2" mainHeader>{this.props.editing ? 'Edytuj' : 'Dodaj'} ciasteczka</Text>
                <Container>
                    <GridCustom fullwidth>
                        <FieldWithLabel label="Treść" className="wysiwyg-wrapper">
                            <WysiwygEditor
                                disabled={savingOn}
                                invalid={isTrescInvalid}
                                initialContentState={tresc}
                                localization={{ locale: 'pl' }}
                                maxLength={this.trescMaxLength}
                                toolbar={{options: []}}
                                toolbarHidden
                                onContentStateChange={(contentState) => this.handleChangeAttribute('tresc', contentState)}
                            />
                            {isTrescInvalid && <Text error>{errors.tresc}</Text>}
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom flexEnd>
                        <ButtonStyled
                            disabled={savingOn}
                            save
                            onClick={this.handleSave}
                        >{saveBtnText}</ButtonStyled>
                    </GridCustom>
                    {fetchSaveError.length > 0 && <Text error role="alert">{fetchSaveError}</Text>}
                </Container>
            </>
        )
    }
}

FormCiasteczka.contextType = UserContext;

export { FormCiasteczka };
