import React from 'react';
import { useParams } from 'react-router-dom';

import { API_URLS } from '../../../urls/api';
import { ImplementationLevelManagement, Text } from '../../common';


const RelatedObjectsExistInfoComponent = (props) => (
    <>
        <Text error>Nie można usunąć instytucji, ponieważ jest ona powiązana z innym obiektem.</Text>
        <Text error>Upewnij się, że nie istnieją projekty lub nabory oparte o tę instytucję oraz nie są przyznane uprawnienia do tej instytucji, a następnie spróbuj ponownie.</Text>
    </>
);


export const Institutions = (props) => {
    const { activityId } = useParams();

    function getParentFormLabel(programmeCode, priorityCode, activityCode) {
        return `${programmeCode}.${priorityCode}.${activityCode}-`
    }

    return (
        <ImplementationLevelManagement
            addApiUrlName="institutionsAdd"
            addItemButtonLabel="Dodaj instytucję"
            additionalSaveData={{activity: parseInt(activityId)}}   // for form
            apiUrl={API_URLS.institutionsAdmin.getUrl(activityId)}
            auditDataBoxTitle="Dane audytowe dla działania"
            auditDataObjectName="activity"
            codeMaxLength={5}
            editApiUrlName="institutionsEdit"
            getParentFormLabel={getParentFormLabel}
            listObjectName="institutions"
            RelatedObjectsExistInfoComponent={RelatedObjectsExistInfoComponent}
            showAuditData={true}
            tableName="Lista instytucji"
            titleFormLabel="instytucji"
        />
    )
};
