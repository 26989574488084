import React from 'react';
import { Icon } from '@rmwc/icon';

import { Text } from './Text'

const Message = (props) => {
    const {
        className,
        children,
        error,
        id,
        messageIcon,
        messageTitle,
        role,
        success
    } = props;

    let basicClassName = 'message'

    if (error) {
        basicClassName += ' message--error'
    } else if (success) {
        basicClassName += ' message--success'
    }

    return (
        <div className={basicClassName + ` ${className || ''}`} id={id}>
            <Icon icon={messageIcon} className="message__icon" aria-hidden="true" />
            <Text
                aria-live={role ? null : 'assertive'}
                className="message__title"
                role={role || 'alert'}
                tag="h2"
            >
                {messageTitle}
            </Text>
            {children && <Text className="message__additional-info">{children}</Text>}
        </div>
    )
}

export { Message };
