import React from 'react';

import { GridCustom, Text } from'../../common';


export const FetchErrorWithOkayButton = ({
    buttonLabel,
    infoMsg,
    message,
    onClickOkay,
}) => {
    return (
        <GridCustom centerVertical className="fetch-error-with-ok-btn">
            <Text error={!infoMsg} info={infoMsg} role="alert">{message}</Text>
            <button onClick={onClickOkay} className={`fetch-btn ${infoMsg ? 'fetch-btn--info' : 'fetch-btn--error'}`}>{buttonLabel || 'OK'}</button>
        </GridCustom>
    )
}
