import React, { Component } from 'react';

import { USER_STATUS_CHOICES } from '../../../const';
import {
    ButtonStyled,
    FieldWithLabel,
    GridCustom,
    Select,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';


class FiltryUzytkownikow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: props.filters,
        };

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
    }

    //handlers

    handleChangeFilter(name, value) {
        this.setState(prevState => {
            return {filters: Object.assign({}, prevState.filters, {[name]: value})}
        });
    }

    handleApplyFilters() {
        this.props.updateFilters(this.state.filters);
    }

    // rendering

    render() {
        const filters = this.state.filters;
        return (
            <>
                <section className="filters-dialog__filters-section">
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel label="Identyfikator użytkownika" tag="label" labelFor="filters_identifier_id">
                            <TextFieldCustom
                                className="filters__input"
                                clearFieldContext="identyfikator użytkownika"
                                id="filters_identifier_id"
                                value={filters.identifier}
                                onChange={(ev) => this.handleChangeFilter('identifier', ev.target.value)}
                                onClear={(ev) => this.handleChangeFilter('identifier', '')} />
                        </FieldWithLabel>
                        <FieldWithLabel label="Adres e-mail" tag="label" labelFor="filters_email_id">
                            <TextFieldCustom
                                clearFieldContext="adres e-mail"
                                value={filters.email}
                                onChange={(ev) => this.handleChangeFilter('email', ev.target.value)}
                                className="filters__input"
                                onClear={(ev) => this.handleChangeFilter('email', '')}
                                id="filters_email_id"
                            />
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel label="Imię" tag="label" labelFor="filters_name_id">
                            <TextFieldCustom
                                clearFieldContext="imię"
                                value={filters.forename}
                                onChange={(ev) => this.handleChangeFilter('forename', ev.target.value)}
                                className="filters__input"
                                onClear={(ev) => this.handleChangeFilter('forename', '')}
                                id="filters_name_id"
                            />
                        </FieldWithLabel>
                        <FieldWithLabel label="Nazwisko" tag="label" labelFor="filters_surname_id">
                            <TextFieldCustom
                                clearFieldContext="nazwisko"
                                value={filters.surname}
                                onChange={(ev) => this.handleChangeFilter('surname', ev.target.value)}
                                className="filters__input"
                                onClear={(ev) => this.handleChangeFilter('surname', '')}
                                id="filters_surname_id"
                            />
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel
                            label="Status"
                            tag="label"
                            selectRef={React.createRef()}>
                            <Select
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                className="select-custom"
                                options={USER_STATUS_CHOICES.map(s => ({value: s[0], label: s[1]}))}
                                value={{value: filters.status.value || USER_STATUS_CHOICES[0][0], label: filters.status.label || USER_STATUS_CHOICES[0][1]}}
                                onChange={(selectedOption) => this.handleChangeFilter('status', selectedOption)}
                                styles={customSelectStyles}
                            />
                        </FieldWithLabel>
                    </GridCustom>
                </section>
                <GridCustom flexEnd>
                    <ButtonStyled onClick={this.props.closeFilters} cancel>Anuluj</ButtonStyled>
                    <ButtonStyled onClick={this.handleApplyFilters} primary>Zastosuj kryteria</ButtonStyled>
                </GridCustom>
            </>
        )
    }
}


export { FiltryUzytkownikow };
