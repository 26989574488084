// USAGE:
// <Text>text</Text> - black text
// <Text label>text</Text> - grey uppercase text, label for <FieldWithLabel /> component
// <Text info>text</Text> - primary (blue) color
// <Text error>text</Text> - red color
// <Text accentHeader>text</Text> - bigger (18px) blue (primary) text
// <Text mainHeader tag="h2">text</Text> - bigger (28px) blue (primary) text for section title
// <Text sectionHeader tag="h2">text</Text> - bigger (28px) without margin-bottom


import React from 'react';
import { Typography } from '@rmwc/typography';

import { colors } from '../vars/vars'


const Text = (props) => {
    const {
        accentHeader,
        children,
        className,
        error,
        errorColorReverse,
        htmlFor,
        info,
        label,
        labelFor,
        mainHeader,
        sectionHeader,
        tag,
        use,
        ...otherProps
    } = props;

    const {
        errorTextStyle,
        errorColorReverseStyle,
        headerTextStyle,
        infoTextStyle,
    } = styles;

    return (
        <Typography
            tag={tag ? tag : 'p'}
            use={label ? 'body2' : (use ? use : 'subtitle2')}
            style={info ? infoTextStyle : (error ? errorTextStyle : (errorColorReverse ? errorColorReverseStyle : (accentHeader && headerTextStyle)))}
            className={[label ? 'label' : 'text', mainHeader && 'text--main-header', sectionHeader && 'text--section-header', className]}
            htmlFor={labelFor || htmlFor || null}
            {...otherProps} >
            {children}
        </Typography>
    );
}

const styles = {
    errorTextStyle: {
        color: colors.error,
    },
    errorColorReverseStyle: {
        color: '#fff',
    },
    infoTextStyle: {
        color: colors.primary,
        fontWeight: '400',
    },
    headerTextStyle: {
        fontSize: 18,
        fontWeight: 700,
        marginTop: 0,
        marginBottom: 10,
        color: colors.primary,
    },
}


export { Text };
