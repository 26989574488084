import React, { Component } from "react";

import {
  ButtonStyled,
  FieldWithLabel,
  GridCustom,
  Select,
  TextFieldCustom,
  Text,
} from "../../common";
import { customSelectStyles } from "../../vars/vars";

import { serializeObjectList } from "../../../serializers";

import { fetchApi } from "../../../fetch";

import {
  sortMiejsceRealizacjiFilter,
  sortProgrammeFilter,
} from "../../../sorters";

import { FormKodCPV } from "../ogloszenia/FormKodCPV";

const EMPTY_VALUE = {
  value: 0,
  label: "Wybierz",
};

const ORDER_ITEM_CATEGORIES = "order_item_categories";

const serializeProgrammeSelectStructure = (data) => {
  let data_ = {};
  for (let programme of Object.values(data)) {
    data_[programme.id] = `${programme.code.toUpperCase()} - ${programme.name}`;
  }
  return Object.entries(data_).sort(sortProgrammeFilter) || [];
};

class FavoritesFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initializingOn: true,
      filters: props.filters,
      dictionaries: props.dictionaries,
      errors: {},
    };

    this.xhrFetch = null;

    this.handleFetchSuccess = this.handleFetchSuccess.bind(this);
    this.handleFetchError = this.handleFetchError.bind(this);
    this.handleFetchIncorrectStatus =
      this.handleFetchIncorrectStatus.bind(this);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
  }

  componentDidMount() {
    if (!Object.keys(this.state.dictionaries).length) {
      // get dictionaries from server
      this.xhrFetch = fetchApi(
        "/api/dictionaries/search-filters",
        "GET",
        {},
        {},
        this.handleFetchSuccess,
        this.handleFetchError,
        this.handleFetchIncorrectStatus
      );
    } else {
      this.setState({ initializingOn: false });
    }
  }

  componentWillUnmount() {
    // abort api request if exist
    if (this.xhrFetch !== null) {
      this.xhrFetch.abort();
    }
  }

  //handlers

  handleFetchSuccess(data) {
    this.xhrFetch = null;
    let dictionaries = {
      przedmiotZamowieniaKategoria: serializeObjectList(
        data[ORDER_ITEM_CATEGORIES]
      ),
      programOperacyjny: serializeProgrammeSelectStructure(
        data.operation_programme
      ),
      miejsceRealizacji:
        Object.entries(data.location_id).sort(sortMiejsceRealizacjiFilter) ||
        [],
    };

    this.setState(
      {
        initializingOn: false,
        dictionaries,
      },
      () => this.props.updateDictionaries(dictionaries)
    );
  }

  handleFetchError(data) {
    this.xhrFetch = null; // clean xhr object
    this.setState({
      initializingOn: false,
      fetchError: `Nie udało się pobrać danych do filtrów. ${data.message}`,
    });
  }

  handleFetchIncorrectStatus(status) {
    this.xhrFetch = null; // clean xhr object
    this.setState({
      initializingOn: false,
      fetchError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
    });
  }

  handleApplyFilters() {
    this.props.updateFilters(this.state.filters);
  }

  handleChangeFilter(name, value) {
    this.setState((prevState) => {
      let dict = { [name]: value };
      if (name === "advertiserIdentificationNumber") {
        // allow to insert only letters and digits
        dict["advertiserIdentificationNumber"] = value.replace(
          /[^a-zA-Z\d]/,
          ""
        );
      } else {
        dict[name] = value;
      }
      if (name === "category") {
        dict["subcategory"] = null;
      }
      // clean error (just in case if exists)
      let errors = Object.assign({}, prevState.errors);
      delete errors[name];
      return { errors, filters: Object.assign({}, prevState.filters, dict) };
    });
  }

  // rendering

  render() {
    const filters = this.state.filters;
    if (this.state.initializingOn) {
      return (
        <Text info tabIndex="1">
          Trwa inicjalizacja danych...
        </Text>
      );
    } else {
      return (
        <>
          <section className="filters-dialog__filters-section">
            <GridCustom fullwidth flexM>
              <FieldWithLabel
                label="Program Operacyjny"
                tag="label"
                selectRef={React.createRef()}
              >
                <Select
                  screenReaderStatus={() => {
                    return "Wybierz opcję z listy rozwijanej";
                  }}
                  className="select-custom"
                  isMulti
                  options={this.state.dictionaries.programOperacyjny.map(
                    ([value, label]) => ({ value, label })
                  )}
                  placeholder="Zacznij wpisywać..."
                  value={filters.programme}
                  onChange={(selectedOption) =>
                    this.handleChangeFilter("programme", selectedOption || [])
                  }
                  styles={customSelectStyles}
                  noOptionsMessage={() => "Brak wybranej opcji"}
                />
              </FieldWithLabel>
              <FieldWithLabel
                label="Kategoria przedmiotu zamówienia"
                tag="label"
                selectRef={React.createRef()}
              >
                <Select
                  screenReaderStatus={() => {
                    return "Wybierz opcję z listy rozwijanej";
                  }}
                  className="select-custom"
                  options={[EMPTY_VALUE].concat(
                    ...this.state.dictionaries.przedmiotZamowieniaKategoria.map(
                      (t) => ({
                        value: t.id,
                        label: t.nazwa,
                      })
                    )
                  )}
                  value={filters.category || EMPTY_VALUE}
                  onChange={(selectedOption) => {
                    this.handleChangeFilter(
                      "category",
                      selectedOption.value === 0 ? null : selectedOption
                    );
                  }}
                  styles={customSelectStyles}
                  noOptionsMessage={() => "Brak wybranej opcji"}
                />
              </FieldWithLabel>
            </GridCustom>
            <GridCustom fullwidth flexM>
              <FieldWithLabel
                label="Podkategoria"
                aria-label={
                  filters.category !== null
                    ? null
                    : "Podkategoria, pole nieaktywne. Aby wybrać podkategorię, musisz wybrać kategorię przedmiotu zamówienia."
                }
                selectRef={React.createRef()}
                tag="label"
                tabIndex={filters.category !== null ? null : "0"}
              >
                <Select
                  screenReaderStatus={() => {
                    return "Wybierz opcję z listy rozwijanej";
                  }}
                  className="select-custom"
                  isDisabled={filters.category === null}
                  options={
                    filters.category === null
                      ? [EMPTY_VALUE]
                      : [EMPTY_VALUE].concat(
                          ...this.state.dictionaries.przedmiotZamowieniaKategoria
                            .filter(
                              (k) =>
                                parseInt(k.id) ===
                                parseInt(filters.category?.value)
                            )[0]
                            .podkategorie.map((p) => ({
                              value: p.id,
                              label: p.nazwa,
                            }))
                        )
                  }
                  value={filters.subcategory || EMPTY_VALUE}
                  onChange={(selectedOption) =>
                    this.handleChangeFilter(
                      "subcategory",
                      selectedOption.value === 0 ? null : selectedOption
                    )
                  }
                  styles={customSelectStyles}
                  aria-hidden={filters.category === null}
                  aria-label={filters.category !== null && "Podkategoria"}
                  noOptionsMessage={() => "Brak wybranej opcji"}
                />
              </FieldWithLabel>
              <FormKodCPV
                isMulti
                initial={filters.cpvItem}
                addKodCPV={(selectedOptions) =>
                  this.handleChangeFilter("cpvItem", selectedOptions || [])
                }
              />
            </GridCustom>
            <GridCustom fullwidth flexM>
              <FieldWithLabel
                label="Miejsce realizacji"
                tag="label"
                selectRef={React.createRef()}
              >
                <Select
                  screenReaderStatus={() => {
                    return "Wybierz opcję z listy rozwijanej";
                  }}
                  className="select-custom"
                  isMulti
                  options={this.state.dictionaries.miejsceRealizacji.map(
                    (m) => ({
                      value: m[0],
                      label: m[1],
                    })
                  )}
                  placeholder="Zacznij wpisywać..."
                  value={filters.fulfillmentPlaces}
                  onChange={(selectedOption) =>
                    this.handleChangeFilter(
                      "fulfillmentPlaces",
                      selectedOption || []
                    )
                  }
                  styles={customSelectStyles}
                  noOptionsMessage={() => "Brak wybranej opcji"}
                />
              </FieldWithLabel>
              <FieldWithLabel
                label="Numer identyfikacyjny ogłoszeniodawcy"
                tag="label"
                labelFor="filters_numer_identyfikacyjny_ogloszeniodawcy_id"
              >
                <TextFieldCustom
                  aria-describedby={
                    (this.state.errors.errorNipOgloszeniodawcy || "").length > 0
                      ? "filters_numer_identyfikacyjny_ogloszeniodawcy_error"
                      : null
                  }
                  aria-label={`Numer identyfikacyjny ogłoszeniodawcy: 255. Wpisz tylko cyfry. Wpisano ${filters.advertiserIdentificationNumber.length} znaków.`}
                  aria-valuemax={255}
                  characterCount
                  className="filters__input"
                  clearFieldContext="numer identyfikacyjny ogłoszeniodawcy"
                  id="filters_numer_identyfikacyjny_ogloszeniodawcy_id"
                  invalid={
                    (this.state.errors.errorNipOgloszeniodawcy || "").length > 0
                  }
                  maxLength={255}
                  onChange={(ev) =>
                    this.handleChangeFilter(
                      "advertiserIdentificationNumber",
                      ev.target.value
                    )
                  }
                  onClear={(ev) =>
                    this.handleChangeFilter(
                      "advertiserIdentificationNumber",
                      ""
                    )
                  }
                  onKeyUp={(ev) =>
                    this.handleChangeFilter(
                      "advertiserIdentificationNumber",
                      ev.target.value
                    )
                  }
                  value={filters.advertiserIdentificationNumber}
                />
                {(this.state.errors.errorNipOgloszeniodawcy || "").length >
                  0 && (
                  <Text
                    error
                    id="filters_numer_identyfikacyjny_ogloszeniodawcy_error"
                  >
                    {this.state.errors.errorNipOgloszeniodawcy}
                  </Text>
                )}
              </FieldWithLabel>
            </GridCustom>
          </section>
          <GridCustom flexEnd>
            <ButtonStyled onClick={this.props.closeFilters} cancel>
              Anuluj
            </ButtonStyled>
            <ButtonStyled onClick={this.handleApplyFilters} primary>
              Zastosuj kryteria
            </ButtonStyled>
          </GridCustom>
        </>
      );
    }
  }
}

export { FavoritesFilters };
