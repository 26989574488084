import {
    ADVERTISEMENT_STATUS_FILTERS_CHOICES,
    CZY_PROJEKT_CHOICES,
    PROJECTS_MANAGEMENTS_CHOICES,
    RODZAJ_ADRESU_FILTERS_CHOICES,
    TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES,
    USER_STATUS_CHOICES
} from './const';


const ADVERTISEMENTS_CLEANED_FILTERS = {
    ktoUtworzyl: 0,
    dataUtworzenia: '',
    ktoModyfikowal: 0,
    dataModyfikacji: '',
    terminOfert: '',
    tytul: '',
    ogloszenie_numer: '',
    status: 0,
};

const PROJECTS_CLEANED_FILTERS = {
    calyNumer: '',
    nazwa: '',
    typ: {value: CZY_PROJEKT_CHOICES[0][0], label: CZY_PROJEKT_CHOICES[0][1]},
};

const SEARCH_CLEANED_FILTERS = {
    title: '',
    publicationDateRange: {
        type: null,
        from: '',
        to: '',
    },
    submissionDeadlineRange: {
        type: null,
        from: '',
        to: '',
    },
    status: ['PUBLISHED', ],
    advertiserName: '',
    advertiserIdentificationNumber: '',
    category: null,
    subcategory: null,
    cpvItem: [],
    fulfillmentPlaces: [],
    programme: [],
};

const USERS_CLEANED_FILTERS = {
    identifier: '',
    email: '',
    forename: '',
    surname: '',
    status: {value: USER_STATUS_CHOICES[0][0], label: USER_STATUS_CHOICES[0][1]},
};


const FAVORITES_ADVERT_CLEANED_FILTERS = {
    status: ['PUBLISHED', ],
    advertiserIdentificationNumber: '',
    category: null,
    subcategory: null,
    cpvItem: [],
    fulfillmentPlaces: [],
    programme: [],
    mode: 'favorite'
};

const PROJECTS_MANAGEMENTS_CLEANED_FILTERS = {
    userEmail: '',
    userForename: '',
    userSurname: '',
    type: {value: PROJECTS_MANAGEMENTS_CHOICES[0][0], label: PROJECTS_MANAGEMENTS_CHOICES[0][1]},
    fullNumber: '',
    name: '',
    perspective: null,
    programme: null,
    priority: null,
    activity: null,
    institution: null,
    region: null,
};

const ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS = {
    userEmail: '',
    userForename: '',
    userSurname: '',
    advertiserName: '',
    // Poland
    advertiserAddressType: RODZAJ_ADRESU_FILTERS_CHOICES.filter(
        choice => choice.value === 'all')[0],
    // NIP
    advertiserIdentificationNumberType:
        TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES.filter(
            choice => choice.value === 'all')[0],
    advertiserIdentificationNumber: '',
    advertiserAddressCountry: '',
    advertiserAddressVoivodeship: '',
    advertiserAddressPoviat: '',
    advertiserAddressCommune: '',
    advertiserAddressLocality: '',
    announcementStatus: ADVERTISEMENT_STATUS_FILTERS_CHOICES[0],
    announcementNumber: '',
    title: '',
    announcementProjectEnrollmentPerspective: null,
    announcementProjectEnrollmentProgramme: null,
    announcementProjectEnrollmentPriority: null,
    announcementProjectEnrollmentActivity: null,
    announcementProjectEnrollmentInstitution: null,
    announcementProjectEnrollmentRegion: null,
    announcementProjectEnrollmentFullNumber: '',
    announcementPublicationDateFrom: '',
    announcementPublicationDateTo: '',
    submissionDeadlineFrom: '',
    submissionDeadlineTo: '',
};

const filterAdvertisements = (advertisementList, filtersAreOn, filters) => {
    if (!filtersAreOn) {
        return advertisementList
    }

    let advertisements = advertisementList.slice();
    for (let [name, value] of Object.entries(filters)) {
        if (filters[name] !== ADVERTISEMENTS_CLEANED_FILTERS[name]) {
            if (['ktoUtworzyl', 'ktoModyfikowal'].includes(name)) {
                advertisements = advertisements.filter(o => o.ogloszenie[name].id === value);
            } else if (name === 'status') {
                advertisements = advertisements.filter(o => o.ogloszenie.status.id === value);
            } else if (name === 'tytul') {
                advertisements = advertisements.filter(o => o[name] !== null && o[name].toLowerCase().includes(value.toLowerCase()));
            } else if (name === 'ogloszenie_numer') {
                const [attr, subattr] = name.split('_');
                advertisements = advertisements.filter(o => {
                    const adValue = o[attr][subattr];
                    return adValue !== null && adValue.toLowerCase().includes(value.toLowerCase())
                });
            } else if (name === 'terminOfert') {
                advertisements = advertisements.filter(o => {
                    let terminOfert = o[name];
                    if (!!terminOfert) {
                        terminOfert = terminOfert.slice(0, 10);
                    }
                    return terminOfert === value;
                });
            } else {
                advertisements = advertisements.filter(o => o[name] === value);
            }
        }
    }
    return advertisements
}

const filterProjects = (projectList, filtersAreOn, filters) => {
    if (!filtersAreOn) {
        return projectList
    }

    for (let [name, value] of Object.entries(filters)) {
        if (filters[name] !== PROJECTS_CLEANED_FILTERS[name]) {
            if (name === 'typ') {
                if (value.value !== 'all') {
                    projectList = projectList.filter(p => (p.typ === 'project').toString() === value.value);
                }
            } else {
                projectList = projectList.filter(
                    p => p[name] !== null && p[name].toLowerCase().includes(value.toLowerCase()));
            }
        }
    }
    return projectList
}


const getSearchFiltersCount = (filters) => {
    let counter = 0;
    for (let [name, value] of Object.entries(filters)) {
        if (['status', 'cpvItem', 'fulfillmentPlaces', 'programme'].includes(name)) {
            // list counters
            if (value.length) {
                counter++;
            }
        } else if (['publicationDateRange', 'submissionDeadlineRange'].includes(name)) {
            if (Object.values(value).some(v => !!v)) {
                counter++;
            }
        } else if (!!value) {
            counter++;
        }
    }
    return counter
}

const getUsersFiltersCount = (filters) => {
    let counter = 0;
    for (let [name, value] of Object.entries(filters)) {
        if (name === 'status' || USERS_CLEANED_FILTERS[name] !== value) {
            counter++;
        }
    }
    return counter
}

const getFavoritesAdvertFiltersCount = (filters) => {
    let counter = 0;
    for (let [name, value] of Object.entries(filters)) {
        if (FAVORITES_ADVERT_CLEANED_FILTERS[name] !== value) {
            
            if(!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
            {
                counter++;
            }
            
        }
    }
    return counter
}

const getOffersFiltersCount = (filters, emptyFilters) => {
    let counter = 0;
    for (let [name, value] of Object.entries(filters)) {
        if (['status', 'isAdmin'].includes(name) || emptyFilters[name] !== value) {
            counter++;
        }
    }
    return counter
}

const getProjectsManagementsFiltersCount = (filters) => {
    if (filters === null) { return 0 }
    let counter = 0;
    for (let [name, value] of Object.entries(filters)) {
        if (['type', ].includes(name) || PROJECTS_MANAGEMENTS_CLEANED_FILTERS[name] !== value) {
            counter++;
        }
    }
    return counter
};

const getAdvertisementsManagementsFiltersCount = (filters) => {
    if (filters === null) { return 0 }
    let counter = 0;
    for (let [name, value] of Object.entries(filters)) {
        if ([
            'advertiserAddressVoivodeship',
            'advertiserAddressPoviat',
            'advertiserAddressCommune'
        ].includes(name)) {
            // don't count it, because they are not inputs
            continue
        }
        if (name === 'advertiserIdentificationNumberType') {
            if (filters.advertiserAddressType.value === 'poland') {
                // identification number select is shown
                counter++;
            }
            continue
        }
        if (['advertiserAddressType', 'announcementStatus'].includes(name) ||
                ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS[name] !== value ) {
            counter++;
        }
    }
    return counter
};


export {
    ADVERTISEMENTS_CLEANED_FILTERS,
    ADVERTISEMENTS_MANAGEMENTS_CLEANED_FILTERS,
    PROJECTS_CLEANED_FILTERS,
    PROJECTS_MANAGEMENTS_CLEANED_FILTERS,
    SEARCH_CLEANED_FILTERS,
    USERS_CLEANED_FILTERS,
    FAVORITES_ADVERT_CLEANED_FILTERS,
    filterAdvertisements,
    filterProjects,
    getAdvertisementsManagementsFiltersCount,
    getOffersFiltersCount,
    getProjectsManagementsFiltersCount,
    getSearchFiltersCount,
    getUsersFiltersCount,
    getFavoritesAdvertFiltersCount
};
