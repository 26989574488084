import React from 'react';

import { OfertyOwnerClosedStatus } from './OfertyOwnerClosedStatus';


class OfertyOffererClosedStatus extends OfertyOwnerClosedStatus {
    showAttachments = false;
    showFullVariants = false;

    // rendering

    renderElements() {
        return (
            <>
                {this.renderMyOfferButton()}
                {this.renderContent()}
                {this.state.previewOn && this.renderPreview()}
            </>
        )
    }
}


export { OfertyOffererClosedStatus };
