export const API_URLS = {
    // users
    users: {
        path: '/api/admin/users',
    },
    usersDetails: {
        getUrl: (userId) => `/api/admin/users/${userId}`,
    },
    usersFindUser: {
        url: '/api/admin/users/find',
    },
    /* watch modes for this url
        modeUserCurrent - needs 'authorized' as current user id - current user's permissions
        modeUserCustom - needs 'authorized' as requested user id - requested user's permissions
        modeContext - don't add 'authorized' to GET parameters - all permissions
    */
    usersPermissionsAdvertisementsProjectsEnrollments: {
        path: '/api/admin/permissions_ads_projects_enrollments',
    },
    usersPermissionsAdvertisementsProjectsEnrollmentsDetails: {
        path: '/api/admin/permissions_ads_projects_enrollments/package',
        description: 'used for single permission details',
    },
    /* watch modes for this url
        modeUserCurrent - needs 'authorized' as current user id - current user's permissions
        modeUserCustom - needs 'authorized' as requested user id - requested user's permissions
        modeContext - don't add 'authorized' to GET parameters - all permissions
    */
    usersPermissionsDictionaries: {
        path: '/api/admin/permissions_dictionary',
    },
    usersPermissionsDictionariesDetails: {
        path: '/api/admin/permissions_dictionary/package',
        description: 'used for single permission details',
    },
    /* watch modes for this url
        modeUserCurrent - needs 'authorized' as current user id - current user's permissions
        modeUserCustom - needs 'authorized' as requested user id - requested user's permissions
        modeContext - don't add 'authorized' to GET parameters - all permissions
    */
    usersPermissionsContent: {
        path: '/api/admin/permissions_content',
    },
    usersPermissionsContentDetails: {
        path: '/api/admin/permissions_content/package',
        description: 'used for single permission details',
    },
    /* watch modes for this url
        modeUserCurrent - needs 'authorized' as current user id - current user's permissions
        modeUserCustom - needs 'authorized' as requested user id - requested user's permissions
        modeContext - don't add 'authorized' to GET parameters - all permissions
    */
    usersPermissionsUsers: {
        path: '/api/admin/permissions_users',
    },
    usersPermissionsUsersDetails: {
        path: '/api/admin/permissions_users/package',
        description: 'used for single permission details',
    },
    usersPermissionsContentDictionariesUsers: {
        path: '/api/admin/permissions_cont_dict_users',
    },
    usersPermissionsHistoryAdvertisementsProjectsEnrollments: {
        path: '/api/admin/permissions_history_ads_projects_enrollments',
    },
    usersPermissionsHistoryContentDictionariesUsers: {
        path: '/api/admin/permissions_history_cont_dict_users',
    },
    usersPermissionsHistoryContent: {
        path: '/api/admin/permissions_history_content',
    },
    usersPermissionsHistoryDictionaries: {
        path: '/api/admin/permissions_history_dictionaries',
    },
    usersPermissionsHistoryUsers: {
        path: '/api/admin/permissions_history_users',
    },
    // content administration
    helpAdmin: {
        path: '/api/admin/help'
    },
    helpAdminDictionaries: {
        path: '/api/admin/help/dictionaries',
    },
    helpAdminDetails: {
        getUrl: (helpId) => `/api/admin/help/${helpId}`
    },
    statementsAdmin: {
        path: '/api/admin/statements'
    },
    statementsAdminDetails: {
        getUrl: (statementId) => `/api/admin/statements/${statementId}`
    },
    cookiesAdmin: {
        path: '/api/admin/cookies',
    },
    generalContentAdmin: {
        path: '/api/admin/general-content',
    },
    generalContentAdminDetails: {
        getUrl: (generalContentId) => `/api/admin/general-content/${
            generalContentId}`,
    },
    regulations: {
        path: '/api/regulations',
    },
    regulationsAdmin: {
        path: '/api/admin/regulations',
    },
    regulationsAdminDetails: {
        // Attention! It doesn't return a single regulation details
        path: '/api/admin/regulations/details',
    },
    // implementation levels
    perspectives: {
        path: '/api/perspectives',
    },
    perspectivesAdmin: {
        path: '/api/admin/dictionaries_perspective',
    },
    programmes: {
        getUrl: (perspectiveId = 0) =>
            `/api/programme/perspective/${perspectiveId}`,
    },
    programmesAdmin: {
        getUrl: (perspectiveId = 0) =>
            `/api/admin/dictionaries_programme/perspective/${perspectiveId}`,
    },
    programmesAdd: {
        path: '/api/admin/dictionaries_programme',
    },
    programmesEdit: {
        getUrl: (programmeId) => `/api/admin/dictionaries_programme/${programmeId}`,
    },
    priorities: {
        getUrl: (programmeId) => `/api/priority/programme/${programmeId}`
    },
    prioritiesAdmin: {
        getUrl: (programmeId) =>
            `/api/admin/dictionaries_priority/programme/${programmeId}`
    },
    prioritiesAdd: {
        path: '/api/admin/dictionaries_priority',
    },
    prioritiesEdit: {
        getUrl: (priorityId) => `/api/admin/dictionaries_priority/${priorityId}`,
    },
    activities: {
        getUrl: (priorityId) => `/api/activity/priority/${priorityId}`
    },
    activitiesAdmin: {
        getUrl: (priorityId) =>
            `/api/admin/dictionaries_activity/priority/${priorityId}`
    },
    activitiesAdd: {
        path: '/api/admin/dictionaries_activity',
    },
    activitiesEdit: {
        getUrl: (activityId) => `/api/admin/dictionaries_activity/${activityId}`,
    },
    regions: {
        getUrl: (activityId) => `/api/region/activity/${activityId}`
    },
    regionsAdmin: {
        getUrl: (activityId) =>
            `/api/admin/dictionaries_region/activity/${activityId}`
    },
    regionsAdd: {
        path: '/api/admin/dictionaries_region',
    },
    regionsEdit: {
        getUrl: (regionId) => `/api/admin/dictionaries_region/${regionId}`,
    },
    institutions: {
        getUrl: (activityId) => `/api/institution/activity/${activityId}`
    },
    institutionsAdmin: {
        getUrl: (activityId) =>
            `/api/admin/dictionaries_institution/activity/${activityId}`
    },
    institutionsAdd: {
        path: '/api/admin/dictionaries_institution',
    },
    institutionsEdit: {
        getUrl: (institutionId) =>
            `/api/admin/dictionaries_institution/${institutionId}`,
    },
}
