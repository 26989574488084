import React from 'react';
import { Link } from 'react-router-dom';

import { Message } from '../../common';


const BladLogowania = (props) => {
    return (
        <Message
            error
            messageIcon="highlight_off"
            messageTitle="Wystąpił błąd logowania." >
            Przejdź na <Link to={'/'} className="link-text">stronę główną</Link>.
        </Message>
    )
}


export { BladLogowania };
