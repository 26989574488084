import React from 'react';
import { Icon } from '@rmwc/icon';

import { GridCustom } from './GridCustom';
import { Text } from './Text';

const OfferStatus = (props) => {
    const {
        className,
        statusIcon,
        statusName,
    } = props;

    return (
        <div className={`offer__status ${className || ''}`}>
            <Text label aria-hidden="true">Status oferty</Text>
            <GridCustom noWrap centerHorizontal>
                <Icon aria-hidden="true" icon={statusIcon} className="offer__status-icon" />
                <Text tag="h2" className="offer__status-name" id="statusName_id"><span className="sr-only">Status oferty: </span>{statusName}</Text>
            </GridCustom>
        </div>
    )
}

export { OfferStatus };
