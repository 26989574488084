// <GridCustom /> without props gives you container with:
// display: flex
// flex-wrap: wrap
// justify-content: flex-start

// center prop makes the container content centered
// centerHorizontal prop makes the container content centered horizontaly
// centerVertical prop makes the container content centered verticaly
// flexS prop makes the container displayed: flex from 600px up
// flexM prop makes the container displayed: flex from 800px up
// flexL prop makes the container displayed: flex from 1150px up
// fullwidth prop gives justify-content: space-between
// flexTop prop makes the container content justify-content: flex-start
// flexEnd prop gives justify-content: flex-end
// noWrap prop gives flex-wrap: nowrap

// <GridCustom flexEnd className=" box__button-container"> - places button container always on bottom right



import React from 'react';

const GridCustom = (props) => {
    const {
        center,
        centerHorizontal,
        centerVertical,
        className,
        flexBottom,
        flexEnd,
        flexS,
        flexM,
        flexL,
        flexTop,
        fullheight,
        fullwidth,
        keyName,
        noWrap,
        tag,
    } = props;

    let gridClassName = 'grid-custom ';

    if (flexS) {
        gridClassName += 'grid-custom--flex-s '
    } else if (flexM) {
        gridClassName += 'grid-custom--flex-m '
    } else if (flexL) {
        gridClassName += 'grid-custom--flex-l '
    };

    fullheight && (gridClassName += 'grid-custom--full-height ');
    fullwidth && (gridClassName += 'grid-custom--full-width ');
    noWrap && (gridClassName += 'grid-custom--one-row ');
    center && (gridClassName += 'grid-custom--center ');
    centerHorizontal && (gridClassName += 'grid-custom--center-h ');
    centerVertical && (gridClassName += 'grid-custom--center-v ');
    flexEnd && (gridClassName += 'grid-custom--end ');
    flexTop && (gridClassName += 'grid-custom--top ');
    flexBottom && (gridClassName += 'grid-custom--bottom ');
    className && (gridClassName += className);

    const GridTag = tag ? tag : 'section';

    return (
        <GridTag
            className={gridClassName}
            key={keyName}
        >
            {props.children}
        </GridTag>
    );
}

export { GridCustom };
