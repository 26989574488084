import React from 'react';
import { Oferta } from './Oferta';
import { DialogCustom } from '../../common';


const OfertaPreview = (props) => {
    return (
        <DialogCustom
            className="dialog-long-content"
            onClose={props.onClosePreview}
        >
            <Oferta
                ogloszenieId={props.ogloszenieId}
                ofertaId={props.ofertaId}
                preview={true}
                onClosePreview={props.onClosePreview} />
        </DialogCustom>
    )
};


export { OfertaPreview }
