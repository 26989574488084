import React, { useContext, useEffect } from 'react';

import {
    AdvertisementContext
} from '../../../../context/advertisementContext';


export const withStepConfiguration = (StepComponent, stepName) => {

    return (props) => {
        const context = useContext(AdvertisementContext);

        useEffect(() => {
            if (!props.isPreview) {
                window.scrollTo(0, 0);
            }

            // clear step error in menu
            context.clearStepError(stepName);

            if (window.formOgloszenie) {
                window.formOgloszenie.computeProgress();
            }
            return () => {
                if (window.formOgloszenie) {
                    window.formOgloszenie.computeProgress();
                }
            }
        }, []); // eslint-disable-line react-hooks/exhaustive-deps

        return <StepComponent {...props} />
    }
}
