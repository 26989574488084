import React from 'react';
import {
    DataTableCell,
    DataTableRow,
} from '@rmwc/data-table';

import { reformatDanePodmiotu } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { Box, Breadcrumps, ButtonStyled, FieldWithLabel, GridCustom, TableCustom, Text } from '../../common';
import { BaseComponent, DanePodmiotu } from '../common';
import { FormDanePodmiotu } from './FormDanePodmiotu';
import { FormOsobaDoKontaktu } from './FormOsobaDoKontaktu';


class ProfilUzytkownika extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        let uzytkownik = getSerializedObject(data).uzytkownik;
        uzytkownik.danePodmiotu = reformatDanePodmiotu(uzytkownik.danePodmiotu);
        this.setState({
            initializingOn: false,
            uzytkownik: uzytkownik,
        });
    }

    handleSaveDanePodmiotu = (data) => {
        this.setState(prevState => ({
            changeDataOn: false,
            uzytkownik: Object.assign({}, prevState.uzytkownik, {danePodmiotu: data}),
        }));
    }

    handleSaveOsobaDoKontaktu = (data, isNew) => {
        this.setState(prevState => {
            let osobyDoKontaktu;
            if (isNew) {
                osobyDoKontaktu = [...prevState.uzytkownik.osobyDoKontaktu, data];
            } else {
                osobyDoKontaktu = prevState.uzytkownik.osobyDoKontaktu.map(o => o.id === data.id ? data : o);
            }
            return {
                formOsobaDoKontaktuOn: false,
                editedOsobaDoKontaktu: null,
                uzytkownik: Object.assign({}, prevState.uzytkownik, {osobyDoKontaktu: osobyDoKontaktu}),
            }
        });
    }

    // helpers

    getAdditionalState(props) {
        return {
            changeDataOn: false,
            formOsobaDoKontaktuOn: false,
            uzytkownik: {},
            editedOsobaDoKontaktu: null,
        }
    }

    getFetchUrl() {
        return '/api/users/0'
    }

    getFetchError(message) {
        return `Nie udało się pobrać danych profilu. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania danych profilu wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__current">Profil użytkownika</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        document.title = "Baza Konkurencyjności - mój profil";
        return (
            <>
                {this.renderProfilUzytkownika()}
                {this.renderDanePodmiotu()}
                {this.renderOsobyDoKontaktu()}
                {this.state.changeDataOn && (
                    <FormDanePodmiotu
                        data={this.state.uzytkownik.danePodmiotu}
                        closeDanePodmiotuForm={() => this.setState({changeDataOn: false})}
                        saveDanePodmiotu={this.handleSaveDanePodmiotu} />
                )}
                {this.state.formOsobaDoKontaktuOn && (
                    <FormOsobaDoKontaktu
                        data={this.state.editedOsobaDoKontaktu}
                        closeOsobaDoKontaktuFormForm={() => this.setState(
                            {formOsobaDoKontaktuOn: false, editedOsobaDoKontaktu: null}
                        )}
                        saveOsobaDoKontaktu={this.handleSaveOsobaDoKontaktu} />
                )}
            </>
        )
    }

    renderProfilUzytkownika() {
        const uzytkownik = this.state.uzytkownik;
        return (
            <>
                <GridCustom fullwidth>
                    <Text tag="h2" mainHeader>Mój profil w Bazie Konkurencyjności</Text>
                    <GridCustom flexTop>
                        <a href='/konto/' className="mdc-button mdc-button--unelevated btn--primary">Ustawienia konta</a>
                    </GridCustom>
                </GridCustom>
                <Box titleOneRow="Twoje dane" className="users__box">
                    <FieldWithLabel label="Imię i nazwisko">
                        <Text>{uzytkownik.imie} {uzytkownik.nazwisko}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Adres e-mail">
                        <Text>{uzytkownik.email}</Text>
                    </FieldWithLabel>
                </Box>
            </>
        )
    }

    renderDanePodmiotu() {
        let content, button;

        if (!Object.keys(this.state.uzytkownik.danePodmiotu).length) {
            content = <Text error>Brak zdefiniowanych danych podmiotu.</Text>;
            button = (
                <ButtonStyled onClick={() => this.setState({changeDataOn: true})} add>
                    Dodaj dane podmiotu
                </ButtonStyled>
            );
        } else {
            content = <DanePodmiotu danePodmiotu={this.state.uzytkownik.danePodmiotu} />;
            button = (
                <ButtonStyled onClick={() => this.setState({changeDataOn: true})} lite>
                    Edytuj dane podmiotu
                </ButtonStyled>
            );
        }

        return (
            <Box titleOneRow="Dane podmiotu" className="users__box">
                <GridCustom flexEnd>
                    {button}
                </GridCustom>
                {content}
            </Box>
        )
    }

    renderOsobyDoKontaktu() {
        const osobyDoKontaktu = this.state.uzytkownik.osobyDoKontaktu || [];
        let content = null;
        if (!osobyDoKontaktu.length) {
            content = <Text error>Brak zdefiniowanych osób do kontaktu.</Text>;
        } else {
            content = (
                <TableCustom tableHeaderList={[
                    'Imię i nazwisko',
                    'Numer telefonu',
                    'Adres e-mail',
                    'Edytuj dane',
                ]}>
                    {osobyDoKontaktu.map(o => this.renderOsobaDoKontaktu(o))}
                </TableCustom>
            );
        }
        return (
            <Box titleOneRow="Osoby do kontaktu" className="users__box">
                <GridCustom flexEnd>
                    <ButtonStyled onClick={() => this.setState({formOsobaDoKontaktuOn: true})} add>Dodaj osobę do kontaktu</ButtonStyled>
                </GridCustom>
                {content}
            </Box>
        )
    }

    renderOsobaDoKontaktu(osoba) {
        const {
            email,
            id,
            imie,
            nazwisko,
            numerTelefonu
        } = osoba;

        return (
            <DataTableRow key={id}>
                <DataTableCell>
                    <Text>{imie} {nazwisko}</Text>
                </DataTableCell>
                <DataTableCell>
                    <Text>{numerTelefonu || '-'}</Text>
                </DataTableCell>
                <DataTableCell className="table__width-needy-cell">
                    <Text>{email}</Text>
                </DataTableCell>
                <DataTableCell>
                    <ButtonStyled
                        primary
                        onClick={() => this.setState({formOsobaDoKontaktuOn: true, editedOsobaDoKontaktu: osoba})}>
                        Edytuj<span className="sr-only"> {imie} {nazwisko}</span>
                    </ButtonStyled>
                </DataTableCell>
            </DataTableRow>
        )
    }
}


export { ProfilUzytkownika };
