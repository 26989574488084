// https://jpuri.github.io/react-draft-wysiwyg/

import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { countWysiwygChars } from '../../helpers';
import { GridCustom } from './GridCustom';
import { Text } from './Text';


class WysiwygEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {counter: countWysiwygChars(props.initialContentState)};

        this.handleContentStateChange = this.handleContentStateChange.bind(this);
    }

    // handlers

    handleContentStateChange(contentState) {
        this.props.onContentStateChange(contentState);
        this.setState({counter: countWysiwygChars(contentState)});
    }

    // rendering

    render() {

        const props_ = Object.assign({}, this.props, {
            onContentStateChange: this.handleContentStateChange
        });

        return (
            <section className={this.props.wysiwygClassName || 'wysiwyg'}>
                <Editor {...props_} wrapperClassName="wysiwyg__wrapper" editorClassName="wysiwyg__editor" stripPastedStyles={true} />
                <GridCustom centerVertical flexEnd><Text className="wysiwyg__counter">{this.state.counter}{!this.props.maxLength ? '' : ' / ' + this.props.maxLength}</Text></GridCustom>
            </section>
        );
    }
}

export { WysiwygEditor };
