import React, { useContext, useMemo, useState } from 'react';

import { UserContext } from '../../../context/user-context';
import { getPermissionHistoryUsersTitle } from '../../../helpers_permissions';
import { API_URLS } from '../../../urls/api';
import { DialogCustom, Text } from '../../common';
import { PermissionList } from './PermissionList';
import { PermissionsHistory } from './PermissionsHistory';
import { PermissionListRow } from './PermissionListRow';
import { PermissionUsers } from './PermissionUsers';
import { SearchUserForm } from './SearchUserForm';
import { UserHeader } from './UserHeader';
import {
    UserPermissionsAdministrationForm
} from './UserPermissionsAdministrationForm';
import {
    UserPermissionsImplementationLevelForm
} from './UserPermissionsImplementationLevelForm';
import {
    UserPermissionsRemovingPermissionDialog
} from './UserPermissionsRemovingPermissionDialog';


const ADMINISTRATION_INITIAL_REQUEST_ID = 1;
const IMPLEMENTATION_LEVEL_INITIAL_REQUEST_ID = 1;
const TABLE_HEADER_LIST = ['Nazwa', 'Zarządzanie uprawnieniami'];


export const Permissions = (props) => {
    const { user } = useContext(UserContext);
    const { id: currentUserId } = user;

    // possible choices of addPermissionMode: administration, implementationLevel
    const [addPermissionMode, setAddPermissionMode] = useState(null);
    const [userForPermissionAdding, setUserForPermissionAdding] = useState(null);

    const [
        administrationPermissionsRequestId,
        setAdministrationPermissionsRequestId
    ] = useState(ADMINISTRATION_INITIAL_REQUEST_ID);
    const [
        implementationLevelPermissionsRequestId,
        setImplementationLevelPermissionsRequestId
    ] = useState(IMPLEMENTATION_LEVEL_INITIAL_REQUEST_ID);

    /*
        activeHistoryPermission/activeUsersPermission structure:
        {
            isImplementationLevelType: <true || false>,
            permission: {
                type: <contentAdministration || usersAdministration>,   // only if not isImplementationLevelType
                perspective: <dict>,   // only if isImplementationLevelType; should be set to distinguish regions and institutions
                programme: <dict>,   // only if isImplementationLevelType; at least should be set
                priority: <dict>,   // only if isImplementationLevelType; it's possible to not set at all
                activity: <dict>,   // only if isImplementationLevelType; it's possible to not set at all
                institution: <dict>,   // only if isImplementationLevelType; it's possible to not set at all
                region: <dict>,   // only if isImplementationLevelType; it's possible to not set at all
            }
        }
    */
    const [
        activeHistoryPermission,
        setActiveHistoryPermission
    ] = useState(null);
    const [
        activeUsersPermission,
        setActiveUsersPermission
    ] = useState(null);
    const [
        lastActiveUsersPermission,
        setLastActiveUsersPermission
    ] = useState(null);

    function handleCloseHistory() {
        setActiveHistoryPermission(null);
    }

    function handleCloseUsers() {
        setActiveUsersPermission(null);
    }

    function handleOpenHistory(permission) {
        if (activeUsersPermission !== null) {
            handleCloseUsers();
        }
        setActiveHistoryPermission(permission);
    }

    function handleOpenUsers(permission) {
        if (activeHistoryPermission !== null) {
            handleCloseHistory();
        }
        setActiveUsersPermission(permission);
        setLastActiveUsersPermission(permission);
    }

    const [editedPermission, setEditedPermission] = useState(null);

    function handleSetEditedPermission(isImplementationLevelType, permission) {
        handleCloseUsers();
        setEditedPermission({isImplementationLevelType, permission});
    }

    function handleCloseEditPermission(data) {
        // close dialog
        setEditedPermission(null);
        // turn user list back
        setActiveUsersPermission(lastActiveUsersPermission);
    }

    function handleCloseEditPermissionSuccess(data) {
        // check if current user permission was changed;
        // if yes - refresh permissions list
        if ((editedPermission.permission.authorized || {}).id === currentUserId) {
            if (editedPermission.isImplementationLevelType) {
                setImplementationLevelPermissionsRequestId(prevId => prevId + 1);
            } else {
                setAdministrationPermissionsRequestId(prevId => prevId + 1);
            }
        }
        handleCloseEditPermission(data);
    }

    const [removedPermission, setRemovedPermission] = useState(null);

    function handleSetRemovedPermission(isImplementationLevelType, permission) {
        handleCloseUsers();
        setRemovedPermission({isImplementationLevelType, permission});
    }

    function handleCloseRemovePermission(data) {
        // close dialog
        setRemovedPermission(null);
        // turn user list back
        setActiveUsersPermission(lastActiveUsersPermission);
    }

    function handleCloseRemovePermissionSuccess(data) {
        // check if current user permission was removed;
        // if yes - refresh permissions list
        if ((removedPermission.permission.authorized || {}).id === currentUserId) {
            if (removedPermission.isImplementationLevelType) {
                setImplementationLevelPermissionsRequestId(prevId => prevId + 1);
            } else {
                setAdministrationPermissionsRequestId(prevId => prevId + 1);
            }
        }
        handleCloseRemovePermission(data);
    }

    const [url, dialogAdditionalFetchData] = useMemo(
        () => {
            let url_ = '';
            let dialogAdditionalFetchData_ = {};
            if (activeHistoryPermission !== null || activeUsersPermission !== null) {
                const isHistoryPermission = activeHistoryPermission !== null;
                const activePermission = activeHistoryPermission ||
                    activeUsersPermission;
                if (activePermission.isImplementationLevelType) {
                    url_ = API_URLS[isHistoryPermission
                        ? 'usersPermissionsHistoryAdvertisementsProjectsEnrollments'
                        : 'usersPermissionsAdvertisementsProjectsEnrollments'
                    ].path;
                    const {
                        programme = null,
                        priority = null,
                        activity = null,
                        institution = null,
                        region = null
                    } = activePermission.permission;
                    dialogAdditionalFetchData_ = {
                        programme: programme !== null ? programme.id : null,
                        priority: priority !== null ? priority.id : null,
                        activity: activity !== null ? activity.id : null,
                        institution: institution !== null ? institution.id : null,
                        region: region !== null ? region.id : null,
                    };
                } else {
                    url_ = activePermission.permission.type === 'usersAdministration'
                        ? API_URLS[isHistoryPermission
                            ? 'usersPermissionsHistoryUsers'
                            : 'usersPermissionsUsers'
                        ].path
                        : activePermission.permission.type === 'contentAdministration'
                            ? API_URLS[isHistoryPermission
                                ? 'usersPermissionsHistoryContent'
                                : 'usersPermissionsContent'
                            ].path
                            : API_URLS[isHistoryPermission
                                ? 'usersPermissionsHistoryDictionaries'
                                : 'usersPermissionsDictionaries'
                            ].path
                }
                if (!isHistoryPermission) {
                    dialogAdditionalFetchData_['mode'] = 'modeContext';
                }
            }
            return [url_, dialogAdditionalFetchData_]
        },
        [activeHistoryPermission, activeUsersPermission]
    );

    const permissionListAdditionalFetchData = useMemo(
        () => ({mode: 'modeUserCurrent', authorized: currentUserId}),
        [currentUserId]
    );

    function handleCloseAddPermissionForm(data) {
        // close dialog
        setAddPermissionMode(null);
        setUserForPermissionAdding(null);
    }

    function handleCloseAddPermissionFormSuccess(data) {
        // check if current user permission was added;
        // if yes - refresh permissions list
        if (userForPermissionAdding.id === currentUserId) {
            if (addPermissionMode === 'implementationLevel') {
                setImplementationLevelPermissionsRequestId(prevId => prevId + 1);
            } else {
                setAdministrationPermissionsRequestId(prevId => prevId + 1);
            }
        }
        handleCloseAddPermissionForm(data);
    }

    let PermissionFormComponent = null;
    if (editedPermission !== null) {
        PermissionFormComponent = editedPermission.isImplementationLevelType
            ? UserPermissionsImplementationLevelForm
            : UserPermissionsAdministrationForm;
    } else if (
        ['administration', 'implementationLevel'].includes(addPermissionMode)
    ) {
        PermissionFormComponent = addPermissionMode === 'implementationLevel'
            ? UserPermissionsImplementationLevelForm
            : UserPermissionsAdministrationForm;
    }

    return (
        <>
            <PermissionList
                additionalFetchData={permissionListAdditionalFetchData}
                isImplementationLevelType={false}
                requestId={administrationPermissionsRequestId}
                RowComponent={PermissionListRow}
                tableHeaderList={TABLE_HEADER_LIST}
                title="Administracja"
                url={API_URLS.usersPermissionsContentDictionariesUsers.path}
                withHistory={false}
                onOpenForm={() => setAddPermissionMode('administration')}
                onSetActiveHistoryPermission={handleOpenHistory}
                onSetActiveUsersPermission={handleOpenUsers} />
            <PermissionList
                additionalFetchData={permissionListAdditionalFetchData}
                isImplementationLevelType={true}
                requestId={implementationLevelPermissionsRequestId}
                RowComponent={PermissionListRow}
                tableHeaderList={TABLE_HEADER_LIST}
                title="Poziomy wdrożenia"
                url={API_URLS.usersPermissionsAdvertisementsProjectsEnrollments.path}
                withHistory={false}
                onOpenForm={() => setAddPermissionMode('implementationLevel')}
                onSetActiveHistoryPermission={handleOpenHistory}
                onSetActiveUsersPermission={handleOpenUsers} />
            {activeHistoryPermission !== null && (
                <PermissionsHistory
                    additionalFetchData={dialogAdditionalFetchData}
                    dialogTitle={getPermissionHistoryUsersTitle(
                        activeHistoryPermission, true
                    )}
                    isImplementationLevelType={activeHistoryPermission.isImplementationLevelType}
                    mode="fullHistory"
                    tableHeaderList={[
                        'Data zmiany', "Użytkownik", 'Rodzaj operacji',
                        'Użytkownik wprowadzający'
                    ]}
                    url={url}
                    userId={null}
                    onClose={handleCloseHistory} />
            )}
            {activeUsersPermission !== null && (
                <DialogCustom
                    className="dialog-long-content"
                    dialogTitle={getPermissionHistoryUsersTitle(
                        activeUsersPermission, false)}
                    onClose={handleCloseUsers}>
                    <PermissionUsers
                        activeUsersPermission={activeUsersPermission}
                        additionalFetchData={dialogAdditionalFetchData}
                        isImplementationLevelType={activeUsersPermission.isImplementationLevelType}
                        url={url}
                        onClose={handleCloseUsers}
                        onSetEditedPermission={handleSetEditedPermission}
                        onSetRemovedPermission={handleSetRemovedPermission} />
                </DialogCustom>
            )}
            {removedPermission !== null && (
                <UserPermissionsRemovingPermissionDialog
                    permission={removedPermission}
                    questionSuffix={` użytkownika ${removedPermission.permission.authorized.forename} ${removedPermission.permission.authorized.surname}`}
                    userId={(removedPermission.permission.authorized || {}).id}
                    onCloseRemovedPermissionDialog={handleCloseRemovePermission}
                    onRemovePermissionSuccess={handleCloseRemovePermissionSuccess} />
            )}
            {editedPermission !== null && (
                <DialogCustom
                    className="dialog-long-content"
                    onClose={handleCloseEditPermission}>
                    <Text>Informacje o użytkowniku</Text>
                    <UserHeader
                        user={editedPermission.permission.authorized || {}}
                        withButtons={false} />
                    <PermissionFormComponent
                        initialData={editedPermission.permission}
                        userId={(editedPermission.permission.authorized || {}).id}
                        onCancel={handleCloseEditPermission}
                        onSavePermissionSuccess={handleCloseEditPermissionSuccess} />
                </DialogCustom>
            )}
            {['administration', 'implementationLevel'].includes(addPermissionMode)
                && (userForPermissionAdding === null
                    ? (
                        <DialogCustom
                            className="dialog"
                            onClose={handleCloseAddPermissionForm}>
                            <SearchUserForm
                                addPermissionMode={addPermissionMode}
                                onCancel={handleCloseAddPermissionForm}
                                onFindUser={setUserForPermissionAdding} />
                        </DialogCustom>
                    )
                    : (
                        <DialogCustom
                            className="dialog-long-content"
                            onClose={handleCloseAddPermissionForm}>
                            <Text>Informacje o użytkowniku</Text>
                            <UserHeader
                                user={userForPermissionAdding}
                                withButtons={false} />
                            <PermissionFormComponent
                                userId={userForPermissionAdding.id}
                                onCancel={handleCloseAddPermissionForm}
                                onSavePermissionSuccess={handleCloseAddPermissionFormSuccess} />
                        </DialogCustom>
                    )
                )
            }
        </>
    )
};
