import React from 'react';
import { Button } from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import { Icon } from '@rmwc/icon';
import { Toolbar, ToolbarRow, ToolbarSection, ToolbarTitle } from '@rmwc/toolbar';
import { ThemeProvider } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';
import { MenuSurfaceAnchor, MenuSurface } from '@rmwc/menu';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import '@material/button/dist/mdc.button.css';
import '@rmwc/icon/icon.css';

import { colors } from '../vars/vars';
import { Text } from './Text';


const Box = (props) => {
    const {
        ariaLabelMoreOptions,
        boxTag,
        boxWithMenu,
        className,
        children,
        flexiblewidth,
        fullwidth,
        headerTag,
        invalid,
        selected,
        subtitle,
        titleOneRow,
        titleTwoRows,
        titleWithSubtitle,
        ...otherProps
    } = props;

    const colorsToolbar = {
        primary: colors.primary
    };

    const [open, setOpen] = React.useState(false);

    let menuSection = (
        <ToolbarSection alignEnd className="box__toolbar-section">
            <MenuSurfaceAnchor>
                <Button
                    title={ariaLabelMoreOptions}
                    onClick={e => {
                        setOpen(!open);
                        e.currentTarget.blur();
                    }}
                    unelevated
                    className="btn--icon-only btn--primary" >
                    <span className="sr-only">
                        {ariaLabelMoreOptions}
                        {invalid && '. Uwaga, edytuj, aby poprawić błędy!'}
                    </span>
                    <Icon aria-hidden="true" icon="more_vert" />
                </Button>
                <MenuSurface open={open} onClose={evt => setOpen(false)} role="dialog" >
                    <nav className="box__corner-menu">
                        {boxWithMenu}
                    </nav>
                </MenuSurface>
            </MenuSurfaceAnchor>
        </ToolbarSection>
    );

    let toolbar;
    if (![null, undefined].includes(titleOneRow)) {
        toolbar = (
            <Toolbar>
                <ToolbarRow>
                    <ToolbarSection alignStart className="box__toolbar-section box__title-section">
                        <ToolbarTitle tag={headerTag || 'h2'}>{titleOneRow}</ToolbarTitle>
                    </ToolbarSection>
                    {boxWithMenu && menuSection}
                </ToolbarRow>
            </Toolbar>
        )
    }

    if (![null, undefined].includes(titleTwoRows)) {
        toolbar = (
            <Toolbar theme='primaryBg'>
                <ToolbarRow>
                    <ToolbarSection alignStart style={{zIndex: 0}} className="box__title-section">
                        <Typography use="subtitle2">{subtitle}</Typography>
                    </ToolbarSection>
                    {boxWithMenu && menuSection}
                </ToolbarRow>
                <ToolbarRow>
                    <ToolbarTitle tag={headerTag || 'h2'}>{titleTwoRows}</ToolbarTitle>
                </ToolbarRow>
            </Toolbar>
        )
    }

    if (![null, undefined].includes(titleWithSubtitle)) {
        toolbar = (
            <Toolbar theme='primaryBg'>
                <ToolbarRow>
                    <ToolbarSection alignStart className="box__flex-title box__title-section">
                        <ToolbarTitle tag={headerTag || 'h2'}>{titleWithSubtitle}</ToolbarTitle>
                    </ToolbarSection>
                    {boxWithMenu && menuSection}
                </ToolbarRow>
                <ToolbarRow>
                    <Typography use="subtitle2">{subtitle}</Typography>
                    {invalid ? <Text errorColorReverse style={{ marginRight: 25, marginBottom: 5 }}><Icon aria-hidden="true" icon={{ icon: 'warning', size: 'xsmall' }} style={{marginRight: 3}} /> Błędy!</Text> : ''}
                </ToolbarRow>
            </Toolbar>
        )
    }

    let boxClassName = 'box ';
    fullwidth && (boxClassName += 'box--long-content ');
    flexiblewidth && (boxClassName += 'box--flexible ');
    selected && (boxClassName += 'box--selected ')
    className && (boxClassName += className);

    return (
        <Elevation z='3' tag={boxTag || 'section'} className={boxClassName} theme='background' {...otherProps}>
            <ThemeProvider options={colorsToolbar} >
                {toolbar}
            </ThemeProvider>
            <div className="box__content">
                {children}
            </div>
        </Elevation>
    );
}

export { Box };
