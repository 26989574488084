import React from 'react';
import { Link } from 'react-router-dom';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';

import { URLS } from '../../../urls/frontend';


export const PerspectivesTableRow = ({item}) => {
    return (
        <DataTableRow>
            <DataTableCell>
                <Link to={URLS.managementProgrammesForPerspective.getUrl(item.id)} className="link-text">
                    {item.name}
                </Link>
            </DataTableCell>
        </DataTableRow>
    )
};
