import React, { Component } from 'react';

import { fetchApi } from '../../../fetch';
import {
    getSerializedErrorsFromServer,
    getSerializedObject,
    translateErrorMessage
} from '../../../serializers';
import {
    ButtonStyled, Container, DialogCustom, FieldWithLabel, GridCustom,
    NotFoundPage, NotPermittedPage, Pagination, Select, Text
} from '../../common';
import { customSelectStyles } from '../../vars/vars';


class BaseComponent extends Component {
    message403 = null;

    constructor(props) {
        super(props);

        this.preState(props);
        this.formPreState(props);
        this.state = Object.assign(
            {
                initializingOn: true,
                pageNotFound: false,
                pageNotPermitted: false,
                fetchError: '',
            },
            this.getAdditionalState(props),
            this.getFormAdditionalState(props)
        );
        this.postState(props);
        this.formPostState(props);

        this.xhrFetch = null;

        this.handleFetchSuccess = this.handleFetchSuccess.bind(this);
        this.handleFetchError = this.handleFetchError.bind(this);
        this.handleFetchIncorrectStatus = this.handleFetchIncorrectStatus.bind(this);

        this.setAdditionalHandlers();
    }

    // basic functions

    componentDidMount() {
        window.scrollTo(0, 0);

        this.fetchInitialData();
    }

    componentWillUnmount() {
        // abort api request if exists
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }

        // clear timeouts if exist
        if (this.saveMsgTimeout !== null) {
            clearTimeout(this.saveMsgTimeout);
        }
    }

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
    }

    handleFetchError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            initializingOn: false,
            fetchError: this.getFetchError(data.message || ''),
        });
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 404) {
            this.setState({initializingOn: false, pageNotFound: true});
        } else if (status === 403) {
            this.setState(
                {
                    initializingOn: false,
                    pageNotPermitted: true,
                },
                this.callback403
            );
        } else {
            this.setState({
                initializingOn: false,
                fetchError: this.getFetchIncorrectStatusError(status),
            });
        }
    }

    // helpers

    preState(props) {
        return
    }

    formPreState(props) {
        return
    }

    getAdditionalState(props) {
        return {}
    }

    getFormAdditionalState(props) {
        return {}
    }

    postState(props) {
        this.saveMsgTimeout = null;
    }

    formPostState(props) {
        return
    }

    setAdditionalHandlers() {
        return
    }

    fetchInitialData() {
        // get object(s) from server
        this.xhrFetch = fetchApi(
            this.getFetchUrl(),
            this.getFetchMethod(),
            {},
            this.getFetchData(),
            this.handleFetchSuccess,
            this.handleFetchError,
            this.handleFetchIncorrectStatus,
        );
    }

    getFetchUrl() {
        throw new Error('Not implemented fetch url!')
    }

    getFetchMethod() {
        return 'GET'
    }

    getFetchData() {
        return {}
    }

    getFetchError(message) {
        return translateErrorMessage(message)
    }

    getFetchIncorrectStatusError(status) {
        return `Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => { return }

    // rendering

    render() {
        if (this.state.pageNotFound) { return <NotFoundPage /> }
        if (this.state.pageNotPermitted) {
            return <NotPermittedPage message={this.message403} />
        }

        let content = null;
        if (this.state.initializingOn) {
            content = <Text info tabIndex="1">Trwa inicjalizacja danych...</Text>;
        } else if (this.state.fetchError.length) {
            content = this.renderFetchError();
        } else {
            content = this.renderElements();
        }

        return (
            <>
                {this.renderHeader()}
                {this.renderBeforeContent()}
                {content}
            </>
        )
    }

    renderFetchError() {
        return <Text error role="alert">{this.state.fetchError}</Text>
    }

    renderHeader() {
        return null
    }

    renderBeforeContent() {
        return null
    }

    renderElements() {
        return this.renderContent()
    }

    renderContent() {
        return null
    }
}


class FormBaseComponent extends BaseComponent {

    // basic functions

    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.xhrFetchSave !== null) {
            this.xhrFetchSave.abort();
        }
    }

    // handlers

    handleSave = () => {
        this.setState(
            {savingOn: true, errors: {}, fetchSaveError: '', saveBtnText: 'Trwa zapisywanie...'},
            () => {
                this.xhrFetchSave = fetchApi(
                    this.getSaveUrl(),
                    this.getSaveMethod(),
                    {},
                    this.getDataForSaving(),
                    this.handleFetchSaveSuccess,
                    this.handleFetchSaveError,
                    this.handleFetchSaveIncorrectStatus,
                    this.handleShowSaveErrors,
                );
            }
        );
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetchSave = null;
    }

    handleFetchSaveError = (data) => {
        this.xhrFetchSave = null;
        this.setState({
            fetchSaveError: this.getFetchSaveError(data.message),
            saveBtnText: this.saveBtnDefaultText,
            savingOn: false,
        });
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.xhrFetchSave = null;
        this.setState({
            fetchSaveError: this.getFetchSaveIncorrectStatusError(status),
            saveBtnText: this.saveBtnDefaultText,
            savingOn: false,
        });
    }

    handleShowSaveErrors = (errors) => {
        this.xhrFetchSave = null;
        this.setState({
            errors: getSerializedErrorsFromServer(errors),
            saveBtnText: this.saveBtnDefaultText,
            savingOn: false,
        });
    }

    // helpers

    formPreState(props) {
        this.saveBtnDefaultText = 'Zapisz zmiany';
    }

    getFormAdditionalState(props) {
        return {
            savingOn: false,
            errors: {},
            fetchSaveError: '',
            saveBtnText: this.saveBtnDefaultText,
        }
    }

    formPostState(props) {
        this.xhrFetchSave = null;
        this.handleFetchSaveSuccess = this.handleFetchSaveSuccess.bind(this)
    }

    getSaveUrl() {
        throw new Error('Not implemented save fetch url!')
    }

    getSaveMethod() {
        return 'POST'
    }

    getDataForSaving() {
        return {}
    }

    getFetchSaveError(message) {
        return translateErrorMessage(message)
    }

    getFetchSaveIncorrectStatusError(status) {
        return `Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }
}


class PaginatedBaseComponent extends BaseComponent {
    withUrlUpdating = false;
    mapToCamelCaseNameFromServer = false;

    componentDidUpdate(prevProps, prevState) {
        // it manages every url change, including back button
        // don't init again from handlers
        const prevParams = prevProps.location.search;
        const params = this.props.location.search;
        if (prevParams !== params) {
            this.setState(
                {initializingOn: true},
                this.fetchInitialData
            );
        }
    }

    // handlers

    handleFetchSuccess(data){
        super.handleFetchSuccess(data);

        const total = data.meta.total;
        const config = this.mapToCamelCaseNameFromServer ? {mapToCamelCaseName: true} : {}
        this.setState(prevState => ({
            initializingOn: false,
            objects: getSerializedObject(data, config)[this.objectsName] || [],
            total: total,
            hasPrev: this.checkHasPrev(),
            hasNext: this.checkHasNext(total, prevState.pageNumber),
        }));
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 403) {
            this.setState(
                {
                    initializingOn: false,
                    pageNotPermitted: true,
                },
                this.callback403
            );
            return
        }
        this.setState({
            initializingOn: false,
            fetchError: this.getFetchIncorrectStatusError(status),
        });
    }

    handleChangePage = (next=true) => {
        this.setState(
            prevState => ({
                pageNumber: next ? prevState.pageNumber + 1 : Math.max(prevState.pageNumber -1, 1)}),
            this.reinitializeData
        );
    }

    handleChangeSort = (selectedOption) => {
        this.setState(
            {selectedSort: selectedOption},
            this.reinitializeData
        )
    }

     handleApplyFilters = (filters) => {
        let data = {
            filters,
            filtersAreOn: false,
            fetchError: '',
            objects: [],
            pageNumber: 1,  // reset pageNumber
        };
        if (!this.withUrlUpdating) {
            Object.assign(data, {
                initializingOn: true,
            });
        }
        this.setState(data, this.reinitializeData);
    }

    handleCloseFilters = () => {
        this.setState({filtersAreOn: false});
    }

    // helpers

    preState(props) {
        this.dialogComponent = null;

        // redefine these values in your class if needed
        this.objectsName = 'objects';
        this.paginateBy = 50;
        this.sortingValues = [];
        this.sortingLabel = 'Sortuj wyniki wyszukiwania';
        this.filteringLabel = 'Opcje zaawansowane';
        this.filtersComponentClass = null;

        this.getFiltersCount = (filters) => { return 0 };
    }

    getAdditionalState(props) {
        const params = new URLSearchParams(props.location.search);
        let pageNumber = parseInt(params.get('strona'));
        if (!(pageNumber > 0)) {
            pageNumber = 1;
        }

        return {
            pageNumber: pageNumber,
            total: 0,
            hasNext: false,
            hasPrev: false,
            selectedSort: {value: 0, label: ''},
            filtersAreOn: false,
            filters: {},
            objects: [],
        }
    }

    reinitializeData = () => {
        if (this.withUrlUpdating) {
            // only update url, it will fetch after url changing
            this.updateUrl();
        } else {
            this.fetchInitialData();
        }
    }
    
    updateUrl() {
        // remember to have an access to history
        this.props.history.push(
            `${window.location.pathname}?strona=${this.state.pageNumber}${this.getUrlParams()}`);
    }

    getUrlParams() {
        // define url params as string; page number is already applied
        return ''
    }

    getFetchData() {
        return {
            page: this.state.pageNumber,
            limit: this.paginateBy,
            sort: this.state.selectedSort.value,
        };
    }

    checkHasNext(total, pageNumber) {
        return Math.ceil(total / this.paginateBy) > (pageNumber || this.state.pageNumber)
    }

    checkHasPrev() {
        return this.state.pageNumber > 1
    }

    // rendering

    renderBeforeContent() {
        return (
            <>
                <GridCustom className=" search__options">
                    {this.renderSorters()}
                    {this.renderFiltersButton()}
                </GridCustom>
                {this.state.filtersAreOn && this.renderFilters()}
            </>
        )
    }

    renderElements() {
        return (
            <>
                <Container>
                    {this.renderContent()}
                </Container>
                {this.renderAdditionalAfterBaseContent()}
            </>
        )
    }

    renderSorters() {
        return (
            <FieldWithLabel
                label={this.sortingLabel}
                tag="label"
                selectRef={React.createRef()}
                className="search__select-sorter" >
                <Select
                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                    className="selectClassName"
                    options={this.sortingValues.map(value => ({value: value[0], label: value[1]}))}
                    value={this.state.selectedSort}
                    onChange={this.handleChangeSort}
                    styles={customSelectStyles}
                    noOptionsMessage={() => 'Brak wybranej opcji'}
                />
            </FieldWithLabel>
        )
    }

    renderFiltersButton() {
        return (
            <ButtonStyled
                lite
                onClick={(e) => {
                    this.setState({filtersAreOn: true});
                    e.currentTarget.blur();
                }}>
                {this.filteringLabel} <span className="btn--counter btn--counter-primary" title="Aktywne filtry">
                    <span className="sr-only"> Liczba aktywnych filtrów </span>{this.renderFiltersCount()}
                </span>
            </ButtonStyled>
        )
    }

    renderFiltersCount() {
        return this.getFiltersCount(this.state.filters)
    }

    renderContent() {
        if (!this.state.objects.length) {
            return this.renderEmptyObjects()
        }
        return (
            <>
                {this.renderList()}
                <Pagination
                    currentPage={this.state.pageNumber}
                    hasNext={this.state.hasNext}
                    hasPrev={this.state.hasPrev}
                    handleChangePage={this.handleChangePage} />
            </>
        )
    }

    renderEmptyObjects() { return null }

    renderList() { return null }

    renderFilters() {
        return (
            <DialogCustom
                className="dialog-long-content"
                dialogRef={c => this.dialogComponent = c}
                onClose={this.handleCloseFilters}
            >
                {this.renderFiltersComponent()}
            </DialogCustom>
        )
    }

    renderFiltersComponent() {
        return <this.filtersComponentClass
                    filters={this.state.filters}
                    closeFilters={this.handleCloseFilters}
                    updateFilters={this.handleApplyFilters} />
    }

    renderAdditionalAfterBaseContent() {
        return null
    }
}


export { BaseComponent, FormBaseComponent, PaginatedBaseComponent };
