import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../context/user-context';
import { checkHasNext, checkHasPrev } from '../../../helpers';
import {
    getRegionOrInstitutionCode,
    PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT
} from '../../../helpers_permissions';
import { fetchApi } from '../../../fetch';
import {
    getSerializedObject,
    translateErrorMessage
} from '../../../serializers';
import {
    ButtonStyled,
    Container,
    GridCustom,
    Loader,
    Pagination,
    TableCustom,
    Text
} from '../../common';


const PAGINATED_BY = 20;


export const PermissionList = ({
    additionalFetchData = {},
    hideAddPermissionButtonIfFullList = false,
    initialRequestId = 1,
    isImplementationLevelType,
    requestId = 1,
    RowComponent,
    tableHeaderList,
    title,
    url,
    withHistory,
    onOpenForm,
    onOpenHistory,
    ...props
}) => {
    const [initializingOn, setInitializingOn] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [fetchError, setFetchError] = useState(null);
    const [data, setData] = useState(null);
    const [is403error, setIs403error] = useState(false);

    useEffect(() => {
        if (requestId === initialRequestId) { return }
        // clear pageNumber, because it's needed to change if useEffect below could run
        setPageNumber(null);
    }, [initialRequestId, requestId]);

    useEffect(() => {
        if (pageNumber === null) {
            // set pageNumber to 1, it should't be empty
            setPageNumber(1);
            return
        }
        let xhrFetch = null;

        function sortContentUsersPermissions(p1, p2) {
            return PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[p1.type].localeCompare(
                PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[p2.type], 'pl')
        }

        function callbackSuccess(data) {
            xhrFetch = null;
            const { total } = data.meta;
            const permissions = getSerializedObject(
                data, {mapToCamelCaseName: true}).permissions;
            setData({
                permissions: isImplementationLevelType
                    ? permissions || []
                    : Object.entries(permissions)
                        .map(([type, data]) => ({...data, type}))
                        .sort(sortContentUsersPermissions),
                total: total,
                hasPrev: checkHasPrev(pageNumber),
                hasNext: checkHasNext(PAGINATED_BY, pageNumber, total),
            });
            setInitializingOn(false);
        }

        function callbackFetchError(message) {
            xhrFetch = null;
            setFetchError(message);
            setInitializingOn(false);
        }

        function callbackError(data) {
            callbackFetchError(
                `Nie udało się pobrać listy uprawnień. ${translateErrorMessage(data.message)}`);
        }

        function callbackIncorrectStatus(status) {
            if (status === 403) {
                callbackFetchError(
                    'Nie masz uprawnień do pobrania listy uprawnień.');
                setIs403error(true);
                return
            }
            callbackFetchError(
                `Nie udało się pobrać listy uprawnień. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
            );
        }

        setInitializingOn(true);
        setFetchError(null);
        xhrFetch = fetchApi(
            url, 'GET', {}, {
                limit: PAGINATED_BY,
                page: pageNumber,
                ...additionalFetchData
            },
            callbackSuccess, callbackError, callbackIncorrectStatus
        );
        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }, [additionalFetchData, isImplementationLevelType, pageNumber, url]);

    function handleChangePage(next=true) {
        setPageNumber(prev => next ? prev + 1 : Math.max(prev -1, 1));
    }

    const { permissions } = useContext(UserContext);

    let hideAddAdministrationPermissionButton = false;
    if (!!data) {
        if (isImplementationLevelType) {
            hideAddAdministrationPermissionButton =
                !permissions.addImplementationLevelPermission;
        } else {
            const permissionsNames = [
                'contentAdministration',
                'implementationLevelsDictionaryAdministration',
                'usersAdministration'
            ];
            if (hideAddPermissionButtonIfFullList) {
                // check if permission list is full
                const setPermissions = data.permissions.map(perm => perm.type);
                let permissionsToSet = [];
                for (let permissionName of permissionsNames) {
                    if (!setPermissions.includes(permissionName)) {
                        permissionsToSet.push(permissionName);
                    }
                }
                if (!permissionsToSet.length) {
                    hideAddAdministrationPermissionButton = true;
                }
            } else {
                // check if at least one permission is set
                const mapDict = {
                    contentAdministration: 'contentPermissionsManagement',
                    implementationLevelsDictionaryAdministration:
                        'implementationLevelsDictionaryPermissionsManagement',
                    usersAdministration: 'usersPermissionsManagement',
                };
                let notSetPermissionsValues = [];
                for (let permissionName of permissionsNames) {
                    notSetPermissionsValues = [
                        ...notSetPermissionsValues,
                        ...Object.values(permissions[mapDict[permissionName]])
                    ];
                }
                hideAddAdministrationPermissionButton =
                    !notSetPermissionsValues.includes(true);
            }
        }
    }

    return (
        <Container>
            <GridCustom fullwidth>
                <Text tag="h2" mainHeader>{title}</Text>
                {withHistory && !is403error && (
                    <GridCustom flexTop>
                        <ButtonStyled lite onClick={onOpenHistory}>
                            Historia zmian
                        </ButtonStyled>
                    </GridCustom>
                )}
            </GridCustom>
            {initializingOn
                ? <Loader />
                : (fetchError
                    ? <Text error role="alert">{fetchError}</Text>
                    : (
                        <>
                            {data.total === 0
                                ? <Text error>Brak uprawnień</Text>
                                : (
                                    <TableCustom
                                        tableHeaderList={tableHeaderList}>
                                        {data.permissions.map(object => (
                                            <RowComponent
                                                isImplementationLevelType={isImplementationLevelType}
                                                key={isImplementationLevelType
                                                    ? getRegionOrInstitutionCode(
                                                        object.programme || {},
                                                        object.priority || {},
                                                        object.activity || {},
                                                        object.region || null,
                                                        object.institution || null,
                                                        object.perspective || null
                                                    )
                                                    : object.type
                                                }
                                                permission={object}
                                                {...props} />
                                        ))}
                                    </TableCustom>
                                )
                            }
                            {data.total > PAGINATED_BY && (
                                <Pagination
                                    currentPage={pageNumber}
                                    hasNext={data.hasNext}
                                    hasPrev={data.hasPrev}
                                    handleChangePage={handleChangePage} />
                            )}
                        </>
                    )
                )
            }
            {!hideAddAdministrationPermissionButton && !is403error && (
                <ButtonStyled
                    add
                    onClick={e => {
                        onOpenForm();
                        e.currentTarget.blur();
                    }}>
                    Dodaj uprawnienia
                </ButtonStyled>
            )}
        </Container>
    )
};
