import React from 'react';

import { ListItem, ListItemGraphic } from '@rmwc/list';
import { Theme } from '@rmwc/theme';
import { Typography } from '@rmwc/typography';

import '@material/theme/dist/mdc.theme.css';
import '@material/list/dist/mdc.list.css';
import '@material/typography/dist/mdc.typography.css';


const MenuBasic = React.forwardRef((props, ref) => {
    return (
        <Theme tag="nav" use="primaryBg" className="menu-basic">
            {props.title && props.title.length > 0 && (
                <Typography tag="h2" use="headline5 onPrimary" theme={'onPrimary'} className="ogloszenie-progress-title">{props.title}</Typography>
            )}
            <ul ref={ref} className="ogloszenie-progress-list" tabIndex="-1">
                {props.children.map((link) =>
                    <li key={link.id}>
                        <ListItem
                            className="ogloszenie-progress-list__button"
                            disabled={props.disabled}
                            id={`${link.name}_button_id`}
                            tag="button"
                            onClick={() => (props.disabled || (props.disabledFrom && props.disabledFrom <= link.id)) ? {} : link.onClick(link.id)}
                        >
                            <ListItemGraphic
                                aria-hidden="true"
                                use="onPrimary"
                                icon={(
                                    props.addProgressIcon && props.currentMenuLink !== null && link.id < props.currentMenuLink)
                                    ? 'lens'
                                    : (props.currentMenuLink !== null && link.id === props.currentMenuLink ? 'adjust' : 'panorama_fish_eye'
                                )}
                                style={styles.iconStyle}/>
                            <ListItemGraphic
                                aria-hidden="true"
                                use="onPrimary"
                                icon={ props.errors[link.name] === true ? 'error_outline' : (props.errors[link.name] === false ? 'done' : '')}
                                style={styles.iconStyle}/>
                            <Typography
                                theme={'onPrimary'}
                                use="body2" >
                                {link.label}
                                <span className="sr-only">
                                    {
                                        (
                                            (props.currentMenuLink !== null && link.id < props.currentMenuLink)
                                                ? ' Wypełnione.'
                                                : (
                                                    props.currentMenuLink !== null && link.id === props.currentMenuLink
                                                        ? ' W trakcie wypełniania.' : ' Przyszły etap.'
                                                )
                                        ) + (
                                            props.errors[link.name] === true
                                                ? ' Wymaga uwagi.'
                                                : (props.errors[link.name] === false ? ' Poprawna walidacja.' : '')
                                        )
                                    }
                                </span>
                            </Typography>
                        </ListItem>
                    </li>
                )}
            </ul>
        </Theme>
    )
})

const styles = {
    iconStyle: {
        color: '#fff'
    }
}


export { MenuBasic };
