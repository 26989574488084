import React from 'react';

import { getSerializedObject } from '../../../serializers';
import { DraftToComponents } from './DraftToComponents';
import { KomunikatFront } from './KomunikatFront';


class ZoltyPasekFront extends KomunikatFront {

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        const komunikat = getSerializedObject(
            data,
            {
                nonRecursiveKeys: ['tresc', ],
                dateAttributes: ['dataOpublikowania', 'dataWaznosci'],
                dateTimeAttributes: ['dataOpublikowania', 'dataWaznosci'],
            }
        ).komunikaty[0];
        if (!komunikat) {
            this.setState({initializingOn: false});
        } else {
            this.setState({initializingOn: false, komunikat});
        }
    }

    // helpers

    getFetchUrl() {
        return '/api/statements'
    }

    getFetchData() {
        return {yellow_stripe: true}
    }

    // rendering

    render() {
        if (this.state.initializingOn || !Object.keys(this.state.komunikat).length) { return null }
        return this.renderContent()
    }

    renderContent() {
        const { tresc } = this.state.komunikat;
        const trescKomunikatu = tresc.blocks.map(b => b.text).join(' ');

        return (
            <section className="yellow-comment" aria-label={`Ważny komunikat: ${trescKomunikatu}`} role="status" aria-live="polite">
                <DraftToComponents content={tresc} />
            </section>
        )
    }
}


export { ZoltyPasekFront };
