import React, { Component } from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { splitLineByChar } from '../../../helpers';
import { PdfHeader } from '../../common';
import { pdfStyles } from '../../vars/pdfStyles';


class PytaniaForOgloszeniePdf extends Component {

    // rendering
    render() {
        const { numerOgloszenia, tytulOgloszenia } = this.props;
        const { labelStyle, mainPdfContainerStyle } = pdfStyles;

        return (
            <Document>
                <Page size="A4" style={mainPdfContainerStyle} wrap>
                    <PdfHeader />
                    <View>
                        <Text style={labelStyle}>Ogłoszenie nr {numerOgloszenia}</Text>
                        <Text>{tytulOgloszenia}</Text>
                        {this.renderQuestionsList()}
                    </View>
                </Page>
            </Document>
        )
    }

    renderQuestionsList() {
        const { pytania } = this.props;
        const { textHeaderStyle, textInfoStyle } = pdfStyles;

        if (pytania.length >= 1) {
            return (
                <>
                    <Text style={textHeaderStyle}>Lista opublikowanych pytań i odpowiedzi</Text>
                    <Text>Liczba wyników: {pytania.length}</Text>
                    {pytania.map(q => this.renderQuestion(q))}
                </>
            )
        }

        return <Text style={textInfoStyle}>Nie zadano żadnego pytania.</Text>
    }

    renderAnswer(question) {
        const { odpowiedz } = question;

        if (odpowiedz) {
            const { status } = question;

            let answerLabel = '';
            if (status.label === 'PUBLISHED') {
                answerLabel = 'Udzielono odpowiedzi';
            } else if (status.label === 'DRAFT') {
                answerLabel = 'Robocza wersja odpowiedzi';
            }

            return (
                <View style={styles.answerContainerStyle}>
                    <Text style={pdfStyles.labelStyle}>{answerLabel}</Text>
                    {splitLineByChar(odpowiedz.tresc).map((line, i) => <Text key={i}>{line}</Text>)}
                </View>
            )
        }

        return <Text style={pdfStyles.textInfoStyle}>Nie udzielono odpowiedzi</Text>;
    }

    renderQuestion(question) {
        const { dataOpublikowania, dataUtworzenia, id, jestAutorem, numer, tresc } = question;

        return (
            <View key={id} style={styles.questionContainerStyle}>
                {jestAutorem && <Text style={styles.myLabelStyle}>Moje pytanie</Text>}
                <Text style={pdfStyles.labelStyle}>Pytanie numer {numer}, data wysłania {dataUtworzenia}{dataOpublikowania && `, data opublikowania ${dataOpublikowania}`}</Text>
                {splitLineByChar(tresc).map((line, i) => <Text key={i}>{line}</Text>)}
                {this.renderAnswer(question)}
            </View>
        )
    }

}

const styles = StyleSheet.create({
    myLabelStyle: {
        width: 70,
        borderBottomWidth: 2,
        borderBottomColor: '#666A6D',
        color: '#666A6D',
        textTransform: 'uppercase',
        fontSize: 10,
        fontWeight: 700,
    },
    questionContainerStyle: {
        paddingVertical: 10,
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#666A6D'
    },
    answerContainerStyle: {
        paddingLeft: 20
    }
});


export { PytaniaForOgloszeniePdf };
