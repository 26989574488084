import { Component } from 'react';


class BaseStepComponent extends Component {
    stepName = '';

    // basic functions

    componentDidMount() {
        if (!this.props.isPreview) {
            window.scrollTo(0, 0);
        }

        // clear step error in menu
        this.context.clearStepError(this.stepName);

        if (window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }
}


export { BaseStepComponent }
