// https://reactdatepicker.com/
// https://www.npmjs.com/package/react-datepicker

import React, { Component } from 'react';
import dfIsSameDay from 'date-fns/isSameDay';
import DatePicker, { registerLocale } from "react-datepicker";
import { TextField } from '@rmwc/textfield';
import "react-datepicker/dist/react-datepicker.css";

import { fillWithLeadingZero } from '../../helpers';

import pl from 'date-fns/locale/pl';
registerLocale('pl', pl)


const WEEKDAYS = {
    Monday: 'poniedziałek',
    Tuesday: 'wtorek',
    Wednesday: 'środa',
    Thursday: 'czwartek',
    Friday: 'piątek',
    Saturday: 'sobota',
    Sunday: 'niedziela',
};
const MONTHS = {
    January: 'styczeń',
    February: 'luty',
    March: 'marzec',
    April: 'kwiecień',
    May: 'maj',
    June: 'czerwiec',
    July: 'lipiec',
    August: 'sierpień',
    September: 'wrzesień',
    October: 'październik',
    November: 'listopad',
    December: 'grudzień',
};


const isSameDay = (date1, date2) => {
    if (date1 && date2) {
        return dfIsSameDay(date1, date2);
    } else {
        return !date1 && !date2;
    }
}


class ExtendedDatePicker extends DatePicker {

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.calendar) {
            const this_ = this;
            this.calendar.componentDidUpdate = function(prevProps) {
                this_.props.replaceDays();
                if (this.props.preSelection && !isSameDay(this.props.preSelection, prevProps.preSelection)) {
                    this.setState({
                        date: this.props.preSelection
                    });
                } else if (this.props.openToDate && !isSameDay(this.props.openToDate, prevProps.openToDate)) {
                    this.setState({
                        date: this.props.openToDate
                    });
                }
            };
        }
    }
}


class DateCalendar extends Component {

    // handlers

    handleCalendarOpen = () => {
        if (this.props.onCalendarOpen) {
            this.props.onCalendarOpen();
        }
        this.handleTranslateCalendar();
    }

    handleMonthChange = () => {
        // this method is needed for calendar with limited days
        this.handleTranslateCalendar();
        this.replaceDays();
    }

    handleTranslateCalendar() {
        if (this.props.showMonthYearPicker) {
            this.translateCalendarFields('button.react-datepicker__navigation--previous', 'Poprzedni rok');
            this.translateCalendarFields('button.react-datepicker__navigation--next', 'Kolejny rok');
        } else {
            this.translateCalendarFields('button.react-datepicker__navigation--previous', 'Poprzedni miesiąc');
            this.translateCalendarFields('button.react-datepicker__navigation--next', 'Kolejny miesiąc');
        }
    }

    handleChange = (value) => {
        if (value !== null) {
            this.translateCalendarFields(
                '.react-datepicker__month',
                `Miesiąc-${value.getFullYear()}-${fillWithLeadingZero(value.getMonth() + 1)}`
            );
        }
        this.props.onChange(value);
    }

    // helpers

    // Translate .react-datepicker-popper fields to Polish
    translateCalendarFields(querySelector, ariaLabel) {
        if (!this.props.parentRef.current) { return }
        const calendarField = this.props.parentRef.current.querySelector(querySelector);

        if (calendarField) {
            calendarField.setAttribute('aria-label', ariaLabel);
        }
    }

    replacePartOfAriaLabel = (querySelector, englishPhrase, polishPhrase) => {
        if (!this.props.parentRef.current) { return }
        this.props.parentRef.current.querySelectorAll(querySelector).forEach(calendarField => {
            const newAriaLabel = calendarField.getAttribute('aria-label').replace(
                englishPhrase, polishPhrase);
            calendarField.setAttribute('aria-label', newAriaLabel);
        });
    }

    replaceDays = () => {
        if (this.props.showMonthYearPicker) { return }
        if (this.props.parentRef.current) {
            this.replacePartOfAriaLabel('.react-datepicker__month', 'month', 'Miesiąc')
            this.props.parentRef.current.querySelectorAll('.react-datepicker__day').forEach(calendarField => {
                // 'Choose Sunday, March 1st, 2020'
                const [weekDay, month, day, year] = calendarField.getAttribute('aria-label').replace(/,/g, '').split(' ').slice(1);
                let day_, month_;
                if (Object.keys(WEEKDAYS).includes(weekDay)) {
                    day_ = day.replace('st', '').replace('nd', '').replace('rd', '').replace('th', '');
                    month_ = MONTHS[month];
                } else {
                    day_ = month;
                    month_ = day;
                }
                calendarField.setAttribute(
                    'aria-label',
                    `Wybierz ${WEEKDAYS[weekDay] || weekDay} ${day_} ${month_} ${year}`
                );
            });
        }
    }

    render() {
        const {
            ariaDescribedby,
            dateCalendarId,
            dateFormatCustom,
            dateFormat,
            dateName,
            disabled,
            fullwidth,
            invalid,
            maxDate,
            minDate,
            showMonthYearPicker,
            showTimeSelect,
            showTimeSelectOnly,
            timeIntervals,
            timeCaption,
            value,
        } = this.props;

        // NOTE! Don't set onFocus, because it destroy translation
        return (
            <ExtendedDatePicker
                customInput={
                    <TextField
                        aria-describedby={ariaDescribedby}
                        aria-label={`Wpisz lub wybierz z kalendarza datę ${dateName || ''} w formacie: ${dateFormat || 'rok myślnik miesiąc myślnik dzień'}.`}
                        fullwidth={fullwidth}
                        id={dateCalendarId}
                        invalid={invalid}
                    />
                }
                dateFormat={dateFormatCustom ? dateFormatCustom : "yyyy-MM-dd"}
                disabled={disabled}
                isClearable={![NaN, null, ''].includes(value)}
                locale="pl"
                maxDate={maxDate}
                minDate={minDate}
                placeholderText=""
                replaceDays={this.replaceDays}
                replacePartOfAriaLabel={this.replacePartOfAriaLabel}
                selected={value}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                timeCaption={timeCaption}
                onChange={this.handleChange}
                onCalendarClose={this.props.onCalendarClose}
                onCalendarOpen={this.handleCalendarOpen}
                onMonthChange={this.handleMonthChange}
                showMonthYearPicker={showMonthYearPicker}
            />
        );
    }
}

export { DateCalendar };
