import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DataTableCell, DataTableRow } from '@rmwc/data-table';
import { Icon } from '@rmwc/icon';

import { UserContext } from '../../../context/user-context';
import { URLS } from '../../../urls/frontend';
import { ButtonStyled, MoreOptionsButton, Text } from '../../common';


const ADVERTISEMENTS_GET_COMMON_URL_PARAMS =
    '&sort=announcementNumber_desc&statusOgl=all&strona=1&typAdresu=all' +
    '&typNumeruIdent=all';


export const ProjectListRow = ({project, onFilterProjectsByUserEmail,
        onSelectProjectForEditing}) => {
    let history = useHistory();
    const isProject = project.type === 'project';
    const { createdBy: user } = project;

    const handleFilterProjectsByUserEmail = () => {
        onFilterProjectsByUserEmail(user.email);
    };

    const handleGoToManageUserPage = () => {
        history.push(URLS.usersDetails.getUrl(user.id));
    };

    const handleFilterAdvertisementsByUserEmail = () => {
        history.push(`${URLS.managementAdvertisements.path}?email=${user.email}${ADVERTISEMENTS_GET_COMMON_URL_PARAMS}`);
    };

    const handleFilterAdvertisementsByFullNumber = () => {
        history.push(`${URLS.managementAdvertisements.path}?numerProj=${project.fullNumber}${ADVERTISEMENTS_GET_COMMON_URL_PARAMS}`);
    };

    const attachedAdvertisementsAmount = project.attachedAdvertisementsAmount || 0;

    const { permissions } = useContext(UserContext);

    return (
        <DataTableRow>
            <DataTableCell>
                <div className="table--administration__type-col">
                    <Icon aria-hidden="true" icon={isProject ? 'label' : 'label_outline'} />
                    <Text>{isProject ? 'Projekt' : 'Nabór'}</Text>
                </div>
            </DataTableCell>
            <DataTableCell>
                <div className="table--administration__action-col">
                    <div className="table--administration__meta">
                        <Text>{user.forename} {user.surname}</Text>
                        <Text use="caption" className="table--administration__user-info">{user.email}</Text>
                    </div>
                    <MoreOptionsButton menuAriaLabel="Otwórz menu użytkownika: zarządzaj użytkownikiem, filtruj projekty i nabory dla tego użytkownika, filtruj ogłoszenia dla tego użytkownika.">
                        <Text use="caption" className="table--administration__user-id">ID {user.identifier}</Text>
                        <ul className="table--administration__user-menu-list">
                            {permissions.fullUsersAndPermissions &&
                                <li>
                                    <ButtonStyled noMargin onClick={handleGoToManageUserPage}>
                                        Zarządzaj użytkownikiem
                                    </ButtonStyled>
                                </li>
                            }
                            <li><ButtonStyled noMargin onClick={handleFilterProjectsByUserEmail}>Filtruj projekty i nabory</ButtonStyled></li>
                            {permissions.advertisements &&
                                <li>
                                    <ButtonStyled noMargin onClick={handleFilterAdvertisementsByUserEmail}>
                                        Filtruj ogłoszenia
                                    </ButtonStyled>
                                </li>
                            }
                        </ul>
                    </MoreOptionsButton>
                </div>
            </DataTableCell>
            <DataTableCell>
                <div className="table--administration__action-col">
                    <div className="table--administration__meta">
                        <Text>{project.fullNumber}</Text>
                        <Text use="caption" className="table--administration__user-info">{project.name}</Text>
                    </div>
                    {(permissions.advertisements || project.canEdit) && (
                        <MoreOptionsButton menuAriaLabel="Otwórz menu: powiązane ogłoszenia.">
                            <ul className="table--administration__user-menu-list">
                                {permissions.advertisements &&
                                <li>
                                    <ButtonStyled
                                        disabled={!attachedAdvertisementsAmount}
                                        noMargin
                                        onClick={handleFilterAdvertisementsByFullNumber}
                                    >
                                        Powiązane ogłoszenia
                                        <span
                                            className="btn--counter btn--counter-primary"
                                            title="Powiązane ogłoszenia">{attachedAdvertisementsAmount}</span>
                                    </ButtonStyled>
                                </li>
                                }
                                {project.canEdit && (
                                    <li>
                                        <ButtonStyled
                                            noMargin
                                            onClick={ev => onSelectProjectForEditing(project)}
                                        >
                                            Edytuj {isProject ? 'projekt' : 'nabór'}
                                        </ButtonStyled>
                                    </li>
                                )}
                            </ul>
                        </MoreOptionsButton>
                    )}
                </div>
            </DataTableCell>
        </DataTableRow>
    )
};
