import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';

import { fetchApi } from '../../../fetch';
import { serializeObjectList, translateErrorMessage } from '../../../serializers';
import { ButtonStyled, DialogCustom, FieldWithLabel, GridCustom, Text } from '../../common';


class ListaWersji extends Component{

    constructor(props) {
        super(props);

        this.state ={
            initializingOn: true,
            versions: [],
            error: '',
        };

        this.xhrFetch = null;
    }

    // basic functions

    componentDidMount() {
        // get version list from server
        this.xhrFetch = fetchApi(
            `/api/announcements/${this.props.ogloszenieId}/versions`,
            'GET',
            {},
            {mode: 'advertiser'},
            this.handleFetchVersionListSuccess,
            this.handleFetchVersionListError,
            this.handleFetchVersionListIncorrectStatus,
        );
    }

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }
    }

    // handlers

    handleFetchVersionListSuccess = (data) => {
        this.xhrFetch = null;
        this.setState({initializingOn: false, versions: serializeObjectList(data.versions)});
    }

    handleFetchVersionListError = (data) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({initializingOn: false, error: `Podczas pobierania listy wersji wystąpił nieoczekiwany błąd: ${translateErrorMessage(data.message)}`});
    }

    handleFetchVersionListIncorrectStatus = (status) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({initializingOn: false, error: `Podczas pobierania listy wersji wystąpił nieoczekiwany błąd o kodzie ${status}.`});
    }

    handleCloseDialog = () => {
        this.props.closeVersionDialog();
    }

    // helpers

    sortVersions(versions) {
        let draft = null, published = [];
        for (let v of versions) {
            if (v.status.id === this.props.roboczeStatusId) {
                draft = v;
            } else {
                published.push(v);
            }
        }

        // sort published versions by id
        published.sort((v1, v2) => {
            return v1.id > v2.id ? -1 : 1
        })

        return [draft, published]
    }

    // rendering

    render() {
        let content = null;
        const { error } = this.state;

        if (this.state.initializingOn) {
            content = <Text info>Trwa pobieranie danych z serwera...</Text>;
        } else {
            content = error.length > 0 ? this.renderError() : this.renderVersionList();
        }
        return (
            <DialogCustom 
                onClose={this.handleCloseDialog}
                dialogTitle={(error.length > 0) ? error : `Wszystkie wersje ogłoszenia`}
            >
                {content}
            </DialogCustom>
        )
    }

    renderError() {
        return (
            <GridCustom centerVertical flexEnd>
                <ButtonStyled onClick={this.handleCloseDialog} primary>OK</ButtonStyled>
            </GridCustom>
        )
    }

    renderVersionList() {
        let content = null;
        if (this.state.versions.length) {
            // sort versions
            let [draft, published] = this.sortVersions(this.state.versions);

            const ogloszenieId = this.props.ogloszenieId;
            let currentlyVisibleMarker = (
                <>
                    <Icon icon="find_in_page" aria-hidden="true" style={{'marginRight': 3}}/>
                    <Text>Aktualnie przeglądasz</Text>
                </>
            );

            content = (
                <>
                    {this.renderDraftList(draft, currentlyVisibleMarker)}
                    {published.length > 0 ? (
                        <FieldWithLabel label="Wersje opublikowane:">
                            {published.map((v, index) => (
                                <GridCustom centerVertical key={v.id} className="published-version__item">
                                    <button onClick={() => this.props.reload(index === 0 ? `/ogloszenia/${ogloszenieId}` : `/ogloszenia/${ogloszenieId}/wersja${v.id}`)} className="link-text">
                                        Wersja {published.length - index}, opublikowana {v.dataOpublikowania}{index === 0 ? ' - obowiązująca' : ''}
                                    </button>
                                    {parseInt(this.props.id) === v.id && currentlyVisibleMarker}
                                    {!v.isCreatedByAdvertiser &&
                                        <Text className="published-version__author-info">
                                            Utworzona przez pracownika instytucji
                                        </Text>
                                    }
                                </GridCustom>
                            ))}
                        </FieldWithLabel>
                    ) : <Text info>Brak wersji opublikowanych</Text>}
                </>
            );
        } else {
            content = <Text info>To ogłoszenie nie ma innych wersji.</Text>;
        }
        return (
            <>
                {content}
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled onClick={this.handleCloseDialog} primary>Zamknij</ButtonStyled>
                </GridCustom>
            </>
        )
    }

    renderDraftList(draft, currentlyVisibleMarker) {
        if (!this.props.isAuthor && !draft) { return null }
        return (
            <FieldWithLabel label="Wersje robocze:">
                {draft !== null ? (
                    <GridCustom centerVertical>
                        <button onClick={() => this.props.reload(`/ogloszenia/${this.props.ogloszenieId}/robocza`)} className="link-text">
                            Wersja robocza, modyfikowana {draft.dataModyfikacji}
                        </button>
                        {parseInt(this.props.id) === draft.id && currentlyVisibleMarker}
                    </GridCustom>
                ) : <Text info>Brak wersji roboczych</Text>}
            </FieldWithLabel>
        )
    }
}


export { ListaWersji };
