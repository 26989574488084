import React from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import { serializeAuditData } from '../../../helpers';
import { getSerializedObject,  } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    AuditData,
    Breadcrumps,
    ButtonStyled,
    Container,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';


class TrescOgolna extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        const trescOgolna = getSerializedObject(
            data, {nonRecursiveKeys: ['tresc', ]}).trescOgolna;
        this.setState({
            initializingOn: false,
            fetchError: '',
            trescOgolna,
            auditData: serializeAuditData(trescOgolna),
        });
    }

    // helpers

    getAdditionalState(props) {
        return {trescOgolna: null, auditData: {}}
    }

    getFetchUrl() {
        return API_URLS.generalContentAdminDetails.getUrl(
            this.props.match.params.id)
    }

    getFetchError(message) {
        return `Nie udało się pobrać treści ogólnej. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania treści ogólnej wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps>
                <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/tresci-ogolne'>Treści ogólne</Link></li>
                <li className="breadcrumps__current">Szczegóły {this.state.trescOgolna ? this.state.trescOgolna.naglowek : ''}</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        const to = this.state.trescOgolna;
        return (
            <>
                <Text tag="h2" mainHeader>{to.naglowek}</Text>
                <Container>
                    <GridCustom flexEnd>
                        <ButtonStyled primary onClick={() => this.props.history.push(`/zarzadzanie-trescia/tresci-ogolne/${this.props.match.params.id}/edytuj`)}>Edytuj</ButtonStyled>
                    </GridCustom>
                    <FieldWithLabel label="Treść">
                        <DraftToComponents content={to.tresc} />
                    </FieldWithLabel>
                </Container>
                <AuditData {...this.state.auditData} />
            </>
        )
    }
}

TrescOgolna.contextType = UserContext;

export { TrescOgolna };
