import React from 'react';

import { fetchApi } from '../../../fetch';
import {
    hasPerspectiveInstitutions,
    hasPerspectiveRegions,
    serializePerspectiveForSelect
} from '../../../helpers';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { ButtonStyled, DialogCustom, FieldWithLabel, GridCustom, Text, TextFieldCustom } from '../../common';
import {
    FormBaseComponent,
    INSTITUTION_NUMBER_DEFAULT_DICT,
    ProjectNumberSelectsWithPerspectives
} from '../common';

const SELECTS_CODES = {
    programOperacyjny: 'AAAA',
    osPriorytetowa: '00',
    dzialanie: '00',
    dzialanieIpoddzialanie: '00.00',
    region: '00',
    instytucja: 'AA.00',
};


class Form extends FormBaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        if ((data.perspectives || []).length) {
            const perspectives =
                serializePerspectiveForSelect(data).perspectives;
            this.setState(
                {
                    initializingOn: false,
                    fetchError: '',
                    perspektywa: perspectives[0],
                    perspektywy: perspectives,
                },
                () => {
                    this.setNumberParts();
                    this.setState({fullNumber: this.getFullNumber()});
                }
            );
            return
        }
        this.handleFetchError({});
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            initializingOn: false,
            fetchError: this.getFetchIncorrectStatusError(status),
        });
    }

    handleChangePerspektywa = (data, callback) => {
        this.setState(
            {
                perspektywa: data.perspective,
                programOperacyjny: data.programme,
                osPriorytetowa: data.priority,
                dzialanie: data.activity,
                numerInstytucji: data.institutionNumber,
                region: data.region,
                instytucja: data.institution,
            },
            () => {
                this.setNumberParts();
                this.updateNumber();
                if (callback) {
                    callback();
                }
            }
        );
    }

    handleChangeProgramOperacyjny = (data, callback) => {
        this.setState(
            {
                programOperacyjny: data.programme,
                osPriorytetowa: data.priority,
                dzialanie: data.activity,
                region: data.region,
                instytucja: data.institution,
            },
            () => {
                this.updateNumber();
                if (callback) {
                    callback();
                }
            }
        );
    };

    handleChangeOsPriorytetowa = (data, callback) => {
        this.setState(
            {
                osPriorytetowa: data.priority,
                dzialanie: data.activity,
                region: data.region,
                instytucja: data.institution,
            },
            () => {
                this.updateNumber();
                if (callback) {
                    callback();
                }
            }
        );
    };

    handleChangeDzialanie = (data, callback) => {
        this.setState(
            {
                dzialanie: data.activity,
                region: data.region,
                instytucja: data.institution,
            },
            () => {
                this.updateNumber();
                if (callback) { callback()}
            }
        );
    };

    handleChangeRegion = (data) => {
        this.setState(
            {region: data.region},
            this.updateNumber
        );
    };

    handleChangeInstytucja = (data) => {
        this.setState(
            {instytucja: data.institution},
            this.updateNumber
        );
    };

    changeInputNumber(value, attrName, defaultDict) {
        value = value.replace(/[^\da-zA-Z-/.]/, '').toUpperCase();
        this.setState(
            prevState => ({
                [attrName]: {
                    ...prevState[attrName],
                    ...(!value ? defaultDict : {value, code: value, label: value}),
                }
            }),
            this.updateNumber
        );
    }

    handleChangeOthers = (value) => {
        this.changeInputNumber(
            value, 'suffix', {...this.getSuffixDefaultDict()});
    };

    handleChangeInstitutionNumber = (value) => {
        this.changeInputNumber(
            value, 'numerInstytucji', INSTITUTION_NUMBER_DEFAULT_DICT);
    }

    handleSave = () => {
        this.setState(
            {savingOn: true, errors: {}},
            () => {
                let data = {
                    nazwa: this.state.nazwa.trim(),
                    programOperacyjny: (this.state.programOperacyjny || {}).value || 0,
                    osPriorytetowa: (this.state.osPriorytetowa || {}).value || 0,
                    dzialanie: (this.state.dzialanie || {}).value || 0,
                    numer: this.state.suffix.value || '',
                };

                const { perspektywa } = this.state;
                data['perspektywa'] = perspektywa.value;
                if (hasPerspectiveRegions(perspektywa)) {
                    if (!this.props.isProjekt) {
                        data['institutionNumber'] =
                            this.state.numerInstytucji.value || '';
                    }
                    data['region'] = (this.state.region || {}).value || 0;
                }
                if (hasPerspectiveInstitutions(perspektywa)) {
                    data['institution'] = (
                        this.state.instytucja || {}).value || 0;
                }

                // save object on server
                this.xhrFetchSave = fetchApi(
                    this.props.saveUrl,
                    'POST',
                    {},
                    getSerializedObject(data, {toServer: true}),
                    this.handleFetchSaveSuccess,
                    this.handleFetchSaveError,
                    this.handleFetchSaveIncorrectStatus,
                    this.handleShowSaveErrors,
                );
            }
        );
    }

    handleFetchSaveSuccess = (data) => {
        super.handleFetchSaveSuccess(data);
        this.props.onSave(getSerializedObject(data)[this.props.isProjekt ? 'projekt' : 'nabor']);
    }

    // helpers

    preState(props) {
        this.parts = [];
    }

    getSuffixDefaultDict() {
        return {
            value: '',
            code: this.props.suffixMask,
            label: this.props.suffixMask,
        }
    }

    getAdditionalState(props) {
        return {
            // fields
            nazwa: '',
            perspektywa: null,
            programOperacyjny: null,
            osPriorytetowa: null,
            dzialanie: null,
            // this one is form old perspective and enrollment
            numerInstytucji: {...INSTITUTION_NUMBER_DEFAULT_DICT},
            region: null,
            instytucja: null,
            suffix: {...this.getSuffixDefaultDict()},
            // helpers
            fullNumber: '',
            perspektywy: [],
            osiePriorytetowe: [],
            dzialania: [],
            regiony: [],
            instytucje: [],
        }
    }

    postState(props) {
        this.xhrFetchSave = null;
        this.state['fullNumber'] = this.getFullNumber();
    }

    getFetchUrl() {
        return API_URLS.perspectives.path
    }

    getFetchError(message) {
        return `Nie udało się pobrać danych do formularza. ${translateErrorMessage(message)}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych do formularza. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    setNumberParts() {
        let parts = [
            ['', 'programOperacyjny'],
            ['.', 'osPriorytetowa'],
        ];
        const { perspektywa } = this.state;
        const hasRegions = hasPerspectiveRegions(perspektywa);
        const hasInstitutions = hasPerspectiveInstitutions(perspektywa);

        if (hasRegions) {
            parts.push(['.', 'dzialanie', 'dzialanieIpoddzialanie']);
            if (!this.props.isProjekt) {
                parts.push(['-', 'numerInstytucji']);
            }
            parts.push(['-', 'region']);
        }
        if (hasInstitutions) {
            parts.push(['.', 'dzialanie']);
            parts.push(['-', 'instytucja']);
        }

        parts.push(['-', 'suffix']);
        this.parts = parts;
    }

    getFullNumber() {
        let fullNumber = '';
        for (let [separator, attrName, alternativeAttrName] of this.parts) {
            const attrValue = this.state[attrName];
            fullNumber += `${separator}${attrValue === null 
                ? (SELECTS_CODES[alternativeAttrName] || SELECTS_CODES[attrName])
                : attrValue.code
            }`;
        }
        return fullNumber
    }

    updateNumber() {
        const fullNumber = this.getFullNumber();
        this.setState(
            {fullNumber},
            () => {
                if (this.props.onNumberChange) {
                    this.props.onNumberChange(fullNumber);
                }
            }
        );
    }

    // rendering

    render() {

        let content;
        if (this.state.initializingOn) {
            content = <Text info tabIndex="1">Trwa inicjalizacja danych...</Text>;
        } else {
            const { title } = this.props;
            content = (
                <>
                    <h2 id="addDialogLabel" className="text--main-header"> {title}</h2>
                    {this.renderNumer()}
                    {this.renderNazwa()}
                    <GridCustom flexEnd className=" box__button-container">
                        <ButtonStyled
                            cancel
                            disabled={this.state.savingOn}
                            onClick={this.props.onClose}
                        >Anuluj</ButtonStyled>
                        <ButtonStyled
                            disabled={this.state.savingOn}
                            primary
                            onClick={this.handleSave}
                        >
                            Zapisz<span className="sr-only"> projekt</span>
                        </ButtonStyled>
                    </GridCustom>
                    {this.renderError('fetchError')}
                    {this.renderError('fetchSaveError')}
                </>
            );
        }

        return (
            <DialogCustom
                ariaLabelledby="addDialogLabel"
                className="dialog-long-content"
                onClose={this.props.onClose}
            >
                {content}
            </DialogCustom>

        )
    }

     renderNumer() {
        return (
            <>
                <GridCustom centerVertical>
                    <Text className="text--project-info">Powstały numer:</Text> <Text accentHeader>{this.state.fullNumber}</Text>
                </GridCustom>
                {this.renderSelects()}
                {this.renderOthers()}
            </>
        )
    }

    renderSelects() {
        const { errors } = this.state;
        return (
            <ProjectNumberSelectsWithPerspectives
                activity={this.state.dzialanie}
                activityErrors={errors.dzialanie || []}
                disabled={this.state.savingOn}
                institution={this.state.instytucja}
                institutionErrors={errors.instytucja || []}
                institutionNumber={this.state.numerInstytucji}
                institutionNumberErrors={errors.numerInstytucji || []}
                perspective={this.state.perspektywa}
                perspectiveErrors={errors.perspektywa}
                perspectives={this.state.perspektywy}
                priority={this.state.osPriorytetowa}
                priorityErrors={errors.osPriorytetowa || []}
                programme={this.state.programOperacyjny}
                programmeErrors={errors.programOperacyjny || []}
                region={this.state.region}
                regionErrors={errors.region || []}
                withInstitutionNumber={!this.props.isProjekt}
                onChangeActivity={this.handleChangeDzialanie}
                onChangeInstitution={this.handleChangeInstytucja}
                onChangeInstitutionNumber={this.handleChangeInstitutionNumber}
                onChangePerspective={this.handleChangePerspektywa}
                onChangePriority={this.handleChangeOsPriorytetowa}
                onChangeProgramme={this.handleChangeProgramOperacyjny}
                onChangeRegion={this.handleChangeRegion}
            />
        )
    }

    renderOthers() {
        const { errors, suffix } = this.state;
        const isSuffixInvalid = (errors.numer || []).length > 0;
        const maxSuffixLength = this.props.suffixMask.length
        return (
            <FieldWithLabel
                label="Dalsza część numeru"
                labelFor="suffix_id"
                tag="label"
                selectRef={React.createRef()}
            >
                <TextFieldCustom
                    aria-describedby={`suffix_mask_info${isSuffixInvalid ? ' numer_instytucji_error' : ''}`}
                    aria-label={`Dalsza część numeru. Wpisano ${suffix.value.length} znaków.`}
                    aria-required={true}
                    aria-valuemax={maxSuffixLength}
                    characterCount
                    clearFieldContext="dalsza część numeru"
                    disabled={this.state.savingOn}
                    fullwidth
                    id="suffix_id"
                    invalid={isSuffixInvalid}
                    maxLength={maxSuffixLength}
                    value={suffix.value}
                    onChange={event => this.handleChangeOthers(event.target.value)}
                    onClear={event => this.handleChangeOthers('')} />
                {isSuffixInvalid && <ul id="numer_instytucji_error">{errors.numer.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                <Text info id="suffix_mask_info"><span className="sr-only">Wpisz numer zgodnie ze wzorem: </span>{this.props.suffixMask}</Text>
            </FieldWithLabel>
        )
    }

    renderNazwa() {
        const { errors, nazwa, savingOn } = this.state;
        const isNazwaInvalid = (errors.nazwa || []).length > 0;
        return (
            <FieldWithLabel label="Tytuł" tag="label" labelFor="title_id">
                <TextFieldCustom
                    aria-describedby={isNazwaInvalid ? 'title_error' : null}
                    aria-label={`Tytuł, maksymalna liczba znaków: 200. Wpisano ${nazwa.length} znaków.`}
                    aria-required={true}
                    aria-valuemax={200}
                    characterCount
                    clearFieldContext="tytuł"
                    disabled={savingOn}
                    invalid={isNazwaInvalid}
                    id="title_id"
                    maxLength={200}
                    required
                    fullwidth
                    value={nazwa}
                    onChange={(event) => this.setState({nazwa: event.target.value})}
                    onClear={(event) => this.setState({nazwa: ''})} />
                {isNazwaInvalid && <ul id="title_error">{errors.nazwa.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
            </FieldWithLabel>
        )
    }

    renderError(errorName) {
        if (this.state[errorName].length) {
            return (
                <GridCustom centerHorizontal>
                    <Text error>{this.state[errorName]}</Text>
                    <ButtonStyled onClick={() => this.setState({[errorName]: ''})} lite>OK</ButtonStyled>
                </GridCustom>
            );
        }
        return null
    }
}


export { Form };
