import React from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { dateToString, getLastTemporaryId, getSortedOrderItemsFromOrder,
         listOrEmpty } from '../../../helpers';
import {
    DateCalendar,
    FieldWithLabel,
    GridCustom,
    Steps,
    Text,
} from '../../common';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { BaseStepComponent } from './BaseStepComponent';
import { Harmonogramy } from './Harmonogramy';


class StepTerminy extends BaseStepComponent {
    stepName = 'terminy';

    // rendering

    render() {
        const { isPreview } = this.props;
        const formLocked = isPreview || this.context.validationOn || this.context.savingOn;
        const o = this.context.ogloszenie;
        const przedmiotyZamowienia = isPreview ? [] : getSortedOrderItemsFromOrder(o.zamowienia);

        let przedmiotyZamowienia_;
        if (przedmiotyZamowienia.length) {
            przedmiotyZamowienia_ = <ul>{przedmiotyZamowienia.map(pz =>
                <Harmonogramy
                    formLocked={formLocked}
                    harmonogramy={listOrEmpty(pz.harmonogramy)}
                    key={pz.temporaryId}
                    lastTemporaryId={getLastTemporaryId(
                        przedmiotyZamowienia, 'harmonogramy')}
                    okresGwarancji={pz.okresGwarancji}
                    opis={pz.opis}
                    podkategoria={pz.podkategoria}
                    przedmiotZamowieniaTemporaryId={pz.temporaryId}
                    typ={pz.kategoria}
                    onSaveHarmonogram={this.context.saveHarmonogram}
                    onRemoveHarmonogram={this.context.removeHarmonogram} />
            )}</ul>
        } else if ((this.context.formErrors.ogloszenie.przedmiotyZamowienia_harmonogramy || '').length) {
            przedmiotyZamowienia_ = <Text error>Zdefiniuj przedmioty zamówienia, aby móc dodawać do nich terminy.</Text>;
        }

        const planowanyTerminPodpisaniaUmowyInputRef = React.createRef();
        return (
            <>
                <PomocEkranowa ekran="form_announcement_deadlines" content={this.props.pomocEkranowaContent} />
                <GridCustom>
                    <section>
                        <FieldWithLabel label="Data opublikowania">
                            {isPreview ? '---' : o.ogloszenie.dataOpublikowania || '---'}
                        </FieldWithLabel>
                        <FieldWithLabel label="Planowany termin podpisania umowy" tag="label" inputRef={planowanyTerminPodpisaniaUmowyInputRef}>
                            <DateCalendar
                                dateCalendarRef={React.createRef()}
                                dateFormat="rok myślnik miesiąc"
                                dateFormatCustom="yyyy-MM"
                                dateName="Planowany termin podpisania umowy"
                                disabled={formLocked}
                                parentRef={planowanyTerminPodpisaniaUmowyInputRef}
                                showMonthYearPicker
                                value={isPreview ? '' : Date.parse(o.planowanyTerminPodpisaniaUmowy || '')}
                                onChange={date => this.context.setAttributeValue('planowanyTerminPodpisaniaUmowy', dateToString(date))} />
                        </FieldWithLabel>
                    </section>
                </GridCustom>
                {przedmiotyZamowienia_}
                <Steps
                    disabled={formLocked}
                    elementToFocusRef={this.props.elementToFocusRef}
                    hasNext
                    hasPrev
                    nextLabel="Warunki"
                    prevLabel="Przedmiot"
                    onNextClick={() => this.props.onChangeCurrentMenuLink('warunki')}
                    onPrevClick={() => this.props.onChangeCurrentMenuLink('przedmiot')}
                />
            </>
        )
    }
}


StepTerminy.contextType = AdvertisementContext;


export { StepTerminy }
