import React, { useEffect, useState } from "react";

import {
  ButtonStyled,
  FieldWithLabel,
  GridCustom,
  Select,
  TextFieldCustom,
  Text,
  DialogCustom,
} from "../../common";
import { customSelectStyles } from "../../vars/vars";

import { serializeObjectList } from "../../../serializers";

import { fetchApi } from "../../../fetch";

import {
  sortMiejsceRealizacjiFilter,
  sortProgrammeFilter,
} from "../../../sorters";

import { FormKodCPV } from "../ogloszenia/FormKodCPV";

const EMPTY_VALUE = {
  value: 0,
  label: "Wybierz",
};

const ORDER_ITEM_CATEGORIES = "order_item_categories";

const serializeProgrammeSelectStructure = (data) => {
  let data_ = {};
  for (let programme of Object.values(data)) {
    data_[programme.id] = `${programme.code.toUpperCase()} - ${programme.name}`;
  }
  return Object.entries(data_).sort(sortProgrammeFilter) || [];
};

const removeEmptyArraysAndNulls = (obj) => {
  if (!obj) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) =>
        value !== null && (!Array.isArray(value) || value.length > 0)
    )
  );
};

export const NewsletterSettingsFilter = (props) => {
  const [state, setState] = useState({
    isSelectOpen: false,
    dictionaries: {
      programOperacyjny: [],
      przedmiotZamowieniaKategoria: [],
      miejsceRealizacji: [],
    },
    currentDictionaries: {
      programOperacyjny: [],
      przedmiotZamowieniaKategoria: [],
      miejsceRealizacji: [],
    },
    errors: { errorNipOgloszeniodawcy: {} },
    filters: removeEmptyArraysAndNulls(props.filter) || {},
    isLoading: true,
  });

  useEffect(() => {
    fetchApi(
      "/api/dictionaries/search-filters",
      "GET",
      {},
      {},
      (data) => {
        const dictionaries = {
          przedmiotZamowieniaKategoria: serializeObjectList(
            data[ORDER_ITEM_CATEGORIES]
          ),
          programOperacyjny: serializeProgrammeSelectStructure(
            data.operation_programme
          ),
          miejsceRealizacji:
            Object.entries(data.location_id).sort(
              sortMiejsceRealizacjiFilter
            ) || [],
        };
        setState({ ...state, dictionaries: dictionaries, isLoading: false });
      },
      () => {},
      () => {}
    );
  }, []);

  /** Hook to filter out already selected dictionaries */
  useEffect(() => {
    const operationalProgramsIdsInFilter =
      state.filters.programme?.map((x) => `${x.value}`) ?? [];

    const fulfillmentPlacesIdsInFilter =
      state.filters.fulfillmentPlaces?.map((x) => x.value) ?? [];

    const currentDictionaries = {
      przedmiotZamowieniaKategoria:
        state.dictionaries.przedmiotZamowieniaKategoria,
      miejsceRealizacji: state.dictionaries.miejsceRealizacji.filter(
        (x) => !fulfillmentPlacesIdsInFilter.includes(x[0])
      ),
      programOperacyjny: state.dictionaries.programOperacyjny.filter(
        (x) => !operationalProgramsIdsInFilter.includes(x[0])
      ),
    };

    setState({ ...state, currentDictionaries });
  }, [state.filters, state.dictionaries]);

  const handleChangeFilter = (name, value) => {
    let dict = { [name]: value };
    if (name === "advertiserIdentificationNumber") {
      // allow to insert only letters and digits
      dict["advertiserIdentificationNumber"] = value.replace(/[^a-zA-Z\d]/, "");
    } else {
      dict[name] = value;
    }
    if (name === "category") {
      let updatedFilters = state.filters;
      updatedFilters.subcategory = undefined;
      setState({ ...state, filters: updatedFilters });
      delete dict["subcategory"];
    }
    // clean error (just in case if exists)
    let errors = Object.assign({}, state.errors);
    delete errors[name];

    const newFilters = Object.assign({}, state.filters, dict);

    if (value?.length === 0 || !value) {
      delete newFilters[name];
    }

    setState({
      ...state,
      errors,
      filters: newFilters,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      if (state.isSelectOpen) {
        event.stopPropagation();
      } else {
        props.close();
      }
    }
  };

  return state.isLoading ? (
    <></>
  ) : (
    <DialogCustom
      onClose={props.close}
      className="dialog-long-content"
      ariaLabelledby="addFilterLabel"
      onKeyDown={handleKeyDown}
    >
      <h2 id="addFilterLabel" className="text--main-header">
        Filtr newslettera
      </h2>
      <section className="filters-dialog__filters-section">
        <GridCustom fullwidth flexM>
          <FieldWithLabel
            label="Program Operacyjny"
            tag="label"
            selectRef={React.createRef()}
          >
            <Select
              screenReaderStatus={() => {
                return "Wybierz opcję z listy rozwijanej";
              }}
              className="select-custom"
              isMulti
              options={state.currentDictionaries.programOperacyjny.map(
                ([value, label]) => ({ value, label })
              )}
              placeholder="Zacznij wpisywać..."
              value={state.filters.programme}
              onChange={(selectedOption) =>
                handleChangeFilter("programme", selectedOption || [])
              }
              onMenuOpen={() => setState({ ...state, isSelectOpen: true })}
              onMenuClose={() => setState({ ...state, isSelectOpen: false })}
              styles={customSelectStyles}
              noOptionsMessage={() => "Brak wybranej opcji"}
            />
          </FieldWithLabel>
          <FieldWithLabel
            label="Kategoria przedmiotu zamówienia"
            tag="label"
            selectRef={React.createRef()}
          >
            <Select
              screenReaderStatus={() => {
                return "Wybierz opcję z listy rozwijanej";
              }}
              className="select-custom"
              options={[EMPTY_VALUE].concat(
                ...state.currentDictionaries.przedmiotZamowieniaKategoria.map(
                  (t) => ({
                    value: t.id,
                    label: t.nazwa,
                  })
                )
              )}
              value={state.filters.category || EMPTY_VALUE}
              onChange={(selectedOption) => {
                handleChangeFilter(
                  "category",
                  selectedOption.value === 0 ? null : selectedOption
                );
              }}
              onMenuOpen={() => setState({ ...state, isSelectOpen: true })}
              onMenuClose={() => setState({ ...state, isSelectOpen: false })}
              styles={customSelectStyles}
              noOptionsMessage={() => "Brak wybranej opcji"}
            />
          </FieldWithLabel>
        </GridCustom>
        <GridCustom fullwidth flexM>
          <FieldWithLabel
            label="Podkategoria"
            aria-label={
              state.filters.category !== null
                ? null
                : "Podkategoria, pole nieaktywne. Aby wybrać podkategorię, musisz wybrać kategorię przedmiotu zamówienia."
            }
            selectRef={React.createRef()}
            tag="label"
            tabIndex={state.filters.category !== null ? null : "0"}
          >
            <Select
              screenReaderStatus={() => {
                return "Wybierz opcję z listy rozwijanej";
              }}
              className="select-custom"
              isDisabled={state.filters.category === null}
              options={
                state.filters.category === null
                  ? [EMPTY_VALUE]
                  : [EMPTY_VALUE].concat(
                      ...(state.currentDictionaries.przedmiotZamowieniaKategoria
                        .filter(
                          (k) =>
                            parseInt(k.id) ===
                            parseInt(state.filters.category?.value)
                        )[0]
                        ?.podkategorie.map((p) => ({
                          value: p.id,
                          label: p.nazwa,
                        })) ?? [])
                    )
              }
              value={state.filters.subcategory || EMPTY_VALUE}
              onChange={(selectedOption) =>
                handleChangeFilter(
                  "subcategory",
                  selectedOption.value === 0 ? null : selectedOption
                )
              }
              onMenuOpen={() => setState({ ...state, isSelectOpen: true })}
              onMenuClose={() => setState({ ...state, isSelectOpen: false })}
              styles={customSelectStyles}
              aria-hidden={state.filters.category === null}
              aria-label={state.filters.category !== null && "Podkategoria"}
              noOptionsMessage={() => "Brak wybranej opcji"}
            />
          </FieldWithLabel>
          <FormKodCPV
            isMulti
            initial={state.filters.cpvItem}
            addKodCPV={(selectedOptions) =>
              handleChangeFilter("cpvItem", selectedOptions || [])
            }
          />
        </GridCustom>
        <GridCustom fullwidth flexM>
          <FieldWithLabel
            label="Miejsce realizacji"
            tag="label"
            selectRef={React.createRef()}
          >
            <Select
              screenReaderStatus={() => {
                return "Wybierz opcję z listy rozwijanej";
              }}
              className="select-custom"
              isMulti
              options={state.currentDictionaries.miejsceRealizacji.map((m) => ({
                value: m[0],
                label: m[1],
              }))}
              placeholder="Zacznij wpisywać..."
              value={state.filters.fulfillmentPlaces}
              onChange={(selectedOption) =>
                handleChangeFilter("fulfillmentPlaces", selectedOption || [])
              }
              onMenuOpen={() => setState({ ...state, isSelectOpen: true })}
              onMenuClose={() => setState({ ...state, isSelectOpen: false })}
              styles={{
                ...customSelectStyles,
                menuPortal: (provided, state) => ({
                  ...provided,
                  maxHeight: "160px",
                }),
                menuList: (provided, state) => ({
                  ...provided,
                  maxHeight: "160px",
                }),
              }}
              noOptionsMessage={() => "Brak wybranej opcji"}
            />
          </FieldWithLabel>
          <FieldWithLabel
            label="Numer identyfikacyjny ogłoszeniodawcy"
            tag="label"
            labelFor="filters_numer_identyfikacyjny_ogloszeniodawcy_id"
          >
            <TextFieldCustom
              aria-describedby={
                (state.errors.errorNipOgloszeniodawcy || "").length > 0
                  ? "filters_numer_identyfikacyjny_ogloszeniodawcy_error"
                  : null
              }
              aria-label={`Numer identyfikacyjny ogłoszeniodawcy: 255. Wpisz tylko cyfry. Wpisano ${
                state.filters.advertiserIdentificationNumber?.length ?? 0
              } znaków.`}
              aria-valuemax={255}
              characterCount
              className="filters__input"
              clearFieldContext="numer identyfikacyjny ogłoszeniodawcy"
              id="filters_numer_identyfikacyjny_ogloszeniodawcy_id"
              invalid={(state.errors.errorNipOgloszeniodawcy || "").length > 0}
              maxLength={255}
              onChange={(ev) =>
                handleChangeFilter(
                  "advertiserIdentificationNumber",
                  ev.target.value
                )
              }
              onClear={(ev) =>
                handleChangeFilter("advertiserIdentificationNumber", "")
              }
              onKeyUp={(ev) =>
                handleChangeFilter(
                  "advertiserIdentificationNumber",
                  ev.target.value
                )
              }
              value={state.filters.advertiserIdentificationNumber ?? ""}
            />
            {(state.errors.errorNipOgloszeniodawcy || "").length > 0 && (
              <Text
                error
                id="filters_numer_identyfikacyjny_ogloszeniodawcy_error"
              >
                {state.errors.errorNipOgloszeniodawcy}
              </Text>
            )}
          </FieldWithLabel>
        </GridCustom>
      </section>
      <GridCustom flexEnd>
        <ButtonStyled
          disabled={
            Object.keys(state.filters).filter((x) => x !== "id")?.length <= 0
          }
          onClick={() => {
            props.save(state.filters);
          }}
          primary
        >
          Zapisz
        </ButtonStyled>
        <ButtonStyled onClick={props.close} remove>
          Anuluj
        </ButtonStyled>
      </GridCustom>
    </DialogCustom>
  );
};
