import React, { Component } from 'react';

import { fetchApi } from '../../../fetch';
import { translateErrorMessage } from '../../../serializers';
import { validateOsobaDoKontaktu } from '../../../validators';
import {ButtonStyled, DialogCustom, FieldWithLabel, GridCustom, Text,
         TextFieldCustom } from '../../common';


const EMPTY_DATA = {
    id: null,
    imie: '',
    nazwisko: '',
    numerTelefonu: '',
    email: '',
};


class FormOsobaDoKontaktu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingOn: false,
            data: props.data !== null ? props.data : EMPTY_DATA,
            isNew: props.data === null,
            errors: {},
            fetchError: '',
        };

        this.dialogComponent = null;
        this.xhrFetchSave = null;
    }

    // basic functions

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetchSave !== null) {
            this.xhrFetchSave.abort();
        }
    }

    // handlers

    handleChangeAttribute = (name, value) => {
        this.setState(prevState => {
            let errors = Object.assign({}, prevState.errors);
            if (name === 'email') {
                delete errors[name];
            } else if (name === 'numerTelefonu') {
                value = value.replace(/[^\d+ -]/, '');
            }
            return {data: Object.assign({}, prevState.data, {[name]: value}), errors}
        });
    }

    handlePasteNumerTelefonu = (ev) => {
        // stop data actually being pasted into input
        ev.stopPropagation();
        ev.preventDefault();

        // set pasted data via clipboard API
        const clipboardData = ev.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').replace(/[^\d+ -]/g, '').trim();
        this.handleChangeAttribute('numerTelefonu', pastedData);
    }

    handleSave = () => {
        this.setState(
            {savingOn: true, fetchError: ''},
            () => {
                const [isValid, errors] = validateOsobaDoKontaktu(this.state.data);
                if (isValid) {
                    const { data, isNew } = this.state;
                    // save osobaDoKontaktu on server
                    this.xhrFetchSave = fetchApi(
                        isNew ? '/api/users/contact-persons' : `/api/users/contact-persons/${data.id}`,
                        isNew ? 'POST' : 'PUT',
                        {},
                        {
                            forename: data.imie,
                            surname: data.nazwisko,
                            phoneNumber: data.numerTelefonu,
                            email: data.email,
                        },
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors});
                }
            }
        );
    }

    handleFetchSaveSuccess = (data) => {
        this.xhrFetchSave = null;

        let data_ = Object.assign({}, this.state.data);
        if (this.state.isNew) {
            data_['id'] = data.contact_person.id;
        }
        this.props.saveOsobaDoKontaktu(data_, this.state.isNew);
    }

    handleFetchSaveError = (data) => {
        this.xhrFetchSave = null;     // clean xhr object
        this.setState({
            savingOn: false,
            fetchError: `Nie udało się zapisać osoby do kontaktu. ${translateErrorMessage(data.message)}`,
        });
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.xhrFetchSave = null;     // clean xhr object

        this.setState({
            savingOn: false,
            fetchError: `Podczas zapisu osoby do kontaktu wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    // rendering

    render() {
        const {data, errors, fetchError, savingOn} = this.state;
        const isImieInvalid = (errors.imie || '').length > 0;
        const isNazwiskoInvalid = (errors.nazwisko || '').length > 0;
        const isEmailInvalid = (errors.email || '').length > 0;
        const isFormInvalid = isImieInvalid || isNazwiskoInvalid || isEmailInvalid;

        return (
            <DialogCustom
                className="dialog-long-content"
                dialogTitle={`${this.state.isNew ? 'Dodaj' : 'Edytuj'} osobę do kontaktu`}
                dialogRef={(c) => { this.dialogComponent = c }}
                onClose={this.props.closeOsobaDoKontaktuFormForm}
            >
                <>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel label="Imię" tag="label" labelFor="name_id">
                            <TextFieldCustom
                                aria-describedby={isImieInvalid ? 'name_error' : null}
                                aria-label={`Imię, maksymalna liczba znaków: 500. Wpisano ${data.imie.length} znaków.`}
                                characterCount
                                className="filters__input"
                                clearFieldContext="imię"
                                disabled={savingOn}
                                id="name_id"
                                invalid={isImieInvalid}
                                maxLength={500}
                                value={data.imie}
                                onChange={(ev) => this.handleChangeAttribute('imie', ev.target.value)}
                                onClear={(ev) => this.handleChangeAttribute('imie', '')} />
                            {isImieInvalid && <Text error id="name_error">{errors.imie}</Text>}
                        </FieldWithLabel>
                        <FieldWithLabel label="Nazwisko" tag="label" labelFor="surname_id">
                            <TextFieldCustom
                                aria-describedby={isNazwiskoInvalid ? 'surname_error' : null}
                                aria-label={`Nazwisko, maksymalna liczba znaków: 500. Wpisano ${data.nazwisko.length} znaków.`}
                                characterCount
                                className="filters__input"
                                clearFieldContext="nazwisko"
                                disabled={savingOn}
                                id="surname_id"
                                invalid={isNazwiskoInvalid}
                                maxLength={500}
                                value={data.nazwisko}
                                onChange={(ev) => this.handleChangeAttribute('nazwisko', ev.target.value)}
                                onClear={(ev) => this.handleChangeAttribute('nazwisko', '')} />
                            {isNazwiskoInvalid && <Text error id="surname_error">{errors.nazwisko}</Text>}
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel label="Numer telefonu" tag="label" labelFor="phone_id">
                            <TextFieldCustom
                                aria-label={`Numer telefonu, maksymalna liczba znaków: 45. Wpisano ${data.numerTelefonu.length} znaków.`}
                                characterCount
                                className="filters__input"
                                clearFieldContext="numer telefonu"
                                disabled={savingOn}
                                id="phone_id"
                                maxLength={45}
                                value={data.numerTelefonu}
                                onChange={(ev) => this.handleChangeAttribute('numerTelefonu', ev.target.value)}
                                onClear={(ev) => this.handleChangeAttribute('numerTelefonu', '')}
                                onPaste={this.handlePasteNumerTelefonu} />
                        </FieldWithLabel>
                        <FieldWithLabel label="Adres e-mail" tag="label" labelFor="email_id">
                            <TextFieldCustom
                                aria-describedby={isEmailInvalid ? 'email_error' : null}
                                aria-label={`Adres e-mail, maksymalna liczba znaków: 500. Wpisano ${data.email.length} znaków.`}
                                characterCount
                                className="filters__input"
                                clearFieldContext="adres e-mail"
                                disabled={savingOn}
                                id="email_id"
                                invalid={isEmailInvalid}
                                maxLength={500}
                                type="email"
                                value={data.email}
                                onChange={(ev) => this.handleChangeAttribute('email', ev.target.value)}
                                onClear={(ev) => this.handleChangeAttribute('email', '')} />
                            {isEmailInvalid && <Text error id="email_error">{errors.email}</Text>}
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom flexEnd>
                        <ButtonStyled onClick={this.props.closeOsobaDoKontaktuFormForm} cancel>Anuluj</ButtonStyled>
                        <ButtonStyled onClick={this.handleSave} primary>
                            Zapisz<span className="sr-only"> osobę do kontaktu</span>
                        </ButtonStyled>
                    </GridCustom>
                    {fetchError.length > 0 && <Text error role="alert">{fetchError}</Text>}
                    {isFormInvalid && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
                </>
            </DialogCustom>
        )
    }
}


export { FormOsobaDoKontaktu };
