import React, { useEffect, useState, useContext } from "react";
import { TabBar, Tab } from "@rmwc/tabs";
import { useLocation, useHistory } from "react-router-dom";
import { Notifications } from "./Notifications";
import { NewsletterSettings } from "./NewsletterSettings";
import { Favorites } from "./Favorites";
import { UserContext } from "../../../context/user-context";

const tabIndexMap = new Map([
  ["newsletter", 0],
  ["powiadomienia", 1],
  ["ulubione", 2],
]);

function getMapKeyByValue(value) {
  for (let [key, val] of tabIndexMap.entries()) {
    if (val === value) {
      return key;
    }
  }
  return undefined;
}

const Newsletter = (props) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    sessionStorage.setItem(
      "currentPathname",
      window.location.pathname + window.location.search
    );
  }, []);

  useEffect(() => {
    const activeTab = location.search?.split("?sekcja=")?.[1];
    if (activeTab) {
      setActiveTabIndex(tabIndexMap.get(activeTab));
    }
  }, [location.search]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { user } = useContext(UserContext);

  return (
    <>
      {user.identifier === null ? (
        <p>Zaloguj się aby wyświetlić zawartość tej strony.</p>
      ) : (
        <>
          <TabBar
            activeTabIndex={activeTabIndex}
            onActivate={(event) => {
              const tabName = getMapKeyByValue(event.detail.index);
              history.push(`${window.location.pathname}?sekcja=${tabName}`);
            }}
          >
            <Tab>Newsletter</Tab>
            <Tab>Powiadomienia</Tab>
            <Tab>Ulubione</Tab>
          </TabBar>
          {activeTabIndex === 0 && <NewsletterSettings />}
          {activeTabIndex === 1 && <Notifications />}
          {activeTabIndex === 2 && <Favorites {...props} />}
        </>
      )}
    </>
  );
};

export { Newsletter };
