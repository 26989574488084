import React from 'react';

import { generatePdfDocument } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { ButtonCreatePdf, GridCustom } from '../../common';
import { BaseComponent } from '../common';
import { PytaniaForOgloszeniePdf } from './PytaniaForOgloszeniePdf';


class BasePytaniaForOgloszenieWidthPdf extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null; // clean xhr object
        this.setState({
            fetchError: '',
            initializingOn: false,
            pytania: getSerializedObject(data).pytania,
        });
    }

    handleCreatePdf = () => {
        const { ogloszenie, tytul } = this.props.ogloszenie;

        this.setState(
            {pdfCreatingOn: true},
            () => generatePdfDocument(
                <PytaniaForOgloszeniePdf
                    pytania={this.state.pytania}
                    tytulOgloszenia={tytul}
                    numerOgloszenia={ogloszenie.numer} />,
                `pytania_do_ogloszenia_nr_${ogloszenie.numer}.pdf`,
                this.callbackPfdCreated
            )
        );
    }

    // helpers

    callbackPfdCreated = () => {
        this.setState({pdfCreatingOn: false});
    }

    getFetchUrl() {
        return `/api/question/announcement/${this.props.ogloszenie.ogloszenie.id}`
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się pobrać pytań dla tego ogłoszenia. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania pytań dla tego ogłoszenia wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // rendering

    renderPrintButton() {
        return (
            <GridCustom flexEnd>
                <ButtonCreatePdf
                    pdfCreatingOn={this.state.pdfCreatingOn}
                    onClick={this.handleCreatePdf}>
                        Wydruk listy pytań<span className="sr-only"> do ogłoszenia {this.props.ogloszenie.tytul}</span>
                    </ButtonCreatePdf>
            </GridCustom>
        )
    }
}

export { BasePytaniaForOgloszenieWidthPdf };
