import React from 'react';
import { Typography } from '@rmwc/typography';
import { ButtonStyled } from '.';


const NextStep = (props) => {
    const {
        className,
        disabled,
        onClick,
        prevStep,
        stepTitle
    } = props;

    let nextStepClassName = 'next-step ';
    className && (nextStepClassName += className);

    return (
        <aside className={nextStepClassName}>
            <nav className='next-step__content'>
                <Typography use='button' theme={'textHintOnBackground'}>{prevStep ? 'Poprzedni' : 'Następny'} krok</Typography>
                {prevStep && <ButtonStyled disabled={disabled} onClick={onClick} prev><span className="sr-only">Poprzedni krok </span>{stepTitle}</ButtonStyled>}
                {!prevStep && <ButtonStyled disabled={disabled} onClick={onClick} next><span className="sr-only">Następny krok </span>{stepTitle}</ButtonStyled>}
            </nav>
        </aside>
    );
}

export { NextStep };
