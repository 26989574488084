import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';
import { TabBar, Tab } from '@rmwc/tabs';

import { Box, ButtonStyled, DialogCustom, GridCustom, Text } from '../../common';
import { FormRozstrzygniecieWariant } from './FormRozstrzygniecieWariant';


const TAB_INDEXES = {
    notRefused: 0,
    refused: 1,
};


class FormCzescRozstrzygniecia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: TAB_INDEXES['notRefused'],
            refusingOfertaId: null,
            restoringOfertaId: null,
        };
    }

    // handlers

    handleRefuse = (ofertaId) => {
        this.setState({refusingOfertaId: ofertaId});
    }

    handleRestore = (ofertaId) => {
        this.setState({restoringOfertaId: ofertaId});
    }

    handleCloseDialog = (ofertaIdAttributeName) => {
        this.setState({[ofertaIdAttributeName]: null});
    }

    // helpers

    hasManyWarianty(ofertaId) {
        return this.props.warianty.filter(w => w.ofertaId === ofertaId).length > 1
    }

    sortWarianty = (w1, w2) => {
        return w1.nazwaOferenta.localeCompare(w2.nazwaOferenta, 'pl')
    }

    // rendering

    render() {
        if (this.props.warianty.length) {
            return (
                <>
                    {this.renderHeader()}
                    <TabBar
                        activeTabIndex={this.state.selectedTab}
                        className="tab-bar"
                        onActivate={ev => this.setState({selectedTab: ev.detail.index})}
                    >
                        <Tab>Oferty spełniające kryteria</Tab>
                        <Tab>Oferty odrzucone</Tab>
                    </TabBar>
                    <Text tag="h2" sectionHeader>Lista wariantów{this.props.hasMultiplyZamowienia && ` do części`}</Text>
                    {this.props.hasMultiplyZamowienia && <Text label>{this.props.zamowienie.tytul}</Text>}
                    {this.renderNotRefused()}
                    {this.renderRefused()}
                </>
            )
        }
        return (
            <>
                {this.renderHeader()}
                <Text tag="h2" sectionHeader>Lista ofert{this.props.hasMultiplyZamowienia && ` do części`}</Text>
                {this.props.hasMultiplyZamowienia && <Text label>{this.props.zamowienie.tytul}</Text>}
                {this.renderNotRefused()}
            </>
        )
    }

    renderHeader() {
        const zamowienie = this.props.zamowienie;

        return (
            <>
                <div className="offer-resolve__name">
                    <span className="sr-only" role="alert">Wybierz ofertę spośród listy ofert spełniających kryteria. Możesz odrzucić oferty. Możesz nie wybrać żadnej oferty.</span>
                    <Text tag="h2" sectionHeader>Rozstrzygnięcie części:</Text>
                    <Text label>{zamowienie.tytul}</Text>
                </div>

                <GridCustom tag="ul">
                    {zamowienie.kryteriaOceny.map(ko => (
                        <Box key={ko.id} tag="li" titleOneRow={ko.czyKryteriumCenowe ? 'Kryterium cenowe' : 'Kryterium pozacenowe'}>
                            {ko.opis}
                        </Box>
                    ))}
                </GridCustom>

            </>
        )
    }

    renderNotRefused() {
        if (this.state.selectedTab !== TAB_INDEXES['notRefused']) {return null}
        const zamowienie = this.props.zamowienie;
        const warianty = this.props.warianty.filter(w => !w.isRefused);

        return (
            <>
                <Text info>Liczba wyników: {warianty.length}</Text>
                <GridCustom tag="ul">
                    {warianty.sort(this.sortWarianty).map(w => (
                        <FormRozstrzygniecieWariant
                            hasOfertaManyWarianty={this.hasManyWarianty(w.ofertaId)}
                            key={w.id}
                            wariant={w}
                            zamowienieNode={zamowienie.zamowienieNode.id}
                            onOfferRefuse={this.handleRefuse}
                            onWariantSelect={this.props.onWariantSelect} />
                    ))}
                    {!warianty.length && <Text error>Nie złożono ofert{this.props.hasMultiplyZamowienia && ` do części: "${this.props.zamowienie.tytul}"`}</Text>}
                </GridCustom>
                <GridCustom centerHorizontal>
                    <Checkbox
                        className="checkbox--btn"
                        label="Nie wybieram żadnej oferty"
                        checked={this.props.zadnaOfertaWybrana}
                        onChange={event => this.props.onNoOfferSelectedChange(event.target.checked, zamowienie.zamowienieNode.id)}
                    />
                </GridCustom>
                {this.state.refusingOfertaId !== null && this.renderRefusingDialog()}
            </>
        )
    }

    renderRefused() {
        if (this.state.selectedTab !== TAB_INDEXES['refused']) {return null}
        const warianty = this.props.warianty.filter(w => w.isRefused);
        return (
            <>
                <Text info>Liczba wyników: {warianty.length}</Text>
                <GridCustom tag="ul">
                    {warianty.sort(this.sortWarianty).map(w => (
                        <FormRozstrzygniecieWariant
                            hasOfertaManyWarianty={this.hasManyWarianty(w.ofertaId)}
                            key={w.id}
                            refused
                            wariant={w}
                            zamowienieNode={this.props.zamowienie.zamowienieNode.id}
                            onOfferRestore={this.handleRestore}
                        />
                    ))}
                </GridCustom>
                {this.state.restoringOfertaId !== null && this.renderRestoringDialog()}
            </>
        )
    }

    renderRefusingDialog() {
        return this.renderDialog(
            'refusingOfertaId',
            'odrzucić',
            this.props.onOfferRefuse,
            'Oferta zostanie odrzucona z chwilą zapisania rozstrzygnięcia.'
        )
    }

    renderRestoringDialog() {
        return this.renderDialog(
            'restoringOfertaId',
            'przywrócić',
            this.props.onOfferRestore,
            'Oferta zostanie przywrócona z chwilą zapisania rozstrzygnięcia.'
        )
    }

    renderDialog(ofertaIdAttributeName, titleAdverb, callback, additionalInfo) {
        let title = `Czy na pewno chcesz ${titleAdverb} tę ofertę`;
        if (this.hasManyWarianty(this.state[ofertaIdAttributeName])) {
            title += ' wraz ze wszystkimi jej wariantami?';
        } else {
            title += '?';
        }
        return (
            <DialogCustom
                dialogTitle={title}
                onClose={() => this.handleCloseDialog(ofertaIdAttributeName)}
            >
                <>
                    <Text>{additionalInfo}</Text>
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled cancel onClick={() => this.handleCloseDialog(ofertaIdAttributeName)}>Nie</ButtonStyled>
                        <ButtonStyled
                            save
                            onClick={() => {
                                const ofertaId = this.state[ofertaIdAttributeName];
                                this.setState(
                                    {[ofertaIdAttributeName]: null},
                                    () => callback(this.props.zamowienie.zamowienieNode.id, ofertaId)
                                );
                            }}
                        >Tak</ButtonStyled>
                    </GridCustom>
                </>
            </DialogCustom>
        )
    }
}


export { FormCzescRozstrzygniecia };
