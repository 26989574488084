import React from 'react';
import { Switch } from '@rmwc/switch';

import { CZY_PROJEKT_CHOICES } from '../../../const';
import { fetchApi } from '../../../fetch';
import { filterProjects, PROJECTS_CLEANED_FILTERS } from '../../../filters';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { PROJECTS_SORTING_VALUES, sortProjects } from '../../../sorters';
import {
    Box,
    ButtonStyled,
    Container,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { BaseComponent } from '../common';
import { PomocEkranowa } from '../zarzadzanieTrescia/PomocEkranowa';
import { Form } from './Form';
import { Projekt } from './Projekt';


class Projekty extends BaseComponent {

    // basic functions

    componentDidMount() {
        if (this.props.preview) {
            this.setState({initializingOn: false});
            return
        }

        document.title = "Baza Konkurencyjności - projekty";
        super.componentDidMount();
    };

    // handlers

    handleFetchSuccess = (data) => {
        super.handleFetchSuccess(data);
        this.setState({
            projekty: getSerializedObject(data).projektyNabory,
            initializingOn: false,
        });
    }

    handleSave = (data) => {
        this.setState(prevState => {
            return {
                formOn: false,
                projekty: [...prevState.projekty, data],
            }
        });
    }

    handleToggleFilters(checked) {
        if (checked) {
            this.setState({filtersAreOn: true});
        } else {
            this.setState({
                filtersAreOn: false,
                filters: PROJECTS_CLEANED_FILTERS,
            });
        }
    }

    handleChangeFilter(name, value) {
        this.setState(prevState => {
            return {filters: Object.assign({}, prevState.filters, {[name]: value})}
        });
    }

    handleRemove = (id, isProject) => {
        // remove project on server
        this.xhrFetch = fetchApi(
            `/api/${isProject ? 'project' : 'enrollment'}/${id}`,
            'DELETE',
            {},
            {},
            this.handleFetchRemoveSuccess,
            this.handleFetchRemoveError,
            this.handleFetchRemoveIncorrectStatus,
        );
    }

    handleFetchRemoveSuccess = (data) => {
        this.xhrFetch = null;
        const serializedData = getSerializedObject(data);
        this.setState(prevState => ({
            projekty: prevState.projekty.filter(
                p => parseInt(p.id) !== parseInt((serializedData.projekt || serializedData.nabor || {}).id),
            ),
            removingError: '',
        }));
    }

    handleFetchRemoveError = (data) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({removingError: `Nie udało się usunąć obiektu. ${translateErrorMessage(data.message)}`});
    }

    handleFetchRemoveIncorrectStatus = (status) => {
        this.xhrFetch = null;     // clean xhr object
        this.setState({removingError: `Nie udało się usunąć obiektu. Wystąpił nieoczekiwany błąd o kodzie ${status}.`});
    }

    // helpers

    getAdditionalState(props) {
        return {
            currentSortingValue: {
                value: PROJECTS_SORTING_VALUES[0][0],
                label: PROJECTS_SORTING_VALUES[0][1],
            },
            czyNewProjekt: true,
            formOn: false,
            filtersAreOn: false,
            filters: Object.assign({}, PROJECTS_CLEANED_FILTERS),
            projekty: [],
            removingError: '',
        }
    }

    getFetchUrl() {
        return '/api/project_enrollment'
    }

    getFetchError(message) {
        return `Nie udało się pobrać danych projektów i naborów. ${translateErrorMessage(message)}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych projektów i naborów. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // rendering

    renderContent() {
        const {
            currentSortingValue,
            filters,
            filtersAreOn,
            formOn,
            projekty
        } = this.state;

        const projects = sortProjects(
            filterProjects(projekty, filtersAreOn, filters),
            currentSortingValue.value
        );

        const isPreview = !!this.props.preview;

        return (
            <>
                <PomocEkranowa ekran="projects_list_advertiser" content={isPreview ? this.props.content : null}/>
                <Text tag="h2" mainHeader>Lista projektów i naborów</Text>
                <Text info className="text--counter">Liczba wyników: {projects.length}</Text>
                <Container className=" filters-container">
                    <GridCustom>
                        <FieldWithLabel label="Sortuj" tag="label" selectRef={React.createRef()}>
                            <Select
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                value={currentSortingValue}
                                isDisabled={isPreview}
                                isSearchable={false}
                                options={PROJECTS_SORTING_VALUES.map(([value, label]) => ({value, label}))}
                                onChange={(selectedOption) => this.setState({currentSortingValue: selectedOption})}
                                className="select-custom"
                                styles={customSelectStyles}
                                noOptionsMessage={() => 'Brak wybranej opcji'}
                            />
                        </FieldWithLabel>
                        <FieldWithLabel
                            className="filters-container__filters"
                            label="Filtruj"
                            tag="label"
                            labelFor="project_filters_id"
                        >
                            <Switch
                                aria-label={`Filtruj. Panel filtrów ${this.state.filtersAreOn ? 'otwarty, zawartość listy będzie dopasowana po wpisaniu każdego nowego znaku w panelu filtrów.' : 'ukryty'}`}
                                checked={filtersAreOn}
                                disabled={isPreview}
                                onChange={(ev) => this.handleToggleFilters(ev.target.checked)}
                                id="project_filters_id"
                            />
                        </FieldWithLabel>
                    </GridCustom>
                </Container>
                <GridCustom flexL noWrap flexTop>
                    {filtersAreOn && this.renderFilters()}
                    <GridCustom tag="ul">
                        <Box flexiblewidth tag="li">
                            <div>
                                <ButtonStyled
                                    add
                                    disabled={isPreview}
                                    onClick={(e) => {
                                        this.setState({formOn: true, czyNewProjekt: true});
                                        e.currentTarget.blur();
                                    }}>
                                    Dodaj projekt
                                </ButtonStyled>
                                <ButtonStyled
                                    add
                                    disabled={isPreview}
                                    onClick={(e) => {
                                        this.setState({formOn: true, czyNewProjekt: false});
                                        e.currentTarget.blur();
                                    }}>
                                    Dodaj nabór
                                </ButtonStyled>
                            </div>
                        </Box>
                        {projects.map(p => <Projekt key={p.id} projekt={p} onRemove={this.handleRemove} />)}
                    </GridCustom>
                </GridCustom>
                {formOn && this.renderForm()}
                {this.state.removingError.length > 0 && this.renderRemovingErrorDialog()}
            </>
        )
    }

    renderFilters() {
        const filters = this.state.filters;
        return (
            <Container className="filters">
                <GridCustom>
                    <FieldWithLabel label="Numer projektu lub naboru" tag="label" labelFor="filters_project_number_id">
                        <TextFieldCustom
                            autoFocus
                            className="filters__input"
                            clearFieldContext="numer projektu lub naboru"
                            id="filters_project_number_id"
                            onChange={(ev) => this.handleChangeFilter('calyNumer', ev.target.value)}
                            onClear={(ev) => this.handleChangeFilter('calyNumer', '')}
                            value={filters.calyNumer}
                        />
                    </FieldWithLabel>
                    <FieldWithLabel label="Tytuł projektu lub naboru" tag="label" labelFor="filters_project_title_id">
                        <TextFieldCustom
                            clearFieldContext="tytuł projektu"
                            value={filters.nazwa}
                            onChange={(ev) => this.handleChangeFilter('nazwa', ev.target.value)}
                            className="filters__input"
                            onClear={(ev) => this.handleChangeFilter('nazwa', '')}
                            id="filters_project_title_id" />
                    </FieldWithLabel>
                    <FieldWithLabel
                        label="Typ obiektu"
                        tag="label"
                        selectRef={React.createRef()}
                    >
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom"
                            options={CZY_PROJEKT_CHOICES.map(s => ({value: s[0], label: s[1]}))}
                            value={{value: filters.typ.value || CZY_PROJEKT_CHOICES[0][0], label: filters.typ.label || CZY_PROJEKT_CHOICES[0][1]}}
                            onChange={(selectedOption) => this.handleChangeFilter('typ', selectedOption)}
                            styles={customSelectStyles}
                        />
                    </FieldWithLabel>
                </GridCustom>
            </Container>
        )
    }

    renderForm() {
        let isProjekt, suffixMask, saveUrl, title;
        if (this.state.czyNewProjekt) {
            isProjekt = true;
            suffixMask = "0000/00";
            saveUrl = '/api/project';
            title = 'Dodaj projekt';
        } else {
            isProjekt = false;
            suffixMask = "000/00";
            saveUrl = '/api/enrollment';
            title = 'Dodaj nabór';
        }
        return <Form
            isProjekt={isProjekt}
            suffixMask={suffixMask}
            saveUrl={saveUrl}
            title={title}
            userProjectsNumbers={this.state.projekty.map(p => p.numer)}
            onClose={() => this.setState({formOn: false})}
            onSave={this.handleSave} />
    }

    renderRemovingErrorDialog() {
        return (
            <DialogCustom
                className="dialog"
                dialogTitleError={this.state.removingError}
                onClose={() => this.setState({ removingError: '' })}
            >
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled onClick={() => this.setState({removingError: ''})} primary>OK</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }
}


export { Projekty };
