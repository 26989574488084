import React from 'react';
import { Radio } from '@rmwc/radio';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { getLastTemporaryId, listOrEmpty } from '../../../helpers';
import {
    ButtonStyled,
    Container,
    GridCustom,
    Steps,
    Text,
} from '../../common';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { AlreadyPublishedInfo } from './AlreadyPublishedInfo';
import { BaseStepComponent } from './BaseStepComponent';
import { Zamowienie } from './Zamowienie';


class StepCzesciKryteria extends BaseStepComponent {
    stepName = 'czesciKryteria';

    constructor(props) {
        super(props);
        this.state = {draggedTemporaryId : null};
    }

    // handlers

    handleSaveCzyDopuszczalnaOfertaCzesciowa = (ev) => {
        const czyDopuszczalnaOfertaCzesciowa = ev.currentTarget.value === 'true';
        this.context.setAttributeValue(
            'czyDopuszczalnaOfertaCzesciowa', czyDopuszczalnaOfertaCzesciowa);
    }

    handleSaveDraggableTemporaryId = (temporaryId) => {
        return new Promise((resolve, reject) => {
            this.setState({draggedTemporaryId: temporaryId}, resolve());
        })
    }

    handleMovePrzedmiotZamowienia = (zamowienieTemporaryId) => {
        this.context.movePrzedmiotZamowienia(
            zamowienieTemporaryId,
            this.state.draggedTemporaryId,
            () => this.setState({draggedTemporaryId: null})
        );
    }

    // rendering

    render() {
        const { isAlreadyPublished, isPreview } = this.props;
        const formLocked = isPreview || this.context.validationOn || this.context.savingOn;
        const o = isPreview ? {} : this.context.ogloszenie;
        const czyDopuszczalnaOfertaCzesciowa = isPreview ? false : o.czyDopuszczalnaOfertaCzesciowa;
        const changeCzyDopuszczalnaOfertaCzesciowaForbidden = czyDopuszczalnaOfertaCzesciowa && o.zamowienia.length > 1;
        const radioDisabled = isAlreadyPublished || formLocked || changeCzyDopuszczalnaOfertaCzesciowaForbidden;

        return (
            <>
                <PomocEkranowa ekran="form_announcement_partscriteria" content={this.props.pomocEkranowaContent} />
                {isAlreadyPublished && <AlreadyPublishedInfo />}
                <Container>
                    <fieldset>
                        <Text tag="legend" className="label">Czy dopuszczalna oferta częściowa?</Text>
                        <Radio
                            value={true}
                            disabled={radioDisabled}
                            name="czyDopuszczalnaOfertaCzesciowaRadioGroup"
                            checked={czyDopuszczalnaOfertaCzesciowa === true}
                            onChange={this.handleSaveCzyDopuszczalnaOfertaCzesciowa}
                            label="Tak"
                            aria-label="Czy dopuszczalna oferta częściowa? Tak"
                            id="radio_id"
                        />
                        <Radio
                            value={false}
                            disabled={radioDisabled}
                            name="czyDopuszczalnaOfertaCzesciowaRadioGroup"
                            checked={czyDopuszczalnaOfertaCzesciowa === false}
                            onChange={this.handleSaveCzyDopuszczalnaOfertaCzesciowa}
                            label="Nie"
                            aria-label="Czy dopuszczalna oferta częściowa?  Nie"
                        />
                    </fieldset>
                    {!isAlreadyPublished && changeCzyDopuszczalnaOfertaCzesciowaForbidden &&
                        <Text info>Usuń dodatkowe części zamówienia aby odblokować tę opcję.</Text>}
                </Container>

                {this.renderZamowienia(formLocked)}

                <Steps
                    disabled={formLocked}
                    elementToFocusRef={this.props.elementToFocusRef}
                    hasNext
                    hasPrev
                    nextLabel="Pozostałe"
                    prevLabel="Warunki"
                    onNextClick={() => this.props.onChangeCurrentMenuLink('pozostale')}
                    onPrevClick={() => this.props.onChangeCurrentMenuLink('warunki')}
                />
            </>
        )
    }

    renderZamowienia(formLocked) {
        if (!this.props.isPreview && this.context.ogloszenie.zamowienia.length) {
            const isAlreadyPublished = this.props.isAlreadyPublished;
            const {
                czyDopuszczalnaOfertaCzesciowa,
                zamowienia,
            } = this.context.ogloszenie;
            const zamowienieTemporaryIds = zamowienia.map((z, i) => [z.temporaryId, i + 1]);
            return (
                <>
                    <Text info>Liczba części zamówienia: {zamowienia.length}</Text>
                    <ul>
                        {listOrEmpty(zamowienia).map((z, i) => (
                            <Zamowienie
                                isAlreadyPublished={isAlreadyPublished}
                                formLocked={formLocked}
                                key={z.temporaryId}
                                index={i + 1}
                                kryteriumOcenyLastTemporaryId={getLastTemporaryId(
                                    zamowienia, 'kryteriaOceny')}
                                zamowienie={z}
                                zamowienieTemporaryIds={zamowienieTemporaryIds}
                                onSetZamowienieAttribute={this.context.setZamowienieAttributeValue}
                                onSaveKryteriumOceny={this.context.saveKryteriumOceny}
                                onRemoveKryteriumOceny={this.context.removeKryteriumOceny}
                                onRemoveZamowienie={this.context.removeZamowienie}
                                saveDraggedTemporaryId={this.handleSaveDraggableTemporaryId}
                                onMovePrzedmiotZamowienia={this.handleMovePrzedmiotZamowienia} />
                        ))}
                    </ul>
                    {!isAlreadyPublished && czyDopuszczalnaOfertaCzesciowa && (
                        <GridCustom flexEnd>
                            <ButtonStyled disabled={formLocked} onClick={this.context.addZamowienie} add>Dodaj część zamówienia</ButtonStyled>
                        </GridCustom>
                    )}
                </>
            )
        } else if ((this.context.formErrors.ogloszenie.zamowienia_kryteriaOceny || '').length) {
            return <Text error>Zdefiniuj przedmioty zamówienia, aby móc dodawać kryteria do części zamówienia.</Text>
        } else {return null}
    }
}


StepCzesciKryteria.contextType = AdvertisementContext;


export { StepCzesciKryteria }
