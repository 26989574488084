import React from 'react';

import { hasPerspectiveRegions } from '../../../helpers';
import { Text } from '../../common';
import {
    PermissionListImplementationLevelCellProgrammePriorityActivity
} from './PermissionListImplementationLevelCellProgrammePriorityActivity';
import { PermissionsMoreOptionButton } from './PermissionsMoreOptionButton';


export const PermissionListImplementationLevelCell = ({
    permission, onSetActiveHistoryPermission, onSetActiveUsersPermission
}) => {
    const {
        perspective = null,
        programme = null,
        priority = null,
        activity = null,
        institution = null,
        region = null
    } = permission;

    const hasRegions = hasPerspectiveRegions(perspective);
    const theLowestLevel = hasRegions ? region : institution;

    // region/institution level
    if ([programme, priority, activity, theLowestLevel].every(el => el !== null)) {
        const permissionForActionDict = {
            isImplementationLevelType: true,
            permission: {
                perspective,
                programme,
                priority,
                activity,
                [hasRegions ? 'region' : 'institution']: theLowestLevel
            },
        };
        return (
            <>
                <Text>{programme.code} {programme.name}</Text>
                <div className="permissions__level-row">
                    <Text className="permissions__indented permissions__indented--on-start">
                        {priority.code} {priority.name}
                    </Text>
                </div>
                <div className="permissions__level-row">
                    <Text className="permissions__indented permissions__indented--on-start">
                        {activity.code} {activity.name}
                    </Text>
                </div>
                <div className="permissions__level-row">
                    <PermissionsMoreOptionButton
                        onOpenHistory={() => onSetActiveHistoryPermission(
                            permissionForActionDict)}
                        onOpenUserList={() => onSetActiveUsersPermission(
                            permissionForActionDict)} />
                    <Text className="permissions__main-name">
                        {theLowestLevel.code} {theLowestLevel.name}
                    </Text>
                </div>
            </>
        )
    }

    // activity level
    if (
        [programme, priority, activity].every(el => el !== null) &&
        theLowestLevel === null
    ) {
        return (
            <>
                <Text>{programme.code} {programme.name}</Text>
                <PermissionListImplementationLevelCellProgrammePriorityActivity
                    fullPermission={permission}
                    hasRegions={hasRegions}
                    isMainLevel={true}
                    object={activity}
                    objectType={hasRegions ? 'subactivity' : 'activity'}
                    onSetActiveHistoryPermission={onSetActiveHistoryPermission}
                    onSetActiveUsersPermission={onSetActiveUsersPermission} />
            </>
        )
    }

    // priority level
    if (
        [programme, priority].every(el => el !== null) &&
        [activity, theLowestLevel].every(el => el === null)
    ) {
        return (
            <>
                <Text>{programme.code} {programme.name}</Text>
                <PermissionListImplementationLevelCellProgrammePriorityActivity
                    fullPermission={permission}
                    hasRegions={hasRegions}
                    isMainLevel={true}
                    object={priority}
                    objectType="priority"
                    onSetActiveHistoryPermission={onSetActiveHistoryPermission}
                    onSetActiveUsersPermission={onSetActiveUsersPermission} />
            </>
        )
    }

    // programme level
    if (
        programme !== null &&
        [priority, activity, theLowestLevel].every(el => el === null)
    ) {
        return (
            <PermissionListImplementationLevelCellProgrammePriorityActivity
                fullPermission={permission}
                hasRegions={hasRegions}
                isMainLevel={true}
                object={programme}
                objectType="programme"
                onSetActiveHistoryPermission={onSetActiveHistoryPermission}
                onSetActiveUsersPermission={onSetActiveUsersPermission} />
        )
    }
    return null
};
