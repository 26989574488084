import React from 'react';

import { fetchApi } from '../../../fetch';
import { generatePdfDocument } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { ButtonCreatePdf, ButtonStyled, Container, GridCustom, Text } from '../../common';
import { ZalacznikiSelect } from '../common';
import { FormRozstrzygniecie } from '../ogloszenia';
import { OfertyOwnerClosedStatus } from './OfertyOwnerClosedStatus';
import { OfertyResolvedStatusPdf } from './OfertyResolvedStatusPdf';


class OfertyOwnerResolvedStatus extends OfertyOwnerClosedStatus {

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;
        const serializedData =  getSerializedObject(data, {toFixAttributes: ['cena', ]});

        if (this.props.setAdvertisementManagerType && data.extra && Object.keys(data.extra).length) {
            this.props.setAdvertisementManagerType({
                isAuthor: data.extra.is_advertiser, isAdmin: data.extra.is_admin
            });
        }

        this.setState(
            {pakietyOfert: serializedData.pakietyOfert},
            () => {
                this.xhrFetch = fetchApi(
                    `/api/announcements/${this.props.ogloszenie.ogloszenie.id}/settlement`,
                    'GET',
                    {},
                    {},
                    this.handleFetchSettlementSuccess,
                    this.handleFetchError,
                    this.handleFetchIncorrectStatus,
                );
            }
        );
    }

    handleFetchIncorrectStatus(status) {
        this.xhrFetch = null;
        this.setState({
            initializingOn: false,
            fetchError: this.getFetchIncorrectStatusError(status),
        });
    }

    handleFetchSettlementSuccess = (data) => {
        this.setState({
            initializingOn: false,
            rozstrzygniecie: getSerializedObject(data).rozstrzygniecie,
        })
    }

    handleCreatePdf = () => {
        const { ogloszenie } = this.props;
        this.setState(
            {pdfCreatingOn: true},
            () => generatePdfDocument(
                <OfertyResolvedStatusPdf
                    pakietyOfert={this.state.pakietyOfert}
                    rozstrzygniecie={this.state.rozstrzygniecie}
                    tytulOgloszenia={ogloszenie.tytul}
                    zamowienia={this.props.ogloszenie.zamowienia} />,
                `rozstrzygniecie_ogloszenia_${ogloszenie.ogloszenie.id}.pdf`,
                this.callbackPfdCreated
            )
        );
    }

    // helpers

    getAdditionalState(props) {
        return Object.assign({}, super.getAdditionalState(props), {pdfCreatingOn: false})
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się pobrać danych rozstrzygnięcia. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych rozstrzygnięcia. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callbackPfdCreated = () => {
        this.setState({pdfCreatingOn: false});
    }

    checkRefused(offerId) {
        let isRefused = false;
        for (let z of this.state.rozstrzygniecie.zamowieniaNodes) {
            for (let o of z.oferty) {
                if (o.id === offerId) {
                    isRefused = o.isRefused;
                    break
                }
            }
        }
        return isRefused
    }

    // rendering

    renderElements() {
        const isAdvertisementOwner = this.props.isAdvertisementOwner;
        if (isAdvertisementOwner && this.state.formOn) {
            return <FormRozstrzygniecie {...this.props} isNew={false} pakietyOfert={this.state.pakietyOfert} />
        }
        const onlyAdmin = this.props.onlyAdmin;
        return (
            <>
                {isAdvertisementOwner && this.renderPdfButton()}
                {onlyAdmin && this.renderMyOfferButton()}
                {this.renderContent()}
                {this.renderZalacznikiRozstrzygniecia()}
                {this.renderKomentarz()}
                {isAdvertisementOwner && (
                    <GridCustom flexEnd>
                        <ButtonStyled className="btn btn--main" primary onClick={() => this.setState({formOn: true})}>
                            Zmień rozstrzygnięcie
                        </ButtonStyled>
                    </GridCustom>
                )}
                {onlyAdmin && this.state.previewOn && this.renderPreview()}
            </>
        )
    }

    renderPdfButton() {
        return (
            <GridCustom flexEnd>
                <ButtonCreatePdf
                    pdfCreatingOn={this.state.pdfCreatingOn}
                    onClick={this.handleCreatePdf}
                >
                    Wydruk listy ofert <span className="sr-only">Pobierz plik PDF ofert z rozstrzygnięciem ogłoszenia {this.props.ogloszenie.tytul}</span>
                </ButtonCreatePdf>
            </GridCustom>
        )
    }

    renderZamowienie(zamowienie, isMultiply) {
        return (
            <div key={zamowienie.id} className="offer__list">
                <div className="offer-resolve__name">
                    <Text tag="h2" sectionHeader>Rozstrzygnięcie{isMultiply && ` części:`}</Text>
                    {isMultiply && <Text label>{zamowienie.tytul}</Text>}
                </div>
                {this.renderSelectedOferta(zamowienie, isMultiply)}
                {this.renderOferty(zamowienie, isMultiply)}
            </div>
        )
    }

    renderSelectedOferta(zamowienie, isMultiply) {
        const zamowienieFromRozstrzygniecie = (
            this.state.rozstrzygniecie.zamowieniaNodes || []).filter(
                z => z.id === zamowienie.zamowienieNode.id)[0];
        if (!zamowienieFromRozstrzygniecie || !zamowienieFromRozstrzygniecie.wybranyWariant) {
            return <Text accentHeader>{isMultiply ? `Dla części: "${zamowienie.tytul}" nie` : 'Nie'} wybrano żadnej oferty.</Text>
        }

        const selectedWariantId = zamowienieFromRozstrzygniecie.wybranyWariant.id;
        let selectedPakietOfert = null;
        let selectedOferta = null;
        let selectedWariant = null;
        for (let po of this.state.pakietyOfert) {
            for (let o of po.oferty) {
                for (let w of o.warianty) {
                    if (w.id === selectedWariantId) {
                        selectedPakietOfert = po;
                        selectedOferta = o;
                        selectedWariant = w;
                        break
                    }
                }
                if (selectedWariant !== null) {
                    break
                }
            }
            if (selectedWariant !== null) {
                break
            }
        }

        if (!selectedWariant) {
            return <Text error role="alert">Wystąpił nieoczekiwany błąd.</Text>
        }

        let header;
        if (isMultiply) {
            header = `Dla części: "${zamowienie.tytul}" została wybrana oferta`;
        } else {
            header = 'Została wybrana oferta';
        }

        const oferta_ = {
            id: selectedOferta.id,
            danePodmiotu: selectedPakietOfert.danePodmiotu,
            warianty: [selectedWariant, ],
            zalaczniki: selectedPakietOfert.zalaczniki,
        }
        const userId = this.props.user.id;
        const showMyOfferLabel = this.props.isAdvertisementOwner ? false : (
            !!userId ? selectedPakietOfert.ktoUtworzyl.id === userId : false);

        return (
            <div key={selectedOferta.id}>
                <Text tag="h3" accentHeader>{header}</Text>
                {this.renderOfertaBox(oferta_, showMyOfferLabel, true)}
            </div>
        )
    }

    renderOferty(zamowienie, isMultiply) {
        let oferty = [];
        for (let po of this.state.pakietyOfert) {
            for (let o of po.oferty) {
                if (o.zamowienieNode.id === zamowienie.zamowienieNode.id) {
                    let oferta = Object.assign({}, o, {
                        oferentId: po.ktoUtworzyl.id,
                        danePodmiotu: po.danePodmiotu,
                        zalaczniki: po.zalaczniki,
                        isRefused: this.checkRefused(o.id),
                    });
                    oferty.push(oferta);
                    break
                }
            }
        }

        if (!oferty.length) {
            return <Text info>Nie złożono żadnej oferty{isMultiply ? ` do części: ${zamowienie.tytul}` : ''}</Text>
        }

        // exclude selected offer

        const selectedWariantId = ((this.state.rozstrzygniecie.zamowieniaNodes || []).filter(
            z => z.id === zamowienie.zamowienieNode.id)[0] || {}).wybranyWariant.id;

        let filteredOferty = [];
        for (let o of oferty) {
            let oferta = Object.assign({}, o);
            oferta.warianty = o.warianty.filter(w => w.id !== selectedWariantId);
            filteredOferty.push(oferta)
        }
        filteredOferty = filteredOferty.filter(o => o.warianty.length > 0);

        if (!filteredOferty.length) {
            return <Text info>Nie złożono innych ofert{isMultiply ? ` do części: ${zamowienie.tytul}` : ''}</Text>
        }

        return (
            <>
                <Text tag="h3" accentHeader>Pozostałe oferty{isMultiply ? ` do części: "${zamowienie.tytul}"` : ''}</Text>
                <Text info>Liczba wyników: {filteredOferty.length}</Text>
                <GridCustom>
                    {filteredOferty.sort(this.sortOferty).map(o => this.renderOferta(o))}
                </GridCustom>
            </>
        )
    }

    renderZalacznikiRozstrzygniecia() {
        const zalaczniki = this.state.rozstrzygniecie.zalaczniki;
        return (
            <Container>
                <Text tag="h2" sectionHeader>Załączniki</Text>
                {zalaczniki.length > 0 && <Text info>Liczba wyników: {zalaczniki.length}</Text>}
                <ZalacznikiSelect
                    label={{attrName: 'jestProtokolem', description: 'Protokół'}}
                    zalaczniki={zalaczniki} />
            </Container>
        )
    }

    renderKomentarz() {
        if (!(this.state.rozstrzygniecie.komentarz || '').length) { return null }
        return (
            <Container>
                <Text tag="h2" mainHeader>Komentarz</Text>
                <Text className="long-text">{this.state.rozstrzygniecie.komentarz}</Text>
            </Container>
        )
    }
}


export { OfertyOwnerResolvedStatus };
