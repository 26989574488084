import React, { createContext } from 'react';

import { getLastTemporaryIdFromList, listOrEmpty } from '../helpers';
import {
    getSerializedList,
    serializeOrderItemCategories
} from '../serializers';
import {
    advertisementValidators,
    publishedAdvertisementAdminValidators,
    publishedAdvertisementOwnerValidators,
    validateHarmonogramAttributes,
    validateKryteriumOcenyAttributes,
    validateAdvertisementBaseAttributes,
    validatePrzedmiotZamowieniaBaseAttributes,
    validateWarunekUdzialuAttributes,
    validateZalacznikAttributes,
    validateZamowienieAttributes
} from '../validators';


const CLEANED_STEP_ERRORS = {
    'przedmiot': null,
    'terminy': null,
    'warunki': null,
    'czesciKryteria': null,
    'pozostale': null,
    'zalaczniki': null,
};

const CLEANED_FORM_ERRORS = {
    ogloszenie: {},
    zamowienia: {},
    kryteriaOceny: {},
    przedmiotyZamowienia: {},
    harmonogramy: {},
    warunkiUdzialu: {},
    zalaczniki: {},
};


const AdvertisementContext = createContext({
    // base variables
    ogloszenie: null,
    validationOn: false,
    savingOn: false,
    // errors
    stepErrors: Object.assign({}, CLEANED_STEP_ERRORS),
    formErrors: Object.assign({}, CLEANED_FORM_ERRORS),
    // const from server
    roboczeStatus: null,
    statusyOgloszenia: {},
    zagranicaId: null,
    typMiejscaRealizacji: {},
    przedmiotZamowieniaKategoria: [],   // the whole list
    przedmiotZamowieniaKategoriaDict: {},   // dict only with types
    przedmiotZamowieniaPodkategoriaDict: {},  // dict only with subcategories
    typWarunku: {},
    projekty: [],
    nabory: [],
    osobyDoKontaktu: [],
    teryt: [],
    // functions
    setConstsFromServer: () => {},
    setConstsFromAdvertisementData: () => {},
    setOgloszenie: () => {},
    saveOgloszenie: () => {},
    updateOgloszenieBeforePublishing: () => {},
    savePrzedmiotZamowienia: () => {},
    removePrzedmiotZamowienia: () => {},
    setAttributeValue: () => {},
    savePrzedmiotZamowieniaListAttributeValue: () => {},
    removePrzedmiotZamowieniaListAttributeValue: () => {},
    saveHarmonogram: () => {},
    removeHarmonogram: () => {},
    saveWarunekUdzialu: () => {},
    removeWarunekUdzialu: () => {},
    addZamowienie: () => {},
    removeZamowienie: () => {},
    movePrzedmiotZamowienia: () => {},
    setZamowienieAttributeValue: () => {},
    saveKryteriumOceny: () => {},
    removeKryteriumOceny: () => {},
    saveZamowienieListAttributeValue: () => {},
    removeZamowienieListAttributeValue: () => {},
    changeProjectsSelected: () => {},
    addZalacznik: () => {},
    removeZalacznik: () => {},
    setZalacznikAttributes: () => {},
    changeFormLockedState: () => {},
    validate:
        (projectsSelected, isAlreadyPublished, advertisementManagerType) => {},
    clearStepError: () => {},
    clearPrzedmiotZamowieniaErrors: () => {},
    clearAttributeError: () => {},
    clearFormErrors: () => {},
    clearState: () => {},
});


export class AdvertisementProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // base variables
            ogloszenie: null,
            validationOn: false,
            savingOn: false,
            // errors
            stepErrors: Object.assign({}, CLEANED_STEP_ERRORS),
            formErrors: Object.assign({}, CLEANED_FORM_ERRORS),
            // const from server
            roboczeStatus: null,
            statusyOgloszenia: {},
            adresId: null,
            zagranicaId: null,
            typMiejscaRealizacji: {},
            przedmiotZamowieniaKategoria: [],   // the whole list
            przedmiotZamowieniaKategoriaDict: {},   // dict only with types
            przedmiotZamowieniaPodkategoriaDict: {},  // dict only with subcategories
            typWarunku: {},
            projekty: [],
            nabory: [],
            osobyDoKontaktu: [],
            teryt: [],
            // functions
            setConstsFromServer: this.setConstsFromServer,
            setConstsFromAdvertisementData: this.setConstsFromAdvertisementData,
            setOgloszenie: this.setOgloszenie,
            saveOgloszenie: this.saveOgloszenie,
            updateOgloszenieBeforePublishing: this.updateOgloszenieBeforePublishing,
            savePrzedmiotZamowienia: this.savePrzedmiotZamowienia,
            removePrzedmiotZamowienia: this.removePrzedmiotZamowienia,
            movePrzedmiotZamowienia: this.movePrzedmiotZamowienia,
            setAttributeValue: this.setAttributeValue,
            savePrzedmiotZamowieniaListAttributeValue: this.savePrzedmiotZamowieniaListAttributeValue,
            removePrzedmiotZamowieniaListAttributeValue: this.removePrzedmiotZamowieniaListAttributeValue,
            saveHarmonogram: this.saveHarmonogram,
            removeHarmonogram: this.removeHarmonogram,
            saveWarunekUdzialu: this.saveWarunekUdzialu,
            removeWarunekUdzialu: this.removeWarunekUdzialu,
            addZamowienie: this.addZamowienie,
            removeZamowienie: this.removeZamowienie,
            setZamowienieAttributeValue: this.setZamowienieAttributeValue,
            saveKryteriumOceny: this.saveKryteriumOceny,
            removeKryteriumOceny: this.removeKryteriumOceny,
            saveZamowienieListAttributeValue: this.saveZamowienieListAttributeValue,
            removeZamowienieListAttributeValue: this.removeZamowienieListAttributeValue,
            changeProjectsSelected: this.changeProjectsSelected,
            addZalacznik: this.addZalacznik,
            removeZalacznik: this.removeZalacznik,
            setZalacznikAttributes: this.setZalacznikAttributes,
            changeFormLockedState: this.changeFormLockedState,
            validate: this.validate,
            // clean errors
            clearStepError: this.clearStepError,
            clearPrzedmiotZamowieniaErrors: this.clearPrzedmiotZamowieniaErrors,
            clearAttributeError: this.clearAttributeError,
            clearFormErrors: this.clearFormErrors,
            clearState: this.clearState,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.ogloszenie !== null && window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }

    setConstsFromServer = (data, callback) => {
        const [
            przedmiotZamowieniaKategoriaDict,
            przedmiotZamowieniaPodkategoriaDict
        ] = serializeOrderItemCategories(data.order_item_categories);
        this.setState(
            {
                roboczeStatus: data.announcement_status_draft,
                statusyOgloszenia: data.announcement_statuses,
                adresId: data.address_id,
                zagranicaId: data.abroad_id,
                typMiejscaRealizacji: data.order_fulfillment_place_types,
                przedmiotZamowieniaKategoria: getSerializedList(data.order_item_categories),
                przedmiotZamowieniaKategoriaDict: przedmiotZamowieniaKategoriaDict,
                przedmiotZamowieniaPodkategoriaDict: przedmiotZamowieniaPodkategoriaDict,
                typWarunku: data.participation_condition_types,
                projekty: getSerializedList(data.projects),
                nabory: getSerializedList(data.enrollment_numbers),
                osobyDoKontaktu: getSerializedList(data.contact_persons),
                teryt: data.teryt_data,
            },
            callback
        );
    }

    setConstsFromAdvertisementData = (data, callback) => {
        this.setState(
            {
                projekty: getSerializedList(data.projects || []),
                nabory: getSerializedList(data.enrollment_numbers || []),
                osobyDoKontaktu: getSerializedList(data.contact_persons || []),
            },
            callback
        );
    }

    setOgloszenie = (data, callback=null) => {
        let ogloszenie;
        if (data === null) {
            ogloszenie = {
                ogloszenie: {
                    id: null,
                    status: {},
                    numer: null,
                    dataOpublikowania : '',
                },
                id: null,
                status: {id: this.state.roboczeStatus},
                projekty: [],
                tytul: '',
                zamowieniaUzupelniajace: '',
                nabor: null,
                warunkiZmianyUmowy: '',
                czyDopuszczalnaOfertaCzesciowa: false,
                dataOpublikowania: '',
                terminOfert: '',
                planowanyTerminPodpisaniaUmowy: '',
                zamowienia: [],
                osobyDoKontaktu: [],
                zalaczniki: [],
            }
        } else {
            ogloszenie = data;
        }
        this.setState(
            {ogloszenie},
            () => {if (callback) { callback() }}
        );
    }

    saveOgloszenie = (data, callback) => {
        if (data.ogloszenie) {
            this.setState(
                prevState => ({
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie,
                        {
                            id: data.id,
                            ogloszenie: Object.assign(
                                {}, prevState.ogloszenie.ogloszenie, data.ogloszenie),
                        }
                    ),
                }),
                callback
            );
        }
    }

    updateOgloszenieBeforePublishing = (callback) => {
        this.setState(
            prevState => ({
                ogloszenie: Object.assign(
                    {}, prevState.ogloszenie, {
                        dataOpublikowania: '',
                        terminOfertData: '',
                        terminOfertWithTime: false,
                        terminOfertGodzina: '',
                    }
                )
            }),
            callback
        );
    }

    savePrzedmiotZamowienia = (data, isNew, callback) => {
        this.setState(
            (prevState) => {
                const ogloszenie = prevState.ogloszenie;
                let zamowienia = [...ogloszenie.zamowienia];

                if (isNew) {
                    if (!zamowienia.length) {
                        zamowienia.push({
                            id: null,
                            temporaryId: 1,
                            zamowienieNode: null,
                            przedmiotyZamowienia: [],
                            czyWariantowe: false,
                            kryteriaOceny: [],
                            szacunkowaWartosc: '',
                            tytul: '',
                        });
                    }

                    data['harmonogramy'] = [];
                    data['warunkiUdzialu'] = [];
                    zamowienia[0].przedmiotyZamowienia.push(data);
                } else {
                    zamowienia = zamowienia.map(z => {
                        let przedmiotyZamowienia = [];
                        for (let pz of z.przedmiotyZamowienia) {
                            if (pz.temporaryId === data.temporaryId) {
                                przedmiotyZamowienia.push(Object.assign({}, pz, data));
                            } else {
                                przedmiotyZamowienia.push(pz);
                            }
                        }
                        z.przedmiotyZamowienia = przedmiotyZamowienia;
                        return z
                    })
                }
                return {
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie, {zamowienia}),
                }
            },
            () => callback()
        );
    }

    removePrzedmiotZamowienia = (temporaryId) => {
        this.setState(
            (prevState) => {
                return {
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie,
                        {zamowienia: prevState.ogloszenie.zamowienia.map(z => {
                            z.przedmiotyZamowienia = z.przedmiotyZamowienia.filter(
                                pz => pz.temporaryId !== temporaryId);
                            return z
                        })}
                    ),
                }
            }
        );
    }

    movePrzedmiotZamowienia = (newZamowienieTemporaryId, temporaryId, callback) => {
        this.setState(
            (prevState) => {
                const zamowienia = prevState.ogloszenie.zamowienia;
                // get przedmiotZamowienia
                let pz_ = null;
                for (let z of zamowienia) {
                    for (let pz of z.przedmiotyZamowienia) {
                        if (pz.temporaryId === temporaryId) {
                            pz_ = pz;
                            break
                        }
                    }
                }

                if (pz_ !== null) {
                    // remove from old zamowienie, add to new zamowienie
                    let zamowienia_ = [];
                    for (let z of zamowienia) {
                        // remove
                        z.przedmiotyZamowienia = z.przedmiotyZamowienia.filter(
                            pz => pz.temporaryId !== temporaryId);
                        // add
                        if (z.temporaryId === newZamowienieTemporaryId) {
                            z.przedmiotyZamowienia = [...z.przedmiotyZamowienia, pz_];
                        }
                        zamowienia_.push(z);
                    }
                    return {
                        ogloszenie: Object.assign(
                            {}, prevState.ogloszenie, {zamowienia: zamowienia_}),
                    }
                } else { return {} }

            },
            callback
        );
    }

    setAttributeValue = (attributeName, value) => {
        this.setState(prevState => ({
            ogloszenie: Object.assign({}, prevState.ogloszenie, {[attributeName]: value})
        }));
    }

    savePrzedmiotZamowieniaListAttributeValue = (data, isNew, attrName, callback) => {
        const przedmiotZamowieniaTemporaryId = data['przedmiotZamowieniaTemporaryId'];
        delete data['przedmiotZamowieniaTemporaryId'];
        this.setState(
            prevState => {
                return {
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie,
                        {zamowienia: prevState.ogloszenie.zamowienia.map(z => {
                            let przedmiotyZamowienia = [];
                            for (let pz of z.przedmiotyZamowienia) {
                                if (pz.temporaryId === przedmiotZamowieniaTemporaryId) {
                                    if (isNew) {
                                        pz[attrName] = [...pz[attrName], data];
                                    } else {
                                        let objects = [];
                                        for (let o of pz[attrName]) {
                                            if (o.temporaryId === data.temporaryId) {
                                                o = data;
                                            }
                                            objects.push(o);
                                        }
                                        pz[attrName] = objects;
                                    }
                                }
                                przedmiotyZamowienia.push(pz);
                            }
                            z.przedmiotyZamowienia = przedmiotyZamowienia;
                            return z
                        })}
                    ),
                }
            },
            callback
        )
    }

    removePrzedmiotZamowieniaListAttributeValue = (przedmiotZamowieniaTemporaryId, temporaryId, attrName) => {
        this.setState(
            prevState => {
                return {
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie,
                        {zamowienia: prevState.ogloszenie.zamowienia.map(z => {
                            let przedmiotyZamowienia = [];
                            for (let pz of z.przedmiotyZamowienia) {
                                if (pz.temporaryId === przedmiotZamowieniaTemporaryId) {
                                    pz[attrName] = pz[attrName].filter(o => o.temporaryId !== temporaryId);
                                }
                                przedmiotyZamowienia.push(pz);
                            }
                            z.przedmiotyZamowienia = przedmiotyZamowienia;
                            return z
                        })}
                    ),
                }
            }
        );
    }

    saveHarmonogram = (data, isNew, callback) => {
        return this.savePrzedmiotZamowieniaListAttributeValue(
            data, isNew, 'harmonogramy', callback);
    }

    removeHarmonogram = (przedmiotZamowieniaTemporaryId, temporaryId) => {
        this.removePrzedmiotZamowieniaListAttributeValue(
            przedmiotZamowieniaTemporaryId, temporaryId, 'harmonogramy');
    }

    saveWarunekUdzialu = (data, isNew, callback) => {
        return this.savePrzedmiotZamowieniaListAttributeValue(
            data, isNew, 'warunkiUdzialu', callback);
    }

    removeWarunekUdzialu = (przedmiotZamowieniaTemporaryId, temporaryId) => {
        this.removePrzedmiotZamowieniaListAttributeValue(
            przedmiotZamowieniaTemporaryId, temporaryId, 'warunkiUdzialu');
    }

    addZamowienie = () => {
        // don't add zamowienie if oferta czesciowa is unavailable
        if (!this.state.ogloszenie.czyDopuszczalnaOfertaCzesciowa) { return }
        this.setState(prevState => ({
            ogloszenie: Object.assign(
                {}, prevState.ogloszenie,
                {zamowienia: [...prevState.ogloszenie.zamowienia, {
                    id: null,
                    temporaryId: Math.max(...listOrEmpty(prevState.ogloszenie.zamowienia).map(z => z.temporaryId)) + 1,
                    tytul: '',
                    czyWariantowe: false,
                    przedmiotyZamowienia: [],
                    szacunkowaWartosc: '',
                    kryteriaOceny: [],
                }]}
            ),
        }));
    }

    removeZamowienie = (temporaryId) => {
        this.setState(prevState => ({
            ogloszenie: Object.assign(
                {}, prevState.ogloszenie,
                {zamowienia: prevState.ogloszenie.zamowienia.filter(z => z.temporaryId !== temporaryId)}
            ),
        }));
    }

    setZamowienieAttributeValue = (temporaryId, attrName, value) => {
        this.setState(prevState => ({
            ogloszenie: Object.assign(
                {}, prevState.ogloszenie,
                {zamowienia: prevState.ogloszenie.zamowienia.map(z => {
                    if (z.temporaryId === temporaryId) {
                        z[attrName] = value;
                    }
                    return z
                })}
            ),
        }));
    }

    saveZamowienieListAttributeValue = (data, isNew, attrName, callback=null) => {
        const zamowienieTemporaryId = data['zamowienieTemporaryId'];
        delete data['zamowienieTemporaryId'];
        this.setState(
            prevState => {
                return {
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie,
                        {zamowienia: prevState.ogloszenie.zamowienia.map(z => {
                            if (z.temporaryId === zamowienieTemporaryId) {
                                if (isNew) {
                                    z[attrName] = [...z[attrName], data];
                                } else {
                                    let objects = [];
                                    for (let o of z[attrName]) {
                                        if (o.temporaryId === data.temporaryId) {
                                            o = data;
                                        }
                                        objects.push(o);
                                    }
                                    z[attrName] = objects;
                                }
                            }
                            return z
                        })}
                    ),
                }
            },
            () => {if (callback) {callback()}}
        )
    }

    removeZamowienieListAttributeValue = (zamowienieTemporaryId, temporaryId, attrName) => {
        this.setState(
            prevState => ({
                ogloszenie: Object.assign(
                    {}, prevState.ogloszenie,
                    {zamowienia: prevState.ogloszenie.zamowienia.map(z => {
                        if (z.temporaryId === zamowienieTemporaryId) {
                            z[attrName] = z[attrName].filter(o => o.temporaryId !== temporaryId);
                        }
                        return z
                    })}
                ),
            })
        );
    }

    saveKryteriumOceny = (data, isNew, callback) => {
        this.saveZamowienieListAttributeValue(
            data, isNew, 'kryteriaOceny', callback);
    }

    removeKryteriumOceny = (zamowienieTemporaryId, temporaryId) => {
        this.removeZamowienieListAttributeValue(
            zamowienieTemporaryId, temporaryId, 'kryteriaOceny');
    }

    changeProjectsSelected = (checked) => {
        this.setState(
            prevState => ({
                ogloszenie: {
                    ...prevState.ogloszenie,
                    ...(checked ? {nabor: null} : {projekty: []})
                }
            })
        );
    }

    addZalacznik = (data) => {
        data = data || {};
        this.setState(
            prevState => {
                const zalaczniki = prevState.ogloszenie.zalaczniki;
                return {
                    ogloszenie: Object.assign(
                        {}, prevState.ogloszenie,
                        {zalaczniki: [...zalaczniki, {
                            id: null,
                            temporaryId: getLastTemporaryIdFromList(zalaczniki) + 1,
                            nazwa: '',
                            plik: {
                                id: null,
                                uri: '',
                                nazwa: '',
                                dataUtworzenia: '',
                                ktoUtworzyl: {imie: '', nazwisko: ''},
                            },
                            plikDoWgrania: data.plikDoWgrania || null,
                            origin: {
                                id: prevState.ogloszenie.status.label === 'DRAFT' ? prevState.ogloszenie.id : null,
                            }
                        }]}
                    ),
                }
            }
        );
    }

    removeZalacznik = (temporaryId) => {
        this.setState(
            prevState => {
                const o = prevState.ogloszenie;
                return {ogloszenie: Object.assign(
                    {}, o, {zalaczniki: o.zalaczniki.filter(z => z.temporaryId !== temporaryId)}
                )}
            }
        );
    }

    setZalacznikAttributes = (temporaryId, data) => {
        this.setState(
            prevState => {
                let zalaczniki = [];
                for (let z of prevState.ogloszenie.zalaczniki) {
                    if (z.temporaryId === temporaryId) {
                        z = Object.assign(z, data);
                    }
                    zalaczniki.push(z);
                }
                return {ogloszenie: Object.assign({}, prevState.ogloszenie, {zalaczniki})}
            }
        );
    }

    changeFormLockedState = (attrName, value, callback=null) => {
        this.setState(
            {[attrName]: value},
            () => {
                if (callback) {callback()}
            }
        );
    }

    validate = (projectsSelected, isAlreadyPublished, advertisementManagerType) => {
        const { ogloszenie } = this.state;
        let [errors, stepErrors] = validateAdvertisementBaseAttributes(
            ogloszenie,
            projectsSelected,
            isAlreadyPublished
                ? (advertisementManagerType.isAuthor
                    ? publishedAdvertisementOwnerValidators
                    : publishedAdvertisementAdminValidators
                )
                : advertisementValidators
        );

        let zamowieniaErrors = {};
        let zamowieniaStepErrors = {};
        let kryteriaOcenyErrors = {};
        let kryteriaOcenyStepErrors = {};
        if (!isAlreadyPublished) {
            // do not validate zamowienia and kryteriaOceny
            [zamowieniaErrors, zamowieniaStepErrors] = validateZamowienieAttributes(
                ogloszenie, isAlreadyPublished);

            let kryteriaOceny = [];
            for (let z of ogloszenie.zamowienia) {
                kryteriaOceny = [...kryteriaOceny, ...z.kryteriaOceny];
            }
            [kryteriaOcenyErrors, kryteriaOcenyStepErrors] = validateKryteriumOcenyAttributes(kryteriaOceny);
        }

        let przedmiotyZamowienia = [];
        for (let z of ogloszenie.zamowienia) {
            przedmiotyZamowienia = [...przedmiotyZamowienia, ...z.przedmiotyZamowienia];
        }
        const [przedmiotyZamowieniaErrors, przedmiotyZamowieniaStepErrors] = validatePrzedmiotZamowieniaBaseAttributes(
            przedmiotyZamowienia, isAlreadyPublished);

        let harmonogramy = [];
        for (let pz of przedmiotyZamowienia) {
            harmonogramy = [...harmonogramy, ...pz.harmonogramy];
        }
        const [harmonogramyErrors, harmonogramyStepErrors] = validateHarmonogramAttributes(harmonogramy);

        let warunkiUdzialuErrors = {};
        let warunkiUdzialuStepErrors = {};
        if (!isAlreadyPublished) {
            // do not validate warunkiUdzialu
            let warunkiUdzialu = [];
            for (let pz of przedmiotyZamowienia) {
                warunkiUdzialu = [...warunkiUdzialu, ...pz.warunkiUdzialu];
            }
            [warunkiUdzialuErrors, warunkiUdzialuStepErrors] = validateWarunekUdzialuAttributes(warunkiUdzialu);
        }

        let zalacznikiErrors = {};
        let zalacznikiStepErrors = {};
        [zalacznikiErrors, zalacznikiStepErrors] = validateZalacznikAttributes(ogloszenie.zalaczniki);

        const finalStepErrors = Object.assign(
            {
                'przedmiot': false,
                'terminy': false,
                'warunki': false,
                'czesciKryteria': false,
                'pozostale': false,
                'zalaczniki': false,
            }, stepErrors, zamowieniaStepErrors, kryteriaOcenyStepErrors,
            przedmiotyZamowieniaStepErrors, harmonogramyStepErrors,
            warunkiUdzialuStepErrors, zalacznikiStepErrors
        );
        this.setState({
            stepErrors: finalStepErrors,
            formErrors: {
                ogloszenie: errors,
                zamowienia: zamowieniaErrors,
                kryteriaOceny: kryteriaOcenyErrors,
                przedmiotyZamowienia: przedmiotyZamowieniaErrors,
                harmonogramy: harmonogramyErrors,
                warunkiUdzialu: warunkiUdzialuErrors,
                zalaczniki: zalacznikiErrors,
            },
        });

        // return if is valid
        return Object.values(finalStepErrors).filter(e => e === true).length === 0
    }

    clearStepError = (stepName) => {
        this.setState((prevState) => {
            let errors = Object.assign({}, prevState.stepErrors);
            errors[stepName] = null;
            return {stepErrors: errors}
        })
    }

    clearPrzedmiotZamowieniaErrors = (temporaryId, callback) => {
        if (this.state.formErrors.przedmiotyZamowienia[temporaryId]) {
            this.setState(
                prevState => {
                    let errors = Object.assign({}, prevState.formErrors.przedmiotyZamowienia);
                    delete errors[temporaryId];
                    return {formErrors: Object.assign({}, prevState.formErrors, {przedmiotZamowienia: errors})}
                },
                () => callback()
            )
        } else {
            callback();
        }
    }

    clearAttributeError = (errorName, temporaryId, attrName) => {
        if (this.state.formErrors[errorName][temporaryId] && this.state.formErrors[errorName][temporaryId][attrName]) {
            this.setState((prevState) => {
                let errors = Object.assign({}, prevState.formErrors[errorName]);
                delete errors[temporaryId][attrName];
                return {formErrors: Object.assign({}, prevState.formErrors, {[errorName]: errors})}
            });
        }
    }

    clearFormErrors = () => {
        this.setState({
            stepErrors: CLEANED_STEP_ERRORS,
            formErrors: CLEANED_FORM_ERRORS
        });
    }

    clearState = (callback=null) => {
        this.setState(
            {
                // base variables
                ogloszenie: null,
                validationOn: false,
                savingOn: false,
                // errors
                stepErrors: Object.assign({}, CLEANED_STEP_ERRORS),
                formErrors: Object.assign({}, CLEANED_FORM_ERRORS),
                // const from server
                roboczeStatus: null,
                statusyOgloszenia: {},
                adresId: null,
                zagranicaId: null,
                typMiejscaRealizacji: {},
                przedmiotZamowieniaKategoria: [],   // the whole list
                przedmiotZamowieniaKategoriaDict: {},   // dict only with types
                przedmiotZamowieniaPodkategoriaDict: {},  // dict only with subcategories
                typWarunku: {},
                projekty: [],
                nabory: [],
                osobyDoKontaktu: [],
                teryt: [],
            },
            () => {
                if (callback) { callback() }
            }
        );
    }

    render() {
        return (
            <AdvertisementContext.Provider value={this.state}>
                {this.props.children}
            </AdvertisementContext.Provider>
        );
    }
}


export const AdvertisementConsumer = AdvertisementContext.Consumer;
export { AdvertisementContext };
