import React from 'react';

import { ButtonStyled, DialogCustom, GridCustom, Message } from '../../common';


const SessionDialog = (props) => {

    const callback = props.callback;
    const messageIcon = props.messageIcon || 'power_settings_new';
    return (
        <DialogCustom
            ariaLabelledby="sessionDialogLabel"
            className="dialog dialog--sesion"
            onClose={callback}
        >
            <>
                <Message
                    error={props.error}
                    id="sessionDialogLabel"
                    messageIcon={messageIcon}
                    messageTitle={props.messageTitle}
                    role={props.messageRole}
                >
                    {props.messageContent}
                </Message>
                {props.withButton && (
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled onClick={callback} primary>{props.dialogButton || 'OK'}</ButtonStyled>
                    </GridCustom>
                )}
            </>
        </DialogCustom>
    );
}


export { SessionDialog };
