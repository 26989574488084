import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import { isAdvertisementLocked, getUserFullName } from '../../../helpers';
import {useOneTimeFetchApi, usePutFetchApi} from '../../../hooks';
import { getSerializedObject } from '../../../serializers';
import { URLS } from '../../../urls/frontend';
import {
    ButtonStyled,
    CancelSaveButtonsRow,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';


export const ManageAdvertisementButtons = ({
    advertisement, onChangeAdvertisementStatus
}) => {

    let { data, loadingOn: initializationOn } = useOneTimeFetchApi({
        url: `/api/admin/permissions_ads_projects_enrollments/ad/${advertisement.ogloszenie.id}`,
    });

    const isLocked = isAdvertisementLocked(advertisement.ogloszenie.status);
    let history = useHistory();
    const [confirmDialogOn, setConfirmDialogOn] = useState(false);
    const [loadingOn, setLoadingOn] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    function handleFetchSuccess(data) {
        setConfirmDialogOn(false);
        onChangeAdvertisementStatus(
            getSerializedObject(data).ogloszenie.status)
    }

    usePutFetchApi({
        url: `/api/admin/announcements/${advertisement.ogloszenie.id}/${isLocked ? 'un' : ''}lock`,
        callbackPutSuccess: handleFetchSuccess,
        loadingOn,
        setLoadingOn,
        setFetchError,
        message403error:
            `Nie masz uprawnień do ${isLocked ? 'odblokowania' : 'blokowania'}
             tego ogłoszenia.`,
    });

    const { permissions: userBasicPermission } = useContext(UserContext);

    if (initializationOn || !data) { return null}

    function hasPermission(parentName, name) {
        return (data.permission[parentName] || {})[name] || false
    }

    // what's now? If there is no base permission, return null
    if (!hasPermission('advertisementAdministration', 'searchNPreviewData')) {
        return null
    }

    function handleOpenConfirmDialog() {
        setFetchError(null);
        setConfirmDialogOn(true);
    }

    function handleCloseConfirmDialog() {
        setConfirmDialogOn(false);
    }

    function handleLock() {
        setLoadingOn(true);
    }

    function handleGoToAdvertisementPage() {
        history.push(
            URLS.advertisementsEdit.getUrl(advertisement.ogloszenie.id));
    }

    const {ktoUtworzyl: advertiser = {}} = advertisement.ogloszenie;

    function handleGoToManageUserPage() {
        history.push(URLS.usersDetails.getUrl(advertiser.id));
    }

    function handleFilterProjectsByUserEmail() {
        history.push(
            `${URLS.managementProjects.path}?email=${advertiser.email}&sort=type&strona=1`);
    }

    function handleFilterAdvertisementsByUserEmail() {
        const ADVERTISEMENTS_GET_COMMON_URL_PARAMS =
            '&sort=announcementNumber_desc&statusOgl=all&strona=1&typAdresu=all' +
            '&typNumeruIdent=all';
        history.push(
            `${URLS.managementAdvertisements.path}?email=${advertiser.email}${ADVERTISEMENTS_GET_COMMON_URL_PARAMS}`);
    }

    const isDraft = advertisement.ogloszenie.status.label === 'DRAFT';
    return (
        <>
            <GridCustom fullwidth flexM className="manage-lock">
                <FieldWithLabel label="Ogłoszenie">
                    <Text>
                        {isLocked || isDraft
                            ? 'Widoczne tylko dla użytkowników posiadających uprawnienia do podglądu.'
                            : 'Widoczne dla wszystkich.'
                        }
                    </Text>
                    {!isDraft && (
                        <>
                            {hasPermission('advertisementAdministration', 'editData') && (
                                <ButtonStyled
                                    disabled={advertisement.ogloszenie.status.label !== 'PUBLISHED'}
                                    icon="edit"
                                    lite
                                    onClick={handleGoToAdvertisementPage}
                                >
                                    Edytuj
                                </ButtonStyled>
                            )}

                            {hasPermission('advertisementAdministration', 'blocking') && (
                                <ButtonStyled
                                    lite
                                    icon={isLocked ? 'check_circle' : 'cancel'}
                                    onClick={handleOpenConfirmDialog}
                                    primary
                                >
                                    {isLocked ? 'Odblokuj' : 'Zablokuj'}
                                </ButtonStyled>
                            )}
                        </>
                    )}
                </FieldWithLabel>
                <GridCustom fullwidth flexL className="manage-lock__user">
                    <FieldWithLabel label="Użytkownik">
                        <Text>{getUserFullName(advertiser)}</Text>
                        <Text className="manage-lock__user-data manage-lock__user-data--email">{advertiser.email}</Text>
                        <Text className="manage-lock__user-data">ID {advertiser.identyfikator}</Text>
                    </FieldWithLabel>
                    <div className="manage-lock__buttons">
                        {userBasicPermission.usersAndPermissionsPreviewListUsers && (
                            <ButtonStyled
                                lite
                                icon="person"
                                onClick={handleGoToManageUserPage}
                            >
                                Zarządzaj użytkownikiem
                            </ButtonStyled>
                        )}
                        {hasPermission('projectsEnrollmentsAdministration', 'searchNPreviewData') && (
                            <ButtonStyled
                                lite
                                icon="line_weight"
                                onClick={handleFilterProjectsByUserEmail}
                            >
                                Lista projektów
                            </ButtonStyled>
                        )}
                        <ButtonStyled
                            lite
                            icon="description"
                            onClick={handleFilterAdvertisementsByUserEmail}
                        >
                            Lista ogłoszeń
                        </ButtonStyled>
                    </div>
                </GridCustom>
            </GridCustom>
            {confirmDialogOn && (
                <DialogCustom
                    dialogTitle={`Czy na pewno chcesz ${isLocked ? 'odblokować' : 'zablokować'} to ogłoszenie?`}
                    onClose={handleCloseConfirmDialog}
                >
                    <CancelSaveButtonsRow
                        disabled={loadingOn}
                        cancelText="Nie"
                        saveText="Tak"
                        onCancel={handleCloseConfirmDialog}
                        onSave={handleLock} />
                    {fetchError !== null && <Text error>{fetchError}</Text>}
                </DialogCustom>
            )}
        </>
    )
};
