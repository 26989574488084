// Notice! Remember to set double '\' in regex if needed, e.g. (\\d+),
// otherwise regex won't work
export const URLS = {
    home: {
        path: '/',
        pattern: /^\/$/
    },
    login: {
        path: '/logowanie',
        pattern: /^\/logowanie$/
    },
    loginError: {
        path: '/blad-logowania',
        pattern: /^\/blad-logowania$/
    },
    projects: {
        path: '/projekty',
        pattern: /^\/projekty$/,
    },
    advertisements: {
        path: '/ogloszenia',
        pattern: /^\/ogloszenia$/,
    },
    advertisementsSearch: {
        path: '/ogloszenia/szukaj',
        pattern: /^\/ogloszenia\/szukaj$/,
    },
    advertisementsNew: {
        path: '/ogloszenia/nowe',
        pattern: /^\/ogloszenia\/nowe$/,
    },
    advertisementsDetails: {
        path: '/ogloszenia/:id(\\d+)',
        pattern: /^\/ogloszenia\/\d+$/,
        getUrl: (advertisementId) => `/ogloszenia/${advertisementId}`,
    },
    advertisementsDetailsDraft: {
        path: '/ogloszenia/:id(\\d+)/robocza',
        pattern: /^\/ogloszenia\/\d+\/robocza$/,
    },
    advertisementsDetailsVersion: {
        path: '/ogloszenia/:id(\\d+)/wersja:versionId(\\d+)',
        pattern: /^\/ogloszenia\/\d+\/wersja\d+$/,
    },
    advertisementsEdit: {
        path: '/ogloszenia/:id(\\d+)/edytuj',
        pattern: /^\/ogloszenia\/\d+\/edytuj$/,
        getUrl: (advertisementId) => `/ogloszenia/${advertisementId}/edytuj`,
    },
    offers: {
        path: '/oferty',
        pattern: /^\/oferty$/,
    },
    newsletter: {
        path: '/newsletter',
        pattern: /^\/newsletter$/,
    },
    accessibility: {
        path: '/deklaracja-dostepnosci',
        pattern: /^\/deklaracja-dostepnosci$/,
    },
    users: {
        path: '/uzytkownicy',
        pattern: /^\/uzytkownicy$/,
    },
    usersDetails: {
        path: '/uzytkownicy/:id(\\d+)',
        pattern: /^\/uzytkownicy\/\d+$/,
        getUrl: (userId) => `/uzytkownicy/${userId}`
    },
    usersMyProfile: {
        path: '/uzytkownicy/moj-profil',
        pattern: /^\/uzytkownicy\/moj-profil$/,
    },
    managementProjects: {
        path: '/administracja/projekty',
        pattern: /^\/administracja\/projekty$/,
    },
    managementAdvertisements: {
        path: '/administracja/ogloszenia',
        pattern: /^\/administracja\/ogloszenia$/,
    },
    managementContents: {
        path: '/zarzadzanie-trescia',
        pattern: /^\/zarzadzanie-trescia$/,
    },
    managementContentsHelps: {
        path: '/zarzadzanie-trescia/pomoc',
        pattern: /^\/zarzadzanie-trescia\/pomoc$/,
    },
    managementContentsHelpsNew: {
        path: '/zarzadzanie-trescia/pomoc/nowa',
        pattern: /^\/zarzadzanie-trescia\/pomoc\/nowa$/,
    },
    managementContentsHelpsEdit: {
        path: '/zarzadzanie-trescia/pomoc/:id(\\d+)-:slug([\\w-]+)/edytuj',
        pattern: /^\/zarzadzanie-trescia\/pomoc\/\d+-[\w-]+\/edytuj$/,
    },
    managementContentsHelpsDetails: {
        path: '/zarzadzanie-trescia/pomoc/:id(\\d+)-:slug([\\w-]+)',
        pattern: /^\/zarzadzanie-trescia\/pomoc\/\d+-[\w-]+$/,
    },
    managementContentsRegulations: {
        path: '/zarzadzanie-trescia/regulaminy',
        pattern: /^\/zarzadzanie-trescia\/regulaminy$/,
    },
    managementContentsRegulationsCurrent: {
        path: '/zarzadzanie-trescia/regulaminy/aktualny',
        pattern: /^\/zarzadzanie-trescia\/regulaminy\/aktualny$/,
    },
    managementContentsRegulationsForPublishing: {
        path: '/zarzadzanie-trescia/regulaminy/do-opublikowania',
        pattern: /^\/zarzadzanie-trescia\/regulaminy\/do-opublikowania$/,
    },
    managementContentsRegulationsNew: {
        path: '/zarzadzanie-trescia/regulaminy/nowy',
        pattern: /^\/zarzadzanie-trescia\/regulaminy\/nowy$/,
    },
    managementContentsRegulationsEdit: {
        path: '/zarzadzanie-trescia/regulaminy/edytuj',
        pattern: /^\/zarzadzanie-trescia\/regulaminy\/edytuj$/,
    },
    managementContentsCookies: {
        path: '/zarzadzanie-trescia/ciasteczka',
        pattern: /^\/zarzadzanie-trescia\/ciasteczka$/,
    },
    managementContentsCookiesNew: {
        path: '/zarzadzanie-trescia/ciasteczka/nowe',
        pattern: /^\/zarzadzanie-trescia\/ciasteczka\/nowe$/,
    },
    managementContentsCookiesEdit: {
        path: '/zarzadzanie-trescia/ciasteczka/edytuj',
        pattern: /^\/zarzadzanie-trescia\/ciasteczka\/edytuj$/,
    },
    managementContentsGeneralContents: {
        path: '/zarzadzanie-trescia/tresci-ogolne',
        pattern: /^\/zarzadzanie-trescia\/tresci-ogolne$/,
    },
    managementContentsGeneralContentsEdit: {
        path: '/zarzadzanie-trescia/tresci-ogolne/:id(\\d+)/edytuj',
        pattern: /^\/zarzadzanie-trescia\/tresci-ogolne\/\d+\/edytuj$/,
    },
    managementContentsGeneralContentsDetails: {
        path: '/zarzadzanie-trescia/tresci-ogolne/:id(\\d+)',
        pattern: /^\/zarzadzanie-trescia\/tresci-ogolne\/\d+$/
    },
    managementContentsStatements: {
        path:  '/zarzadzanie-trescia/komunikaty',
        pattern: /^\/zarzadzanie-trescia\/komunikaty$/,
    },
    managementContentsStatementsNew: {
        path: '/zarzadzanie-trescia/komunikaty/nowy',
        pattern: /^\/zarzadzanie-trescia\/komunikaty\/nowy$/,
    },
    managementContentsStatementsEdit: {
        path: '/zarzadzanie-trescia/komunikaty/:id(\\d+)-:slug([\\w-]+)/edytuj',
        pattern: /^\/zarzadzanie-trescia\/komunikaty\/\d+-[\w-]+\/edytuj$/,
    },
    managementContentsStatementsDetails: {
        path: '/zarzadzanie-trescia/komunikaty/:id(\\d+)-:slug([\\w-]+)',
        pattern: /^\/zarzadzanie-trescia\/komunikaty\/\d+-[\w-]+$/,
    },
    managementPerspectives: {
        path: '/administracja/perspektywy',
        pattern: /^\/administracja\/perspektywy$/,
    },
    managementProgrammesForPerspective: {
        path: '/administracja/perspektywy/:perspectiveId(\\d+)/programy',
        pattern: /^\/administracja\/perspektywy\/\d+\/programy$/,
        getUrl: (perspectiveId) => `/administracja/perspektywy/${perspectiveId}/programy`,
    },
    managementPrioritiesForProgramme: {
        path: '/administracja/programy/:programmeId(\\d+)/osie',
        pattern: /^\/administracja\/programy\/\d+\/osie$/,
        getUrl: (programmeId) =>
            `/administracja/programy/${programmeId}/osie`,
    },
    managementSubactivitiesForPriority: {
        path: '/administracja/osie/:priorityId(\\d+)/dzialania-i-poddzialania',
        pattern: /^\/administracja\/osie\/\d+\/dzialania-i-poddzialania$/,
        getUrl: (priorityId) =>
            `/administracja/osie/${priorityId}/dzialania-i-poddzialania`,
    },
    managementActivitiesForPriority: {
        path: '/administracja/osie/:priorityId(\\d+)/dzialania',
        pattern: /^\/administracja\/osie\/\d+\/dzialania$/,
        getUrl: (priorityId) => `/administracja/osie/${priorityId}/dzialania`,
    },
    managementRegionsForSubactivity: {
        path: '/administracja/dzialania-i-poddzialania/:activityId(\\d+)/regiony',
        pattern: /^\/dzialania-i-poddzialania\/osie\/\d+\/regiony$/,
        getUrl: (activityId) => `/administracja/dzialania-i-poddzialania/${activityId}/regiony`,
    },
    managementInstitutionsForActivity: {
        path: '/administracja/dzialania/:activityId(\\d+)/instytucje',
        pattern: /^\/administracja\/dzialania\/\d+\/instytucje$/,
        getUrl: (activityId) => `/administracja/dzialania/${activityId}/instytucje`,
    },
    helps: {
        path: '/pomoc',
        pattern: /^\/pomoc$/,
    },
    helpsDetails: {
        path: '/pomoc/:id(\\d+)-:slug([\\w-]+)',
        pattern: /^\/pomoc\/\d+-[\w-]+$/,
    },
    regulations: {
        path: '/regulamin',
        pattern: /^\/regulamin$/,
    },
    subscriptions: {
        path: '/subskrypcje',
        pattern: /^\/subskrypcje$/,
    },
    statements: {
        path: '/komunikaty',
        pattern: /^\/komunikaty$/,
    },
    statementsDetails: {
        path: '/komunikaty/:id(\\d+)-:slug([\\w-]+)',
        pattern: /^\/komunikaty\/\d+-[\w-]+$/,
    },
    // sativa
    sativaUsersDetails: {
        getUrl: (userIdentifier) => `/konto/panel/uzytkownicy/${userIdentifier}`
    },
};
