import React from 'react';
import { Checkbox } from '@rmwc/checkbox';

import {
    getLastTemporaryIdFromList,
    reformatDanePodmiotu,
} from '../../../helpers';
import {
    getSerializedObject,
    getSerializedObjectErrorsFormServer,
    translateErrorMessage
} from '../../../serializers';
import {
    Box,
    ButtonStyled,
    Container,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    GridCellCustom,
    MenuBasic,
    Steps,
    Text,
    TextFieldCustom,
} from '../../common';
import { FormBaseComponent, Zalaczniki } from '../common';
import { FormOfertaOgloszeniodawca, OfertaPreview } from '../oferty';
import { FormCzescRozstrzygniecia } from './FormCzescRozstrzygniecia';


const MAX_ATTACHMENTS_NUMBER = 20;


class FormRozstrzygniecie extends FormBaseComponent {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleSetCurrentMenuLink(0);

        if (this.props.isNew) {
            this.setState({initializingOn: false});
        } else {
            this.fetchInitialData();
        }
    }

    // handlers
    handleFetchSuccess(data) {
        this.xhrFetch = null;
        data = getSerializedObject(data, {addTemporaryId: true}).rozstrzygniecie;
        for (let z of data.zamowieniaNodes) {
            z.wybranyWariant = z.wybranyWariant.id || null;
        }
        this.setState(Object.assign(
            data,
            {initializingOn: false})
        );
    }

    handleSetCurrentMenuLink(id) {
        this.setState(prevState =>
            {
                const menuLinks = prevState.menuLinks;
                let next = null, prev=null, name=null;
                for (let i=0; i < menuLinks.length; i++) {
                    if (id === menuLinks[i].id) {
                        name = menuLinks[i].name;
                        if (i > 0) {
                            prev = {
                                label: menuLinks[i - 1].label,
                                id: menuLinks[i - 1].id,
                            };
                        }
                        if (i < menuLinks.length - 1) {
                            next = {
                                label: menuLinks[i + 1].label,
                                id: menuLinks[i + 1].id,
                            };
                        }
                    }
                }
                return {
                    currentMenuLink: {id, name, next, prev}
                }
            }
        );
    }

    handleCloseForm = () => {
        this.setState({offerFormOn: false, editingOffersetId: null});
    }

    handleSubmitOfertaForm = (data, isNew) => {
        data = getSerializedObject(data).pakietOfert;
        data.danePodmiotu = reformatDanePodmiotu(data.danePodmiotu);
        // set dict of orderNode and offer ids
        let idsDict = {};
        for (let o of data.oferty) {
            idsDict[o.zamowienieNode.id] = o.id;
        }
        this.setState(
            prevState => {
                if (isNew) {
                    return {
                        offerFormOn: false,
                        pakietyOfert: [...prevState.pakietyOfert, data],
                        zamowieniaNodes: prevState.zamowieniaNodes.map(z => {
                            if (idsDict[z.id]) {
                                const ofertaId = idsDict[z.id];
                                z.oferty = [...z.oferty, {id: ofertaId, temporaryId: ofertaId, isRefused: false}]
                            }
                            return z
                        }),
                    }
                } else {
                    return {
                        offerFormOn: false,
                        editingOffersetId: null,
                        pakietyOfert: prevState.pakietyOfert.map(po => {
                            if (po.id === data.id) {
                                return data
                            }
                            return po
                        }),
                    }
                }
            }
        );
    }

    handleCloseOfferPreview = () => {
        this.setState({offerPreviewOn: false, previewedOfferId: null});
    }

    handleAddZalacznik = (data) => {
        data = data || {};

        this.setState(prevState => ({
            zalaczniki: [...prevState.zalaczniki, {
                id: null,
                temporaryId: getLastTemporaryIdFromList(prevState.zalaczniki) + 1,
                jestProtokolem: false,
                nazwa: '',
                plik: {
                    id: null,
                    uri: '',
                    nazwa: '',
                    dataUtworzenia: '',
                    ktoUtworzyl: {imie: '', nazwisko: ''},
                },
                plikDoWgrania: data.plikDoWgrania || null,
            }],
        }))
    }

    handleSaveZalacznikAttributes = (temporaryId, data) => {
        this.setState(prevState => {
            return {zalaczniki: prevState.zalaczniki.map(z => {
                if (z.temporaryId === temporaryId) {
                    return Object.assign(z, data)
                } else {
                    return z
                }
            })}
        });
    }

    handleRemoveZalacznik = (temporaryId) => {
        this.setState(prevState => {
            return {zalaczniki: prevState.zalaczniki.filter(z => z.temporaryId !== temporaryId)}
        });
    }

    handleChangeZalacznikIsProtocol = (checked, temporaryId) => {
        this.setState(prevState => {
            let zalaczniki = [];
            for (let z of prevState.zalaczniki) {
                if (z.temporaryId === temporaryId) {
                    z.jestProtokolem = checked;
                } else {
                    if (checked) {
                        z.jestProtokolem = false;
                    }
                }
                zalaczniki.push(z);
            }
            return {zalaczniki: zalaczniki}
        });
    }

    handleNoOfferSelectedChange = (checked, zamowienieId) => {
        this.setState(prevState => {
            return {zamowieniaNodes: prevState.zamowieniaNodes.map(z => {
                if (z.id === zamowienieId) {
                    z.zadnaOfertaWybrana = checked;
                    if (checked) {
                        z.wybranyWariant = null;
                    }
                }
                return z
            })}
        });
    }

    handleRefuseOffer = (zamowienieId, ofertaId) => {
        this.setOfferRefuseValue(zamowienieId, ofertaId, true);
    }

    handleRestoreOffer = (zamowienieId, ofertaId) => {
        this.setOfferRefuseValue(zamowienieId, ofertaId, false);
    }

    handleSelectWariant = (checked, zamowienieNode, wariantId) => {
        this.setState(prevState => ({
            zamowieniaNodes: prevState.zamowieniaNodes.map(z => {
                if (z.id === zamowienieNode) {
                    if (checked) {
                        z.wybranyWariant = wariantId;
                        z.zadnaOfertaWybrana = false;
                    } else {
                        z.wybranyWariant = null;
                    }
                }
                return z
            })
        }));
    }

    handleRemoveOfertaZewnetrzna = (pakietOfertId) => {
        this.setState(prevState => {
            // get variants ids for offerset
            let variantsIds = [];
            for (let po of prevState.pakietyOfert) {
                if (po.id === pakietOfertId) {
                    for (let o of po.oferty) {
                        variantsIds = [...variantsIds, ...o.warianty.map(w => w.id)];
                    }
                    break
                }
            }
            return {
                ofertyDoUsuniecia: [...prevState.ofertyDoUsuniecia, pakietOfertId],
                zamowieniaNodes: prevState.zamowieniaNodes.map(z => {
                    if (variantsIds.includes(z.wybranyWariant)) {
                        z.wybranyWariant = null;
                    }
                    return z
                }),
            }
        });
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetchSave = null;
        window.location.reload();
    }

    handleShowSaveErrors = (errors) => {
        this.xhrFetchSave = null;
        const errors_ = getSerializedObjectErrorsFormServer(
            errors,
            this.getNotSerializedDataForSaving(),
            ['ofertyDoUsuniecia', 'zamowieniaNodes', 'oferty', 'zalaczniki']
        );
        this.setState(
            prevState => ({
                savingOn: false,
                saveBtnText: this.saveBtnDefaultText,
                errors: errors_,
                errorsDialogOn: Object.values(errors_.zamowieniaNodes).filter(
                    z => (z.zadnaOfertaWybrana || []).length > 0).length > 0,
            })
        );
    }

    // helpers

    preState(props) {
        // it has to be here, because 'getMenuLinks' function needs it
        this.handleSetCurrentMenuLink = this.handleSetCurrentMenuLink.bind(this);
    }

    formPreState(props) {
        this.saveBtnDefaultText = 'Publikuj rozstrzygnięcie';
    }

    getAdditionalState(props) {

        const getOfertyByZamowienieNodeId = (zamowienieNodeId) => {
            let oferty = [];
            for (let po of this.props.pakietyOfert) {
                for (let o of po.oferty) {
                    if (zamowienieNodeId === o.zamowienieNode.id) {
                        oferty.push({id: o.id, temporaryId: o.id, isRefused: false});
                    }
                }
            }
            return oferty
        }

        const zamowienia = this.props.ogloszenie.zamowienia;
        return {
            isNew: props.isNew,
            offerFormOn: false,
            editingOffersetId: null,
            offerPreviewOn: false,
            previewedOfferId: null,
            errorsDialogOn: false,
            menuLinks: this.getMenuLinks(zamowienia),
            currentMenuLink: {
                id: null,
                name: null,
                next: null,
                prev: null,
            },
            pakietyOfert: [...this.props.pakietyOfert],
            nieMaWiecejOfert: false,
            ofertyDoUsuniecia: [],
            zalaczniki: [],
            komentarz: '',
            zamowieniaNodes: zamowienia.map(z => ({
                id: z.zamowienieNode.id,
                temporaryId: z.id,
                zadnaOfertaWybrana: false,
                wybranyWariant: null,
                oferty: getOfertyByZamowienieNodeId(z.zamowienieNode.id),
            })),
        };
    }

    getFetchUrl() {
        return `/api/announcements/${this.props.ogloszenie.ogloszenie.id}/settlement`
    }

    getFetchError(message) {
        message = translateErrorMessage(message);
        if (this.state.isNew) {
            return `Nie udało się pobrać danych do rozstrzygnieca ogłoszenia. ${message}`
        }
        return `Nie udało się pobrać danych do rozstrzygnieca ogłoszenia. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        if (this.state.isNew) {
            return `Podczas pobierania danych do rozstrzygnieca ogłoszenia wystąpił nieoczekiwany błąd o kodzie ${status}`
        }
        return `Podczas pobierania danych do rozstrzygnieca ogłoszenia wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    getMenuLinks(zamowienia) {
        let index = 0;
        let links = [
            {name: 'offers_completing', id: index++, label: 'Kompletowanie ofert', onClick: this.handleSetCurrentMenuLink},
        ];
        for (let z of zamowienia) {
            links.push({name: z.id, id: index, label: `Oferty${zamowienia.length > 1 ? ' do części ' + index : ''}`, onClick: this.handleSetCurrentMenuLink});
            index++;
        }
        links.push({name: 'summary', id: index, label: 'Podsumowanie', onClick: this.handleSetCurrentMenuLink});
        return links
    }

    setOfferRefuseValue(zamowienieId, ofertaId, isRefused) {
        this.setState(prevState => {
            let wariantIds = [];
            if (isRefused) {
                // get ids of variants for the offer to unselect if selected
                for (let po of prevState.pakietyOfert) {
                    for (let o of po.oferty) {
                        if (o.id === ofertaId) {
                            wariantIds = o.warianty.map(w => w.id);
                        }
                    }
                }
            }

            // get previously selected wariant to check if it hasn't been already refused
            let prevWybranyWariant = null;
            for (let z of prevState.zamowieniaNodes) {
                if (z.id === zamowienieId) {
                    prevWybranyWariant = z.wybranyWariant;
                }
            }

            return {
                zamowieniaNodes: prevState.zamowieniaNodes.map(z => {
                    if (z.id === zamowienieId) {
                        z.oferty = z.oferty.map(o => {
                            if (o.id === ofertaId) {
                                o.isRefused = isRefused;
                            }
                            return o
                        });
                        z.wybranyWariant = isRefused && wariantIds.includes(prevWybranyWariant) ? null : prevWybranyWariant;
                    }
                    return z
                }),
            }
        });
    }

    getSaveUrl() {
        return `/api/announcements/${this.props.ogloszenie.ogloszenie.id}/resolve`
    }

    getSaveMethod() {
        return this.props.isNew ? 'POST' : 'PUT'
    }

    getDataForSaving() {
        return getSerializedObject(
            this.getNotSerializedDataForSaving(),
            {toServer: true, removeTemporaryId: true}
        )
    }

    getNotSerializedDataForSaving() {
        return {
            ofertyDoUsuniecia: this.state.ofertyDoUsuniecia,
            zamowieniaNodes: this.state.zamowieniaNodes,
            komentarz: this.state.komentarz,
            zalaczniki: this.state.zalaczniki.map(z => ({
                id: z.id,
                temporaryId: z.temporaryId,
                nazwa: z.nazwa,
                plik: z.plik.id,
                jestProtokolem: z.jestProtokolem
            })),
        }
    }

    // rendering

    renderContent() {
        const currentMenuLinkName = this.state.currentMenuLink.name;
        return (
            <>
                <GridCustom flexL noWrap>
                    <GridCellCustom>
                        <MenuBasic
                            addProgressIcon={true}
                            children={this.state.menuLinks}
                            currentMenuLink={this.state.currentMenuLink.id}
                            disabled={this.state.savingOn}
                            disabledFrom={this.state.nieMaWiecejOfert ? null : 1}
                            errors={{}}
                            title={`${this.state.isNew ? 'Dodawanie' : 'Edycja'} rozstrzygnięcia`} />
                    </GridCellCustom>
                    <GridCellCustom className="offer-resolve__container" id="offer-resolve_content">
                        {currentMenuLinkName === 'offers_completing' && this.renderOffersCompleting()}
                        {this.props.ogloszenie.zamowienia.map(z => {
                            if (currentMenuLinkName === z.id) {
                                return this.renderOffers(z)
                            }
                            return null
                        })}
                        {currentMenuLinkName === 'summary' && this.renderSummary()}
                    </GridCellCustom>
                </GridCustom>
                {this.state.offerFormOn && this.renderFormOferta()}
                {this.state.offerPreviewOn && this.renderOfertaPreview()}
            </>
        )
    }

    renderOffersCompleting() {
        const pakietyOfertZewnetrzne = this.state.pakietyOfert.filter(
            po => po.ktoUtworzyl.id === this.props.user.id);
        return (
            <>
                <Container>
                    <span className="sr-only" role="alert">Jeśli masz oferty spoza Bazy Konkurencyjności, dodaj je klikając w guzik "Dodaj ofertę, która wpłynęła innym kanałem". Jeśli nie masz innych ofert, lub wprowadziłeś wszystkie, które wpłynęły innym kanałem, zaznacz checkbox "Nie ma więcej ofert".</span>
                    <GridCustom flexEnd>
                        <ButtonStyled
                            add
                            disabled={this.state.nieMaWiecejOfert}
                            onClick={() => this.setState({offerFormOn: true})}
                        >
                            Dodaj ofertę, która wpłynęła innym kanałem
                        </ButtonStyled>
                    </GridCustom>
                    <Text tag="h1" sectionHeader>Lista ofert złożonych poza Bazą Konkurencyjności</Text>
                    <Text info>Liczba wyników: {pakietyOfertZewnetrzne.length}</Text>
                    <GridCustom tag="ul">
                        {pakietyOfertZewnetrzne.map(oferta => this.renderOfertaZewnetrzna(oferta))}
                    </GridCustom>
                    <GridCustom centerHorizontal>
                        <Checkbox
                            checked={this.state.nieMaWiecejOfert}
                            className="checkbox--btn"
                            label="Nie ma więcej ofert"
                            onChange={event => this.setState({nieMaWiecejOfert: event.target.checked})}
                        />
                    </GridCustom>
                </Container>
                <Steps
                    disabled={this.state.nieMaWiecejOfert ? null : 1}
                    hasNext
                    nextLabel={this.state.currentMenuLink.next.label}
                    onNextClick={() => this.handleSetCurrentMenuLink(this.state.currentMenuLink.next.id)}
                />
            </>
        )
    }

    renderOfertaZewnetrzna(pakietOfert) {
        const { adres, nazwa } = pakietOfert.danePodmiotu;
        const zamowienia = this.props.ogloszenie.zamowienia;
        const isRemoved = this.state.ofertyDoUsuniecia.includes(pakietOfert.id);
        return (
            <Box
                boxWithMenu={isRemoved ? null : (
                    <>
                        <h3 className="sr-only">Menu więcej opcji ogłoszenia</h3>
                        <ul>
                            <li>
                                <ButtonStyled
                                    className="box__menu-item"
                                    onClick={() => this.setState({offerFormOn: true, editingOffersetId : pakietOfert.id})}>
                                    Edytuj
                                </ButtonStyled>
                                <ButtonStyled className="box__menu-item" onClick={() => this.handleRemoveOfertaZewnetrzna(pakietOfert.id)}>Usuń</ButtonStyled>
                            </li>
                        </ul>
                    </>
                )}
                key={pakietOfert.id}
                titleOneRow={`Oferta ${nazwa}`}
                tag="li"
            >
                <FieldWithLabel label="Nazwa podmiotu">
                    <Text className="long-text">{nazwa}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Miejscowość">
                    <Text className="long-text">{adres.miejscowosc}{adres.kraj !== 'Polska' ? ', ' + adres.kraj : ''}</Text>
                </FieldWithLabel>
                {zamowienia.length > 1 && (
                    <FieldWithLabel label="Złożona do części">
                        {(pakietOfert.oferty || []).map(o => (
                            <Text className="long-text" key={o.id}>- {((o.zamowienieNode || {}).ostatniaWersja || {}).tytul}</Text>
                        ))}
                    </FieldWithLabel>
                )}
                {isRemoved ? (
                        <Text error>Oferta zostanie usunięta z chwilą zapisania rozstrzygnięcia.</Text>
                    ) : (
                        <ButtonStyled
                            ariaLabel={`Pokaż szczegóły oferty ${nazwa}`}
                            className='corner-button'
                            primary
                            onClick={() => this.setState({offerPreviewOn: true, previewedOfferId: pakietOfert.id})}
                        >Pokaż szczegóły</ButtonStyled>
                    )
                }
            </Box>
        )
    }

    renderOffers(zamowienie) {
        let offersStateDict = {};
        let wybranyWariant = null;
        for (let z of this.state.zamowieniaNodes) {
            if (z.id === zamowienie.zamowienieNode.id) {
                wybranyWariant = z.wybranyWariant;
            }
            for (let o of z.oferty) {
                offersStateDict[o.id] = o.isRefused;
            }
        }

        let warianty = [];
        const ofertyDoUsuniecia = this.state.ofertyDoUsuniecia;
        for (let po of this.state.pakietyOfert) {
            if (ofertyDoUsuniecia.includes(po.id)) {
                continue
            }
            for (let o of po.oferty) {
                if (o.zamowienieNode.id === zamowienie.zamowienieNode.id) {
                    for (let w of o.warianty) {
                        warianty.push(Object.assign({}, w, {
                            nazwaOferenta: (po.danePodmiotu || {}).nazwa || '',
                            isRefused: offersStateDict[o.id] || false,
                            isSelected: wybranyWariant === w.id,
                            ofertaId: o.id,
                            zalaczniki: po.zalaczniki || [],
                        }));
                    }
                }
            }
        }

        let zadnaOfertaWybrana = false;
        for (let z of this.state.zamowieniaNodes) {
            if (z.id === zamowienie.zamowienieNode.id) {
                zadnaOfertaWybrana = z.zadnaOfertaWybrana;
                break
            }
        }

        return (
            <GridCellCustom key={zamowienie.id} className="offer-resolve__container">
                <Container>
                    <span className="sr-only" role="alert">Możesz odrzucić oferty, wybrać zwycięską ofertę lub zaznaczyć, że nie wybierasz żadnej oferty.</span>
                    <FormCzescRozstrzygniecia
                        hasMultiplyZamowienia={this.props.ogloszenie.zamowienia.length > 1}
                        zadnaOfertaWybrana={zadnaOfertaWybrana}
                        warianty={warianty}
                        zamowienie={zamowienie}
                        onNoOfferSelectedChange={this.handleNoOfferSelectedChange}
                        onOfferRefuse={this.handleRefuseOffer}
                        onOfferRestore={this.handleRestoreOffer}
                        onWariantSelect={this.handleSelectWariant}
                    />
            </Container>
                <Steps
                    hasNext
                    hasPrev
                    nextLabel={this.state.currentMenuLink.next.label}
                    prevLabel={this.state.currentMenuLink.prev.label}
                    onNextClick={() => this.handleSetCurrentMenuLink(this.state.currentMenuLink.next.id)}
                    onPrevClick={() => this.handleSetCurrentMenuLink(this.state.currentMenuLink.prev.id)}
                />
            </GridCellCustom>
        )
    }

    renderSummary() {
        const formLocked = this.state.savingOn;
        const fetchSaveError = this.state.fetchSaveError;
        return (
            <GridCellCustom className="offer-resolve__container">
                <Container>
                    <span className="sr-only" role="alert">Podsumowanie: możesz dodać załącznik, oznaczyć wybrany załącznik jako protokół, a następnie opublikować rozstrzygnięcie.</span>
                    <Text tag="h2" mainHeader>Podsumowanie</Text>
                    {this.renderSelectedOfertyList()}
                    {this.renderZalaczniki()}
                    {this.renderKomentarz()}
                </Container>
                <Steps
                    disabled={formLocked}
                    hasPrev
                    prevLabel={this.state.currentMenuLink.prev.label}
                    onPrevClick={() => this.handleSetCurrentMenuLink(this.state.currentMenuLink.prev.id)}
                />
                <GridCustom flexEnd>
                    <ButtonStyled
                        className="btn btn--main"
                        disabled={formLocked}
                        save
                        onClick={this.handleSave}
                    >{this.state.saveBtnText}</ButtonStyled>
                </GridCustom>
                {fetchSaveError.length > 0 && <Text error role="alert">{fetchSaveError}</Text>}
                {this.state.errorsDialogOn && this.renderErrorsDialog()}
            </GridCellCustom>
        )
    }

    renderFormOferta() {
        return (
            <DialogCustom
                className="dialog-long-content"
                onClose={this.handleCloseForm}
            >
                <FormOfertaOgloszeniodawca
                    isNew={this.state.editingOffersetId === null}
                    ogloszenieId={this.props.ogloszenie.ogloszenie.id}
                    ofertaId={this.state.editingOffersetId}
                    zamowienia={this.props.ogloszenie.zamowienia.map(z => ({
                        id: z.id,
                        zamowienieNode: z.zamowienieNode,
                        tytul: z.tytul,
                        czyWariantowe: z.czyWariantowe,
                    }))}
                    onCloseForm={this.handleCloseForm}
                    onSubmitForm={this.handleSubmitOfertaForm}
                />
            </DialogCustom>
        )
    }

    renderOfertaPreview() {
        if (!this.state.previewedOfferId) { return null }
        return <OfertaPreview
            ofertaId={this.state.previewedOfferId}
            ogloszenieId={this.props.ogloszenie.ogloszenie.id}
            onClosePreview={this.handleCloseOfferPreview} />
    }

    renderZalaczniki() {
        return (
            <Container>
                <Zalaczniki
                    checkAttributeName="jestProtokolem"
                    checkLabel="Oznacz jako protokół"
                    contextForSaving='settlement'
                    errors={this.state.errors.zalaczniki || {}}
                    formLocked={this.state.savingOn}
                    isPreview={false}
                    maxLength={MAX_ATTACHMENTS_NUMBER}
                    withCheck={true}
                    zalaczniki={this.state.zalaczniki}
                    addZalacznik={this.handleAddZalacznik}
                    removeZalacznik={this.handleRemoveZalacznik}
                    saveZalacznikAttributes={this.handleSaveZalacznikAttributes}
                    onCheckChange={this.handleChangeZalacznikIsProtocol}
                >
                    <Text info className="text--counter">Liczba wyników: {this.state.zalaczniki.length}</Text>
                    <Text info>Możesz załączyć maksymalnie {MAX_ATTACHMENTS_NUMBER} plików.</Text>
                </Zalaczniki>
            </Container>
        )
    }

    renderKomentarz() {
        const isKomentarzInvalid = (this.state.errors.komentarz || []).length > 0;
        return (
            <Container>
                <FieldWithLabel label="Komentarz" tag="label" labelFor="notes_id">
                    <TextFieldCustom
                        aria-describedby={isKomentarzInvalid ? 'notes_error' : null}
                        aria-label={`Komentarz, maksymalna liczba znaków: 1000. Wpisano ${this.state.komentarz.length} znaków.`}
                        aria-valuemax={1000}
                        characterCount
                        clearFieldContext="komentarz"
                        disabled={this.state.savingOn}
                        invalid={isKomentarzInvalid}
                        fullwidth
                        id="notes_id"
                        maxLength={1000}
                        textarea
                        value={this.state.komentarz}
                        onChange={event => this.setState({komentarz: event.target.value})}
                        onClear={(ev) => this.setState({komentarz: ''})} />
                    {isKomentarzInvalid && <ul id="notes_error">{this.state.errors.komentarz.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                </FieldWithLabel>
            </Container>
        )
    }

    renderSelectedOfertyList() {
        const zamowienia = this.props.ogloszenie.zamowienia;
        if (zamowienia.length === 1) {
            return this.renderZamowienieSummary(zamowienia[0], false)
        }
        return zamowienia.map(z => this.renderZamowienieSummary(z, true))
    }

    renderZamowienieSummary(zamowienie, isMultiply) {
        let zamowienieFromState = null;
        for (let z of this.state.zamowieniaNodes) {
            if (z.id === zamowienie.zamowienieNode.id) {
                zamowienieFromState = z;
            }
        }

        if (!zamowienieFromState) {
            return this.renderUnexpectedSummaryPartError(zamowienie, isMultiply)
        }

        if (zamowienieFromState.zadnaOfertaWybrana) {
           return this.renderNoOfferSelected(zamowienie, isMultiply)
        }

        if (zamowienieFromState.wybranyWariant === null) {
           return this.renderNoChoice(zamowienie, isMultiply)
        }

        // get selectedWariant data
        let wybranyWariant = null;
        for (let po of this.state.pakietyOfert) {
            for (let o of po.oferty) {
                for (let w of o.warianty) {
                    if (w.id === zamowienieFromState.wybranyWariant) {
                        wybranyWariant = Object.assign(
                            {}, w, {nazwaOferenta: po.danePodmiotu.nazwa});
                        break
                    }
                }
                if (wybranyWariant !== null) {
                    break
                }
            }
            if (wybranyWariant !== null) {
                break
            }
        }

        if (wybranyWariant === null) {
            return this.renderUnexpectedSummaryPartError(zamowienie, isMultiply);
        }

        let info;
        if (isMultiply) {
            info = <Text label>Dla części "{zamowienie.tytul}" została wybrana oferta</Text>;
        } else {
            info = <Text label>Została wybrana oferta</Text>;
        }

        return (
            <div key={zamowienie.id} className="offer-resolve__summary">
                {info}
                <Box titleOneRow={`${wybranyWariant.cena} PLN`}>
                    <Text className="long-text">{wybranyWariant.nazwaOferenta}</Text>
                    {wybranyWariant.opis.length > 0 && (
                        <FieldWithLabel label="Opis">
                            <Text className="long-text">{wybranyWariant.opis}</Text>
                        </FieldWithLabel>
                    )}
                </Box>
            </div>
        )
    }

    renderNoOfferSelected(zamowienie, isMultiply) {
        if (isMultiply) {
            return (
                <div className="offer-resolve__summary" key={zamowienie.id}>
                    <Text label>Dla części "{zamowienie.tytul}" nie wybrano żadnej oferty.</Text>
                </div>
            )
        }
        return (
            <div className="offer-resolve__summary">
                <Text label>Nie wybrano żadnej oferty.</Text>
            </div>
        )
    }

    renderNoChoice(zamowienie, isMultiply) {
        if (isMultiply) {
            return (
                <div key={zamowienie.id} className="offer-resolve__summary">
                    <Text label>Dla części "{zamowienie.tytul}"</Text>
                    <Text error>Nie dokonano wyboru</Text>
                </div>
            )
        }
        return (
            <div className="offer-resolve__summary">
                <Text>Dla oferty</Text>
                <Text error>Nie dokonano wyboru</Text>
            </div>
        )
    }

    renderUnexpectedSummaryPartError(zamowienie, isMultiply) {
        if (isMultiply) {
            return (
                <Text error key={zamowienie.id} role="alert">
                   Wystąpił nieoczekiwany problem z częścią "{zamowienie.tytul}"
               </Text>
            )
        }
        return <Text error role="alert">Wystąpił nieoczekiwany problem.</Text>
    }

    renderErrorsDialog() {
        const errors = this.state.errors.zamowieniaNodes || {};
        const zamowienia = this.props.ogloszenie.zamowienia;
        const isMultiply = zamowienia.length > 1;
        return (
            <DialogCustom
                className="dialog"
                dialogTitleError="Podczas próby rozstrzygnięcia wystąpiły błędy dotyczące wyboru ofert."
                onClose={() => this.setState({errorsDialogOn: false})}
            >
                <>
                    {zamowienia.map(z => {
                        const zamowienieErrors = (errors[z.zamowienieNode.id] || {}).zadnaOfertaWybrana || [];
                        if (zamowienieErrors.length) {
                            let info;
                            if (isMultiply) {
                                info = <Text error>Dla części "{z.tytul}" wystąpiły następujące błędy:</Text>;
                            } else {
                                info = <Text error>Dla oferty wystąpiły następujące błędy:</Text>;
                            }
                            return (
                                <div key={z.id} role="status" aria-live="polite">
                                    {info}
                                    {zamowienieErrors.map((e, i) => <Text error key={i}>{e}</Text>)}
                                </div>
                            )
                        }
                        return null
                    })}
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled onClick={() => this.setState({errorsDialogOn: false})} primary>OK</ButtonStyled>
                    </GridCustom>
                </>
            </DialogCustom>
        )
    }
}


export { FormRozstrzygniecie };
