import React, { createContext } from 'react';

import { getSerializedObject } from '../serializers';


const EMPTY_USER = {
    accountType: 'USER',
    email: '',
    forename: '',
    id: null,
    identifier: null,
    surname: '',
    advertisementManagerType: {
        isAuthor: false,
        isAdmin: false,
    },
};
const EMPTY_AUTH = { expire: null };


const UserContext = createContext({
    // errors
    user: {...EMPTY_USER},
    auth: {...EMPTY_AUTH},
    permissions: {},
    set: () => {},
    setAdvertisementManagerType: () => {},
    refresh: () => {},
    refreshTimer: () => {},
    logout: () => {},
    removePermission: () => {},
});


export class UserProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {...EMPTY_USER},
            auth: {...EMPTY_AUTH},
            permissions: {},
            set: this.set,
            setAdvertisementManagerType: this.setAdvertisementManagerType,
            refresh: this.refresh,
            refreshTimer: this.refreshTimer,
            logout: this.logout,
            removePermission: this.removePermission,
        };
    }

    set = (data, callback=null) => {
        // from backend, at the beginning of application life or after login
        this.setState(
            prevState => ({
                user: Object.assign({}, prevState.user, getSerializedObject(data.user || {}, {mapToCamelCaseName: true})),
                auth: Object.assign(
                    {}, prevState.auth, (data.meta || {}).auth || {}),
                permissions: this.serializePermission(data.permissions || {}),
            }),
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    }

    setAdvertisementManagerType = (data, callback=null) => {
        this.setState(
            prevState => ({
                user: {...prevState.user, advertisementManagerType: data},
            }),
            () => {
                if (callback) { callback() }
            }
        );
    }

    serializePermission = (permissions) => {
        /*
        Full expected permissions is a dict like that:
        {
            adProjectEnrollments: {
                advertisementAdministration: {
                    searchNPreviewData: true,
                    blocking: true,
                    editData: true
                },
                projectsEnrollmentsAdministration: {
                    searchNPreviewData: true,
                    editData: true
                },
                permissionsAdministration: {
                    previewListUsersAuthorized: true,
                    functionalityManagement: true,
                    permissionsManagement: true
                }
            },
            contentAdministration: {
                contentAdministration: {
                    help: true,
                    generalContentCookiesRegulation: true,
                    statements: true
                },
                permissionsAdministration: {
                    previewListUsersAuthorized: true,
                    functionalityManagement: true,
                    permissionsManagement: true
                }
            },
            implementationLevelsDictionaryAdministration: {
                implementationLevelsDictionaryAdministration: {
                  previewData: true,
                  editData: true
                },
                permissionsAdministration: {
                  previewListUsersAuthorized: true,
                  functionalityManagement: true,
                  permissionsManagement: true
                }
            },
            usersAdministration: {
                usersAdministration: {
                    previewListUsers: true,
                    blockingUsers: true
                },
                permissionsAdministration: {
                    previewListUsersAuthorized: true,
                    functionalityManagement: true,
                    permissionsManagement: true
                }
            }
        }
        */
        permissions = permissions || {};
        const adProjEnrAdm = permissions.adProjectEnrollments || {};

        const usersAdm = permissions.usersAdministration || {};
        const usersAdmUsersAdministration =
            usersAdm.usersAdministration || {};
        const usersAndPermissionsPreviewListUsers =
            usersAdmUsersAdministration.previewListUsers || false;
        const usersAdmPermissionsAdministration =
            usersAdm.permissionsAdministration || {}
        const usersAndPermissionsPreviewListUsersAuthorized =
            usersAdmPermissionsAdministration.previewListUsersAuthorized ||
            false;

        const contentAdm = permissions.contentAdministration || {};
        const contentAdmContentAdministration =
            contentAdm.contentAdministration || {};
        const contentAdmPermissionsAdministration =
            contentAdm.permissionsAdministration || {};
        const implementationLevelsDictionaryAdm =
            permissions.implementationLevelsDictionaryAdministration || {};
        const implementationLevelsDictionaryAdmPermissionsAdministration =
            implementationLevelsDictionaryAdm.permissionsAdministration || {};
        const implementationLevelsDictionaryAdmImplementationLevelsDictionaryAdministration =
            implementationLevelsDictionaryAdm.implementationLevelsDictionaryAdministration || {};

        return {
            // used in menu
            fullUsersAndPermissions: usersAndPermissionsPreviewListUsers ||
                usersAndPermissionsPreviewListUsersAuthorized,
            usersAndPermissionsPreviewListUsers,
            usersAndPermissionsPreviewListUsersAuthorized,
            // used in add/edit permissions forms
            usersPermissionsManagement: {
                functionality:
                    usersAdmPermissionsAdministration.functionalityManagement ||
                    false,
                permissions:
                    usersAdmPermissionsAdministration.permissionsManagement ||
                    false,
            },
            blockingUsers:
                usersAdmUsersAdministration.blockingUsers || false,
            // used in menu
            projectsEnrollments: (adProjEnrAdm
                .projectsEnrollmentsAdministration || {})
                .searchNPreviewData || false,
            // used in menu
            advertisements: (adProjEnrAdm
                .advertisementAdministration || {})
                .searchNPreviewData || false,
            // used in menu
            fullContent: Object.values(contentAdmContentAdministration).filter(
                value => value === true).length > 0,
            help: contentAdmContentAdministration.help || false,
            generalContentCookiesRegulation:
                contentAdmContentAdministration.generalContentCookiesRegulation
                || false,
            statements: contentAdmContentAdministration.statements || false,
            // used in add/edit permissions forms
            contentPermissionsManagement: {
                functionality:
                    contentAdmPermissionsAdministration.functionalityManagement,
                permissions:
                    contentAdmPermissionsAdministration.permissionsManagement,
            },
            addImplementationLevelPermission:
                (adProjEnrAdm.permissionsAdministration || {}).functionalityManagement ||
                (adProjEnrAdm.permissionsAdministration || {}).permissionsManagement ||
                false
            ,
            // used in add/edit permissions forms
            implementationLevelsDictionaryPermissionsManagement: {
                functionality:
                    implementationLevelsDictionaryAdmPermissionsAdministration.functionalityManagement,
                permissions:
                    implementationLevelsDictionaryAdmPermissionsAdministration.permissionsManagement,
            },
            previewDictionaries:
                implementationLevelsDictionaryAdmImplementationLevelsDictionaryAdministration
                    .previewData || false,
            editingDictionaries:
                implementationLevelsDictionaryAdmImplementationLevelsDictionaryAdministration
                    .editData || false,
        }
    }

    refresh = (data) => {
        // from sativa
        this.setState(
            prevState => {
                // if identifier is null, user has logged out in the meantime, do nothing
                if (prevState.user.identifier === null) {
                    return {}
                }
                const user = data.data;
                return {
                    user: Object.assign(
                        {},
                        prevState.user,
                        {
                            email: user.email,
                            forename: user.first_name,
                            identifier: user.id,
                            surname: user.last_name,
                        }
                    ),
                    auth: Object.assign(
                        {},
                        prevState.auth,
                        {expire: data.meta.expire}
                    ),
                }
            },
        );
    }

    refreshTimer = (expire, callback) => {
        this.setState(
            prevState => {
                // if identifier is null, user has logged out in the meantime, do nothing
                if (prevState.user.identifier === null) { return {} }
                return {
                    auth: Object.assign(
                        {},
                        prevState.auth,
                        {expire}
                    ),
                }
            },
            () => {
                // run callback only if authenticated
                if (this.state.user.identifier !== null) { callback() }
            }
        );
    }

    logout = (callback=null) => {
        this.setState(
            {
                user: EMPTY_USER,
                auth: EMPTY_AUTH,
                permissions: {},
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    }

    removePermission = (permissionName) => {
        /*
        Possible permission's names:
        fullUsersAndPermissions, usersAndPermissionsPreviewListUsers,
        usersAndPermissionsPreviewListUsersAuthorized,
        blockingUsers,
        projectsEnrollments,
        advertisements,
        fullContent, help, generalContentCookiesRegulation, statements,
        previewDictionaries, editingDictionaries
        */
        this.setState(prevState => {
            let permissions = {
                ...prevState.permissions,
                [permissionName]: false
            };
            if ([
                'help', 'generalContentCookiesRegulation', 'statements'
            ].includes(permissionName)) {
                permissions['fullContent'] = permissions.help ||
                    permissions.generalContentCookiesRegulation ||
                    permissions.statements;
            } else if ([
                'usersAndPermissionsPreviewListUsers',
                'usersAndPermissionsPreviewListUsersAuthorized'
            ].includes(permissionName)) {
                permissions['fullUsersAndPermissions'] =
                    permissions.usersAndPermissionsPreviewListUsers ||
                    permissions.usersAndPermissionsPreviewListUsersAuthorized
            }
            return {permissions}
        });
    }

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}


export const UserConsumer = UserContext.Consumer;
export { UserContext };
