import React, { Component } from 'react';

import { ButtonStyled, DialogCustom } from '../../common';
import { LandingPage } from '../LandingPage';
import { FormOgloszenie } from '../ogloszenia/FormOgloszenie';
import { Ogloszenia } from '../ogloszenia/Ogloszenia';
import { Projekty } from '../projekty/Projekty';
import { NewsletterSettings } from '../newsletter/NewsletterSettings';
import { Favorites } from '../newsletter/Favorites';


class TrescPreview extends Component {

    // rendering

    render() {
        return (
            <DialogCustom
                className="dialog-long-content dialog-long-content--preview"
                style={{zIndex: 100}}
                onClose={this.props.closePreview}
            >
                <>
                    <ButtonStyled onClick={this.props.closePreview} primary className="dialog-long-content__close">Zamknij podgląd</ButtonStyled>
                    <p className="top-app-bar-imitation">Baza Konkurencyjności</p>
                    {this.renderPreview()}
                </>
            </DialogCustom>
        )
    }

    renderPreview() {
        let Component = null;
        let step = null;
        switch (this.props.screen) {
            case 'homePage':
                Component = LandingPage;
                break;
            case 'projekty_lista_ogloszeniodawca':
                Component = Projekty;
                break;
            case 'ogloszenia_lista_ogloszeniodawca':
                Component = Ogloszenia;
                break;
            case 'newsletter_ustawienia':
                Component = NewsletterSettings;
                break;
            case 'ulubione_lista':
                Component = Favorites;
                break;
            default:
                Component = FormOgloszenie;
                step = this.props.screen.split('_').pop();
        }
        if (!Component) { return null }
        return <Component preview content={this.props.content} step={step} {...{location: {search: {}}}}/>
    }
}


export { TrescPreview };
