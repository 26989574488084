import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { AdvertisementProvider } from '../context/advertisementContext';
import { MenuContext } from '../context/menuContext';
import { UserContext } from '../context/user-context';
import { URLS } from '../urls/frontend';
import { NotFoundPage } from './common';
import { DeklaracjaDostepnosci } from './layoutElements/DeklaracjaDostepnosci';
import {
    Activities,
    Institutions,
    Perspectives,
    Priorities,
    Programmes,
    Regions,
    Subactivities
} from './layoutElements/implementationLevelsManagement';
import { LandingPage } from './layoutElements/LandingPage';
import { Advertisements, Projects } from './layoutElements/management';
import { FormOgloszenie, Ogloszenia, Ogloszenie, Wyszukiwarka } from './layoutElements/ogloszenia';
import { Oferty } from './layoutElements/oferty';
import { Newsletter } from './layoutElements/newsletter/Newsletter';
import { Projekty } from './layoutElements/projekty';
import {
    BladLogowania, Logowanie, ProfilUzytkownika, User, Users
} from './layoutElements/uzytkownicy';
import {
    Ciasteczka,
    FormCiasteczka, FormKomunikat, FormPomoc, FormRegulamin, FormTrescOgolna,
    Komunikat, Komunikaty, KomunikatFront, KomunikatyFront,
    Pomoc, Pomoce, PomoceFront, PomocFront,
    Regulamin, Regulaminy,
    TresciOgolne, TrescOgolna,
    ZarzadzanieTrescia
} from './layoutElements/zarzadzanieTrescia';
import { Subscriptions } from './layoutElements/subscriptions/Subscriptions'


// add url pattern here if you want to light the menu link on for that page
const URL_PATTERNS_BY_MENU_LINK_NAME = {
    projects: [URLS.projects.pattern],
    advertisements: [
        'advertisements', 'advertisementsNew', 'advertisementsEdit'].map(
        name => URLS[name].pattern),
    offers: [URLS.offers.pattern],
    newsletter: [URLS.newsletter.pattern],
    accessibility: [URLS.accessibility.pattern],
    users: ['users', 'usersDetails', 'usersMyProfile'].map(
        name => URLS[name].pattern),
    projectsManagement: [URLS.managementProjects.pattern],
    advertisementsManagement: [URLS.managementAdvertisements.pattern],
    contentsManagement: [
        'managementContents',
        'managementContentsHelps',
        'managementContentsHelpsNew',
        'managementContentsHelpsEdit',
        'managementContentsHelpsDetails',
        'managementContentsRegulations',
        'managementContentsRegulationsCurrent',
        'managementContentsRegulationsForPublishing',
        'managementContentsRegulationsNew',
        'managementContentsRegulationsEdit',
        'managementContentsCookies',
        'managementContentsCookiesNew',
        'managementContentsCookiesEdit',
        'managementContentsGeneralContents',
        'managementContentsGeneralContentsEdit',
        'managementContentsGeneralContentsDetails',
        'managementContentsStatements',
        'managementContentsStatementsNew',
        'managementContentsStatementsEdit',
        'managementContentsStatementsDetails'
    ].map(name => URLS[name].pattern),
    implementationLevelsDictionaryManagement: [
        'managementPerspectives',
        'managementProgrammesForPerspective',
        'managementPrioritiesForProgramme',
        'managementSubactivitiesForPriority',
        'managementActivitiesForPriority',
        'managementRegionsForSubactivity',
        'managementInstitutionsForActivity',
    ].map(name => URLS[name].pattern),
};


const Routes = ({ onLoginClick, onSetMenuLinkByName }) => {

    const {
        permissions,
        user,
        setAdvertisementManagerType
    } = useContext(UserContext);
    const userAnonymous = user.identifier === null;

    const history = useHistory();

    function setMenuLink() {
        for (let [linkName, patternList] of Object.entries(
                URL_PATTERNS_BY_MENU_LINK_NAME)) {
            for (let pattern of patternList) {
                if (pattern.test(history.location.pathname)) {
                    onSetMenuLinkByName(linkName);
                    return
                }
            }
        }
        // if not found, clear menu link
        onSetMenuLinkByName(null);
    }
    useEffect(setMenuLink, [history.location.pathname, ]);

    const getComponent = (component, permissionName=null) => {
        if (userAnonymous) {
            return <Redirect to="/" />
        }
        if (permissionName !== null && !permissions[permissionName]) {
            return <NotFoundPage />
        }
        return component
    }

    const { menuLinkKey } = useContext(MenuContext);

    return (
        <AdvertisementProvider>
            <Switch>
                <Route
                    exact path={URLS.home.path}
                    render={(props) => <LandingPage
                        {...props}
                        user={user}
                        onLoginClick={onLoginClick} />
                    } />
                <Route
                    exact path={URLS.login.path}
                    render={(props) => <Logowanie {...props} user={user} />}
                />
                <Route
                    exact path={URLS.loginError.path}
                    render={(props) => userAnonymous
                        ? <BladLogowania {...props} />
                        : <Redirect to="/" />
                    } />
                <Route
                    exact path={URLS.projects.path}
                    render={(props) => getComponent(
                        <Projekty {...props} />
                    )} />
                <Route
                    exact path={URLS.advertisements.path}
                    render={(props) => getComponent(
                        <Ogloszenia {...props} />
                    )} />
                <Route
                    exact path={URLS.advertisementsSearch.path}
                    render={(props) => <Wyszukiwarka
                        {...props} userIdentifier={user.identifier} />
                    } />
                <Route
                    exact path={URLS.advertisementsNew.path}
                    render={(props) => getComponent(
                        <FormOgloszenie
                            {...props}
                            isNew={true}
                            ref={(c) => {window.formOgloszenie = c}}
                            setAdvertisementManagerType={setAdvertisementManagerType}
                            userData={user}
                        />
                    )} />
                <Route
                    exact path={URLS.advertisementsDetails.path}
                    render={(props) => <Ogloszenie
                        {...props}
                        user={user}
                        userAnonymous={userAnonymous}
                        setAdvertisementManagerType={setAdvertisementManagerType} />
                    } />
                <Route
                    exact path={URLS.advertisementsDetailsDraft.path}
                    render={(props) => getComponent(
                        <Ogloszenie
                            {...props}
                            draftMode={true}
                            user={user}
                            setAdvertisementManagerType={setAdvertisementManagerType}
                        />
                )} />
                <Route
                    exact path={URLS.advertisementsDetailsVersion.path}
                    render={(props) => <Ogloszenie
                        {...props}
                        user={user}
                        userAnonymous={userAnonymous}
                        versionMode={true}
                        setAdvertisementManagerType={setAdvertisementManagerType} />
                    } />
                <Route
                    exact path={URLS.advertisementsEdit.path}
                    render={(props) => getComponent(
                        <FormOgloszenie
                            {...props}
                            isNew={false}
                            ref={(c) => {window.formOgloszenie = c}}
                            setAdvertisementManagerType={setAdvertisementManagerType}
                            userData={user}
                        />
                    )} />
                <Route
                    exact path={URLS.offers.path}
                    render={(props) => getComponent(
                        <Oferty {...props} />
                    )} />
                <Route
                    exact path={URLS.newsletter.path} 
                    render={(props) => <Newsletter key={menuLinkKey} {...props} />}/>
                <Route
                    exact path={URLS.accessibility.path}
                    component={DeklaracjaDostepnosci} />
                <Route
                    exact path={URLS.users.path}
                    render={(props) => getComponent(
                        <Users key={menuLinkKey} {...props} />,
                        'fullUsersAndPermissions'
                    )} />
                <Route
                    exact path={URLS.usersDetails.path}
                    render={(props) => getComponent(
                        <User {...props} />,
                        'fullUsersAndPermissions'
                    )} />
                <Route
                    exact path={URLS.usersMyProfile.path}
                    render={(props) => getComponent(
                        <ProfilUzytkownika {...props} />
                    )} />
                <Route
                    exact path={URLS.managementProjects.path}
                    render={(props) => getComponent(
                        <Projects key={menuLinkKey} {...props} />,
                        'projectsEnrollments'
                    )} />
                <Route
                    exact path={URLS.managementAdvertisements.path}
                    render={(props) => getComponent(
                        <Advertisements key={menuLinkKey} {...props} />,
                        'advertisements'
                    )} />
                <Route
                    exact path={URLS.managementContents.path}
                    render={(props) => getComponent(
                        <ZarzadzanieTrescia {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsHelps.path}
                    render={(props) => getComponent(
                        <Pomoce {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsHelpsNew.path}
                    render={(props) => getComponent(
                        <FormPomoc {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsHelpsEdit.path}
                    render={(props) => getComponent(
                        <FormPomoc {...props} editing={true} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsHelpsDetails.path}
                    render={(props) => getComponent(
                        <Pomoc {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsRegulations.path}
                    render={(props) => getComponent(
                        <Regulaminy {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsRegulationsCurrent.path}
                    render={(props) => getComponent(
                        <Regulamin {...props} front={false} current={true} />,
                        'fullContent'
                    )} />
                <Route
                    exact
                    path={URLS.managementContentsRegulationsForPublishing.path}
                    render={(props) => getComponent(
                       <Regulamin {...props} front={false} current={false} />,
                        'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsRegulationsNew.path}
                    render={(props) => getComponent(
                       <FormRegulamin {...props} />
                    )} />
                <Route
                    exact path={URLS.managementContentsRegulationsEdit.path}
                    render={(props) => getComponent(
                       <FormRegulamin {...props} editing={true} />,
                        'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsCookies.path}
                    render={(props) => getComponent(
                        <Ciasteczka {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsCookiesNew.path}
                    render={(props) => getComponent(
                        <FormCiasteczka {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsCookiesEdit.path}
                    render={(props) => getComponent(
                        <FormCiasteczka {...props} editing={true} />,
                        'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsGeneralContents.path}
                    render={(props) => getComponent(
                        <TresciOgolne {...props} />, 'fullContent'
                    )} />
                <Route
                    exact
                    path={URLS.managementContentsGeneralContentsEdit.path}
                    render={(props) => getComponent(
                        <FormTrescOgolna {...props} />, 'fullContent'
                    )} />
                <Route
                    exact
                    path={URLS.managementContentsGeneralContentsDetails.path}
                    render={(props) => getComponent(
                        <TrescOgolna {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsStatements.path}
                    render={(props) => getComponent(
                        <Komunikaty {...props} />, 'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsStatementsNew.path}
                    render={(props) => getComponent(
                        <FormKomunikat {...props} editing={false} />,
                        'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsStatementsEdit.path}
                    render={(props) => getComponent(
                        <FormKomunikat {...props} editing={true} />,
                        'fullContent'
                    )} />
                <Route
                    exact path={URLS.managementContentsStatementsDetails.path}
                    render={(props) => getComponent(
                        <Komunikat {...props} />, 'fullContent'
                    )} />
                <Route exact path={URLS.managementPerspectives.path}>
                    {getComponent(<Perspectives />, 'previewDictionaries')}
                </Route>
                <Route exact path={URLS.managementProgrammesForPerspective.path}>
                    {getComponent(<Programmes />, 'previewDictionaries')}
                </Route>
                <Route exact path={URLS.managementPrioritiesForProgramme.path}>
                    {getComponent(<Priorities />, 'previewDictionaries')}
                </Route>
                <Route exact path={URLS.managementSubactivitiesForPriority.path}>
                    {getComponent(<Subactivities />, 'previewDictionaries')}
                </Route>
                <Route exact path={URLS.managementActivitiesForPriority.path}>
                    {getComponent(<Activities />, 'previewDictionaries')}
                </Route>
                <Route exact path={URLS.managementRegionsForSubactivity.path}>
                    {getComponent(<Regions />, 'previewDictionaries')}
                </Route>
                <Route exact path={URLS.managementInstitutionsForActivity.path}>
                    {getComponent(<Institutions />, 'previewDictionaries')}
                </Route>
                <Route
                    exact path={URLS.helps.path}
                    component={PomoceFront} />
                <Route
                    exact path={URLS.helpsDetails.path}
                    component={PomocFront} />
                <Route
                    exact path={URLS.regulations.path}
                    render={(props) =>
                        <Regulamin {...props} front={true} current={true} />
                    } />
                <Route
                    exact path={URLS.subscriptions.path}
                    component={Subscriptions} />
                <Route
                    exact path={URLS.statements.path}
                    component={KomunikatyFront} />
                <Route
                    exact path={URLS.statementsDetails.path}
                    component={KomunikatFront} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </AdvertisementProvider>
    )
}


export { Routes };
