import React from 'react';

import { OFERTA_STATUSES } from '../../../const';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { ButtonStyled, DialogCustom, GridCustom, Text } from '../../common';
import { BaseComponent } from '../common';
import { FormOferta } from './FormOferta';
import { Oferta } from './Oferta';


class OfertyOffererPublishedStatus extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        this.xhrFetch = null;

        const serializedData = getSerializedObject(data, {addTemporaryId: true});
        const pakietOfert = serializedData.pakietOfert;
        if (!pakietOfert) {
            this.setState({initializingOn: false});
            return
        }

        this.setState({
            initializingOn: false,
            formOn: ['DRAFT', 'WITHDRAWN'].includes(pakietOfert.status.label),
            pakietOfert: pakietOfert,
        });
    }

    handleCloseForm = () => {
        this.setState(
            {formOn: false},
            () => this.handleReload()
        );
    }

    handleWithdrawOferta = () => {
        this.setState(prevState => ({
            formOn: true,
            withdrawingOfferConfirmationOn: true,
            submittingOfferConfirmationOn: false,
            pakietOfert: Object.assign(
                {}, prevState.pakietOfert, {status: OFERTA_STATUSES.withdrawn}),
        }));
    }

    handleCloseWithdrawingOfferConfirmationDialog = () => {
        window.location.reload();
    }

    handleSubmitOferta = (offerSetId) => {
        this.setState(prevState => ({
            formOn: false,
            withdrawingOfferConfirmationOn: false,
            submittingOfferConfirmationOn: true,
            pakietOfert: Object.assign(
                {}, prevState.pakietOfert, {status: OFERTA_STATUSES.submitted, id: offerSetId}),
        }));
    }

    handleCloseSubmittingOfferConfirmationDialog = () => {
        window.location.reload();
    }

    handleReload = () => {
        this.setState(
            {initializingOn: true, fetchError: '', pakietOfert: null},
            () => {this.fetchInitialData()}
        );
    }

    // helpers

    getAdditionalState(props) {
        return {
            formOn: false,
            withdrawingOfferConfirmationOn: false,
            submittingOfferConfirmationOn: false,
            pakietOfert: null,
        }
    }

    getFetchUrl() {
        return `/api/offerset/announcement/${this.props.ogloszenie.ogloszenie.id}`
    }

    getFetchError(message) {
        return `Nie udało się pobrać danych oferty. ${translateErrorMessage(message)}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych oferty. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // rendering

    renderContent() {
        if (this.state.formOn) {
            return (
                <>
                    <FormOferta
                        isNew={this.state.pakietOfert === null}
                        ogloszenieId={this.props.ogloszenie.ogloszenie.id}
                        zamowienia={this.props.ogloszenie.zamowienia}
                        onCloseForm={this.handleCloseForm}
                        onSubmitOferta={this.handleSubmitOferta}
                    />
                    {this.state.withdrawingOfferConfirmationOn && this.renderWithdrawingOfferConfirmationDialog()}
                </>
            )
        }
        const { pakietOfert } = this.state;
        if (!pakietOfert || !pakietOfert.id) {
            return (
                <div className="login-info-container">
                    <ButtonStyled add onClick={() => this.setState({formOn: true})} className="btn btn--main">Utwórz ofertę</ButtonStyled>
                </div>
            )
        }
        return (
            <>
                <Oferta ogloszenieId={this.props.ogloszenie.ogloszenie.id} onWithdrawOferta={this.handleWithdrawOferta} />
                {this.state.submittingOfferConfirmationOn && this.renderSubmittingOfferConfirmationDialog()}
            </>
        )
    }

    renderBaseDialog(onClose, title, content) {
        return (
            <DialogCustom
                dialogTitle={title}
                onClose={onClose}
            >
                <>
                    {content}
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled primary onClick={onClose}>OK</ButtonStyled>
                    </GridCustom>
                </>
            </DialogCustom>
        )
    }

    renderWithdrawingOfferConfirmationDialog() {
        return this.renderBaseDialog(
            this.handleCloseWithdrawingOfferConfirmationDialog,
            'Wycofałeś ofertę.',
            (
                <GridCustom>
                    <Text>Możesz ją teraz usunąć lub edytować i ponownie złożyć.</Text>
                    <Text>Możesz również zrezygnować z edycji oferty i opuścić zakładkę.</Text>
                </GridCustom>
            )
        )
    }

    renderSubmittingOfferConfirmationDialog() {
        return this.renderBaseDialog(
            this.handleCloseSubmittingOfferConfirmationDialog,
            'Oferta została złożona.', null
        )
    }
}


export { OfertyOffererPublishedStatus };
