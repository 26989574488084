import React, { Component } from 'react';

import { dateToString } from '../../../helpers';
import {
    ButtonStyled,
    DateCalendar,
    FieldWithLabel,
    GridCustom,
    Select,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';


// TODO: get from backend
const OGLOSZENIE_STATUS_CHOICES = [
    ['all', 'Wszystkie'],
    ['published', 'Opublikowane'],
    ['closed', 'Zamknięte'],
    ['cancelled', 'Anulowane'],
    ['resolved', 'Rozstrzygnięte'],
];
const OFERTA_STATUS_CHOICES = [
    ['all', 'Wszystkie'],
    ['draft', 'Robocza'],
    ['submitted', 'Złożona'],
];


class FiltryOfert extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: props.filters,
        };
    }

    //handlers

    handleChangeFilter(name, value) {
        this.setState(prevState => {
            return {filters: Object.assign({}, prevState.filters, {[name]: value})}
        });
    }

    handleApplyFilters = () => {
        this.props.updateFilters(this.state.filters);
    }

    // rendering

    render() {
        const filters = this.state.filters;
        const submissionDeadlineInputRef = React.createRef();
        const announcementPublicationDateInputRef = React.createRef();
        return (
            <>
                <section className="filters-dialog__filters-section">
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel label="Tytuł ogłoszenia" tag="label" labelFor="filters_title_id">
                            <TextFieldCustom
                                clearFieldContext="tytuł ogłoszenia"
                                value={filters.title}
                                onChange={(ev) => this.handleChangeFilter('title', ev.target.value)}
                                className="filters__input"
                                onClear={(ev) => this.handleChangeFilter('title', '')}
                                id="filters_title_id" />
                        </FieldWithLabel>
                        <FieldWithLabel label="Numer ogłoszenia" tag="label" labelFor="filters_number_id">
                            <TextFieldCustom
                                clearFieldContext="numer ogłoszenia"
                                value={filters.announcementNumber}
                                onChange={(ev) => this.handleChangeFilter('announcementNumber', ev.target.value)}
                                className="filters__input"
                                onClear={(ev) => this.handleChangeFilter('announcementNumber', '')}
                                id="filters_number_id" />
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom flexM>
                        <FieldWithLabel label="Termin składania ofert" tag="label" inputRef={submissionDeadlineInputRef}>
                            <DateCalendar
                                dateName="Termin składania ofert"
                                parentRef={submissionDeadlineInputRef}
                                value={Date.parse(filters.submissionDeadline)}
                                onChange={date => this.handleChangeFilter('submissionDeadline', dateToString(date))} />
                        </FieldWithLabel>
                        <FieldWithLabel label="Data opublikowania ogłoszenia" tag="label" inputRef={announcementPublicationDateInputRef}>
                            <DateCalendar
                                dateName="Data opublikowania ogłoszenia"
                                parentRef={announcementPublicationDateInputRef}
                                value={Date.parse(filters.announcementPublicationDate)}
                                onChange={date => this.handleChangeFilter('announcementPublicationDate', dateToString(date))} />
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth flexM>
                        <FieldWithLabel
                            label="Status ogłoszenia"
                            tag="label"
                            selectRef={React.createRef()}>
                            <Select
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                className="select-custom"
                                options={OGLOSZENIE_STATUS_CHOICES.map(s => ({value: s[0], label: s[1]}))}
                                value={{value: filters.announcementStatus.value || OGLOSZENIE_STATUS_CHOICES[0][0], label: filters.announcementStatus.label || OGLOSZENIE_STATUS_CHOICES[0][1]}}
                                onChange={selectedOption => this.handleChangeFilter('announcementStatus', selectedOption)}
                                styles={customSelectStyles}
                            />
                        </FieldWithLabel>
                        <FieldWithLabel
                            label="Status oferty"
                            tag="label"
                            selectRef={React.createRef()}>
                            <Select
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                className="select-custom"
                                options={OFERTA_STATUS_CHOICES.map(s => ({value: s[0], label: s[1]}))}
                                value={{value: filters.offerStatus.value || OFERTA_STATUS_CHOICES[0][0], label: filters.offerStatus.label || OFERTA_STATUS_CHOICES[0][1]}}
                                onChange={selectedOption => this.handleChangeFilter('offerStatus', selectedOption)}
                                styles={customSelectStyles}
                            />
                        </FieldWithLabel>
                    </GridCustom>
                </section>
                <GridCustom flexEnd>
                    <ButtonStyled onClick={this.props.closeFilters} cancel>Anuluj</ButtonStyled>
                    <ButtonStyled onClick={this.handleApplyFilters} primary>Zastosuj kryteria</ButtonStyled>
                </GridCustom>
            </>
        )
    }
}


export { FiltryOfert, OFERTA_STATUS_CHOICES, OGLOSZENIE_STATUS_CHOICES };
