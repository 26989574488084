import React, { Component } from 'react';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

import { TOKEN_COOKIE_NAME } from '../../../const';
import { fetchSativaApi } from '../../../fetch';
import { deleteCookie, fillWithLeadingZero, refreshCookie } from '../../../helpers';
import { Text } from '../../common/Text';
import { UserContext } from '../../../context/user-context';
import { SessionDialog } from './SessionDialog';


class Sesja extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionTime: null,
            refreshSessionErrorDialogOn: false,
            logoutDialogOn: false,
            logoutDone: false,
            unauthorizedDialogOn:false,
        };

        this.xhrFetch = null;
        this.sessionIntervalId = null;
    }

    // basic functions

    componentDidMount() {
        if (this.context.auth.expire) {
            this.initializeSessionTime();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.sessionTime === this.state.sessionTime
                && this.state.sessionTime !== this.computeSessionTime()) {
            clearInterval(this.sessionIntervalId);
            this.sessionIntervalId = null;
            this.initializeSessionTime();
        }
    }

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }

        // abort interval if exist
        if (this.sessionIntervalId !== null) {
            clearInterval(this.sessionIntervalId)
            this.sessionIntervalId = null;
        }
    }

    // handlers

    handleRefreshSessionTime = () => {
        this.xhrFetch = fetchSativaApi(
            '/api/user',
            this.handleFetchSuccess,
            this.handleFetchError,
            this.handleFetchIncorrectStatus,
        );
    }

    handleFetchSuccess = (data) => {
        this.xhrFetch = null;
        this.context.refresh(data);
    }

    handleFetchError = (data) => {
        this.xhrFetch = null;
        this.setState({refreshSessionErrorDialogOn: true});
    }

    handleFetchIncorrectStatus = (status) => {
        this.xhrFetch = null;
        this.setState({refreshSessionErrorDialogOn: true});
    }

    handleSessionErrorDialogClose = () => {
        this.setState({refreshSessionErrorDialogOn: false});
    }

    handleSessionDialogClose = () => {
        this.setState({refreshSessionErrorDialogOn: false});
    }

    handleExpirationDialogClose = () => {
        // refresh page
        window.location.reload();
    }

    handleUnauthorizedDialogClose = () => {
        this.setState(
            {unauthorizedDialogOn: false},
            () => {
                deleteCookie(TOKEN_COOKIE_NAME);
                // refresh page
                window.location.reload();
            }
        );
    }

    // helpers

    initializeSessionTime = () => {
        const sessionTime = this.computeSessionTime();
        this.setState(
            {sessionTime},
            () => this.startSessionTime()
        );
    }

    computeSessionTime() {
        const now = new Date();
        const nowUTC = new Date(
            now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
            now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        return parseInt(this.context.auth.expire) - Math.floor(nowUTC.getTime() / 1000)
    }

    startSessionTime() {
        // refresh cookie expire
        refreshCookie(TOKEN_COOKIE_NAME, this.state.sessionTime);
        this.sessionIntervalId = window.setInterval(
            () => {this.setState(prevState => {
                if (prevState.sessionTime === 0) {
                    clearInterval(this.sessionIntervalId);
                    this.sessionIntervalId = null;
                    this.context.logout(this.expire)
                } else {
                    if (prevState.sessionTime - 1 === 0) {
                        return {sessionTime: 0, logoutDialogOn: true}
                    }
                    return {sessionTime: prevState.sessionTime - 1}
                }
            })},
            1000
        );
    }

    expire = () => {
        this.setState({logoutDone: true});
    }

    refreshTimerAfterRequest(authTokenCookieValue) {
        const now = new Date();
        const nowUTC = new Date(
            now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
            now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
        this.context.refreshTimer(
            Math.floor(nowUTC.getTime() / 1000) + 1800,
            () => {
                const sessionTime = this.computeSessionTime();
                refreshCookie(
                    TOKEN_COOKIE_NAME, sessionTime, authTokenCookieValue);
                this.setState({sessionTime});
            }
        );
    }

    showUnauthorizedMessage() {
        this.setState({unauthorizedDialogOn: true});
    }

    // rendering

    render() {
        const sessionTime = this.state.sessionTime;
        if (!sessionTime || sessionTime < 1) {
            if (this.state.logoutDialogOn) {
                return this.renderLogoutDialog()
            }
            return this.state.unauthorizedDialogOn ? this.renderUnauthorizedDialog() : null
        }

        const min = ~~(sessionTime / 60);
        const minRemaining = fillWithLeadingZero(min);
        const secRemaining = fillWithLeadingZero(sessionTime - min * 60);
        return (
            <div className="session-timer" role="timer">
                <Text id="session_time_remaining">{`${minRemaining}:${secRemaining}`}</Text>
                <Button
                    aria-label={`Do końca sesji pozostało ${minRemaining} minut ${secRemaining} sekund. Odśwież czas sesji.`}
                    className="btn--icon-only btn--primary"
                    onClick={this.handleRefreshSessionTime}
                    title="Odśwież czas sesji"
                    unelevated>
                    <span className="sr-only">Odśwież czas sesji</span>
                    <Icon aria-hidden="true" icon="watch_later" />
                </Button>
                {this.state.refreshSessionErrorDialogOn && this.renderRefreshSessionErrorDialog()}
                {this.state.unauthorizedDialogOn && this.renderUnauthorizedDialog()}
                {(sessionTime < 120) && this.renderRefreshSessionDialog(minRemaining, secRemaining)}
            </div>
        )
    }

    renderRefreshSessionErrorDialog() {
        return <SessionDialog
                   callback={this.handleSessionErrorDialogClose}
                   error
                   messageTitle="Nie udało się odświeżyć czasu sesji."
                   messageIcon="error_outline"
                   withButton />
    }

    renderRefreshSessionDialog(min, sec) {
        return <SessionDialog
                    callback={this.handleRefreshSessionTime}
                    messageTitle={`Do końca sesji pozostało ${min} minut ${sec} sekund.`}
                    messageIcon="error_outline"
                    messageRole="timer"
                    withButton
                    dialogButton="Przedłuż czas sesji" />
    }

    renderLogoutDialog() {
        if (this.state.logoutDone) {
            return <SessionDialog
                        callback={this.handleExpirationDialogClose}
                        messageContent="Aby uzyskać dostęp do widoków dla zarejestrowanych użytkowników, zaloguj się ponownie."
                        messageTitle="Użytkownik został wylogowany."
                        withButton={true} />
        }
        return <SessionDialog
                    callback={this.handleExpirationDialogClose}
                    messageTitle="Sesja wygasła. Trwa wylogowywanie..." />
    }

    renderUnauthorizedDialog() {
        return <SessionDialog
                    callback={this.handleUnauthorizedDialogClose}
                    messageTitle="Wystąpił problem z uwierzytelnieniem użytkownika."
                    withButton={true} />
    }
}


Sesja.contextType = UserContext;


export { Sesja };
