import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

import { Text } from '../../common';


class DraftToComponents extends Component {

    // helpers

    isEmptyString(str) {
        return str === undefined || str === null || str.length === 0 || str.trim().length === 0
    }

    isAtomicEntityBlock(block) {
        return block.entityRanges.length > 0 || block.type === 'atomic'
    }

    isList(blockType) {
        return blockType === 'unordered-list-item' || blockType === 'ordered-list-item';
    }

    // rendering

    render() {
        const { role } = this.props;

        return <section className="draft-to-components" role={role}>{this.renderContent()}</section>
    }

    renderContent() {
        const content = this.props.content;
        const blocks = content.blocks;
        if (!(blocks && blocks.length > 0)) { return null }
        let orderedListIndex = 0;
        return blocks.map(block => {
            switch (block.type) {
                case 'blockquote':
                    orderedListIndex = 0;
                    return this.renderBlockquote(block)
                case 'ordered-list-item':
                    orderedListIndex++;
                    return this.renderOrderedListItem(block, orderedListIndex)
                case 'unordered-list-item':
                    orderedListIndex = 0;
                    return this.renderUnorderedListItem(block)
                default:
                    orderedListIndex = 0;
                    if (this.isAtomicEntityBlock(block)) {
                        return this.renderAtomic(block, content.entityMap[block.entityRanges[0].key])
                    }
                    return this.renderText(block)
            }
        })
    }

    renderBlockquote(block) {
        return (
            <Typography key={block.key} tag="p" className="draft-to-components__blockquote">
                <Icon icon="format_quote" aria-hidden="true"/>
                {this.renderSections(block)}
            </Typography>
        )
    }

    renderOrderedListItem(block, index) {
        return <Text key={block.key} tag="ol" className="draft-to-components__ol">{index}. {this.renderSections(block)}</Text>
    }

    renderUnorderedListItem(block) {
        return <Text key={block.key} tag="ul" className="draft-to-components__ul">- {this.renderSections(block)}</Text>
    }

    renderAtomic(block, entity) {
        switch (entity.type) {
            case 'LINK':
                return this.renderLink(block, entity.data)
            case 'IMAGE':
                return this.renderImage(block, entity.data)
            case 'EMBEDDED_LINK':
                return this.renderEmbed(block, entity.data)
            default:
                return null
        }
    }

    renderLink(block, data) {
        return <a key={block.key} id={'a11y-url-' + block.key} href={data.url} className="link-text" target={data.targetOption || ''}>{this.renderSections(block)}</a>
    }

    renderImage(block, data) {
        return <img key={block.key} src={data.src} alt={data.alt || ''} width={data.width} height={data.height}/>
    }

    renderEmbed(block, data) {
        return <iframe key={block.key} width={data.width} height={data.height} src={data.src} frameBorder="0" title={block.key}></iframe>
    }

    renderText(block) {
        return <p key={block.key}>{this.renderSections(block)}</p>
    }

    renderSections(block) {
        if (!block.inlineStyleRanges.length) {
            return this.renderPureText(block.text, block.key)
        }
        const inlineStyleRanges = block.inlineStyleRanges.map(range => [range.offset, range.offset + range.length, range.style.toLowerCase()]);

        let charsWithStyles = [];
        let i = 0;
        for (let char of block.text) {
            let charStyles = new Set();
            for (let r of inlineStyleRanges) {
                if (i >= r[0] && i <= r[1]) {
                    charStyles.add(r[2]);
                }
            }
            charsWithStyles.push([char, [...charStyles].join('')]);
            i++;
        }

        let currentText = '';
        let currentStyle = '';
        let blockSections = [];
        for (let [char, style] of charsWithStyles) {
            if (style === currentStyle) {
                currentText += char;
            } else {
                blockSections.push([currentText, currentStyle]);
                currentText = char;
                currentStyle = style
            }
        }
        blockSections.push([currentText, currentStyle]);

        return blockSections.map((section, i) => {
            return this.renderSection(block.key + i, section)
        })
    }

    renderPureText(text, blockKey) {
        return text;
    }

    renderSection(key, section) {
        return <span key={key} style={styles[section[1]]}>{section[0]}</span>
    }
}


const styles = {
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
    bolditalic: {
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
    italicbold: {
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
    // ^ these styles should be doubled because it is difference if italic or bold is choosen first
};


export { DraftToComponents };
