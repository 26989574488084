import React from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Radio } from '@rmwc/radio';

import { EKRANOWA, EKRANY_DICT, OGOLNA } from '../../../const';
import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import {
    countWysiwygChars,
    extractYoutubeSrc,
    getAuthTokenCookieValue,
    refreshTimerAfterRequest
} from '../../../helpers';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { validateWysiwygEditor } from '../../../validators';
import {
    Breadcrumps,
    ButtonStyled,
    Container,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Text,
    TextFieldCustom,
    WysiwygEditor,
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { BaseComponent } from '../common';
import { TrescPreview } from './TrescPreview';


const EMPTY_VALUE = {
    value: 0,
    label: '--- Wybierz ---',
}
const SAVE_BUTTON_DEFAULT_LABEL = 'Zapisz pomoc';


class FormPomoc extends BaseComponent {

    // base functions

    componentDidMount() {
        this.fetchInitialData();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.xhrSaveFile !== null) {
            this.xhrSaveFile.abort();
        }
    }

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const serializedData = getSerializedObject(
            data, {nonRecursiveKeys: ['tresc', ]});
        if (this.state.isNew) {
            this.setState({
                initializingOn: false,
                availableParents: serializedData.pomoce.parents_candidates.data || [],
                availableRelatives: serializedData.pomoce.parents_candidates.data || [],
                availableScreens: serializedData.pomoce.screens_available || [],
            });
            return
        }
        const pomoc = serializedData.pomoc;
        const typOgolna = pomoc.typ === OGOLNA;
        const rozdzialNadrzedny = !pomoc.rozdzialNadrzedny ? null : {value: pomoc.rozdzialNadrzedny.id, label: pomoc.rozdzialNadrzedny.naglowek};
        this.setState(
            {
                initializingOn: false,
                ...Object.assign({}, pomoc, {
                    ekran: typOgolna ? EMPTY_VALUE : (
                        pomoc.ekran ? {value: pomoc.ekran, label: EKRANY_DICT[pomoc.ekran].label} : EMPTY_VALUE),
                    tresc: !Object.keys(pomoc.tresc).length ? '' : pomoc.tresc,
                    rozdzialNadrzedny,
                    powiazane: pomoc.powiazane.map(p => ({value: p.id, label: p.naglowek})),
                }),
                isRoot: pomoc.root || false,
                hasChildren: pomoc.has_children || false,
                originalTypOgolna: typOgolna,
                originalRozdzialNadrzedny: rozdzialNadrzedny === null ? null : rozdzialNadrzedny.value,
                typOgolna,
                availableParents: serializedData.slowniki.parents_candidates.data || [],
                availableRelatives: serializedData.slowniki.relatives_candidates.data || [],
                availableScreens: serializedData.slowniki.screens_available || [],
            },
            () => {
                // update url if slugs are not equal
                const urlParams = this.props.match.params;
                if (pomoc.slug !== urlParams.slug) {
                    this.props.history.push(`/zarzadzanie-trescia/pomoc/${urlParams.id}-${pomoc.slug}/edytuj`);
                }
            }
        );
    }

    handleSaveTypOgolna(ev) {
        this.setState({
            typOgolna: ev.currentTarget.value === 'true',
            ekran: EMPTY_VALUE,
            rozdzialNadrzedny: EMPTY_VALUE,
            powiazane: [],
        });
    }

    handleChangeAttribute(name, value) {
        this.setState(prevState => ({
            [name]: value,
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleClearAttributeError(name) {
        this.setState(prevState => ({
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleUploadFile(file) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);

            this.xhrSaveFile = new XMLHttpRequest();
            this.xhrSaveFile.open('POST', '/api/files?context=help');

            const authTokenCookieValue = getAuthTokenCookieValue();
            if (authTokenCookieValue) {
                this.xhrSaveFile.setRequestHeader(
                    'X-Auth-Token', authTokenCookieValue);
            }

            this.xhrSaveFile.onload = () => {
                refreshTimerAfterRequest(
                    authTokenCookieValue, this.xhrSaveFile.status);
                let success = true;
                let error;
                let jsonData = {};
                try {
                    jsonData = JSON.parse(this.xhrSaveFile.responseText);
                } catch(error) {
                    success = false;
                }

                if (success) {
                    if (jsonData.status === 'OK') {
                        resolve({data: {link: jsonData.data.file.uri}});
                        return
                    } else if (jsonData.errors) {
                        error = translateErrorMessage(jsonData.errors.file);
                    } else {
                        error = translateErrorMessage(jsonData.message);
                    }
                } else if (this.xhrSaveFile.status === 413) {
                    error = 'Wybrany plik jest zbyt duży. Maksymalny rozmiar pliku to 25 MB.';
                } else {
                    error = `Wystąpił nieoczekiwany błąd o kodzie ${this.xhrSaveFile.status}. Nie udało się dodać pliku.`;
                }
                this.setState({uploadImageError: error}, () => reject(error));
                this.xhrSaveFile = null;
            };

            this.xhrSaveFile.onerror = () => {
                this.xhrSaveFile = null;
                const error = 'Wystąpił nieoczekiwany błąd. Nie udało się dodać pliku.';
                this.setState({uploadImageError: error}, () => reject(error));
            };

            this.xhrSaveFile.send(formData);
        })
    }

    handleLoadEmbed(url) {
        const embeddedUrl = extractYoutubeSrc(url);
        if (!embeddedUrl) {
            this.setState({loadEmbedError: 'Podano niepoprawny url wideo.'});
            return
        }
        return embeddedUrl
    }

    handleSave() {
        this.setState(
            {savingOn: true, fetchSaveError: '', saveBtnText: 'Trwa zapisywanie...'},
            () => {
                const [isValid, errors] = this.validate();
                if (isValid) {
                    const { id, isNew } = this.state;
                    for (let block of this.state.tresc.blocks) {
						if(block.type === 'unstyled' && block.entityRanges.length){
							for (let entityRange of block.entityRanges){
								delete this.state.tresc.entityMap[entityRange.key];
							}
							block.entityRanges.length = 0;
						}
					}
                    // save pomoc on server
                    this.xhrFetch = fetchApi(
                        isNew
                            ? API_URLS.helpAdmin.path
                            : API_URLS.helpAdminDetails.getUrl(id),
                        isNew ? 'POST' : 'PUT',
                        {},
                        getSerializedObject(
                            {
                                id: id,
                                typ: this.state.typOgolna ? OGOLNA : EKRANOWA,
                                rozdzialNadrzedny: this.state.rozdzialNadrzedny ? this.state.rozdzialNadrzedny.value : null,
                                ekran: this.state.ekran.value,
                                naglowek: this.state.naglowek,
                                tresc: this.state.tresc,
                                powiazane: this.state.powiazane.map(p => p.value),
                                root: this.state.isRoot,
                            },
                            {toServer: true, nonRecursiveKeys: ['tresc', ]}
                        ),
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors, saveBtnText: SAVE_BUTTON_DEFAULT_LABEL});
                }
            }
        );
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetch = null;
        this.setState(
            prevState => ({
                isNew: false,
                savingOn: false,
                saveBtnText: 'Zapisano',
                id: getSerializedObject(data).pomoc.id,
                originalRozdzialNadrzedny: prevState.rozdzialNadrzedny === null ? null : prevState.rozdzialNadrzedny.value,
            }),
            () => {
                this.isNew = false;
                this.saveMsgTimeout = setTimeout(() => {
                    this.setState({saveBtnText: SAVE_BUTTON_DEFAULT_LABEL})
                }, 5000)
            }
        );
    }

    handleFetchSaveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            savingOn: false,
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: `Nie udało się zapisać pomocy. ${data.message}`,
        });
    }

    handleFetchSaveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 404) {
            this.setState({savingOn: false, pageNotFound: true});
        } else {
            let message = '';
            if (status === 403) {
                message = `Nie masz uprawnień do ${this.state.isNew
                    ? 'dodawania' : 'edycji'} pomocy.`;
                // update permission value in context
                this.removePermission();
            }
            this.setState({
                savingOn: false,
                saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
                fetchSaveError: message || `Podczas zapisu pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            });
        }
    }

    handlePreview() {
        if (this.state.ekran.value === 0) { return }
        const [isValid, errors] = this.validate();
        if (isValid) {
            this.setState({previewOn: true});
        } else {
            this.setState({errors});
        }
    }

    // helpers

    preState(props) {
        this.trescMaxLength = 5000;
    }

    getAdditionalState(props) {
        return {
            isNew: !props.editing,
            isRoot: false,
            hasChildren: false,
            previewOn: false,
            originalTypOgolna: null,
            originalRozdzialNadrzedny: 0,
            id: null,
            typOgolna: null,
            rozdzialNadrzedny: EMPTY_VALUE,
            ekran: EMPTY_VALUE,
            naglowek: '',
            tresc: '',
            powiazane: [],
            savingOn: false,
            errors: {
                ekran: '',
                naglowek: '',
            },
            uploadImageError: '',
            loadEmbedError: '',
            fetchSaveError: '',
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            availableParents: [],
            availableRelatives: [],
            availableScreens: [],
        };
    }

    postState(props) {
        this.xhrSaveFile = null;
    }

    setAdditionalHandlers() {
        this.handleSaveTypOgolna = this.handleSaveTypOgolna.bind(this);
        this.handleUploadFile = this.handleUploadFile.bind(this);
        this.handleLoadEmbed = this.handleLoadEmbed.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleFetchSaveSuccess = this.handleFetchSaveSuccess.bind(this);
        this.handleFetchSaveError = this.handleFetchSaveError.bind(this);
        this.handleFetchSaveIncorrectStatus = this.handleFetchSaveIncorrectStatus.bind(this);
        this.handlePreview = this.handlePreview.bind(this);
    }

    getFetchUrl() {
        if (this.state.isNew) {
            return API_URLS.helpAdminDictionaries.path
        }
        return API_URLS.helpAdminDetails.getUrl(this.props.match.params.id)
    }

    getFetchData() {
        if (this.state.isNew) {
            return {}
        }
        return {dictionary: 'auto_determining'}
    }

    getFetchError(message) {
        if (this.state.isNew) {
            return `Nie udało się pobrać danych do formularza pomocy. ${message}`
        }
        return `Nie udało się pobrać pomocy. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        if (this.state.isNew) {
            return `Podczas pobierania danych do formularza pomocy wystąpił nieoczekiwany błąd o kodzie ${status}`
        }
        return `Podczas pobierania pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.removePermission();
    }

    removePermission() {
        this.context.removePermission('help');
    }

    validate() {
        let isValid = true;
        const {
            isRoot,
            naglowek,
            rozdzialNadrzedny,
            tresc,
            typOgolna,
        } = this.state;
        const requiredMsg = 'To pole jest wymagane.';
        let errors = {};

        // validateTyp
        if (![true, false].includes(typOgolna)) {
            errors['typOgolna'] = requiredMsg;
            isValid = false;
        }

        // validate rozdzialNadrzedny
        if (!isRoot && typOgolna === true && rozdzialNadrzedny.value === 0) {
            errors['rozdzialNadrzedny'] = requiredMsg;
            isValid = false;
        }

        // validate naglowek
        if (!naglowek.length) {
            errors['naglowek'] = requiredMsg;
            isValid = false;
        }

        // validate tresc
        if (!validateWysiwygEditor(tresc)) {
            errors['tresc'] = requiredMsg;
            isValid = false;
        } else if (countWysiwygChars(tresc) > this.trescMaxLength) {
            errors['tresc'] = `To pole może mieć maksymalnie ${this.trescMaxLength} znaków.`;
            isValid = false;
        }

        return [isValid, errors]
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps>
                <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/pomoc'>Pomoc</Link></li>
                <li className="breadcrumps__current">{this.state.isNew ? 'Dodaj' : 'Edytuj'} pomoc</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        const {
            ekran,
            isNew,
            loadEmbedError,
            naglowek,
            previewOn,
            savingOn,
            tresc,
            typOgolna,
            uploadImageError,
        } = this.state;
        const typeChosen = [true, false].includes(typOgolna);
        return (
            <>
                <Text tag="h2" mainHeader>{this.state.isNew ? 'Dodaj' : 'Edytuj'} pomoc</Text>
                <Container className="container-with-wysiwyg">
                    <GridCustom fullwidth>
                        <fieldset>
                            <Text tag="legend" className="label">Typ pomocy</Text>
                            {!isNew && <Text info>Podczas edycji pomocy nie jest możliwa zmiana jej typu.</Text>}
                            <Radio
                                checked={typOgolna === true}
                                disabled={!isNew || savingOn}
                                name="typRadioGroup"
                                value={true}
                                onChange={this.handleSaveTypOgolna}
                                label="Ogólna"
                            />
                            <Radio
                                checked={typOgolna === false}
                                disabled={!isNew || savingOn}
                                name="typRadioGroup"
                                value={false}
                                onChange={this.handleSaveTypOgolna}
                                label="Na ekranie aplikacji"
                            />
                        </fieldset>
                    </GridCustom>
                    {typOgolna === true && this.renderTypOgolnaForm()}
                    {typOgolna === false && this.renderTypNaEkranieForm()}
                    {typeChosen && this.renderCommonFields()}
                    {typOgolna === true && this.renderPowiazaneForm()}
                    {typeChosen && this.renderButtons(typOgolna)}
                </Container>
                {uploadImageError.length > 0 && this.renderUploadImageErrorDialog()}
                {loadEmbedError.length > 0 && this.renderLoadEmbedErrorDialog()}
                {previewOn && (
                    <TrescPreview
                        content={{naglowek, tresc}}
                        screen={EKRANY_DICT[ekran.value].id}
                        closePreview={() => this.setState({previewOn: false})}
                    />
                )}
            </>
        )
    }

    renderTypOgolnaForm() {
        const {
            errors,
            hasChildren,
            isRoot,
            originalRozdzialNadrzedny,
            originalTypOgolna,
            rozdzialNadrzedny,
            savingOn,
            typOgolna
        } = this.state;
        const isRozdzialNadrzednyInvalid = (errors.rozdzialNadrzedny || '').length > 0;
        return (
            <FieldWithLabel label="Rozdział nadrzędny">
                {isRoot && (
                    <Text info>To pierwsza pomoc dodana do serwisu, więc nie da się dla niej wybrać rozdziału nadrzędnego.</Text>
                )}
                {!isRoot && typOgolna && originalTypOgolna && rozdzialNadrzedny.value !== originalRozdzialNadrzedny && hasChildren && (
                    <Text info>Uwaga! Dla edytowanej pomocy istnieją pomoce podrzędne, zmiana rozdziału nadrzędnego spowoduje przepięcie pod wskazany rozdział całej gałęzi.</Text>
                )}
                <Select
                    aria-describedby={isRozdzialNadrzednyInvalid ? 'rozdzialNadrzedny_error' : null}
                    className={isRozdzialNadrzednyInvalid ? 'select-custom select-custom--invalid' : 'select-custom'}
                    isDisabled={isRoot || savingOn}
                    options={[EMPTY_VALUE, ...this.state.availableParents.map(p => ({value: p.id, label: p.naglowek}))]}
                    placeholder='Wybierz...'
                    value={rozdzialNadrzedny}
                    onChange={(selectedOption) => this.handleChangeAttribute('rozdzialNadrzedny', selectedOption)}
                    styles={customSelectStyles}
                />
                {isRozdzialNadrzednyInvalid && <Text error id="rozdzialNadrzedny_error">{errors.rozdzialNadrzedny}</Text>}
            </FieldWithLabel>
        )
    }

    renderTypNaEkranieForm() {
        const {
            availableScreens,
            ekran,
            errors,
            savingOn
        } = this.state;
        const isEkranInvalid = (errors.ekran || '').length > 0;
        return (
            <FieldWithLabel label="Ekran">
                {!availableScreens.length && <Text info>Wszystkie ekrany są aktualnie wykorzystane w innych pomocach. Możesz odpiąć ekran od istniejącej pomocy, żeby ustawić go w nowej pomocy.</Text>}
                <Select
                    aria-describedby={isEkranInvalid ? 'ekran_error' : null}
                    className={isEkranInvalid ? 'select-custom select-custom--invalid' : 'select-custom'}
                    isDisabled={savingOn}
                    options={[EMPTY_VALUE, ].concat(...availableScreens.map(s => {
                        return {value: s, label: EKRANY_DICT[s].label}
                    }))}
                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                    value={ekran}
                    onChange={(selectedOption) => this.handleChangeAttribute('ekran', selectedOption)}
                    styles={customSelectStyles}
                />
                {isEkranInvalid && <Text error id="ekran_error">{errors.ekran}</Text>}
            </FieldWithLabel>
        )
    }

    renderCommonFields() {
        const {
            errors,
            savingOn,
            naglowek,
            tresc,
        } = this.state;
        const isNaglowekInvalid = (errors.naglowek || '').length > 0;
        const isTrescInvalid = (errors.tresc || '').length > 0;
        return (
            <>
                <FieldWithLabel label="Nagłówek">
                    <TextFieldCustom
                        aria-describedby={isNaglowekInvalid ? 'naglowek_error' : null}
                        characterCount
                        clearFieldContext="nagłówek"
                        disabled={savingOn}
                        invalid={isNaglowekInvalid}
                        fullwidth
                        maxLength={300}
                        value={naglowek}
                        onClear={(ev) => this.handleChangeAttribute('naglowek', '')}
                        onChange={(ev) => this.handleChangeAttribute('naglowek', ev.target.value)}
                        onFocus={(ev) => this.handleClearAttributeError('naglowek')} />
                    {isNaglowekInvalid && <Text error id="naglowek_error">{errors.naglowek}</Text>}
                </FieldWithLabel>
                <FieldWithLabel label="Treść" className="wysiwyg-container">
                    <WysiwygEditor
                        invalid={isTrescInvalid}
                        initialContentState={tresc}
                        maxLength={this.trescMaxLength}
                        toolbar={{
                            options: ['inline', 'list', 'blockType', 'link', 'image', 'embedded'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic'],
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                            blockType: {
                                inDropdown: false,
                                options: ['Blockquote', ],
                            },
                            link: { inDropdown: false },
                            image: {
                                urlEnabled: false,
                                uploadEnabled: true,
                                alignmentEnabled: false,
                                uploadCallback: this.handleUploadFile,
                                previewImage: true,
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                alt: {present: true, mandatory: true},
                                defaultSize: {
                                    height: 'auto',
                                    width: 500,
                                },
                            },
                            embedded: {
                                defaultSize: {
                                    height: 300,
                                    width: 500,
                                },
                                embedCallback: this.handleLoadEmbed
                            },
                        }}
                        localization={{ locale: 'pl' }}
                        onContentStateChange={(contentState) => this.handleChangeAttribute('tresc', contentState)}
                    />
                    {isTrescInvalid && <Text error>{errors.tresc}</Text>}
                </FieldWithLabel>
            </>
        )
    }

    renderPowiazaneForm() {
        const optionsForPowiazane = this.state.availableRelatives.map(r => ({value: r.id, label: r.naglowek}));
        const isRoot = this.state.isRoot;
        return (
            <GridCustom fullwidth>
                <FieldWithLabel label="Powiązane">
                    {(isRoot && <Text info>To pierwsza pomoc dodana do serwisu, więc nie da się dla niej wybrać powiązanych.</Text>) || (!optionsForPowiazane.length && <Text info>Nie ma jeszcze innych pomocy do ustawienia jako powiązane.</Text>)}
                    <Select
                        className={'select-custom'}
                        closeMenuOnSelect={false}
                        defaultValue={this.state.powiazane}
                        isClearable={false}
                        isDisabled={this.state.isRoot || !optionsForPowiazane.length || this.state.savingOn}
                        isMulti
                        isSearchable
                        options={optionsForPowiazane}
                        placeholder='Wybierz...'
                        screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                        onChange={selectedOptions => this.handleChangeAttribute(
                            'powiazane', selectedOptions === null ? [] : selectedOptions
                        )}
                        styles={customSelectStyles}
                    />
                </FieldWithLabel>
            </GridCustom>
        )
    }

    renderUploadImageErrorDialog() {
        return this.renderErrorDialog('uploadImageError')
    }

    renderLoadEmbedErrorDialog() {
        return this.renderErrorDialog('loadEmbedError')
    }

    renderErrorDialog(errorName) {
        // TODO: after close wysiwyg toolbar component hides and it's impossible to show error in the component.
        // https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/controls/Image/Component/index.js#L138
        return (
            <DialogCustom
                dialogTitleError={this.state[errorName]}
                style={{zIndex: 100}}
                onClose={() => this.setState({[errorName]: ''})}
            >
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled onClick={() => this.setState({ [errorName]: '' })} primary>OK</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }

    renderButtons(typOgolna) {
        const {
            ekran,
            fetchSaveError,
            saveBtnText,
            savingOn
        } = this.state;
        return (
            <>
                <GridCustom fullwidth={typOgolna === false} flexEnd={typOgolna === true}>
                    {typOgolna === false && <ButtonStyled disabled={ekran.value === 0 || savingOn} primary onClick={this.handlePreview}>Podgląd na ekranie</ButtonStyled>}
                    <ButtonStyled disabled={savingOn} save onClick={this.handleSave}>{saveBtnText}</ButtonStyled>
                </GridCustom>
                {fetchSaveError.length > 0 && <Text error>{fetchSaveError}</Text>}
            </>
        )
    }
}

FormPomoc.contextType = UserContext;

export { FormPomoc };
