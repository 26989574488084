import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox } from '@rmwc/checkbox';

import {
    checkSinglePermission,
    getGroupChecked,
    getGroupIndeterminate,
    getMainGroupIndeterminate,
    getPermissionErrors,
    getPermissionsValuesDict,
    sortKeysList,
    translatePermissionName
} from '../../../helpers_permissions';
import { usePutFormFetchApi } from '../../../hooks';
import {
    CancelSaveButtonsRow,
    FetchErrorWithOkayButton,
    FieldWithLabel,
    Text
} from '../../common';


export const UserPermissionsFormCommonFields = ({
    additionalFetchData,
    checksSortDict,
    defaultChecksDict,
    disabled, disabledCancelButton, disabledSaveButton,
    editionModeOn,
    existingPermissionData,
    existsErrorOn, existsErrorLabel,
    isImplementationLevelType,
    permissions,
    saveUrl,
    savingOn,
    setAdditionalErrors,
    setEditionModeOn,
    setExistsErrorOn,
    userId,
    onCancel,
    onSavePermissionSuccess,
    onSetSavingOn
}) => {
    const [checks, setChecks] = useState({...defaultChecksDict});

    additionalFetchData = additionalFetchData || {};

    const { fetchError, errors, saveBtnText } = usePutFormFetchApi({
        url: saveUrl,
        fetchData: {permission: {
            authorized: parseInt(userId),
            ...checks,
            ...additionalFetchData
        }},
        callbackSuccess: onSavePermissionSuccess,
        callbackObjectExists: () => setExistsErrorOn(true),
        loadingOn: savingOn,
        setLoadingOn: onSetSavingOn,
        method: editionModeOn ? 'PUT' : 'POST',
        defaultSaveBtnText: "Zapisz uprawnienia",
        doNotTranslateNames: true,
        message403error: 'Nie masz uprawnień do zapisu wybranych uprawnień. ' +
            'Jeśli uważasz, że to pomyłka, odśwież stronę i spróbuj jeszcze raz.',
    });

    const additionalFetchDataValuesAsString = Object.values(
        additionalFetchData).join('');
    function updateExistErrorInParent() {
        setExistsErrorOn(false);
    }
    useEffect(updateExistErrorInParent, [additionalFetchDataValuesAsString]);

    function updateAdditionalErrorsInParent() {
        if (!setAdditionalErrors) { return }
        setAdditionalErrors(errors);
    }
    useEffect(updateAdditionalErrorsInParent, [errors]);

    function handleCheckAll(ev) {
        const checked = ev.target.checked;
        setChecks(prevValue => {
            let dct = {};
            for (let groupName of Object.keys(prevValue)) {
                dct = {
                    ...dct,
                    ...getPermissionsValuesDict(
                        defaultChecksDict, groupName, checked),
                };
            }
            return dct
        });
    }

    function handleCheckGroup(ev, groupName) {
        const checked = ev.target.checked;
        setChecks(prevChecks => ({
            ...prevChecks,
            ...getPermissionsValuesDict(defaultChecksDict, groupName, checked),
        }));
    }

    function handleCheckSinglePermission(checked, parent, child) {
        setChecks(prevChecks => ({
            ...prevChecks,
            ...checkSinglePermission(prevChecks, parent, child, checked),
        }));
    }

    function handleLoadExistedPermissionData() {
        setChecks(existingPermissionData !== null
            ? existingPermissionData : defaultChecksDict
        );
        setExistsErrorOn(false);
        setEditionModeOn(true);
    }

    let groupCheckedValues = {};
    for (let groupName of Object.keys(checks)) {
        groupCheckedValues[groupName] = getGroupChecked(
            checks, groupName);
    }

    const permissionErrors = getPermissionErrors(errors);

    // These values say if checkboxes have to be disabled
    let functionalityDisabled = false;
    let permissionsDisabled = false;
    if (permissions) {
        functionalityDisabled = !permissions.functionality;
        permissionsDisabled = !permissions.permissions;
    }

    function checkDisabledGroup(groupName) {
        if (groupName === 'permissionsAdministration') {
            return permissionsDisabled
        }
        return functionalityDisabled
    }

    return (
        <>
            {existsErrorOn && !editionModeOn && (
                <FetchErrorWithOkayButton
                    buttonLabel="wczytaj i edytuj zapisane uprawnienia"
                    infoMsg
                    message={`Istnieje pakiet uprawnień dla wybranych powyżej danych. Zmień ${existsErrorLabel || 'wybór'} lub`}
                    onClickOkay={handleLoadExistedPermissionData} />
            )}
            <FieldWithLabel label="Uprawnienia" tag="label">
                {permissionErrors.length > 0 && permissionErrors.map(
                    (e, i) => <Text error key={i}>{e}</Text>
                )}
                <div className="permissions__level-main">
                    <Checkbox
                        checked={!Object.values(groupCheckedValues).includes(false)}
                        disabled={
                            functionalityDisabled || permissionsDisabled ||
                            disabled || existsErrorOn
                        }
                        indeterminate={getMainGroupIndeterminate(checks)}
                        label={translatePermissionName('all')}
                        onChange={handleCheckAll} />
                </div>

                {sortKeysList(Object.entries(checks), checksSortDict.groups).map(
                    ([groupName_, groupPermissions]) => {
                        const groupDisabled = checkDisabledGroup(groupName_);
                        return (
                            <Fragment key={groupName_}>
                                <div className="permissions__level-1">
                                    <Checkbox
                                        checked={groupCheckedValues[groupName_] || false}
                                        disabled={
                                            groupDisabled || disabled ||
                                            existsErrorOn
                                        }
                                        indeterminate={getGroupIndeterminate(
                                            checks, groupName_)}
                                        label={`${translatePermissionName(groupName_)} - pełne uprawnienia`}
                                        onChange={ev => handleCheckGroup(ev, groupName_)} />
                                </div>
                                {sortKeysList(
                                    Object.entries(groupPermissions),
                                    checksSortDict[groupName_]
                                ).map(
                                    ([permissionName_, permissionValue]) => (
                                    <Fragment key={permissionName_}>
                                        <div className="permissions__level-2">
                                            <Checkbox
                                                checked={checks[groupName_][permissionName_]}
                                                disabled={
                                                    groupDisabled || disabled ||
                                                    existsErrorOn
                                                }
                                                label={translatePermissionName(
                                                    isImplementationLevelType && permissionName_ === 'functionalityManagement'
                                                    ? 'functionalityManagementImplementationLevel'
                                                    : permissionName_
                                                )}
                                                onChange={ev => handleCheckSinglePermission(
                                                    ev.target.checked, groupName_, permissionName_
                                                )} />
                                        </div>
                                    </Fragment>
                                ))}
                            </Fragment>
                        )
                    }
                )}
            </FieldWithLabel>
            <CancelSaveButtonsRow
                disabled={disabledCancelButton}
                disabledSave={disabledSaveButton}
                saveText={saveBtnText}
                onCancel={onCancel}
                onSave={() => onSetSavingOn(true)} />
            {fetchError !== null && <Text error>{fetchError}</Text>}
            {existsErrorOn && editionModeOn && (
                <FetchErrorWithOkayButton
                    buttonLabel="OK"
                    message="Próbujesz edytować nieistniejący zestaw uprawnień. Odśwież stronę z listą uprawnień i spróbuj ponownie."
                    onClickOkay={onCancel} />
            )}
        </>
    )
};
