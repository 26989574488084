import React from 'react';
import { Link } from 'react-router-dom';
import { Text as PdfText, StyleSheet } from '@react-pdf/renderer';

import { UserContext } from '../../../context/user-context';
import { generatePdfDocument, serializeAuditData } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    AuditData,
    Breadcrumps,
    ButtonCreatePdf,
    Container,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';
import { PdfWithDraftContent } from './PdfWithDraftContent';


class Regulamin extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const regulamin = getSerializedObject(
            data,
            {
                nonRecursiveKeys: ['tresc', ],
                dateAttributes: ['dataOpublikowania', ],
            }
        ).regulaminy;
        if (!regulamin) {
            this.setState({initializingOn: false, pageNotFound: true});
        } else {
            this.setState({
                initializingOn: false,
                tresc: regulamin.tresc,
                dataOpublikowania: regulamin.dataOpublikowania,
                auditData: serializeAuditData(regulamin),
            });
        }
    }

    handleCreatePdf = () => {
        this.setState(
            {pdfCreatingOn: true},
            () => {
                if (this.props.current) {
                    generatePdfDocument(
                        <PdfWithDraftContent
                            renderHeader={() => <PdfText style={styles.pdfHeaderStyle}>Regulamin</PdfText>}
                            content={this.state.tresc}
                            renderFooter={() => <PdfText>Data ostatniej aktualizacji: {this.state.dataOpublikowania}.</PdfText>}
                        />,
                        'regulamin.pdf',
                        this.callbackPfdCreated
                    );
                } else {
                    generatePdfDocument(
                        <PdfWithDraftContent content={this.state.tresc} title='Wersja "Do opublikowania" regulaminu' />,
                        'regulamin_do_opublikowania.pdf',
                        this.callbackPfdCreated
                    );
                }
            }
        );
    }

    // helpers

    getAdditionalState(props) {
        return {
            tresc: '',
            dataOpublikowania: '',
            pdfCreatingOn: false,
            auditData: {},
        }
    }

    getFetchUrl() {
        return API_URLS[
            this.props.front ? 'regulations' : 'regulationsAdmin'
        ].path
    }

    getFetchData() {
        let query = {};
        if (!this.props.current) {
            query['version'] = 'for_publication';
        }
        return query
    }

    getFetchError(message) {
        return `Nie udało się pobrać regulaminu. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania regulaminu wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callbackPfdCreated = () => {
        this.setState({pdfCreatingOn: false});
    }

    callback403 = () => {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps isFront={this.props.front}>
                { this.props.front ? (
                    <li className="breadcrumps__current">Regulamin</li>
                ) : (
                    <>
                        <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/regulaminy'>Regulamin</Link></li>
                        <li className="breadcrumps__current">{this.props.current ? 'Szczegóły wersji aktualnej' : 'Szczegóły wersji "Do opublikowania"'}</li>
                    </>
                )}
            </Breadcrumps>
        )
    }

    renderContent() {
        document.title = "Baza Konkurencyjności - regulamin";
        const dataOpublikowania = this.state.dataOpublikowania;

        if (this.props.current) {
            return (
                <>
                    <GridCustom fullwidth centerVertical flexS>
                        <Text tag="h2" mainHeader>Regulamin</Text>
                            {this.renderPdfButton()}
                    </GridCustom>
                    <Container className="separate-container">
                        <DraftToComponents content={this.state.tresc} />
                    </Container>
                    <FieldWithLabel label="Data ostatniej aktualizacji">
                        <Text>{dataOpublikowania}</Text>
                    </FieldWithLabel>
                    <AuditData {...this.state.auditData} />
                </>
            )
        }
        return (
            <>
                <GridCustom fullwidth centerVertical flexS>
                    <Text tag="h2" mainHeader>Regulamin<Text info>wersja "Do opublikowania"</Text></Text>
                    <GridCustom flexEnd>
                        {this.renderPdfButton()}
                    </GridCustom>
                </GridCustom>
                <Container className="separate-container">
                    <DraftToComponents content={this.state.tresc} />
                </Container>
                <FieldWithLabel label="Data opublikowania">
                    <Text>{dataOpublikowania}</Text>
                </FieldWithLabel>
                <AuditData {...this.state.auditData} />
            </>
        )
    }

    renderPdfButton() {
        let pdfCreatingButtonLabel;

        if (this.state.pdfCreatingOn) {
            if (this.props.current) {
                pdfCreatingButtonLabel = 'Tworzenie pliku pdf z regulaminem...'
            } else {
                pdfCreatingButtonLabel = 'Tworzenie pliku pdf z regulaminem w wersji "Do opublikowania"...'
            }
        } else {
            if (this.props.current) {
                pdfCreatingButtonLabel = 'Pobierz plik pdf z regulaminem'
            } else {
                pdfCreatingButtonLabel = 'Pobierz plik pdf z regulaminem w wersji "Do opublikowania"'
            }
        }

        return (
            <ButtonCreatePdf
                pdfCreatingOn={this.state.pdfCreatingOn}
                onClick={this.handleCreatePdf}>{pdfCreatingButtonLabel}</ButtonCreatePdf>
        )
    }
}

const styles = StyleSheet.create({
    pdfHeaderStyle: {
        lineHeight: 1.6,
        marginBottom: 5,
        fontSize: 16,
        fontWeight: 'bold'
    },
})

Regulamin.contextType = UserContext;

export { Regulamin };
