import React from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { countWysiwygChars } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    Breadcrumps,
    ButtonStyled,
    Container,
    GridCustom,
    Text,
    WysiwygEditor,
} from '../../common';
import { BaseComponent } from '../common';
import { TrescPreview } from './TrescPreview';


const SAVE_BUTTON_DEFAULT_LABEL = 'Zapisz treść';


class FormTrescOgolna extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const trescOgolna = getSerializedObject(
            data, {nonRecursiveKeys: ['tresc', ]}).trescOgolna;
        this.setState({
            initializingOn: false,
            id: parseInt(this.props.match.params.id),
            naglowek: trescOgolna.naglowek,
            tresc: Object.keys(trescOgolna.tresc).length > 0 ? trescOgolna.tresc : '',
        });
    }

    handleChangeAttribute(name, value) {
        this.setState(prevState => ({
            [name]: value,
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleSave() {
        this.setState(
            {savingOn: true, fetchSaveError: '', saveBtnText: 'Trwa zapisywanie...'},
            () => {
                const [isValid, errors] = this.validate();
                if (isValid) {
                    const id_ = this.props.match.params.id;
                    // save trescOgolna on server
                    this.xhrFetch = fetchApi(
                        API_URLS.generalContentAdminDetails.getUrl(id_),
                        'PUT',
                        {},
                        getSerializedObject(
                            {id: id_, tresc: this.state.tresc},
                            {toServer: true, nonRecursiveKeys: ['tresc', ]},
                        ),
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors, saveBtnText: SAVE_BUTTON_DEFAULT_LABEL});
                }
            }
        );
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetchSave = null;
        this.setState(
            {
                savingOn: false,
                saveBtnText: 'Zapisano',
            },
            () => {
                this.saveMsgTimeout = setTimeout(() => {
                    this.setState({saveBtnText: SAVE_BUTTON_DEFAULT_LABEL})
                }, 5000)
            }
        );
    }

    handleFetchSaveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            savingOn: false,
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: `Nie udało się zapisać treści ogólnej. ${data.message}`,
        });
    }

    handleFetchSaveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 404) {
            this.setState({savingOn: false, pageNotFound: true});
        } else {
            let message = '';
            if (status === 403) {
                message = 'Nie masz uprawnień do edycji treści ogólnych.';
                // update permission value in context
                this.removePermission();
            }
            this.setState({
                savingOn: false,
                saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
                fetchSaveError: message || `Podczas zapisu treści ogólnej wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            });
        }
    }

    // helpers

    preState(props) {
        this.trescMaxLength = 2000;
    }

    getAdditionalState(props) {
        return {
            previewOn: false,
            savingOn: false,
            id: '',
            naglowek: '',
            tresc: '',
            errors: {
                tresc: '',
            },
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: '',
        }
    }

    setAdditionalHandlers() {
        this.handleSave = this.handleSave.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleFetchSaveSuccess = this.handleFetchSaveSuccess.bind(this);
        this.handleFetchSaveError = this.handleFetchSaveError.bind(this);
        this.handleFetchSaveIncorrectStatus = this.handleFetchSaveIncorrectStatus.bind(this);
    }

    getFetchUrl() {
        return API_URLS.generalContentAdminDetails.getUrl(
            this.props.match.params.id)
    }

    validate() {
        let isValid = true;
        let errors = {};

        // validate tresc
        if (countWysiwygChars(this.state.tresc) > this.trescMaxLength) {
            errors['tresc'] = `To pole może mieć maksymalnie ${this.trescMaxLength} znaków.`;
            isValid = false;
        }

        return [isValid, errors]
    }

    callback403 = () => {
        this.removePermission();
    }

    removePermission() {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/tresci-ogolne'>Treści ogólne</Link></li>
                    <li className="breadcrumps__current">Edytuj {this.state.naglowek}</li>
                </Breadcrumps>
                <Text tag="h2" mainHeader>Edycja: {this.state.naglowek}</Text>
            </>
        )
    }

    renderContent() {
        const {
            errors,
            fetchSaveError,
            id,
            naglowek,
            previewOn,
            saveBtnText,
            savingOn,
            tresc,
        } = this.state;
        const isTrescInvalid = (errors.tresc || '').length > 0;
        return (
            <>
                <Container className="wysiwyg-wrapper">
                    <WysiwygEditor
                        disabled={savingOn}
                        invalid={isTrescInvalid}
                        initialContentState={tresc}
                        localization={{ locale: 'pl' }}
                        maxLength={this.trescMaxLength}
                        wysiwygClassName="wysiwyg__wrapper-with-toolbar"
                        toolbar={{
                            options: ['inline', 'list', 'blockType'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic'],
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                            blockType: {
                                inDropdown: false,
                                options: ['Blockquote', ],
                            },
                        }}
                        onContentStateChange={(contentState) => this.handleChangeAttribute('tresc', contentState)}
                    />
                    {isTrescInvalid && <Text error>{errors.tresc}</Text>}
                    <GridCustom fullwidth>
                        <ButtonStyled disabled={savingOn} primary onClick={() => this.setState({previewOn: true})}>Podgląd na ekranie</ButtonStyled>
                        <ButtonStyled disabled={savingOn} save onClick={this.handleSave}>{saveBtnText}</ButtonStyled>
                    </GridCustom>
                    {fetchSaveError.length > 0 && <Text error role="alert">{fetchSaveError}</Text>}
                </Container>
                {previewOn && (
                    <TrescPreview
                        content={{id, naglowek, tresc}}
                        screen='homePage'
                        closePreview={() => this.setState({previewOn: false})}
                    />
                )}
            </>
        )
    }
}

FormTrescOgolna.contextType = UserContext;

export { FormTrescOgolna };
