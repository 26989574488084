import React from 'react';
import FocusTrap from 'focus-trap-react';
import { Dialog, DialogContent, DialogTitle } from '@rmwc/dialog';

const DialogCustom = (props) => {
    const {
        ariaLabelledby,
        children,
        dialogRef,
        dialogTitle,
        dialogTitleError,
        onClose,
        onKeyDown,
        ...otherProps
    } = props;

    let title = null;

    if (dialogTitle) {
        title = <DialogTitle id={ariaLabelledby || null}>{dialogTitle}</DialogTitle>;
    }

    if (dialogTitleError) {
        title = <DialogTitle id={ariaLabelledby || null} className="dialog-error">{dialogTitleError}</DialogTitle>;
    }

    function handleKeyDown(ev) {
        if (onKeyDown) {
            onKeyDown(ev);
        } else if (ev.key === 'Escape') {
            onClose();
        }
    }

    return (
        <>
            <Dialog
                aria-labelledby={ariaLabelledby || null}
                aria-modal="true"
                open
                preventOutsideDismiss={true}
                role="dialog"
                ref={dialogRef || null}
                onClose={onClose}
                onKeyDown={handleKeyDown}
                {...otherProps}
            >
                <FocusTrap focusTrapOptions={{
                    fallbackFocus: '#dialogContainer'
                }}>
                    <div id="dialogContainer" tabIndex="-1">
                        <div tabIndex={0}>{title}</div>
                        <DialogContent>
                            {children}
                        </DialogContent>
                    </div>
                </FocusTrap>
            </Dialog>
        </>
    )
}


export { DialogCustom };
