import React, { Component } from 'react';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { DrawerAppContent } from '@rmwc/drawer';
import { Icon } from '@rmwc/icon';
import { TopAppBar, TopAppBarFixedAdjust, TopAppBarActionItem, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@rmwc/top-app-bar';
import '@material/elevation/dist/mdc.elevation.css';
import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';

import { TOKEN_COOKIE_NAME } from '../const';
import { MenuProvider } from '../context/menuContext';
import { UserContext } from '../context/user-context';
import { fetchApi, fetchSativaApi } from '../fetch';
import { deleteCookie, getCookieValue, redirectToLoginView, setFocusOnElement } from '../helpers';
import { URLS } from '../urls/frontend';
import { MenuDrawer, SkipLinks, Text } from './common';
import { Sesja, SessionDialog } from './layoutElements/uzytkownicy';
import { CiasteczkaFront, ZoltyPasekFront } from './layoutElements/zarzadzanieTrescia';
import { Routes } from './Routes';

import ueFooter from '../static/img/FE_footer.png'


const SKIP_LINKS_PRIVATE = [
    {
        id: 1,
        label: 'Przejdź do projektów',
        linkTo: URLS.projects.path,
    },
    {
        id: 2,
        label: 'Przejdź do ogłoszeń',
        linkTo: URLS.advertisements.path,
    },
    {
        id: 3,
        label: 'Przejdź do ofert',
        linkTo: URLS.offers.path,
    },
];

const SKIP_LINKS = [
    {
        id: 4,
        label: 'Przejdź do deklaracji dostępności',
        linkTo: URLS.accessibility.path,
    },
    {
        id: 5,
        label: 'Przejdź do treści',
        linkTo: '#',
    },
];

const MENU_LINKS = [
    {
        icon: 'question_answer',
        id: 1,
        label: 'Moje projekty i nabory',
        linkTo: URLS.projects.path,
        name: 'projects',
        onlyAuthenticated: true,
    },
    {
        icon: 'send',
        id: 2,
        label: 'Moje ogłoszenia',
        linkTo: URLS.advertisements.path,
        name: 'advertisements',
        onlyAuthenticated: true,
    },
    {
        icon: 'list',
        id: 3,
        label: 'Moje oferty',
        linkTo: URLS.offers.path,
        name: 'offers',
        onlyAuthenticated: true,
    },
    {
        icon: 'mail',
        id: 4,
        label: 'Newsletter, powiadomienia, ulubione',
        linkTo: `${URLS.newsletter.path}?sekcja=newsletter`,
        name: 'newsletter',
        onlyAuthenticated: true,
    },
    {
        icon: 'accessibility',
        id: 5,
        label: 'Deklaracja dostępności',
        linkTo: URLS.accessibility.path,
        name: 'accessibility',
    },
    {
        icon: 'people',
        id: 6,
        label: 'Administracja użytkownikami i uprawnieniami',
        linkTo: `${URLS.users.path}?sekcja=lista`,
        name: 'users',
        onlyAuthenticated: true,
        permissionName: 'fullUsersAndPermissions',
    },
    {
        icon: 'account_tree_icon',
        id: 7,
        label: 'Administracja słownikiem poziomów wdrażania',
        linkTo: URLS.managementPerspectives.path,
        name: 'implementationLevelsDictionaryManagement',
        onlyAuthenticated: true,
        permissionName: 'previewDictionaries',
    },
    {
        icon: 'vertical_split',
        id: 8,
        label: 'Administracja treściami',
        linkTo: URLS.managementContents.path,
        name: 'contentsManagement',
        onlyAuthenticated: true,
        permissionName: 'fullContent',
    },
    {
        icon: 'line_weight',
        id: 9,
        label: 'Administracja projektami i\u00a0naborami',
        linkTo: URLS.managementProjects.path,
        name: 'projectsManagement',
        onlyAuthenticated: true,
        permissionName: 'projectsEnrollments',
    },
    {
        icon: 'description',
        id: 10,
        label: 'Administracja ogłoszeniami',
        linkTo: URLS.managementAdvertisements.path,
        name: 'advertisementsManagement',
        onlyAuthenticated: true,
        permissionName: 'advertisements',
    },
];


const UePadding = (props) => {
    const location = useLocation();

    if (location.pathname != '/') {
        return <></>
    }

    return <div style={{paddingBottom: "14rem"}}></div>
}


const UeFooter = (props) => {
    const location = useLocation();

    if (location.pathname != '/') {
        return <></>
    }

    return (
        <>
            <div style={{textAlign: "center"}}>
                <img style={{maxWidth: "800px", width: "90%", height: "auto"}} alt="Zestawienie znaków: Fundusze Europejskie, Barwy Rzeczypospolitej Polskiej, Unia Europejska" src={ueFooter}/>
            </div>
            <div style={{textAlign: "center", marginBottom: "4rem"}}>
                Projekt współfinansowany z Funduszu Spójności i budżetu państwa
            </div>
        </>
    )
}


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initializingOn: window.location.pathname !== URLS.login.path,
            currentMenuLink: null,
            openMenu: false,
            mobile: true,
            currentHref: '/',
            logoutError: '',
            logoutDoneOn: false,
        }

        this.appContentRef = React.createRef();

        this.xhrFetch = null;
        this.xhrLogoutFetch = null;

        this.updateMenuDisplay = this.updateMenuDisplay.bind(this);
    }

    // basic functions

    componentDidMount() {
        this.updateMenuDisplay();
        window.addEventListener('resize', this.updateMenuDisplay);

        if (this.state.initializingOn) {
            // if token cookie exists, check it on server
            if (getCookieValue(TOKEN_COOKIE_NAME)) {
                this.xhrFetch = fetchApi(
                    '/api/authentication/identity',
                    'GET',
                    {},
                    {},
                    this.handleFetchSuccess,
                    this.handleFetchError,
                    this.handleFetchIncorrectStatus,
                );
            } else {
                this.setState({initializingOn: false});
            }
        }
    }

    componentWillUnmount() {
        // abort api requests if exist
        for (let fetch of [this.xhrFetch, this.xhrLogoutFetch]) {
            if (fetch !== null) {
                fetch.abort();
            }
        }
    }

    // handlers

    handleFetchSuccess = (data) => {
        this.xhrFetch = null;
        this.context.set(
            data,
            () => this.setState({initializingOn: false})
        )
    }

    handleFetchError = (data) => {
        this.xhrFetch = null;
        this.clearAuthCookie();
        this.setState({initializingOn: false});
    }

    handleFetchIncorrectStatus = (status) => {
        this.xhrFetch = null;
        this.clearAuthCookie();
        this.setState({initializingOn: false});
    }

    handleLogin = () => {
        redirectToLoginView();
    }

    handleLogout = () => {
        this.xhrLogoutFetch = fetchSativaApi(
            '/api/token/revoke',
            this.handleFetchLogoutSuccess,
            this.handleFetchLogoutError,
            this.handleFetchLogoutIncorrectStatus,
        );
    }

    handleFetchLogoutSuccess = (data) => {
        this.xhrLogoutFetch = null;
        this.clearAuthCookie(
            () => this.context.logout(() => this.setState({logoutDoneOn: true}))
        );
    }

    handleFetchLogoutError = (data) => {
        this.xhrLogoutFetch = null;
        this.setState({logoutError: `Nie udało się wylogować użytkownika. ${data.message}`});
    }

    handleFetchLogoutIncorrectStatus = (status) => {
        this.xhrLogoutFetch = null;
        this.setState({logoutError: `Nie udało się wylogować użytkownika. Wystąpił błąd o kodzie ${status}.`});
    }

    handleSetMenuLinkByName = (linkName) => {
        if (linkName === null) {
            this.setState({currentMenuLink: null});
            return
        }
        for (let link of MENU_LINKS) {
            if (link.name === linkName) {
                this.setState({currentMenuLink: link.id});
                return
            }
        }
        this.setState({currentMenuLink: null});
    }

    // helpers

    setCurrentMenuLink(id) {
        this.setState({currentMenuLink: id});
        setFocusOnElement(this.appContentRef);
    }

    updateMenuDisplay() {
        this.setState({mobile: window.innerWidth >= 1340 ? false : true});
    }

    clearAuthCookie = (callback=null) => {
        deleteCookie(TOKEN_COOKIE_NAME, callback);
    }

    // rendering

    render() {
        if (this.state.initializingOn) {
            return <Text info>Trwa inicjalizacja danych...</Text>
        }

        let skipLinks = [...SKIP_LINKS];
        if (this.context.user.identifier) {
            skipLinks = [...SKIP_LINKS_PRIVATE, ...SKIP_LINKS];
        }

        // Router should be on the top of components, because MenuDrawer needs routing
        return (
            <>
                <Router>
                    <MenuProvider style={{display: "flex"}}>
                        <SkipLinks children={skipLinks} />
                        <CiasteczkaFront />
                        {this.renderHeader()}
                        <TopAppBarFixedAdjust />
                        {this.renderMenu()}
                        {this.renderContent()}
                        {this.renderFooter()}
                    </MenuProvider>
                </Router>
                {this.state.logoutError.length > 0 && this.renderLogoutError()}
                {this.state.logoutDoneOn && this.renderLogoutDialog()}
            </>
        )
    }

    renderHeader() {
        const { mobile } = this.state;
        return (
            <TopAppBar fixed={!mobile} className={!mobile && 'top-app-bar--desktop'}>
                <TopAppBarRow>
                    <TopAppBarSection alignStart>
                        {mobile && (
                            <Button
                                className="btn--icon-only btn--primary"
                                onClick={() => {this.setState(prevState => ({openMenu: !prevState.openMenu}))}}
                                title="Rozwiń menu"
                                unelevated >
                                <span className="sr-only">Rozwiń menu</span>
                                <Icon aria-hidden="true" icon="menu" />
                            </Button>
                        )}
                        <TopAppBarTitle tag="h1">
                            <Link to={URLS.home.path} className="main-page-title">
                                Baza Konkurencyjności
                            </Link>
                        </TopAppBarTitle>
                    </TopAppBarSection>
                    <TopAppBarSection alignEnd>
                        <Sesja ref={c => {window.sessionComponent = c}} />
                        {this.renderUserWidget()}
                    </TopAppBarSection>
                </TopAppBarRow>
            </TopAppBar>
        )
    }

    renderUserWidget() {
        if (this.context.user.identifier === null) {
            return (
                <Button
                    className="btn--icon-only btn--primary"
                    onClick={this.handleLogin}
                    title="Zaloguj"
                    unelevated>
                    <span className="sr-only">Zaloguj się</span>
                    <Icon aria-hidden="true" icon="power_settings_new" />
                </Button>
            )
        }
        const { forename, surname } = this.context.user;
        return (
            <>
                <Link to={URLS.usersMyProfile.path} className="link-text">
                    <TopAppBarActionItem
                        aria-hidden="false"
                        aria-label="Mój profil"
                        title="Mój profil"
                        icon="account_circle" />
                </Link>
                <Text className="header-user-name">{forename} {surname}</Text>
                <Button
                    className="btn--icon-only btn--primary"
                    onClick={this.handleLogout}
                    title="Wyloguj"
                    unelevated >
                    <span className="sr-only">Wyloguj się</span>
                    <Icon aria-hidden="true" icon="power_settings_new" />
                </Button>
            </>
        )
    }

    renderMenu() {
        const {
            currentMenuLink,
            mobile,
            openMenu,
        } = this.state;

        let menuLinks = [...MENU_LINKS];
        if (!this.context.user.identifier) {
            menuLinks = menuLinks.filter(link => link.onlyAuthenticated !== true);
        } else {
            const permissions = this.context.permissions;
            menuLinks = menuLinks.filter(
                link => !link.permissionName ||
                    permissions[link.permissionName] || false
            );
        }

        return (
            <MenuDrawer
                children={menuLinks}
                currentMenuLink={currentMenuLink}
                openMenu={openMenu}
                onMenuClose={() => {this.setState(prevState => ({openMenu: !prevState.openMenu}))}}
                mobile={mobile} />
        )
    }

    renderContent() {
        return (
            <DrawerAppContent>
                <div ref={this.appContentRef} tabIndex="-1">
                    <ZoltyPasekFront />
                    <main className="main">
                        <Routes
                            onLoginClick={this.handleLogin}
                            onSetMenuLinkByName={this.handleSetMenuLinkByName} />
                    </main>
                </div>
                <UePadding/>
            </DrawerAppContent>
        )
    }

    renderFooter() {
        return (
            <>
                <div style={{flexGrow: 1}}></div>
                <footer className="footer">
                    <nav aria-label="Nawigacja w stopce">
                        <UeFooter/>
                        <ul className="footer__list">
                            <li><Link to={URLS.helps.path} className="link-text footer__list-item">Pomoc</Link></li>
                            <li><Link to={URLS.regulations.path} className="link-text footer__list-item">Regulamin</Link></li>
                            <li><Link to={URLS.statements.path} className="link-text footer__list-item">Komunikaty</Link></li>
                        </ul>
                    </nav>
                </footer>
            </>
        )
    }

    renderLogoutError() {
        return <SessionDialog
                   callback={() => this.setState({logoutError: ''})}
                   error
                   messageTitle={this.state.logoutError}
                   messageIcon="error_outline"
                   withButton />
    }

    renderLogoutDialog() {
        return <SessionDialog
                   callback={() => window.location.reload()}
                   messageContent="Aby uzyskać dostęp do widoków dla zarejestrowanych użytkowników, zaloguj się ponownie."
                   messageTitle="Użytkownik został wylogowany."
                   withButton />
    }
}


App.contextType = UserContext;

export default App;
