import React from 'react';
import {
    DataTable,
    DataTableBody,
    DataTableCell,
    DataTableContent,
    DataTableHead,
    DataTableHeadCell,
    DataTableRow,
} from '@rmwc/data-table';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { Breadcrumps, ButtonStyled, DialogCustom, GridCustom, Text } from '../../common';
import { BaseComponent } from '../common';


class Regulaminy extends BaseComponent {

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const regulaminy = getSerializedObject(data).regulaminy;
        this.setState({
            initializingOn: false,
            currentExists: regulaminy.aktualnyIstnieje,
            forPublicationExists: regulaminy.doPublikacjiIstnieje,
        });
    }

    handleCloseCurrentVersionDialog() {
        this.setState({editCurrentVersionOn: false});
    }

    handleRemoveDoOpublikowania() {
        this.setState({removingDoOpublikowaniaOn: true});
    }

    handleCloseRemoveDoOpublikowaniaDialog() {
        this.setState({removingDoOpublikowaniaOn: false});
    }

    handleRemove() {
        // remove ruleForPublication from server
        this.xhrFetch = fetchApi(
            API_URLS.regulationsAdmin.path,
            'DELETE',
            {},
            {},
            this.handleFetchRemoveSuccess,
            this.handleFetchRemoveError,
            this.handleFetchRemoveIncorrectStatus,
        );
    }

    handleFetchRemoveSuccess(data) {
        this.xhrFetchSave = null;
        this.setState({removingDoOpublikowaniaOn: false, forPublicationExists: false});
    }

    handleFetchRemoveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            fetchRemoveError: `Nie udało się usunąć wersji regulaminu. ${data.message}`,
        });
    }

    handleFetchRemoveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 404) {
            this.setState({savingOn: false, pageNotFound: true});
        } else {
            let message = '';
            if (status === 403) {
                message = 'Nie masz uprawnień do usuwania regulaminu.';
                // update permission value in context
                this.removePermission();
            }
            this.setState({
                fetchRemoveError: message || `Podczas usuwania wersji regulaminu wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            });
        }
    }

    // helpers

    getAdditionalState(props) {
        return {
            editCurrentVersionOn: false,
            removingDoOpublikowaniaOn: false,
            currentExists: false,
            forPublicationExists: false,
            fetchRemoveError: '',
        }
    }

    setAdditionalHandlers() {
        this.handleCloseCurrentVersionDialog = this.handleCloseCurrentVersionDialog.bind(this);
        this.handleRemoveDoOpublikowania = this.handleRemoveDoOpublikowania.bind(this);
        this.handleCloseRemoveDoOpublikowaniaDialog = this.handleCloseRemoveDoOpublikowaniaDialog.bind(this);

        this.handleRemove = this.handleRemove.bind(this);
        this.handleFetchRemoveSuccess = this.handleFetchRemoveSuccess.bind(this);
        this.handleFetchRemoveError = this.handleFetchRemoveError.bind(this);
        this.handleFetchRemoveIncorrectStatus = this.handleFetchRemoveIncorrectStatus.bind(this);
    }

    getFetchUrl() {
        return API_URLS.regulationsAdminDetails.path
    }

    getFetchError(message) {
        return `Nie udało się pobrać regulaminów. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania regulaminów wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.removePermission();
    }

    removePermission() {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    // rendering

    renderHeader() {
        return (
            <>
                <Breadcrumps>
                    <li className="breadcrumps__current">Regulamin</li>
                </Breadcrumps>
                <Text tag="h2" mainHeader>Regulamin</Text>
            </>
        )
    }

    renderContent() {
        document.title = "Baza Konkurencyjności - regulaminy";
        if (!this.state.currentExists) {
            return <ButtonStyled add onClick={() => this.props.history.push(`/zarzadzanie-trescia/regulaminy/nowy`)}>Dodaj regulamin</ButtonStyled>
        }

        const history = this.props.history;
        return (
            <>
                <DataTable className="table">
                    <DataTableContent>
                        <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell>Wersja regulaminu</DataTableHeadCell>
                                <DataTableHeadCell></DataTableHeadCell>
                            </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                            <DataTableRow>
                                <DataTableCell>Wersja aktualna</DataTableCell>
                                <DataTableCell>
                                    <ButtonStyled lite onClick={() => this.setState({editCurrentVersionOn: true})}>Edytuj</ButtonStyled>
                                    <ButtonStyled primary onClick={() => history.push('/zarzadzanie-trescia/regulaminy/aktualny')}>Szczegóły</ButtonStyled>
                                </DataTableCell>
                            </DataTableRow>
                            {this.state.forPublicationExists && (
                                <DataTableRow>
                                    <DataTableCell>Wersja "Do opublikowania"</DataTableCell>
                                    <DataTableCell>
                                        <ButtonStyled lite onClick={() => history.push('/zarzadzanie-trescia/regulaminy/edytuj')}>Edytuj</ButtonStyled>
                                        <ButtonStyled primary onClick={() => history.push('/zarzadzanie-trescia/regulaminy/do-opublikowania')}>Szczegóły</ButtonStyled>
                                        <ButtonStyled remove onClick={this.handleRemoveDoOpublikowania}>Usuń</ButtonStyled>
                                    </DataTableCell>
                                </DataTableRow>
                            )}
                        </DataTableBody>
                    </DataTableContent>
                </DataTable>
                {this.state.editCurrentVersionOn && this.renderEditCurrentVersionDialog()}
                {this.state.removingDoOpublikowaniaOn && this.renderRemoveDoOpublikowaniaDialog()}
            </>
        )
    }

    renderEditCurrentVersionDialog() {
        const history = this.props.history;
        let content, title = null;
        if (this.state.forPublicationExists) {
            title = 'Istnieje wersja "Do opublikowania" regulaminu.';
            content = (
                <>
                    <ButtonStyled primary onClick={() => history.push('/zarzadzanie-trescia/regulaminy/edytuj?wersja=aktualna')}>Nadpisz wersję "Do opublikowania" aktualną wersją</ButtonStyled>
                    <GridCustom>
                        <ButtonStyled cancel onClick={this.handleCloseCurrentVersionDialog}>Anuluj</ButtonStyled>
                        <ButtonStyled lite onClick={() => history.push('/zarzadzanie-trescia/regulaminy/edytuj')}>Edytuj wersję "Do opublikowania"</ButtonStyled>
                    </GridCustom>
                </>
            )
        } else {
            title ='Nie istnieje żadna wersja "Do opublikowania" regulaminu.';
            content = (
                <GridCustom>
                    <ButtonStyled cancel onClick={this.handleCloseCurrentVersionDialog}>Anuluj</ButtonStyled>
                    <ButtonStyled primary onClick={() => history.push('/zarzadzanie-trescia/regulaminy/edytuj?wersja=aktualna')}>Utwórz wersję "Do opublikowania" na podstawie aktualnej wersji</ButtonStyled>
                </GridCustom>
            )
        }

        return (
            <DialogCustom
                dialogTitle={title}
                onClose={this.handleCloseCurrentVersionDialog}
            >
                {content}
            </DialogCustom>
        )
    }

    renderRemoveDoOpublikowaniaDialog() {
        return (
            <DialogCustom
                dialogTitle='Czy na pewno chcesz usunąć wersję "Do opublikowania" regulaminu?'
                onClose={this.handleCloseRemoveDoOpublikowaniaDialog}
            >
                <>
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled onClick={this.handleCloseRemoveDoOpublikowaniaDialog} cancel>Anuluj</ButtonStyled>
                        <ButtonStyled onClick={this.handleRemove} remove>Usuń</ButtonStyled>
                    </GridCustom>
                    {this.state.fetchRemoveError.length > 0 && <Text error role="alert">{this.state.fetchRemoveError}</Text>}
                </>
            </DialogCustom>
        )
    }
}

Regulaminy.contextType = UserContext;

export { Regulaminy };
