import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';

import { splitLineByChar } from '../../../helpers';
import { DraftToComponents } from './DraftToComponents';

class DraftToPdf extends DraftToComponents {

    // rendering
    render() {
        return this.renderContent()
    }

    renderBlockquote(block) {
        return <Text key={block.key} style={styles.blockquote}>"{this.renderSections(block)}"</Text>
    }

    renderOrderedListItem(block, index) {
        return <Text key={block.key} style={styles.listItem}>{index}. {this.renderSections(block)}</Text>
    }

    renderUnorderedListItem(block) {
        return <Text key={block.key} style={styles.listItem}>- {this.renderSections(block)}</Text>
    }

    renderLink(block, data) {
        return <Text key={block.key} style={styles.link}>{data.url}</Text>
    }

    renderImage(block, data) {
        return (
            <Image key={block.key} src={data.src} style={styles.image} />
        )}

    renderEmbed(block, data) {
        return <Text key={block.key} style={styles.infoText}>Odnośnik do multimediów: <Text style={styles.link}>{data.src}</Text></Text>
    }

    renderText(block) {
        return <View key={block.key}>{this.renderSections(block)}</View>
    }

    renderPureText(text, blockKey) {
        return splitLineByChar(text).map((line, i) => <Text key={blockKey + i}>{line}</Text>)
    }

    renderSection(key, section) {
        return <Text key={key} style={styles[section[1]]}>{section[0]}</Text>
    }
}


const styles = StyleSheet.create({
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
    bolditalic: {
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
    italicbold: {
        fontStyle: 'italic',
        fontWeight: 'bold',
    },
    blockquote: {
        fontStyle: 'italic',
        paddingLeft: 20,
        marginVertical: 20
    },
    image: {
        display: 'block',
        position: 'relative',
        marginVertical: 20,
        marginHorizontal: 'auto',
        width: 200,
    },
    infoText: {
        marginVertical: 20,
        fontStyle: 'italic',
        fontWeight: 'bold'
    },
    link: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    listItem: {
        paddingLeft: 20,
    }
});


export { DraftToPdf };
