import React from 'react';

import { customSelectStyles } from '../../vars/vars';
import { FieldWithLabel, Select } from '../../common';


export const SortersSelect = ({selectedSort, sortingLabel, sortingValues, onChangeSort}) => {
    // sortingValues as a list [[value1, label1], [value2, label2]]
    return (
        <FieldWithLabel
            label={sortingLabel || "Sortuj listę"}
            tag="label"
            selectRef={React.createRef()}
            className="search__select-sorter"
            tabIndex="-1">
            <Select
                aria-label={sortingLabel}
                className="selectClassName"
                options={(sortingValues || []).map(value => ({value: value[0], label: value[1]}))}
                noOptionsMessage={() => 'Brak wybranej opcji'}
                screenReaderStatus={() => {return ''}}
                styles={customSelectStyles}
                value={selectedSort}
                onChange={onChangeSort}
            />
        </FieldWithLabel>
    )
}
