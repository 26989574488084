// USAGE
// <Breadcrumps>
//     <li className="breadcrumps__link">Link to upper content</li>
//     <li className="breadcrumps__current">Inactive text of current content</li>
// </Breadcrumps>

import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@rmwc/icon';


const Breadcrumps = (props) => {
    const {
        className,
        children,
        isFront,
        notZarzadzanieTrescia,
    } = props;

    let breadcrumpsClassName = 'breadcrumps ';
    className && (breadcrumpsClassName += className);

    return (
        <nav className={breadcrumpsClassName}>
            <ul className="breadcrumps__list">
                <li className="breadcrumps__link"><Link to='/' title="Strona główna"><Icon aria-hidden="true" icon={{ icon: 'home', size: 'small' }} style={{ marginTop: 3 }}/></Link></li>
                {!isFront && !notZarzadzanieTrescia && <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia'>Administracja treściami</Link></li>}
                {children}
            </ul>
        </nav>
    );
}

export { Breadcrumps };
