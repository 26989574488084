import React from 'react';
import { ButtonStyled } from './ButtonStyled';

const ButtonCreatePdf = (props) => {
    const {
        children,
        onClick,
        pdfCreatingOn
    } = props;

    let pdfCreatingButtonLabel;

    if (pdfCreatingOn) {
        pdfCreatingButtonLabel = 'Tworzenie pliku pdf...'
    } else {
        pdfCreatingButtonLabel = 'Pobierz plik pdf'
    }

    return (
        <ButtonStyled
            className="btn--pdf-dowload"
            disabled={pdfCreatingOn}
            onClick={onClick}
            print
        >
            {children || pdfCreatingButtonLabel}
            <span className="sr-only"> Generowanie pliku PDF może potrwać kilka sekund.</span>
        </ButtonStyled>
    )
}


export { ButtonCreatePdf };
