// USAGE:
// Do not provide any props if you use <Ellipsis /> to normal text paragraph,
// which line-height = 25px (font-size = 16px).
// If font-size is bigger, provide `lineHeight` prop.
// To set lineHeight use number of px, but do not add px unit ex.: lineHeight={10}.
// There are 4 lines by default.
// If you want to show different number of lines, put it in `lines` prop

import React, { useEffect, useState } from 'react';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';

const Ellipsis = (props) => {
    const {
        children,
        lineHeight,
        lines,
    } = props;

    let ellipsisRef = React.createRef();
    let ellipsisMaxHeight = (lines || 4) * (lineHeight || 25);

    const [ellipsis, setEllipsisContainer] = useState(true);
    const [longText, setLongText] = useState(true);

    useEffect(() => {
        // If text is short (height of text is smaller than ellipsis container height (ellipsisMaxHeight), dont set the ellipsis container.
        if (ellipsisRef.current.querySelector('.text').clientHeight <= ellipsisMaxHeight) {
            setLongText(false);
        }
    }, [ellipsisRef, ellipsisMaxHeight]);

    return (
        <>
            <div
                className="ellipsis-container"
                ref={ellipsisRef}
                style={(longText && ellipsis) ? { maxHeight: ellipsisMaxHeight, overflow: 'hidden'} : null} >
                {children}
            </div>
            {longText && (
                <div className="ellipsis" style={{ bottom: (lineHeight || 25) }}>
                    {ellipsis &&
                        <span className="ellipsis__mask" style={{ height: (lineHeight || 25) }}></span>
                    }
                    <Button
                        className="ellipsis__button"
                        onClick={() => setEllipsisContainer(!ellipsis)}
                        style={!ellipsis && { marginTop: (lineHeight || 25) }}
                    >
                        <Icon aria-hidden="true" icon={ellipsis ? 'keyboard_arrow_down' : 'keyboard_arrow_up'} />
                        {ellipsis ? 'Czytaj więcej' : 'Ukryj tekst'}
                    </Button>
                </div>
            )}
        </>
    );
}

export { Ellipsis };
