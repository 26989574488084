import React from 'react';
import { Link } from 'react-router-dom';

import { URLS } from '../../../urls/frontend';
import {
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';


export const UserHeader = ({ user, withButtons = true }) => {
    return (
        <>
            <GridCustom fullwidth>
                <Text tag="h2" mainHeader>
                    {user.imie || user.forename} {user.nazwisko || user.surname}
                </Text>
                {withButtons && (
                    <GridCustom flexTop>
                        <Link
                            className="mdc-button mdc-button--unelevated btn--cancel"
                            to={URLS.users.path}>
                            Wróć do listy użytkowników
                        </Link>
                        <a
                            className="mdc-button mdc-button--unelevated btn--primary"
                            href={URLS.sativaUsersDetails.getUrl(user.identyfikator)}>
                            Konto użytkownika
                        </a>
                    </GridCustom>
                )}
            </GridCustom>
            <GridCustom fullwidth>
                <FieldWithLabel label="Adres e-mail">
                    <Text>{user.email}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Identyfikator">
                    <Text>{user.identyfikator || user.identifier}</Text>
                </FieldWithLabel>
            </GridCustom>
        </>
    )
};
