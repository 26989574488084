import React, { Component } from 'react';

import { ButtonStyled, GridCustom, Text } from '../../common';
import { FormKodCPV } from './FormKodCPV';


class KodyCPV extends Component{

    constructor(props) {
        super(props);
        this.state = {
            newKodOn: false,
            selectedKod: null,
        };

        this.handleAddKodCPV = this.handleAddKodCPV.bind(this);
        this.handleRemoveKodCPV = this.handleRemoveKodCPV.bind(this);
    }

    // handlers

    handleAddKodCPV(selectedCode) {
        this.setState(
            {newKodOn: false},
            () => this.props.addKodCPV(selectedCode)
        );
    }

    handleRemoveKodCPV(id_) {
        this.props.removeKodCPV(id_);
    }

    handleCancelKodCPV = () => {
        this.setState({newKodOn: false, selectedKod: null})
    }

    // rendering

    render() {
        const kodyCPV = this.props.kodyCPV;
        return (
            <>
                <Text info>Liczba kodów CPV: {kodyCPV.length}</Text>
                <ul>
                    {kodyCPV.map(kod_ => {
                        return (
                            <GridCustom key={kod_.id} centerVertical fullwidth className=" separate-content" tag="li">
                                <Text>{kod_.kod} {kod_.nazwa}</Text>
                                <ButtonStyled onClick={(ev) => this.handleRemoveKodCPV(kod_.id)} remove className="btn--delete">
                                    Usuń<span className="sr-only"> kod CPV</span>
                                </ButtonStyled>
                            </GridCustom>
                        )
                    })}
                </ul>
                {this.renderNewKodForm()}
            </>
        )
    }

    renderNewKodForm() {
        if (this.state.newKodOn) {
            return <FormKodCPV
                        usedIds={this.props.kodyCPV.map(k => parseInt(k.id))}
                        withButtons
                        addKodCPV={this.handleAddKodCPV}
                        onCancelKodCPV={this.handleCancelKodCPV} />
        }
        return (
            <GridCustom flexEnd>
                <ButtonStyled add id="dodaj_kodCPV_button_id" onClick={() => this.setState({newKodOn: true})}>
                    Dodaj kod CPV<span className="sr-only"> {this.props.errorsKodyCPV}</span>
                </ButtonStyled>
            </GridCustom>
        )
    }
}


export { KodyCPV };
