import React from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { countWysiwygChars } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { validateWysiwygEditor } from '../../../validators';
import {
    Breadcrumps,
    ButtonStyled,
    Container,
    FieldWithLabel,
    GridCustom,
    Text,
    WysiwygEditor,
} from '../../common';
import { BaseComponent } from '../common';


const SAVE_BUTTON_DEFAULT_LABEL = 'Zapisz regulamin';


class FormRegulamin extends BaseComponent {
    // basic functions

    componentDidMount() {
        if (!this.props.editing) {
            this.setState({initializingOn: false});
        } else {
            // get regulamin from server
            super.componentDidMount()
        }
    }

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const regulamin = getSerializedObject(
            data,
            {
                nonRecursiveKeys: ['tresc'],
                dateAttributes: ['dataOpublikowania', ],
                dateTimeAttributes: ['dataOpublikowania', ],
            },
        ).regulaminy;
        if (!regulamin) {
            this.setState({initializingOn: false, pageNotFound: true});
        } else {
            this.setState({
                initializingOn: false,
                tresc: regulamin.tresc,
                dataOpublikowania: this.version === 'aktualna' ? '' : regulamin.dataOpublikowania,
            });
        }
    }

    handleChangeAttribute(name, value) {
        this.setState(prevState => ({
            [name]: value,
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleSave() {
        this.setState(
            {savingOn: true, fetchSaveError: '', saveBtnText: 'Trwa zapisywanie...'},
            () => {
                const [isValid, errors] = this.validate();
                if (isValid) {
                    // save regulamin on server
                    this.xhrFetch = fetchApi(
                        API_URLS.regulationsAdmin.path,
                        this.firstSaving ? 'POST' : 'PUT',
                        {},
                        getSerializedObject(
                            {tresc: this.state.tresc},
                            {toServer: true, nonRecursiveKeys: ['tresc', ]}
                        ),
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors, saveBtnText: SAVE_BUTTON_DEFAULT_LABEL});
                }
            }
        );
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetchSave = null;
        this.setState(
            {
                savingOn: false,
                saveBtnText: 'Zapisano',
                dataOpublikowania: getSerializedObject(
                    data,
                    {
                        dateAttributes: ['dataOpublikowania', ],
                        dateTimeAttributes: ['dataOpublikowania', ],
                    },
                ).regulaminy.dataOpublikowania},
            () => {
                this.firstSaving = false;
                this.saveMsgTimeout = setTimeout(() => {
                    this.setState({saveBtnText: SAVE_BUTTON_DEFAULT_LABEL})
                }, 5000)
            }
        );
    }

    handleFetchSaveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            savingOn: false,
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: `Nie udało się zapisać regulaminu. ${data.message}`,
        });
    }

    handleFetchSaveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 404) {
            this.setState({savingOn: false, pageNotFound: true});
        } else {
            let message = '';
            if (status === 403) {
                message = `Nie masz uprawnień do ${this.firstSaving
                    ? 'dodawania' : 'edycji'} regulaminu.`;
                // update permission value in context
                this.removePermission();
            }
            this.setState({
                savingOn: false,
                saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
                fetchSaveError: message || `Podczas zapisu regulaminu wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            });
        }
    }

    // helpers

    preState(props) {
        this.trescMaxLength = 50000;
        this.firstSaving = !props.editing;

        this.version = null;
        if (props.editing) {
            const params = new URLSearchParams(props.location.search);
            this.version = params.get('wersja') || this.version;
        }
    }

    getAdditionalState(props) {
        return {
            savingOn: false,
            tresc: '',
            dataOpublikowania: '',
            fetchSaveError: '',
            errors: {
                tresc: '',
            },
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
        }
    }

    setAdditionalHandlers() {
        this.handleSave = this.handleSave.bind(this);
        this.handleFetchSaveSuccess = this.handleFetchSaveSuccess.bind(this);
        this.handleFetchSaveError = this.handleFetchSaveError.bind(this);
        this.handleFetchSaveIncorrectStatus = this.handleFetchSaveIncorrectStatus.bind(this);
    }

    getFetchUrl() {
        return API_URLS.regulationsAdmin.path
    }

    getFetchData() {
        let query = {};
        if (!(this.version === 'aktualna')) {
            query['version'] = 'for_publication';
        }
        return query
    }

    getFetchError(message) {
        return `Nie udało się pobrać wersji regulaminu do edycji. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania wersji regulaminu do edycji wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.removePermission();
    }

    removePermission() {
        this.context.removePermission('generalContentCookiesRegulation');
    }

    validate() {
        let isValid = true;
        let errors = {};

        const tresc = this.state.tresc;
        // validate tresc
        if (!validateWysiwygEditor(tresc)) {
            errors['tresc'] = 'To pole jest wymagane.';
            isValid = false;
        } else if (countWysiwygChars(tresc) > this.trescMaxLength) {
            errors['tresc'] = `To pole może mieć maksymalnie ${this.trescMaxLength} znaków.`;
            isValid = false;
        }

        return [isValid, errors]
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps>
                <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/regulaminy'>Regulamin</Link></li>
                <li className="breadcrumps__current">{this.props.editing ? 'Edytuj' : 'Dodaj'} regulamin</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        const {
            dataOpublikowania,
            errors,
            fetchSaveError,
            saveBtnText,
            savingOn,
            tresc,
        } = this.state;
        const isTrescInvalid = (errors.tresc || '').length > 0;
        return (
            <>
                <Text tag="h2" mainHeader>{this.props.editing ? 'Edytuj' : 'Dodaj'} regulamin</Text>
                <Container>
                    <GridCustom fullwidth>
                        <FieldWithLabel label="Treść" className="wysiwyg-wrapper">
                            <WysiwygEditor
                                disabled={savingOn}
                                invalid={isTrescInvalid}
                                initialContentState={tresc}
                                maxLength={this.trescMaxLength}
                                toolbar={{
                                    options: ['list', 'link'],
                                    list: {
                                        inDropdown: false ,
                                        options: ['unordered', 'ordered'],
                                    },
                                    link: { inDropdown: false },
                                }}
                                localization={{ locale: 'pl' }}
                                onContentStateChange={(contentState) => this.handleChangeAttribute('tresc', contentState)}
                            />
                            {isTrescInvalid && <Text error>{errors.tresc}</Text>}
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom flexEnd>
                        {dataOpublikowania.length > 0 && (new Date(dataOpublikowania).getTime() > new Date().getTime()) && <Text info>Regulamin zostanie opublikowany {dataOpublikowania}.</Text>}
                    </GridCustom>
                    <GridCustom flexEnd>
                        <ButtonStyled disabled={savingOn} save onClick={this.handleSave}>{saveBtnText}</ButtonStyled>
                    </GridCustom>
                    {fetchSaveError.length > 0 && <Text error role="alert">{fetchSaveError}</Text>}
                </Container>
            </>
        )
    }
}

FormRegulamin.contextType = UserContext;

export { FormRegulamin };
