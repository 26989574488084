import {hasPerspectiveInstitutions, hasPerspectiveRegions} from './helpers';


export const getPermissionsValuesDict = (defaultDict, keyName, value) => {
    let dct = {};
    for (let key of Object.keys(defaultDict[keyName])) {
        dct[key] = value;
    }
    return {[keyName]: dct}
};

export const checkSinglePermission = (prevChecks, parent, child, value) => {
    // check as mark, not check as check if value is equal to something
    return {
        [parent]: {
            ...prevChecks[parent],
            [child]: value,
        },
    }
};

export const getPermissionErrors = (errors) => {
    return (errors.permission || {}).decisions || []
};

export const getGroupChecked = (checks, name) => {
    const permissions = checks[name] || {};
    if (!Object.keys(permissions).length) {
        return false
    }
    return !Object.values(permissions).includes(false)
};

export const getGroupIndeterminate = (checks, name) => {
    const values = Object.values(checks[name]);
    return values.includes(false) && values.includes(true)
};

export const getMainGroupIndeterminate = (checks) => {
    let values = [];
    for (let group of Object.values(checks)) {
        values = [...values, ...Object.values(group)];
    }
    return values.includes(false) && values.includes(true)
};

export const getGrantedPermissionsForGroup = (permissions) => {
    if (!permissions) { return false }
    return Object.entries(permissions).filter(
        ([_, value]) => value === true).map(([name, _]) => name)
};

const PERMISSIONS_NAMES_DICT = {
    all: 'Wszystkie uprawnienia',
    usersAdministration: 'Funkcjonalność',
    previewListUsers: 'Podgląd listy użytkowników',
    blockingUsers: 'Blokowanie użytkowników',
    contentAdministration: 'Funkcjonalność',
    generalContentCookiesRegulation: 'Treści ogólne, cookies i regulamin',
    statements: 'Komunikaty',
    help: 'Pomoc',
    projectsEnrollmentsAdministration: 'Projekty i nabory',
    searchNPreviewData: 'Wyszukiwanie i podgląd danych',
    editData: 'Edycja danych',
    advertisementAdministration: 'Ogłoszenia',
    blocking: 'Blokowanie i odblokowywanie',
    permissionsAdministration: 'Zarządzanie uprawnieniami',
    previewListUsersAuthorized: 'Podgląd listy uprawnionych użytkowników',
    functionalityManagement: 'Dla funkcjonalności',
    functionalityManagementImplementationLevel: 'Dla projektów i naborów oraz ogłoszeń',
    permissionsManagement: 'Dla zarządzania uprawnieniami',
    implementationLevelsDictionaryAdministration: 'Funkcjonalność',
    previewData: 'Podgląd danych',
};

export const translatePermissionName = (name) => {
    return PERMISSIONS_NAMES_DICT[name] || name
};

const getPerspectiveLabel_ = (perspective) => {
    // if perspective is set, add it to string - it may be needed for key prop
    return perspective ? `${perspective.id || ''}.` : ''
}

const getRegionCode = (programme, priority, activity, region, perspective=null) => {
    return `${getPerspectiveLabel_(perspective)}${programme.code || ''}.${priority.code || ''}.${activity.code || ''}-${region.code || ''}`
};

const getInstitutionCode = (programme, priority, activity, institution, perspective=null) => {
    return `${getPerspectiveLabel_(perspective)}${programme.code || ''}.${priority.code || ''}.${activity.code || ''}-${institution.code || ''}`
};

export const getActivityCode = (programme, priority, activity, perspective=null) => {
    return `${getPerspectiveLabel_(perspective)}${programme.code || ''}.${priority.code || ''}.${activity.code || ''}`
};

const getPriorityCode = (programme, priority, perspective=null) => {
    return `${getPerspectiveLabel_(perspective)}${programme.code || ''}.${priority.code || ''}`
};

export const getRegionOrInstitutionCode = (
    programme, priority, activity, region, institution, perspective=null
) => {
    if (region) {
        return getRegionCode(
            programme, priority, activity, region, perspective)
    }
    if (institution) {
        return getInstitutionCode(
            programme, priority, activity, institution, perspective)
    }
    // maybe there is neither region nor institution, then get activity code
    return getActivityCode(programme, priority, activity, perspective)
};

export const getImplementationLevelLabels = (permission) => {
    const emptyData = {
        code: null,
        name: null,
        description: null,
    };
    if (!permission.programme) {
        return emptyData
    }
    const { programme } = permission;
    if (!permission.priority) {
        return {
            code: programme.code || '',
            name: programme.name || '',
            description: 'Wszystkie osie',
        }
    }
    const { priority } = permission;
    if (!permission.activity) {
        return {
            code: getPriorityCode(programme, priority),
            name: priority.name || '',
            description: 'Wszystkie działania',
        }
    }
    const { activity } = permission;
    if (!permission.region && !permission.institution) {
        return {
            code: getActivityCode(programme, priority, activity),
            name: activity.name || '',
            description: hasPerspectiveRegions(permission.perspective)
                ? 'Wszystkie regiony'
                : hasPerspectiveInstitutions(permission.perspective)
                    ? 'Wszystkie instytucje' : '',
        }
    }

    const { institution, region } = permission;
    if (!institution) {
        return {
            code: getRegionCode(programme, priority, activity, region),
            name: region.name || '',
            description: '',
        }
    }
    return {
        code: getInstitutionCode(programme, priority, activity, institution),
        name: institution.name || '',
        description: '',
    }
};

export const sortKeysList = (list, sortList) => {
    list.sort((value1, value2) => {
        return sortList.indexOf(value1[0]) - sortList.indexOf(value2[0])
    });
    return list
};

export const PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT = {
    usersAdministration: 'Lista użytkowników',
    implementationLevelsDictionaryAdministration:
        'Zarządzanie słownikami poziomów wdrożenia',
    contentAdministration: 'Zarządzanie treścią',
};

export const getPermissionHistoryUsersTitle = (permission, isHistory) => {
    if (permission.isImplementationLevelType) {
        const {
            programme = null,
            priority = null,
            activity = null,
            institution = null,
            region = null
        } = permission.permission;

        if ([programme, priority, activity, region || institution].every(el => el !== null)) {
            const isRegion = region !== null;
            const levelSuffix = isRegion ? 'regionu' : 'instytucji';
            return `${isHistory
                ? `Historia zmian dla ${levelSuffix}`
                : `Lista użytkowników z uprawnieniami do ${levelSuffix}`
            } ${isRegion
                ? `${getRegionCode(programme, priority, activity, region)} ${region.name}`
                : `${getInstitutionCode(programme, priority, activity, institution)} ${institution.name}`
            }`
        }
        if ([programme, priority, activity].every(el => el !== null)) {
            return `${isHistory
                ? 'Historia zmian dla działania'
                : 'Lista użytkowników z uprawnieniami do działania'
            } ${getActivityCode(programme, priority, activity)} ${activity.name}`
        }
        if ([programme, priority].every(el => el !== null)) {
            return `${isHistory
                ? 'Historia zmian dla osi'
                : 'Lista użytkowników z uprawnieniami do osi'
            } ${getPriorityCode(programme, priority)} ${priority.name}`
        }
        if (programme !== null) {
            return `${isHistory
                ? 'Historia zmian dla'
                : 'Lista użytkowników z uprawnieniami do'
            } programu ${programme.code || ''} ${programme.name}`
        }
        return `${isHistory
            ? 'Historia zmian'
            : 'Lista użytkowników'
        } - stało się coś nieoczekiwanego`
    }
    return `${isHistory
        ? 'Historia zmian'
        : 'Lista użytkowników'
    } dla uprawnienia ${PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[permission.permission.type]}`
};
