import {
    NIP_CHARS_IMPORTANCE,
    PESEL_CHARS_IMPORTANCE,
    SEARCH_CUSTOM_NAME
} from './const';
import { countWysiwygChars, slugify } from './helpers';


///////////////////////////////////////////////////////////////////////////////
//                            fields checks                                  //
///////////////////////////////////////////////////////////////////////////////
const checkRequired = (value) => {
    if (!value || !value.length) {
        return 'To pole jest wymagane.'
    }
    return ''
}

const checkRequiredPrzedmiotZamowienia = (value) => {
    return checkListRequired(
        value, 'Dodaj co najmniej jeden przedmiot zamówienia, aby zdefiniować dla niego atrybuty.')
}

const checkNabor = (value, projectsSelected) => {
    // don't validate if projectsSelected is null
    if (projectsSelected === null) { return '' }
    if (!projectsSelected && (!value || !value.value)) {
        return 'To pole jest wymagane.'
    }
    return ''
}

const checkProjekty = (value, projectsSelected) => {
    // don't validate if projectsSelected is null
    if (projectsSelected === null) { return '' }
    if (projectsSelected && (!value || !value.length)) {
        return 'To pole jest wymagane.'
    }
    return ''
}

const checkListRequired = (value, msg='') => {
    if (!value.length) {
        return msg || 'To pole jest wymagane.'
    }
    return ''
}

const checkSelectRequired = (value) => {
    if ([0, '0', null].includes(value)) {
        return 'To pole jest wymagane.'
    }
    return ''
}


const checkDatesDependency = (start, end) => {
    if (!start.length || !end.length) { return '' }
    if (new Date(start) > new Date(end)) {
        return 'Data końca musi być późniejsza niż data początku.'
    }
    return ''
}


const checkWysiwygEditorRequired = (content) => {
    if (!content || !content.blocks.length) { return false }
    return countWysiwygChars(content) > 0
}


const checkNip = (value) => {
    let error = '';
    if (value.length) {
        if (!/^\d{10}$/.test(value)) {
            error = 'Wpisz poprawny numer NIP.';
        } else {
            // count control sum
            let controlSum = 0;
            for (let i=0; i < value.length - 1; i++) {
                controlSum += parseInt(value[i]) * NIP_CHARS_IMPORTANCE[i];
            }
            if (controlSum % 11 !== parseInt(value[9])) {
                error = 'Wpisz poprawny numer NIP. Suma kontrolna jest nieprawidłowa.';
            }
        }
    }
    return error
};


const checkPesel = (value) => {
    let error = '';
    if (value.length) {
        if (!/^\d{11}$/.test(value)) {
            error = 'Wpisz poprawny numer PESEL.';
        } else {
            // count control sum
            let controlSum = 0;
            for (let i=0; i < value.length - 1; i++) {
                controlSum += parseInt(value[i]) * PESEL_CHARS_IMPORTANCE[i];
            }
            if (controlSum % 10 !== parseInt(value[10])) {
                error = 'Wpisz poprawny numer PESEL. Suma kontrolna jest nieprawidłowa.';
            }
        }
    }
    return error
};

const checkKodPocztowy = (value) => {
    let error = '';
    if (value.length) {
        if (!/^\d{2}-\d{3}$/.test(value)) {
            error = 'Wpisz poprawny kod pocztowy.';
        }
    }
    return error
};

const checkEmail = (value) => {
    if (value.length) {
        // check spaces; check if includes @ char; check dot char after @ char
        if (value.includes(' ') || value.split('@').length !== 2 || value.indexOf('.', value.indexOf('@')) < 0) {
            return 'Wpisz poprawny adres e-mail.'
        }
    }
    return ''
};

const checkSimilarValueOnList = (value, valueList, message='') => {
    if (valueList.map(v => slugify(v)).filter(v => v === slugify(value)).length > 1) {
        return message || 'Wartość tego pola jest podobna do wartości analogicznego pola w innym obiekcie na liście.'
    }
    return ''
};

const checkSimilarTitleValueOnList = (value, valueList) => {
    return (checkSimilarValueOnList(
        value, valueList,
        'Tytuł tej części jest zbyt podobny do tytułu innej części. Zmień tytuł tak, aby łatwo było go odróżnić od pozostałych.'
    ))
}


///////////////////////////////////////////////////////////////////////////////
//                                 configs                                   //
///////////////////////////////////////////////////////////////////////////////

const ogloszeniePrzedmiotZamowieniaRequiredValidators = {
    zamowienia_kryteriaOceny: {
        validators: [checkRequiredPrzedmiotZamowienia, ],
        formStep: 'czesciKryteria',
    },
    przedmiotyZamowienia: {
        validators: [checkRequiredPrzedmiotZamowienia, ],
        formStep: 'przedmiot',
    },
    przedmiotyZamowienia_harmonogramy: {
        validators: [checkRequiredPrzedmiotZamowienia, ],
        formStep: 'terminy',
    },
    przedmiotyZamowienia_warunkiUdzialu: {
        validators: [checkRequiredPrzedmiotZamowienia, ],
        formStep: 'warunki',
    },
};

const titleValidatorData = {
    validators: [checkRequired, ],
    formStep: 'pozostale',
};
const projectsValidationData = {
    validators: [checkProjekty, ],
    formStep: 'pozostale',
};
const enrollmentValidatorData = {
    validators: [checkNabor, ],
    formStep: 'pozostale',
};
const contactPersonsValidatorData = {
    validators: [checkListRequired, ],
    formStep: 'pozostale',
};

const advertisementValidators = {
    tytul: titleValidatorData,
    projekty: projectsValidationData,
    nabor: enrollmentValidatorData,
    osobyDoKontaktu: contactPersonsValidatorData,
    ...ogloszeniePrzedmiotZamowieniaRequiredValidators,
};

const publishedAdvertisementOwnerValidators = {
    tytul: titleValidatorData,
    osobyDoKontaktu: contactPersonsValidatorData,
};

const publishedAdvertisementAdminValidators = {
    tytul: titleValidatorData,
    projekty: projectsValidationData,
    nabor: enrollmentValidatorData,
};

const zamowienieValidators = {
    kryteriaOceny: [checkListRequired, ],
    tytul: [checkRequired, checkSimilarTitleValueOnList],
};

const kryteriumOcenyValidators = {
    opis: [checkRequired, ],
};

const przedmiotZamowieniaValidators = {
    kategoria: [checkSelectRequired, ],
    podkategoria: [checkSelectRequired, ],
    opis: [checkRequired, ],
    kodyCPV: [checkListRequired, ],
    miejscaRealizacji: [checkListRequired, ],
};

const publishedPrzedmiotZamowieniaValidators = {
    opis: [checkRequired, ],
    kodyCPV: [checkListRequired, ],
    miejscaRealizacji: [checkListRequired, ],
};

const harmonogramValidators = {
    koniecRealizacji: [checkRequired, ],
    dependent: [
        // validator name, attributes names as props for validator, attribute name for error showing
        [checkDatesDependency, ['poczatekRealizacji', 'koniecRealizacji'], 'koniecRealizacji'],
    ]
};

const warunekUdzialuValidators = {
    typ: [checkSelectRequired, ],
    opis: [checkRequired, ],
};

const zalacznikValidators = {
    nazwa: [checkRequired, ],
    nested: [
        // validator name, subattribute name, attribute name for error showing
        [checkRequired, 'uri', 'plik']]
};


///////////////////////////////////////////////////////////////////////////////
//                               functions                                   //
///////////////////////////////////////////////////////////////////////////////

const validateAdvertisementBaseAttributes = (
    advertisement, projectsSelected, validatorsDict
) => {
    let przedmiotyZamowienia = [];
    for (let z of advertisement.zamowienia) {
        przedmiotyZamowienia = [...przedmiotyZamowienia, ...z.przedmiotyZamowienia];
    }
    let errors = {};
    let stepErrors = {};
    for (let [attrName, validators] of Object.entries(validatorsDict)) {
        let value = advertisement[attrName];
        for (let v of validators.validators) {
            let error;
            if ([checkNabor, checkProjekty].includes(v)) {
                error = v(value, projectsSelected);
            } else {
                if (v === checkRequiredPrzedmiotZamowienia) {
                    value = przedmiotyZamowienia;
                }
                error = v(value);
            }
            if (error.length > 0) {
                errors[attrName] = error;
                stepErrors[validators.formStep] = true;
                break
            }
        }
    }
    return [errors, stepErrors]
}


const validateZamowienieAttributes = (ogloszenie) => {
    // validate basic fields
    let [errors, stepErrors] = validateFieldAttributes(
        ogloszenie.zamowienia, zamowienieValidators, 'czesciKryteria');
    // validate if zamowienie has at least one przedmiotZamowienia

    let przedmiotyZamowienia = [];
    for (let z of ogloszenie.zamowienia) {
        przedmiotyZamowienia = [...przedmiotyZamowienia, ...z.przedmiotyZamowienia];
    }
    [errors, stepErrors] = validatePrzedmiotyZamowieniaInZamowienie(
        ogloszenie.zamowienia, errors, stepErrors);
    // validate szcunkowaWartosci
    [errors, stepErrors] = validateSzacunkowaWartosc(
        ogloszenie.zamowienia, errors, stepErrors);
    return [errors, stepErrors]
}


const validatePrzedmiotyZamowieniaInZamowienie = (zamowienie, errors, stepErrors) => {
    // every zamowienie has to have at least one przedmiotZamowienia
    for (let z of zamowienie) {
        if (!z.przedmiotyZamowienia.length) {
            errors[z.temporaryId]['przedmiotyZamowienia'] = 'Brak przedmiotów zamówienia dla tego zamówienia.';
            stepErrors['czesciKryteria'] = true;
        }
    }
    return [errors, stepErrors]
}


const validateSzacunkowaWartosc = (zamowienia, errors, stepErrors) => {
    for (let z of zamowienia) {
        let value = z.szacunkowaWartosc;
        if (!value.length) { return [errors, stepErrors] }
        if (!/^\d{1,24},\d{2}$/.test(value)) {
            errors[z.temporaryId]['szacunkowaWartosc'] = 'Wpisz poprawną wartość zgodnie ze wzorem.';
            stepErrors['czesciKryteria'] = true;
        } else {
            if (parseInt(value.replace(',', '')) === 0) {
                errors[z.temporaryId]['szacunkowaWartosc'] = 'Ta wartość powinna być większa od 0.';
                stepErrors['czesciKryteria'] = true;
            }
            if (value.length > 27) {
                errors[z.temporaryId]['szacunkowaWartosc'] = 'Ta wartość nie może być większa od 999999999999999999999999,99.';
                stepErrors['czesciKryteria'] = true;
            }
        }
    }
    return [errors, stepErrors]
}


const validateKryteriumOcenyAttributes = (kryteriumOceny) => {
    return validateFieldAttributes(kryteriumOceny, kryteriumOcenyValidators, 'czesciKryteria')
}


const validatePrzedmiotZamowieniaBaseAttributes = (przedmiotZamowienia, isAlreadyPublished) => {
    return validateFieldAttributes(
        przedmiotZamowienia,
        isAlreadyPublished ? publishedPrzedmiotZamowieniaValidators : przedmiotZamowieniaValidators,
        'przedmiot'
    )
};


const validateHarmonogramAttributes = (harmonogram) => {
    return validateFieldAttributes(harmonogram, harmonogramValidators, 'terminy')
}


const validateWarunekUdzialuAttributes = (warunekUdzialu) => {
    return validateFieldAttributes(warunekUdzialu, warunekUdzialuValidators, 'warunki')
}


const validateZalacznikAttributes = (zalacznik) => {
    return validateFieldAttributes(zalacznik, zalacznikValidators, 'zalaczniki')
}


const validateFieldAttributes = (objectList, fieldValidators, stepName) => {
    let errors = {};
    let stepErrors = {};
    for (let attr of objectList) {
        errors[attr.temporaryId] = {};
        for (let [attrName, validators] of Object.entries(fieldValidators)) {
            if (attrName === 'dependent') {
                for (let v of validators) {
                    let error = v[0](...v[1].map((name) => attr[name]));
                    if (error.length > 0) {
                        errors[attr.temporaryId][v[2]] = error;
                        stepErrors[stepName] = true;
                        break
                    }
                }
                continue
            }
            if (attrName === 'nested') {
                for (let v of validators) {
                    let error = v[0](attr[v[2]][v[1]]);
                    if (error.length > 0) {
                        errors[attr.temporaryId][v[2]] = error;
                        stepErrors[stepName] = true;
                        break
                    }
                }
                continue
            }
            const value = attr[attrName];
            for (let v of validators) {
                let error;
                if ([checkSimilarValueOnList, checkSimilarTitleValueOnList].includes(v)) {
                    error = v(value, objectList.map(o => o[attrName]));
                } else {
                    error = v(value);
                }
                if (error.length > 0) {
                    errors[attr.temporaryId][attrName] = stepName === 'zalaczniki' ? [error] : error;
                    stepErrors[stepName] = true;
                    break
                }
            }
        }
    }
    return [errors, stepErrors]
}


const validateWysiwygEditor = (content) => {
    return checkWysiwygEditorRequired(content);
};

// search validators

const validateSearchFilters = (filters) => {
    let errors = {};
    let isValid = true;

    // validate status - at least one check has to be checked
    if (!filters.status.length) {
        errors['status'] = 'Wybierz przynajmniej jeden status ogłoszenia.';
        isValid = false;
    }

    // validate dates
    for (let name of ['publicationDateRange', 'submissionDeadlineRange']) {
        if (filters[name].type === SEARCH_CUSTOM_NAME) {
            if (!filters[name].from && !filters[name].to) {
                errors[name] = 'Wybierz przynajmniej jedną z dat.'
                isValid = false;
            }
        }
    }

    return [isValid, errors]
}

// user validators

const validatePersonalData = (data) => {
    let errors = {
        adres: {},
    };
    let isValid = true;
    let error = '';

    // check required
    for (let [name, isAdresPart] of [
            ['nazwa', false], ['miejscowosc', true], ['kodPocztowy', true],
            ['numerIdentyfikacyjny', false], ['numerDomu', true]]
    ) {
        error = checkRequired(isAdresPart ? data.adres[name] : data[name]);
        if (error.length) {
            isValid = false;
            if (isAdresPart) {
                errors['adres'][name] = [error, ];
            } else {
                errors[name] = [error, ];
            }
        }
    }

    if (data.rodzajAdresu === 'poland') {
        // check NIP and PESEL sum control
        if (data.typNumeruIdentyfikacyjnego === 'nip') {
            error = checkNip(data.numerIdentyfikacyjny);
        } else if (data.typNumeruIdentyfikacyjnego === 'pesel') {
            error = checkPesel(data.numerIdentyfikacyjny);
        }
        if (error.length) {
            isValid = false;
            errors['numerIdentyfikacyjny'] = [error, ];
        }

        // check kod pocztowy
        error = checkKodPocztowy(data.adres.kodPocztowy);
        if (error.length) {
            isValid = false;
            errors['adres']['kodPocztowy'] = [error, ];
        }
    } else {
        // check kraj
        error = checkRequired(data.adres.kraj);
        if (error.length) {
            isValid = false;
            errors['adres']['kraj'] = [error, ];
        }
    }

    return [isValid, errors]
};


const validateOsobaDoKontaktu = (data) => {
    let errors = {};
    let isValid = true;
    let error = '';

    // check required fields
    for (let name of ['imie', 'nazwisko', 'email']) {
        const value = data[name];
        error = checkRequired(value);
        if (error.length) {
            isValid = false;
            errors[name] = error;
        } else if (name === 'email') {
            // validate email structure
            error = checkEmail(value);
            if (error.length) {
                isValid = false;
                errors[name] = error;
            }
        }
    }

    return [isValid, errors]
};


///////////////////////////////////////////////////////////////////////////////
//                                 exports                                   //
///////////////////////////////////////////////////////////////////////////////

export {
    advertisementValidators,
    ogloszeniePrzedmiotZamowieniaRequiredValidators,
    publishedAdvertisementAdminValidators,
    publishedAdvertisementOwnerValidators,
    validateHarmonogramAttributes,
    validateKryteriumOcenyAttributes,
    validateAdvertisementBaseAttributes,
    validateOsobaDoKontaktu,
    validatePersonalData,
    validatePrzedmiotZamowieniaBaseAttributes,
    validateSearchFilters,
    validateWarunekUdzialuAttributes,
    validateWysiwygEditor,
    validateZalacznikAttributes,
    validateZamowienieAttributes
}


if (typeof module === 'object' && module.exports) {
    module.exports = {
        checkDatesDependency,
        checkListRequired,
        checkNabor,
        checkRequired,
        checkRequiredPrzedmiotZamowienia,
        checkSelectRequired,
        checkSimilarValueOnList,
        publishedAdvertisementOwnerValidators,
        validateAdvertisementBaseAttributes,
    };
}
