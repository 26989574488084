import React from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { getSerializedObject } from '../../../serializers';
import { ButtonStyled, Text } from '../../common';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';


class PomocEkranowa extends BaseComponent {

    // basic functions

    componentDidMount() {
        // id content declared in props, render it
        if (this.props.content) {
            this.setState({initializingOn: false, pomoc: this.props.content});
            return
        }
        // get pomoc from server
        this.fetchInitialData();
    }

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);
        this.setState({
            initializingOn: false,
            pomoc: getSerializedObject(
                data, {nonRecursiveKeys: ['tresc', ]}).pomoc,
        });
    }

    handleFetchError(data) {
        // do nothing, don't render error
        this.xhrFetch = null;     // clean xhr object
        this.setState({initializingOn: false});
    }

    handleFetchIncorrectStatus(status) {
        // do nothing, don't render error
        this.xhrFetch = null;     // clean xhr object
        this.setState({initializingOn: false});
    }

    // helpers

    getAdditionalState(props) {
        return {
            showText: true,
            pomoc: {},
        }
    }

    getFetchUrl() {
        return `/api/help/${this.props.ekran}`
    }

    getFetchData() {
        return {screen: this.props.ekran}
    }

    // rendering

    render() {
        if (this.state.initializingOn || !Object.keys(this.state.pomoc).length) { return null }
        const {
            pomoc,
            showText,
        } = this.state;
        return (
            <section className="help">
                <Text tag="h2" accentHeader>{pomoc.naglowek}</Text>
                <ButtonStyled lite onClick={() => this.setState(prevState => ({showText: !prevState.showText}))} className="help__show-more">{showText ? 'Zwiń pomoc' : 'Rozwiń pomoc'}</ButtonStyled>
                {showText && <DraftToComponents content={pomoc.tresc} />}
            </section>
        )
    }
}


PomocEkranowa.contextType = AdvertisementContext;


export { PomocEkranowa };
