import React from 'react';

import { API_URLS } from '../../../urls/api';
import { ImplementationLevelManagement } from '../../common';
import { PerspectivesTableRow } from './PerspectivesTableRow';


export const Perspectives = (props) => {
    return (
        <ImplementationLevelManagement
            addItemButtonVisible={false}
            apiUrl={API_URLS.perspectivesAdmin.path}
            listObjectName="perspectives"
            RowComponent={PerspectivesTableRow}
            sortItemsFunction={null}
            tableHeaderList={['Nazwa']}
            tableName="Lista perspektyw"
        />
    )
};
