import React from 'react';
import { useParams } from 'react-router-dom';

import { API_URLS } from '../../../urls/api';
import { URLS } from '../../../urls/frontend';
import { sortProgrammes } from '../../../helpers';
import { ImplementationLevelManagement, Text } from '../../common';


const RelatedObjectsExistInfoComponent = (props) => (
    <>
        <Text error>Nie można usunąć programu operacyjnego, ponieważ jest on powiązany z innym obiektem.</Text>
        <Text error>Upewnij się, że program operacyjny nie ma osi priorytetowych, nie istnieją projekty lub nabory oparte o ten program operacyjny oraz nie są przyznane uprawnienia do tego programu operacyjnego, a następnie spróbuj ponownie.</Text>
    </>
);


export const Programmes = (props) => {
    const { perspectiveId } = useParams();

    function getItemLinkUrl(item) {
        return URLS.managementPrioritiesForProgramme.getUrl(item.id)
    }

    return (
        <ImplementationLevelManagement
            addApiUrlName="programmesAdd"
            addItemButtonLabel="Dodaj program operacyjny"
            additionalSaveData={{perspective: parseInt(perspectiveId)}}   // for form
            apiUrl={API_URLS.programmesAdmin.getUrl(perspectiveId)}
            codeMaxLength={4}
            editApiUrlName="programmesEdit"
            getItemLinkFunction={getItemLinkUrl}
            listObjectName="programmes"
            RelatedObjectsExistInfoComponent={RelatedObjectsExistInfoComponent}
            rowComponentProps={{
                getItemLinkFunction: getItemLinkUrl, withLink: true
            }}
            sortItemsFunction={sortProgrammes}
            tableName="Lista programów operacyjnych"
            titleFormLabel="programu operacyjnego"
        />
    )
};
