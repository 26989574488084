import React, { Component, Fragment } from 'react';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';
import '@rmwc/icon/icon.css';

import {
    dateStringToYearMonth,
    datetimeStringToISO,
    getUserFullName,
    isNotEmpty,
    listOrEmpty,
	saveFileFromApi
} from '../../../helpers';
import {
    Box,
    Container,
    GridCustom,
    FieldWithLabel,
    Text,
    AuditData,
	ButtonStyled
} from '../../common';


const TYP_NUMERU_IDENTYFIKACYJNEGO = {
    'nip': 'NIP',
    'pesel': 'PESEL',
    'numer_zagraniczny': 'Numer identyfikacyjny',
}


class OgloszenieDetails extends Component {

    // base functions

    componentDidMount() {
        document.title = "Baza Konkurencyjności - szczegóły ogłoszenia " + this.props.ogloszenie.tytul;
        window.scrollTo(0, 0);
    }

    // helpers

    getPrzedmiotyZamowieniaList() {
        let przedmiotyZamowienia = [];
        for (let z of this.props.ogloszenie.zamowienia) {
            przedmiotyZamowienia = [...przedmiotyZamowienia, ...z.przedmiotyZamowienia];
        }
        return przedmiotyZamowienia
    }

    getHarmonogramList(przedmiotyZamowienia) {
        let harmonogramy = [];
        for (let pz of przedmiotyZamowienia) {
            if (!isNotEmpty(pz.harmonogramy, 'list')) {
                continue
            }
            let i = 1;
            for (let h of pz.harmonogramy) {
                harmonogramy.push({
                    date: h.poczatekRealizacji,
                    label: `Etap${i} (początek): ${pz.kategoria.nazwa || '-'} / ${pz.podkategoria.nazwa || '-'} / ${(pz.opis || '').substring(0, 100)}...`,
                    key: `${pz.id}_${i}_1`,
                    hasPointer: true,
                })
                harmonogramy.push({
                    date: h.koniecRealizacji,
                    label: `Etap${i} (koniec): ${pz.kategoria.nazwa || '-'} / ${pz.podkategoria.nazwa || '-'} / ${(pz.opis || '').substring(0, 100)}... ${h.czyWystepujePlatnoscCzesciowa ? ' / występuje płatność częściowa' : ''}`,
                    key:`${pz.id}_${i}_2`,
                    hasPointer: true,
                })
                i++;
            }
        }
        return harmonogramy
    }

    prepareChartData(harmonogramy) {
        const baseHeigth = 15;
        harmonogramy = listOrEmpty(harmonogramy);
        if (harmonogramy.length === 1) {
            harmonogramy[0]['height'] = 0;
        } else {
            const firstDate = harmonogramy[0].date;
            if (!firstDate.length) {    // every date is empty
                for (let obj of harmonogramy) {
                    obj['height'] = baseHeigth;
                }
            } else {
                let lastDate = '';
                for (let i = harmonogramy.length - 1; i >= 0; i--){
                    if (isNotEmpty(harmonogramy[i].date)) {
                        lastDate = harmonogramy[i].date;
                        break
                    }
                }

                const maxDistance = new Date(
                    datetimeStringToISO(lastDate)).getTime() - new Date(
                    datetimeStringToISO(firstDate)).getTime();
                const factor = (harmonogramy.length - 1) * 100;
                for (let i = 0; i < harmonogramy.length; i++) {
                    const obj = harmonogramy[i];
                    if (!obj.date.length) {
                        obj['height'] = baseHeigth;
                    } else {
                        const nextObj = harmonogramy[i + 1];
                        if (i < harmonogramy.length - 1 && nextObj.date !== null) {
                            if (obj.date === nextObj.date) {
                                obj['height'] = 0;
                                nextObj['hasPointer'] = false;
                            } else if (!nextObj.date.length) {
                                obj['height'] = baseHeigth;
                            } else {
                                const height = (new Date(
                                    datetimeStringToISO(nextObj.date)).getTime() - new Date(
                                    datetimeStringToISO(obj.date)).getTime()) / maxDistance * factor;
                                obj['height'] = height < 20 ? 20 : (height > 170 ? 170 : height);
                            }
                        } else {
                            obj['height'] = baseHeigth;
                        }
                    }
                }
            }
            //we don't want to have line after last element
            harmonogramy[harmonogramy.length - 1]['height'] = 0;
        }
        return harmonogramy
    }

handleSaveFileFromApi = (url, fileName) => {
        this.setState(
            () => saveFileFromApi(
                url,
				fileName)
        );
    }

    // rendering

    render() {
        const o = this.props.ogloszenie;
        const { ogloszenie } = o;
        const { nabor } = ogloszenie;
        const showVersionModeInfo = this.props.versionMode && o.status.label !== 'DRAFT' && ogloszenie.ostatniaOpublikowanaWersjaId !== o.id;
        return (
            <>
                {showVersionModeInfo && <Text className="success-text"><Icon icon="error" aria-hidden="true" style={{'marginRight': 3}} />To jest nieaktualna wersja ogłoszenia. Ta wersja została opublikowana dnia {o.dataOpublikowania}.</Text>}
                <Container className="details-preview">
                    <GridCustom flexL fullwidth noWrap>
                        <section className="details-preview__part details-preview__part--main">
                            {isNotEmpty(ogloszenie.projekty, 'list') && (
                                <FieldWithLabel label="Powstaje w kontekście projektu">
                                    {ogloszenie.projekty.map(p => <Text key={p.id}>{p.calyNumer} - {p.nazwa}</Text>)}
                                </FieldWithLabel>
                            )}
                            {!!nabor && (
                                <FieldWithLabel label="Powstaje w kontekście naboru">
                                    <Text>{nabor.calyNumer}{nabor.nazwa.length > 0 ? ` - ${nabor.nazwa}` : ''}</Text>
                                </FieldWithLabel>
                            )}
                            {isNotEmpty(o.zamowieniaUzupelniajace) && (
                                <FieldWithLabel label="Zamówienia uzupełniające">
                                    <Text className="long-text">{o.zamowieniaUzupelniajace}</Text>
                                </FieldWithLabel>
                            )}
                            {isNotEmpty(o.warunkiZmianyUmowy) && (
                                <FieldWithLabel label="Warunki zmiany umowy">
                                    <Text className="long-text">{o.warunkiZmianyUmowy}</Text>
                                </FieldWithLabel>
                            )}
                            {isNotEmpty(o.zalaczniki, 'list') && (
                                <FieldWithLabel label="Załączniki">
                                    {/* attachments are sorted by origin announcement version */}
                                    {o.zalaczniki.map(origin => this.renderOrigin(origin))}
                                </FieldWithLabel>
                            )}
                        </section>
                        <aside className="details-preview__part details-preview__part--aside">
                            <FieldWithLabel label="Czy dopuszczalna oferta częściowa?">
                                <Text>{o.czyDopuszczalnaOfertaCzesciowa === true ? 'TAK' : 'NIE'}</Text>
                            </FieldWithLabel>

                            {this.renderDaneAdresoweOgloszeniodawcy()}
                            {isNotEmpty(o.osobyDoKontaktu, 'list') && this.renderOsobyDoKontaktu()}
                        </aside>
                    </GridCustom>
                </Container>
                {this.renderZamowienia()}
                {this.renderPodsumowanie()}
                {(ogloszenie.jestAutorem || ogloszenie.isAdmin) &&
                    <AuditData {...this.props.auditData} />
                }
            </>
        )
    }

    renderOrigin(origin) {
        const ogloszenie = this.props.ogloszenie;
        let indexVersion = (ogloszenie.ogloszenie.all_available_version_ids || []).indexOf(origin.id);
        if (indexVersion > -1) {
            indexVersion++;
        } else {
            indexVersion = '';
        }
        let label = origin.id === ogloszenie.id ? (
            this.props.draftMode ? 'aktualnej wersji roboczej' : `obowiązującej wersji z dn. ${origin.dataOpublikowania}`
        ) : `wersji ${indexVersion} z dn. ${origin.dataOpublikowania}`;
        return this.renderAttachmentsForOrigin(origin, label)
    }

    renderAttachmentsForOrigin(origin, label) {
        return (
            <Fragment key={origin.id}>
                <Typography tag="h2" use="button" className="attachements__header">Dodane do ogłoszenia w {label}</Typography>
                <ol className="attachements__list">
                    {origin.zalaczniki.map((z, i) => {
                        if (z.nazwa.length || Object.keys(z.plik).length) {
                            return (
                                <li key={z.id} className="grid-custom attachements__download-item">
                                    <Text key={z.id}>
                                        {i + 1}. {z.nazwa || 'Załącznik bez nazwy'}
                                        {z.plik && z.plik.uri.length > 0 && <ButtonStyled className="btn--pdf-dowload" onClick={(ev) => this.handleSaveFileFromApi(z.plik.uri, z.plik.nazwa)}>Pobierz</ButtonStyled>}
                                    </Text>
                                </li>
                            )
                        } else {return null}
                    })}
                </ol>
            </Fragment>
        )
    }

    renderDaneAdresoweOgloszeniodawcy() {
        if (!this.props.ogloszenie.daneAdresoweOgloszeniodawcy) {
            return null
        }
        const daneAdresowe = this.props.ogloszenie.daneAdresoweOgloszeniodawcy || {};
        const adres = daneAdresowe.adres || {};
        return (
            <FieldWithLabel label="Dane adresowe ogłoszeniodawcy">
                <Text>{daneAdresowe.nazwa}</Text>
                <Text>{adres.ulica} {adres.numerDomu}</Text>
                <Text>{adres.kodPocztowy} {adres.miejscowosc}</Text>
                {adres.country === 'Polska' && (
                    <>
                        <Text>gmina/dzielnica: {adres.gmina}</Text>
                        <Text>powiat: {adres.powiat}</Text>
                        <Text>woj.: {adres.wojewodztwo}</Text>
                    </>
                )}
                {adres.kraj !== 'Polska' && (
                    <Text>{adres.kraj}</Text>
                )}
                <Text>{TYP_NUMERU_IDENTYFIKACYJNEGO[daneAdresowe.typNumeruIdentyfikacyjnego]}: {daneAdresowe.numerIdentyfikacyjny}</Text>
            </FieldWithLabel>
        )
    }

    renderOsobyDoKontaktu() {
        return (
            <FieldWithLabel label="Osoby do kontaktu">
                {this.props.ogloszenie.osobyDoKontaktu.map(osoba => {
                    return (
                        <div key={osoba.id} className="separate-content">
                            <Text>{getUserFullName(osoba)}</Text>
                            <Text>tel.: {osoba.numerTelefonu || '-'}</Text>
                            <Text>e-mail: {osoba.email}</Text>
                        </div>
                    )
                })}
            </FieldWithLabel>
        )
    }

    renderZamowienia() {
        const o = this.props.ogloszenie;

        if (!isNotEmpty(o.zamowienia)) {
            return (
                <>
                    <Text tag="h2" mainHeader>Części zamówienia</Text>
                    <Container>
                        <Text error>Brak zdefiniowanych części zamówienia</Text>
                    </Container>
                </>
            )
        }

        let tableOfContetns = null;

        if (o.zamowienia.length > 1) {
            tableOfContetns = (
                <Container>
                    <Text className="label">Spis treści</Text>
                    {o.zamowienia.map((z, i) => (
                        <button key={z.id} onClick={() => window.scrollTo(0, refs[i].current.offsetTop)} className="link-text announcement__part-link"><span className="sr-only">Przejdź do części: </span>Część {i + 1}{isNotEmpty(z.tytul) && `: ${z.tytul}`}</button>
                    ))}
                </Container>
            )
        }

        const refs = o.zamowienia.map(z => React.createRef());

        return (
            <>
                <Text tag="h2" mainHeader>Części zamówienia</Text>
                {tableOfContetns}
                <ul>
                    {o.zamowienia.map((z, i) => (
                        <li key={z.id} ref={refs[i]} className="announcement__part">
                            <GridCustom fullwidth noWrap flexTop flexM>
                                <section className="announcement__part-title">
                                    <Text mainHeader tag="h2">Część {i + 1}</Text>
                                    {isNotEmpty(z.tytul) && <Typography use="headline6" tag="h2">{z.tytul}</Typography>}
                                </section>
                                <section className="announcement__part-price">
                                    {isNotEmpty(z.szacunkowaWartosc) && (
                                        <FieldWithLabel label="Budżet">
                                            <Typography use="headline6">{z.szacunkowaWartosc.replace('.', ',')} PLN</Typography>
                                        </FieldWithLabel>
                                    )}
                                    <FieldWithLabel label="Czy dopuszczalne oferty wariantowe">
                                        <Text>{z.czyWariantowe === true ? 'TAK' : 'NIE'}</Text>
                                    </FieldWithLabel>
                                </section>
                            </GridCustom>
                            {this.renderPrzedmiotyZamowienia(z.przedmiotyZamowienia)}
                            {this.renderKryteriaOceny(z.kryteriaOceny)}
                        </li>
                    ))}
                </ul>
            </>
        )
    }

    renderPrzedmiotyZamowienia(przedmiotyZamowienia) {
        if (isNotEmpty(przedmiotyZamowienia, 'list')) {
            return (
                <>
                    <Text tag="h3" accentHeader>Przedmioty zamówienia</Text>
                    <ul>
                        {przedmiotyZamowienia.map((pz) => this.renderPrzedmiotZamowienia(pz))}
                    </ul>
                </>
            )
        }
        return (
            <>
                <Text tag="h3" accentHeader>Przedmioty zamówienia</Text>
                <Text error>Brak zdefiniowanych przedmiotów zamówienia</Text>
            </>
        )
    }

    renderPrzedmiotZamowienia(przedmiotZamowienia) {
        return <Box
                    fullwidth
                    key={przedmiotZamowienia.id}
                    headerTag="h4"
                    tag="li"
                    titleWithSubtitle={przedmiotZamowienia.kategoria.nazwa || '-'}
                    subtitle={przedmiotZamowienia.podkategoria.nazwa || '-'}
                >
                    {isNotEmpty(przedmiotZamowienia.opis) && (
                        <FieldWithLabel label="Opis">
                            <Text className="long-text">{przedmiotZamowienia.opis}</Text>
                        </FieldWithLabel>
                    )}
                    {isNotEmpty(przedmiotZamowienia.okresGwarancji) && (
                        <FieldWithLabel label="Okres gwarancji">
                            <Text>{przedmiotZamowienia.okresGwarancji}</Text>
                        </FieldWithLabel>
                    )}
                    {isNotEmpty(przedmiotZamowienia.kodyCPV, 'list') && this.renderKodyCPV(przedmiotZamowienia.kodyCPV)}
                    {isNotEmpty(przedmiotZamowienia.miejscaRealizacji, 'list') && this.renderMiejscaRealizacji(przedmiotZamowienia.miejscaRealizacji)}
                    {isNotEmpty(przedmiotZamowienia.harmonogramy, 'list') && this.renderHarmonogram(przedmiotZamowienia.harmonogramy)}
                    {isNotEmpty(przedmiotZamowienia.warunkiUdzialu, 'list') && this.renderWarunkiUdziału(przedmiotZamowienia.warunkiUdzialu)}
                </Box>
    }

    renderKodyCPV(kodyCPV) {
        return (
            <FieldWithLabel label="Kody CPV">
                {kodyCPV.map(kod_ => <Text key={kod_.id}>{kod_.kod} {kod_.nazwa}</Text>)}
            </FieldWithLabel>
        )
    }

    renderMiejscaRealizacji(miejscaRealizacji) {
        return (
            <FieldWithLabel label="Miejsca realizacji">
                {miejscaRealizacji.map(mr => {
                    let address = null;
                    if (mr.kraj || mr.wojewodztwo || mr.powiat || mr.gmina || mr.miejscowosc) {
                        address = (
                            <GridCustom fullwidth className=" separate-content">
                                {isNotEmpty(mr.kraj) && (
                                    <FieldWithLabel label="Kraj">
                                        <Text>{mr.kraj}</Text>
                                    </FieldWithLabel>
                                )}
                                {isNotEmpty(mr.wojewodztwo) && (
                                    <FieldWithLabel label="Województwo">
                                        <Text>{mr.wojewodztwo}</Text>
                                    </FieldWithLabel>
                                )}
                                {isNotEmpty(mr.powiat) && (
                                    <FieldWithLabel label="Powiat">
                                        <Text>{mr.powiat || '-'}</Text>
                                    </FieldWithLabel>
                                )}
                                {isNotEmpty(mr.gmina) && (
                                    <FieldWithLabel label="Gmina">
                                        <Text>{mr.gmina || '-'}</Text>
                                    </FieldWithLabel>
                                )}
                                {isNotEmpty(mr.miejscowosc) && (
                                    <FieldWithLabel label="Miejscowość">
                                        <Text>{mr.miejscowosc || '-'}</Text>
                                    </FieldWithLabel>
                                )}
                            </GridCustom>
                        );
                    }
                    return (
                        <div key={mr.id}>
                            <GridCustom centerVertical className=" separate-content">
                                <Text>{mr.typ.nazwa}</Text>
                            </GridCustom>
                            {address}
                        </div>
                    )
                })}
            </FieldWithLabel>
        )
    }

    renderHarmonogram(harmonogramy) {
        let i = 0;
        return (
            <>
                <Text tag="h2" accentHeader>Harmonogram</Text>
                <ul>
                    {harmonogramy.map((h) => {
                        i++;
                        return (
                            <li key={h.id} className="separate-content">
                                <Text info>Etap {i}</Text>
                                <GridCustom>
                                    {isNotEmpty(h.poczatekRealizacji) && (
                                        <FieldWithLabel label="Początek realizacji">
                                            <Text>{h.poczatekRealizacji}</Text>
                                        </FieldWithLabel>
                                    )}
                                    {isNotEmpty(h.koniecRealizacji) && (
                                        <FieldWithLabel label="Koniec realizacji">
                                            <Text>{h.koniecRealizacji}</Text>
                                        </FieldWithLabel>
                                    )}
                                    <FieldWithLabel label="Czy występuje płatność częściowa">
                                        <Text>{h.czyWystepujePlatnoscCzesciowa === true ? 'TAK' : 'NIE'}</Text>
                                    </FieldWithLabel>
                                </GridCustom>
                                {isNotEmpty(h.opis) && (
                                    <FieldWithLabel label="Opis">
                                        <Text className="long-text">{h.opis}</Text>
                                    </FieldWithLabel>
                                )}
                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }

    renderWarunkiUdziału(warunkiUdzialu) {
        return (
            <>
                <Text tag="h3" accentHeader>Warunki, jakie musi spełniać oferent</Text>
                {warunkiUdzialu.map((wu) =>
                    <div key={wu.id} className="separate-content">
                        {wu.typ.id !== null && (
                            <FieldWithLabel label="Typ">
                                <Text>{wu.typ.nazwa}</Text>
                            </FieldWithLabel>
                        )}
                        {isNotEmpty(wu.opis) && (
                            <FieldWithLabel label="Opis">
                                <Text className="long-text">{wu.opis}</Text>
                            </FieldWithLabel>
                        )}
                    </div>
                )}
            </>
        )
    }

    renderKryteriaOceny(kryteriaOceny) {
        if (!isNotEmpty(kryteriaOceny, 'list')) {
            return (
                <>
                    <Text tag="h3" accentHeader>Kryteria oceny</Text>
                    <Text error>Brak zdefiniowanych kryteriów oceny</Text>
                </>
            )
        }
        return (
            <>
                <Text tag="h3" accentHeader>Kryteria oceny</Text>
                <GridCustom tag="ul">
                    {kryteriaOceny.map((ko) => (
                        <Box key={ko.id} tag="li">
                            <Text label>Czy kryterium cenowe</Text>
                            <Text>{ko.czyKryteriumCenowe === true ? 'TAK' : 'NIE'}</Text>
                            {isNotEmpty(ko.opis) && (
                                <FieldWithLabel label="Opis">
                                    <Text className="long-text">{ko.opis}</Text>
                                </FieldWithLabel>
                            )}
                        </Box>
                    ))}
                </GridCustom>
            </>
        )
    }

    renderPodsumowanie() {
        return (
            <>
                <Text tag="h2" mainHeader>Podsumowanie</Text>
                <Text tag="h3" accentHeader>Oś czasu związana z ogłoszeniem i ofertowaniem</Text>
                {this.renderOgloszeniePodsumowanie()}

                <Text tag="h3" accentHeader>Oś czasu realizacji przedmiotów zamówienia</Text>
                {this.renderPrzedmiotyZamowieniaPodsumowanie()}
            </>
        )
    }

    renderOgloszeniePodsumowanie() {
        const o = this.props.ogloszenie;
        const harmonogram = [
            {
                date: o.dataOpublikowania || '',
                label: 'data opublikowania',
                key: `${o.id}_dataOpublikowania`,
                hasPointer: true,
            },
            {
                date: o.terminOfert || '',
                label: 'termin składania ofert',
                key: `${o.id}_terminOfert`,
                hasPointer: true,
            },
            {
                date: o.planowanyTerminPodpisaniaUmowy || '',
                dateForShowing: (dateStringToYearMonth(o.planowanyTerminPodpisaniaUmowy)),
                label: 'planowany termin podpisania umowy',
                key: `${o.id}_planowanyTerminPodpisaniaUmowy`,
                hasPointer: true,
            },
        ];
        return this.renderChartData(harmonogram)
    }

    renderPrzedmiotyZamowieniaPodsumowanie() {
        const przedmiotyZamowienia = this.getPrzedmiotyZamowieniaList();
        if (!przedmiotyZamowienia.length) {
            return this.renderTextError('Brak zdefiniowanych przedmiotów zamówienia.')
        }

        const harmonogramForRendering = this.getHarmonogramList(przedmiotyZamowienia);
        if (!harmonogramForRendering.length) {
            return this.renderTextError('Brak zdefiniowanych etapów dla przedmiotów zamówienia.')
        }

        harmonogramForRendering.sort((h1, h2) => {
            if (h1.date.length === 0) {return 1}
            if (h2.date.length === 0) {return -1}
            return h1.date < h2.date ? -1 : 1
        });

        return this.renderChartData(harmonogramForRendering);
    }

    renderChartData(harmonogramy) {
        this.prepareChartData(harmonogramy);
        return harmonogramy.map((h) => (
            <div key={h.key} className="time-line">
                <GridCustom noWrap>
                    <div className='time-line__icon'>
                        <Icon aria-hidden="true" icon={{ icon: h.hasPointer && 'date_range' }} />
                    </div>
                    <Text className="time-line__text">{h.dateForShowing || h.date || '????-??-??'} - {h.label}</Text>
                </GridCustom>
                <div className="time-line__time-container" style={{height: `${h.height}px`}}></div>
            </div>
        ))
    }

    renderTextError(text) {
        return <Text error>{text}</Text>
    }
}


export { OgloszenieDetails, TYP_NUMERU_IDENTYFIKACYJNEGO };
