import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchApi } from '../fetch';
import {
    getSerializedErrorsFromServer,
    getSerializedObject,
    serializeUrlParams,
    translateErrorMessage
} from '../serializers';


export const useOneTimeFetchApi = (config) => {
  const url = config.url || '';
  const fetchData = config.fetchData || {};
  const serializeFetchDataFunction = config.serializeFetchDataFunction ||
    (data => data);
  const updatedData = config.updatedData || null;
  const message403error = config.message403error || '';
  const callback403 = config.callback403 || (() => {});
  const requestId = config.requestId || 1;

  const [loadingOn, setLoadingOn] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [data, setData] = useState(null);

  function getDataFromServer() {
    setLoadingOn(true);
    setFetchError(null);

    let xhrFetch = null;

    function callbackSuccess(data) {
      xhrFetch = null;
      setData(serializeFetchDataFunction(data));
      setLoadingOn(false);
    }

    function callbackError(data) {
      xhrFetch = null;
      setFetchError(translateErrorMessage(data.message));
      setLoadingOn(false);
    }

    function callbackIncorrectStatus(status) {
      xhrFetch = null;
      if (status === 403) {
        if (message403error.length) {
          setFetchError(message403error);
        }
        callback403();
      } else {
        setFetchError(`Wystąpił nieoczekiwany błąd o kodzie ${status}.`);
      }
      setLoadingOn(false);
    }

    xhrFetch = fetchApi(
      url, 'GET', {}, fetchData,
      callbackSuccess, callbackError, callbackIncorrectStatus
    );
    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
    }
  }
  useEffect(getDataFromServer, [requestId]);

  useEffect(() => {
    if (updatedData === null) { return }
    setData(prevData =>
        ({...prevData, ...{uzytkownik: {...prevData.uzytkownik, ...updatedData}}})
    );
  }, [updatedData])

  return {data, loadingOn, fetchError}
};

const sortUrlParams = (param1, param2) => {
  return param1[0].localeCompare(param2[0], 'pl')
};

export const usePaginatedFetchApi = (
    pageNumber, selectedSort, filters, getFiltersFetchData, paginatedBy,
    objectsAttrName, baseApiUrl, serializationUrlDictToPolish,
    serializerConfig={}) => {
  let history = useHistory();
  const [permissionsCheckingOn, setPermissionsCheckingOn] = useState(true);
  const [notPermitted, setNotPermitted] = useState(false);
  const [loadingOn, setLoadingOn] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [data, setData] = useState(null);

  function getDataFromServer() {
    if (selectedSort === null) { return }
    setLoadingOn(true);
    setFetchError(null);
    let data = {
      page: pageNumber,
      sort: selectedSort.value,
      ...(filters !== null ? getFiltersFetchData(filters) : {}),
    };

    let urlParams = '?';
    const serializedUrlData = serializeUrlParams(
        data, serializationUrlDictToPolish);
    for (let [index, [k, v]] of Object.entries(
        serializedUrlData).sort(sortUrlParams).entries()) {
      urlParams += `${index > 0 ? '&' : ''}${k}=${v}`;
    }

    // it looks odd, but it's necessary, because some filters have to be set
    // as all and backend doesn't like "all"
    const dataWithoutAll = {};
    for (let [name, value] of Object.entries(data)) {
      if (value !== 'all') {
        dataWithoutAll[name] = value;
      }
    }

    const urlWithParams = `${window.location.pathname}${urlParams}`;
    if (window.location.pathname + window.location.search !== urlWithParams) {
        history.replace(urlWithParams);
    }

    function checkHasPrev() {
      return pageNumber > 1
    }

    function checkHasNext(total) {
      return Math.ceil(total / paginatedBy) > pageNumber
    }

    let xhrFetch = null;

    function callbackSuccess(data_) {
      xhrFetch = null;
      const { total } = data_.meta;
      setData({
        objects: getSerializedObject(
            data_, {mapToCamelCaseName: true, ...serializerConfig})[objectsAttrName] || [],
        total: total,
        hasPrev: checkHasPrev(),
        hasNext: checkHasNext(total),
      });
      if (permissionsCheckingOn) {
        setPermissionsCheckingOn(false);
      }
      setLoadingOn(false);
    }

    function callbackError(data_) {
      xhrFetch = null;
      setFetchError(data_.message);
      if (permissionsCheckingOn) {
        setPermissionsCheckingOn(false);
      }
      setLoadingOn(false);
    }

    function callbackIncorrectStatus(status) {
      xhrFetch = null;
      if (status === 403) {
        setNotPermitted(true);
        if (permissionsCheckingOn) {
          setPermissionsCheckingOn(false);
        }
        setLoadingOn(false);
        return
      }
      setFetchError(`Wystąpił nieoczekiwany błąd o kodzie ${status}.`);
      setLoadingOn(false);
    }

    xhrFetch = fetchApi(
      baseApiUrl, 'GET', {}, {...dataWithoutAll, limit: paginatedBy},
      callbackSuccess, callbackError, callbackIncorrectStatus
    );
    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
    }
  }
  useEffect(getDataFromServer, [pageNumber, selectedSort, filters])

  function updateData(objectId, data_) {
    setData(prevData => ({
      objects: prevData.objects.map(obj => {
          if (obj.id === objectId) {
              obj = {...obj, ...data_};
          }
          return obj
      }),
    }));
  }

  return {
    data, fetchError, loadingOn, notPermitted, permissionsCheckingOn,
    updateData
  }
}

export const usePutFetchApi = ({
  callbackPutSuccess,
  fetchData = {},
  loadingOn,
  message403error = null,
  setLoadingOn,
  setFetchError,
  url
}) => {

  function save() {
    if (!loadingOn) { return }
    setLoadingOn(true);
    setFetchError(null);

    let xhrFetch = null;

    function callbackSuccess(data) {
      xhrFetch = null;
      callbackPutSuccess(data);
      setLoadingOn(false);
    }

    function callbackError(data) {
      xhrFetch = null;
      setFetchError(data.message);
      setLoadingOn(false);
    }

    function callbackIncorrectStatus(status) {
      xhrFetch = null;
      setFetchError(
        status === 403 && message403error
          ? message403error
          : `Wystąpił nieoczekiwany błąd o kodzie ${status}.`
      );
      setLoadingOn(false);
    }

    xhrFetch = fetchApi(
      url, 'PUT', {}, fetchData,
      callbackSuccess, callbackError, callbackIncorrectStatus
    );
    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
    }
  }
  useEffect(save, [loadingOn]);

  return {}
};


export const usePutFormFetchApi = (config) => {

  const url = config.url || '';
  const fetchData = config.fetchData || {};
  const callbackPutSuccess = config.callbackSuccess || (() => {});
  const callbackObjectExists = config.callbackObjectExists || null;
  const loadingOn = config.loadingOn || false;
  const setLoadingOn = config.setLoadingOn || (() => {});
  const method = config.method || 'PUT';
  const defaultSaveBtnText = config.defaultSaveBtnText || 'Zapisz zmiany';
  const doNotTranslateNames = config.doNotTranslateNames || false;
  const message403error = config.message403error || '';
  const callback403 = config.callback403 || (() => {});

  const [fetchError, setFetchError] = useState(null);
  const [errors, setErrors] = useState({});
  const [saveBtnText, setSaveBtnText] = useState(defaultSaveBtnText);

  function save() {
    if (!loadingOn) { return }
    setLoadingOn(true);
    setFetchError(null);
    setErrors({});
    setSaveBtnText('Trwa zapisywanie zmian...');

    let xhrFetch = null;
    let saveMsgTimeout = null;

    function callbackSuccess(data) {
      xhrFetch = null;
      setSaveBtnText('Zapisano');
      saveMsgTimeout = setTimeout(() => {
        setSaveBtnText(defaultSaveBtnText);
      }, 5000);
      setLoadingOn(false);
      callbackPutSuccess(data);
    }

    function callbackError(data) {
      xhrFetch = null;
      if (data.message === 'permissions.store.set_exists' &&
          callbackObjectExists !== null) {
        callbackObjectExists();
      } else {
        setFetchError(translateErrorMessage(data.message));
      }
      setSaveBtnText(defaultSaveBtnText);
      setLoadingOn(false);
    }

    function callbackIncorrectStatus(status) {
      xhrFetch = null;
      if (method === 'PUT' && status === 404 && callbackObjectExists !== null) {
        callbackObjectExists();
      } else {
        let message;
        if (status === 403) {
            message = message403error;
            callback403();
        }
        setFetchError(
            message || `Wystąpił nieoczekiwany błąd o kodzie ${status}.`);
      }
      setSaveBtnText(defaultSaveBtnText);
      setLoadingOn(false);
    }

    function callbackShowErrors(errors) {
      xhrFetch = null;
      setErrors(getSerializedErrorsFromServer(errors, {doNotTranslateNames}));
      setSaveBtnText(defaultSaveBtnText);
      setLoadingOn(false);
    }

    xhrFetch = fetchApi(
      url, method, {}, fetchData,
      callbackSuccess, callbackError, callbackIncorrectStatus,
      callbackShowErrors
    );
    return () => {
      if (xhrFetch !== null) {xhrFetch.abort()}
      if (saveMsgTimeout !== null) {
        // TODO: it cleans timeout every time function ends, not as is expected
        clearTimeout(saveMsgTimeout);
        setSaveBtnText(defaultSaveBtnText);
      }
    }
  }
  useEffect(save, [loadingOn]);

  return {fetchError, errors, saveBtnText}
};
