import React, { Component } from 'react';

import { USER_DATA_COOKIE_NAME } from '../../../const';
import { UserContext } from '../../../context/user-context';
import { deleteCookie, getCookieValue } from '../../../helpers';
import { Message } from '../../common';


class Logowanie extends Component {

    constructor(props) {
        super(props);
        this.timeout = null;
    }

    componentWillUnmount() {
        // clear timeout
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }

    componentDidMount() {
        const cookie = getCookieValue(USER_DATA_COOKIE_NAME);
        if (cookie && cookie.length) {
            const userData = JSON.parse(atob(decodeURIComponent(cookie)));
            this.context.set(userData)
            deleteCookie(USER_DATA_COOKIE_NAME);
        }
        this.timeout = setTimeout(() => {
            this.redirectToCurrentPathname();
        }, 5000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.identifier !== this.props.user.identifier && this.props.user.identifier === null) {
            this.redirectToCurrentPathname();
        }
    }

    // helpers

    redirectToCurrentPathname() {
        let currentPathname = sessionStorage.getItem('currentPathname') || '/';
        if (currentPathname === '/blad-logowania') {
            currentPathname = '/';
        }
        this.props.history.push(currentPathname);
    }

    // rendering

    render() {
        return (
            <Message
                success
                messageIcon="check_circle_outline"
                messageTitle="Logowanie zakończone powodzeniem" >
                Następuje powrót do poprzedniej strony.
            </Message>
        )
    }
}

Logowanie.contextType = UserContext;


export { Logowanie };
