import React, { createContext } from 'react';

/*
    What is this for?
    This one is updated after link in menu was clicked. If it changes some
    components will be rerendered - in Routes.js define them th key as
    menuLinkKey and that's all what you need to refresh page content.
*/

const MenuContext = createContext({
    menuLinkKey: 0,
    refreshBadgesCount: 0,
    updateMenuKey: () => {},
    refreshBadges: () => {}
});


export class MenuProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuLinkKey: 0,
            updateMenuKey: this.updateMenuKey,
            refreshBadges: this.refreshBadges
        }
    }

    refreshBadges = () => {
        this.setState(prevState => ({refreshBadgesCount: prevState.refreshBadgesCount + 1}))
    }

    updateMenuKey = () => {
        this.setState(prevState => ({menuLinkKey: prevState.menuLinkKey + 1}));
    }

    render() {
        return (
            <MenuContext.Provider value={this.state}>
                {this.props.children}
            </MenuContext.Provider>
        );
    }
}

export const MenuConsumer = MenuContext.Consumer;
export { MenuContext };
