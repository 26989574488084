import React from 'react';
import { Document, Page, View } from '@react-pdf/renderer';

import { PdfHeader } from '../../common';
import { pdfStyles } from '../../vars/pdfStyles';
import { DraftToPdf } from './DraftToPdf';


const PdfWithDraftContent = (props) => {
    return (
        <Document>
            <Page size="A4" style={pdfStyles.mainPdfContainerStyle} wrap>
                <PdfHeader title={props.title} />
                {props.renderHeader && props.renderHeader()}
                <View style={pdfStyles.borderedSeparateSectionStyle}>
                    <DraftToPdf content={props.content}/>
                </View>
                {props.renderFooter && props.renderFooter()}
            </Page>
        </Document>
    )
}


export { PdfWithDraftContent };
