import React from 'react';

import { ButtonStyled } from './ButtonStyled';
import { GridCustom } from './GridCustom';


const Pagination = (props) => {
    const {
        currentPage,
        disabled,
        handleChangePage,
        hasNext,
        hasPrev
    } = props;

    return (
        <GridCustom centerVertical oneRow fullwidth className="pagination">
            <ButtonStyled disabled={disabled || !hasPrev} onClick={() => handleChangePage(false)} lite>Poprzednia<span className="sr-only"> strona</span></ButtonStyled>
            <span className="pagination__current">{currentPage}</span>
            <ButtonStyled disabled={disabled || !hasNext} onClick={() => handleChangePage(true)} lite>Następna<span className="sr-only"> strona</span></ButtonStyled>
        </GridCustom>
    )
}

export { Pagination };
