import React, { useContext } from 'react';

import { UserContext } from '../../../context/user-context';
import { Box } from '../Box';
import { FieldWithLabel } from '../FieldWithLabel';
import { Text } from '../Text';


const EMPTY_VALUE = 'Brak danych';


export const AuditData = ({
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    titleOneRow,
    ...boxProps
}) => {
    const { user } = useContext(UserContext);
    if (!user.identifier) { return null }
    return (
        <Box
            fullwidth
            titleOneRow={titleOneRow || 'Dane audytowe'}
            {...boxProps}
        >
            <section className="grid-custom grid-custom--flex-m grid-custom--full-width audit-meta">
                <>
                    <FieldWithLabel label="Kto utworzył">
                        <Text>{createdBy || EMPTY_VALUE}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Kiedy utworzył">
                        <Text>{createdAt || EMPTY_VALUE}</Text>
                    </FieldWithLabel>
                </>
                <>
                    <FieldWithLabel label="Kto modyfikował">
                        <Text>{modifiedBy || EMPTY_VALUE}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Kiedy modyfikował">
                        <Text>{modifiedAt || EMPTY_VALUE}</Text>
                    </FieldWithLabel>
                </>
            </section>
        </Box>
    )
};
