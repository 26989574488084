import React, { Component } from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { splitLineByChar } from '../../../helpers';
import { PdfHeader } from '../../common';
import { pdfStyles } from '../../vars/pdfStyles';


class OfertyResolvedStatusPdf extends Component {

    // helpers

    checkRefused(offerId) {
        let isRefused = false;
        for (let z of this.props.rozstrzygniecie.zamowieniaNodes) {
            for (let o of z.oferty) {
                if (o.id === offerId) {
                    isRefused = o.isRefused;
                    break
                }
            }
        }
        return isRefused
    }

    // rendering

    render() {
        return (
            <Document>
                <Page size="A4" style={pdfStyles.mainPdfContainerStyle} wrap>
                    <PdfHeader />
                    <View>
                        <Text style={pdfStyles.labelStyle}>Rozstrzygnięcie ogłoszenia</Text>
                        <Text>{this.props.tytulOgloszenia}</Text>
                        {this.renderContent()}
                        {this.renderKomentarz()}
                    </View>
                </Page>
            </Document>
        )
    }

    renderContent() {
        if (this.props.zamowienia.length > 1) {
            return this.props.zamowienia.map(z => this.renderZamowienie(z, true))
        }
        return this.renderZamowienie(this.props.zamowienia[0], false)
    }

    renderZamowienie(zamowienie, isMultiply) {
        return (
            <View key={zamowienie.id}>
                <Text style={pdfStyles.labelHeaderStyle}>
                    Rozstrzygnięcie{isMultiply ? ` części: ${zamowienie.tytul}` : ''}
                </Text>
                {this.renderSelectedOferta(zamowienie, isMultiply)}
                {this.renderOferty(zamowienie, isMultiply)}
            </View>
        )
    }

    renderSelectedOferta(zamowienie, isMultiply) {
        const zamowienieFromRozstrzygniecie = (
            this.props.rozstrzygniecie.zamowieniaNodes || []).filter(
                z => z.id === zamowienie.zamowienieNode.id)[0];
        if (!zamowienieFromRozstrzygniecie || !zamowienieFromRozstrzygniecie.wybranyWariant) {
            return <Text>{isMultiply ? `Dla części: "${zamowienie.tytul}" nie` : 'Nie'} wybrano żadnej oferty.</Text>
        }

        const selectedWariantId = zamowienieFromRozstrzygniecie.wybranyWariant.id;
        let selectedPakietOfert = null;
        let selectedOferta = null;
        let selectedWariant = null;
        for (let po of this.props.pakietyOfert) {
            for (let o of po.oferty) {
                for (let w of o.warianty) {
                    if (w.id === selectedWariantId) {
                        selectedPakietOfert = po;
                        selectedOferta = o;
                        selectedWariant = w;
                        break
                    }
                }
                if (selectedWariant !== null) {
                    break
                }
            }
            if (selectedWariant !== null) {
                break
            }
        }

        if (!selectedWariant) {
            return <Text error role="alert">Wystąpił nieoczekiwany błąd.</Text>
        }

        let header;
        if (isMultiply) {
            header = `Dla części: "${zamowienie.tytul}" została wybrana oferta`;
        } else {
            header = 'Została wybrana oferta';
        }

        const oferta_ = {
            id: selectedOferta.id,
            danePodmiotu: selectedPakietOfert.danePodmiotu,
            warianty: [selectedWariant, ],
        }

        return (
            <>
                <Text style={pdfStyles.labelStyle}>{header}</Text>
                {this.renderOfertaBox(oferta_, [selectedWariant,], true)}
            </>
        )
    }

    renderCena(warianty) {
        let cena;
        if (warianty.length > 1) {
            cena = (
                <>
                    {warianty.map((w, i) => (
                        <View key={i}>
                            <Text>{i + 1}. {w.cena} PLN</Text>
                            {splitLineByChar(w.opis).map((line, i) => <Text key={i} style={styles.offerVariantDescrStyle}>{line}</Text>)}
                        </View>
                    ))}
                 </>
            );
        } else {
            cena = <Text>{warianty[0].cena} PLN</Text>;
        }
        return (
            <>
                <Text style={pdfStyles.labelStyle}>Cena</Text>
                {cena}
            </>
        )
    }

    renderOferty(zamowienie, isMultiply) {
        let oferty = [];
        for (let po of this.props.pakietyOfert) {
            for (let o of po.oferty) {
                if (o.zamowienieNode.id === zamowienie.zamowienieNode.id) {
                    let oferta = Object.assign({}, o, {
                        oferentId: po.ktoUtworzyl.id,
                        danePodmiotu: po.danePodmiotu,
                        zalaczniki: po.zalaczniki,
                        isRefused: this.checkRefused(o.id),
                    });
                    oferty.push(oferta);
                    break
                }
            }
        }

        if (!oferty.length) {
            return <Text>Nie złożono żadnej oferty{isMultiply ? ` do części: ${zamowienie.tytul}` : ''}</Text>
        }

        // exclude selected offer

        const selectedWariantId = ((this.props.rozstrzygniecie.zamowieniaNodes || []).filter(
            z => z.id === zamowienie.zamowienieNode.id)[0] || {}).wybranyWariant.id;

        let filteredOferty = [];
        for (let o of oferty) {
            let oferta = Object.assign({}, o);
            oferta.warianty = o.warianty.filter(w => w.id !== selectedWariantId);
            filteredOferty.push(oferta)
        }
        filteredOferty = filteredOferty.filter(o => o.warianty.length > 0);

        if (!filteredOferty.length) {
            return <Text>Nie złożono innych ofert{isMultiply ? ` do części: ${zamowienie.tytul}` : ''}</Text>
        }

        return (
            <View style={styles.otherOffersContainerStyle}>
                <Text>Pozostałe oferty{isMultiply ? ` do części: "${zamowienie.tytul}"` : ''}</Text>
                <Text style={pdfStyles.textInfoStyle}>Liczba wyników: {filteredOferty.length}</Text>
                {filteredOferty.sort(this.sortOferty).map(o => this.renderOfertaBox(o, o.warianty))}
            </View>
        )
    }

    renderOfertaBox(oferta, warianty, selected=false) {
        const { borderedDashedSectionStyle, labelStyle } = pdfStyles;
        const danePodmiotu = oferta.danePodmiotu;
        const adres = danePodmiotu.adres;
        return (
            <View key={oferta.id} style={selected ? styles.selectedOfferStyle : borderedDashedSectionStyle}>
                {oferta.isRefused && <Text style={labelStyle}>Odrzucona</Text>}
                <Text style={labelStyle}>Nazwa podmiotu</Text>
                <Text>{danePodmiotu.nazwa}</Text>
                <Text style={labelStyle}>Miejscowość</Text>
                <Text>{`${adres.miejscowosc}${adres.kraj === 'Polska' ? '' : ', ' + adres.kraj}`}</Text>
                {this.renderCena(warianty)}
            </View>
        )
    }

    renderKomentarz() {
        if (!(this.props.rozstrzygniecie.komentarz || '').length) { return null }
        return (
            <View>
                <Text style={pdfStyles.labelStyle}>Komentarz</Text>
                {splitLineByChar(this.props.rozstrzygniecie.komentarz).map((line, i) => <Text key={i} style={styles.commentTextStyle}>{line}</Text>)}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    selectedOfferStyle: {
        padding: 10,
        marginBottom: 30,
        borderWidth: 2,
        borderColor: '#666A6D'
    },
    otherOffersContainerStyle: {
        marginTop: 20
    },
    offerVariantDescrStyle: {
        paddingLeft: 10,
        marginBottom: 8
    },
    commentTextStyle: {
        whiteSpace: 'pre-line',
    }
});


export { OfertyResolvedStatusPdf };
