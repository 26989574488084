import React from 'react';
import { Elevation } from '@rmwc/elevation';
import { ThemeProvider } from '@rmwc/theme';
import { Toolbar, ToolbarRow, ToolbarTitle } from '@rmwc/toolbar';
import '@material/toolbar/dist/mdc.toolbar.css';

import { colors } from '../vars/vars';


const Container = (props) => {
    const {
        containerTag,
        children,
        className,
        style,
        titleTag,
        title
    } = props;

    const colorsToolbar = {
        primary: colors.primary,
    }


    let toolbar;
    if (title) {
        toolbar = (
            <ThemeProvider options={colorsToolbar}>
                <Toolbar theme='primaryBg'>
                    <ToolbarRow>
                        <ToolbarTitle tag={titleTag ? titleTag : 'h2'}>{title}</ToolbarTitle>
                    </ToolbarRow>
                </Toolbar>
            </ThemeProvider>
        )
    }

    let containerClassName = 'container ';
    className && (containerClassName += className);

    return (
        <Elevation
            z='2'
            tag={containerTag || 'section'}
            className={containerClassName}
            theme='background'
            style={style}>
            {toolbar}
            <div className="container__content">
                {children}
            </div>
        </Elevation>
    );
}

export { Container };
