import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';

import { AdvertisementConsumer } from '../../../context/advertisementContext';
import { dateToString } from '../../../helpers';
import {
    ButtonStyled,
    DateCalendar,
    DialogCustom,
    GridCustom,
    FieldWithLabel,
    Text,
    TextFieldCustom
} from '../../common';


class FormHarmonogram extends Component{

    constructor(props) {
        super(props);

        if (props.isNew) {
            this.state = Object.assign({}, props.harmonogram, {
                poczatekRealizacji: '',
                koniecRealizacji: '',
                opis: '',
                czyWystepujePlatnoscCzesciowa: false,
                isPickerOpen: false,
            });
        } else {
            this.state = Object.assign(
                {isPickerOpen: false}, props.harmonogram);
        }
    }

    // basic functions

    componentDidMount() {
        window.formOgloszenie.computeProgress();
    }

    componentDidUpdate(prevProps, prevState) {
        if (window.formOgloszenie) {
            window.formOgloszenie.computeProgress();
        }
    }

    // helpers

    handleEscPress = (event) => {
    if (event.keyCode === 27) {
        if (this.state.isPickerOpen) {
            event.nativeEvent.stopImmediatePropagation();
        } else {
            this.props.onCloseForm();
        }
    }
}

    // handlers

    handleSave = () => {
        const {isNew, harmonogram} = this.props;
        this.props.onSave({
            id: isNew ? null : harmonogram.id,
            temporaryId: harmonogram.temporaryId,
            przedmiotZamowieniaTemporaryId: this.props.przedmiotZamowieniaTemporaryId,
            poczatekRealizacji: this.state.poczatekRealizacji,
            koniecRealizacji: this.state.koniecRealizacji,
            opis: this.state.opis.trim(),
            czyWystepujePlatnoscCzesciowa: this.state.czyWystepujePlatnoscCzesciowa,
        }, isNew);
    }

    handleCloseDialog = () => {
        this.props.onCloseForm();
    }

    handleCalendarOpen = () => {
        this.setState({isPickerOpen: true})
    }

    handleCalendarClose = () => {
        this.setState({isPickerOpen: false})
    }

    // rendering

    render() {
        const poczatekInputRef = React.createRef();
        const koniecInputRef = React.createRef();
        return (
            <AdvertisementConsumer>
                {({formErrors, clearAttributeError}) => {
                    const temporaryId = this.props.harmonogram.temporaryId;
                    const errors = formErrors.harmonogramy[temporaryId] || {};
                    const koniecRealizacjiErrorVisible = (errors.koniecRealizacji || '').length > 0;
                    return (
                        <DialogCustom 
                            className="dialog"
                            style={{ zIndex: 100 }}
                            onClose={this.handleCloseDialog}
                            onKeyDown={this.handleEscPress}
                        >
                            <>
                                <Text className="dialog__title-custom">Dodaj etap</Text>
                                <FieldWithLabel label="Opis" tag="label" labelFor="harmonogram_opis_id">
                                    <TextFieldCustom
                                        aria-label={`Opis, maksymalna liczba znaków: 4000. Wpisano ${this.state.opis.length} znaków.`}
                                        aria-valuemax={4000}
                                        clearFieldContext="opis"
                                        characterCount
                                        fullwidth
                                        id="harmonogram_opis_id"
                                        maxLength={4000}
                                        onChange={(event) => this.setState({opis: event.target.value})}
                                        onClear={(ev) => this.setState({opis: ''})}
                                        textarea
                                        value={this.state.opis}
                                    />
                                </FieldWithLabel>
                                <FieldWithLabel label="Początek" tag="label" inputRef={poczatekInputRef}>
                                    <DateCalendar
                                        dateName="Początek"
                                        fullwidth
                                        parentRef={poczatekInputRef}
                                        value={Date.parse(this.state.poczatekRealizacji)}
                                        onCalendarOpen={this.handleCalendarOpen}
                                        onCalendarClose={this.handleCalendarClose}
                                        onChange={(date) => this.setState({poczatekRealizacji: dateToString(date)})} />
                                </FieldWithLabel>
                                <FieldWithLabel label="Koniec" tag="label" inputRef={koniecInputRef}>
                                    <DateCalendar
                                        ariaDescribedby={koniecRealizacjiErrorVisible ? 'koniecRealizacji_error' : null}
                                        dateName="Koniec"
                                        fullwidth
                                        invalid={koniecRealizacjiErrorVisible}
                                        parentRef={koniecInputRef}
                                        value={Date.parse(this.state.koniecRealizacji)}
                                        onCalendarOpen={this.handleCalendarOpen}
                                        onCalendarClose={this.handleCalendarClose}
                                        onChange={(date) => this.setState(
                                            {koniecRealizacji: dateToString(date)},
                                            () => {
                                                if (koniecRealizacjiErrorVisible) {
                                                    clearAttributeError('harmonogramy', temporaryId, 'koniecRealizacji')       // it's possible to change value by clicking clear button
                                                }
                                            }
                                        )}
                                        onFocus={(ev) => {clearAttributeError('harmonogramy', temporaryId, 'koniecRealizacji')}} />
                                    {koniecRealizacjiErrorVisible && <Text error id="koniecRealizacji_error">{errors.koniecRealizacji}</Text>}
                                </FieldWithLabel>
                                <Checkbox
                                    label="Płatność częściowa"
                                    checked={this.state.czyWystepujePlatnoscCzesciowa}
                                    onChange={(event) => this.setState({czyWystepujePlatnoscCzesciowa: event.target.checked})} />
                                <GridCustom flexEnd>
                                    <ButtonStyled onClick={this.handleCloseDialog} cancel>Anuluj</ButtonStyled>
                                    <ButtonStyled id="potwierdz_zapisz_harmonogram_button_id" save onClick={this.handleSave}>{this.props.isNew ? 'Dodaj' : 'Zapisz zmiany'}</ButtonStyled>
                                </GridCustom>
                                {(errors.koniecRealizacji || '').length > 0 && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
                            </>
                        </DialogCustom>
                    )
                }}
            </AdvertisementConsumer>
        )
    }
}


export { FormHarmonogram };
