import React, { useEffect, useState } from 'react';

import { fetchApi } from '../../../fetch';
import {
    getImplementationLevelLabels,
    PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT
} from '../../../helpers_permissions';
import { API_URLS } from '../../../urls/api';
import { ButtonStyled, DialogCustom, GridCustom } from '../../common';


export const UserPermissionsRemovingPermissionDialog = ({
    permission, questionSuffix = '', userId,
    onCloseRemovedPermissionDialog, onRemovePermissionSuccess
}) => {

    const [removingPermissionOn, setRemovingPermissionOn] = useState(false);
    const [fetchError, setFetchError] = useState('');

    function removePermission() {
        if (!removingPermissionOn) {
            return
        }

        let xhrFetch = null;

        function callbackSuccess(data) {
            xhrFetch = null;
            // true means removingWithSuccess
            onRemovePermissionSuccess(data, true);
        }

        function setError(message) {
            xhrFetch = null;
            setRemovingPermissionOn(false);
            setFetchError(message);
        }

        function callbackError(data) {
            setError(`Nie udało się usunąć uprawnienia. ${data.message}`);
        }

        function callbackIncorrectStatus(status) {
            setError(status === 403
                ? 'Nie masz uprawnień do usunięcia tego uprawnienia.'
                : `Nie udało się usunąć uprawnienia. Wystąpił błąd o kodzie ${status}.`
            );
        }

        let fetchDataDict = {};
        let url = '';
        if (permission.isImplementationLevelType) {
            const {permission: permission_} = permission;
            for (let name of [
                'perspective', 'programme', 'priority', 'activity', 'region',
                'institution'
            ]) {
                fetchDataDict[name] =
                    permission_[name] ? permission_[name].id : null;
            }
            url = API_URLS.usersPermissionsAdvertisementsProjectsEnrollments.path;
        } else {
            switch (permission.permission.type) {
                case 'usersAdministration':
                    url = API_URLS.usersPermissionsUsers.path;
                    break
                case 'contentAdministration':
                    url = API_URLS.usersPermissionsContent.path;
                    break
                case 'implementationLevelsDictionaryAdministration':
                    url = API_URLS.usersPermissionsDictionaries.path;
                    break
                default:
                    url = '';
            }
        }

        xhrFetch = fetchApi(
            url, 'DELETE', {},
            {authorized: parseInt(userId) , ...fetchDataDict},
            callbackSuccess, callbackError,
            callbackIncorrectStatus
        );

        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }
    useEffect(removePermission, [removingPermissionOn]);

    let permissionDescription = '';
    if (permission !== null) {
        if (permission.isImplementationLevelType) {
            const implementationLevelLabels = getImplementationLevelLabels(
                permission.permission);
            permissionDescription = `${implementationLevelLabels.code} ${implementationLevelLabels.name}`;
        } else {
            permissionDescription =
                PERMISSION_CONTENT_DICTIONARIES_USERS_TRANSLATION_DICT[
                    permission.permission.type
                ];
        }
    }
    const isError = fetchError.length > 0;
    return (
        <DialogCustom
            className="dialog"
            dialogTitle={isError
                ? ''
                : `Czy na pewno chcesz usunąć uprawnienie "${permissionDescription}"${questionSuffix}?`
            }
            dialogTitleError={isError ? fetchError : ''}
            onClose={onCloseRemovedPermissionDialog}>
            {isError
                ? (
                   <GridCustom centerVertical flexEnd>
                        <ButtonStyled
                            primary
                            onClick={onCloseRemovedPermissionDialog}>
                            OK
                        </ButtonStyled>
                    </GridCustom>
                )
                : (
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled
                            cancel
                            disabled={removingPermissionOn}
                            onClick={onCloseRemovedPermissionDialog}>
                            Anuluj
                        </ButtonStyled>
                        <ButtonStyled
                            disabled={removingPermissionOn}
                            remove
                            onClick={() => setRemovingPermissionOn(true)}>
                            {removingPermissionOn
                                ? 'Trwa usuwanie uprawnienia'
                                : 'Usuń uprawnienie'
                            }
                        </ButtonStyled>
                    </GridCustom>
                )
            }
        </DialogCustom>
    )
};
