import React from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { getLastTemporaryId, getSortedOrderItemsFromOrder,
         listOrEmpty } from '../../../helpers';
import { GridCustom, Steps, Text } from '../../common';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { AlreadyPublishedInfo } from './AlreadyPublishedInfo';
import { BaseStepComponent } from './BaseStepComponent';
import { WarunkiUdzialu } from './WarunkiUdzialu';


class StepWarunki extends BaseStepComponent {
    stepName = 'warunki';

    // rendering

    render() {
        const { isAlreadyPublished, isPreview } = this.props;
        const formLocked = isPreview || this.context.validationOn || this.context.savingOn;
        const o = this.context.ogloszenie;
        const przedmiotyZamowienia = isPreview ? [] : getSortedOrderItemsFromOrder(o.zamowienia);

        let przedmiotyZamowienia_;
        if (przedmiotyZamowienia.length) {
            przedmiotyZamowienia_ = przedmiotyZamowienia.map(pz =>
                <WarunkiUdzialu
                    isAlreadyPublished={isAlreadyPublished}
                    formLocked={formLocked}
                    key={pz.temporaryId}
                    lastTemporaryId={getLastTemporaryId(
                        przedmiotyZamowienia, 'warunkiUdzialu')}
                    opis={pz.opis}
                    podkategoria={pz.podkategoria}
                    przedmiotZamowieniaTemporaryId={pz.temporaryId}
                    typ={pz.kategoria}
                    warunkiUdzialu={listOrEmpty(pz.warunkiUdzialu)}
                    onSaveWarunekUdzialu={this.context.saveWarunekUdzialu}
                    onRemoveWarunekUdzialu={this.context.removeWarunekUdzialu} />
            )
        } else if ((this.context.formErrors.ogloszenie.przedmiotyZamowienia_warunkiUdzialu || '').length) {
            przedmiotyZamowienia_ = <Text error>Zdefiniuj przedmioty zamówienia, aby móc dodawać do nich warunki.</Text>
        }

        return (
            <>
                <PomocEkranowa ekran="form_announcement_conditions" content={this.props.pomocEkranowaContent} />
                {isAlreadyPublished && <AlreadyPublishedInfo />}
                <GridCustom tag="ul">{przedmiotyZamowienia_}</GridCustom>
                <Steps
                    disabled={formLocked}
                    elementToFocusRef={this.props.elementToFocusRef}
                    hasNext
                    hasPrev
                    nextLabel="Części i kryteria"
                    prevLabel="Terminy"
                    onNextClick={() => this.props.onChangeCurrentMenuLink('czesciKryteria')}
                    onPrevClick={() => this.props.onChangeCurrentMenuLink('terminy')}
                />
            </>
        )
    }
}


StepWarunki.contextType = AdvertisementContext;


export { StepWarunki }
