import React from 'react';
import { Typography } from '@rmwc/typography';

import { FieldWithLabel, GridCustom, Text } from '../../common';


const DanePodmiotu = (props) => {
    const danePodmiotu = props.danePodmiotu;
    const adres = danePodmiotu.adres;
    return (
        <>
            <Typography use="headline5" className="long-text">{danePodmiotu.nazwa}</Typography>
            <GridCustom flexM fullwidth className="company-meta">
                <FieldWithLabel label="Rodzaj adresu">
                    <Text>{danePodmiotu.rodzajAdresu.label}</Text>
                </FieldWithLabel>
                <FieldWithLabel label={danePodmiotu.typNumeruIdentyfikacyjnego.label}>
                    <Text>{danePodmiotu.numerIdentyfikacyjny}</Text>
                </FieldWithLabel>
                {danePodmiotu.rodzajAdresu.value === 'abroad' && (
                    <FieldWithLabel label="Kraj">
                        <Text>{adres.kraj}</Text>
                    </FieldWithLabel>
                )}
                <FieldWithLabel label="Miejscowość">
                    <Text>{adres.kodPocztowy} {adres.miejscowosc}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Adres">
                    <Text>{adres.ulica || ''} {adres.numerDomu || ''}</Text>
                </FieldWithLabel>
            </GridCustom>
        </>
    );
}


export { DanePodmiotu };
