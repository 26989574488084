import React, { useContext } from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { ButtonStyled, GridCustom, FieldWithLabel, Text } from '../../common';


const MiejsceRealizacji = (props) => {
    const ogloszeniaContext = useContext(AdvertisementContext)

    const {
        gmina,
        kraj,
        miejscowosc,
        powiat,
        temporaryId,
        typ,
        wojewodztwo,
    } = props.miejsceRealizacji;

    let address = null;
    if (typ === ogloszeniaContext.adresId) {
        address = (
            <GridCustom fullwidth>
                <FieldWithLabel label="Kraj">
                    <Text>{kraj}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Województwo">
                    <Text>{wojewodztwo}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Powiat">
                    <Text>{powiat || '-'}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Gmina">
                    <Text>{gmina || '-'}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Miejscowość">
                    <Text>{miejscowosc || '-'}</Text>
                </FieldWithLabel>
            </GridCustom>
        );
    } else if (typ === ogloszeniaContext.zagranicaId) {
        address = (
            <GridCustom fullwidth>
                <FieldWithLabel label="Kraj">
                    <Text>{kraj || '-'}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Miejscowość">
                    <Text>{miejscowosc || '-'}</Text>
                </FieldWithLabel>
            </GridCustom>
        );
    }

    return (
        <li className="separate-content">
            <GridCustom centerVertical fullwidth>
                <Text>{ogloszeniaContext.typMiejscaRealizacji[typ]}</Text>
                <ButtonStyled onClick={() => props.removeMiejsceRealizacji(temporaryId)} remove className="btn--delete">
                    Usuń<span className="sr-only"> miejsce realizacji</span>
                </ButtonStyled>
            </GridCustom>
            {address}
        </li>
    )
};


export { MiejsceRealizacji };
