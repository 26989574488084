import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';

import {
    ButtonStyled,
    Container,
    GridCustom,
    Text,
} from '../../common';
import { FormDanePodmiotuFields, Zalaczniki } from '../common';
import { FormWariant } from './FormWariant';

const MAX_ATTACHMENTS_NUMBER = 20;
const MAX_WARIANTY_NUMBER = 20;


class FormOfertaFields extends Component {

    // helpers

    getWariantLasttemporaryId() {
        let lastTemporaryId = 0;
        for (let o of Object.values(this.props.oferty)) {
            for (let w of o.warianty) {
                if (w.temporaryId > lastTemporaryId) {
                    lastTemporaryId = w.temporaryId;
                }
            }
        }
        return lastTemporaryId
    }

    getEmptyOferta() {
        return {
            czyWariantowe: false,
            warianty: [this.getEmptyWariant(), ],
        }
    }

    getEmptyWariant() {
        return {
            id: null,
            temporaryId: this.getWariantLasttemporaryId(),
            cena: '',
            opis: '',
        }
    }

    // rendering

    render() {
        return (
            <>
                {this.renderDanePodmiotu()}
                {this.renderOferty()}
                {this.renderZalaczniki()}
            </>
        )
    }

    renderDanePodmiotu() {
        if (!this.props.withDanePodmiotuForm) { return null }
        return (
            <Container className="details-preview separate-container">
                <GridCustom flexEnd fullwidth>
                    <Text tag="h2" mainHeader>Dane podmiotu</Text>
                </GridCustom>
                <FormDanePodmiotuFields
                    data={this.props.danePodmiotu}
                    errors={this.props.errors.danePodmiotu || {}}
                    formLocked={this.props.formLocked}
                    changeAttribute={this.props.changeAttribute}
                    changeMiejscowosc={this.props.changeMiejscowosc}
                    changeNumerIdentyfikacyjny={this.props.changeNumerIdentyfikacyjny}
                    changeRodzajAdresu={this.props.changeRodzajAdresu}
                />
            </Container>
        )
    }

    renderOferty() {
        const zamowienia = this.props.zamowienia;
        const ofertyErrors = (this.props.errors.oferty || {}).errors || [];
        const isOfertyInvalid = ofertyErrors.length > 0;

        return (
            <Container className="separate-container">
                <GridCustom flexEnd fullwidth>
                    <Text tag="h2" mainHeader>Dane oferty</Text>
                </GridCustom>
                {isOfertyInvalid && <ul>{ofertyErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                {zamowienia.length > 1 ? (
                    zamowienia.map(z => this.renderMultiplyOferta(z))
                ) : this.renderSingleOferta(zamowienia[0])}
            </Container>
        )
    }

    renderMultiplyOferta(zamowienie) {
        const zamowienieNodeId = zamowienie.zamowienieNode.id;
        const oferta = this.props.oferty.filter(
            o => o.zamowienieNode === zamowienieNodeId)[0] || null;
        const isChecked = oferta !== null;
        const formLocked = this.props.formLocked;
        const czyWariantoweErrors = oferta === null ? {} : ((this.props.errors.oferty || {})[oferta.temporaryId] || {}).czyWariantowe || [];
        const isCzyWariantoweInvalid = oferta === null ? true : czyWariantoweErrors.length > 0;
        return (
            <Container key={zamowienieNodeId} className={!isChecked ? 'container--no-border' : null}>
                <GridCustom fullwidth flexM className="offer__btn-container">
                    <div className="offer__part-name">
                        <Checkbox
                            aria-label={`Dodaj ofertę częściową do cześci ${ zamowienie.tytul }`}
                            checked={isChecked}
                            className="checkbox--btn"
                            disabled={formLocked}
                            label="Dodaj ofertę częściową do części"
                            onChange={event => this.props.changeAddOferta(zamowienieNodeId, event.target.checked)}
                        />
                        <Text label>{zamowienie.tytul}</Text>
                    </div>
                    {zamowienie.czyWariantowe && isChecked && oferta !== null && (
                        <>
                            <Checkbox
                                aria-describedby={isCzyWariantoweInvalid ? 'czyWariantowe_error' : null}
                                checked={oferta.czyWariantowe}
                                className="checkbox--btn"
                                disabled={formLocked}
                                label={'Oferta wariantowa'}
                                onChange={event => this.props.changeCzyWariantowa(zamowienieNodeId, event.target.checked)}
                            />
                            {isCzyWariantoweInvalid && (
                                <>
                                    <ul id="czyWariantowe_error">{czyWariantoweErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>
                                    <span role="alert" className="sr-only">Formularz zawiera błędy!</span>
                                </>
                            )}
                        </>
                    )}
                </GridCustom>
                {isChecked && this.renderWarianty(zamowienie, oferta)}
            </Container>
        )
    }

    renderSingleOferta(zamowienie) {
        if (!zamowienie) { return null }
        const oferta = this.props.oferty.filter(
            o => o.zamowienieNode === zamowienie.zamowienieNode.id)[0] || null;
        if (!oferta) { return null }
        return (
            <Container>
                <GridCustom fullwidth flexM className="offer__btn-container">
                    <div className="offer__pz">
                        <Text accentHeader>Dodaj ofertę do zamówienia</Text>
                        <Text label>{zamowienie.tytul}</Text>
                    </div>
                    {zamowienie.czyWariantowe && (
                        <Checkbox
                            checked={oferta.czyWariantowe}
                            className="checkbox--btn"
                            disabled={this.props.formLocked}
                            label={'Oferta wariantowa'}
                            onChange={event => this.props.changeCzyWariantowa(zamowienie.zamowienieNode.id, event.target.checked)}
                        />
                    )}
                </GridCustom>
                {this.renderWarianty(zamowienie, oferta)}
            </Container>
        )
    }

    renderWarianty(zamowienie, oferta) {
        if (zamowienie.czyWariantowe) {
            const zamowienieNodeId = zamowienie.zamowienieNode.id;
            const formLocked = this.props.formLocked;
            const wariantyErrors = ((this.props.errors.oferty || {})[oferta.temporaryId] || {}).warianty || {};
            const isWariantyInvalid = (wariantyErrors.errors || []).length > 0;
            return (
                <>
                    {oferta.czyWariantowe && (
                        <>
                            <GridCustom fullwidth flexTop>
                                <Text tag="h2" sectionHeader>Lista wariantów</Text>
                                {oferta.warianty.length < MAX_WARIANTY_NUMBER && (
                                    <ButtonStyled
                                        add
                                        disabled={formLocked}
                                        onClick={() => this.props.onAddWariant(zamowienieNodeId)} >
                                        Dodaj wariant oferty
                                    </ButtonStyled>
                                )}
                            </GridCustom>
                            <Text info className="text--counter">Liczba wyników: {oferta.warianty.length}</Text>
                            <Text info>Możesz dodać maksymalnie {MAX_WARIANTY_NUMBER} wariantów.</Text>
                            {isWariantyInvalid && (
                                <>
                                    <ul>{wariantyErrors.errors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>
                                    <span role="alert" className="sr-only">Formularz zawiera błędy!</span>
                                </>
                            )}
                            <GridCustom tag="ul">
                                {oferta.warianty.map(w => {
                                    return (
                                        <FormWariant
                                            errors={wariantyErrors[w.temporaryId] || {}}
                                            formLocked={formLocked}
                                            key={w.temporaryId}
                                            priceAutofocus
                                            wariant={w}
                                            onChangeWariantAttribute={(attrName, value) => this.props.onChangeWariantAttribute(
                                                zamowienieNodeId, w.temporaryId, attrName, value
                                            )}
                                            onRemoveWariant={() => this.props.onRemoveWariant(zamowienieNodeId, w.temporaryId)}
                                        />
                                    )
                                })}
                            </GridCustom>
                        </>
                    )}
                    {!oferta.czyWariantowe && this.renderSingleWariant(oferta, zamowienieNodeId)}
                </>
            )
        } else {
            return this.renderSingleWariant(oferta, zamowienie.zamowienieNode.id)
        }
    }

    renderSingleWariant(oferta, zamowienieId) {
        const wariant = oferta.warianty[0];
        const wariantyErrors = ((this.props.errors.oferty || {})[oferta.temporaryId] || {}).warianty || {};
        return (
            <>
                <FormWariant
                    errors={wariantyErrors[wariant.temporaryId] || {}}
                    formLocked={this.props.formLocked}
                    key={wariant.temporaryId}
                    onlyCena={true}
                    wariant={wariant}
                    onChangeWariantAttribute={(attrName, value) => this.props.onChangeWariantAttribute(
                        zamowienieId, wariant.temporaryId, attrName, value
                    )}
                    onRemoveWariant={() => this.props.onRemoveWariant(zamowienieId, wariant.temporaryId)}
                />
                { wariantyErrors && <span role="alert" className="sr-only">Formularz zawiera błędy!</span> }
            </>
        )
    }

    renderZalaczniki() {
        return (
            <Container className="details-preview separate-container">
                <Zalaczniki
                    contextForSaving='offer'
                    errors={this.props.errors.zalaczniki || {}}
                    formLocked={this.props.formLocked}
                    isPreview={false}
                    maxLength={MAX_ATTACHMENTS_NUMBER}
                    zalaczniki={this.props.zalaczniki}
                    addZalacznik={this.props.changeAddZalacznik}
                    removeZalacznik={this.props.changeRemoveZalacznik}
                    saveZalacznikAttributes={this.props.changeSaveZalacznikAttributes}
                >
                    <Text info className="text--counter">Liczba wyników: {this.props.zalaczniki.length}</Text>
                    <Text info>Możesz załączyć maksymalnie {MAX_ATTACHMENTS_NUMBER} plików.</Text>
                </Zalaczniki>
            </Container>
        )
    }
}


export { FormOfertaFields };
