import React from "react";

import { translateErrorMessage } from "../../../serializers";
import { Box, GridCustom, Text, ButtonStyled } from "../../common";
import { BaseComponent } from "../common";
import { BadgeAnchor, Badge } from "@rmwc/badge";
import "@rmwc/badge/badge.css";
import { fetchApi } from "../../../fetch";
import { MenuContext } from "../../../context/menuContext";

class OfertyOwnerPublishedStatus extends BaseComponent {

  static contextType = MenuContext;

  // handlers

  handleFetchSuccess(data) {
    super.handleFetchSuccess();
    this.setState({
      initializingOn: false,
      offersCount: data.offersets.length,
      offers: data.offersets,
    });
  }

  handleRemoveBadge = (id) => {
    fetchApi(
        `/api/badge/offerset/${id}`,
        "DELETE",
        {},
        {},
        () => {
            this.fetchInitialData();
            this.props.refresh();
            this.context.refreshBadges();
        }
    );
  };

  // helpers

  getAdditionalState(props) {
    return { offersCount: 0 };
  }

  getFetchUrl() {
    return `/api/offerset/announcement/${this.props.ogloszenie.ogloszenie.id}/list`;
  }

  getFetchError(message) {
    return `Nie udało się pobrać danych ofert. ${translateErrorMessage(
      message
    )}`;
  }

  getFetchIncorrectStatusError(status) {
    return `Nie udało się pobrać danych ofert. Wystąpił nieoczekiwany błąd o kodzie ${status}.`;
  }

  // rendering

  renderContent() {
    return (
      <>
        <Text tag="h2" sectionHeader>
          Lista złożonych ofert
        </Text>
        <Text info className="text--counter">
          Liczba wyników: {this.state.offersCount}
        </Text>
        {this.renderList()}
      </>
    );
  }

  renderList() {
    if (!this.state.offersCount) {
      return null;
    }
    return (
      <>
        <Text accentHeader>
          Podgląd ofert będzie dostępny po zamknięciu ogłoszenia.
        </Text>
        <GridCustom tag="ul">
          {[...this.state.offers].map((offer, index) => (
            <Box
              key={index}
              tag="li"
              titleOneRow="Oferta"
              flexiblewidth
              aria-hidden="true"
            >
              <Text className="offer__box-hidden-text"></Text>
              <Text className="offer__box-hidden-text"></Text>
              <Text className="offer__box-hidden-text"></Text>
              <Text className="offer__box-hidden-text"></Text>
              {offer.badge && (
                <BadgeAnchor>
                  <ButtonStyled
                    primary
                    icon="check"
                    onClick={() => this.handleRemoveBadge(offer.id)}
                  >
                    USUŃ OZNACZENIE JAKO NOWE
                  </ButtonStyled>
                  <Badge label="N" />
                </BadgeAnchor>
              )}
            </Box>
          ))}
        </GridCustom>
      </>
    );
  }
}

export { OfertyOwnerPublishedStatus };
