import React, { useEffect } from 'react';

import { setCustomInputAttributes, setCustomSelectAttributes } from '../../helpers';
import { Text } from './Text';

const FieldWithLabel = (props) => {
    const {
        className,
        children,
        inputRef,
        labelFor,
        label,
        selectRef,
        tag,
        ...otherProps
    } = props;

    let fieldWithLabelClassName = 'field-with-label ';
    className && (fieldWithLabelClassName += className);

    // Add selectRef={React.createRef()} to <FieldWithLabel> with select and inputRef={React.createRef()} to dateTime input.
    // Adding inputRef to text input forces proper reading of aria-label of input trailing icon (for cleaning text input value).
    useEffect(() => {
        if (selectRef && selectRef.current !== null) {
            setCustomSelectAttributes(selectRef);
        }

        if (inputRef && inputRef.current !== null) {
            setCustomInputAttributes(inputRef);
        }
    });

    return (
        <div
            className={fieldWithLabelClassName}
            ref={selectRef || inputRef}
            {...otherProps}
        >
            <Text label tag={tag ? tag : 'p'} labelFor={tag === 'label' && labelFor}>{label}</Text>
            {children}
        </div>
    );
}

export { FieldWithLabel };
