import React from 'react';

import { getCookieValue } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { ButtonStyled, GridCustom } from '../../common';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';


const COOKIE_NAME = 'bzkc';


class CiasteczkaFront extends BaseComponent {

    // helpers

    getAdditionalState(props) {
        return {
            showCookies: false,
            ciasteczka: {},
        }
    }

    getFetchUrl() {
        return '/api/cookies'
    }

    // handlers

    handleFetchSuccess = (data) => {
        super.handleFetchSuccess(data);

        if (data === null) {
            this.setState({initializingOn: false});
        } else {
            // if cookies accepted, don't render them
            const serializedObject = getSerializedObject(
                data,
                {
                    nonRecursiveKeys: ['tresc', ],
                    dateAttributes: ['dataOpublikowania'],
                    dateTimeAttributes: ['dataOpublikowania'],
                }
            );
            if (parseInt(getCookieValue(COOKIE_NAME)) === new Date(serializedObject.dataOpublikowania).getTime()) {
                this.setState({initializingOn: false});
            } else {
                this.setState({
                    initializingOn: false,
                    showCookies: true,
                    ciasteczka: serializedObject,
                });
            }
        }
    }

    handleFetchError = (data) => {
        // do nothing, don't render error
        this.xhrFetch = null;     // clean xhr object
        this.setState({initializingOn: false});
    }

    handleFetchIncorrectStatus = (status) => {
        this.handleFetchError(null);
    }

    handleCloseCiasteczka = (savingDate) => {
        document.cookie = `${COOKIE_NAME}=${new Date(this.state.ciasteczka.dataOpublikowania).getTime()};path=/`;
        this.setState({showCookies: false});
    }

    // rendering

    render() {
        if (this.state.initializingOn || !this.state.showCookies) { return null }
        return (
            <GridCustom noWrap fullwidth className=" cookies-container">
                <DraftToComponents content={this.state.ciasteczka.tresc} />
                <ButtonStyled
                    onClick={this.handleCloseCiasteczka}
                    primary
                >
                    <span className="sr-only">Akceptuję politykę dotyczącą wykorzystania plików cookies. </span>Zamknij<span className="sr-only"> pop-up.</span>
                </ButtonStyled>
            </GridCustom>
        )
    }
}


export { CiasteczkaFront };
