import React from 'react';

import { ButtonStyled, GridCustom, Text } from '../../common';
import { Zalacznik } from './Zalacznik';


const Zalaczniki = (props) => {
    const {
        children,
        errors,
        formLocked,
        header,
        isAlreadyPublished,
        withoutHeader,
        zalaczniki
    } = props;
    const zalacznikiErrors = (props.errors || {}).errors || [];
    const isZalacznikiInvalid = zalacznikiErrors.length > 0;
    // it is possible to rewrite this value
    const zalacznikiLength = props.zalacznikiLength || zalaczniki.length;

    return (
        <>
            {isZalacznikiInvalid && <ul role="alert">{zalacznikiErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
            <GridCustom fullwidth flexTop>
                {!withoutHeader && <Text tag="h2" sectionHeader>{header || 'Załączniki'}</Text>}
                {(!isAlreadyPublished && (!props.maxLength || zalacznikiLength < props.maxLength)) && (
                    <ButtonStyled
                        add
                        disabled={formLocked}
                        onClick={props.addZalacznik}
                        noMargin
                    >
                        Dodaj załącznik
                    </ButtonStyled>
                )}
            </GridCustom>
            {children}
            <GridCustom tag="ul">
                {[...zalaczniki].reverse().map(z => (
                    <Zalacznik
                        isAlreadyPublished={isAlreadyPublished}
                        checkAttributeName={props.checkAttributeName}
                        checkLabel={props.checkLabel}
                        contextForSaving={props.contextForSaving}
                        errors={(errors || {})[z.temporaryId] || {}}
                        formLocked={formLocked}
                        key={z.temporaryId}
                        maxLength={props.maxLength}
                        withCheck={props.withCheck}
                        zalacznik={z}
                        zalacznikiLength={zalacznikiLength}
                        saveZalacznikAttributes={props.saveZalacznikAttributes}
                        removeZalacznik={props.removeZalacznik}
                        addZalacznik={props.addZalacznik}
                        onCheckChange={props.onCheckChange}
                    />)
                )}
            </GridCustom>
        </>
    )
}

export { Zalaczniki };
