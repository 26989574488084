import React from 'react';
import { Link } from 'react-router-dom';
import { Switch } from '@rmwc/switch';

import '@material/switch/dist/mdc.switch.css';

import { fetchApi } from '../../../fetch';
import { ADVERTISEMENTS_CLEANED_FILTERS, filterAdvertisements } from '../../../filters';
import {
    dateToString,
    getUserFullName,
    isAdvertisementLocked,
    triggerBodyClick
} from '../../../helpers';
import { serializeObject, serializeObjectList } from '../../../serializers';
import { ADVERTISEMENTS_SORTING_VALUES, sortAdvertisements } from '../../../sorters';
import { URLS } from '../../../urls/frontend';
import {
    Box,
    ButtonStyled,
    Container,
    DateCalendar,
    DialogCustom,
    GridCustom,
    FieldWithLabel,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { BaseComponent } from '../common';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { BadgeAnchor, Badge } from '@rmwc/badge';
import '@rmwc/badge/badge.css';


const EMPTY_VALUE = {
    value: 0,
    label: '--- Wybierz ---',
}
const ANNOUNCEMENT_STATUS_DRAFT = 'announcement_status_draft';
const ANNOUNCEMENT_STATUS_PUBLISHED = 'announcement_status_published';
const ANNOUNCEMENT_STATUSES = 'announcement_statuses';


class Ogloszenia extends BaseComponent {

    // basic functions

    componentDidMount() {
        if (this.props.preview) { return }

        document.title = "Baza Konkurencyjności - ogłoszenia";
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        // abort api requests if exist
        for (let xhr of [this.xhrRemoveAdvertisement, this.xhrCopyAdvertisement,
                         this.xhrCancelAdvertisement]) {
            if (xhr !== null) {
                xhr.abort();
            }
        }
    }

    // handlers

    handleFetchSuccess = (data) => {
        super.handleFetchSuccess(data);

        const ogloszenia = serializeObjectList(data.advertisements);

        // update const values according to data from server
        this.obublikowaneStatusId = data.dictionaries[ANNOUNCEMENT_STATUS_PUBLISHED];
        this.roboczeStatusId = data.dictionaries[ANNOUNCEMENT_STATUS_DRAFT];
        this.statusyOgloszen = data.dictionaries[ANNOUNCEMENT_STATUSES];

        // update state after const defining
        this.setState({initializingOn: false, ogloszenia: ogloszenia});
    }

    handleRemoveOgloszenie = (removeFunct, dialogComponent) => {
        // remove advertisement on server
            this.xhrRemoveAdvertisement = fetchApi(
            `/api/announcements/${this.state.removedOgloszenieId}`,
            'DELETE',
            {},
            {},
            this.handleFetchRemoveOgloszenieSuccess,
            this.handleFetchRemoveOgloszenieError,
            this.handleFetchRemoveOgloszenieIncorrectStatus,
        );
    }

    handleFetchRemoveOgloszenieSuccess = (data) => {
        this.xhrRemoveAdvertisement = null;     // clean xhr object

        // remove advertisement from state
        this.setState(prevState => ({
            ogloszenia: prevState.ogloszenia.filter(o => o.ogloszenie.id !== prevState.removedOgloszenieId),
        }), this.handleCloseActionDialog);
    }

    handleFetchRemoveOgloszenieError = (data) => {
        this.xhrRemoveAdvertisement = null;     // clean xhr object
        this.setState({fetchActionError: data.message});
    }

    handleFetchRemoveOgloszenieIncorrectStatus = (status) => {
        this.xhrRemoveAdvertisement = null;     // clean xhr object
        this.setState({fetchActionError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`});
    }

    handleCopyOgloszenie = (baseOgloszenieId) => {
        this.setState(
            {copyingOgloszenieId: baseOgloszenieId},
            () => {
                this.xhrCopyAdvertisement = fetchApi(
                    `/api/announcements/${baseOgloszenieId}/copy`,
                    'POST',
                    {},
                    {},
                    this.handleFetchCopyOgloszenieSuccess,
                    this.handleFetchCopyOgloszenieError,
                    this.handleFetchCopyOgloszenieIncorrectStatus,
                );
            }
        );
    }

    handleFetchCopyOgloszenieSuccess = (data, baseOgloszenieId) => {
        this.xhrCopyAdvertisement = null;      // clean xhr object

        // update advertisements list and other state values
        const ogloszenie = serializeObject(data.advertisement);
        this.setState(prevState => ({
            ogloszenia: [...prevState.ogloszenia, ogloszenie],
            copiedOgloszenieIds: [...prevState.copiedOgloszenieIds, ogloszenie.ogloszenie.id],
        }));
    }

    handleFetchCopyOgloszenieError = (data) => {
        this.xhrCopyAdvertisement = null;      // clean xhr object
        this.setState({fetchActionError: `Podczas kopiowania ogłoszenia wystąpił nieoczekiwany błąd: ${data.message}`});
    }

    handleFetchCopyOgloszenieIncorrectStatus = (status) => {
        this.xhrCopyAdvertisement = null;      // clean xhr object
        this.setState({fetchActionError: `Podczas kopiowania ogłoszenia wystąpił nieoczekiwany błąd o kodzie ${status}.`});
    }

    handleToggleFilters = (checked) => {
        if (checked) {
            this.setFiltersData();
            this.setState({filtersAreOn: true});
        } else {
            this.setState({
                filtersAreOn: false,
                filters: ADVERTISEMENTS_CLEANED_FILTERS,
            });
        }
    }

    handleChangeFilter = (name, value) => {
        this.setState((prevState) => {
            return {filters: Object.assign({}, prevState.filters, {[name]: value})}
        });
    }

    handleCancelOgloszenie = () => {
        // cancel advertisement on server
        this.xhrRemoveAdvertisement = fetchApi(
            `/api/announcements/${this.state.cancelledOgloszenieId}/cancel`,
            'POST',
            {},
            {},
            this.handleFetchCancelOgloszenieSuccess,
            this.handleFetchCancelOgloszenieError,
            this.handleFetchCancelOgloszenieIncorrectStatus,
        );
    }

    handleFetchCancelOgloszenieSuccess = (data) => {
        this.xhrCancelAdvertisement = null;     // clean xhr object
        const ogloszenieData = serializeObject(data.advertisement);

        // update advertisement status in the state
        this.setState(
            prevState => {
                let ogloszenia = [];
                for (let o of prevState.ogloszenia) {
                    if (o.ogloszenie.id === prevState.cancelledOgloszenieId) {
                        o.ogloszenie.status = Object.assign({}, o.ogloszenie.status, ogloszenieData.status);
                    }
                    ogloszenia.push(o);
                }
                return {ogloszenia}
            },
            this.handleCloseActionDialog
        );
    }

    handleFetchCancelOgloszenieError = (data) => {
        this.xhrCancelAdvertisement = null;     // clean xhr object
        this.setState({fetchActionError: data.message});
    }

    handleFetchCancelOgloszenieIncorrectStatus = (status) => {
        this.xhrCancelAdvertisement = null;     // clean xhr object
        this.setState({fetchActionError: `Wystąpił nieoczekiwany błąd o kodzie ${status}.`});
    }

    handleCloseActionDialog = () => {
        this.setState({
            removedOgloszenieId: null,
            removedOgloszenieTitle: '',
            cancelledOgloszenieId: null,
            cancelledOgloszenieTitle: '',
        });
    }

    // helpers

    getAdditionalState(props) {
        return {
            initializingOn: props.preview ? false : true,
            filtersAreOn: false,
            currentSortingValue: {
                value: ADVERTISEMENTS_SORTING_VALUES[0][0],
                label: ADVERTISEMENTS_SORTING_VALUES[0][1],
            },
            removedOgloszenieId: null,
            removedOgloszenieTitle: '',
            cancelledOgloszenieId: null,
            cancelledOgloszenieTitle: '',
            filters: Object.assign({}, ADVERTISEMENTS_CLEANED_FILTERS),
            copyingOgloszenieId: null,  // for dialog after copying id of base ogloszenie
            copiedOgloszenieIds: [],   // for distinguishing on the list
            ogloszenia: [],
            fetchActionError: '',
        }
    }

    postState(props) {
        this.obublikowaneStatusId = null;
        this.roboczeStatusId = null;
        this.statusyOgloszen = {};

        this.actionComponent = null;

        // keep xhr objects for componentWillUnmount
        this.xhrRemoveAdvertisement = null;
        this.xhrCopyAdvertisement = null;
        this.xhrCancelAdvertisement = null;

        if (this.props.preview) { return }

        this.createdByList = [];    // needed for filtering
        this.modifiedByList = [];    // needed for filtering
    }

    getFetchUrl() {
        return '/api/announcements'
    }

    getFetchData() {
        return {
            dictionaries: [
                ANNOUNCEMENT_STATUS_DRAFT, ANNOUNCEMENT_STATUS_PUBLISHED,
                ANNOUNCEMENT_STATUSES],
            mode: 'advertiser',
        }
    }

    getFetchError(message) {
        message = super.getFetchError(message);
        return `Nie udało się pobrać ogłoszeń. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Nie udało się pobrać danych ogłoszeń. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    // helpers

    setFiltersData() {

        // set data for Utworzył and Modyfikował filters
        let creators = [];
        let createdByList = []
        let modifiers = [];
        let modifiedByList = [];

        for (let o of this.state.ogloszenia) {
            const { ktoUtworzyl, ktoModyfikowal } = o.ogloszenie;
            if (ktoUtworzyl) {
                const creator = getUserFullName(ktoUtworzyl);
                if (!creators.includes(creator)) {
                    creators.push(creator);
                    createdByList.push([creator, ktoUtworzyl.id]);
                }
            }

            if (ktoModyfikowal) {
                const modifier = getUserFullName(ktoModyfikowal);
                if (!modifiers.includes(modifier)) {
                    modifiers.push(modifier);
                    modifiedByList.push([modifier, ktoModyfikowal.id])
                }
            }
        }

        this.createdByList = createdByList.sort().map(([creator, id]) => [id, creator]);
        this.modifiedByList = modifiedByList.sort().map(([modifier, id]) => [id, modifier]);
    }

    // rendering

    renderContent() {
        const {
            cancelledOgloszenieId,
            copyingOgloszenieId,
            currentSortingValue,
            filters,
            filtersAreOn,
            ogloszenia,
            removedOgloszenieId,
        } = this.state;
        const { preview } = this.props;

        const advertisements = sortAdvertisements(
            filterAdvertisements(ogloszenia, filtersAreOn, filters),
            currentSortingValue.value
        );

        return (
            <>
                <PomocEkranowa ekran="announcements_list_advertiser" content={this.props.isPreview ? this.props.content : null}/>
                <Text tag="h2" mainHeader>Lista ogłoszeń</Text>
                <Text info>Liczba ogłoszeń: {advertisements.length}</Text>
                <Container className="filters-container">
                    <GridCustom>
                        <FieldWithLabel tag="label" label="Sortuj" selectRef={React.createRef()}>
                            <Select
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                className="select-custom"
                                isDisabled={preview}
                                isSearchable={false}
                                options={ADVERTISEMENTS_SORTING_VALUES.map(([value, label]) => ({value, label}))}
                                value={currentSortingValue}
                                onChange={(selectedOption) => this.setState({currentSortingValue: selectedOption})}
                                styles={customSelectStyles}
                                noOptionsMessage={() => 'Brak wybranej opcji'}
                            />
                        </FieldWithLabel>
                        <FieldWithLabel
                            className="filters-container__filters"
                            label="Filtruj"
                            labelFor="ogloszenia_filters_id"
                            tag="label"
                        >
                            <Switch
                                aria-label={`Filtruj. Panel filtrów ${filtersAreOn ? 'otwarty, zawartość listy będzie dopasowana po wpisaniu każdego nowego znaku w panelu filtrów.' : 'ukryty'}`}
                                checked={filtersAreOn}
                                disabled={preview}
                                onChange={(ev) => this.handleToggleFilters(ev.target.checked)}
                                id="ogloszenia_filters_id"
                            />
                        </FieldWithLabel>
                    </GridCustom>
                </Container>
                <GridCustom flexL noWrap flexTop>
                    {filtersAreOn && this.renderFilters()}
                    <GridCustom centerHorizontal className="advertisements-list" tag="ul">
                        {this.renderAddOgloszenieButton()}
                        {advertisements.map((o) => this.renderOgloszenie(o))}
                    </GridCustom>
                    {removedOgloszenieId !== null && this.renderRemovingOgloszenie()}
                    {cancelledOgloszenieId !== null && this.renderCancellingOgloszenie()}
                </GridCustom>
                {copyingOgloszenieId !== null && this.renderCopiedMessage(
                    advertisements.filter(o => parseInt(o.ogloszenie.id) === parseInt(copyingOgloszenieId))[0].tytul)}
            </>
        )
    }

    renderFilters() {
        const filters = this.state.filters;

        const ktoUtworzylList = this.createdByList;
        const ktoUtworzylDict = {};
        for (let [id, username] of ktoUtworzylList) {
            ktoUtworzylDict[id] = username;
        }

        const ktoModyfikowalList = this.modifiedByList;
        const ktoModyfikowalDict = {};
        for (let [id, username] of ktoModyfikowalList) {
            ktoModyfikowalDict[id] = username;
        }

        const dataUtworzeniaInputRef = React.createRef();
        const dataModyfikacjiInputRef = React.createRef();
        const terminOfertInputRef = React.createRef();

        return (
            <Container className="filters" style={{zIndex: 5}}>
                <GridCustom>
                    <FieldWithLabel label="Tytuł" tag="label" labelFor="filters_title_id">
                        <TextFieldCustom
                            autoFocus
                            clearFieldContext="tytuł"
                            maxLength={1000}
                            value={filters.tytul}
                            onChange={(ev) => this.handleChangeFilter('tytul', ev.target.value)}
                            className="filters__input"
                            onClear={(ev) => this.handleChangeFilter('tytul', '')}
                            id="filters_title_id" />
                    </FieldWithLabel>
                    <FieldWithLabel label="Numer ogłoszenia" tag="label" labelFor="filters_number_id">
                        <TextFieldCustom
                            clearFieldContext="numer ogłoszenia"
                            maxLength={20}
                            value={filters.ogloszenie_numer}
                            onChange={(ev) => this.handleChangeFilter('ogloszenie_numer', ev.target.value)}
                            className="filters__input"
                            onClear={(ev) => this.handleChangeFilter('ogloszenie_numer', '')}
                            id="filters_number_id" />
                    </FieldWithLabel>
                    <FieldWithLabel label="Utworzył" tag="label" selectRef={React.createRef()}>
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom filters__input"
                            options={[EMPTY_VALUE, ].concat(...ktoUtworzylList.map(([id, label]) => ({value: id, label: label})))}
                            value={{value: filters.ktoUtworzyl || EMPTY_VALUE.value, label: ktoUtworzylDict[filters.ktoUtworzyl] || EMPTY_VALUE.label}}
                            onChange={(selectedOption) =>this.handleChangeFilter(
                                'ktoUtworzyl', selectedOption === null ? 0 : parseInt(selectedOption.value))}
                            styles={customSelectStyles}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                    </FieldWithLabel>
                    <FieldWithLabel label="Data utworzenia" tag="label" inputRef={dataUtworzeniaInputRef}>
                        <DateCalendar
                            dateName="Data utworzenia"
                            parentRef={dataUtworzeniaInputRef}
                            value={Date.parse(filters.dataUtworzenia)}
                            onChange={(date) => this.handleChangeFilter('dataUtworzenia', dateToString(date))} />
                    </FieldWithLabel>
                    <FieldWithLabel label="Status" tag="label" selectRef={React.createRef()}>
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom filters__input"
                            options={[EMPTY_VALUE, ].concat(...Object.entries(this.statusyOgloszen).map(([id, name]) => [name, id]).sort().map(([name, id]) => ({value: id, label: name})))}
                            value={{value: filters.status || EMPTY_VALUE.value, label: this.statusyOgloszen[filters.status] || EMPTY_VALUE.label}}
                            onChange={(selectedOption) =>this.handleChangeFilter(
                                'status', selectedOption === null ? 0 : parseInt(selectedOption.value))}
                            styles={customSelectStyles}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                    </FieldWithLabel>
                    <FieldWithLabel label="Modyfikował" tag="label" selectRef={React.createRef()}>
                        <Select
                            screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                            className="select-custom filters__input"
                            options={[EMPTY_VALUE, ].concat(...ktoModyfikowalList.map(([id, label]) => ({value: id, label: label})))}
                            value={{value: filters.ktoModyfikowal || EMPTY_VALUE.value, label: ktoModyfikowalDict[filters.ktoModyfikowal] || EMPTY_VALUE.label}}
                            onChange={(selectedOption) =>this.handleChangeFilter(
                                'ktoModyfikowal', selectedOption === null ? 0 : parseInt(selectedOption.value))}
                            styles={customSelectStyles}
                            noOptionsMessage={() => 'Brak wybranej opcji'}
                        />
                    </FieldWithLabel>
                    <FieldWithLabel label="Data modyfikacji" tag="label" inputRef={dataModyfikacjiInputRef}>
                        <DateCalendar
                            dateName="Data modyfikacji"
                            parentRef={dataModyfikacjiInputRef}
                            value={Date.parse(filters.dataModyfikacji)}
                            onChange={(date) => this.handleChangeFilter('dataModyfikacji', dateToString(date))} />
                    </FieldWithLabel>
                    <FieldWithLabel label="Termin składania ofert" tag="label" inputRef={terminOfertInputRef}>
                        <DateCalendar
                            dateName="Termin składania ofert"
                            parentRef={terminOfertInputRef}
                            value={Date.parse(filters.terminOfert)}
                            onChange={(date) => this.handleChangeFilter('terminOfert', dateToString(date))} />
                    </FieldWithLabel>
                </GridCustom>
            </Container>
        )
    }

    renderAddOgloszenieButton() {
        return (
            <Box flexiblewidth>
                <GridCustom center fullheight>
                    <ButtonStyled
                        add
                        disabled={!!this.props.preview}
                        onClick={() => this.props.history.push('/ogloszenia/nowe')}
                        className="btn" >
                        Dodaj nowe ogłoszenie
                    </ButtonStyled>
                </GridCustom>
            </Box>
        )
    }

    renderOgloszenie(o) {
        const status = parseInt(o.ogloszenie.status.id);
        const isDraft = status === parseInt(this.roboczeStatusId);
        const isPublished = status === parseInt(this.obublikowaneStatusId);
        const isLocked = isAdvertisementLocked(o.ogloszenie.status);
        const { dataOpublikowania } = o.ogloszenie;
        return (
            <Box
                className="box--advertisement"
                flexiblewidth
                tag="li"
                titleOneRow={o.tytul || '-'}
                key={o.id}
                ariaLabelMoreOptions={`Rozwiń opcje ogłoszenia ${o.tytul}: edytuj, skopiuj, usuń`}
                boxWithMenu={(
                    <>
                        <h3 className="sr-only">Menu więcej opcji ogłoszenia</h3>
                        <ul>
                            <li>
                                <ButtonStyled
                                    disabled={!isDraft && !isPublished}
                                    onClick={() => this.props.history.push(
                                        URLS.advertisementsEdit.getUrl(o.ogloszenie.id)
                                    )}
                                    className="box__menu-item" >
                                    Edytuj
                                </ButtonStyled>
                            </li>
                            <li>
                                <ButtonStyled
                                    className="box__menu-item"
                                    disabled={isLocked}
                                    onClick={() => {
                                        this.handleCopyOgloszenie(o.ogloszenie.id);
                                        // hide box menu by triggering click
                                        triggerBodyClick();
                                    }}
                                >
                                    Skopiuj
                                </ButtonStyled>
                            </li>
                            <li>
                                <ButtonStyled
                                    onClick={() => {
                                        this.setState(
                                            {removedOgloszenieId: o.ogloszenie.id, removedOgloszenieTitle: o.tytul},
                                            () => triggerBodyClick()    // hide box menu by triggering click
                                        );
                                    }}
                                    disabled={!isDraft}
                                    className="box__menu-item" >
                                    Usuń
                                </ButtonStyled>
                            </li>
                            <li>
                                <ButtonStyled
                                    onClick={() => {
                                        this.setState(
                                            {cancelledOgloszenieId: o.ogloszenie.id, cancelledOgloszenieTitle: o.tytul},
                                            () => triggerBodyClick()    // hide box menu by triggering click
                                        );
                                    }}
                                    disabled={!isPublished}
                                    className="box__menu-item" >
                                    Anuluj
                                </ButtonStyled>
                            </li>
                        </ul>
                    </>
                )} >

                {this.state.copiedOgloszenieIds.includes(o.ogloszenie.id) && <Text info>Ogłoszenie skopiowane</Text>}
                <GridCustom fullwidth>
                    <FieldWithLabel label="Numer ogłoszenia">
                        <Text>{o.ogloszenie.numer || '-'}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Status">
                        <Text>{o.ogloszenie.status.nazwa}</Text>
                        {status === this.obublikowaneStatusId && o.ogloszenie.czyMaKopieRobocza && <Text info>+ Posiada kopię roboczą</Text>}
                    </FieldWithLabel>
                </GridCustom>
                <GridCustom fullwidth>
                    <FieldWithLabel label={`Data ${dataOpublikowania?.length > 0 && dataOpublikowania > dateToString(new Date()) ? 'odroczonej ' : ''}publikacji`}>
                        <Text>{dataOpublikowania || '-'}</Text>
                    </FieldWithLabel>
                    <FieldWithLabel label="Termin składania ofert">
                        <Text>{o.terminOfert || '-'}</Text>
                    </FieldWithLabel>
                </GridCustom>
                <FieldWithLabel label="Czy dopuszczalna oferta częściowa?">
                    <Text>
                        {o.czyDopuszczalnaOfertaCzesciowa ? 'TAK' : 'NIE'}
                        {o.czyDopuszczalnaOfertaCzesciowa && ` (Części: ${o.liczbaZamowien})`}
                    </Text>
                </FieldWithLabel>

                <BadgeAnchor style={{width: '100%'}}>
                    <ButtonStyled
                        className='corner-button'
                        disabled={isLocked}
                        primary
                        tag={Link}
                        to={`/ogloszenia/${o.ogloszenie.id}${status === this.roboczeStatusId ? '/robocza' : ''}`}
                    >
                        Pokaż szczegóły<span className="sr-only">{` ogłoszenia ${o.tytul}`}</span>
                    </ButtonStyled>
                    {o.badge_count > 0 && <Badge label={o.badge_count} />}
                </BadgeAnchor>


            </Box>
        )
    }

    renderRemovingOgloszenie() {
        return this.renderActionDialog('usunąć', 'Usuń', 'removed', this.handleRemoveOgloszenie)
    }

    renderCopiedMessage(baseOgloszenieTytul) {
        let content;
        if (this.state.fetchActionError.length) {
            content = (
                <Text error role="alert">Nie udało się skopiować ogłoszenia. {this.state.fetchActionError}</Text>
            );
        } else {
            content = `Ogłoszenie ${baseOgloszenieTytul.length > 0 ? '"' + baseOgloszenieTytul + '"' : 'bez tytułu'} zostało skopiowane.`;
        }
        return (
            <DialogCustom
                className="dialog"
                dialogTitle={content}
                onClose={() => this.setState({ copyingOgloszenieId: null, fetchActionError: '' })}
            >
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled onClick={() => this.setState({copyingOgloszenieId: null, fetchActionError: ''})} primary>OK</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }

    renderCancellingOgloszenie() {
        return this.renderActionDialog('anulować', 'Anuluj', 'cancelled', this.handleCancelOgloszenie)
    }

    renderActionDialog(verbString, actionName, actionPrefix, fetchHandler) {
        let content, title = null;
        if (this.state.fetchActionError.length) {
            title = `Nie udało się ${verbString} ogłoszenia.`;

            content = (
                <>
                    <Text error role="alert">{this.state.fetchActionError}</Text>
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled
                            onClick={() => this.setState(
                                {fetchActionError: '', [`${actionPrefix}OgloszenieId`]: null, [`${actionPrefix}OgloszenieTitle`]: ''}
                            )}
                            primary>
                            OK
                        </ButtonStyled>
                    </GridCustom>
                </>
            );
        } else {
            title = `Czy na pewno chcesz ${verbString} ogłoszenie "${this.state[`${actionPrefix}OgloszenieTitle`] || 'Bez tytułu'}"?`;

            content = (
                <GridCustom centerVertical flexEnd>
                    <ButtonStyled onClick={this.handleCloseActionDialog} cancel>Nie. Wróć do listy</ButtonStyled>
                    <ButtonStyled onClick={fetchHandler} remove>{actionName} ogłoszenie</ButtonStyled>
                </GridCustom>
            );
        }
        return (
            <DialogCustom 
                className="dialog"
                dialogTitle={title}
                dialogRef={(c) => { this.actionComponent = c }}
                onClose={() => { this.setState({ [`${actionPrefix}OgloszenieId`]: null, [`${actionPrefix}OgloszenieTitle`]: '' }) }}
            >
                {content}
            </DialogCustom>
        )
    }
}

export { Ogloszenia };
