import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Checkbox } from '@rmwc/checkbox';

import { getAuthTokenCookieValue, refreshTimerAfterRequest, saveFileFromApi } from '../../../helpers';
import { translateErrorMessage } from '../../../serializers';
import {
    Box,
    ButtonStyled,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    LinearProgressCustom,
    Text,
    TextFieldCustom
} from '../../common';


class Zalacznik extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmRemovingOn: false,
            progress: null,
            loadingInProgress: false,
            loadingFileName: null,
            showLoadedSuccessMsg: false,
            showLoadingErrorMsg: false,
            errors: {},
        };

        this.blockedFilesExtensions = ['exe', ];
        this.successMessageTimeout = null;
        this.errorMessageTimeout = null;
        this.removingDialogComponent = null;
        this.xhrSaveFile = null;

        this.handleRemoveZalacznik = this.handleRemoveZalacznik.bind(this);
        this.handleAddPlik = this.handleAddPlik.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.progress !== this.state.progress && this.state.progress === 1.0) {
            this.setState({
                loadingFileName: null,
                showLoadedSuccessMsg: true,
            });

            this.successMessageTimeout = setTimeout(() => {
                this.successMessageTimeout = null;
                this.setState({
                    showLoadedSuccessMsg: false,
                    loadingInProgress: false,
                });
            }, 7000);
        }
    }

    componentDidMount() {
        if (this.props.zalacznik.plikDoWgrania) {
            this.handleAddPlik([this.props.zalacznik.plikDoWgrania, ]);
        }
    }

    componentWillUnmount() {
        if (this.successMessageTimeout !== null) {
            clearTimeout(this.successMessageTimeout);
        }
        if (this.errorMessageTimeout !== null) {
            clearTimeout(this.errorMessageTimeout);
        }
        if (this.xhrSaveFile !== null) {
            this.xhrSaveFile.abort();
        }
    }

    handleCloseConfirmRemovingDialog = () => {
        this.setState({confirmRemovingOn: false});
    }

	handleSaveFileFromApi = (url, fileName) => {
        this.setState(
            () => saveFileFromApi(
                url,
				fileName)
        );
    }

    render() {
        const isAlreadyPublished = this.props.isAlreadyPublished;
        const z = this.props.zalacznik;
        const temporaryId = z.temporaryId;
        const formLocked = this.props.formLocked;
        const zalacznikTitleId = `zalacznik_${z.temporaryId}_title_id`;

        const errors = Object.assign({}, this.props.errors || {}, this.state.errors);
        const checkAttributeName = this.props.checkAttributeName;
        const isCheckInvalid = (errors[checkAttributeName] || []).length > 0;
        const isNazwaInvalid = (errors.nazwa || []).length > 0;

        return (
            <Box tag="li">
                {this.props.withCheck && (
                    <>
                        <Checkbox
                            aria-describedby={isCheckInvalid ? 'withCheck_errors' : null}
                            checked={z[checkAttributeName]}
                            disabled={formLocked}
                            label={this.props.checkLabel}
                            onChange={event => this.props.onCheckChange(event.target.checked, temporaryId)}
                        />
                        {isCheckInvalid && (
                            <ul id="withCheck_errors">
                                {errors[checkAttributeName].map((e, i) => <Text error key={i} tag="li">{e}</Text>)}
                            </ul>
                        )}
                    </>
                )}
                <FieldWithLabel label="Nazwa załącznika" tag="label" labelFor={zalacznikTitleId}>
                    <TextFieldCustom
                        aria-describedby={isNazwaInvalid ? 'zalacznik_title_error' : null}
                        aria-label={`Nazwa załącznika, maksymalna liczba znaków: 100. Wpisano ${z.nazwa.length} znaków.`}
                        aria-valuemax={100}
                        autoFocus
                        characterCount
                        clearFieldContext="nazwa załącznika"
                        disabled={isAlreadyPublished || formLocked}
                        fullwidth
                        id={zalacznikTitleId}
                        invalid={isNazwaInvalid}
                        maxLength={100}
                        value={z.nazwa}
                        onBlur={(ev) => this.props.saveZalacznikAttributes(z.temporaryId, {nazwa: ev.target.value.trim()})}
                        onChange={(ev) => this.props.saveZalacznikAttributes(z.temporaryId, {nazwa: ev.target.value})}
                        onClear={!isAlreadyPublished && ((ev) => this.props.saveZalacznikAttributes(z.temporaryId, {nazwa: ''}))}
                    />
                    {isNazwaInvalid && <ul id="zalacznik_title_error">{errors.nazwa.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                </FieldWithLabel>
                <FieldWithLabel>
                    {this.renderFile(temporaryId, errors)}
                </FieldWithLabel>
                {!isAlreadyPublished && (
                    <ButtonStyled
                        disabled={formLocked}
                        remove
                        onClick={() => this.setState({confirmRemovingOn: true})}>
                        Usuń załącznik<span className="sr-only"> {z.nazwa}</span>
                    </ButtonStyled>
                )}
                {this.state.confirmRemovingOn && (
                    <DialogCustom 
                        className="dialog"
                        dialogTitle="Czy na pewno chcesz usunąć ten załącznik?"
                        dialogRef={(c) => {this.removingDialogComponent = c}}
                        onClose={this.handleCloseConfirmRemovingDialog}
                    >
                        <GridCustom centerVertical flexEnd>
                            <ButtonStyled onClick={() => {this.setState({confirmRemovingOn: false})}} cancel>Anuluj</ButtonStyled>
                            <ButtonStyled onClick={this.handleRemoveZalacznik} remove>Usuń załącznik</ButtonStyled>
                        </GridCustom>
                    </DialogCustom>
                )}
                {isNazwaInvalid && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
            </Box>
        )
    }

    renderFile(temporaryId, errors) {
        // loaded file
        const z = this.props.zalacznik;
        const progress = <LinearProgressCustom progress={this.state.progress || 0} />;
        const error = (errors.plik || '').length > 0 ? <Text error id="load_file_error">{errors.plik}</Text> : null;
        if (z.plik.id) {
            const plik = z.plik;
            return (
                <>
                    {this.state.loadingInProgress && progress}
                    {this.state.showLoadedSuccessMsg && <Text className="success-text" role="status">Plik został dodany!</Text>}
                    <Text>{plik.nazwa}</Text>
					<ButtonStyled className="btn--pdf-dowload" onClick={(ev) => this.handleSaveFileFromApi(plik.uri, plik.nazwa)}>Pobierz załącznik<span className="sr-only"> {z.nazwa || plik.nazwa}</span></ButtonStyled>
                    {error}
                    {this.renderOwner(z)}
                </>
            )
        }

        // loading file
        if (this.state.loadingInProgress) {
            return (
                <>
                    <Text>{this.state.loadingFileName}</Text>
                    {progress}
                    {error}
                </>
            )
        }

        let fileError = null;

        const loadFileError = (errors.plik || '').length > 0 ? 'load_file_error' : null;
        const noUploadError = this.state.showLoadingErrorMsg ? 'no_file_upload_error' : null;

        if (loadFileError && noUploadError) {
            fileError = `${loadFileError} ${noUploadError}`;
        } else {
            fileError = (loadFileError || noUploadError)
        }

        // set file
        return (
            <>
                <Dropzone onDrop={this.handleAddPlik}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps({className: 'dropzone'})}>
                            <input
                                aria-describedby={fileError}
                                disabled={this.props.formLocked}
                                id="fileinput"
                                style={{display: 'block'}}
                                {...getInputProps({
                                    onClick: ev => this.clearFileErrors(temporaryId),
                                    style: {display: 'block', position: 'absolute', width: 0, height: 0, left: '-1000%'},
                                })}
                            />
                            <label className="drag-n-drop-input-label" htmlFor="fileinput">Przeciągnij i upuść pliki lub kliknij, aby wybrać plik z komputera</label>
                        </div>
                    )}
                </Dropzone>
                {error}
                {this.state.showLoadingErrorMsg && <Text error id="no_file_upload_error">Plik nie został dodany</Text>}
            </>
        )
    }

    renderOwner(zalacznik) {
        if (!zalacznik.plik.ktoUtworzyl) { return null }
        const {
            dataUtworzenia,
            ktoUtworzyl,
        } = zalacznik.plik;
        return (
            <>
                <FieldWithLabel label="Dodany przez">
                    <Text>{ktoUtworzyl.imie} {ktoUtworzyl.nazwisko}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Data i czas dodania pliku">
                    <Text>{dataUtworzenia}</Text>
                </FieldWithLabel>
            </>
        )
    }

    clearFileErrors(temporaryId) {
        this.setState(
            (prevState) => {
                let errors = prevState.errors;
                delete errors['plik'];
                return {errors: errors}
            }
        );
    }

    handleRemoveZalacznik() {
        if (this.removingDialogComponent !== null){
            this.handleCloseConfirmRemovingDialog();
        }
        this.props.removeZalacznik(this.props.zalacznik.temporaryId);
    }

    handleAddPlik(files) {
        if (!files.length) {
            // stop loading
            this.setState({loadingFileName: null, progress: null});
            return
        }

        // get first file and use it it current component
        let currentComponentFile = files[0];

        if (this.validate(currentComponentFile)) {
            this.save(currentComponentFile);
        }

        // render components for other files
        if (files.length > 1) {
            let filesLength = files.length;
            if (this.props.maxLength) {
                filesLength = Math.min(
                    files.length,
                    // available attachments to load
                    this.props.maxLength - (this.props.zalacznikiLength - 1) // 1 is because of current input
                );
            }
            // start from the second file, because the first one is already added
            for (let i=1; i<filesLength; i++) {
                this.props.addZalacznik({plikDoWgrania: files[i]})
            }
        }
    }

    validate(file) {
        const fileNameList = file.name.split('.');
        const extension = fileNameList[fileNameList.length - 1];

        if (this.blockedFilesExtensions.includes(extension.toLowerCase())) {
            this.setState(
                (prevState) => {
                    return {
                        errors: Object.assign({}, prevState.errors, {
                            plik: [`Nie udało się dodać pliku ${file.name}. Dodawanie plików z rozszerzeniem ${extension} zostało zablokowane.`]
                        }),
                        showLoadingErrorMsg: true,
                    }
                },
                () => {
                    this.errorMessageTimeout = setTimeout(() => {
                        this.errorMessageTimeout = null;
                        this.setState({showLoadingErrorMsg: false})
                    }, 5000);
                }
            );
            return false
        }

        if (this.errorMessageTimeout !== null){
            clearTimeout(this.errorMessageTimeout);
        }
        this.setState((prevState) => {
            let errors = prevState.errors;
            delete errors.plik;
            return {
                errors: errors,
                showLoadingErrorMsg: false,
            }
        });
        return true
    }

    save(file) {
        this.setState({progress: 0, loadingInProgress: true, loadingFileName: file.name});

        const formData = new FormData();
        formData.append('file', file);

        this.xhrSaveFile = new XMLHttpRequest();
        this.xhrSaveFile.open('POST', `/api/files?context=${this.props.contextForSaving || 'default'}`);

        const authTokenCookieValue = getAuthTokenCookieValue();
        if (authTokenCookieValue) {
            this.xhrSaveFile.setRequestHeader('X-Auth-Token', authTokenCookieValue);
        }

        const onProgress = (e) => {
            this.setState({progress: e.loaded / e.total})
        };

        this.xhrSaveFile.upload.onprogress = onProgress;

        this.xhrSaveFile.onerror = () => {
            this.xhrSaveFile = null;
            this.setState(prevState => ({
                errors: Object.assign({}, prevState.errors, {plik: ['Wystąpił nieoczekiwany błąd.']}),
            }));
        };

        this.xhrSaveFile.onload = () => {
            refreshTimerAfterRequest(
                authTokenCookieValue, this.xhrSaveFile.status);
            let success = true;
            let error;
            let jsonData = {};
            try {
                jsonData = JSON.parse(this.xhrSaveFile.responseText);
            } catch(error) {
                success = false;
            }

            if (success) {
                if (jsonData.status === 'OK') {
                    const file = jsonData.data.file;
                    this.props.saveZalacznikAttributes(this.props.zalacznik.temporaryId, {
                        plik: {
                            id: file.id,
                            uri: file.uri,
                            nazwa: file.name,
                            dataUtworzenia: file.created_at,
                            ktoUtworzyl: {imie: file.created_by.forename, nazwisko: file.created_by.surname},
                        },
                        plikDoWgrania: null,
                    });
                    return
                } else if (jsonData.errors) {
                    error = translateErrorMessage(jsonData.errors.file);
                } else {
                    error = translateErrorMessage(jsonData.message);
                }
            } else if (this.xhrSaveFile.status === 413) {
                error = 'Wybrany plik jest zbyt duży. Maksymalny rozmiar pliku to 25 MB.';
            } else {
                error = `Wystąpił nieoczekiwany błąd o kodzie ${this.xhrSaveFile.status}. Nie udało się dodać pliku.`;
            }
            this.setState(prevState => ({
                errors: Object.assign({}, prevState.errors, {plik: [error]}),
            }));
            this.xhrSaveFile = null;
        };
        this.xhrSaveFile.send(formData);
    }
}


export { Zalacznik };
