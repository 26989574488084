import React, { Component } from 'react';
import '@material/dialog/dist/mdc.dialog.css';

import { AdvertisementConsumer } from '../../../context/advertisementContext';
import { isNotEmpty, listOrEmpty } from '../../../helpers';
import { Box, ButtonStyled, DialogCustom, Ellipsis, GridCustom, FieldWithLabel, Text } from '../../common';


class PrzedmiotZamowienia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRemovingOn: false,
        };
    }

    // rendering

    render() {
        const pz = this.props.przedmiotZamowienia;
        const formLocked = this.props.formLocked;

        let kodyCPVPrzedmiotuZamowienia;
        if (isNotEmpty(pz.kodyCPV)) {
            kodyCPVPrzedmiotuZamowienia = listOrEmpty(pz.kodyCPV).map(kod_ => {
                return <Text key={kod_.id}>{kod_.kod} {kod_.nazwa}</Text>
            });
        } else {
            kodyCPVPrzedmiotuZamowienia = '-';
        }
        return (
            <AdvertisementConsumer>
                {({
                    formErrors,
                    przedmiotZamowieniaPodkategoriaDict,
                    przedmiotZamowieniaKategoriaDict
                }) => (
                    <Box
                        fullwidth
                        invalid={Object.keys(formErrors.przedmiotyZamowienia[pz.temporaryId] || {}).length > 0}
                        titleWithSubtitle={przedmiotZamowieniaKategoriaDict[pz.kategoria] || '-'}
                        tag="li"
                        subtitle={przedmiotZamowieniaPodkategoriaDict[pz.podkategoria] || '-'}
                        ariaLabelMoreOptions={`Rozwiń opcje przedmiotu zamówienia: edytuj ${!this.props.isAlreadyPublished ? ', usuń' : ''}`}
                        boxWithMenu={(
                            <>
                                <h3 className="sr-only">Menu więcej opcji przedmiotu ogłoszenia</h3>
                                <ul>
                                     <li>
                                        <ButtonStyled
                                            className="box__menu-item"
                                            disabled={formLocked}
                                            lite
                                            onClick={() => this.props.onEditClick(pz.temporaryId)}>
                                            Edytuj
                                        </ButtonStyled>
                                    </li>
                                    {!this.props.isAlreadyPublished && (
                                        <li>
                                            <ButtonStyled
                                                className="box__menu-item"
                                                disabled={formLocked}
                                                remove
                                                onClick={() => this.setState({isRemovingOn: true})}>
                                                Usuń
                                            </ButtonStyled>
                                        </li>
                                    )}
                                </ul>
                            </>
                        )} >
                        <FieldWithLabel label="Opis">
                            <Ellipsis>
                                <Text className="long-text">{pz.opis || '-'}</Text>
                            </Ellipsis>
                        </FieldWithLabel>
                        <FieldWithLabel label="Kod CPV">
                            {kodyCPVPrzedmiotuZamowienia}
                        </FieldWithLabel>
                        {this.renderRemovingPart()}
                    </Box>
                )}
            </AdvertisementConsumer>
        )
    }

    handleCloseRemovingDialog = () => {
        this.setState({isRemovingOn: false});
    }

    renderRemovingPart() {
        if (this.state.isRemovingOn) {
            return (
                <DialogCustom 
                    className="dialog"
                    dialogTitle="Czy na pewno chcesz usunąć ten przedmiot zamówienia?"
                    onClose={this.handleCloseRemovingDialog}
                >
                    <>
                        <Text>Jeśli tak, zostaną usunięte również następujące obiekty powiązane, o ile zostały zdefiniowane:</Text>
                        <Text>- Miejsca realizacji</Text>
                        <Text>- Etapy</Text>
                        <Text>- Wymagania</Text>
                        <GridCustom centerVertical flexEnd>
                            <ButtonStyled onClick={this.handleCloseRemovingDialog} cancel>Anuluj</ButtonStyled>
                            <ButtonStyled onClick={() => this.props.onRemoveClick(this.props.przedmiotZamowienia.temporaryId)} remove>Usuń</ButtonStyled>
                        </GridCustom>
                    </>
                </DialogCustom>
            )
        }
    }
}


export { PrzedmiotZamowienia };
