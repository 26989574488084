import React from 'react';

import { BasePytaniaForOgloszenieWidthPdf } from './BasePytaniaForOgloszenieWidthPdf';
import { Pytanie } from './Pytanie';
import { ButtonStyled, Container, Text } from '../../common';
import { redirectToLoginView } from '../../../helpers';


class PytaniaForOgloszenieAnonymous extends BasePytaniaForOgloszenieWidthPdf {

    // helpers

    getAdditionalState(props) {
        return {
            pytania: [],
        }
    }

    // rendering

    renderContent() {
        const pytania = this.state.pytania;
        const ogloszenieStatus = this.props.ogloszenie.ogloszenie.status.label;

        return (
            <>
                {ogloszenieStatus === 'PUBLISHED' && (
                    <div className="login-info-container">
                        <ButtonStyled add onClick={redirectToLoginView} className="btn btn--main">Dodaj pytanie</ButtonStyled>
                        <Text info>Wymaga logowania</Text>
                    </div>
                )}

                <Container className="questions-container">
                    {this.renderPrintButton()}
                    {!pytania.length ? <Text info>Do tego ogłoszenia nie ma opublikowanych pytań.</Text> : (
                        <div className="questions-list-container">
                            <Text mainHeader>Opublikowane pytania i odpowiedzi</Text>
                            <Text info className="text--counter">Liczba wyników: {pytania.length}</Text>
                            <ul>
                                {pytania.map((q) => <Pytanie key={q.id} question={q} reloadAnnouncement={this.props.reloadAnnouncement} />)}
                            </ul>
                        </div>
                    )}
                </Container>
            </>
        )
    }
}


export { PytaniaForOgloszenieAnonymous };
