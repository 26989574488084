import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';

import { AdvertisementConsumer } from '../../../context/advertisementContext';
import { Box, ButtonStyled, Ellipsis, GridCustom, FieldWithLabel, Text } from '../../common';
import { FormHarmonogram } from './FormHarmonogram';


class Harmonogramy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newHarmonogramOn: false,
            editingHarmonogramId: null,
        };
    }

    // handlers

    handleCloseForm = () => {
        this.setState(
            {
                newHarmonogramOn: false,
                editingHarmonogramId: null,
            },
            () => {
                if (window.formOgloszenie) { window.formOgloszenie.computeProgress()}
            }
        );
    }

    handleSave = (data, isNew) => {
        this.props.onSaveHarmonogram(data, isNew, this.handleCloseForm);
    }

    // rendering

    render() {
        const {
            harmonogramy,
            okresGwarancji,
            opis,
            podkategoria,
            typ,
        } = this.props;
        return (
            <AdvertisementConsumer>
                {({formErrors, przedmiotZamowieniaPodkategoriaDict, przedmiotZamowieniaKategoriaDict}) => (
                    <Box
                        tag="li"
                        titleWithSubtitle={przedmiotZamowieniaKategoriaDict[typ] || '-'}
                        subtitle={przedmiotZamowieniaPodkategoriaDict[podkategoria] || '-'}
                        fullwidth >
                        <FieldWithLabel label="Opis">
                            <Ellipsis>
                                <Text className="long-text">{opis}</Text>
                            </Ellipsis>
                        </FieldWithLabel>
                        {okresGwarancji.length > 0 && (
                            <FieldWithLabel label="Okres gwarancji">
                                <Text>{okresGwarancji}</Text>
                            </FieldWithLabel>
                        )}
                        <Text tag="h3" sectionHeader>Etapy</Text>
                        <Text info>Liczba wyników: {harmonogramy.length}</Text>
                        <GridCustom tag="ul">
                            {harmonogramy.map((h, i) => {
                                return this.renderHarmonogram(h, i + 1, formErrors);
                            })}
                        </GridCustom>
                        {this.renderHarmonogramForm()}
                        {this.renderNewHarmonogramButton()}
                    </Box>
                )}
            </AdvertisementConsumer>
        )
    }

    renderHarmonogram(harmonogram, index, formErrors) {
        const formLocked = this.props.formLocked;
        const etapErrors = Object.keys(formErrors.harmonogramy[harmonogram.temporaryId] || {}).length > 0
        return (
            <Box key={harmonogram.temporaryId} tag="li">
                <section>
                    {etapErrors && (
                        <Text error role="alert">
                            <Icon aria-hidden="true" icon={{ icon: 'warning', size: 'xsmall' }} style={{marginRight: 3}} /> Niepoprawne informacje o etapie
                        </Text>
                    )}
                    <Text tag="h4" accentHeader>Etap {index}</Text>
                    <FieldWithLabel label="Opis">
                        <Ellipsis>
                            <Text className="long-text">{harmonogram.opis || '-'}</Text>
                        </Ellipsis>
                    </FieldWithLabel>
                    <GridCustom fullwidth>
                        <FieldWithLabel label="Początek">
                            <Text>{harmonogram.poczatekRealizacji || '-'}</Text>
                        </FieldWithLabel>
                        <FieldWithLabel label="Koniec">
                            <Text>{harmonogram.koniecRealizacji || '-'}</Text>
                        </FieldWithLabel>
                    </GridCustom>
                    <FieldWithLabel label="Płatność częściowa">
                        <Text>{harmonogram.czyWystepujePlatnoscCzesciowa ? 'TAK' : 'NIE'}</Text>
                    </FieldWithLabel>
                </section>
                <GridCustom flexEnd>
                    <ButtonStyled
                        disabled={formLocked}
                        remove
                        onClick={() => this.props.onRemoveHarmonogram(
                            this.props.przedmiotZamowieniaTemporaryId, harmonogram.temporaryId)
                        }>
                        Usuń<span className="sr-only"> etap {index}</span>
                    </ButtonStyled>
                    <ButtonStyled
                        disabled={formLocked}
                        lite
                        onClick={() => this.setState(
                            {editingHarmonogramId: harmonogram.temporaryId})
                        }>
                        Edytuj<span className="sr-only"> etap {index} {etapErrors ? 'Uwaga! Etap zawiera błędy!' : ''}</span>
                    </ButtonStyled>
                </GridCustom>
            </Box>
        )
    }

    renderHarmonogramForm() {
        const {
            editingHarmonogramId,
            newHarmonogramOn
        } = this.state;

        if (newHarmonogramOn || editingHarmonogramId !== null) {
            let h;
            if (newHarmonogramOn) {
                h = {temporaryId: this.props.lastTemporaryId + 1};
            } else {
                h = this.props.harmonogramy.filter(
                    harm => harm.temporaryId === editingHarmonogramId)[0];
            }

            return <FormHarmonogram
                        harmonogram={h}
                        isNew={newHarmonogramOn}
                        przedmiotZamowieniaTemporaryId={this.props.przedmiotZamowieniaTemporaryId}
                        onCloseForm={this.handleCloseForm}
                        onSave={this.handleSave} />
        }
        return null
    }

    renderNewHarmonogramButton() {
        if (this.state.newHarmonogramOn) { return null }
        return (
            <GridCustom flexEnd additionalClassName=" box__button-container">
                <ButtonStyled
                    add
                    disabled={this.props.formLocked}
                    id={`pz${this.props.przedmiotZamowieniaTemporaryId}_dodaj_harmonogram_button_id`}
                    onClick={() => this.setState({newHarmonogramOn: true})}>
                    Dodaj etap
                </ButtonStyled>
            </GridCustom>
        )
    }
}


export { Harmonogramy };
