import React, { Component } from 'react';

import { TYP_NUMERU_IDENTYFIKACYJNEGO } from '../../../const';
import { fetchApi } from '../../../fetch';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { validatePersonalData } from '../../../validators';
import { ButtonStyled, DialogCustom, GridCustom, Text } from '../../common';
import { FormDanePodmiotuFields } from '../common';

const RODZAJ_ADRESU_CHOICES = [
    {
        value: 'poland',
        label: 'Polska',
    },
    {
        value: 'abroad',
        label: 'Zagranica',
    },
];
const TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES = TYP_NUMERU_IDENTYFIKACYJNEGO.slice(0, 2);
const EMPTY_DATA = {
    id: null,
    nazwa: '',
    typNumeruIdentyfikacyjnego: TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES[0],
    numerIdentyfikacyjny: '',
    rodzajAdresu: RODZAJ_ADRESU_CHOICES[0],
    adres: {
        kraj: 'Polska',
        wojewodztwo: '',
        powiat: '',
        gmina: '',
        miejscowosc: '',
        kodPocztowy: '',
        ulica: '',
        numerDomu: '',
    },
};


class FormDanePodmiotu extends Component {

    constructor(props) {
        super(props);
        this.isNew = !Object.keys(props.data).length;
        this.state = {
            savingOn: false,
            data: Object.assign({}, EMPTY_DATA, props.data),
            errors: {},
            fetchError: '',
        };

        this.dialogComponent = null;
        this.xhrFetchSave = null;
    }

    componentWillUnmount() {
        // abort api request if exist
        if (this.xhrFetchSave !== null) {
            this.xhrFetchSave.abort();
        }
    }

    // handlers

    handleChangeRodzajAdresu = (value) => {
        let dct = {
            numerIdentyfikacyjny: '',
            rodzajAdresu: value,
        };
        let adres = {
            kraj: null,
            wojewodztwo: '',
            powiat: '',
            gmina: '',
            miejscowosc: '',
        };
        if (value.value === 'poland') {
            adres.kraj = 'Polska';
            dct['typNumeruIdentyfikacyjnego'] = TYP_NUMERU_IDENTYFIKACYJNEGO[0];
        } else {
            // set abroad
            dct['typNumeruIdentyfikacyjnego'] = TYP_NUMERU_IDENTYFIKACYJNEGO[2];
        }

        this.setState(prevState => {
            return {data: Object.assign({}, prevState.data, {
                ...dct,
                adres: Object.assign({}, prevState.data.adres, adres),
            })}
        });
    }

    handleChangeAttribute = (name, value, isAdresPart) => {
        this.setState(prevState => {
            let dict = {[name]: value};
            if (name === 'typNumeruIdentyfikacyjnego' && prevState.data['typNumeruIdentyfikacyjnego'].value !== value.value) {
                dict['numerIdentyfikacyjny'] = '';
            }
            if (isAdresPart) {
                return {data: Object.assign({}, prevState.data, {
                    adres: Object.assign({}, prevState.data.adres, dict),
                })}
            } else {
                return {data: Object.assign({}, prevState.data, dict)}
            }
        });
    }

    handleChangeMiejscowosc = (value) => {
        this.setState(prevState => {
            return {data: Object.assign({}, prevState.data, {
                adres: Object.assign({}, prevState.data.adres, value)
            })}
        });
    }

    handleChangeNumerIdentyfikacyjny = (value, onlyDigits=false, maxLength=null) => {
        if (onlyDigits) {
            value = value.replace(/[^\d]/g, '');
        }
        if (maxLength) {
            value = value.slice(0, maxLength);
        }
        this.setState(prevState => {
            return {data: Object.assign({}, prevState.data, {numerIdentyfikacyjny: value})}
        });
    }

    handleSave = () => {
        this.setState(
            {savingOn: true, fetchError: ''},
            () => {
                const data = this.getData();
                const [isValid, errors] = validatePersonalData(data);
                if (isValid) {
                    // save danePodmiotu on server
                    this.xhrFetchSave = fetchApi(
                        this.isNew ? '/api/users/economic-subject' : `/api/users/economic-subject/${this.state.data.id}`,
                        this.isNew ? 'POST' : 'PUT',
                        {},
                        getSerializedObject(data, {toServer: true}),
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors});
                }
            }
        );
    }

    handleFetchSaveSuccess = (data) => {
        this.xhrFetchSave = null;

        let data_ = Object.assign({}, this.state.data);
        if (this.isNew) {
            data_['id'] = data.economic_subject.id;
        }
        this.props.saveDanePodmiotu(data_);
    }

    handleFetchSaveError = (data) => {
        this.xhrFetchSave = null;     // clean xhr object
        this.setState({
            savingOn: false,
            fetchError: `Nie udało się zapisać danych podmiotu. ${translateErrorMessage(data.message)}`,
        });
    }

    handleFetchSaveIncorrectStatus = (status) => {
        this.xhrFetchSave = null;     // clean xhr object

        this.setState({
            savingOn: false,
            fetchError: `Podczas zapisu danych podmiotu wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    // helpers

    getData() {
        const data = this.state.data;
        const adres = data.adres;
        return {
            nazwa: data.nazwa,
            typNumeruIdentyfikacyjnego: data.typNumeruIdentyfikacyjnego.value,
            numerIdentyfikacyjny: data.numerIdentyfikacyjny,
            rodzajAdresu: data.rodzajAdresu.value,
            adres: {
                kraj: data.adres.kraj,
                wojewodztwo: adres.wojewodztwo,
                powiat: adres.powiat,
                gmina: adres.gmina,
                miejscowosc: adres.miejscowosc,
                kodPocztowy: adres.kodPocztowy,
                ulica: adres.ulica,
                numerDomu: adres.numerDomu,
            },
        }
    }

    // rendering

    render() {
        return (
            <DialogCustom
                className="dialog-long-content"
                dialogTitle={`${this.isNew ? 'Dodaj' : 'Edytuj'} dane podmiotu`}
                dialogRef={(c) => { this.dialogComponent = c }}
                onClose={this.props.closeDanePodmiotuForm}
            >
                <>
                    <FormDanePodmiotuFields
                        data={this.state.data}
                        errors={this.state.errors}
                        changeAttribute={this.handleChangeAttribute}
                        changeMiejscowosc={this.handleChangeMiejscowosc}
                        changeNumerIdentyfikacyjny={this.handleChangeNumerIdentyfikacyjny}
                        changeRodzajAdresu={this.handleChangeRodzajAdresu}
                    />
                    <GridCustom flexEnd>
                        <ButtonStyled onClick={this.props.closeDanePodmiotuForm} cancel>Anuluj</ButtonStyled>
                        <ButtonStyled onClick={this.handleSave} primary>
                            Zapisz<span className="sr-only"> dane podmiotu</span>
                        </ButtonStyled>
                    </GridCustom>
                    {this.state.fetchError.length > 0 && <Text error role="alert">{this.state.fetchError}</Text>}
                </>
            </DialogCustom>
        )
    }
}


export { FormDanePodmiotu };
