import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../context/user-context';
import {
    getActivityChildUrlByPerspectiveData,
    getActivityUrlByPerspectiveData,
    serializeAuditData,
    sortImplementationLevelByNumberCode
} from '../../../helpers';
import { useOneTimeFetchApi } from '../../../hooks';
import { URLS } from '../../../urls/frontend';
import {
    AuditData,
    Breadcrumps,
    ButtonStyled,
    Container,
    GridCustom,
    Loader,
    PaginatedListHeader,
    TableCustom,
    Text
} from '../../common';
import {
    ImplementationLevelTableRow
} from '../../layoutElements/implementationLevelsManagement';
import { DeleteImplementationLevel } from './DeleteImplementationLevel';
import { ImplementationLevelForm } from './ImplementationLevelForm';


export const ImplementationLevelManagement = ({
    addApiUrlName,
    addItemButtonLabel,
    addItemButtonVisible = true,
    additionalSaveData = {},
    apiUrl,
    auditDataBoxTitle,
    auditDataObjectName,
    codeMaxLength,
    editApiUrlName,
    getParentFormLabel = null,
    listObjectName,
    RelatedObjectsExistInfoComponent,
    RowComponent = ImplementationLevelTableRow,
    rowComponentProps = {},
    showAuditData = false,
    sortItemsFunction = sortImplementationLevelByNumberCode,
    tableHeaderList,
    tableName,
    titleFormLabel
}) => {
    const [formOn, setFormOn] = useState(false);
    const [editedItem, setEditedItem] = useState(null);
    const [requestId, setRequestId] = useState(1);
    const [deletedItem, setDeletedItem] = useState(null);

    const { permissions, removePermission } = useContext(UserContext);

    const {data, loadingOn, fetchError} = useOneTimeFetchApi({
        url: apiUrl,
        message403error: 'Nie masz uprawnień do przeglądania elementów słownika poziomów wdrożenia.',
        callback403: () => removePermission('previewDictionaries'),
        requestId,
    });

    const items = useMemo(
        () => {
            if (!data) { return [] }

            let items_ = [];
            if (data[listObjectName]) {
                items_ = data[listObjectName] || [];
            }
            if (sortItemsFunction) {
                items_.sort(sortItemsFunction);
            }

            return items_
        }, [data] // eslint-disable-line react-hooks/exhaustive-deps
    );

    function handleCancelFormOn() {
        setFormOn(false);
        setEditedItem(null);
    }

    function handleSetEditedItem(item) {
        setDeletedItem(null);
        setEditedItem(item);
        setFormOn(true);
    }

    function handleUpdateRequestId() {
        setRequestId(prevValue => prevValue + 1);
    }

    function handleSetDeletedItem(item) {
        setFormOn(false);
        setEditedItem(null);
        setDeletedItem(item);
    }

    function handleCancelDeleting() {
        setDeletedItem(null);
    }

    let parentFormLabel = '';
    let perspectiveId, programmeId, priorityId, activityId;
    if (data) {
        perspectiveId = (data.perspective || {}).id;
        programmeId = (data.programme || {}).id;
        priorityId = (data.priority || {}).id;
        activityId = (data.activity || {}).id;
        if (getParentFormLabel) {
            parentFormLabel = getParentFormLabel(
                (data.programme || {}).code,
                (data.priority || {}).code,
                (data.activity || {}).code
            );
        }
    }

    const { editingDictionaries: editingDictionariesPermission } = permissions;
    if (!tableHeaderList) {
        tableHeaderList = ['Kod', 'Nazwa'];
        if (editingDictionariesPermission) {
            tableHeaderList.push('');
        }
    }

    return (
        <>
            <Breadcrumps notZarzadzanieTrescia>
                <li className="breadcrumps__current">
                    Administracja słownikiem poziomów wdrażania
                </li>
            </Breadcrumps>
            {loadingOn
                ? <Loader />
                : !!fetchError
                    ? <Text error>{fetchError}</Text>
                    : (
                        <Container>
                            <div className="administration__header-links">
                                {data.perspective && (
                                    <Link to={URLS.managementProgrammesForPerspective.getUrl(perspectiveId)} className="link-text administration__header-link">
                                        {data.perspective.name}
                                    </Link>
                                )}
                                {[
                                    ['programme', programmeId ? URLS.managementPrioritiesForProgramme.getUrl(programmeId) : null],
                                    ['priority', priorityId ? URLS[getActivityUrlByPerspectiveData(data.perspective)].getUrl(priorityId) : null],
                                    ['activity', activityId ? URLS[getActivityChildUrlByPerspectiveData(data.perspective)].getUrl(activityId) : null]
                                ].map(([levelName, levelUrl]) => {
                                    if (levelUrl && data[levelName]) {
                                        const levelData = data[levelName] || {};
                                        return (
                                            <p key={levelName} className="administration__indented">
                                                <span className="administration__code">{levelData.code}</span>
                                                <Link to={levelUrl} className="link-text">
                                                    {levelData.name}
                                                </Link>
                                            </p>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                            <GridCustom fullwidth flexTop>
                                <PaginatedListHeader
                                    title={tableName}
                                    total={data.meta ? data.meta.total : 0} />
                                {addItemButtonVisible && editingDictionariesPermission && (
                                    <ButtonStyled add onClick={() => setFormOn(true)}>
                                        {addItemButtonLabel || 'Dodaj'}
                                    </ButtonStyled>
                                )}
                            </GridCustom>
                            {items.length > 0 && (
                                <TableCustom tableHeaderList={tableHeaderList}>
                                    {items.map(item => (
                                        <RowComponent
                                            key={item.id}
                                            item={item}
                                            perspectiveData={data.perspective || {}}
                                            {...rowComponentProps}
                                            onEdit={handleSetEditedItem}
                                            onDelete={handleSetDeletedItem}
                                        />
                                    ))}
                                </TableCustom>
                            )}
                            {formOn && (
                                <ImplementationLevelForm
                                    addApiUrlName={addApiUrlName}
                                    additionalSaveData={additionalSaveData}
                                    codeMaxLength={codeMaxLength}
                                    editApiUrlName={editApiUrlName}
                                    originalData={editedItem}
                                    parentFormLabel={parentFormLabel}
                                    titleFormLabel={titleFormLabel}
                                    onCancel={handleCancelFormOn}
                                    onSave={handleUpdateRequestId}
                                />
                            )}
                            {deletedItem !== null && (
                                <DeleteImplementationLevel
                                    apiUrlName={editApiUrlName}
                                    item={deletedItem}
                                    parentFormLabel={parentFormLabel}
                                    perspectiveData={data.perspective || {}}
                                    RelatedObjectsExistInfoComponent={RelatedObjectsExistInfoComponent}
                                    titleFormLabel={titleFormLabel}
                                    onCancel={handleCancelDeleting}
                                    onDelete={handleUpdateRequestId}
                                />
                            )}
                            {showAuditData && auditDataObjectName &&
                                <AuditData
                                    {...serializeAuditData(data[auditDataObjectName])}
                                    titleOneRow={`${auditDataBoxTitle} ${parentFormLabel}`}
                                />
                            }
                        </Container>
                    )
            }
        </>
    )
};
