import React from 'react';
import { Checkbox } from '@rmwc/checkbox';

import { Box, ButtonStyled, FieldWithLabel, Text } from '../../common';
import { ZalacznikiSelect } from '../common';


const FormRozstrzygniecieWariant = (props) => {
    const { wariant } = props;
    const checkboxLabel = <>Wybierz ofertę <span className="sr-only">od {wariant.nazwaOferenta}</span></>;
    
    let offerTitle = `${wariant.cena} PLN`;
    if (wariant.isSelected) {
        offerTitle = <><span className="sr-only">Wybrana oferta: </span> {wariant.cena} PLN</>
    }

    return (
        <Box titleOneRow={offerTitle} tag="li" >
            <Text>{wariant.nazwaOferenta}</Text>
            {wariant.opis.length > 0 && (
                <FieldWithLabel label="Opis">
                    <Text className="long-text">{wariant.opis}</Text>
                </FieldWithLabel>
            )}
            <FieldWithLabel
                label="Załączniki"
                tag="label"
                selectRef={wariant.zalaczniki.length > 0 ? React.createRef() : null} >
                <ZalacznikiSelect zalaczniki={wariant.zalaczniki} />
            </FieldWithLabel>
            {wariant.isRefused && (
                <ButtonStyled
                    primary
                    onClick={ev => props.onOfferRestore(wariant.ofertaId)}
                >Przywróć ofertę{props.hasOfertaManyWarianty && ' i wszystkie jej warianty'}</ButtonStyled>)}
            {!wariant.isRefused && (
                <>
                    <ButtonStyled
                        remove
                        onClick={ev => props.onOfferRefuse(wariant.ofertaId)}
                    >Odrzuć ofertę<span className="sr-only"> od {wariant.nazwaOferenta} </span>{props.hasOfertaManyWarianty && ' i wszystkie jej warianty'}</ButtonStyled>
                    <Checkbox
                        className="checkbox--btn checkbox--btn-primary "
                        label={checkboxLabel}
                        checked={wariant.isSelected}
                        onChange={ev => props.onWariantSelect(
                            ev.target.checked, props.zamowienieNode, wariant.id)}
                    />
                </>
            )}
        </Box>
    );
}

export { FormRozstrzygniecieWariant };
