import { useEffect, useState } from 'react';

import { fetchApi } from '../fetch';


export const useCheckPermissionExists = (
    checkingExistedPermissionOn, setCheckingExistedPermissionOn, url,
    fetchDataDict, setExistsErrorOn, setPermissions403errorOn, userId
) => {
    const [existingPermissionData, setExistedPermissionData] = useState(null);

    function checkPermission() {
        if (!checkingExistedPermissionOn || !url.length) { return }

        let xhrFetch = null;

        function callbackPermissionExists(data) {
            xhrFetch = null;
            setExistedPermissionData(data.permission);
            setCheckingExistedPermissionOn(false);
            setExistsErrorOn(true);
        }

        function callbackPermissionDoesNotExists() {
            xhrFetch = null;
            setCheckingExistedPermissionOn(false);
        }

        function callbackPermissionIncorrectStatus(statusCode) {
            if (statusCode === 403) {
                setPermissions403errorOn(true);
            }
            callbackPermissionDoesNotExists();
        }

        xhrFetch = fetchApi(
            url, 'GET', {},
            {authorized: userId, ...fetchDataDict},
            callbackPermissionExists, callbackPermissionDoesNotExists,
            callbackPermissionIncorrectStatus
        );
        return () => {
            if (xhrFetch !== null) {
                xhrFetch.abort();
            }
        }
    }
    useEffect(checkPermission, [checkingExistedPermissionOn]);

  return {existingPermissionData}
};
