import React, { useContext } from 'react';
import { Checkbox } from '@rmwc/checkbox';
import { Typography } from '@rmwc/typography';

import { SELECT_CLASSNAME, SELECT_INVALID_CLASSNAME } from '../../../const';
import { AdvertisementContext } from '../../../context/advertisementContext';
import { UserContext } from '../../../context/user-context';
import { listOrEmpty } from '../../../helpers';
         import { sortProjects } from '../../../sorters';
import { Container, FieldWithLabel, GridCustom, Select, Steps, Text,
         TextFieldCustom } from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { PomocEkranowa } from '../zarzadzanieTrescia';
import { AlreadyPublishedInfo } from './AlreadyPublishedInfo';
import { withStepConfiguration } from './hoc/withStepConfiguration';


const NO_PROJECT_TEXT =
    'Nie masz zdefiniowanych projektów. Określ je w menu Projekty i Nabory.';
const NO_PROJECT_ADMIN_TEXT =
    'Ogłoszeniodawca nie ma zdefiniowanych projektów.';
const NO_ENROLLMENT_TEXT =
    'Nie masz zdefiniowanych naborów. Określ je w menu Projekty i Nabory.';
const NO_ENROLLMENT_ADMIN_TEXT =
    'Ogłoszeniodawca nie ma zdefiniowanych naborów.';
const NO_OSOBY_TEXT =
    'Nie masz zdefiniowanych osób do kontaktu. Określ je w ustawieniach profilu.';


const StepPozostaleBase = (props) => {

    function sortProjectsEnrollments(objectList) {
        return sortProjects(objectList, 'calyNumer_asc')
    }

    const { isAlreadyPublished, isPreview, projectsSelected } = props;

    const { user } = useContext(UserContext);
    const { advertisementManagerType } = user;
    const context = useContext(AdvertisementContext);
    const {
        nabory,
        ogloszenie: o,
        osobyDoKontaktu,
        projekty,
        setAttributeValue
    } = context;

    const formLocked = isPreview || context.validationOn || context.savingOn;

    const errors = context.formErrors.ogloszenie;
    const osobyDoKontaktu_ = isPreview ? [] : listOrEmpty(o.osobyDoKontaktu);

    const isProjektyInvalid = (errors.projekty || '').length > 0;
    const isNumerNaboruInvalid = (errors.nabor || '').length > 0;
    const isTytulInvalid = (errors.tytul || '').length > 0;
    const isOsobyDoKontaktuInvalid = (errors.osobyDoKontaktu || '').length > 0;

    let tytul = '';
    let zamowieniaUzupelniajace = '';
    let warunkiZmianyUmowy = '';
    if (!isPreview) {
        tytul = o.tytul;
        warunkiZmianyUmowy = o.warunkiZmianyUmowy;
        zamowieniaUzupelniajace = o.zamowieniaUzupelniajace;
    }

    const { isAuthor } = advertisementManagerType;

    const projectsEnrollmentsDisabled = formLocked ||
        (isAlreadyPublished && isAuthor);
    const contactPersonsDisabled = formLocked ||
        (isAlreadyPublished && advertisementManagerType.isAdmin);

    return (
        <>
            <PomocEkranowa ekran="form_announcement_others" content={props.pomocEkranowaContent} />
            {isAlreadyPublished && <AlreadyPublishedInfo />}
            <Container>
                <FieldWithLabel label="Numer ogłoszenia">
                    <Typography use="headline4" tag="h2">{isPreview ? '-' : o.ogloszenie.numer || '-'}</Typography>
                </FieldWithLabel>
                <Checkbox
                    checked={projectsSelected}
                    disabled={projectsEnrollmentsDisabled}
                    label={`${isAuthor ? 'Mam' : 'Ogłoszeniodawca ma'} projekt`}
                    onChange={props.onChangeProjectsSelected}
                />
                <GridCustom oneRow fullwidth flexBottom>
                    {projectsSelected && (
                        <FieldWithLabel
                            aria-label={isAlreadyPublished
                                ? 'Dołączone projekty. Projekty nie mogą być już zmienione.'
                                : `Projekty ${!projekty.length 
                                    ? (isAuthor ? NO_PROJECT_TEXT : NO_PROJECT_ADMIN_TEXT) 
                                    : ''
                                } ${isProjektyInvalid ? errors.projekty : ''}`
                            }
                            label="Projekty"
                            selectRef={React.createRef()}
                            tag="label"
                        >
                            <Select
                                className={isProjektyInvalid ? SELECT_INVALID_CLASSNAME : SELECT_CLASSNAME}
                                closeMenuOnSelect={false}
                                defaultValue={isPreview ? [] : projekty.filter(
                                    p => o.projekty.includes(parseInt(p.id))
                                ).map(p => ({value: p.id, label: p.calyNumer}))}
                                isDisabled={projectsEnrollmentsDisabled}
                                isClearable={false}
                                isMulti
                                isSearchable
                                noOptionsMessage={() => 'Brak wybranej opcji'}
                                options={
                                    sortProjectsEnrollments(projekty)
                                        .map(p => ({value: p.id, label: p.calyNumer}))
                                }
                                placeholder='Wybierz...'
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej. Możesz wybrać kilka opcji.' }}
                                styles={customSelectStyles}
                                onChange={selectedOptions => setAttributeValue(
                                    'projekty', selectedOptions === null ? [] : [...selectedOptions].map(option => parseInt(option.value))
                                )}
                            />
                            {!projekty.length &&
                                <Text error id="no_projects_info">
                                    {isAuthor ? NO_PROJECT_TEXT : NO_PROJECT_ADMIN_TEXT}
                                </Text>
                            }
                            {isProjektyInvalid && <Text error>{errors.projekty}</Text>}
                        </FieldWithLabel>
                    )}
                    {!projectsSelected && (
                        <FieldWithLabel
                            aria-label={isAlreadyPublished
                                ? 'Nabór. Nabór nie może być już zmieniony.'
                                : `Nabór ${!nabory.length 
                                    ? (isAuthor ? NO_ENROLLMENT_TEXT : NO_ENROLLMENT_ADMIN_TEXT)
                                    : ''
                                } ${isNumerNaboruInvalid ? errors.nabor : ''}`
                            }
                            label="Nabór"
                            selectRef={React.createRef()}
                            tag="label"
                        >
                            <Select
                                className={isNumerNaboruInvalid ? SELECT_INVALID_CLASSNAME : SELECT_CLASSNAME}
                                closeMenuOnSelect={false}
                                defaultValue={(isPreview || o.nabor === null) ? null :
                                    nabory.filter(n => o.nabor.value === n.id)
                                        .map(p => ({value: p.id, label: p.calyNumer}))
                                }
                                isDisabled={projectsEnrollmentsDisabled}
                                isClearable={false}
                                isSearchable
                                noOptionsMessage={() => 'Brak wybranej opcji'}
                                options={
                                    sortProjectsEnrollments(nabory)
                                        .map(n => ({value: n.id, label: n.calyNumer}))
                                }
                                placeholder='Wybierz...'
                                screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                                styles={customSelectStyles}
                                onChange={selectedOption => setAttributeValue('nabor', selectedOption)}
                            />
                            {!nabory.length &&
                                <Text error>
                                    {isAuthor ? NO_ENROLLMENT_TEXT : NO_ENROLLMENT_ADMIN_TEXT }
                                </Text>
                            }
                            {isNumerNaboruInvalid && <Text error>{errors.nabor}</Text>}
                        </FieldWithLabel>
                    )}
                </GridCustom>
                <FieldWithLabel label="Tytuł" tag="label" labelFor="title_id">
                    <TextFieldCustom
                        aria-describedby={isTytulInvalid ? 'tytul_error' : null}
                        aria-label={`Tytuł, maksymalna liczba znaków: 1000. Wpisano ${tytul.length} znaków.`}
                        aria-valuemax={1000}
                        characterCount
                        clearFieldContext="tytuł"
                        disabled={formLocked}
                        fullwidth
                        id="title_id"
                        invalid={isTytulInvalid}
                        maxLength={1000}
                        textarea
                        value={tytul}
                        onChange={ev => setAttributeValue('tytul', ev.target.value)}
                        onClear={ev => setAttributeValue('tytul', '')} />
                    {isTytulInvalid && <Text error id="tytul_error">{errors.tytul}</Text>}
                </FieldWithLabel>
                <FieldWithLabel
                    label="Zamówienia uzupełniające"
                    labelFor="zamowieniaUzupelniajace_id"
                    tag="label"
                >
                    <TextFieldCustom
                        aria-label={isAlreadyPublished
                            ? 'Zamówienia uzupełniające. Pole nie może być już edytowane.'
                            : `Zamówienia uzupełniające, maksymalna liczba znaków: 6000. Wpisano ${zamowieniaUzupelniajace.length} znaków.`
                        }
                        aria-valuemax={6000}
                        characterCount
                        clearFieldContext="zamówienie uzupełniające"
                        disabled={isAlreadyPublished || formLocked}
                        fullwidth
                        id="zamowieniaUzupelniajace_id"
                        maxLength={6000}
                        textarea
                        value={zamowieniaUzupelniajace}
                        onChange={ev => setAttributeValue('zamowieniaUzupelniajace', ev.target.value)}
                        onClear={ev => setAttributeValue('zamowieniaUzupelniajace', '')} />
                </FieldWithLabel>
                <FieldWithLabel
                    label="Warunki zmiany umowy"
                    labelFor="warunkiZmianyUmowy_id"
                    tag="label"
                >
                    <TextFieldCustom
                        aria-label={isAlreadyPublished
                            ? 'Warunki zmiany umowy. Pole nie może być już edytowane.'
                            : `Warunki zmiany umowy, maksymalna liczba znaków: 6000. Wpisano ${warunkiZmianyUmowy.length} znaków.`
                        }
                        aria-valuemax={6000}
                        clearFieldContext="warunki zmiany umowy"
                        characterCount
                        disabled={isAlreadyPublished || formLocked}
                        id="warunkiZmianyUmowy_id"
                        fullwidth
                        maxLength={6000}
                        textarea
                        value={warunkiZmianyUmowy}
                        onChange={ev => setAttributeValue('warunkiZmianyUmowy', ev.target.value)}
                        onClear={ev => setAttributeValue('warunkiZmianyUmowy', '')} />
                </FieldWithLabel>
                <FieldWithLabel label="Osoby do kontaktu" tag="label" selectRef={React.createRef()}>
                    <Select
                        aria-label={`Osoby do kontaktu ${!osobyDoKontaktu.length ? NO_OSOBY_TEXT : ''} ${isOsobyDoKontaktuInvalid ? errors.osobyDoKontaktu : ''}`}
                        className={isOsobyDoKontaktuInvalid > 0 ? SELECT_INVALID_CLASSNAME : SELECT_CLASSNAME}
                        closeMenuOnSelect={false}
                        defaultValue={osobyDoKontaktu.filter(o => osobyDoKontaktu_.includes(o.id)).map(
                            o => ({value: o.id, label: o.imie + ' ' + o.nazwisko}))}
                        isClearable={false}
                        isDisabled={contactPersonsDisabled}
                        isMulti
                        isSearchable
                        noOptionsMessage={() => 'Brak wybranej opcji'}
                        options={osobyDoKontaktu.map(o => ({value: o.id, label: o.imie + ' ' + o.nazwisko}))}
                        placeholder='Wybierz...'
                        screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej. Możesz wybrać kilka opcji.' }}
                        styles={customSelectStyles}
                        onChange={selectedOptions => setAttributeValue(
                            'osobyDoKontaktu', selectedOptions === null ? [] : [...selectedOptions].map(option => option.value))}
                    />
                    {!osobyDoKontaktu.length && <Text error>{NO_OSOBY_TEXT}</Text>}
                    {isOsobyDoKontaktuInvalid && <Text error>{errors.osobyDoKontaktu}</Text>}
                </FieldWithLabel>
            </Container>
            <Steps
                disabled={formLocked}
                elementToFocusRef={props.elementToFocusRef}
                hasNext
                hasPrev
                nextLabel="Załączniki"
                prevLabel="Części i kryteria"
                onNextClick={() => props.onChangeCurrentMenuLink('zalaczniki')}
                onPrevClick={() => props.onChangeCurrentMenuLink('czesciKryteria')}
            />
        </>
    )
}

const StepPozostale = withStepConfiguration(StepPozostaleBase, 'pozostale');
export { StepPozostale }
