import React, { Component } from 'react';

import {
    RODZAJ_ADRESU_CHOICES,
    TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES
} from '../../../const';
import { FieldWithLabel, GridCustom, Select, Text, TextFieldCustom } from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { FormKraj, FormMiejscowosc } from '../common';


class FormDanePodmiotuFields extends Component {

    // handlers
    handlePasteNumerIdentyfikacyjny = (ev, maxLength) => {
        // stop data actually being pasted into input
        ev.stopPropagation();
        ev.preventDefault();

        // set pasted data via clipboard API
        const clipboardData = ev.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        this.props.changeNumerIdentyfikacyjny(pastedData, true, maxLength);
    }

    // rendering

    render() {
        const {changeAttribute, data, errors, formLocked } = this.props;
        const adres = data.adres;
        const poland = data.rodzajAdresu.value === 'poland';

        const isNazwaInvalid = (errors.nazwa || []).length > 0;
        const adresErrors = errors.adres || {};
        const isKodPocztowyInvalid = (adresErrors.kodPocztowy || []).length > 0;
        const isUlicaInvalid = (adresErrors.ulica || []).length > 0;
        const isNumerDomuInvalid = (adresErrors.numerDomu || []).length > 0;
        const isFormInvalid = isNazwaInvalid || adresErrors.length > 0 || isKodPocztowyInvalid || isUlicaInvalid || isNumerDomuInvalid;

        return (
            <>
                <GridCustom fullwidth flexM>
                    <FieldWithLabel label="Nazwa" tag="label" labelFor="name_id">
                        <TextFieldCustom
                            aria-describedby={isNazwaInvalid ? 'name_error' : null}
                            aria-label={`Nazwa, maksymalna liczba znaków: 250. Wpisano ${data.nazwa.length} znaków.`}
                            autoComplete="organization"
                            autoFocus
                            characterCount
                            className="filters__input"
                            clearFieldContext="nazwa"
                            disabled={formLocked}
                            id="name_id"
                            invalid={isNazwaInvalid}
                            maxLength={250}
                            value={data.nazwa}
                            onChange={(ev) => changeAttribute('nazwa', ev.target.value)}
                            onClear={(ev) => changeAttribute('nazwa', '')} />
                        {isNazwaInvalid && <ul id="name_error">{errors.nazwa.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                </GridCustom>
                <GridCustom fullwidth flexM>
                    <FieldWithLabel
                        label="Rodzaj adresu"
                        tag="label"
                        selectRef={React.createRef()}>
                        <Select
                            screenReaderStatus={() => {return 'Wybierz opcję z listy rozwijanej'}}
                            className="select-custom"
                            isDisabled={formLocked}
                            options={RODZAJ_ADRESU_CHOICES}
                            value={data.rodzajAdresu}
                            onChange={selectedOption => this.props.changeRodzajAdresu(selectedOption)}
                            styles={customSelectStyles} />
                    </FieldWithLabel>
                    {this.renderIdentyfikator()}
                </GridCustom>
                {this.renderKraj()}
                {this.renderMiejscowosc()}
                <GridCustom fullwidth flexM>
                    <FieldWithLabel label="Kod pocztowy" tag="label" labelFor="postal_code_id">
                        <TextFieldCustom
                            aria-describedby={isKodPocztowyInvalid ? 'postal_code_error' : null}
                            aria-label={`Kod pocztowy, maksymalna liczba znaków: ${poland ? 6 : 10}. Wpisano ${adres.kodPocztowy.length} znaków.`}
                            autoComplete="postal-code"
                            characterCount
                            className="filters__input"
                            clearFieldContext="kod pocztowy"
                            disabled={formLocked}
                            id="postal_code_id"
                            invalid={isKodPocztowyInvalid}
                            maxLength={poland ? 6 : 10}
                            value={adres.kodPocztowy}
                            onChange={(ev) => changeAttribute('kodPocztowy', ev.target.value, true)}
                            onClear={(ev) => changeAttribute('kodPocztowy', '', true)} />
                        {isKodPocztowyInvalid && <ul id="postal_code_error">{adresErrors.kodPocztowy.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                    <FieldWithLabel label="Ulica" tag="label" labelFor="street_id">
                        <TextFieldCustom
                            aria-describedby={isUlicaInvalid ? 'street_error' : null}
                            aria-label={`Ulica, maksymalna liczba znaków: 255. Wpisano ${adres.ulica.length} znaków.`}
                            autoComplete="address-line1"
                            characterCount
                            className="filters__input"
                            clearFieldContext="ulica"
                            disabled={formLocked}
                            id="street_id"
                            invalid={isUlicaInvalid}
                            maxLength={255}
                            value={adres.ulica}
                            onChange={(ev) => changeAttribute('ulica', ev.target.value, true)}
                            onClear={(ev) => changeAttribute('ulica', '', true)} />
                        {isUlicaInvalid && <ul id="street_error">{adresErrors.ulica.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                    <FieldWithLabel label="Numer" tag="label" labelFor="number_id">
                        <TextFieldCustom
                            aria-describedby={isNumerDomuInvalid ? 'number_error' : null}
                            aria-label={`Numer, maksymalna liczba znaków: 120. Wpisano ${adres.numerDomu.length} znaków.`}
                            autoComplete="address-line1"
                            characterCount
                            className="filters__input"
                            clearFieldContext="numer"
                            disabled={formLocked}
                            id="number_id"
                            invalid={isNumerDomuInvalid}
                            maxLength={120}
                            value={adres.numerDomu}
                            onChange={(ev) => changeAttribute('numerDomu', ev.target.value, true)}
                            onClear={(ev) => changeAttribute('numerDomu', '', true)} />
                        {isNumerDomuInvalid && <ul id="number_error">{adresErrors.numerDomu.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                    </FieldWithLabel>
                </GridCustom>
                {isFormInvalid && <span role="alert" className="sr-only">Formularz zawiera błędy.</span>}
            </>
        )
    }

    renderKraj() {
        if (this.props.data.rodzajAdresu.value === 'poland') {
            return null
        }
        const krajErrors = (this.props.errors.adres || {}).kraj || [];
        const isKrajInvalid = krajErrors.length > 0;
        return (
            <FormKraj
                error={isKrajInvalid ? krajErrors[0] : ''}
                formLocked={this.props.formLocked}
                invalid={isKrajInvalid}
                isClearable
                krajName={this.props.data.adres.kraj}
                changeKraj={(selectedOption) => this.props.changeAttribute(
                    'kraj', selectedOption === null ? null : selectedOption.label, true
                )} />
        )
    }

    renderMiejscowosc() {
        const miejscowoscErrors = (this.props.errors.adres || {}).miejscowosc || [];
        const isMiejscowoscInvalid = miejscowoscErrors.length > 0;
        const { data, formLocked } = this.props;
        const adres = data.adres;
        if (data.rodzajAdresu.value === 'poland') {
            const miejscowosc = adres.miejscowosc.length > 0 ? {
                wojewodztwo: adres.wojewodztwo,
                powiat: adres.powiat,
                gmina: adres.gmina,
                miejscowosc: adres.miejscowosc,
            } : null;
            return (
                <FormMiejscowosc
                    error={isMiejscowoscInvalid ? miejscowoscErrors[0] : ''}
                    formLocked={formLocked}
                    invalid={isMiejscowoscInvalid}
                    miejscowosc={miejscowosc}
                    changeMiejscowosc={this.props.changeMiejscowosc} />
            )
        }
        return (
            <FieldWithLabel label="Miejscowość" tag="label" labelFor="city_id">
                <TextFieldCustom
                    aria-describedby={isMiejscowoscInvalid ? 'city_error' : null}
                    aria-label={`Miejscowość, maksymalna liczba znaków: 100. Wpisano ${adres.miejscowosc.length} znaków.`}
                    autoComplete="address-line2"
                    characterCount
                    className="filters__input"
                    clearFieldContext="miejscowość"
                    disabled={formLocked}
                    id="city_id"
                    invalid={isMiejscowoscInvalid}
                    maxLength={100}
                    value={adres.miejscowosc}
                    onChange={ev => this.props.changeAttribute('miejscowosc', ev.target.value, true)}
                    onClear={ev => this.props.changeAttribute('miejscowosc', '', true)} />
                {isMiejscowoscInvalid && <ul id="city_error">{miejscowoscErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
            </FieldWithLabel>
        )
    }

    renderIdentyfikator() {
        const { data, formLocked } = this.props;
        const numerIdentyfikacyjnyErrors = this.props.errors.numerIdentyfikacyjny || [];
        const isNumerIdentyfikacyjnyInvalid = numerIdentyfikacyjnyErrors.length > 0;

        if (data.rodzajAdresu.value !== 'poland') {
            return (
                <FieldWithLabel label="Numer identyfikacyjny" tag="label" labelFor="identification_number_id">
                    <TextFieldCustom
                        aria-describedby={`identification_number_info${isNumerIdentyfikacyjnyInvalid ? ' identification_number_error' : ''}`}
                        aria-label={`Numer identyfikacyjny (dla podmiotów zagranicznych), maksymalna liczba znaków: 255. Wpisano ${data.numerIdentyfikacyjny.length} znaków.`}
                        characterCount
                        className="filters__input"
                        clearFieldContext="numer identyfikacyjny"
                        disabled={formLocked}
                        id="identification_number_id"
                        invalid={isNumerIdentyfikacyjnyInvalid}
                        maxLength={255}
                        value={data.numerIdentyfikacyjny}
                        onChange={ev => this.props.changeNumerIdentyfikacyjny(ev.target.value)}
                        onClear={ev => this.props.changeNumerIdentyfikacyjny('')} />
                    <Text info id="identification_number_info">(dla podmiotów zagranicznych)</Text>
                    {isNumerIdentyfikacyjnyInvalid && (
                        <>
                            <ul id="identification_number_error">{numerIdentyfikacyjnyErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>
                            <span role="alert" className="sr-only">Formularz zawiera błędy!</span>
                        </>
                    )}
                </FieldWithLabel>
            )
        }
        const maxLength = data.typNumeruIdentyfikacyjnego.value === 'nip' ? 10 : 11;
        return (
            <>
                <FieldWithLabel
                    label="NIP/PESEL"
                    tag="label"
                    selectRef={React.createRef()} >
                    <Select
                        screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                        className="select-custom"
                        isDisabled={formLocked}
                        options={TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES}
                        value={this.props.data.typNumeruIdentyfikacyjnego}
                        onChange={selectedOption => this.props.changeAttribute('typNumeruIdentyfikacyjnego', selectedOption)}
                        styles={customSelectStyles}
                    />
                </FieldWithLabel>
                <FieldWithLabel label={data.typNumeruIdentyfikacyjnego.label} tag="label" labelFor="numerIdentyfikacyjny_id">
                    <TextFieldCustom
                        aria-describedby={`identification_number_info${isNumerIdentyfikacyjnyInvalid ? ' identification_number_error' : ''}`}
                        aria-label={`${data.typNumeruIdentyfikacyjnego.label} (dla podmiotów z Polski), maksymalna liczba znaków: ${maxLength}. Wpisz tylko cyfry. Wpisano ${data.numerIdentyfikacyjny.length} znaków.`}
                        aria-valuemax={maxLength}
                        characterCount
                        className="filters__input"
                        clearFieldContext={data.typNumeruIdentyfikacyjnego.label}
                        disabled={formLocked}
                        id="numerIdentyfikacyjny_id"
                        invalid={isNumerIdentyfikacyjnyInvalid}
                        maxLength={maxLength}
                        value={data.numerIdentyfikacyjny}
                        onChange={(ev) => this.props.changeNumerIdentyfikacyjny(ev.target.value, true)}
                        onClear={(ev) => this.props.changeNumerIdentyfikacyjny('')}
                        onKeyUp={(ev) => this.props.changeNumerIdentyfikacyjny(ev.target.value, true)}
                        onPaste={(ev) => this.handlePasteNumerIdentyfikacyjny(ev, maxLength)} />
                    <Text info id="identification_number_info">(dla podmiotów z Polski)</Text>
                    {isNumerIdentyfikacyjnyInvalid && (
                        <>
                            <ul id="identification_number_error">{numerIdentyfikacyjnyErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>
                            <span role="alert" className="sr-only">Formularz zawiera błędy!</span>
                        </>
                    )}
                </FieldWithLabel>
            </>
        )
    }
}


export { FormDanePodmiotuFields };
