import React, { useEffect, useRef, useState } from 'react';

import { fetchApi } from '../../../fetch';
import { checkHasNext, checkHasPrev, dateToString } from '../../../helpers';
import {
    getSerializedObject,
    translateErrorMessage
} from '../../../serializers';
import {
    ButtonStyled,
    DateCalendar,
    DialogCustom,
    FieldWithLabel,
    GridCustom,
    Loader,
    Pagination,
    TableCustom,
    Text,
    TextFieldCustom
} from '../../common';
import { PermissionsHistoryListRow } from './PermissionsHistoryListRow';


const PAGINATED_BY = 20;


export const PermissionsHistory = ({dialogTitle = '', ...props}) => {
    const [initializingOn, setInitializingOn] = useState(true);
    const [requestId, setRequestId] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [fetchError, setFetchError] = useState(null);
    const [filters, setFilters] = useState({
        addedAtFrom: '',
        addedAtTo: '',
        addedByEmail: '',
    });
    const [data, setData] = useState(null);
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    useEffect(() => {
        setRequestId(1);
    }, []);

    useEffect(() => {
        setRequestId(prevValue => prevValue + 1);
    }, [pageNumber]);

    function getHistory() {
        if (requestId === null) { return }
        let xhrFetch = null;

        function callbackSuccess(data) {
            xhrFetch = null;
            const { total } = data.meta;
            setData({
                events: getSerializedObject(
                    data, {mapToCamelCaseName: true}).permissionsHistory || [],
                total: total,
                hasPrev: checkHasPrev(pageNumber),
                hasNext: checkHasNext(PAGINATED_BY, pageNumber, total),
            });
            setInitializingOn(false);
        }

        function callbackFetchError(message) {
            xhrFetch = null;
            setFetchError(message);
            setInitializingOn(false);
        }

        function callbackError(data) {
            callbackFetchError(
                `Nie udało się pobrać historii uprawnień. ${translateErrorMessage(data.message)}`
            );
        }

        function callbackIncorrectStatus(status) {
            callbackFetchError(status === 403
                ? 'Nie masz uprawnień do przeglądania historii.'
                : `Nie udało się pobrać historii uprawnień. Wystąpił nieoczekiwany błąd o kodzie ${status}.`
            );
        }

        setInitializingOn(true);
        setFetchError(null);
        xhrFetch = fetchApi(
            props.url, 'GET', {}, {
                authorized: props.userId, limit: PAGINATED_BY,
                page: pageNumber, ...filters,
                ...(props.additionalFetchData || {})
            },
            callbackSuccess, callbackError, callbackIncorrectStatus
        );
        return () => {
            if (xhrFetch !== null) {xhrFetch.abort()}
        }
    }
    useEffect(getHistory, [requestId, ]);

    function handleChangeFilter(name, value) {
        setFilters(prevValue => ({...filters, [name]: value}));
    }

    function handleChangePage(next=true) {
        setPageNumber(prev => next ? prev + 1 : Math.max(prev -1, 1));
    }

    function handleCalendarOpen() {
        setIsPickerOpen(true)
    }

    function handleCalendarClose() {
        setIsPickerOpen(false)
    }

    function handleEscPress(event) {
        if (event.keyCode === 27) {
            if (isPickerOpen) {
                event.nativeEvent.stopImmediatePropagation();
            } else {
                props.onClose();
            }
        }
    }

    const addedAtFromRef = useRef();
    const addedAtToRef = useRef();
    const addedAtFrom = !!filters.addedAtFrom
        ? new Date(filters.addedAtFrom)
        : '';
    const addedAtTo = !!filters.addedAtTo ? new Date(filters.addedAtTo) : '';

    return (
        <DialogCustom
            className="dialog-long-content"
            dialogTitle={dialogTitle}
            onClose={props.onClose}
            onKeyDown={handleEscPress}
        >
            <Text>Zakres historii</Text>
            <GridCustom fullwidth flexTop flexM className="permissions__history">
                {/* Empty div for WCAG purpose: prevents autofocus of the first calendar field */}
                <div tabIndex="0"></div>
                <FieldWithLabel
                    inputRef={addedAtFromRef}
                    label="od"
                    tag="label">
                    <DateCalendar
                        dateName="Od"
                        disabled={initializingOn}
                        maxDate={addedAtTo || undefined}
                        parentRef={addedAtFromRef}
                        value={addedAtFrom}
                        onCalendarOpen={handleCalendarOpen}
                        onCalendarClose={handleCalendarClose}
                        onChange={date => handleChangeFilter('addedAtFrom', dateToString(date))} />
                </FieldWithLabel>
                <FieldWithLabel
                    inputRef={addedAtToRef}
                    label="do"
                    tag="label">
                    <DateCalendar
                        dateName="Do"
                        disabled={initializingOn}
                        minDate={addedAtFrom || undefined}
                        parentRef={addedAtToRef}
                        value={addedAtTo}
                        onCalendarOpen={handleCalendarOpen}
                        onCalendarClose={handleCalendarClose}
                        onChange={date => handleChangeFilter('addedAtTo', dateToString(date))} />
                </FieldWithLabel>
                <FieldWithLabel
                    label="wprowadzający"
                    labelFor="filters_addedByEmail_id"
                    tag="label">
                    <TextFieldCustom
                        aria-label={`Wprowadzający, maksymalna liczba znaków: 100. Wpisano ${filters.addedByEmail.length} znaków.`}
                        aria-valuemax={100}
                        characterCount
                        className="filters__input"
                        clearFieldContext="wprowadzający"
                        disabled={initializingOn}
                        id="filters_addedByEmail_id"
                        maxLength={100}
                        value={filters.addedByEmail}
                        onChange={ev => handleChangeFilter('addedByEmail', ev.target.value)}
                        onClear={ev => handleChangeFilter('addedByEmail', '')} />
                </FieldWithLabel>
                <ButtonStyled
                    className="permissions__history-filter-btn"
                    disabled={initializingOn}
                    lite
                    onClick={() => {
                        if (pageNumber > 1) {
                            setPageNumber(1);
                        } else {
                            setRequestId(prevValue => prevValue + 1)
                        }
                    }}>
                    Filtruj
                </ButtonStyled>
            </GridCustom>
            {initializingOn
                ? <Loader />
                : (fetchError
                    ? <Text error>{fetchError}</Text>
                    : (
                        <>
                            {data.total === 0
                                ? <Text error>Historia jest pusta</Text>
                                : (
                                    <TableCustom
                                        tableHeaderList={props.tableHeaderList}>
                                        {data.events.map(event => (
                                            <PermissionsHistoryListRow
                                                event={event}
                                                isImplementationLevelType={
                                                    props.isImplementationLevelType
                                                }
                                                key={event.id}
                                                mode={props.mode} />
                                        ))}
                                    </TableCustom>
                                )
                            }
                            {data.total > PAGINATED_BY && (
                                <Pagination
                                    currentPage={pageNumber}
                                    disabled={initializingOn}
                                    hasNext={data.hasNext}
                                    hasPrev={data.hasPrev}
                                    handleChangePage={handleChangePage} />
                            )}
                        </>
                    )
                )
            }
            <GridCustom flexEnd>
                <ButtonStyled primary onClick={props.onClose}>
                    Zamknij
                </ButtonStyled>
            </GridCustom>
        </DialogCustom>
    )
};
