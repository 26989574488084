import React from 'react';
import { useParams } from 'react-router-dom';

import {
    getActivityUrlByPerspectiveData,
    hasSubactivities
} from '../../../helpers';
import { URLS } from '../../../urls/frontend';
import { API_URLS } from '../../../urls/api';
import {ImplementationLevelManagement, Text} from '../../common';


const RelatedObjectsExistInfoComponent = ({perspectiveData}) => {
    const hasSubactivities_ = hasSubactivities(perspectiveData);
    return (
        <>
            <Text error>
                Nie można usunąć osi priorytetowej, ponieważ jest ona
                powiązana z innym obiektem.
            </Text>
            <Text error>
                Upewnij się, że oś priorytetowa nie ma działań
                {hasSubactivities_ && ' i poddziałań'}, nie istnieją projekty
                lub nabory oparte o tę oś priorytetową oraz nie są przyznane
                uprawnienia do tej osi priorytetowej, a następnie spróbuj ponownie.
            </Text>
        </>
    )
};


export const Priorities = (props) => {
    const { programmeId } = useParams();

    function getItemLinkUrl(item, perspectiveData) {
        return URLS[getActivityUrlByPerspectiveData(perspectiveData)]
            .getUrl(item.id)
    }

    function getParentFormLabel(programmeCode, priorityCode, activityCode) {
        return `${programmeCode}.`
    }

    return (
        <ImplementationLevelManagement
            addApiUrlName="prioritiesAdd"
            addItemButtonLabel="Dodaj oś priorytetową"
            additionalSaveData={{programme: parseInt(programmeId)}}   // for form
            apiUrl={API_URLS.prioritiesAdmin.getUrl(programmeId)}
            auditDataBoxTitle="Dane audytowe dla programu operacyjnego"
            auditDataObjectName="programme"
            editApiUrlName="prioritiesEdit"
            getParentFormLabel={getParentFormLabel}
            listObjectName="priorities"
            RelatedObjectsExistInfoComponent={RelatedObjectsExistInfoComponent}
            rowComponentProps={{
                getItemLinkFunction: getItemLinkUrl, withLink: true
            }}
            showAuditData={true}
            tableName="Lista osi priorytetowych"
            titleFormLabel="osi priorytetowej"
        />
    )
};
