import React, { Component } from 'react';
import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { PdfHeader } from '../../common';
import { pdfStyles } from '../../vars/pdfStyles';
import { DraftToPdf } from './DraftToPdf';


class PomoceFrontPdf extends Component {

    render() {
        return (
            <Document>
                <Page size="A4" style={pdfStyles.mainPdfContainerStyle} wrap>
                    <PdfHeader />
                    <View>
                        {this.renderPomoc(this.props.pomoce[0], this.props.pomoce, 1)}
                    </View>
                </Page>
            </Document>
        )
    }

    renderPomoc(pomoc, pomoce, deepLevel) {
        return (
            <View key={pomoc.id}>
                <Text style={pdfStyles.textHeaderStyle}>{pomoc.naglowek}</Text>
                <View style={styles.sectionTextStyle}>
                    <DraftToPdf content={pomoc.tresc}/>
                </View>
                {this.renderPomocePodrzedne(pomoc.id, pomoce, deepLevel)}
            </View>
        )
    }

    renderPomocePodrzedne(pomocId, pomoce, deepLevel) {
        const pomocePodrzedne = pomoce.filter(p => p.rozdzialNadrzedny.id === pomocId);
        if (!pomocePodrzedne.length) { return null }
        return (
            <View style={[styles.secondarySectionStyle, (deepLevel >= 5) && styles.noPadding]}>
                {pomocePodrzedne.map(p => this.renderPomoc(p, pomoce, deepLevel + 1))}
            </View>
        )
    }
}


const styles = StyleSheet.create({
    sectionTextStyle: {
        marginBottom: 20
    },
    secondarySectionStyle: {
        paddingLeft: 16
    },
    noPadding: {
        paddingLeft: 0
    }
});


export { PomoceFrontPdf };
