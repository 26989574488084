import { TOKEN_COOKIE_NAME } from './const';
import {
    getAuthTokenCookieValue,
    getCookieValue,
    refreshTimerAfterRequest
} from './helpers';


const fetchApi = (url, method, headers, body, callbackSuccess,
        callbackError, callbackIncorrectStatus, callbackShowErrors=null) => {

    const xhr = new XMLHttpRequest();
    if (!['POST', 'PUT'].includes(method)) {
        if (Object.keys(body).length) {
            let urlParamsPart = '';
            for (let [k, v] of Object.entries(body)) {
                if (Array.isArray(v)) {
                    let i = 0;
                    for (let subvalue of v) {
                        urlParamsPart += `&${k}[${i++}]=${subvalue}`;
                    }
                } else {
                    urlParamsPart += `&${k}=${v}`;
                }
            }
            if (urlParamsPart.length) {
                url += `?${urlParamsPart.substring(1)}`;
            }
        }
    }
    xhr.open(method, encodeURI(url));

    // headers
    xhr.setRequestHeader('Content-type','application/json; charset=utf-8');
    const authTokenCookieValue = getAuthTokenCookieValue();
    if (authTokenCookieValue) {
        xhr.setRequestHeader('X-Auth-Token', authTokenCookieValue);
    }
    if (headers || null) {
        for (let [name, value] of Object.entries(headers)) {
            xhr.setRequestHeader(name, value);
        }
    }

    // define values and callbacks
    xhr.timeout = 40000;
    xhr.ontimeout = () => {
        callbackError({message: 'Serwer zbyt długo nie odpowiada.'})
    }
    xhr.onerror = () => {callbackIncorrectStatus(xhr.status)};
    xhr.onload = () => {
        if ([403, 404].includes(xhr.status)) {
            callbackIncorrectStatus(xhr.status);
            return
        }

        let success = true;
        let jsonData = {};
        try {
            jsonData = JSON.parse(xhr.responseText);
        } catch(error) {
            success = false;
        }
        if (!success) {
            callbackError({message: 'Wystąpił nieoczekiwany błąd.'});
        } else {
            refreshTimerAfterRequest(authTokenCookieValue, xhr.status);
            if (jsonData.status === 'OK') {
                callbackSuccess(jsonData.data);
            } else if (jsonData.status === 'Error') {
                if (callbackShowErrors && jsonData.errors && Object.keys(jsonData.errors).length > 0) {
                    callbackShowErrors(jsonData.errors)
                } else {
                    callbackError(jsonData);
                }
            } else {
                callbackIncorrectStatus(xhr.status);
            }
        }
    };

    xhr.send(JSON.stringify(body));
    return xhr
};


const fetchSativaApi = (url, callbackSuccess, callbackError, callbackIncorrectStatus) => {

    const xhr = new XMLHttpRequest();
    xhr.open('GET', encodeURI('/konto' + url));

    // headers
    xhr.setRequestHeader('Content-Type', 'application/json');
    const authTokenCookieValue = getAuthTokenCookieValue();
    if (authTokenCookieValue) {
        xhr.setRequestHeader('ACCESS-TOKEN', decodeURIComponent(
            getCookieValue(TOKEN_COOKIE_NAME)));
    }

    // define values and callbacks
    xhr.timeout = 15000;
    xhr.ontimeout = () => {
        callbackError({message: 'Serwer zbyt długo nie odpowiada.'})
    }
    xhr.onerror = () => {callbackIncorrectStatus(xhr.status)};
    xhr.onload = () => {
        if (xhr.status === 200) {
            let success = true;
            let jsonData = {};
            try {
                jsonData = JSON.parse(xhr.responseText);
            } catch(error) {
                success = false;
            }
            if (!success) {
                callbackError({message: 'Wystąpił nieoczekiwany błąd.'});
            } else {
                callbackSuccess(jsonData);
            }
            return
        }
        callbackIncorrectStatus(xhr.status);
    };
    xhr.send();
    return xhr
}


export { fetchApi, fetchSativaApi }
