import React from 'react';
import { Link } from 'react-router-dom';

import { OFERTA_STATUSES, TYP_NUMERU_IDENTYFIKACYJNEGO } from '../../../const';
import { fetchApi } from '../../../fetch';
import { getLastTemporaryIdFromList, reformatDanePodmiotu } from '../../../helpers';
import {
    getSerializedObjectErrorsFormServer,
    getSerializedObject,
    translateErrorMessage
} from '../../../serializers';
import {
    ButtonStyled,
    Container,
    DialogCustom,
    GridCustom,
    Text,
} from '../../common';
import { DanePodmiotu, FormBaseComponent } from '../common';
import { FormOfertaFields } from './FormOfertaFields';

const RODZAJ_ADRESU_CHOICES = [
    {
        value: 'poland',
        label: 'Polska',
    },
    {
        value: 'abroad',
        label: 'Zagranica',
    },
];
const TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES = [
    {
        value: 'nip',
        label: 'NIP',
    },
    {
        value: 'pesel',
        label: 'PESEL',
    },
];
const EMPTY_DANE_PODMIOTU_DATA = {
    nazwa: '',
    rodzajAdresu: RODZAJ_ADRESU_CHOICES[0],
    adres: {
        kraj: 'Polska',
        wojewodztwo: '',
        powiat: '',
        gmina: '',
        miejscowosc: '',
        kodPocztowy: '',
        ulica: '',
        numerDomu: '',
    },
    typNumeruIdentyfikacyjnego: TYP_NUMERU_IDENTYFIKACYJNEGO_CHOICES[0],
    numerIdentyfikacyjny: '',
};


class FormOferta extends FormBaseComponent {

    // handlers

    handleFetchUserDataSuccess = (data) => {
        this.xhrFetch = null;
        this.setState(
            prevState => {
                let oferty = [];
                const zamowienia = this.props.zamowienia;
                if (zamowienia.length === 1) {
                    oferty.push(this.getEmptyOferta(1, zamowienia[0].zamowienieNode.id));
                }
                return {
                    initializingOn: false,
                    danePodmiotu: reformatDanePodmiotu(
                        getSerializedObject(data).uzytkownik.danePodmiotu),
                    oferty: oferty,
                    zalaczniki: [],
                    id: null,
                    status: OFERTA_STATUSES.draft,
                }
            }
        );
    }

    handleFetchOfferDataSuccess = (data) => {
        this.xhrFetch = null;
        data = getSerializedObject(
            data, {addTemporaryId: true}
        ).pakietOfert;
        this.setState({
            initializingOn: false,
            danePodmiotu: reformatDanePodmiotu(data.danePodmiotu),
            oferty: (data.oferty || []).map(o => {
                o.zamowienieNode = o.zamowienieNode.id;
                return o
            }),
            zalaczniki: data.zalaczniki || [],
            id: data.id,
            status: data.status,
        });
    }

    handleCloseCancellingDialog = () => {
        this.setState({cancellingOn: false});
    }

    handleCloseRemovingDialog = () => {
        this.setState({removingOn: false});
    }

    handleCloseSubmittingDialog = () => {
        this.setState({submittingDialogOn: false});
    }

    handleChangeAddOferta = (zamowienieNodeId, isChecked) => {
        this.setState(prevState => {
            if (isChecked) {
                return {oferty: [...prevState.oferty, this.getEmptyOferta(
                    Math.max(...[...(prevState.oferty.map(o => o.temporaryId)), 0]) + 1,
                    zamowienieNodeId
                )]}
            } else {
                return {oferty: prevState.oferty.filter(
                    o => o.zamowienieNode !== zamowienieNodeId)}
            }
        });
    }

    handleChangeCzyWariantowa = (zamowienieNodeId, isChecked) => {
        this.setState(prevState => {
            let warianty = [];
            if (!isChecked) {
                warianty.push(this.getEmptyWariant());
            }
            return {oferty: prevState.oferty.map(o => {
                if (o.zamowienieNode === zamowienieNodeId) {
                    o.czyWariantowe = isChecked;
                    o.warianty = warianty;
                }
                return o
            })}
        });
    }

    handleAddWariant = (zamowienieNodeId) => {
        this.setState(prevState => {
            return {oferty: prevState.oferty.map(o => {
                if (o.zamowienieNode === zamowienieNodeId) {
                    o.warianty = [...o.warianty, this.getEmptyWariant()];
                }
                return o
            })}
        })
    }

    handleChangeWariantAttribute = (zamowienieNodeId, temporaryId, attrName, value) => {
        this.setState(prevState => {
            return prevState.oferty.map(o => {
                if (o.zamowienieNode === zamowienieNodeId) {
                    let warianty = [];
                    for (let w of o.warianty) {
                        if (w.temporaryId === temporaryId) {
                            w[attrName] = value;
                        }
                        warianty.push(w);
                    }

                    o.warianty = warianty;
                }
                return o
            })
        });
    }

    handleRemoveWariant = (zamowienieNodeId, temporaryId) => {
        this.setState(prevState => {
            return {oferty: prevState.oferty.map(o => {
                if (o.zamowienieNode === zamowienieNodeId) {
                    o.warianty = o.warianty.filter(w => w.temporaryId !== temporaryId);
                }
                return o
            })}
        });
    }

    handleAddZalacznik = (data) => {
        data = data || {};

        this.setState(prevState => {
            return {zalaczniki: [...prevState.zalaczniki, {
                id: null,
                temporaryId: getLastTemporaryIdFromList(prevState.zalaczniki) + 1,
                nazwa: '',
                plik: {
                    id: null,
                    uri: '',
                    nazwa: '',
                    dataUtworzenia: '',
                    ktoUtworzyl: {imie: '', nazwisko: ''},
                },
                plikDoWgrania: data.plikDoWgrania || null,
            }]}
        })
    }

    handleSaveZalacznikAttributes = (temporaryId, data) => {
        this.setState((prevState) => {
            let zalaczniki = [];
            for (let z of prevState.zalaczniki) {
                if (z.temporaryId === temporaryId) {
                    z = Object.assign(z, data);
                }
                zalaczniki.push(z);
            }
            return {zalaczniki}
        });
    }

    handleRemoveZalacznik = (temporaryId) => {
        this.setState(prevState => {
            return {zalaczniki: prevState.zalaczniki.filter((z) => z.temporaryId !== temporaryId)}
        });
    }

    handleFetchSaveSuccess = (data) => {
        super.handleFetchSaveSuccess(data);
        data = getSerializedObject(data, {addTemporaryId: true}).pakietOfert;
        this.setState(
            {
                savingOn: false,
                isNew: false,
                saveBtnText: 'Zapisano',
                id: data.id,
                status: data.status,
                zalaczniki: data.zalaczniki,
            },
            () => {
                this.saveMsgTimeout = setTimeout(() => {
                    this.setState({saveBtnText: this.saveBtnDefaultText})
                }, 5000)
            }
        );
    }

    handleShowSaveErrors = (errors) => {
        this.xhrFetchSave = null;
        this.setState(
            prevState => ({
                savingOn: false,
                saveBtnText: this.saveBtnDefaultText,
                errors: getSerializedObjectErrorsFormServer(
                    errors,
                    {
                        ogloszenie: this.props.ogloszenieId,
                        oferty: prevState.oferty,
                        zalaczniki: prevState.zalaczniki,
                    },
                    ['oferty', 'warianty', 'zalaczniki']
                ),
            })
        );
    }

    handleRemove = () => {
        this.xhrFetch = fetchApi(
            `/api/offerset/announcement/${this.props.ogloszenieId}`,
            'DELETE',
            {},
            {},
            this.handleFetchRemoveSuccess,
            this.handleFetchRemoveError,
            this.handleFetchRemoveIncorrectStatus
        );
    }

    handleFetchRemoveSuccess = (data) => {
        this.xhrFetch = null;
        window.location.reload();
    }

    handleFetchRemoveError = (data) => {
        this.xhrFetch = null;
        this.setState({
            removingOn: false,
            fetchRemoveError: `Nie udało się usunąć oferty. ${translateErrorMessage(data.message)}`,
        });
    }

    handleFetchRemoveIncorrectStatus = (status) => {
        this.xhrFetch = null;
        this.setState({
            removingOn: false,
            fetchRemoveError: `Nie udało się usunąć oferty. Wystąpił błąd o kodzie ${status}.`,
        });
    }

    handleSubmit = () => {
        this.setState(
            {savingOn: true, submittingDialogOn: false, fetchSaveError: ''},
            () => {
                this.xhrFetchSave = fetchApi(
                    this.getSubmitUrl(),
                    'PUT',
                    {},
                    this.getSerializedDataForSubmitting(),
                    this.handleFetchSubmitSuccess,
                    this.handleFetchSaveError,
                    this.handleFetchSaveIncorrectStatus,
                    this.handleShowSaveErrors,
                );
            }
        );
    }

    handleFetchSubmitSuccess = (data) => {
        this.xhrFetchSave = null;
        this.props.onSubmitOferta((data.offerset || {}).id || null);
    }

    // helpers

    getAdditionalState(props) {
        return {
            isNew: props.isNew,
            cancellingOn: false,
            removingOn: false,
            submittingDialogOn: false,
            danePodmiotu: {},
            oferty: [],
            zalaczniki: [],
            fetchRemoveError: '',
        };
    }

    fetchInitialData = () => {
        if (this.state.isNew) {
            this.xhrFetch = fetchApi(
                '/api/users/0',
                'GET',
                {},
                {},
                this.handleFetchUserDataSuccess,
                this.handleFetchError,
                this.handleFetchIncorrectStatus,
            );
        } else {
            this.xhrFetch = fetchApi(
                `/api/offerset/announcement/${this.props.ogloszenieId}`,
                'GET',
                {},
                {},
                this.handleFetchOfferDataSuccess,
                this.handleFetchError,
                this.handleFetchIncorrectStatus,
            );
        }
    }

    getFetchError(message) {
        message = translateErrorMessage(message);
        if (this.state.isNew) {
            return `Nie udało się pobrać danych do formularza oferty. ${message}`
        }
        return `Nie udało się pobrać danych oferty. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        if (this.state.isNew) {
            return `Podczas pobierania danych do formularza oferty wystąpił nieoczekiwany błąd o kodzie ${status}`
        }
        return `Podczas pobierania danych oferty wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    getWariantLastTemporaryId() {
        let lastTemporaryId = 0;
        for (let o of Object.values(this.state.oferty)) {
            for (let w of o.warianty) {
                if (w.temporaryId > lastTemporaryId) {
                    lastTemporaryId = w.temporaryId;
                }
            }
        }
        return lastTemporaryId
    }

    getEmptyOferta(temporaryId, zamowienieNodeId) {
        return {
            id: null,
            temporaryId,
            zamowienieNode: zamowienieNodeId,
            czyWariantowe: false,
            warianty: [this.getEmptyWariant(), ],
        }
    }

    getEmptyWariant() {
        return {
            id: null,
            temporaryId: this.getWariantLastTemporaryId() + 1,
            cena: null,
            opis: '',
        }
    }

    formPreState(props) {
        this.saveBtnDefaultText = 'Zapisz wersję roboczą oferty';
    }

    getSaveUrl() {
        return this.state.isNew ? '/api/offerset' : `/api/offerset/announcement/${this.props.ogloszenieId}`
    }

    getSaveMethod() {
        return this.state.isNew ? 'POST' : 'PUT'
    }

    getDataForSaving() {
        const data = {
            oferty: this.state.oferty,
            zalaczniki: this.state.zalaczniki.map(z => {
                return {id: z.id, nazwa: z.nazwa, plik: z.plik.id}
            }),
        }
        if (this.state.isNew) {
            data['ogloszenie'] = this.props.ogloszenieId;
        }
        return getSerializedObject(data, {toServer: true});
    }

    getSubmitUrl() {
        return `/api/offerset/announcement/${this.props.ogloszenieId}/submit`
    }

    getDataForSubmitting() {
        return {
            ogloszenie: this.props.ogloszenieId,
            oferty: this.state.oferty,
            zalaczniki: this.state.zalaczniki.map(z => {
                return {id: z.id, nazwa: z.nazwa, plik: z.plik.id}
            }),
        }
    }

    getSerializedDataForSubmitting() {
        return getSerializedObject(
            this.getDataForSubmitting(), {toServer: true})
    }

    // rendering

    renderContent() {
        const statusOferty = this.state.status;
        const formLocked = this.state.savingOn;
        const { fetchSaveError, fetchRemoveError, isNew } = this.state;
        const ogloszenieErrors = (this.state.errors || {}).ogloszenie || [];
        const formOfertaErrors = fetchRemoveError.length > 0 || fetchSaveError.length > 0 || ogloszenieErrors.length > 0;

        return (
            <>
                <Text tag="h1" mainHeader>{isNew ? 'Utwórz' : 'Edytuj'} ofertę</Text>
                {this.renderDanePodmiotu()}
                <FormOfertaFields
                    errors={this.state.errors}
                    formLocked={formLocked}
                    oferty={this.state.oferty}
                    withDanePodmiotuForm={false}
                    zalaczniki={this.state.zalaczniki}
                    zamowienia={this.props.zamowienia}
                    changeAddOferta={this.handleChangeAddOferta}
                    changeCzyWariantowa={this.handleChangeCzyWariantowa}
                    changeAddZalacznik={this.handleAddZalacznik}
                    changeRemoveZalacznik={this.handleRemoveZalacznik}
                    changeSaveZalacznikAttributes={this.handleSaveZalacznikAttributes}
                    onAddWariant={this.handleAddWariant}
                    onChangeWariantAttribute={this.handleChangeWariantAttribute}
                    onRemoveWariant={this.handleRemoveWariant}
                />
                <GridCustom flexEnd fullwidth flexM flexTop>
                    <GridCustom>
                        <ButtonStyled
                            cancel
                            disabled={formLocked}
                            onClick={(e) => {
                                this.setState({cancellingOn: true})
                                e.currentTarget.blur();
                            }}>
                            Anuluj {isNew ? 'tworzenie' : 'edycję'} oferty 
                        </ButtonStyled>
                        {!isNew && (
                            <ButtonStyled
                                disabled={formLocked}
                                remove
                                onClick={() => this.setState({removingOn: true, fetchRemoveError: ''})}>
                                Usuń ofertę
                            </ButtonStyled>
                        )}
                        {statusOferty !== null && ['DRAFT', 'WITHDRAWN'].includes(statusOferty.label) && (
                            <>
                                <ButtonStyled
                                    disabled={formLocked}
                                    lite
                                    onClick={this.handleSave}>
                                    {this.state.saveBtnText}
                                </ButtonStyled>
                                {this.state.saveBtnText === 'Zapisano' && <span role="alert" className="sr-only">Zapisano!</span>}
                            </>
                        )}
                    </GridCustom>
                    <ButtonStyled
                        className="btn btn--main"
                        disabled={formLocked || !Object.keys(this.state.danePodmiotu).length}
                        save
                        onClick={() => this.setState(
                            {submittingDialogOn: true, removingOn: false, cancellingOn: false})}>
                        Złóż ofertę
                    </ButtonStyled>
                </GridCustom>
                {fetchRemoveError.length > 0 && <Text error>{fetchRemoveError}</Text>}
                {fetchSaveError.length > 0 && <Text error>{fetchSaveError}</Text>}
                {ogloszenieErrors.length > 0 && <ul>{ogloszenieErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                {formOfertaErrors && <span className="sr-only" role="alert">Formularz zawiera błędy!</span>}

                {!formLocked && this.state.cancellingOn && this.renderCancelDialog()}
                {!formLocked && this.state.removingOn && this.renderRemoveDialog()}
                {!formLocked && this.state.submittingDialogOn && this.renderSubmitDialog()}
            </>
        )
    }

    renderDanePodmiotu() {
        let content;
        if (!Object.keys(this.state.danePodmiotu).length) {
            content = (
                <div role="status" aria-live="polite">
                    <Text error>
                        Nie zdefiniowano danych podmiotu dla użytkownika. Aby je ustawić, <Link to="/uzytkownicy/moj-profil" className="link-text">przejdź do swojego profilu</Link>.
                    </Text>
                    <Text error>
                        Oferta nie będzie mogła zostać złożona. W dalszym ciągu będzie można ją zapisać.
                    </Text>
                </div>
            );
        } else {
            content = (
                <>
                    <DanePodmiotu danePodmiotu={this.state.danePodmiotu} />
                    <Text info>
                        Te dane podmiotu zostaną dołączone do oferty. Aby je zmienić, <Link to="/uzytkownicy/moj-profil" className="link-text">przejdź do swojego profilu</Link> i edytuj dane podmiotu.
                    </Text>
                </>
            );
        }
        return (
            <Container>
                <GridCustom flexEnd fullwidth>
                    <Text tag="h2" mainHeader>Dane podmiotu</Text>
                </GridCustom>
                {content}
            </Container>
        )
    }

    renderCancelDialog() {
        let confirmButton;
        if (this.state.isNew) {
            confirmButton = <ButtonStyled save tag={Link} onClick={() => window.location.reload()} to={`/ogloszenia/${this.props.ogloszenieId}`}>Tak</ButtonStyled>;
        } else {
            confirmButton = <ButtonStyled save onClick={() => window.location.reload()}>Tak</ButtonStyled>;
        }

        return (
            <DialogCustom
                dialogTitle={`Czy na pewno chcesz anulować ${this.state.isNew ? 'tworzenie' : 'edycję'} oferty? Wszystkie niezapisane dane zostaną utracone.`}
                onClose={this.handleCloseCancellingDialog}
            >
                <GridCustom flexEnd>
                    <ButtonStyled onClick={this.handleCloseCancellingDialog} cancel>Nie</ButtonStyled>
                    {confirmButton}
                </GridCustom>
            </DialogCustom>
        )
    }

    renderBaseDialog(onClose, onConfirm, title) {
        return (
            <DialogCustom
                dialogTitle={title}
                onClose={onClose}
            >
                <GridCustom flexEnd>
                    <ButtonStyled cancel onClick={onClose}>Nie</ButtonStyled>
                    <ButtonStyled save onClick={onConfirm}>Tak</ButtonStyled>
                </GridCustom>
            </DialogCustom>
        )
    }

    renderRemoveDialog() {
        return this.renderBaseDialog(
            this.handleCloseRemovingDialog, this.handleRemove,
            'Czy na pewno chcesz usunąć tę ofertę?'
        )
    }

    renderSubmitDialog() {
        return this.renderBaseDialog(
            this.handleCloseSubmittingDialog, this.handleSubmit,
            'Czy na pewno chcesz złożyć tę ofertę?'
        )
    }
}


class FormOfertaOgloszeniodawca extends FormOferta {

    // handlers

    handleChangeRodzajAdresu = (value) => {
        this.setState(prevState => {
            if (value.value === prevState.danePodmiotu.rodzajAdresu.value) {
                return {}
            }
            let dct = {
                numerIdentyfikacyjny: '',
                rodzajAdresu: value,
            };
            let adres = {
                kraj: null,
                wojewodztwo: '',
                powiat: '',
                gmina: '',
                miejscowosc: '',
            };
            if (value.value === 'poland') {
                adres.kraj = 'Polska';
                dct['typNumeruIdentyfikacyjnego'] = TYP_NUMERU_IDENTYFIKACYJNEGO[0];
            } else {
                // set abroad
                dct['typNumeruIdentyfikacyjnego'] = TYP_NUMERU_IDENTYFIKACYJNEGO[2];
            }
            return {danePodmiotu: Object.assign({}, prevState.danePodmiotu, {
                ...dct,
                adres: Object.assign({}, prevState.danePodmiotu.adres, adres),
            })}
        });
    }

    handleChangeAttribute = (name, value, isAdresPart) => {
        this.setState(prevState => {
            let dict = {[name]: value};
            if (name === 'typNumeruIdentyfikacyjnego' && prevState.danePodmiotu['typNumeruIdentyfikacyjnego'].value !== value.value) {
                dict['numerIdentyfikacyjny'] = '';
            }
            if (isAdresPart) {
                return {danePodmiotu: Object.assign({}, prevState.danePodmiotu, {
                    adres: Object.assign({}, prevState.danePodmiotu.adres, dict),
                })}
            } else {
                return {danePodmiotu: Object.assign({}, prevState.danePodmiotu, dict)}
            }
        });
    }

    handleChangeMiejscowosc = (value) => {
        this.setState(prevState => {
            return {danePodmiotu: Object.assign({}, prevState.danePodmiotu, {
                adres: Object.assign({}, prevState.danePodmiotu.adres, value)
            })}
        });
    }

    handleChangeNumerIdentyfikacyjny = (value, onlyDigits=false) => {
        if (onlyDigits) {
            value = value.replace(/[^\d]/, '');
        }
        this.setState(prevState => {
            return {danePodmiotu: Object.assign({}, prevState.danePodmiotu, {numerIdentyfikacyjny: value})}
        });
    }

    handleSave = () => {}

    handleShowSaveErrors = (errors) => {
        this.xhrFetchSave = null;
        this.setState(
            prevState => ({
                savingOn: false,
                saveBtnText: this.saveBtnDefaultText,
                errors: getSerializedObjectErrorsFormServer(
                    errors,
                    this.getDataForSubmitting(),
                    ['oferty', 'warianty', 'zalaczniki']
                ),
            })
        );
    }

    handleFetchSubmitSuccess = (data) => {
        this.props.onSubmitForm(data, this.state.isNew);
    }

    // helpers

    fetchInitialData = () => {
        if (this.state.isNew) {
            let data = {initializingOn: false};
            if (this.props.zamowienia.length === 1) {
                data['oferty'] = [this.getEmptyOferta(
                    1, this.props.zamowienia[0].zamowienieNode.id), ];
            }
            this.setState(data);
        } else {
            this.xhrFetch = fetchApi(
                `/api/offerset/${this.props.ofertaId}`,
                'GET',
                {},
                {},
                this.handleFetchOfferDataSuccess,
                this.handleFetchError,
                this.handleFetchIncorrectStatus,
            );
        }
    }

    getAdditionalState(props) {
        return Object.assign(
            super.getAdditionalState(props),
            {danePodmiotu: Object.assign({}, EMPTY_DANE_PODMIOTU_DATA)}
        );
    }

    formPreState(props) {
        this.saveBtnDefaultText = 'Zapisz ofertę';
    }

    getSubmitUrl() {
        if (this.state.isNew) {
            return `/api/offerset/announcement/${this.props.ogloszenieId}/submit`
        }
        return `/api/offerset/${this.props.ofertaId}/submit`
    }

    getDataForSubmitting() {
        const danePodmiotu = this.state.danePodmiotu;
        const adres = danePodmiotu.adres;
        return {
            ogloszenie: this.props.ogloszenieId,
            danePodmiotu: {
                nazwa: danePodmiotu.nazwa,
                typNumeruIdentyfikacyjnego: danePodmiotu.typNumeruIdentyfikacyjnego.value,
                numerIdentyfikacyjny: danePodmiotu.numerIdentyfikacyjny,
                adres: {
                    kraj: adres.kraj,
                    wojewodztwo: adres.wojewodztwo,
                    powiat: adres.powiat,
                    gmina: adres.gmina,
                    miejscowosc: adres.miejscowosc,
                    kodPocztowy: adres.kodPocztowy,
                    ulica: adres.ulica,
                    numerDomu: adres.numerDomu,
                },
            },
            oferty: this.state.oferty,
            zalaczniki: this.state.zalaczniki.map(z => {
                return {id: z.id, temporaryId: z.temporaryId, nazwa: z.nazwa, plik: z.plik.id}
            }),
        }
    }

    // rendering

    renderHeader() {
        return null
    }

    renderContent() {
        const { fetchSaveError, isNew } = this.state;
        const ogloszenieErrors = (this.state.errors || {}).ogloszenie || [];
        const formLocked = this.state.savingOn;
        return (
            <>
                <Text tag="h1" mainHeader>{isNew ? 'Dodaj' : 'Edytuj'} ofertę</Text>
                <FormOfertaFields
                    danePodmiotu={this.state.danePodmiotu}
                    errors={this.state.errors}
                    formLocked={formLocked}
                    oferty={this.state.oferty}
                    withDanePodmiotuForm={true}
                    zalaczniki={this.state.zalaczniki}
                    zamowienia={this.props.zamowienia}
                    changeAttribute={this.handleChangeAttribute}
                    changeMiejscowosc={this.handleChangeMiejscowosc}
                    changeNumerIdentyfikacyjny={this.handleChangeNumerIdentyfikacyjny}
                    changeRodzajAdresu={this.handleChangeRodzajAdresu}
                    changeAddOferta={this.handleChangeAddOferta}
                    changeCzyWariantowa={this.handleChangeCzyWariantowa}
                    changeAddZalacznik={this.handleAddZalacznik}
                    changeRemoveZalacznik={this.handleRemoveZalacznik}
                    changeSaveZalacznikAttributes={this.handleSaveZalacznikAttributes}
                    onAddWariant={this.handleAddWariant}
                    onChangeWariantAttribute={this.handleChangeWariantAttribute}
                    onRemoveWariant={this.handleRemoveWariant}
                />
                <GridCustom flexEnd>
                    <ButtonStyled
                        cancel
                        disabled={formLocked}
                        onClick={this.props.onCloseForm}>
                        Anuluj {isNew ? 'tworzenie' : 'edycję'} oferty
                    </ButtonStyled>
                    <ButtonStyled
                        disabled={formLocked}
                        primary
                        onClick={this.handleSubmit}
                    >{this.state.saveBtnText}</ButtonStyled>
                </GridCustom>
                {fetchSaveError.length > 0 && <Text error role="alert">{fetchSaveError}</Text>}
                {ogloszenieErrors.length > 0 && <ul role="alert">{ogloszenieErrors.map((e, i) => <Text error key={i} tag="li">{e}</Text>)}</ul>}
                {this.state.cancellingOn && this.renderCancelDialog()}
            </>
        )
    }
}


export { FormOferta, FormOfertaOgloszeniodawca };
