import React, { Component } from 'react';

import { AdvertisementContext } from '../../../context/advertisementContext';
import {
    ButtonStyled,
    FieldWithLabel,
    GridCustom,
    Select,
    Text,
    TextFieldCustom
} from '../../common';
import { customSelectStyles } from '../../vars/vars';
import { FormKraj, FormMiejscowosc } from '../common';


const POLAND = 'Polska';
const EMPTY_ADDRESS = {
    kraj: POLAND,
    wojewodztwo: null,
    powiat: null,
    gmina: null,
    miejscowosc: '',
}


class FormMiejsceRealizacji extends Component {

    constructor(props) {
        super(props);
        this.state = Object.assign({
            typ: null,
            adresErrors: {},
        }, EMPTY_ADDRESS);

        this.handleChangeMiejscowosc = this.handleChangeMiejscowosc.bind(this);
        this.handleChangeTyp = this.handleChangeTyp.bind(this);
        this.handleSaveMiejsceRealizacji = this.handleSaveMiejsceRealizacji.bind(this);
    }

    // basic functions

    componentDidMount() {
        this.setState({typ: this.context.adresId});
    }

    // handlers

    handleChangeMiejscowosc(value) {
        this.setState(prevState =>
            ({
                adresErrors: Object.assign({}, prevState.adresErrors, {miejscowosc: ''}),
                kraj: POLAND,
                ...value
            })
        );
    }

    handleChangeZagranicaAttribute(value, field) {
        this.setState(prevState =>
            ({
                adresErrors: Object.assign({}, prevState.adresErrors, {[field]: ''}),
                [field]: value,
            })
        );
    }

    handleChangeTyp(selectedOptions) {
        const typ = parseInt(selectedOptions.value);
        if (typ === this.context.adresId) {
            this.setState({
                typ: typ,
                ...EMPTY_ADDRESS,
                adresErrors: {},
            });
        } else if (typ === this.context.zagranicaId) {
            this.setState({
                typ: typ,
                kraj: null,
                wojewodztwo: null,
                powiat: null,
                gmina: null,
                miejscowosc: '',
                adresErrors: {},
            });
        } else {
            this.setState({
                typ: typ,
                kraj: POLAND,
                wojewodztwo: null,
                powiat: null,
                gmina: null,
                miejscowosc: null,
                adresErrors: {},
            });
        }
    }

    handleSaveMiejsceRealizacji() {
        if (!this.validate()) { return }
        this.props.saveMiejsceRealizacji({
            id: null,
            temporaryId: this.props.temporaryId,
            typ: this.state.typ,
            kraj: this.state.kraj,
            wojewodztwo: this.state.wojewodztwo,
            powiat: this.state.powiat,
            gmina: this.state.gmina,
            miejscowosc: this.state.miejscowosc,
        });
    }

    // helpers

    isSaveButtonDisabled() {
        // can't save miejsce realizacji if it's adres and not wojewodztwo set
        // or it's zagranica and not kraj and miejscowosc set
        const {
            kraj,
            miejscowosc,
            typ,
        } = this.state;
        return (
            (typ === this.context.adresId && (miejscowosc || '').length === 0) ||
            (typ === this.context.zagranicaId && !((kraj || '').length && (miejscowosc || '').length))
        )
    }

    sortMiejscowosc(miejscowosc1, miejscowosc2) {
        return miejscowosc1.miejscowosc.localeCompare(miejscowosc2.miejscowosc, 'pl')
    }

    validate() {
        // clean errors
        this.setState({adresErrors: {}});
        const {
            kraj,
            miejscowosc,
            typ,
        } = this.state;
        if (typ === this.context.adresId && !miejscowosc.length) {
            this.setState({adresErrors: {miejscowosc: 'To pole jest wymagane.'}})
            return false
        }
        if (typ === this.context.zagranicaId && (!(kraj || '').length || !miejscowosc.length)) {
            let errors = {};
            for (let name of ['kraj', 'miejscowosc']) {
                if (!(this.state[name] || '').length) {
                    errors[name] = 'To pole jest wymagane.'
                }
            }
            this.setState({adresErrors: errors});
            return false
        }
        return true
    }

    // rendering

    render() {
        const isSaveButtonDisabled = this.isSaveButtonDisabled();
        const {
            adresId,
            typMiejscaRealizacji,
            zagranicaId,
        } = this.context;
        const typ = this.state.typ;

        return (
            <FieldWithLabel label="Rodzaj adresu" tag="label" selectRef={React.createRef()}>
                <Select
                    className="select-custom"
                    indicatorId="mr_rodzajAdresu_id"
                    isSearchable={false}
                    noOptionsMessage={() => 'Brak wybranej opcji'}
                    options={Object.entries(typMiejscaRealizacji).map(([key, value]) => {
                        return {value: key, label: value}
                    })}
                    screenReaderStatus={() => { return 'Wybierz opcję z listy rozwijanej' }}
                    styles={customSelectStyles}
                    value={{
                        value: this.state.typ,
                        label: typMiejscaRealizacji[this.state.typ]
                    }}
                    onChange={this.handleChangeTyp}
                />
                <GridCustom flexS noWrap fullwidth>
                    {adresId === typ && this.renderAdresForm()}
                    {zagranicaId === typ && this.renderZagranicaForm()}
                </GridCustom>
                <GridCustom flexEnd>
                    <ButtonStyled onClick={this.props.cancelNewMiejsceRealizacji} cancel>Anuluj<span className="sr-only"> dodawanie miejsca realizacji</span></ButtonStyled>
                    <ButtonStyled onClick={this.handleSaveMiejsceRealizacji} add={!isSaveButtonDisabled} disabled={isSaveButtonDisabled}>Dodaj<span className="sr-only"> miejsce realizacji.{isSaveButtonDisabled && 'Guzik nieaktywny, musisz wybrać miejsce realizacji, aby je dodać.'}</span></ButtonStyled>
                </GridCustom>
            </FieldWithLabel>
        )
    }

    renderAdresForm() {
        const adresErrors = this.state.adresErrors;
        const isSelectInvalid = adresErrors.miejscowosc && adresErrors.miejscowosc.length > 0;
        return (
            <FormMiejscowosc
                error={isSelectInvalid ? adresErrors.miejscowosc : ''}
                invalid={isSelectInvalid}
                changeMiejscowosc={this.handleChangeMiejscowosc} />
        )
    }

    renderZagranicaForm() {
        const {
            adresErrors,
            kraj,
            miejscowosc,
        } = this.state;
        const isSelectInvalid = adresErrors.kraj && adresErrors.kraj.length > 0;
        return (
            <>
                <FormKraj
                    error={isSelectInvalid ? adresErrors.kraj : ''}
                    invalid={isSelectInvalid}
                    kraj={kraj}
                    changeKraj={(selectedOption) => this.handleChangeZagranicaAttribute(selectedOption.value, 'kraj')} />

                <FieldWithLabel label="Miejscowość" tag="label" labelFor="miejscowosc_id">
                    <TextFieldCustom
                        aria-describedby={adresErrors.miejscowosc && adresErrors.miejscowosc.length > 0 ? 'miejscowosc_error' : null}
                        clearFieldContext="miejscowość"
                        value={miejscowosc || ''}
                        onChange={(event) => this.handleChangeZagranicaAttribute(event.target.value, 'miejscowosc')}
                        onClear={() => this.handleChangeZagranicaAttribute('', 'miejscowosc')}
                        aria-required="true"
                        id="miejscowosc_id" />
                    {adresErrors.miejscowosc && adresErrors.miejscowosc.length > 0 && <Text error id="miejscowosc_error">{adresErrors.miejscowosc}</Text>}
                </FieldWithLabel>
            </>
        )
    }
}

FormMiejsceRealizacji.contextType = AdvertisementContext;

export { FormMiejsceRealizacji };
