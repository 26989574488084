import React from 'react';
import { Link } from 'react-router-dom';
import { Text as PdfText, StyleSheet } from '@react-pdf/renderer';
import { Icon } from '@rmwc/icon';

import { EKRANY_DICT, OGOLNA } from '../../../const';
import { UserContext } from '../../../context/user-context';
import { generatePdfDocument, serializeAuditData } from '../../../helpers';
import { getSerializedObject } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import {
    AuditData,
    Breadcrumps,
    ButtonCreatePdf,
    ButtonStyled,
    Container,
    FieldWithLabel,
    GridCustom,
    Text
} from '../../common';
import { BaseComponent } from '../common';
import { DraftToComponents } from './DraftToComponents';
import { PdfWithDraftContent } from './PdfWithDraftContent';


class Pomoc extends BaseComponent {

    // basic functions

    componentDidMount() {
        super.componentDidMount();

        window.onpopstate = () => {
            this.handleReload();
        }
    }

    componentWillUnmount() {
        window.onpopstate = () => {};
    }

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const pomoc = getSerializedObject(
            data,
            {
                nonRecursiveKeys: ['tresc', ],
                dateAttributes: ['dataOpublikowania', ],
                dateTimeAttributes: ['dataOpublikowania', ],
            }
        ).pomoc;
        this.setState(
            {
                initializingOn: false,
                pomoc: Object.assign(pomoc, {typOgolna: pomoc.typ === OGOLNA}),
                auditData: serializeAuditData(pomoc),
            },
            () => this.setUrlWithCorrectSlug(pomoc)
        );
    }

    handleReload() {
        this.setState(
            {initializingOn: true},
            () => {this.fetchInitialData()}
        );
    }

    handleChangePomoc(newId, newSlug) {
        this.props.history.push(`/zarzadzanie-trescia/pomoc/${newId}-${newSlug}`);
        this.handleReload();
    }

    handleCreatePdf = () => {
        const pomoc = this.state.pomoc;
        this.setState(
            {pdfCreatingOn: true},
            () => {
                let pdfHeaderText;
                let ekranLabel = '---';
                if (pomoc.typOgolna) {
                    pdfHeaderText = `Rozdział nadrzędny: ${!pomoc.rozdzialNadrzedny ? '---' : pomoc.rozdzialNadrzedny.naglowek}`;
                } else {
                    if (pomoc.ekran) {
                        ekranLabel = EKRANY_DICT[pomoc.ekran].label || ekranLabel;
                    }
                    pdfHeaderText = `Ekran: ${ekranLabel}`;
                }
                generatePdfDocument(
                    <PdfWithDraftContent
                        content={pomoc.tresc}
                        renderHeader={() => (
                            <>
                                <PdfText style={[styles.pdfHeaderStyle, styles.pdfHeaderSecondaryStyle]}>
                                    {pdfHeaderText}
                                </PdfText>
                                <PdfText style={styles.pdfHeaderStyle}>Tytuł: {pomoc.naglowek}</PdfText>
                            </>
                        )}
                    />,
                    'pomoc.pdf',
                    this.callbackPfdCreated
                );
            }
        );
    }

    // helpers

    getAdditionalState(props) {
        return {
            pomoc: {},
            pdfCreatingOn: false,
            auditData: {},
        }
    }

    getFetchUrl() {
        return API_URLS.helpAdminDetails.getUrl(this.props.match.params.id)
    }

    getFetchError(message) {
        return `Nie udało się pobrać pomocy. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania pomocy wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    setUrlWithCorrectSlug(pomoc) {
        // update url if slugs are not equal
        const urlParams = this.props.match.params;
        if (pomoc.slug !== urlParams.slug) {
            this.props.history.push(`/zarzadzanie-trescia/pomoc/${urlParams.id}-${pomoc.slug}`);
        }
    }

    callbackPfdCreated = () => {
        this.setState({pdfCreatingOn: false});
    }

    callback403 = () => {
        this.context.removePermission('help');
    }

    // rendering

    renderHeader() {
        return (
            <Breadcrumps>
                <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/pomoc'>Pomoc</Link></li>
                <li className="breadcrumps__current">Szczegóły</li>
            </Breadcrumps>
        )
    }

    renderContent() {
        const pomoc = this.state.pomoc;
        const {
            id,
            naglowek,
            rozdzialNadrzedny,
            slug,
            typOgolna
        } = pomoc;

        let ekranLabel = '---';
        if (!pomoc.typOgolna && pomoc.ekran) {
            ekranLabel = EKRANY_DICT[pomoc.ekran].label || ekranLabel;
        }

        return (
            <>
                <GridCustom flexEnd>
                    <ButtonCreatePdf
                        pdfCreatingOn={this.state.pdfCreatingOn}
                        onClick={this.handleCreatePdf} />
                </GridCustom>
                <Text tag="h2" mainHeader>{naglowek}</Text>
                <Container className="details-preview separate-container">
                    <GridCustom flexEnd>
                        <ButtonStyled primary noMargin onClick={() => this.props.history.push(`/zarzadzanie-trescia/pomoc/${id}-${slug}/edytuj`)}>Edytuj pomoc</ButtonStyled>
                    </GridCustom>
                    <GridCustom flexL fullwidth noWrap>
                        <section className="details-preview__part details-preview__part--main">
                            <FieldWithLabel label="Typ pomocy">
                                <Text>{typOgolna ? 'ogólna' : 'ekranowa'}</Text>
                            </FieldWithLabel>
                            <FieldWithLabel label="Nagłówek">
                                <Text>{naglowek}</Text>
                            </FieldWithLabel>
                            <FieldWithLabel label="Treść">
                                <DraftToComponents content={pomoc.tresc} />
                            </FieldWithLabel>
                        </section>
                        <aside className="details-preview__part details-preview__part--aside">
                            <FieldWithLabel label="Data opublikowania">
                                <Text>{pomoc.dataOpublikowania}</Text>
                            </FieldWithLabel>
                            <FieldWithLabel label="Slug">
                                <Text>{slug}</Text>
                            </FieldWithLabel>
                            {typOgolna && (
                                <>
                                    <FieldWithLabel label="Rozdział nadrzędny">
                                        {!rozdzialNadrzedny ? <Text>---</Text> : (
                                            <button key={rozdzialNadrzedny.id} onClick={() => this.handleChangePomoc(rozdzialNadrzedny.id, rozdzialNadrzedny.slug)} className="link-text">
                                                <Icon icon="arrow_right" aria-hidden="true" className="help__help-item-icon" />{rozdzialNadrzedny.naglowek}
                                            </button>
                                        )}
                                    </FieldWithLabel>
                                    <FieldWithLabel label="Powiązane">
                                        {pomoc.powiazane.length > 0 ? pomoc.powiazane.map(p => (
                                            <button key={p.id} onClick={() => this.handleChangePomoc(p.id, p.slug)} className="link-text">
                                                <Icon icon="arrow_right" aria-hidden="true" className="help__help-item-icon" />{p.naglowek}
                                            </button>
                                        )) : <Text>---</Text>}
                                    </FieldWithLabel>
                                </>
                            )}
                            {!typOgolna && (
                                <FieldWithLabel label="Ekran">
                                    <Text>{ekranLabel}</Text>
                                </FieldWithLabel>
                            )}
                            <FieldWithLabel label="Id">
                                <Text>{id}</Text>
                            </FieldWithLabel>
                        </aside>
                    </GridCustom>
                </Container>
                <AuditData {...this.state.auditData} />
            </>
        )
    }
}

const styles = StyleSheet.create({
    pdfHeaderStyle: {
        lineHeight: 1.6,
        marginBottom: 5,
        fontSize: 16,
        fontWeight: 'bold'
    },
    pdfHeaderSecondaryStyle: {
        fontSize: 12,
    }
})

Pomoc.contextType = UserContext;

export { Pomoc };
