import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';

import { TYP_NUMERU_IDENTYFIKACYJNEGO, TOKEN_COOKIE_NAME } from './const';


const emptyValueList = [
    '',
    'Wybierz',
    '--- Wybierz ---',
    'Wybierz...',
    'Wpisz co najmniej 5 cyfr lub 3 litery...',
    'Wpisz co najmniej 3 litery...',
    'Zacznij wpisywać...'
];


const computeProgress = (additionalAttrFilledLen=0, additionalAttrLen=0) =>  {
    let filledInputsLen = 0;

    // text, number, date, textarea inputs
    // 'user-searching-input' if for user changing
    const inputs = document.querySelectorAll(
        'input[type=text]:not([disabled=""]):not([id="user-searching-input"]), input[type=number]:not([disabled=""]), input[type=date]:not([disabled=""]), input[type=file]:not([disabled=""]), textarea:not([disabled=""])');
    for (let $i of inputs) {
        if ($i.id.startsWith('react-select')) {
            if (!emptyValueList.includes($i.parentNode.parentNode.parentNode.firstChild.textContent)) {
                filledInputsLen++;
            }
        } else {
            if ($i.value) {
                filledInputsLen++;
            }
        }
    }

    // selects
    const selectInputs = document.querySelectorAll('select:not([multiple=""])');
    for (let i of selectInputs) {
        if (i.value !== '0') {
            filledInputsLen++;
        }
    }

    // multiselects
    const multiselectInputs = document.querySelectorAll('select[multiple=""]');
    for (let i of multiselectInputs) {
        if (i.selectedOptions.length) {
            filledInputsLen++;
        }
    }

    filledInputsLen += additionalAttrFilledLen;
    const inputsLen = inputs.length + selectInputs.length + multiselectInputs.length + additionalAttrLen;
    return inputsLen === 0 ? 1 : (filledInputsLen === 0 ?  0 : filledInputsLen / inputsLen);
}


const getLastTemporaryId = (attribute, subAttributeName) => {
    // get last subAttribute temporaryId (needed for adding subAttribute)
    let temporaryIds = [];
    for (let obj of attribute) {
        temporaryIds = [...temporaryIds, ...(obj[subAttributeName] || []).map((o) => parseInt(o.temporaryId))];
    }
    return temporaryIds.length > 0 ? Math.max(...temporaryIds) : 0
}


const getLastTemporaryIdFromList = (list) => {
    const temporaryIds = list.map((obj) => obj.temporaryId);
    return temporaryIds.length > 0 ? Math.max(...temporaryIds) : 0
}


const fillWithLeadingZero = (value, length=2) => {
    value = value.toString();
    if (value.length >= length) {
        return value
    }
    return `${'0'.repeat(length - value.length)}${value}`
};

const datetimeStringToISO = (date) => {
    if (!date) { return '' }
    return date.replace(/\s/g, 'T')
}

const dateToString = (date) => {
    if (!date) { return '' }
    return `${date.getFullYear()}-${fillWithLeadingZero(date.getMonth() + 1)}-${fillWithLeadingZero(date.getDate())}`
};

const dateToStringOldBK = (date) => {
    if (!date) { return '' }
    return `${fillWithLeadingZero(date.getDate())}.${fillWithLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`
};

const datetimeToString = (date) => {
    if (!date) { return '' }
    return `${date.getFullYear()}-${fillWithLeadingZero(date.getMonth() + 1)}-${fillWithLeadingZero(date.getDate())} ${fillWithLeadingZero(date.getHours())}:${fillWithLeadingZero(date.getMinutes())}`
};

const dateStringToYearMonth = (dateString) => {
    return (dateString || '').substring(0, 7)
};

const timeToString = (date) => {
    if (!date) { return '' }
    return `${fillWithLeadingZero(date.getHours())}:${fillWithLeadingZero(date.getMinutes())}:${fillWithLeadingZero(date.getSeconds())}`
};

const listOrEmpty = (value) => {
    return value || []
}

const stringOrEmpty = (value) => {
    return value || ''
}

const isNotEmpty = (value, fieldType='str') => {
    if (fieldType === 'list') {
        value = listOrEmpty(value);
    }
    value = stringOrEmpty(value);
    return value.length > 0
};

const setCustomSelectAttributes = (selectRef) => {
    const inputId = selectRef.current.querySelector('input').getAttribute('id');
    selectRef.current.querySelector('label').setAttribute('for', inputId);
};

const setCustomInputAttributes = (inputRef) => {
    const setAttr = (inputRef, querySelector) => {
        const button = inputRef.current.querySelector(querySelector);

        if (button) {
            button.setAttribute('aria-label', 'Wyczyść pole');
            button.setAttribute('type', 'button');
            button.style.right = '12px';
        }
    };

    setAttr(inputRef, 'button.react-datepicker__close-icon');
};

const setFocusOnElement = (elRef) => {
    if (elRef) {
        elRef.current.focus();
        elRef.current.setAttribute('tabIndex', '-1');
    }
}

const triggerBodyClick = () => {
    document.querySelector('body').click();
};

const getCookieValue = (name) => {
    for (let cookie of document.cookie.split(';')) {
        const [n, v] = cookie.trim().split('=');
        if (n === name) {
            return v
        }
    }
    return null
};

const getAuthTokenCookieValue = () => {
    let authTokenCookieValue = getCookieValue(TOKEN_COOKIE_NAME);
    if (authTokenCookieValue === 'null') {
        authTokenCookieValue = null;
    }
    if (authTokenCookieValue) {
        authTokenCookieValue = decodeURIComponent(authTokenCookieValue);
    }
    return authTokenCookieValue
};

const deleteCookie = (name, callback=null) => {
    document.cookie = `${name}=null;Max-Age=10;path=/`;
    if (callback) { callback() }
};

const refreshCookie = (name, maxAge, value=null) => {
    value = value || getCookieValue(name);
    if (value) {
        document.cookie = `${name}=${value};Max-Age=${maxAge};path=/`;
    }
};

const refreshTimerAfterRequest = (authTokenCookieValue, statusCode) => {
    if (!authTokenCookieValue || (statusCode > 400 && statusCode !== 403)) {
        if (statusCode === 401) {
            if (window.sessionComponent) {
                window.sessionComponent.showUnauthorizedMessage();
            } else {
                // user couldn't authenticate, remove cookie
                deleteCookie(TOKEN_COOKIE_NAME);
            }
        }
        return
    }
    if (window.sessionComponent) {
        window.sessionComponent.refreshTimerAfterRequest(authTokenCookieValue);
    }
};

const refreshTimerAfterSativaRequest = (authTokenCookieValue, statusCode) => {
    if (window.sessionComponent) {
        window.sessionComponent.handleRefreshSessionTime();
    }
};

const countWysiwygChars = (content) => {
    if (!content) { return 0 }
    let counter = 0;
    for (let block of content.blocks) {
        counter += block.text.length;
        counter++;
    }
    // remove enter after last block
    counter--;
    return counter
};

const extractYoutubeSrc = (url) => {
    /*
    examples:
        http://www.youtube.com/watch?v=KjgWWjkNbhU&feature=youtu.be
        http://www.youtube.com/watch?v=KjgWWjkNbhU
        http://youtu.be/KjgWWjkNbhU
    with timing
        https://youtu.be/KjgWWjkNbhU?t=9s
        https://www.youtube.com/watch?time_continue=9&v=KjgWWjkNbhU
    return
        http://www.youtube.com/embed/KjgWWjkNbhU?rel=0[&start=9 opcja]
    */

    const long_domain = 'www.youtube.com',
          short_domain = 'youtu.be';

    try {
        url = new URL(url);
    } catch {
        return ''
    }

    if (![long_domain, short_domain].includes(url.hostname)) { return ''}

    const params = new URLSearchParams(url.searchParams);
    let videoId;
    if (url.hostname === long_domain && url.pathname.startsWith('/watch')) {
        // Long url - http://www.youtube.com/watch?v=KjgWWjkNbhU
        videoId = params.get('v');
    } else {
        // Short url - http://youtu.be/KjgWWjkNbhU
        // Embed url - https://www.youtube.com/embed/KjgWWjkNbhU
        const splitedPath = url.pathname.split('/')
        videoId = splitedPath[splitedPath.length - 1]
    }

    if (!videoId) { return '' }

    const _boolToInt = (paramString) => {
        if (['1', 'true', 'True'].includes(paramString)) { return 1 }
        if (['0', 'false', 'False'].includes(paramString)) { return 0 }
        return null
    };

    const _timeInSeconds = (timeString) => {
        const regex = /^((?<days>\d+)d)?((?<hours>\d+)h)?((?<minutes>\d+)m)?((?<seconds>\d+)s?)?$/gi;

        const match = regex.exec(timeString || '');
        if (!match) { return null }

        const durationGroups = match.groups;
        let duration = 0;
        const timeDict = {
            days: 60 * 60 * 24,
            hours: 60 * 60,
            minutes: 60,
            seconds: 1,
        };

        for (let [key, multipleValue] of Object.entries(timeDict)) {
            if (durationGroups[key]) {
                duration += (parseInt(durationGroups[key]) || 0) * multipleValue;
            }
        }

        return duration || null
    };

    const _timeInInt = (timeString) => {
        // parseInt returns first digits from string, we don't want to do that
        const regex = /^\d+$/;
        if (!regex.exec(timeString)) { return null }
        return parseInt(timeString);
    };

    const allowedParams =[
        {
            name: 'rel',
            iframeParamName: 'rel',
            convertFunc: _boolToInt,
            default_: 0,
        },
        {
            name: 'loop',
            iframeParamName: 'loop',
            convertFunc: _boolToInt,
        },
        {
            name: 't',
            iframeParamName: 'start',
            convertFunc: _timeInSeconds,
        },
        {
            name: 'time_continue',
            iframeParamName: 'start',
            convertFunc: _timeInSeconds,
        },
        {
            name: 'start',
            iframeParamName: 'start',
            convertFunc: _timeInInt,
        },
        {
            name: 'end',
            iframeParamName: 'end',
            convertFunc: _timeInSeconds,
        },
    ];

    const iframeParams = {};
    for (let p of allowedParams) {
        const paramStr = params.get(p['name']);
        if (paramStr) {
            // convert param value string
            let value = p['convertFunc'](paramStr);
            if (value === null) {
                if (!Object.keys(p).includes('default_')) {
                    // param value is invalid and it hasn't got default
                    // skip this param
                    continue
                }
                // param value is invalid, get default
                value = p['default_'];
            }
            iframeParams[p['iframeParamName']] = value;
        } else if (Object.keys(p).includes('default_')) {
            iframeParams[p['iframeParamName']] = p['default_'];
        }
    }
    return `${url.protocol}//www.youtube.com/embed/${videoId}?${new URLSearchParams(iframeParams).toString()}`
};

const getRodzajAdresuDict = (kraj) => {
    if (kraj === 'Polska') {
        return {
            value: 'poland',
            label: 'Polska',
        }
    }
    return {
        value: 'abroad',
        label: 'Zagranica',
    }
}

const generatePdfDocument = async (documentComponent, fileName, creatingCallback) => {
    const blob = await pdf(documentComponent).toBlob();
    if (creatingCallback) {
        creatingCallback();
    }
    saveAs(blob, fileName);
};

const saveFileFromApi = async (url, fileName) => {
	const authTokenCookieValue = getAuthTokenCookieValue();
	
	let result = null;
	if(authTokenCookieValue){
	    result = await fetch(url, {	
	      headers: {'X-Auth-Token': authTokenCookieValue}
	    })
	}else {
		result = await fetch(url)
	}

	if(result != null){
    	const blob = await result.blob()
		saveAs(blob, fileName);
	}
 };

const getBreakIndex = (charsList, slicedLine) => {
    let splittingCharsIndexes = charsList.map(
        char => slicedLine.lastIndexOf(char));
    return Math.max(...splittingCharsIndexes)
}


const splitLineByChar = (text) => {
    const textLength = text.length;

    let lines = [];
    let line = '';
    let lineLength = 0;
    let entersInLine = 0;

    let i = 0;
    while (i < textLength) {
        let char = text[i];
        const isEnter = /\n/.test(char);
        if (isEnter) {
            entersInLine += 1;
        }
        line += char;
        i++;
        lineLength++;
        if (lineLength >= 1000 || entersInLine >= 40) {
            if (isEnter) {
                lines.push(line);
                line = '';
                lineLength = 0;
                entersInLine = 0;
            } else {
                // find first enter char before missing 4000th char for this line in missing text
                const missingText = text.substring(i, i + 4001 - lineLength);
                const enterIndex = missingText.indexOf('\n');
                if (enterIndex > -1) {
                    // add chars until enter
                    line += missingText.substring(0, enterIndex + 1);
                    lines.push(line);
                    line = '';
                    lineLength = 0;
                    entersInLine = 0;
                    i += enterIndex + 1;
                } else {
                    // check length of real missing text
                    const realMissingText = text.substring(i);
                    if (lineLength + realMissingText.length < 4000) {
                        line += realMissingText;
                        i += realMissingText.length;
                    } else {
                        // maybe there is some char from the list before 4000th char
                        const breakIndex = getBreakIndex([' ', ',', ';', '?', '!'], missingText);
                        if (breakIndex > -1) {
                            line += missingText.substring(0, breakIndex + 1)
                            lines.push(line);
                            line = '';
                            lineLength = 0;
                            entersInLine = 0;
                            i += breakIndex + 1;
                        } else {
                            // cut line util 4001st char
                            line += missingText;
                            lines.push(line);
                            line = '';
                            lineLength = 0;
                            entersInLine = 0;
                            i += missingText.length;
                        }
                    }
                }
            }
        }
    }
    lines.push(line)
    return lines
};


const roundStringTo = (value, n=2) => {
    if (!value.length) {
        return value
    }
    value = value.replace(',', '.');
    // cut to n decimal
    const splittedValue = value.split('.');

    let firstPart = splittedValue[0];
    // manage leading zeros
    if (!firstPart) {
        firstPart ='0';
    } else {
        while (true) {
            if (firstPart.length > 1 && firstPart[0] === '0') {
                firstPart = firstPart.slice(1);
            } else {
                break
            }
        }
    }
    return `${firstPart},${splittedValue.length > 1 ? splittedValue[1].slice(0, n).padEnd(n, '0') : '00'}`
};

const redirectToLoginView = (ovverrideCurrentPathname = true) => {
    if(ovverrideCurrentPathname) {
        sessionStorage.setItem(
            'currentPathname', window.location.pathname + window.location.search);
    }

    // "log in" user, go to backend
    window.location.href = '/api/authentication/login-redirect';
}

const slugify = (value) => {
    return value.toString().toLowerCase().trim()
            .replace(/\s+/g, '-')       // Replace spaces with '-'
            .replace(/ą/g, 'a')         // Replace Polish char
            .replace(/ć/g, 'c')         // Replace Polish char
            .replace(/ę/g, 'e')         // Replace Polish char
            .replace(/ł/g, 'ł')         // Replace Polish char
            .replace(/ń/g, 'n')         // Replace Polish char
            .replace(/ó/g, 'o')         // Replace Polish char
            .replace(/ś/g, 's')         // Replace Polish char
            .replace(/ź/g, 'z')         // Replace Polish char
            .replace(/ż/g, 'z')         // Replace Polish char
            .replace(/[^\w-]+/g, '')    // Remove all non-word chars
            .replace(/--+/g, '-')       // Replace multiple '-' with single '-'
};

const sortOrderItems = (orderItem1, orderItem2) => {
    return orderItem1.temporaryId > orderItem2.temporaryId ? 1 : -1
};

const getSortedOrderItemsFromOrder = (orderList) => {
    let orderItems = [];
    for (let order of orderList) {
        for (let orderItem of order.przedmiotyZamowienia) {
            orderItems.push(orderItem);
        }
    }
    orderItems.sort(sortOrderItems);
    return orderItems
};

const getOrderItemsLength = (orderList) => {
    let orderItems = [];
    for (let order of orderList) {
        for (let orderItem of order.przedmiotyZamowienia) {
            orderItems.push(orderItem);
        }
    }
    return orderItems.length
};

const reformatDanePodmiotu = (data) => {
    if (data) {
        return Object.assign(
            {}, data,
            {
                typNumeruIdentyfikacyjnego: TYP_NUMERU_IDENTYFIKACYJNEGO.filter(
                    t => t.value === data.typNumeruIdentyfikacyjnego)[0] || TYP_NUMERU_IDENTYFIKACYJNEGO[0],
                rodzajAdresu: getRodzajAdresuDict(data.adres.kraj),
            }
        )
    }
    return {}
};

const getPricesRange = (prices) => {
    let pricesDict = {};
    let maxPriceLength = Math.max(...prices.map(p => p.length));
    for (let price of prices) {
        pricesDict[price.padStart(maxPriceLength, '0')] = price;
    }
    const paddedPriced = Object.keys(pricesDict).sort();
    return {
        min: pricesDict[paddedPriced[0]],
        max: pricesDict[paddedPriced[paddedPriced.length - 1]]
    }
};

const isAdvertisementLocked = (statusDict) => {
    return ((statusDict || {}).label || '') === 'LOCKED'
};

// pagination
const checkHasPrev = (pageNumber) => {
    return pageNumber > 1
};

const checkHasNext = (paginatedBy, pageNumber, total) => {
    return Math.ceil(total / paginatedBy) > pageNumber
}

// implementation levels
const sortProgrammes = (programme1, programme2) => {
    return programme1.code.localeCompare(programme2.code, 'pl')
}

const sortImplementationLevelByNumberCode = (obj1, obj2) => {
    return obj1.code.localeCompare(obj2.code, 'pl')
};

const hasSubactivities = (perspectiveData) => {
    perspectiveData = perspectiveData || {};
    return (
        perspectiveData.activity_types || perspectiveData.activityTypes || []
    ).includes('subactivities')
};

const hasPerspectiveRegions = (perspectiveData) => {
    perspectiveData = perspectiveData || {};
    return (
        perspectiveData.activity_types || perspectiveData.activityTypes || []
    ).includes('regions')
};

const hasPerspectiveInstitutions = (perspectiveData) => {
    perspectiveData = perspectiveData || {};
    return (
        perspectiveData.activity_types || perspectiveData.activityTypes || []
    ).includes('institutions')
};

const getActivityUrlByPerspectiveData = (perspectiveData) => {
    return hasSubactivities(perspectiveData)
        ? 'managementSubactivitiesForPriority'
        : 'managementActivitiesForPriority'
};

const getActivityChildUrlByPerspectiveData = (perspectiveData) => {
    perspectiveData = perspectiveData || {};
    const activityTypes =
        perspectiveData.activity_types || perspectiveData.activityTypes || [];
    if (activityTypes.includes('regions')) {
        return 'managementRegionsForSubactivity'
    }
    if (activityTypes.includes('institutions')) {
        return 'managementInstitutionsForActivity'
    }
    return null
};

const serializePerspectiveForSelect = (data) => {
    return {
        perspectives: data.perspectives.map(p => ({
            value: p.id,
            code: p.name,
            label: p.name,
            activity_types: p.activity_types || p.activityTypes,
        })),
    }
}

const getUserFullName = (data) => {
    const forename = data.imie || data.forename || '';
    const surname = data.nazwisko || data.surname || '';
    return (forename.length || surname.length) ? `${forename} ${surname}` : null
}

const serializeAuditData = (data) => {
    return {
        createdAt:
            data.dataUtworzenia || data.createdAt || data.created_at || null,
        createdBy: getUserFullName(
            data.ktoUtworzyl || data.createdBy || data.created_by || {}),
        modifiedAt:
            data.dataModyfikacji || data.modifiedAt || data.modified_at || null,
        modifiedBy: getUserFullName(
            data.ktoModyfikowal || data.modifiedBy || data.modified_by || {}),
    }
}

const generateUUIDV4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }


export {
    checkHasNext,
    checkHasPrev,
    computeProgress,
    countWysiwygChars,
    dateToString,
    dateToStringOldBK,
    datetimeToString,
    datetimeStringToISO,
    dateStringToYearMonth,
    deleteCookie,
    extractYoutubeSrc,
    fillWithLeadingZero,
    generatePdfDocument,
	saveFileFromApi,
    getActivityChildUrlByPerspectiveData,
    getActivityUrlByPerspectiveData,
    getAuthTokenCookieValue,
    getCookieValue,
    getLastTemporaryId,
    getLastTemporaryIdFromList,
    getOrderItemsLength,
    getPricesRange,
    getRodzajAdresuDict,
    getSortedOrderItemsFromOrder,
    getUserFullName,
    hasPerspectiveInstitutions,
    hasPerspectiveRegions,
    hasSubactivities,
    isAdvertisementLocked,
    isNotEmpty,
    listOrEmpty,
    redirectToLoginView,
    reformatDanePodmiotu,
    refreshCookie,
    refreshTimerAfterRequest,
    refreshTimerAfterSativaRequest,
    roundStringTo,
    serializeAuditData,
    serializePerspectiveForSelect,
    setCustomInputAttributes,
    setCustomSelectAttributes,
    setFocusOnElement,
    slugify,
    sortImplementationLevelByNumberCode,
    sortProgrammes,
    splitLineByChar,
    timeToString,
    triggerBodyClick,
    generateUUIDV4,
};

if (typeof module === 'object' && module.exports) {
    module.exports = {
        extractYoutubeSrc,
        getPricesRange,
        getRodzajAdresuDict,
        roundStringTo,
        slugify,
    };
}
