import React from 'react';


const GridCellCustom = (props) => {
    const {
        className,
        children,
        fill,
        customRef,
        ...otherProps
    } = props;

    let gridCellClassName = 'grid-custom__cell ';
    className && (gridCellClassName += className);

    return (
        <div
            className={gridCellClassName}
            style={fill && {flexGrow: '1'}}
            ref={customRef}
            {...otherProps} >
            {children}
        </div>
    );
}

export { GridCellCustom };
