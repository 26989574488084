import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@rmwc/checkbox';

import { UserContext } from '../../../context/user-context';
import { fetchApi } from '../../../fetch';
import { countWysiwygChars, dateToString } from '../../../helpers';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import { API_URLS } from '../../../urls/api';
import { validateWysiwygEditor } from '../../../validators';
import {
    Breadcrumps,
    ButtonStyled,
    Container,
    DateCalendar,
    FieldWithLabel,
    GridCustom,
    Text,
    TextFieldCustom,
    WysiwygEditor,
} from '../../common';
import { BaseComponent } from '../common';


const EMPTY_KOMUNIKAT = {
    id: null,
    naglowek: '',
    tresc: '',
    czyZoltyPasek: false,
    czyAktywny: true,
    czyBezterminowy: true,
    dataOpublikowania: '',
    dataWaznosci: '',
    slug: '',
};
const SAVE_BUTTON_DEFAULT_LABEL = 'Zapisz komunikat';


class FormKomunikat extends BaseComponent {

    // base functions

    componentDidMount() {
        if (this.state.isNew) {
            this.setState({initializingOn: false});
        } else {
            // it is editing, get object from server
            this.fetchInitialData();
        }
    }

    // handlers

    handleFetchSuccess(data) {
        super.handleFetchSuccess(data);

        const komunikat = getSerializedObject(
            data,
            {
                nonRecursiveKeys: ['tresc'],
                dateAttributes: ['dataOpublikowania', 'dataWaznosci'],
                dateTimeAttributes: ['dataOpublikowania', 'dataWaznosci'],
            }
        ).komunikat;
        if (!komunikat) {
            this.setState({initializingOn: false, pageNotFound: true});
        } else {
            this.setState(
                {
                    initializingOn: false,
                    komunikat: Object.assign(komunikat, {
                        id: this.props.match.params.id,
                        czyBezterminowy: komunikat.dataWaznosci === '',
                    }),
                },
                () => {
                    const urlParams = this.props.match.params;
                    if (this.props.editing && komunikat.slug !== urlParams.slug) {
                        // update url if slugs are not equal
                        this.props.history.push(`/zarzadzanie-trescia/komunikaty/${urlParams.id}-${komunikat.slug}/edytuj`);
                    }
                }
            );
        }
    }

    handleChangeAttribute(name, value) {
        let newData = {[name]: value}
        if (name === 'czyAktywny' && !value) {
            newData['czyZoltyPasek'] = false;
        }
        this.setState(prevState => ({
            komunikat: Object.assign({}, prevState.komunikat, newData),
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleClearAttributeError(name) {
        this.setState(prevState => ({
            errors: Object.assign({}, prevState.errors, {[name]: ''}),
        }));
    }

    handleSave() {
        this.setState(
            {savingOn: true, fetchSaveError: '', saveBtnText: 'Trwa zapisywanie...'},
            () => {
                const [isValid, errors] = this.validate();
                if (isValid) {
                    const isNew = this.state.isNew;
                    // save object on server
                    this.xhrFetch = fetchApi(
                        isNew
                            ? API_URLS.statementsAdmin.path
                            : API_URLS.statementsAdminDetails.getUrl(
                                this.state.komunikat.id),
                        isNew ? 'POST' : 'PUT',
                        {},
                        getSerializedObject(
                            this.state.komunikat, {toServer: true, nonRecursiveKeys: ['tresc', ]}),
                        this.handleFetchSaveSuccess,
                        this.handleFetchSaveError,
                        this.handleFetchSaveIncorrectStatus,
                    );
                } else {
                    this.setState({savingOn: false, errors, saveBtnText: SAVE_BUTTON_DEFAULT_LABEL});
                }
            }
        );
    }

    handleFetchSaveSuccess(data) {
        this.xhrFetchSave = null;

        const komunikat = getSerializedObject(data).komunikat;
        this.setState(prevState => ({
                savingOn: false,
                saveBtnText: 'Zapisano',
                isNew: false,
                komunikat: Object.assign({}, prevState.komunikat, komunikat),
            }),
            () => {
                this.firstSaving = false;
                const urlParams = this.props.match.params;
                if (this.props.editing && komunikat.slug !== urlParams.slug) {
                    // update url if slugs are not equal
                    this.props.history.push(`/zarzadzanie-trescia/komunikaty/${urlParams.id}-${komunikat.slug}/edytuj`);
                }
                this.saveMsgTimeout = setTimeout(() => {
                    this.setState({saveBtnText: SAVE_BUTTON_DEFAULT_LABEL})
                }, 5000)
            }
        );
    }

    handleFetchSaveError(data) {
        this.xhrFetch = null;     // clean xhr object
        this.setState({
            savingOn: false,
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
            fetchSaveError: `Nie udało się zapisać komunikatu. ${translateErrorMessage(data.message)}`,
        });
    }

    handleFetchSaveIncorrectStatus(status) {
        this.xhrFetch = null;     // clean xhr object
        if (status === 404) {
            this.setState({savingOn: false, pageNotFound: true});
        } else {
            let message = '';
            if (status === 403) {
                message = `Nie masz uprawnień do ${this.state.isNew
                    ? 'dodawania' : 'edycji'} komunikatu.`;
                // update permission value in context
                this.removePermission();
            }
            this.setState({
                savingOn: false,
                saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
                fetchSaveError: message ||
                    `Podczas zapisu komunikatu wystąpił nieoczekiwany błąd o kodzie ${status}.`,
            });
        }
    }

    // helpers

    preState(props) {
        this.trescMaxLength = 2000;
    }

    getAdditionalState(props) {
        return {
            isNew: !props.editing,
            savingOn: false,
            komunikat: EMPTY_KOMUNIKAT,
            fetchSaveError: '',
            errors: {
                tresc: '',
            },
            saveBtnText: SAVE_BUTTON_DEFAULT_LABEL,
        }
    }

    setAdditionalHandlers() {
        this.handleSave = this.handleSave.bind(this);
        this.handleFetchSaveSuccess = this.handleFetchSaveSuccess.bind(this);
        this.handleFetchSaveError = this.handleFetchSaveError.bind(this);
        this.handleFetchSaveIncorrectStatus = this.handleFetchSaveIncorrectStatus.bind(this);
    }

    getFetchUrl() {
        return API_URLS.statementsAdminDetails.getUrl(
            this.props.match.params.id)
    }

    getFetchData() {
        return {context: 'admin_panel'}
    }

    getFetchError(message) {
        return `Nie udało się pobrać komunikatu do edycji. ${message}`
    }

    getFetchIncorrectStatusError(status) {
        return `Podczas pobierania komunikatu do edycji wystąpił nieoczekiwany błąd o kodzie ${status}.`
    }

    callback403 = () => {
        this.removePermission();
    }

    removePermission() {
        this.context.removePermission('statements');
    }

    validate() {
        let isValid = true;
        const requiredMsg = 'To pole jest wymagane.';
        let errors = {};

        const {
            czyBezterminowy,
            dataWaznosci,
            naglowek,
            tresc,
        } = this.state.komunikat;

        // validate naglowek
        if (!naglowek.length) {
            errors['naglowek'] = requiredMsg;
            isValid = false;
        }

        // validate tresc
        if (!validateWysiwygEditor(tresc)) {
            errors['tresc'] = requiredMsg;
            isValid = false;
        } else if (countWysiwygChars(tresc) > this.trescMaxLength) {
            errors['tresc'] = `To pole może mieć maksymalnie ${this.trescMaxLength} znaków.`;
            isValid = false;
        }

        // validate dataWaznosci
        if (!czyBezterminowy && !dataWaznosci.length) {
            errors['dataWaznosci'] = requiredMsg;
            isValid = false;
        }

        return [isValid, errors]
    }

    // rendering

    renderHeader() {
        const komunikat = this.state.komunikat;
        return (
            <Breadcrumps>
                <li className="breadcrumps__link"><Link to='/zarzadzanie-trescia/komunikaty'>Komunikaty</Link></li>
                {komunikat.id !== null ? (
                    <>
                        <li className="breadcrumps__link"><Link to={`/zarzadzanie-trescia/komunikaty/${komunikat.id}-${komunikat.slug}`}>Szczegóły komunikatu</Link></li>
                        <li className="breadcrumps__current">Edycja</li>
                    </>
                ) : (
                    <li className="breadcrumps__current">Dodaj komunikat</li>
                )}
            </Breadcrumps>
        )
    }

    renderContent() {
        const {
            errors,
            fetchSaveError,
            komunikat,
            saveBtnText,
            savingOn,
        } = this.state;

        const isTrescInvalid = (errors.tresc || '').length > 0;
        const isNaglowekInvalid = (errors.naglowek || '').length > 0;
        const isDataWaznosciValid = (errors.dataWaznosci || '').length > 0;

        const dataWaznosciInputRef = React.createRef();

        return (
            <>
                <Text tag="h2" mainHeader>Edycja komunikatu: {komunikat.naglowek}</Text>
                <Container>
                    <GridCustom fullwidth>
                        <FieldWithLabel
                            label="Nagłówek"
                            labelFor="naglowek_id"
                            tag="label"
                        >
                            <TextFieldCustom
                                aria-describedby={isNaglowekInvalid ? 'naglowek_error' : null}
                                characterCount
                                clearFieldContext="nagłówek"
                                disabled={savingOn}
                                id="naglowek_id"
                                invalid={isNaglowekInvalid}
                                fullwidth
                                maxLength={200}
                                value={komunikat.naglowek}
                                onClear={(ev) => this.handleChangeAttribute('naglowek', '')}
                                onChange={(ev) => this.handleChangeAttribute('naglowek', ev.target.value)}
                                onFocus={(ev) => this.handleClearAttributeError('naglowek')} />
                            {isNaglowekInvalid && <Text error id="naglowek_error">{errors.naglowek}</Text>}
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth>
                        <FieldWithLabel label="Treść" className="wysiwyg-wrapper">
                            <WysiwygEditor
                                aria-describedby={isTrescInvalid ? 'komunikat_error' : null}
                                disabled={savingOn}
                                invalid={isTrescInvalid}
                                initialContentState={komunikat.tresc}
                                localization={{ locale: 'pl' }}
                                maxLength={this.trescMaxLength}
                                toolbar={{options: []}}
                                toolbarHidden
                                onContentStateChange={(contentState) => this.handleChangeAttribute('tresc', contentState)}
                            />
                            {isTrescInvalid && <Text error id="komunikat_error">{errors.tresc}</Text>}
                        </FieldWithLabel>
                    </GridCustom>
                    <GridCustom fullwidth>
                        <Checkbox
                            checked={komunikat.czyAktywny}
                            disabled={savingOn}
                            label="Czy aktywny?"
                            onChange={(event) => this.handleChangeAttribute('czyAktywny', event.target.checked)}
                        />
                        <Checkbox
                            disabled={savingOn}
                            checked={komunikat.czyBezterminowy}
                            label="Czy bezterminowy?"
                            onChange={(event) => {
                                const checked = event.target.checked
                                this.handleChangeAttribute('czyBezterminowy', checked);
                                if (checked) {
                                    this.handleChangeAttribute('dataWaznosci', '');
                                }
                            }}
                        />
                        <Checkbox
                            checked={komunikat.czyZoltyPasek}
                            disabled={savingOn || !komunikat.czyAktywny}
                            label="Czy żółty pasek?"
                            onChange={(event) => this.handleChangeAttribute('czyZoltyPasek', event.target.checked)}
                        />
                    </GridCustom>
                    {!komunikat.czyAktywny && <GridCustom flexEnd><Text info>Komunikat nieaktywny nie może być żółtym paskiem</Text></GridCustom>}
                    {!komunikat.czyBezterminowy && (
                        <FieldWithLabel label="Data ważności" inputRef={dataWaznosciInputRef} className={savingOn && 'disabled'}>
                            <DateCalendar
                                ariaDescribedby={isDataWaznosciValid ? 'dataWaznosci_error' : null}
                                disabled={savingOn}
                                fullwidth
                                invalid={isDataWaznosciValid}
                                minDate={!komunikat.dataWaznosci ? new Date() : Date.parse(komunikat.dataWaznosci)}
                                parentRef={dataWaznosciInputRef}
                                value={Date.parse(komunikat.dataWaznosci)}
                                onChange={(date) => this.handleChangeAttribute('dataWaznosci', !date ? '' : dateToString(date))}
                            />
                            {isDataWaznosciValid && <Text error id="dataWaznosci_error">{errors.dataWaznosci}</Text>}
                        </FieldWithLabel>
                    )}
                    <GridCustom flexEnd className=" separate-container">
                        <ButtonStyled disabled={savingOn} save onClick={this.handleSave}>{saveBtnText}</ButtonStyled>
                    </GridCustom>
                    {fetchSaveError.length > 0 && <Text error role="alert">{fetchSaveError}</Text>}
                </Container>
            </>
        )
    }
}

FormKomunikat.contextType = UserContext;

export { FormKomunikat };
