import React, { Component } from 'react';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';
import { Draggable, Droppable } from 'react-simple-drag-n-drop';

import { AdvertisementContext } from '../../../context/advertisementContext';
import { listOrEmpty, roundStringTo } from '../../../helpers';
import {
    Box,
    ButtonStyled,
    Container,
    DialogCustom,
    Ellipsis,
    GridCustom,
    FieldWithLabel,
    Text,
    TextFieldCustom
} from '../../common';
import { FormKryteriumOceny } from './FormKryteriumOceny';


class Zamowienie extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newKryteriumOcenyOn: false,
            editingKryteriumOCenyId: null,
            isRemoving: false,
            szacunkowaWartosc: props.zamowienie.szacunkowaWartosc || '',
        };
    }

    // handlers

    handleMovePrzedmiotZamowieniaFromList(zamowienieTemporaryId, przedmiotZamowieniaTemporaryId) {
        this.props.saveDraggedTemporaryId(przedmiotZamowieniaTemporaryId).then(
            () => this.props.onMovePrzedmiotZamowienia(zamowienieTemporaryId));
    }

    handleCloseForm = () => {
        this.setState(
            {
                newKryteriumOcenyOn: false,
                editingKryteriumOCenyId: null,
            },
            () => {
                if (window.formOgloszenie) { window.formOgloszenie.computeProgress()}
            }
        );
    }

    handleSaveKryteriumOceny = (data, isNew) => {
        const z = this.props.zamowienie;
        if (isNew && !z.kryteriaOceny.length) {
            this.context.clearAttributeError('zamowienia', z.temporaryId, 'kryteriumOceny');
        }
        this.props.onSaveKryteriumOceny(data, isNew, this.handleCloseForm);
    }

    handleChangeSzacunkowaWartosc = (ev) => {
        let value = ev.target.value;
        this.setState(
            prevState => {
                if (value.indexOf(',') === -1 && value.length > 24) {
                    value = prevState.szacunkowaWartosc;
                }
                return {szacunkowaWartosc: value}
            },
            () => this.props.onSetZamowienieAttribute(
                this.props.zamowienie.temporaryId, 'szacunkowaWartosc', value)
        );
    }

    handleBlurSzacunkowaWartosc = (ev) => {
        let value = roundStringTo(ev.target.value);
        this.setState(
            {szacunkowaWartosc: value},
            () => this.props.onSetZamowienieAttribute(
                this.props.zamowienie.temporaryId, 'szacunkowaWartosc', value)
        );
    }

    handleSzacunkowaWartoscKeyPress = (ev) => {
        const szacunkowaWartosc = this.state.szacunkowaWartosc;
        const szacunkowaWartoscLenth = szacunkowaWartosc.length;
        const key = ev.key;
        const keyIndex = ev.target.selectionStart;
        if (!/[\d,]/.test(key)) {
            ev.preventDefault();
            return
        }
        if (key === ',') {
            if (szacunkowaWartosc.indexOf(',') > -1 || szacunkowaWartoscLenth === 0) {
                ev.preventDefault();
                return
            }
            if (keyIndex < szacunkowaWartoscLenth - 2) {
                ev.preventDefault();
                return
            }
        } else {
            // it's digit

            const comaIndex = szacunkowaWartosc.indexOf(',');

            if (comaIndex === -1 && szacunkowaWartoscLenth === 24) {
                ev.preventDefault();
                return
            }

            if (comaIndex === 24 && keyIndex <= 24) {
                ev.preventDefault();
                return
            }

            if (comaIndex > -1 && szacunkowaWartoscLenth === comaIndex + 3 && keyIndex > comaIndex) {
                ev.preventDefault();
            }
        }
    }

    handleSzacunkowaWartoscPaste = (ev) => {
        // stop data actually being pasted into input
        ev.preventDefault();
        // set pasted data via clipboard API
        const clipboardData = ev.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (/^\d{1,24}(,\d{2})?$/.test(pastedData)) {
            this.setState({szacunkowaWartosc: pastedData});
        }
    }

    handleCloseRemoveZamowienieDialog = () => {
        this.setState({isRemoving: false});
    }

    // rendering

    render() {
        // const from props
        const { isAlreadyPublished, formLocked, onSetZamowienieAttribute } = this.props;
        const { czyWariantowe, kryteriaOceny, przedmiotyZamowienia, temporaryId, tytul } = this.props.zamowienie;
        const kryteriaOceny_ = listOrEmpty(kryteriaOceny);
        const czyWariantowe_ = czyWariantowe || false;
        const tytul_ = tytul || '';

        // const from context
        const clearAttributeError = this.context.clearAttributeError;
        const errors = this.context.formErrors.zamowienia[temporaryId] || {};

        const isInvalidTytul = (errors.tytul || '').length > 0;
        const tytulId = `title_id_${temporaryId}`;

        const isSzacunkowaWartoscInvalid = (errors.szacunkowaWartosc || '').length > 0
        const szacunkowaWartoscId = `szacunkowaWartosc_id_${temporaryId}`;

        return (
            <Droppable onDropCallback={() => (isAlreadyPublished || formLocked) ? {} : this.props.onMovePrzedmiotZamowienia(temporaryId)}>
                <Container key={temporaryId}>
                    <GridCustom fullwidth>
                        <Text tag="h2" accentHeader>Część {this.props.index}</Text>
                        {this.renderRemoveZamowienie()}
                    </GridCustom>
                    <FieldWithLabel label="Tytuł" tag="label" labelFor={tytulId}>
                        <TextFieldCustom
                            aria-describedby={isInvalidTytul ? 'tytul_error' : null}
                            aria-label={`Tytuł, maksymalna liczba znaków: 60. Wpisano ${tytul_.length} znaków.`}
                            aria-required={true}
                            aria-valuemax={60}
                            clearFieldContext="tytuł"
                            characterCount
                            disabled={isAlreadyPublished || formLocked}
                            fullwidth
                            id={tytulId}
                            invalid={isInvalidTytul}
                            maxLength={60}
                            value={tytul_ || ''}
                            onChange={ev => onSetZamowienieAttribute(
                                temporaryId, 'tytul', ev.target.value
                            )}
                            onClear={ev => onSetZamowienieAttribute(
                                temporaryId, 'tytul', ''
                            )} />
                        {isInvalidTytul && <Text error id="tytul_error">{errors.tytul}</Text>}
                    </FieldWithLabel>
                    <Checkbox
                        className="checkbox checkbox--inside"
                        disabled={isAlreadyPublished || formLocked}
                        label="Czy dopuszczalne oferty wariantowe?"
                        checked={czyWariantowe_}
                        onChange={ev => onSetZamowienieAttribute(
                            temporaryId, 'czyWariantowe', ev.target.checked
                        )}
                    />

                    <Text tag="h2" sectionHeader>Przedmioty zamówienia</Text>
                    <Text info>Liczba wyników: {przedmiotyZamowienia.length}</Text>
                    <ul>
                        {przedmiotyZamowienia.map((pz) => this.renderPrzedmiotZamowienia(pz))}
                    </ul>
                    {(errors.przedmiotyZamowienia || '').length > 0 && <Text error>{errors.przedmiotyZamowienia}</Text>}

                    <FieldWithLabel label="Budżet" tag="label" labelFor={szacunkowaWartoscId}>
                        <TextFieldCustom
                            aria-describedby={`szacunkowaWartosc_descr${isSzacunkowaWartoscInvalid ? ' szacunkowaWartosc_error' : ''}`}
                            aria-valuemax={27}
                            clearFieldContext="budżet"
                            characterCount
                            disabled={formLocked}
                            fullwidth
                            id={szacunkowaWartoscId}
                            invalid={isSzacunkowaWartoscInvalid}
                            maxLength={27}
                            value={this.state.szacunkowaWartosc || ''}
                            onBlur={this.handleBlurSzacunkowaWartosc}
                            onFocus={(ev) => clearAttributeError('zamowienia', temporaryId, 'szacunkowaWartosc')}
                            onChange={this.handleChangeSzacunkowaWartosc}
                            onClear={(ev) => this.setState({szacunkowaWartosc: ''}, () => {
                                onSetZamowienieAttribute(temporaryId, 'szacunkowaWartosc', '')
                            })}
                            onKeyPress={this.handleSzacunkowaWartoscKeyPress}
                            onPaste={this.handleSzacunkowaWartoscPaste}
                        />
                        {isSzacunkowaWartoscInvalid && <Text error id="szacunkowaWartosc_error">{errors.szacunkowaWartosc}</Text>}
                        <Text info id="szacunkowaWartosc_descr">Wpisz wartość zgodnie ze wzorem: 1000000,00</Text>
                    </FieldWithLabel>

                    <FieldWithLabel label="Kryteria oceny">
                        <Text info>Liczba kryteriów oceny: {kryteriaOceny_.length}</Text>
                        <GridCustom tag="ul">
                            {kryteriaOceny_.map(ko => this.renderKryteriumOceny(ko))}
                        </GridCustom>
                        {(errors.kryteriaOceny || '').length > 0 && <Text error><span className="sr-only">Kryteria oceny - </span> {errors.kryteriaOceny}</Text>}
                    </FieldWithLabel>
                    {this.renderKryteriumOcenyForm()}
                    {this.renderNewKryteriumOcenyButton()}
                </Container>
            </Droppable>
        )
    }

    renderPrzedmiotZamowienia(pz) {
        const {isAlreadyPublished, formLocked} = this.props;
        let boxWithMenu;
        if (!isAlreadyPublished && this.props.zamowienieTemporaryIds.length > 1) {
            boxWithMenu = (
                <>
                    <h3 className="sr-only">Menu przenieś do innej części</h3>
                    <ul className="box__wide-corner-menu">
                        {this.props.zamowienieTemporaryIds.filter(
                                ([temporaryId, indexOnList]) => parseInt(temporaryId) !== parseInt(this.props.zamowienie.temporaryId)).map(
                                ([temporaryId, indexOnList]) => (
                            <li key={temporaryId}>
                                <ButtonStyled
                                    className="box__menu-item"
                                    disabled={formLocked}
                                    onClick={() => this.handleMovePrzedmiotZamowieniaFromList(temporaryId, pz.temporaryId)}>
                                    Przenieś do części {indexOnList}
                                </ButtonStyled>
                            </li>
                        ))}
                    </ul>
                </>
            );
        }

        return (
            <Draggable
                isDragAndDropElement
                key={pz.temporaryId}
                onDragCallback={() => (isAlreadyPublished || formLocked) ? {} : this.props.saveDraggedTemporaryId(pz.temporaryId)}
            >
                <Box
                    fullwidth
                    tag="li"
                    titleWithSubtitle={this.context.przedmiotZamowieniaKategoriaDict[pz.kategoria] || '-'}
                    subtitle={this.context.przedmiotZamowieniaPodkategoriaDict[pz.podkategoria] || '-'}
                    boxWithMenu={boxWithMenu}
                    ariaLabelMoreOptions="Przenieś do części"
                >
                    <FieldWithLabel label="Opis">
                        <Ellipsis>
                            <Text className="long-text">{pz.opis}</Text>
                        </Ellipsis>
                    </FieldWithLabel>
                </Box>
            </Draggable>
        )
    }

    renderRemoveZamowienie() {
        if (this.props.isAlreadyPublished) { return null }
        if (this.state.isRemoving) {
            if (this.props.zamowienie.przedmiotyZamowienia.length) {
                return (
                    <>
                        <Text error role="alert">To zamówienie ma zdefiniowane przedmioty zamówienia i nie może zostać usunięte.</Text>
                        <ButtonStyled onClick={() => this.setState({isRemoving: false})}primary>OK</ButtonStyled>
                    </>
                )
            }
            return (
                <DialogCustom 
                    className="dialog"
                    dialogTitle="Czy na pewno chcesz usunąć tę część zamówienia wraz ze wszystkimi danymi określonymi dla niej?"
                    onClose={this.handleCloseRemoveZamowienieDialog}
                >
                    <GridCustom centerVertical flexEnd>
                        <ButtonStyled onClick={this.handleCloseRemoveZamowienieDialog} cancel>Anuluj</ButtonStyled>
                        <ButtonStyled onClick={() => this.props.onRemoveZamowienie(this.props.zamowienie.temporaryId)} remove>Usuń</ButtonStyled>
                    </GridCustom>
                </DialogCustom>
            )
        }

        return (
            <>
                <ButtonStyled
                    disabled={this.props.formLocked || this.props.zamowienie.przedmiotyZamowienia.length > 0}
                    remove
                    onClick={() => this.setState({isRemoving: true})}>
                    Usuń część zamówienia
                </ButtonStyled>
            </>
        )
    }

    renderKryteriumOceny(kryteriumOceny) {
        const formLocked = this.props.formLocked;
        return (
            <Box key={kryteriumOceny.temporaryId} tag="li" >
                {Object.keys(this.context.formErrors.kryteriaOceny[kryteriumOceny.temporaryId] || {}).length > 0 && <Text error><Icon aria-hidden="true" icon={{ icon: 'warning', size: 'xsmall' }} style={{marginRight: 3}} /><span className="sr-only"Kryterium cenowe zawiera ></span> Błędy!</Text>}
                <FieldWithLabel label="Czy kryterium cenowe">
                    <Text>{kryteriumOceny.czyKryteriumCenowe ? 'Tak' : 'Nie'}</Text>
                </FieldWithLabel>
                <FieldWithLabel label="Opis">
                    <Text className="long-text">{kryteriumOceny.opis || '-'}</Text>
                </FieldWithLabel>
                {!this.props.isAlreadyPublished && (
                    <GridCustom flexEnd additionalClassName=" box__button-container">
                        <ButtonStyled
                            disabled={formLocked}
                            remove
                            onClick={() => this.props.onRemoveKryteriumOceny(
                                this.props.zamowienie.temporaryId, kryteriumOceny.temporaryId)
                            }>
                            Usuń
                        </ButtonStyled>
                        <ButtonStyled
                            disabled={formLocked}
                            lite
                            onClick={() => this.setState(
                                {editingKryteriumOCenyId: kryteriumOceny.temporaryId})
                            }>
                            Edytuj{Object.keys(this.context.formErrors.kryteriaOceny[kryteriumOceny.temporaryId] || {}).length > 0 && <span className="sr-only"> Uwaga! kryterium cenowe zawiera błędy.</span>}
                        </ButtonStyled>
                    </GridCustom>
                )}
            </Box>
        )
    }

    renderKryteriumOcenyForm() {
        if (this.props.isAlreadyPublished || (!this.state.newKryteriumOcenyOn &&
            this.state.editingKryteriumOCenyId === null)) { return null }

        const {
            editingKryteriumOCenyId,
            newKryteriumOcenyOn
        } = this.state;

        const z = this.props.zamowienie;

        let ko;
        if (newKryteriumOcenyOn) {
            ko = {temporaryId: this.props.kryteriumOcenyLastTemporaryId + 1};
        } else {
            ko = z.kryteriaOceny.filter(k => k.temporaryId === editingKryteriumOCenyId)[0];
        }

        return <FormKryteriumOceny
                    isNew={newKryteriumOcenyOn}
                    kryteriumOceny={ko}
                    zamowienieTemporaryId={z.temporaryId}
                    onCloseForm={this.handleCloseForm}
                    onSave={this.handleSaveKryteriumOceny} />
    }

    renderNewKryteriumOcenyButton() {
        if (this.props.isAlreadyPublished || this.state.newKryteriumOcenyOn) { return null }
        const { temporaryId } = this.props.zamowienie;
        const errors = this.context.formErrors.zamowienia[temporaryId] || {};
        return <ButtonStyled
                    add
                    disabled={this.props.formLocked}
                    onClick={() => this.setState({newKryteriumOcenyOn: true, editingKryteriumOCenyId: null})}>
                    Dodaj kryterium{(errors.kryteriaOceny || '').length > 0 && <span className="sr-only"> {errors.kryteriaOceny}</span>}
                </ButtonStyled>
    }
}


Zamowienie.contextType = AdvertisementContext;


export { Zamowienie };
