// Possible button variants:
// <ButtonStyled>No props</ButtonStyled> - blue text, no bg, no icon
// <ButtonStyled lite>lite</ButtonStyled> - blue text, blue border, no icon
// <ButtonStyled cancel>cancel</ButtonStyled> - gray text, gray border, no icon
// <ButtonStyled next>next</ButtonStyled> - white text, blue bg, arrow_forward icon
// <ButtonStyled prev>prev</ButtonStyled> - white text, gray bg, arrow_back icon
// <ButtonStyled save>save</ButtonStyled> - white text, green bg, done icon
// <ButtonStyled add>add</ButtonStyled> - white text, blue bg, add icon
// <ButtonStyled remove>remove</ButtonStyled> - red text, red border, no icon
// <ButtonStyled removeFull>removeFull</ButtonStyled> - white text, red bg, no icon
// <ButtonStyled noMargin>noMargin</ButtonStyled> - removes site margin, gives: margin: .5em 0


import React from 'react';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import '@material/button/dist/mdc.button.css';
import { GridCustom } from './GridCustom';

import { colors } from '../vars/vars';


const ButtonStyled = (props) => {
    const {
        add,
        cancel,
        children,
        className,
        disabled,
        icon,
        id,
        lite,
        next,
        noMargin,
        onClick,
        prev,
        primary,
        print,
        remove,
        removeFull,
        save,
        tag,
        to,
        ...otherProps
    } = props;

    const {
        buttonIconStyle,
        cancelBtnStyle,
        disabledBtnStyle,
        prevBgStyle,
        primaryBtnStyle,
        outlineBtnStyle,
        removeFullBgStyle
    } = styles;

    return (
            <Button
                className={[noMargin ? 'button--no-margin ' : '', className]}
                danger={remove || removeFull}
                disabled={disabled}
                id={id}
                outlined={lite || remove}
                ripple={false}
                style={disabled ? disabledBtnStyle : ( add || next || primary || print || save ? primaryBtnStyle : (prev ? prevBgStyle : (removeFull ? removeFullBgStyle : (remove || lite ? outlineBtnStyle: (cancel && cancelBtnStyle)))))}
                tag={tag}
                to={to}
                unelevated={cancel || next || save || add || removeFull || prev || primary || print}
                onClick={onClick}
                {...otherProps}
            >
                <GridCustom centerVertical centerHorizontal>
                    {(add || cancel || icon || next || prev || print || save) && (
                        <Icon
                            icon={
                                next ? 'arrow_forward' : (prev ? 'arrow_back' : (save ? 'done' : (add ? 'add' : (cancel ? 'clear' : (print ? 'print' : icon)))))
                            }
                            aria-hidden="true"
                            style={buttonIconStyle} />
                    )}
                    {children}
                </GridCustom>

            </Button>
    );
}

const styles = {
    cancelBtnStyle: {
        backgroundColor: colors.darkBg
    },
    disabledBtnStyle: {
        backgroundColor: colors.disabled,
        color: '#000',
        borderWidth: 0
    },
    primaryBtnStyle: {
        backgroundColor: colors.primary
    },
    prevBgStyle: {
        backgroundColor: colors.darkBg
    },
    removeFullBgStyle: {
        backgroundColor: colors.error
    },
    outlineBtnStyle: {
        borderWidth: 2,
        backgroundColor: '#fff',
    },
    buttonIconStyle: {
       marginRight: 10
   }
}

export { ButtonStyled };
