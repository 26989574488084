import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@rmwc/typography';
import '@material/list/dist/mdc.list.css';


const SkipLinks = (props) => {
    return (
        <nav aria-label="Nawigacja pomocnicza">
            <ul>
                {props.children.map((link) =>
                    <li key={link.id}>
                        <Typography
                            tag={Link}
                            to={link.linkTo}
                            className="skip-link"
                            tabIndex={link.id} >
                            {link.label}
                        </Typography>
                    </li>
                )}
            </ul>
        </nav>
    )
}


export { SkipLinks };
