import React, { Component } from 'react';

import { getUserFullName } from '../../../helpers';
import { fetchApi } from '../../../fetch';
import { getSerializedObject, translateErrorMessage } from '../../../serializers';
import {
    AuditData,
    Box,
    ButtonStyled,
    FieldWithLabel,
    Text
} from '../../common';
import { Ogloszenie } from './Ogloszenie';


class Projekt extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ogloszenia: [],
            detailsOn: false,
            initializingDetailsOn: false,
            removingOn: false,
            fetchError: '',
        }
        this.isProject = props.projekt.typ === 'project';

        this.xhrFetch = null;
    }

    // basic functions

    componentWillUnmount() {
        // abort api request if exists
        if (this.xhrFetch !== null) {
            this.xhrFetch.abort();
        }
    }

    // handlers

    handleShowOgloszenia = () => {
        this.setState(
            {
                detailsOn: true,
                initializingDetailsOn: true,
                fetchError: '',
            },
            this.getOgloszenia
        );
    }

    handleHideOgloszenia = () => {
        this.setState({
            detailsOn: false,
            initializingDetailsOn: false,
            fetchError: '',
        });
    }

    handleFetchOgloszeniaSuccess = (data) => {
        this.xhrFetch = null;
        this.setState({
            initializingDetailsOn: false,
            ogloszenia: getSerializedObject(data).ogloszenia,
        });
    }

    handleFetchOgloszeniaError = (data) => {
        this.xhrFetch = null;
        this.setState({
            initializingDetailsOn: false,
            fetchError: `Nie udało się pobrać danych ogłoszeń. ${translateErrorMessage(data.message)}`,
        });
    }

    handleFetchOgloszeniaIncorrectStatus = (status) => {
        this.xhrFetch = null;
        this.setState({
            initializingDetailsOn: false,
            fetchError: `Nie udało się pobrać danych ogłoszeń. Wystąpił nieoczekiwany błąd o kodzie ${status}.`,
        });
    }

    handleCloseRemovingOn = () => {
        this.setState({removingOn: false});
    }

    // helpers

    getOgloszenia() {
        // save ogloszenia from server
        this.xhrFetch = fetchApi(
            `/api/announcements/project_enrollment/${this.props.projekt.id}`,
            'GET',
            {},
            {},
            this.handleFetchOgloszeniaSuccess,
            this.handleFetchOgloszeniaError,
            this.handleFetchOgloszeniaIncorrectStatus,
        );
    }

    // rendering

    render() {
        const { projekt } = this.props;
        const { detailsOn } = this.state;
        const isProject = projekt.typ === 'project';

        return (
            <Box titleOneRow={projekt.calyNumer} tag="li" flexiblewidth>
                <FieldWithLabel label={isProject ? 'Projekt' : 'Nabór'}>
                    <Text>{projekt.nazwa || '-'}</Text>
                </FieldWithLabel>
                <div>
                    <ButtonStyled
                        primary
                        onClick={detailsOn ? this.handleHideOgloszenia : this.handleShowOgloszenia}
                    >
                        {detailsOn ? 'Ukryj ' : 'Pokaż '} szczegóły {isProject ? 'projektu ' : 'naboru '}
                        <span className="sr-only">{projekt.nazwa}</span>
                    </ButtonStyled>
                    {this.renderOgloszeniaDetails()}
                    {this.renderRemovingPart()}
                </div>
                <AuditData
                    className="box--half-width"
                    createdAt={projekt.dataUtworzenia}
                    createdBy={getUserFullName(projekt.ktoUtworzyl || {})}
                    flexiblewidth
                    modifiedAt={projekt.dataModyfikacji}
                    modifiedBy={getUserFullName(projekt.ktoModyfikowal || {})}
                />
            </Box>
        );
    }

    renderOgloszeniaDetails() {
        if (!this.state.detailsOn) { return null }
        if (this.state.initializingDetailsOn) {
            return <Text info>Trwa pobieranie danych ogłoszeń...</Text>
        }
        if (this.state.fetchError.length) {
            return <Text error role="alert">{this.state.fetchError}</Text>
        }

        const { ogloszenia } = this.state;
        const { projekt } = this.props;
        let ogloszenia_;
        if (ogloszenia.length) {
            ogloszenia_ = (
                <ul>
                    {ogloszenia.map(o => <li key={o.id}><Ogloszenie ogloszenie={o} /></li>)}
                </ul>
            );
        } else {
            ogloszenia_ = <Text error>Ten {this.isProject ? 'projekt' : 'nabór'} nie ma przypisanych ogłoszeń.</Text>;
        }
        return (
            <>
                <Text tag="h3" accentHeader>
                    Lista ogłoszeń
                    <span className="sr-only">
                        {` podpiętych do ${this.isProject ? 'projektu' : 'naboru'} ${projekt.nazwa}`}
                    </span>:
                </Text>
                {ogloszenia_}
            </>
        )
    }

    renderRemovingPart() {
        if (!this.state.removingOn) {
            return (
                <ButtonStyled
                    remove
                    onClick={() => this.setState({removingOn: true})}
                >
                    Usuń{this.isProject ? ' projekt ' : ' nabór '}<span className="sr-only"> {this.props.projekt.nazwa}</span>
                </ButtonStyled>
            )
        }

        if (this.props.projekt.maOpublikowaneOgloszenia) {
            return (
                <>
                    <Text error role="alert">Tego {this.isProject ? 'projektu' : 'naboru'} nie można usunąć, ponieważ ma przypisane ogłoszenia już opublikowane.</Text>
                    <ButtonStyled onClick={this.handleCloseRemovingOn} primary>OK</ButtonStyled>
                </>
            )
        }
        const { projekt } = this.props;
        let msg;
        if (projekt.maRoboczeOgloszenia) {
            msg = (
                <Text error role="alert">
                    Ten {this.isProject ? 'projekt' : 'nabór'} ma przypisane ogłoszenia o statusie "Robocze" i po usunięciu zostaną one od niego odpięte.
                </Text>
            );
        } else {
            msg = <Text info role="alert">Ten {this.isProject ? 'projekt' : 'nabór'} nie ma przypisanych ogłoszeń i może być bezpiecznie usunięty.</Text>;
        }
        return (
            <>
                {msg}
                <ButtonStyled
                    remove
                    onClick={() => this.props.onRemove(projekt.id, this.isProject)}
                >
                    Usuń{this.isProject ? ' projekt ' : ' nabór '}<span className="sr-only">{projekt.nazwa}</span>
                </ButtonStyled>
                <ButtonStyled onClick={this.handleCloseRemovingOn} cancel>Anuluj<span className="sr-only"> usuwanie{this.isProject ? ' projektu ' : ' naboru '}{projekt.nazwa}</span></ButtonStyled>
            </>
        )
    }
};


export { Projekt };
